import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BGContainer } from 'src/components';
import Store from 'src/store';
import Maps from './Maps';
import './style.css';

const remToPx = (rem: number) => parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;
const CONTENT_HEIGHT = window.innerHeight - remToPx(20);

interface Props extends RouteComponentProps {
    type: 'hotel' | 'wallet',
}

const typeName = {
    hotel: 'Hotel',
    wallet: 'Wallet',
} as const;

const HomePage = (props: Props) => {
    const StoreEntity = props.type === 'hotel' ? Store.Hotel : Store.Country
    useEffect(() => {
        StoreEntity.getListData();
        // @ts-ignore
        !!window.plausible && window.plausible('Page View', { props: { page: 'Home' } });
    }, []);

    return (
        <BGContainer title={`Amadeus ${typeName[props.type]} Heatmap`}
            onClickHome={() => props.history.push('/select-heat-map')}
        >
            <div style={{ height: CONTENT_HEIGHT, padding: '4rem', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Maps onClick={async (region) => {
                    await StoreEntity.setSelectedRegion(region);
                    props.history.push(`/${props.type}/region/${region.toLowerCase()}`);
                }} />
            </div>
            <div style={{ width: '100%', flexDirection: 'column', display: 'flex', position: 'absolute', bottom: '2rem' }}>
                <span style={{ fontSize: '2.1rem', textAlign: 'center', alignSelf: 'center' }} className="text-light">CLICK YOUR REGION</span>
            </div>
        </BGContainer>
    )
}

export default withRouter(HomePage);
