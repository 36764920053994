import React, { useEffect, useState } from 'react';
import { BGContainer, DotPaging } from 'src/components';
import _ from 'lodash'
import './style.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Store from 'src/store';
import { CountrySchema } from 'src/store/Country.Store';
import VarHelper from 'src/utils/VarHelper';
import classNames from 'classnames';
import { HotelSchema } from 'src/store/Hotel.Store';

const remToPx = (rem: number) => parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;

const calculateSize = () => {
    let row = Math.floor((window.innerHeight - remToPx(16)) / 125);
    let col = Math.floor((window.innerWidth - remToPx(10)) / (100 + remToPx(2)));
    // console.log('window.innerHeight', window.innerHeight, 125 * (row + 2) + 52, row);
    if (window.innerHeight >= 125 * (row + 2) + 52) {
        row += 1;
    }
    return row * col;
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots dot__',
};

interface Props extends RouteComponentProps {
    type: 'hotel' | 'wallet',
}

const getDefaultType = () => window.location.pathname.includes('wallet') ? 'wallet' : 'hotel'

const CountryList = ({ type = getDefaultType(), ...props }: Props) => {
    const { region }: any = props.match.params || {};
    const [rerender, setRerender] = useState(Math.random());
    console.log('props.match', props.match);
    let numPerPage = calculateSize();

    const StoreEntity = type === 'hotel' ? Store.Hotel : Store.Country;
    const alphabetList = StoreEntity.listCountriesByRegion.sort((a, b) => {
        if (type === 'hotel') return a.country < b.country ? -1 : 1;
        else if (type === 'wallet') return a.name < b.name ? -1 : 1;
    });
    let pagesData = _.chunk(alphabetList as any, numPerPage);
    console.log(pagesData);
    const sliderRef = React.createRef<Slider>();

    useEffect(() => {
        console.log('StoreEntity.selectedRegion', StoreEntity.selectedRegion);
        if (!!StoreEntity.listCountries && StoreEntity.listCountries.length === 0) {
            if (!StoreEntity.selectedRegion) {
                StoreEntity.setSelectedRegion(region.toUpperCase());
            }
            StoreEntity.getListData().then(() => setRerender(Math.random()))
        } else {
            if (!StoreEntity.selectedRegion) props.history.push(`/${type}/map`);
        }
        // @ts-ignore
        !!window.plausible && window.plausible('Page View', { props: { page: 'Country List', value: StoreEntity.selectedRegion } });
    }, [StoreEntity.listCountries]);

    const onSelectCountry = (country) => {
        console.log('country', country);
        StoreEntity.setSelectedCountry(country);
        props.history.push(`/${type}/country/${region}/${country.name || country.country}`);
    }

    return (
        <BGContainer
            title={`${StoreEntity.selectedRegion ? StoreEntity.selectedRegion.replace('_', ' ').toUpperCase() : ''} - SELECT COUNTRY`}
            onClickHome={() => props.history.push('/select-heat-map')}
        >
            {pagesData.length > 0 ? (
                <Slider ref={sliderRef} {...settings} >
                    {pagesData.map((pageData, j) => {
                        return (
                            <div key={`page${j}`} className="page-container">
                                {pageData.map((item: any, i: number) => {
                                    return <div
                                        // @ts-ignore 
                                        onClick={
                                            // type === 'wallet'
                                            //     ? () => item.hasLocalIssuer && onSelectCountry(item)
                                            //     : () => onSelectCountry(item)
                                            () => onSelectCountry(item)
                                        }
                                        className="country-item"
                                        key={`country${i}`}
                                    >
                                        <img
                                            alt={item.name}
                                            className={classNames("country-flag")}
                                            key={i}
                                            src={Store.Site.getImageByName(item.country || item.name) || VarHelper.getFlagByCountry(item.country || item.name)}
                                        />
                                        <span className="text-light">{item.country || item.name}</span>
                                    </div>
                                })}
                            </div>
                        )
                    })}
                </Slider>
            ) : (
                <p style={{ textAlign: 'center', color: 'white' }}>There's no information about this region</p>
            )}

            {/* {pagesData.length > 1 &&
                <div style={{ width: '100%', flexDirection: 'column', display: 'flex', position: 'absolute', bottom: '2rem' }}>
                    <DotPaging max={pagesData.length} current={page} onChange={onSelectDot} />
                </div>
            } */}
        </BGContainer>
    );
}

export default withRouter(CountryList);
