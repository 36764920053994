import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import ArrowIc from 'src/assets/icon/arrow.png';
import LogoIc from 'src/assets/logo.png';
import Store from 'src/store';
import './style.css';

const SelectHeatMap = (props: RouteComponentProps) => {

    const showHotelButton = true;
    // const showHotelButton = !window.location.href.includes('heatmap2380.com');

    // useEffect(() => {
    //     if (!showHotelButton) {
    //         props.history.push(`/wallet/map`);
    //     }
    // }, []);

    return (
        <div className="input-pass-background">
            <img src={LogoIc} style={{ width: '10rem', height: 'auto', position: 'absolute', top: '2rem', right: '2rem' }} />
            <div className='top-left-container'>
                <span style={{ fontSize: '2.1rem' }} id="title1" className="text-light">Amadeus B2B Wallet</span>
            </div>
            <div className="input-pass-box">    
                
                <span style={{ fontSize: '3.5rem' }} id="title2" className="text-light">SELECT HEATMAP</span>
                <div className="btn-container">
                    { showHotelButton && <Link to="/hotel/map"><button>Amadeus Hotel Heatmap</button></Link>}
                    <Link to="/wallet/map"><button>Amadeus B2B Wallet Heatmap</button></Link>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SelectHeatMap);
