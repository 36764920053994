import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import ArrowWhiteIc from 'src/assets/icon/arrow-white.png';
import Store from 'src/store';
import VarHelper from 'src/utils/VarHelper';
interface Props {
    selected?: string,
    setSelected(item: any): void,
}

const Partners = (props: Props) => {
    return (
        <Fragment>
            {Store.Country.selectedCountry.partners.map((item, i) => {
                let selected = item.name === props.selected;
                return (
                    <Fragment key={`partner${i}`}>
                        <div className={classNames('partner-item', {
                            'partner-item-active': selected,
                        })} >
                            <div className="partner-item-spacer" />
                            <div style={{ flex: 1 }}>
                                <img
                                    alt={item.name}
                                    className={classNames('image-partner', {
                                        'selected-partner': selected,
                                        'image-partner-active': selected,
                                        'disable-img': !selected && props.selected,
                                    })}
                                    onClick={() => {
                                        props.setSelected(item);
                                    }}
                                    src={Store.Site.getImageByName(item.name) || VarHelper.getImgByPartner(item.name)}
                                    style={{ width: '100%', backgroundColor: 'rgba(255,255,255,0.1)', minHeight: 100 }}
                                />
                                <img src={ArrowWhiteIc} className="arrow-icon-bottom" alt="" />
                            </div>
                            <div className={classNames('partner-item-spacer', {
                                'active-line': selected,
                            })} style={{ marginLeft: '1px' }} />
                        </div>
                        {item.description && <p className="partner-item-description">{item.description}</p>}
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default Partners;
