import React, { useEffect, useState } from 'react';
import { BGContainer, SteppedLineTo } from 'src/components';
import './style.css';
import Partners from './Partners';
import CardSchemes from './CardSchemes';
import CardSchemesGrid from './CardSchemesGrid';
import Currencies from './Currencies';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GlobalIc from 'src/assets/icon/global.png';
import HandsIc from 'src/assets/icon/hands.png';
import CheckedIc from 'src/assets/icon/checked.png';
import CardsIc from 'src/assets/icon/cards.png';
import classNames from 'classnames';
import Store from 'src/store';
import { PartnerSchema, CurrencySchema } from 'src/store/Country.Store';
import VarHelper from 'src/utils/VarHelper';
import { get } from 'lodash';

const TABS = [
    { title: 'COUNTRY', flex: 1, icon: GlobalIc },
    { title: 'PARTNERS', flex: 1, icon: HandsIc },
    { title: 'CARD SCHEMES', flex: 3, icon: CardsIc },
    { title: 'CURRENCIES SUPPORTED', flex: 2, icon: CheckedIc },
]
const TABS2 = [
    { title: 'COUNTRY', flex: 1, icon: GlobalIc },
    { title: 'PARTNERS', flex: 1, icon: HandsIc },
    { title: 'CURRENCIES SUPPORTED', flex: 3.5, icon: CheckedIc },
]

const remToPx = (rem: number) => parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;
const CONTENT_HEIGHT = window.innerHeight - remToPx(16);

const checkPartnerHideCard = (partner: PartnerSchema) => {
    if (!partner.currencies || partner.currencies.length !== 1) return false;
    return partner.currencies[0].cardScheme === 'none';
}


const CountryDetail = (props: RouteComponentProps) => {
    const { country, region }: any = props.match.params || {};
    const [showLine, setShowLine] = useState(false);
    const [rerender, setRerender] = useState(Math.random());
    const [selectedPartner, setSelectedPartner] = useState<PartnerSchema>();
    const [selectedCard, setSelectedCard] = useState<CurrencySchema>();

    useEffect(() => {
        // @ts-ignore
        !!window.plausible && window.plausible('Page View', { props: { page: 'Country Detail', value: Store.Country.selectedCountry } });
    }, []);

    console.log(`%c ${country} ${Store.Country.selectedCountry?.name}`, 'font-size: 36px; font-weight: bold');

    useEffect(() => {
        if (selectedPartner) {
            setShowLine(false);
            setTimeout(() => {
                setShowLine(true);
            }, 100);
            if (checkPartnerHideCard(selectedPartner)) {
                setSelectedCard(selectedPartner.currencies[0]);
            } else {
                setSelectedCard(undefined);
            }
        } else {
            setSelectedCard(undefined);
        }
    }, [selectedPartner]);

    useEffect(() => {
        if (!Store.Country.listCountries || Store.Country.listCountries.length === 0 || Store.Country.selectedRegion !== region.toUpperCase()) {
            if (!Store.Country.selectedRegion || Store.Country.selectedRegion !== region.toUpperCase()) {
                Store.Country.setSelectedRegion(region.toUpperCase());
            }
            Store.Country.getListData().then(() => {
                setRerender(Math.random());
            });
        } else {
            if (!Store.Country.selectedCountry || Store.Country.selectedCountry?.name !== country) {
                const findCountry = Store.Country.listCountries.find(val => val.name === country);
                if (!!findCountry) {
                    Store.Country.setSelectedCountry(findCountry);
                    setRerender(Math.random());
                } else {
                    props.history.push('/');
                    // props.history.push('/select-heat-map');
                }
            }
        }
    }, [Store.Country.listCountries?.length, Store.Country.selectedRegion, country, region]);

    const { selectedCountry } = Store.Country;
    console.log('selectedCountry', selectedCountry);

    useEffect(() => {
        if (selectedCountry && !Store.Site.getImageByName(selectedCountry.name)) {
            Store.Site.waitUntilLoaded().then(() => setRerender(Math.random()));
        }
    }, [selectedCountry]);

    let hideCardScheme = selectedPartner && checkPartnerHideCard(selectedPartner);

    const goBack = () => {
        props.history.goBack();
    }

    const toSelectPage = () => {
        props.history.push('/select-heat-map');
    }

    return (
        <BGContainer title={selectedCountry?.region} onClickHome={toSelectPage}>
            <div className="detail-header-container">
                {(hideCardScheme ? TABS2 : TABS).map((tab, i) => {
                    return (
                        <div key={`tab${i}`} className={classNames("header-item", {
                            'header-item-border': i < TABS.length - 1
                        })} style={{ flex: tab.flex }}>
                            <img src={tab.icon} />
                            <span className="text-regular" style={{ textAlign: 'center', fontSize: 20 }}>{tab.title}</span>
                        </div>
                    )
                })}
            </div>
            {selectedCountry &&
                <div className="detail-content-container">
                    <div style={{ flex: TABS[0].flex, overflow: 'hidden', transform: 'translateX(-20px)' }}>
                        <div style={{ marginTop: '15px' }}>
                            <img
                                src={Store.Site.getImageByName(selectedCountry.name) || VarHelper.getFlagByCountry(selectedCountry.name)}
                                style={{ width: '100%' }}
                            />
                            <span className="text-light" style={{ fontSize: '2vw', marginTop: '1rem', marginBottom: '1rem' }}>{selectedCountry.name.toUpperCase()}</span>
                            {Boolean(selectedCountry.issurance) &&
                                <span className="text-light" style={{ fontSize: '0.9rem' }}>
                                    {selectedCountry.issurance}
                                </span>
                            }
                            {Boolean(selectedCountry.additionComments) &&
                                <span className="text-light" style={{ fontSize: '0.9rem', marginTop: 10 }}>
                                    Additional Comments: {selectedCountry.additionComments}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="scrollable-col" style={{
                        flex: TABS[1].flex, height: CONTENT_HEIGHT,
                        overflow: 'scroll',
                        // overflow: selectedPartner ? 'hidden' : 'scroll'
                    }}>
                        <Partners selected={selectedPartner?.name} setSelected={(item: PartnerSchema) => {
                            if (item.name !== selectedPartner?.name) setSelectedPartner(item);
                            else setSelectedPartner(undefined);
                        }} />
                    </div>
                    {!hideCardScheme &&
                        <div className="scrollable-col" style={{
                            flex: TABS[2].flex, height: CONTENT_HEIGHT,
                            overflow: 'scroll',
                            // overflow: selectedCard ? 'hidden' : 'scroll'
                        }}>
                            {selectedPartner && selectedPartner.currencies &&
                                <CardSchemesGrid
                                    data={selectedPartner.currencies}
                                    selected={selectedCard?.cardScheme}
                                    setSelected={(item: CurrencySchema) => {
                                        if (item.cardScheme !== selectedCard?.cardScheme) setSelectedCard(item);
                                        else setSelectedCard(undefined);
                                    }}
                                />
                            }
                        </div>
                    }
                    <div className="scrollable-col" style={{ flex: hideCardScheme ? TABS2[2].flex : TABS[3].flex, height: CONTENT_HEIGHT }}>
                        {selectedCard && selectedCard.list &&
                            <Currencies data={selectedCard.list} funding={selectedPartner?.funding} />
                        }
                    </div>
                    {/* {showLine &&
                    <SteppedLineTo from="partner-item-active" to="target-card" fromAnchor="right" toAnchor="left" orientation="h" />
                } */}
                </div>
            }
        </BGContainer>
    );
}

export default withRouter(CountryDetail);
