import FLAGS from 'src/assets/flag_countries';
import FLAGS_PARTNER from 'src/assets/flag_partners';
import FLAGS_CARD from 'src/assets/flag_cards';
import { CardSchemeName } from 'src/store/Country.Store';

const cleanTxt = function (str, noDash) {
    let res = str.trim().replace(/'/g, '').replace(/\./g, '').replace(/[(),]/g, '').toLowerCase();
    if (noDash) res = res.replace(/-/g, '');
    return res.replace(/ /g, noDash ? '' : '_');
}

class VarHelper {
    getFlagByCountry(country: string) {
        return FLAGS[cleanTxt(country, true)];
    }
    getImgByPartner(partner: string) {
        return FLAGS_PARTNER[cleanTxt(partner, true)];
    }
    getImgByCard(card: CardSchemeName) {
        switch (card) {
            case 'AIDA (Airplus)':
                return FLAGS_CARD.card_aida;
            case 'MC MBS (GWTTP) : GPS':
                return FLAGS_CARD.card_mbs;
            case 'MC MRW (FALLBACK) : GPS':
                return FLAGS_CARD.card_mrw;
            case 'UATP (Airplus)':
                return FLAGS_CARD.card_uatp;
            case 'VISA Business Debit : FIS':
                return FLAGS_CARD.card_visa;
            default:
                return null;
        }
    }
}

export default new VarHelper();

