const ProtoBuf = require('protobufjs');

const requireNode = (str) => {
  return __dirname + str.substr(1, str.length);
}

class Protos {

  HelloWorld = {
    Root: undefined,
    SayHelloRequest: undefined,
    SayHelloResponse: undefined,
  };
  ErrorMessage = {
    Root: undefined,
    ErrorMessage: undefined,
  };
  User = {
    LoginRequest: undefined,
    LoginResponse: undefined,
    LogoutResponse: undefined,
    UserData: undefined,
  };
  Country = {
    Country: undefined,
    ListCountry: undefined,
  };
  Site = {
    AccessRequest: undefined,
    AccessResponse: undefined,
    ImageResponse: undefined,
  }

  constructor() {
    (async () => {
      await this.build();
      this.ready = true;
    })();
  }

  ready = false;
  _interval = [];
  onReady = () => new Promise((resolve) => {
    if (this.ready) {
      resolve(undefined);
      return;
    }
    const nextIndex = this._interval.length;
    this._interval[nextIndex] = setInterval(() => {
      if (this.ready) {
        resolve(undefined);
        clearInterval(this._interval[nextIndex]);
      }
    }, 500);
  });

  protoPathlist = [
    { prop: 'HelloWorld', path: require('./hello-world.proto') },
    { prop: 'ErrorMessage', path: require('./error-message.proto') },
    { prop: 'User', path: require('./user.proto') },
    { prop: 'Country', path: require('./country.proto') },
    { prop: 'Site', path: require('./site.proto') },
  ]

  build = async () => {

    const buildOne = async (property, protoPath) => {
      const root = await ProtoBuf.load(typeof protoPath === 'string' ? protoPath : protoPath.default);
      for (let key in this[property]) {
        if (key === 'Root') {
          this[property].Root = root;
        } else {
          this[property][key] = root.lookupType(key);
        }
      }
    };

    await Promise.all(this.protoPathlist.map(val => buildOne(val.prop, val.path)))
  }
}

export default new Protos();
