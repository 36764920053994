import card_aida from 'src/assets/card_aida.png';
import card_mbs from 'src/assets/card_mbs.png';
import card_mrw from 'src/assets/card_mrw.png';
import card_uatp from 'src/assets/card_uatp.png';
import card_visa from 'src/assets/card_visa.png';

const IMGS = {
    card_aida,
    card_mbs,
    card_mrw,
    card_uatp,
    card_visa,
}

export default IMGS;