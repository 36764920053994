import React, { Fragment, useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import ArrowBlueIc from 'src/assets/icon/arrow-blue.png';
import { CurrencySchema } from 'src/store/Country.Store';
import Store from 'src/store';
import VarHelper from 'src/utils/VarHelper';
import { ImageRatio } from 'src/components';

import './CardSchemesGrid.css';

interface Props {
  selected?: string,
  setSelected(item: any): void,
  data?: Array<any>,
}

const CardSchemesGrid = (props: Props) => {
  console.log('length', props.data.length);
  const [size, setSize] = useState({ width: 1, height: 1 });
  const divRef = useRef<HTMLElement>();

  const calculateWidthHeight = () => {
    if (!divRef.current || !divRef.current.getBoundingClientRect) return;
    const { width, height } = divRef.current.getBoundingClientRect();
    setSize({ width, height });
  };

  useEffect(() => {
    calculateWidthHeight();
    window.addEventListener('resize', calculateWidthHeight);
    return () => {
      window.removeEventListener('resize', calculateWidthHeight);
    }
  }, []);

  console.log(size);

  const layoutData = useMemo(() => {
    const containerPadding = 20;
    const itemMargin = 10;
    // -> 20 = itemMargin * 2
    const widthHeightRatio = 168 / 112;
    const areaWidth = (size.width - containerPadding * 2);

    const area = areaWidth * (size.height - containerPadding * 2);
    let maximumItemArea = area / props.data.length;
    // solve this equation
    // let itemArea = (itemWidth + 20) * (itemHeight + 20);
    // itemArea = (itemWidth + 20) + (itemWidth * 112 / 168  + 20);
    // itemArea = itemWidth * itemWidth + (20 + 112/168) * itemWidth + 400;
    // ->
    // itemWidth * itemWidth + (20 + 112/168) * itemWidth + 400 - maximumItemArea <= 0

    let delta = (20 + 112 / 168) * (20 + 112 / 168) - 4 * (400 - maximumItemArea);
    if (delta < 0) {
      maximumItemArea = maximumItemArea * 0.7;
      delta = (20 + 112 / 168) * (20 + 112 / 168) - 4 * (400 - maximumItemArea);
    }
    const maximumItemWidth = Math.max(
      (-(20 + 112 / 168) + Math.sqrt(delta)) / 2,
      (-(20 + 112 / 168) - Math.sqrt(delta)) / 2,
    );

    console.log('maximumItemWidth', maximumItemWidth);

    // (maximumItemWidth + 20) * mimiumNumberOfItemsPerRow = areaWidth

    const mimiumNumberOfItemsPerRow = (areaWidth / (maximumItemWidth + 2 * itemMargin));
    const numberOfItems = Math.floor(mimiumNumberOfItemsPerRow) === mimiumNumberOfItemsPerRow ? mimiumNumberOfItemsPerRow : Math.floor(mimiumNumberOfItemsPerRow) + 1;
    let itemWidth = Math.floor((areaWidth) / numberOfItems) - 2 * itemMargin;
    if (numberOfItems === 1) itemWidth = itemWidth / 2;

    const groupedItems = []; // for example itemWidth = 3 -> [ [a,b,c], [d,e,f], ...]
    for (let i = 0; i < props.data.length; i++) {
      const index = Math.floor(i / numberOfItems);
      if (!groupedItems[index]) groupedItems[index] = [];
      if (groupedItems[index].length < numberOfItems) {
        groupedItems[index].push(props.data[i]);
      } else {
        if (!groupedItems[index + 1]) groupedItems[index + 1] = [];
        groupedItems[index + 1].push(props.data[i]);
      }
    }
    console.log('areaWidth', areaWidth);
    const areaUsedWidth = (itemWidth + 2 * itemMargin) * numberOfItems;
    return {
      numberOfItems,
      height: itemWidth / widthHeightRatio,
      width: itemWidth,
      margin: itemMargin,
      groupedItems,
      areaUsedWidth,
      rowMargin: (areaWidth - areaUsedWidth) / 2,
    };
  }, [props.data, size]);

  console.log('layoutData', layoutData);

  return (
    <div className='card-scheme-provider-grid' ref={ref => { divRef.current = ref; }}>
      {layoutData.groupedItems.map((groupItems, groupItemIndex) => {
        return (
          <div style={{ width: layoutData.areaUsedWidth, display: 'flex', flexDirection: 'row', position: 'relative' }} key={'row-' + groupItemIndex}>
            {!!groupItems && groupItems.map((item, index) => {
              return (
                <div
                  key={'provider-' + index}
                  className={classNames('card-scheme-provider-container', { selected: item.cardScheme === props.selected })}
                  style={{ width: layoutData.width, height: layoutData.height, margin: layoutData.margin }}
                  onClick={() => props.setSelected(item)}
                >
                  {!!Store.Site.getImageByName(item.cardScheme) || VarHelper.getImgByCard(item.cardScheme) ? (
                    <ImageRatio
                      src={Store.Site.getImageByName(item.cardScheme) || VarHelper.getImgByCard(item.cardScheme)}
                      ratio={168 / 112}
                    />
                  ) : (
                    <div
                      style={{ backgroundColor: 'rgb(1,188,241)', height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              );
            })}
            <div
              className='connect-line'
              style={{ width: (layoutData.rowMargin+40), left: -(layoutData.rowMargin+30) }}
            />
          </div>
        )
      })}

    </div>
  );
};

export default CardSchemesGrid;
