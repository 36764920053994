import React, { useState } from 'react';
import classNames from 'classnames';
import { Region } from 'src/store/Country.Store';

// let clearHoverTimeout = setTimeout(() => { }, 0);

interface Props {
	onClick(region: Region): void;
}

const Maps = (props: Props) => {
	const [highlight, setHighlight] = useState('');
	const onHoverRegion = (id: string) => {
		// if (clearHoverTimeout) clearTimeout(clearHoverTimeout);
		if (id !== highlight) {
			setHighlight(id);
		}
	}

	const clearHover = () => {
		// clearTimeout(clearHoverTimeout);
		// clearHoverTimeout = setTimeout(() => setHighlight(''), 200);
	}

	const onClick = () => {
		if (!highlight) return;
		props.onClick(highlight.toUpperCase() as Region);
	}

	return (
		<svg onClick={onClick} width="1502.6px" height="656px" viewBox="0 0 1502.6 656" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g className={classNames("g-latam", { 'g-map-active': highlight === 'latam' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('latam')}>
				<path fill="#D51566" d="M363.3,642.1c0,1.4,1.6,2.4,3.6,2.3c2-0.1,3.7-1.4,3.7-2.8c0-1.4-1.6-2.4-3.6-2.3
			C365,639.5,363.3,640.7,363.3,642.1"/>
				<path fill="#D51566" d="M351.6,643c0,1.4,1.6,2.4,3.6,2.3c2-0.1,3.7-1.4,3.8-2.8c0-1.4-1.6-2.4-3.6-2.3
			C353.3,640.3,351.6,641.6,351.6,643"/>
				<path fill="#D51566" d="M410.4,631.1c0,1.4,1.6,2.5,3.6,2.3c2-0.1,3.7-1.4,3.7-2.8c0-1.4-1.6-2.5-3.6-2.4
			C412.1,628.5,410.4,629.7,410.4,631.1"/>
				<path fill="#D51566" d="M351.9,635c-0.1,1.4,1.5,2.5,3.6,2.3c2-0.1,3.7-1.4,3.8-2.8c0.1-1.4-1.5-2.5-3.6-2.3
			C353.6,632.3,351.9,633.5,351.9,635"/>
				<path fill="#D51566" d="M340.2,635.8c-0.1,1.4,1.5,2.5,3.6,2.3c2-0.1,3.7-1.4,3.8-2.8c0.1-1.4-1.5-2.5-3.5-2.3
			C341.9,633.1,340.2,634.4,340.2,635.8"/>
				<path fill="#D51566" d="M363.9,626c-0.1,1.4,1.5,2.5,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.4-1.5-2.5-3.5-2.4
			C365.7,623.3,364,624.6,363.9,626"/>
				<path fill="#D51566" d="M352.3,626.8c-0.1,1.5,1.5,2.5,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.4-1.5-2.5-3.5-2.4
			C354.1,624.1,352.4,625.4,352.3,626.8"/>
				<path fill="#D51566" d="M340.6,627.7c-0.1,1.5,1.5,2.5,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.5-3.5-2.4
			C342.4,624.9,340.7,626.2,340.6,627.7"/>
				<path fill="#D51566" d="M469.4,611.6c0,1.5,1.6,2.6,3.6,2.5c2-0.1,3.7-1.4,3.8-2.8c0-1.5-1.6-2.6-3.6-2.5
			C471.1,608.9,469.4,610.2,469.4,611.6"/>
				<path fill="#D51566" d="M457.7,612.2c-0.1,1.5,1.6,2.6,3.6,2.5s3.7-1.4,3.8-2.8c0-1.5-1.5-2.6-3.6-2.5
			C459.4,609.5,457.7,610.8,457.7,612.2"/>
				<path fill="#D51566" d="M446,612.8c-0.1,1.5,1.5,2.6,3.6,2.4c2-0.1,3.7-1.4,3.8-2.8c0.1-1.5-1.5-2.6-3.6-2.5
			C447.8,610.1,446.1,611.4,446,612.8"/>
				<path fill="#D51566" d="M376.1,617c-0.1,1.5,1.5,2.6,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.6-3.5-2.4
			C377.9,614.2,376.2,615.5,376.1,617"/>
				<path fill="#D51566" d="M364.4,617.8c-0.1,1.5,1.5,2.6,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.6-3.5-2.4
			C366.3,615,364.5,616.3,364.4,617.8"/>
				<path fill="#D51566" d="M352.8,618.6c-0.1,1.5,1.4,2.5,3.5,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.4-2.6-3.5-2.4
			C354.7,615.8,352.9,617.1,352.8,618.6"/>
				<path fill="#D51566" d="M341.2,619.4c-0.1,1.5,1.4,2.5,3.4,2.4c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.4-2.6-3.4-2.4
			C343.1,616.6,341.3,617.9,341.2,619.4"/>
				<path fill="#D51566" d="M458,603.9c-0.1,1.5,1.5,2.6,3.5,2.5c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.6-3.5-2.5
			C459.8,601.1,458.1,602.4,458,603.9"/>
				<path fill="#D51566" d="M446.4,604.5c-0.1,1.5,1.5,2.6,3.5,2.5c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.6-3.5-2.5
			C448.2,601.7,446.5,603,446.4,604.5"/>
				<path fill="#D51566" d="M434.7,605.1c-0.1,1.5,1.5,2.6,3.5,2.5s3.7-1.4,3.8-2.9c0.1-1.5-1.5-2.6-3.5-2.5
			C436.5,602.3,434.8,603.6,434.7,605.1"/>
				<path fill="#D51566" d="M376.7,608.6c-0.1,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.4,3.8-2.9c0.1-1.5-1.4-2.6-3.4-2.5
			C378.5,605.8,376.8,607.1,376.7,608.6"/>
				<path fill="#D51566" d="M365.1,609.4c-0.1,1.5,1.4,2.6,3.4,2.5s3.7-1.4,3.8-2.9c0.1-1.5-1.4-2.6-3.4-2.5
			C366.9,606.6,365.2,607.9,365.1,609.4"/>
				<path fill="#D51566" d="M353.5,610.2c-0.1,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.6-3.4-2.5
			C355.4,607.3,353.6,608.7,353.5,610.2"/>
				<path fill="#D51566" d="M341.9,611c-0.1,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.6-3.4-2.5
			C343.8,608.1,342.1,609.5,341.9,611"/>
				<path fill="#D51566" d="M377.4,600.1c-0.1,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.7-3.4-2.5
			C379.2,597.2,377.5,598.6,377.4,600.1"/>
				<path fill="#D51566" d="M365.8,600.9c-0.1,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.7-3.4-2.5
			C367.7,598,366,599.3,365.8,600.9"/>
				<path fill="#D51566" d="M354.3,601.6c-0.2,1.5,1.4,2.6,3.4,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.7-3.4-2.5
			C356.2,598.8,354.4,600.1,354.3,601.6"/>
				<path fill="#D51566" d="M342.8,602.4c-0.2,1.5,1.3,2.6,3.3,2.5c2-0.1,3.7-1.5,3.9-3c0.2-1.5-1.3-2.6-3.3-2.5
			C344.7,599.6,342.9,600.9,342.8,602.4"/>
				<path fill="#D51566" d="M447.4,587.4c-0.1,1.5,1.4,2.7,3.4,2.6c2-0.1,3.7-1.4,3.8-3c0.1-1.5-1.4-2.7-3.4-2.6
			C449.2,584.5,447.5,585.9,447.4,587.4"/>
				<path fill="#D51566" d="M389.7,590.7c-0.1,1.5,1.4,2.7,3.4,2.6c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.4-2.7-3.4-2.6
			C391.6,587.8,389.8,589.2,389.7,590.7"/>
				<path fill="#D51566" d="M378.2,591.5c-0.2,1.5,1.3,2.7,3.3,2.5c2-0.1,3.7-1.5,3.9-3c0.1-1.5-1.3-2.7-3.3-2.6
			C380.1,588.6,378.3,589.9,378.2,591.5"/>
				<path fill="#D51566" d="M366.7,592.2c-0.2,1.5,1.3,2.7,3.3,2.5c2-0.1,3.7-1.5,3.9-3c0.2-1.5-1.3-2.7-3.3-2.6
			C368.6,589.3,366.8,590.7,366.7,592.2"/>
				<path fill="#D51566" d="M355.2,593c-0.2,1.5,1.3,2.7,3.3,2.5c2-0.1,3.7-1.5,3.9-3c0.2-1.5-1.3-2.7-3.3-2.6
			C357.1,590.1,355.3,591.4,355.2,593"/>
				<path fill="#D51566" d="M343.7,593.8c-0.2,1.5,1.3,2.7,3.3,2.5c2-0.1,3.7-1.5,3.9-3c0.2-1.5-1.3-2.7-3.3-2.6
			C345.6,590.9,343.9,592.2,343.7,593.8"/>
				<path fill="#D51566" d="M402,581.3c-0.2,1.6,1.3,2.7,3.3,2.6c2-0.1,3.7-1.5,3.9-3c0.1-1.6-1.3-2.7-3.3-2.6
			C403.9,578.3,402.2,579.7,402,581.3"/>
				<path fill="#D51566" d="M390.5,582c-0.2,1.6,1.3,2.7,3.3,2.6c2-0.1,3.7-1.5,3.9-3c0.2-1.6-1.3-2.7-3.3-2.6
			C392.5,579,390.7,580.4,390.5,582"/>
				<path fill="#D51566" d="M379.1,582.7c-0.2,1.6,1.3,2.7,3.3,2.6c2-0.1,3.7-1.5,3.9-3c0.2-1.6-1.3-2.7-3.3-2.6
			C381,579.8,379.3,581.1,379.1,582.7"/>
				<path fill="#D51566" d="M367.6,583.4c-0.2,1.6,1.3,2.7,3.3,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.7-3.3-2.6
			C369.6,580.5,367.8,581.9,367.6,583.4"/>
				<path fill="#D51566" d="M356.2,584.2c-0.2,1.6,1.3,2.7,3.3,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.7-3.2-2.6
			C358.1,581.3,356.4,582.6,356.2,584.2"/>
				<path fill="#D51566" d="M344.8,585c-0.2,1.6,1.3,2.7,3.2,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.7-3.2-2.6
			C346.7,582,345,583.4,344.8,585"/>
				<path fill="#D51566" d="M414.4,571.7c-0.2,1.6,1.3,2.8,3.3,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.7
			C416.3,568.8,414.6,570.1,414.4,571.7"/>
				<path fill="#D51566" d="M403,572.4c-0.2,1.6,1.3,2.8,3.3,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.7
			C404.9,569.4,403.1,570.8,403,572.4"/>
				<path fill="#D51566" d="M391.5,573.1c-0.2,1.6,1.3,2.8,3.3,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.6
			C393.5,570.1,391.7,571.5,391.5,573.1"/>
				<path fill="#D51566" d="M380.1,573.8c-0.2,1.6,1.3,2.8,3.2,2.6c2-0.1,3.7-1.5,3.9-3.1s-1.3-2.8-3.2-2.6
			C382.1,570.8,380.3,572.2,380.1,573.8"/>
				<path fill="#D51566" d="M368.7,574.6c-0.2,1.6,1.2,2.8,3.2,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.6
			C370.7,571.6,368.9,573,368.7,574.6"/>
				<path fill="#D51566" d="M357.3,575.3c-0.2,1.6,1.2,2.8,3.2,2.6c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.6
			C359.3,572.3,357.5,573.7,357.3,575.3"/>
				<path fill="#D51566" d="M346,576.1c-0.2,1.6,1.2,2.7,3.2,2.6c2-0.1,3.7-1.5,4-3.1s-1.2-2.8-3.2-2.6
			C348,573.1,346.2,574.5,346,576.1"/>
				<path fill="#D51566" d="M426.8,562.1c-0.2,1.6,1.3,2.8,3.3,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.7
			C428.7,559.1,427,560.5,426.8,562.1"/>
				<path fill="#D51566" d="M415.4,562.7c-0.2,1.6,1.3,2.8,3.3,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.7
			C417.3,559.7,415.6,561.1,415.4,562.7"/>
				<path fill="#D51566" d="M404,563.4c-0.2,1.6,1.3,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.2-2.7
			C405.9,560.4,404.2,561.8,404,563.4"/>
				<path fill="#D51566" d="M392.6,564.1c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C394.6,561.1,392.8,562.5,392.6,564.1"/>
				<path fill="#D51566" d="M381.2,564.8c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C383.2,561.8,381.4,563.2,381.2,564.8"/>
				<path fill="#D51566" d="M369.9,565.6c-0.2,1.6,1.2,2.8,3.2,2.7s3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C371.9,562.6,370.1,564,369.9,565.6"/>
				<path fill="#D51566" d="M358.6,566.3c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,4-3.1c0.2-1.6-1.2-2.8-3.1-2.7
			C360.6,563.3,358.8,564.7,358.6,566.3"/>
				<path fill="#D51566" d="M439.2,552.4c-0.2,1.6,1.3,2.8,3.3,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.3-2.7
			C441.1,549.4,439.4,550.8,439.2,552.4"/>
				<path fill="#D51566" d="M427.8,553c-0.2,1.6,1.3,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.8-3.2-2.7
			C429.8,550,428,551.4,427.8,553"/>
				<path fill="#D51566" d="M416.5,553.7c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C418.4,550.6,416.7,552,416.5,553.7"/>
				<path fill="#D51566" d="M405.1,554.3c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C407.1,551.3,405.3,552.7,405.1,554.3"/>
				<path fill="#D51566" d="M393.8,555c-0.2,1.6,1.2,2.8,3.2,2.7c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.8-3.2-2.7
			C395.8,552,394,553.4,393.8,555"/>
				<path fill="#D51566" d="M382.5,555.7c-0.2,1.6,1.2,2.8,3.1,2.7c2-0.1,3.7-1.5,4-3.1c0.2-1.6-1.2-2.8-3.1-2.7
			C384.5,552.7,382.7,554.1,382.5,555.7"/>
				<path fill="#D51566" d="M371.2,556.5c-0.2,1.6,1.2,2.8,3.1,2.7c2-0.1,3.7-1.5,4-3.2c0.2-1.6-1.2-2.8-3.1-2.7
			C373.2,553.4,371.4,554.8,371.2,556.5"/>
				<path fill="#D51566" d="M359.9,557.2c-0.2,1.6,1.1,2.8,3.1,2.7c2-0.1,3.7-1.5,4-3.2c0.2-1.6-1.1-2.8-3.1-2.7
			C361.9,554.2,360.2,555.6,359.9,557.2"/>
				<path fill="#D51566" d="M451.6,542.6c-0.2,1.6,1.3,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.3-2.9-3.2-2.8
			C453.5,539.5,451.8,540.9,451.6,542.6"/>
				<path fill="#D51566" d="M440.3,543.2c-0.2,1.6,1.2,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.1c0.2-1.6-1.2-2.9-3.2-2.8
			C442.2,540.1,440.5,541.6,440.3,543.2"/>
				<path fill="#D51566" d="M428.9,543.8c-0.2,1.6,1.2,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.6-1.2-2.9-3.2-2.8
			C430.9,540.8,429.1,542.2,428.9,543.8"/>
				<path fill="#D51566" d="M417.6,544.5c-0.2,1.6,1.2,2.9,3.2,2.7s3.7-1.5,3.9-3.2c0.2-1.6-1.2-2.9-3.2-2.8
			C419.6,541.4,417.8,542.8,417.6,544.5"/>
				<path fill="#D51566" d="M406.3,545.1c-0.2,1.6,1.2,2.9,3.1,2.7c2-0.1,3.7-1.5,3.9-3.2c0.2-1.6-1.2-2.9-3.1-2.8
			C408.3,542.1,406.6,543.5,406.3,545.1"/>
				<path fill="#D51566" d="M395.1,545.8c-0.2,1.6,1.2,2.9,3.1,2.7c2-0.1,3.7-1.5,4-3.2c0.2-1.6-1.2-2.9-3.1-2.7
			C397.1,542.8,395.3,544.2,395.1,545.8"/>
				<path fill="#D51566" d="M383.8,546.5c-0.2,1.6,1.1,2.9,3.1,2.7c2-0.1,3.7-1.5,4-3.2c0.2-1.6-1.1-2.9-3.1-2.7
			S384.1,544.9,383.8,546.5"/>
				<path fill="#D51566" d="M372.6,547.3c-0.3,1.6,1.1,2.9,3.1,2.7c2-0.1,3.7-1.6,4-3.2c0.2-1.6-1.1-2.9-3.1-2.7
			C374.6,544.2,372.8,545.6,372.6,547.3"/>
				<path fill="#D51566" d="M361.4,548c-0.3,1.6,1.1,2.9,3,2.7c1.9-0.1,3.7-1.6,4-3.2c0.3-1.6-1.1-2.9-3-2.7
			C363.4,544.9,361.6,546.4,361.4,548"/>
				<path fill="#D51566" d="M350.2,548.8c-0.3,1.6,1.1,2.8,3,2.7c1.9-0.1,3.7-1.6,4-3.2c0.3-1.6-1.1-2.9-3-2.7
			C352.2,545.7,350.4,547.2,350.2,548.8"/>
				<path fill="#D51566" d="M452.7,533.3c-0.2,1.7,1.2,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.2-2.8
			C454.7,530.2,452.9,531.6,452.7,533.3"/>
				<path fill="#D51566" d="M441.4,533.9c-0.2,1.7,1.2,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.2-2.8
			S441.6,532.3,441.4,533.9"/>
				<path fill="#D51566" d="M430.1,534.5c-0.2,1.7,1.2,2.9,3.1,2.8s3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.1-2.8
			C432.1,531.4,430.4,532.9,430.1,534.5"/>
				<path fill="#D51566" d="M418.9,535.2c-0.2,1.7,1.2,2.9,3.1,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.1-2.8
			C420.9,532.1,419.1,533.5,418.9,535.2"/>
				<path fill="#D51566" d="M407.7,535.8c-0.2,1.7,1.1,2.9,3.1,2.8c2-0.1,3.7-1.5,4-3.2c0.2-1.7-1.1-2.9-3.1-2.8
			C409.7,532.8,407.9,534.2,407.7,535.8"/>
				<path fill="#D51566" d="M396.4,536.5c-0.3,1.7,1.1,2.9,3.1,2.8c1.9-0.1,3.7-1.6,4-3.2c0.2-1.7-1.1-2.9-3.1-2.8
			C398.5,533.4,396.7,534.9,396.4,536.5"/>
				<path fill="#D51566" d="M385.2,537.2c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8
			C387.3,534.1,385.5,535.6,385.2,537.2"/>
				<path fill="#D51566" d="M374.1,538c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8S374.3,536.3,374.1,538" />
				<path fill="#D51566" d="M362.9,538.7c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8
			C365,535.6,363.2,537.1,362.9,538.7"/>
				<path fill="#D51566" d="M351.8,539.5c-0.3,1.7,1,2.9,3,2.7c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1-2.9-3-2.8
			C353.9,536.4,352.1,537.8,351.8,539.5"/>
				<path fill="#D51566" d="M465.1,523.3c-0.2,1.7,1.2,2.9,3.2,2.8c2-0.1,3.7-1.5,3.9-3.2s-1.2-2.9-3.2-2.8
			C467.1,520.2,465.3,521.7,465.1,523.3"/>
				<path fill="#D51566" d="M453.9,523.9c-0.2,1.7,1.2,2.9,3.1,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.1-2.8
			C455.8,520.8,454.1,522.2,453.9,523.9"/>
				<path fill="#D51566" d="M442.6,524.5c-0.2,1.7,1.2,2.9,3.1,2.8c2-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.1-2.8
			C444.6,521.4,442.9,522.9,442.6,524.5"/>
				<path fill="#D51566" d="M431.4,525.1c-0.2,1.7,1.2,2.9,3.1,2.8c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-2.9-3.1-2.8
			C433.4,522,431.7,523.5,431.4,525.1"/>
				<path fill="#D51566" d="M420.2,525.8c-0.2,1.7,1.1,2.9,3.1,2.8c1.9-0.1,3.7-1.6,4-3.2c0.2-1.7-1.1-2.9-3.1-2.8
			C422.3,522.7,420.5,524.1,420.2,525.8"/>
				<path fill="#D51566" d="M409.1,526.5c-0.3,1.7,1.1,2.9,3.1,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8
			C411.1,523.3,409.3,524.8,409.1,526.5"/>
				<path fill="#D51566" d="M397.9,527.2c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8
			C400,524,398.2,525.5,397.9,527.2"/>
				<path fill="#D51566" d="M386.8,527.9c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-2.9-3-2.8
			S387.1,526.2,386.8,527.9"/>
				<path fill="#D51566" d="M375.7,528.6c-0.3,1.7,1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1-2.9-3-2.8
			C377.8,525.5,376,526.9,375.7,528.6"/>
				<path fill="#D51566" d="M364.6,529.3c-0.3,1.7,1,2.9,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-2.9-2.9-2.8
			C366.7,526.2,364.9,527.7,364.6,529.3"/>
				<path fill="#D51566" d="M353.5,530.1c-0.3,1.7,1,2.9,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-2.9-2.9-2.8
			C355.6,527,353.8,528.4,353.5,530.1"/>
				<path fill="#D51566" d="M477.5,513.3c-0.2,1.7,1.2,3,3.2,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C479.5,510.2,477.7,511.6,477.5,513.3"/>
				<path fill="#D51566" d="M466.3,513.9c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C468.3,510.7,466.5,512.2,466.3,513.9"/>
				<path fill="#D51566" d="M455.1,514.4c-0.2,1.7,1.2,3,3.1,2.9s3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C457.1,511.3,455.4,512.8,455.1,514.4"/>
				<path fill="#D51566" d="M444,515.1c-0.2,1.7,1.1,3,3.1,2.8c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.1-3-3.1-2.9
			C446,511.9,444.2,513.4,444,515.1"/>
				<path fill="#D51566" d="M432.8,515.7c-0.3,1.7,1.1,3,3.1,2.8c1.9-0.1,3.7-1.6,3.9-3.2c0.2-1.7-1.1-3-3-2.9
			C434.8,512.5,433.1,514,432.8,515.7"/>
				<path fill="#D51566" d="M421.7,516.3c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.2c0.3-1.7-1.1-3-3-2.8
			C423.7,513.2,421.9,514.6,421.7,516.3"/>
				<path fill="#D51566" d="M410.6,517c-0.3,1.7,1.1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1.1-3-3-2.8
			C412.6,513.9,410.8,515.3,410.6,517"/>
				<path fill="#D51566" d="M399.5,517.7c-0.3,1.7,1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1.1-3-3-2.8
			C401.6,514.5,399.8,516,399.5,517.7"/>
				<path fill="#D51566" d="M388.4,518.4c-0.3,1.7,1,2.9,3,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.8
			C390.5,515.2,388.7,516.7,388.4,518.4"/>
				<path fill="#D51566" d="M377.4,519.1c-0.3,1.7,1,2.9,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-2.9-2.9-2.8
			C379.5,516,377.7,517.4,377.4,519.1"/>
				<path fill="#D51566" d="M366.3,519.9c-0.3,1.7,1,2.9,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-2.9-2.9-2.8
			C368.5,516.7,366.6,518.2,366.3,519.9"/>
				<path fill="#D51566" d="M355.3,520.6c-0.3,1.7,1,2.9,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-2.9-2.9-2.8
			C357.5,517.5,355.7,519,355.3,520.6"/>
				<path fill="#D51566" d="M501.1,502.7c-0.2,1.7,1.2,3,3.2,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.2-2.9
			C503,499.5,501.3,501,501.1,502.7"/>
				<path fill="#D51566" d="M489.9,503.2c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C491.8,500.1,490.1,501.5,489.9,503.2"/>
				<path fill="#D51566" d="M478.7,503.7c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C480.7,500.6,479,502.1,478.7,503.7"/>
				<path fill="#D51566" d="M467.6,504.3c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.2c0.2-1.7-1.2-3-3.1-2.9
			C469.6,501.2,467.8,502.6,467.6,504.3"/>
				<path fill="#D51566" d="M456.5,504.9c-0.2,1.7,1.1,3,3.1,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3.1-2.9
			C458.5,501.7,456.7,503.2,456.5,504.9"/>
				<path fill="#D51566" d="M445.4,505.5c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3-2.9
			C447.4,502.3,445.6,503.8,445.4,505.5"/>
				<path fill="#D51566" d="M434.3,506.1c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1.1-3-3-2.9
			C436.3,503,434.5,504.4,434.3,506.1"/>
				<path fill="#D51566" d="M423.2,506.8c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1.1-3-3-2.9
			C425.3,503.6,423.5,505.1,423.2,506.8"/>
				<path fill="#D51566" d="M412.2,507.4c-0.3,1.7,1,3,3,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9S412.4,505.8,412.2,507.4" />
				<path fill="#D51566" d="M401.1,508.1c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C403.2,505,401.4,506.4,401.1,508.1"/>
				<path fill="#D51566" d="M390.1,508.8c-0.3,1.7,1,3,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C392.2,505.7,390.4,507.1,390.1,508.8"/>
				<path fill="#D51566" d="M379.1,509.6c-0.3,1.7,1,3,2.9,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.8
			C381.3,506.4,379.5,507.9,379.1,509.6"/>
				<path fill="#D51566" d="M368.2,510.3c-0.3,1.7,0.9,3,2.8,2.8c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.8
			C370.3,507.1,368.5,508.6,368.2,510.3"/>
				<path fill="#D51566" d="M346.3,511.9c-0.4,1.7,0.9,3,2.8,2.8c1.9-0.1,3.7-1.6,4.1-3.3c0.4-1.7-0.9-3-2.8-2.8
			C348.5,508.7,346.7,510.2,346.3,511.9"/>
				<path fill="#D51566" d="M502.2,493c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.3c0.2-1.7-1.2-3-3.1-2.9
			C504.2,489.9,502.4,491.3,502.2,493"/>
				<path fill="#D51566" d="M491.1,493.6c-0.2,1.7,1.2,3,3.1,2.9c1.9-0.1,3.7-1.5,3.9-3.3c0.2-1.7-1.2-3-3.1-2.9
			C493.1,490.4,491.3,491.9,491.1,493.6"/>
				<path fill="#D51566" d="M480,494.1c-0.2,1.7,1.1,3,3.1,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3.1-2.9
			C482,490.9,480.3,492.4,480,494.1"/>
				<path fill="#D51566" d="M468.9,494.7c-0.2,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3-2.9
			C470.9,491.5,469.2,493,468.9,494.7"/>
				<path fill="#D51566" d="M457.9,495.3c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3-2.9
			C459.9,492.1,458.1,493.6,457.9,495.3"/>
				<path fill="#D51566" d="M446.8,495.9c-0.3,1.7,1.1,3,3,2.9s3.7-1.6,3.9-3.3c0.3-1.7-1.1-3-3-2.9
			C448.9,492.7,447.1,494.2,446.8,495.9"/>
				<path fill="#D51566" d="M435.8,496.5c-0.3,1.7,1,3,3,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1.1-3-3-2.9
			C437.9,493.3,436.1,494.8,435.8,496.5"/>
				<path fill="#D51566" d="M424.8,497.2c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C426.9,494,425.1,495.4,424.8,497.2"/>
				<path fill="#D51566" d="M413.8,497.8c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C415.9,494.6,414.1,496.1,413.8,497.8"/>
				<path fill="#D51566" d="M402.9,498.5c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3s-1-3-2.9-2.9C405,495.3,403.2,496.8,402.9,498.5
			"/>
				<path fill="#D51566" d="M391.9,499.2c-0.3,1.7,0.9,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.8-2.9
			C394.1,496,392.3,497.5,391.9,499.2"/>
				<path fill="#D51566" d="M381,499.9c-0.3,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C383.2,496.7,381.4,498.2,381,499.9"/>
				<path fill="#D51566" d="M370.1,500.7c-0.4,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C372.3,497.5,370.5,499,370.1,500.7"/>
				<path fill="#D51566" d="M359.3,501.5c-0.4,1.7,0.9,3,2.8,2.8c1.9-0.1,3.7-1.6,4.1-3.3c0.4-1.7-0.9-3-2.8-2.9
			C361.4,498.2,359.6,499.7,359.3,501.5"/>
				<path fill="#D51566" d="M348.4,502.2c-0.4,1.7,0.8,3,2.7,2.8s3.7-1.6,4.1-3.3c0.4-1.7-0.9-3-2.7-2.9
			C350.6,499,348.8,500.5,348.4,502.2"/>
				<path fill="#D51566" d="M337.6,503c-0.4,1.7,0.8,3,2.7,2.8c1.9-0.1,3.7-1.6,4.1-3.3c0.4-1.7-0.8-3-2.7-2.8
			C339.8,499.8,338,501.3,337.6,503"/>
				<path fill="#D51566" d="M503.4,483.3c-0.2,1.7,1.2,3,3.1,3c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.2-3.1-3.1-3
			C505.4,480.1,503.7,481.6,503.4,483.3"/>
				<path fill="#D51566" d="M492.4,483.9c-0.2,1.7,1.1,3,3.1,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3
			C494.4,480.7,492.6,482.1,492.4,483.9"/>
				<path fill="#D51566" d="M481.4,484.4c-0.2,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3-3
			C483.4,481.2,481.6,482.7,481.4,484.4"/>
				<path fill="#D51566" d="M470.4,485c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.2-1.7-1.1-3-3-2.9
			C472.4,481.8,470.6,483.3,470.4,485"/>
				<path fill="#D51566" d="M459.4,485.6c-0.3,1.7,1.1,3,3,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.3-1.7-1.1-3-3-2.9
			C461.4,482.4,459.6,483.8,459.4,485.6"/>
				<path fill="#D51566" d="M448.4,486.2c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.3-1.7-1-3-2.9-2.9
			C450.4,483,448.7,484.5,448.4,486.2"/>
				<path fill="#D51566" d="M437.4,486.8c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C439.5,483.6,437.7,485.1,437.4,486.8"/>
				<path fill="#D51566" d="M426.5,487.5c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C428.6,484.2,426.8,485.7,426.5,487.5"/>
				<path fill="#D51566" d="M415.6,488.1c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3-2.9-2.9
			C417.7,484.9,415.9,486.4,415.6,488.1"/>
				<path fill="#D51566" d="M404.7,488.8c-0.3,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C406.8,485.6,405,487.1,404.7,488.8"/>
				<path fill="#D51566" d="M393.8,489.5c-0.3,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C396,486.3,394.2,487.8,393.8,489.5"/>
				<path fill="#D51566" d="M383,490.2c-0.4,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C385.1,487,383.3,488.5,383,490.2"/>
				<path fill="#D51566" d="M372.2,491c-0.4,1.7,0.9,3,2.7,2.9c1.9-0.1,3.7-1.6,4.1-3.3c0.4-1.7-0.9-3-2.7-2.9
			C374.3,487.8,372.5,489.3,372.2,491"/>
				<path fill="#D51566" d="M361.4,491.8c-0.4,1.7,0.8,3,2.7,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.7-2.9
			C363.6,488.5,361.7,490,361.4,491.8"/>
				<path fill="#D51566" d="M350.6,492.5c-0.4,1.7,0.8,3,2.7,2.9s3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.7-2.9S351,490.8,350.6,492.5" />
				<path fill="#D51566" d="M339.8,493.3c-0.4,1.7,0.8,3,2.6,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.6-2.9
			C342.1,490.1,340.2,491.6,339.8,493.3"/>
				<path fill="#D51566" d="M329.1,494.2c-0.4,1.7,0.8,3,2.6,2.9c1.9-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.8-3-2.6-2.9
			C331.4,490.9,329.5,492.5,329.1,494.2"/>
				<path fill="#D51566" d="M504.7,473.6c-0.2,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3
			C506.7,470.4,505,471.8,504.7,473.6"/>
				<path fill="#D51566" d="M493.7,474.1c-0.2,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3
			C495.7,470.9,494,472.4,493.7,474.1"/>
				<path fill="#D51566" d="M482.8,474.6c-0.3,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3
			C484.8,471.4,483,472.9,482.8,474.6"/>
				<path fill="#D51566" d="M471.8,475.2c-0.3,1.7,1.1,3.1,3,3c1.9-0.1,3.7-1.6,3.9-3.3c0.3-1.7-1.1-3.1-3-3
			C473.9,472,472.1,473.5,471.8,475.2"/>
				<path fill="#D51566" d="M460.9,475.8c-0.3,1.7,1,3.1,2.9,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.3-1.7-1-3.1-2.9-3
			C463,472.6,461.2,474.1,460.9,475.8"/>
				<path fill="#D51566" d="M450,476.4c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,3.9-3.3c0.3-1.7-1-3.1-2.9-3
			C452.1,473.2,450.3,474.7,450,476.4"/>
				<path fill="#D51566" d="M439.1,477c-0.3,1.7,1,3,2.9,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3.1-2.9-2.9
			C441.2,473.8,439.4,475.3,439.1,477"/>
				<path fill="#D51566" d="M428.3,477.7c-0.3,1.7,1,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-1-3.1-2.8-2.9
			C430.4,474.5,428.6,476,428.3,477.7"/>
				<path fill="#D51566" d="M417.4,478.4c-0.3,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.3c0.3-1.7-0.9-3-2.8-2.9
			C419.5,475.1,417.8,476.6,417.4,478.4"/>
				<path fill="#D51566" d="M406.6,479c-0.3,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.4c0.3-1.7-0.9-3-2.8-2.9S406.9,477.3,406.6,479" />
				<path fill="#D51566" d="M395.8,479.8c-0.4,1.7,0.9,3,2.8,2.9c1.9-0.1,3.7-1.6,4-3.4c0.3-1.7-0.9-3-2.7-2.9
			C398,476.5,396.2,478,395.8,479.8"/>
				<path fill="#D51566" d="M385,480.5c-0.4,1.7,0.8,3,2.7,2.9c1.9-0.1,3.7-1.6,4-3.4c0.4-1.7-0.9-3-2.7-2.9
			C387.2,477.2,385.4,478.8,385,480.5"/>
				<path fill="#D51566" d="M374.3,481.2c-0.4,1.7,0.8,3,2.7,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.7-2.9
			C376.5,478,374.7,479.5,374.3,481.2"/>
				<path fill="#D51566" d="M363.5,482c-0.4,1.7,0.8,3,2.7,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.7-2.9
			C365.8,478.7,363.9,480.3,363.5,482"/>
				<path fill="#D51566" d="M352.8,482.8c-0.4,1.7,0.8,3,2.6,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3-2.6-2.9
			C355.1,479.5,353.3,481.1,352.8,482.8"/>
				<path fill="#D51566" d="M342.2,483.6c-0.4,1.7,0.7,3,2.6,2.9c1.9-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.7-3-2.6-2.9
			C344.4,480.3,342.6,481.9,342.2,483.6"/>
				<path fill="#D51566" d="M331.5,484.4c-0.4,1.7,0.7,3,2.6,2.9c1.9-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.7-3-2.6-2.9
			C333.8,481.2,332,482.7,331.5,484.4"/>
				<path fill="#D51566" d="M320.9,485.3c-0.4,1.7,0.7,3,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.7-3-2.5-2.9
			C323.2,482,321.4,483.5,320.9,485.3"/>
				<path fill="#D51566" d="M517,463.2c-0.2,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.8-3.3c0.2-1.7-1.1-3.1-3-3
			C518.9,460,517.2,461.5,517,463.2"/>
				<path fill="#D51566" d="M506.1,463.7c-0.2,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3S506.3,462,506.1,463.7" />
				<path fill="#D51566" d="M495.2,464.3c-0.3,1.7,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.7-1.1-3.1-3-3
			C497.2,461,495.4,462.5,495.2,464.3"/>
				<path fill="#D51566" d="M484.3,464.8c-0.3,1.7,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3c0.3-1.7-1.1-3.1-2.9-3
			C486.3,461.6,484.5,463.1,484.3,464.8"/>
				<path fill="#D51566" d="M473.4,465.4c-0.3,1.7,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3c0.3-1.7-1-3.1-2.9-3
			C475.4,462.1,473.7,463.6,473.4,465.4"/>
				<path fill="#D51566" d="M462.6,466c-0.3,1.7,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3c0.3-1.7-1-3.1-2.9-3
			C464.6,462.7,462.8,464.2,462.6,466"/>
				<path fill="#D51566" d="M451.7,466.6c-0.3,1.7,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3c0.3-1.7-1-3.1-2.8-3
			C453.8,463.3,452,464.8,451.7,466.6"/>
				<path fill="#D51566" d="M440.9,467.2c-0.3,1.7,0.9,3.1,2.8,3c1.9-0.1,3.7-1.6,4-3.4c0.3-1.7-1-3.1-2.8-3
			C443,464,441.2,465.5,440.9,467.2"/>
				<path fill="#D51566" d="M430.1,467.9c-0.3,1.7,0.9,3.1,2.8,2.9c1.9-0.1,3.7-1.6,4-3.4c0.3-1.7-0.9-3.1-2.8-3
			C432.2,464.6,430.4,466.1,430.1,467.9"/>
				<path fill="#D51566" d="M419.3,468.5c-0.3,1.7,0.9,3.1,2.8,2.9c1.9-0.1,3.7-1.6,4-3.4c0.3-1.7-0.9-3.1-2.8-2.9
			C421.5,465.3,419.7,466.8,419.3,468.5"/>
				<path fill="#D51566" d="M408.6,469.2c-0.4,1.7,0.9,3.1,2.7,2.9c1.9-0.1,3.7-1.6,4-3.4c0.4-1.7-0.9-3.1-2.7-2.9
			C410.7,466,408.9,467.5,408.6,469.2"/>
				<path fill="#D51566" d="M397.9,469.9c-0.4,1.7,0.8,3.1,2.7,2.9c1.9-0.1,3.7-1.6,4-3.4c0.4-1.7-0.8-3.1-2.7-2.9
			C400,466.7,398.2,468.2,397.9,469.9"/>
				<path fill="#D51566" d="M387.2,470.7c-0.4,1.7,0.8,3.1,2.7,2.9c1.9-0.1,3.7-1.6,4-3.4c0.4-1.7-0.8-3.1-2.7-2.9
			C389.4,467.4,387.5,468.9,387.2,470.7"/>
				<path fill="#D51566" d="M376.5,471.4c-0.4,1.7,0.8,3,2.6,2.9c1.9-0.1,3.7-1.6,4.1-3.4c0.4-1.7-0.8-3.1-2.6-2.9
			C378.7,468.1,376.9,469.7,376.5,471.4"/>
				<path fill="#D51566" d="M365.8,472.2c-0.4,1.7,0.8,3,2.6,2.9c1.9-0.1,3.7-1.7,4.1-3.4s-0.8-3.1-2.6-2.9
			C368.1,468.9,366.2,470.4,365.8,472.2"/>
				<path fill="#D51566" d="M355.2,473c-0.4,1.7,0.7,3,2.6,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.7-3-2.6-2.9
			C357.5,469.7,355.6,471.2,355.2,473"/>
				<path fill="#D51566" d="M344.6,473.8c-0.4,1.7,0.7,3,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.7-0.7-3-2.5-2.9
			C346.9,470.5,345,472,344.6,473.8"/>
				<path fill="#D51566" d="M334,474.6c-0.5,1.7,0.7,3,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4s-0.7-3-2.5-2.9
			C336.3,471.3,334.5,472.9,334,474.6"/>
				<path fill="#D51566" d="M323.5,475.4c-0.5,1.7,0.6,3,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.7-0.7-3-2.5-2.9
			C325.8,472.2,324,473.7,323.5,475.4"/>
				<path fill="#D51566" d="M529.2,452.8c-0.2,1.8,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.8-3.3c0.2-1.8-1.1-3.1-3-3
			C531.1,449.6,529.4,451.1,529.2,452.8"/>
				<path fill="#D51566" d="M518.3,453.3c-0.2,1.8,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.8-3.3c0.2-1.8-1.1-3.1-3-3
			C520.3,450.1,518.6,451.6,518.3,453.3"/>
				<path fill="#D51566" d="M507.5,453.8c-0.3,1.8,1.1,3.1,3,3c1.9-0.1,3.6-1.6,3.9-3.3c0.2-1.8-1.1-3.1-3-3
			C509.5,450.6,507.7,452.1,507.5,453.8"/>
				<path fill="#D51566" d="M496.6,454.4c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3s-1.1-3.1-2.9-3
			C498.6,451.1,496.9,452.6,496.6,454.4"/>
				<path fill="#D51566" d="M485.8,454.9c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.3c0.3-1.8-1-3.1-2.9-3
			C487.8,451.7,486.1,453.2,485.8,454.9"/>
				<path fill="#D51566" d="M475,455.5c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.9-3
			C477.1,452.2,475.3,453.7,475,455.5"/>
				<path fill="#D51566" d="M464.2,456.1c-0.3,1.8,1,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.8-3
			C466.3,452.8,464.6,454.3,464.2,456.1"/>
				<path fill="#D51566" d="M453.5,456.7c-0.3,1.8,0.9,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.8-3
			C455.6,453.4,453.8,454.9,453.5,456.7"/>
				<path fill="#D51566" d="M442.7,457.3c-0.3,1.8,0.9,3.1,2.8,3c1.9-0.1,3.6-1.6,4-3.4c0.3-1.8-0.9-3.1-2.8-3
			C444.9,454,443.1,455.6,442.7,457.3"/>
				<path fill="#D51566" d="M432,458c-0.3,1.8,0.9,3.1,2.8,3c1.9-0.1,3.6-1.6,4-3.4c0.3-1.8-0.9-3.1-2.7-3
			C434.2,454.7,432.4,456.2,432,458"/>
				<path fill="#D51566" d="M421.3,458.6c-0.4,1.8,0.9,3.1,2.7,3c1.9-0.1,3.6-1.6,4-3.4c0.4-1.8-0.9-3.1-2.7-3
			C423.5,455.4,421.7,456.9,421.3,458.6"/>
				<path fill="#D51566" d="M410.7,459.3c-0.4,1.8,0.8,3.1,2.7,3c1.9-0.1,3.7-1.6,4-3.4c0.4-1.8-0.8-3.1-2.7-3
			C412.8,456.1,411,457.6,410.7,459.3"/>
				<path fill="#D51566" d="M400,460c-0.4,1.8,0.8,3.1,2.7,2.9c1.9-0.1,3.7-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C402.2,456.8,400.4,458.3,400,460"/>
				<path fill="#D51566" d="M389.4,460.8c-0.4,1.8,0.8,3.1,2.6,2.9c1.8-0.1,3.7-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-2.9
			C391.6,457.5,389.8,459,389.4,460.8"/>
				<path fill="#D51566" d="M378.8,461.5c-0.4,1.8,0.8,3.1,2.6,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.8-3.1-2.6-2.9
			C381,458.2,379.2,459.8,378.8,461.5"/>
				<path fill="#D51566" d="M368.2,462.3c-0.4,1.8,0.7,3.1,2.6,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.6-2.9
			C370.5,459,368.6,460.5,368.2,462.3"/>
				<path fill="#D51566" d="M357.6,463.1c-0.4,1.8,0.7,3.1,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-2.9
			C359.9,459.8,358.1,461.3,357.6,463.1"/>
				<path fill="#D51566" d="M347.1,463.9c-0.5,1.8,0.7,3.1,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-2.9
			C349.4,460.6,347.6,462.1,347.1,463.9"/>
				<path fill="#D51566" d="M336.6,464.7c-0.5,1.8,0.6,3.1,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.5-2.9
			C338.9,461.4,337.1,463,336.6,464.7"/>
				<path fill="#D51566" d="M326.2,465.6c-0.5,1.8,0.6,3,2.4,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.4-2.9
			C328.5,462.3,326.6,463.8,326.2,465.6"/>
				<path fill="#D51566" d="M519.7,443.4c-0.3,1.8,1.1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.8-3.3c0.2-1.8-1.1-3.1-2.9-3
			C521.7,440.1,520,441.6,519.7,443.4"/>
				<path fill="#D51566" d="M508.9,443.9c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.8-3.4c0.3-1.8-1-3.1-2.9-3
			C510.9,440.6,509.2,442.1,508.9,443.9"/>
				<path fill="#D51566" d="M498.2,444.4c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.9-3
			C500.2,441.1,498.4,442.7,498.2,444.4"/>
				<path fill="#D51566" d="M487.4,445c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.9-3
			C489.5,441.7,487.7,443.2,487.4,445"/>
				<path fill="#D51566" d="M476.7,445.5c-0.3,1.8,1,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.8-3
			C478.8,442.3,477,443.8,476.7,445.5"/>
				<path fill="#D51566" d="M466,446.1c-0.3,1.8,0.9,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.8-3
			C468.1,442.8,466.3,444.4,466,446.1"/>
				<path fill="#D51566" d="M455.3,446.7c-0.3,1.8,0.9,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.8-3
			C457.4,443.5,455.7,445,455.3,446.7"/>
				<path fill="#D51566" d="M444.7,447.4c-0.3,1.8,0.9,3.1,2.7,3c1.9-0.1,3.6-1.6,4-3.4c0.3-1.8-0.9-3.1-2.7-3
			C446.8,444.1,445,445.6,444.7,447.4"/>
				<path fill="#D51566" d="M434,448c-0.4,1.8,0.9,3.1,2.7,3c1.8-0.1,3.6-1.6,4-3.4c0.3-1.8-0.9-3.1-2.7-3
			C436.2,444.7,434.4,446.3,434,448"/>
				<path fill="#D51566" d="M423.4,448.7c-0.4,1.8,0.8,3.1,2.7,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.7-3
			C425.6,445.4,423.8,446.9,423.4,448.7"/>
				<path fill="#D51566" d="M412.8,449.4c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C415,446.1,413.2,447.6,412.8,449.4"/>
				<path fill="#D51566" d="M402.2,450.1c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C404.4,446.8,402.6,448.3,402.2,450.1"/>
				<path fill="#D51566" d="M391.7,450.8c-0.4,1.8,0.7,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.6-3
			C393.9,447.5,392.1,449.1,391.7,450.8"/>
				<path fill="#D51566" d="M381.1,451.6c-0.4,1.8,0.7,3.1,2.5,2.9c1.8-0.1,3.6-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-3
			C383.4,448.3,381.6,449.8,381.1,451.6"/>
				<path fill="#D51566" d="M370.6,452.4c-0.4,1.8,0.7,3.1,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-2.9
			C372.9,449.1,371.1,450.6,370.6,452.4"/>
				<path fill="#D51566" d="M360.2,453.2c-0.5,1.8,0.7,3.1,2.5,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-2.9
			C362.5,449.9,360.6,451.4,360.2,453.2"/>
				<path fill="#D51566" d="M349.7,454c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.4-2.9
			C352,450.7,350.2,452.2,349.7,454"/>
				<path fill="#D51566" d="M339.3,454.8c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.4-2.9
			C341.6,451.5,339.8,453,339.3,454.8"/>
				<path fill="#D51566" d="M328.9,455.6c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.7-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.4-2.9
			C331.3,452.3,329.4,453.9,328.9,455.6"/>
				<path fill="#D51566" d="M318.6,456.5c-0.5,1.8,0.5,3.1,2.3,2.9c1.8-0.1,3.7-1.7,4.2-3.5c0.5-1.8-0.5-3.1-2.3-2.9
			C320.9,453.2,319.1,454.7,318.6,456.5"/>
				<path fill="#D51566" d="M521.1,433.4c-0.3,1.8,1,3.1,2.9,3.1c1.9-0.1,3.6-1.6,3.8-3.4c0.2-1.8-1.1-3.1-2.9-3.1
			C523.1,430.1,521.4,431.6,521.1,433.4"/>
				<path fill="#D51566" d="M510.4,433.9c-0.3,1.8,1,3.1,2.9,3c1.9-0.1,3.6-1.6,3.8-3.4c0.3-1.8-1-3.1-2.9-3.1
			C512.4,430.6,510.7,432.1,510.4,433.9"/>
				<path fill="#D51566" d="M499.8,434.4c-0.3,1.8,1,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.8-3
			C501.8,431.1,500,432.6,499.8,434.4"/>
				<path fill="#D51566" d="M489.1,435c-0.3,1.8,1,3.1,2.8,3c1.9-0.1,3.6-1.6,3.9-3.4c0.3-1.8-1-3.1-2.8-3
			C491.1,431.7,489.4,433.2,489.1,435"/>
				<path fill="#D51566" d="M478.5,435.5c-0.3,1.8,0.9,3.1,2.8,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.8-3
			C480.5,432.3,478.8,433.8,478.5,435.5"/>
				<path fill="#D51566" d="M467.8,436.1c-0.3,1.8,0.9,3.1,2.8,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C469.9,432.8,468.2,434.4,467.8,436.1"/>
				<path fill="#D51566" d="M457.2,436.8c-0.3,1.8,0.9,3.1,2.7,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C459.3,433.5,457.6,435,457.2,436.8"/>
				<path fill="#D51566" d="M446.6,437.4c-0.4,1.8,0.8,3.1,2.7,3c1.8-0.1,3.6-1.6,4-3.4c0.3-1.8-0.9-3.1-2.7-3
			C448.8,434.1,447,435.6,446.6,437.4"/>
				<path fill="#D51566" d="M436.1,438c-0.4,1.8,0.8,3.1,2.7,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.7-3
			C438.2,434.7,436.4,436.3,436.1,438"/>
				<path fill="#D51566" d="M425.5,438.7c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C427.7,435.4,425.9,436.9,425.5,438.7"/>
				<path fill="#D51566" d="M415,439.4c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C417.2,436.1,415.4,437.6,415,439.4"/>
				<path fill="#D51566" d="M404.5,440.1c-0.4,1.8,0.7,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.6-3
			C406.7,436.8,404.9,438.4,404.5,440.1"/>
				<path fill="#D51566" d="M394,440.9c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C396.3,437.6,394.5,439.1,394,440.9"/>
				<path fill="#D51566" d="M383.6,441.6c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-3
			C385.9,438.3,384,439.9,383.6,441.6"/>
				<path fill="#D51566" d="M373.2,442.4c-0.5,1.8,0.6,3.1,2.5,3c1.8-0.1,3.6-1.7,4.1-3.4c0.4-1.8-0.7-3.1-2.5-3
			C375.5,439.1,373.6,440.6,373.2,442.4"/>
				<path fill="#D51566" d="M362.8,443.2c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.6-1.7,4.1-3.4c0.5-1.8-0.6-3.1-2.4-3
			C365.1,439.9,363.2,441.4,362.8,443.2"/>
				<path fill="#D51566" d="M352.4,444c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-2.9
			C354.7,440.7,352.9,442.2,352.4,444"/>
				<path fill="#D51566" d="M342.1,444.8c-0.5,1.8,0.6,3.1,2.4,2.9c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-2.9
			C344.4,441.5,342.6,443.1,342.1,444.8"/>
				<path fill="#D51566" d="M331.8,445.7c-0.5,1.8,0.5,3.1,2.3,2.9c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-2.9
			C334.1,442.4,332.3,443.9,331.8,445.7"/>
				<path fill="#D51566" d="M480.3,425.5c-0.3,1.8,0.9,3.1,2.7,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C482.3,422.2,480.6,423.7,480.3,425.5"/>
				<path fill="#D51566" d="M469.7,426.1c-0.3,1.8,0.9,3.1,2.7,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C471.8,422.8,470.1,424.3,469.7,426.1"/>
				<path fill="#D51566" d="M459.2,426.7c-0.4,1.8,0.8,3.1,2.7,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C461.3,423.4,459.5,424.9,459.2,426.7"/>
				<path fill="#D51566" d="M448.7,427.4c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C450.8,424,449,425.6,448.7,427.4"/>
				<path fill="#D51566" d="M438.2,428c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C440.4,424.7,438.6,426.2,438.2,428"/>
				<path fill="#D51566" d="M427.7,428.7c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C429.9,425.4,428.1,426.9,427.7,428.7"/>
				<path fill="#D51566" d="M417.3,429.4c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C419.5,426.1,417.7,427.6,417.3,429.4"/>
				<path fill="#D51566" d="M406.9,430.1c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C409.1,426.8,407.3,428.3,406.9,430.1"/>
				<path fill="#D51566" d="M396.5,430.8c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C398.7,427.5,396.9,429.1,396.5,430.8"/>
				<path fill="#D51566" d="M386.1,431.6c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.4-1.8-0.7-3.1-2.4-3
			C388.4,428.3,386.6,429.8,386.1,431.6"/>
				<path fill="#D51566" d="M375.8,432.4c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-3
			C378.1,429.1,376.2,430.6,375.8,432.4"/>
				<path fill="#D51566" d="M365.5,433.2c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-3
			C367.8,429.8,365.9,431.4,365.5,433.2"/>
				<path fill="#D51566" d="M355.2,434c-0.5,1.8,0.6,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.3-3
			C357.5,430.7,355.7,432.2,355.2,434"/>
				<path fill="#D51566" d="M344.9,434.8c-0.5,1.8,0.5,3.1,2.3,2.9c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-3
			C347.3,431.5,345.4,433,344.9,434.8"/>
				<path fill="#D51566" d="M334.7,435.7c-0.5,1.8,0.5,3.1,2.3,2.9c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-2.9
			C337.1,432.3,335.2,433.9,334.7,435.7"/>
				<path fill="#D51566" d="M471.7,416c-0.3,1.8,0.8,3.1,2.7,3c1.8-0.1,3.6-1.6,3.9-3.4c0.3-1.8-0.9-3.1-2.7-3
			C473.8,412.7,472,414.3,471.7,416"/>
				<path fill="#D51566" d="M461.2,416.7c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,3.9-3.4c0.4-1.8-0.8-3.1-2.6-3
			C463.3,413.3,461.6,414.9,461.2,416.7"/>
				<path fill="#D51566" d="M450.8,417.3c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,3.9-3.4c0.4-1.8-0.8-3.1-2.6-3
			C452.9,414,451.2,415.5,450.8,417.3"/>
				<path fill="#D51566" d="M440.4,418c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.8-3.1-2.6-3
			C442.6,414.6,440.8,416.2,440.4,418"/>
				<path fill="#D51566" d="M430,418.6c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C432.2,415.3,430.4,416.8,430,418.6"/>
				<path fill="#D51566" d="M419.6,419.3c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C421.9,416,420.1,417.5,419.6,419.3"/>
				<path fill="#D51566" d="M409.3,420c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.7-3.1-2.5-3
			C411.5,416.7,409.7,418.3,409.3,420"/>
				<path fill="#D51566" d="M399,420.8c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.6-3.1-2.4-3
			C401.3,417.5,399.4,419,399,420.8"/>
				<path fill="#D51566" d="M388.7,421.5c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-3
			C391,418.2,389.2,419.8,388.7,421.5"/>
				<path fill="#D51566" d="M378.4,422.3c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.4-3
			C380.8,419,378.9,420.5,378.4,422.3"/>
				<path fill="#D51566" d="M368.2,423.1c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.3-3
			C370.5,419.8,368.7,421.3,368.2,423.1"/>
				<path fill="#D51566" d="M358,423.9c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-3
			C360.4,420.6,358.5,422.2,358,423.9"/>
				<path fill="#D51566" d="M347.8,424.8c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-3
			C350.2,421.4,348.3,423,347.8,424.8"/>
				<path fill="#D51566" d="M453,407.2c-0.4,1.8,0.8,3.1,2.6,3c1.8-0.1,3.6-1.6,3.9-3.4c0.4-1.8-0.8-3.2-2.6-3
			C455.1,403.9,453.3,405.4,453,407.2"/>
				<path fill="#D51566" d="M442.6,407.9c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.4c0.4-1.8-0.7-3.1-2.5-3
			C444.8,404.5,443,406.1,442.6,407.9"/>
				<path fill="#D51566" d="M432.3,408.5c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.7-3.1-2.5-3
			C434.5,405.2,432.8,406.7,432.3,408.5"/>
				<path fill="#D51566" d="M422.1,409.2c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.7-3.1-2.4-3
			C424.3,405.9,422.5,407.4,422.1,409.2"/>
				<path fill="#D51566" d="M411.8,410c-0.4,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.6-3.1-2.4-3
			C414,406.6,412.2,408.2,411.8,410"/>
				<path fill="#D51566" d="M401.6,410.7c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.5-1.8-0.6-3.1-2.4-3
			C403.8,407.4,402,408.9,401.6,410.7"/>
				<path fill="#D51566" d="M391.4,411.5c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.3-3
			C393.7,408.1,391.8,409.7,391.4,411.5"/>
				<path fill="#D51566" d="M381.2,412.2c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.6-3.1-2.3-3
			C383.5,408.9,381.7,410.5,381.2,412.2"/>
				<path fill="#D51566" d="M371,413c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-3
			C373.4,409.7,371.5,411.3,371,413"/>
				<path fill="#D51566" d="M360.9,413.9c-0.5,1.8,0.5,3.1,2.2,3c1.8-0.1,3.6-1.7,4.1-3.5s-0.5-3.1-2.2-3
			C363.3,410.5,361.4,412.1,360.9,413.9"/>
				<path fill="#D51566" d="M350.8,414.7c-0.5,1.8,0.5,3.1,2.2,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.2-3
			C353.2,411.3,351.3,412.9,350.8,414.7"/>
				<path fill="#D51566" d="M340.7,415.5c-0.6,1.8,0.4,3.1,2.2,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.4-3.1-2.2-3
			C343.1,412.2,341.3,413.8,340.7,415.5"/>
				<path fill="#D51566" d="M320.7,417.3c-0.6,1.8,0.4,3.1,2.1,2.9c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.4-3.1-2.1-3
			C323.1,414,321.3,415.5,320.7,417.3"/>
				<path fill="#D51566" d="M444.9,397.7c-0.4,1.8,0.7,3.1,2.5,3c1.8-0.1,3.5-1.7,4-3.5c0.4-1.8-0.7-3.2-2.5-3
			C447.1,394.4,445.4,395.9,444.9,397.7"/>
				<path fill="#D51566" d="M434.7,398.4c-0.4,1.8,0.7,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.7-3.2-2.4-3
			C436.9,395.1,435.2,396.6,434.7,398.4"/>
				<path fill="#D51566" d="M424.5,399.1c-0.4,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.6-3.1-2.4-3
			C426.8,395.8,425,397.3,424.5,399.1"/>
				<path fill="#D51566" d="M414.4,399.8c-0.5,1.8,0.6,3.1,2.4,3c1.8-0.1,3.6-1.7,4-3.5c0.4-1.8-0.6-3.1-2.4-3
			C416.6,396.5,414.8,398,414.4,399.8"/>
				<path fill="#D51566" d="M404.2,400.6c-0.5,1.8,0.6,3.1,2.3,3c1.8-0.1,3.6-1.7,4-3.5c0.5-1.8-0.6-3.1-2.3-3
			C406.5,397.2,404.7,398.8,404.2,400.6"/>
				<path fill="#D51566" d="M394.1,401.3c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4-3.5c0.5-1.8-0.6-3.1-2.3-3
			C396.4,398,394.6,399.6,394.1,401.3"/>
				<path fill="#D51566" d="M384,402.1c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.3-3
			C386.3,398.8,384.5,400.3,384,402.1"/>
				<path fill="#D51566" d="M373.9,402.9c-0.5,1.8,0.5,3.1,2.2,3c1.8-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.2-3
			C376.3,399.6,374.4,401.1,373.9,402.9"/>
				<path fill="#D51566" d="M363.9,403.7c-0.5,1.8,0.5,3.1,2.2,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.2-3
			C366.3,400.4,364.4,402,363.9,403.7"/>
				<path fill="#D51566" d="M353.9,404.6c-0.5,1.8,0.4,3.1,2.2,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.4-3.1-2.2-3
			C356.3,401.2,354.4,402.8,353.9,404.6"/>
				<path fill="#D51566" d="M324,407.2c-0.6,1.8,0.3,3.1,2.1,3c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.3-3.1-2.1-3
			C326.5,403.8,324.6,405.4,324,407.2"/>
				<path fill="#D51566" d="M304.2,409.1c-0.6,1.8,0.3,3.1,2,2.9c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.3-3.1-2-2.9
			C306.7,405.7,304.9,407.3,304.2,409.1"/>
				<path fill="#D51566" d="M417,389.7c-0.5,1.8,0.6,3.1,2.3,3c1.8-0.1,3.5-1.7,4-3.5c0.5-1.8-0.6-3.1-2.3-3
			C419.2,386.3,417.5,387.9,417,389.7"/>
				<path fill="#D51566" d="M406.9,390.4c-0.5,1.8,0.5,3.1,2.3,3c1.8-0.1,3.6-1.7,4-3.5c0.5-1.8-0.5-3.1-2.3-3
			C409.2,387.1,407.4,388.7,406.9,390.4"/>
				<path fill="#D51566" d="M396.9,391.2c-0.5,1.8,0.5,3.1,2.3,3s3.6-1.7,4-3.5c0.5-1.8-0.5-3.1-2.3-3
			C399.2,387.9,397.4,389.4,396.9,391.2"/>
				<path fill="#D51566" d="M386.9,392c-0.5,1.8,0.5,3.1,2.2,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.2-3
			C389.2,388.6,387.4,390.2,386.9,392"/>
				<path fill="#D51566" d="M376.9,392.8c-0.5,1.8,0.4,3.1,2.2,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.5-3.1-2.2-3
			C379.2,389.4,377.4,391,376.9,392.8"/>
				<path fill="#D51566" d="M366.9,393.6c-0.5,1.8,0.4,3.1,2.2,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.4-3.1-2.1-3
			C369.3,390.3,367.5,391.8,366.9,393.6"/>
				<path fill="#D51566" d="M357,394.5c-0.6,1.8,0.4,3.1,2.1,3c1.7-0.1,3.6-1.7,4.1-3.5c0.5-1.8-0.4-3.1-2.1-3
			C359.4,391.1,357.6,392.7,357,394.5"/>
				<path fill="#D51566" d="M307.8,398.9c-0.6,1.8,0.2,3.1,1.9,2.9c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.9-3
			C310.3,395.6,308.4,397.1,307.8,398.9"/>
				<path fill="#D51566" d="M321.1,387.9c-0.6,1.8,0.2,3.1,1.9,3c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.9-3
			C323.6,384.5,321.7,386.1,321.1,387.9"/>
				<path fill="#D51566" d="M311.4,388.8c-0.6,1.8,0.2,3.1,1.9,3c1.7-0.2,3.6-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.9-3
			C313.9,385.4,312,387,311.4,388.8"/>
				<path fill="#D51566" d="M301.7,389.8c-0.7,1.8,0.2,3.1,1.9,2.9c1.7-0.2,3.6-1.8,4.2-3.5c0.6-1.8-0.2-3.1-1.8-2.9
			C304.3,386.4,302.4,388,301.7,389.8"/>
				<path fill="#D51566" d="M292.1,390.7c-0.7,1.8,0.1,3.1,1.8,2.9c1.7-0.2,3.6-1.8,4.2-3.5c0.7-1.8-0.1-3.1-1.8-2.9
			C294.7,387.3,292.8,388.9,292.1,390.7"/>
				<path fill="#D51566" d="M282.5,391.7c-0.7,1.8,0.1,3.1,1.8,2.9c1.7-0.2,3.6-1.8,4.2-3.6c0.7-1.8-0.1-3.1-1.8-2.9
			C285.1,388.3,283.2,389.9,282.5,391.7"/>
				<path fill="#D51566" d="M272.9,392.7c-0.7,1.8,0.1,3.1,1.7,2.9c1.7-0.2,3.6-1.8,4.3-3.6c0.7-1.8-0.1-3.1-1.7-2.9
			C275.5,389.3,273.6,390.9,272.9,392.7"/>
				<path fill="#D51566" d="M334.3,376.8c-0.6,1.8,0.2,3.1,1.9,3c1.7-0.2,3.5-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.9-3
			C336.8,373.4,335,375,334.3,376.8"/>
				<path fill="#D51566" d="M315.1,378.7c-0.7,1.8,0.2,3.1,1.8,3c1.7-0.2,3.5-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.8-3
			C317.6,375.3,315.7,376.9,315.1,378.7"/>
				<path fill="#D51566" d="M305.5,379.6c-0.7,1.8,0.1,3.1,1.8,2.9c1.7-0.2,3.5-1.8,4.2-3.5c0.7-1.8-0.1-3.1-1.8-3
			C308,376.2,306.2,377.8,305.5,379.6"/>
				<path fill="#D51566" d="M295.9,380.6c-0.7,1.8,0.1,3.1,1.8,2.9c1.7-0.2,3.6-1.8,4.2-3.6c0.7-1.8-0.1-3.1-1.8-2.9
			C298.5,377.2,296.6,378.8,295.9,380.6"/>
				<path fill="#D51566" d="M286.4,381.6c-0.7,1.8,0.1,3.1,1.7,2.9c1.7-0.2,3.6-1.8,4.2-3.6c0.7-1.8-0.1-3.1-1.7-2.9
			C289,378.2,287.1,379.8,286.4,381.6"/>
				<path fill="#D51566" d="M276.9,382.6c-0.7,1.8,0,3.1,1.7,2.9c1.6-0.2,3.6-1.8,4.3-3.6c0.7-1.8-0.1-3.1-1.7-2.9
			C279.6,379.2,277.6,380.8,276.9,382.6"/>
				<path fill="#D51566" d="M267.5,383.6c-0.7,1.8,0,3.1,1.7,2.9c1.6-0.2,3.6-1.8,4.3-3.6c0.7-1.8,0-3.1-1.7-2.9
			C270.1,380.2,268.2,381.8,267.5,383.6"/>
				<path fill="#D51566" d="M258.1,384.6c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.6-1.8,4.3-3.6c0.7-1.8,0-3.1-1.6-2.9
			C260.7,381.2,258.8,382.8,258.1,384.6"/>
				<path fill="#D51566" d="M248.7,385.7c-0.8,1.8,0,3.1,1.6,2.9c1.6-0.2,3.6-1.8,4.3-3.6c0.7-1.8,0-3.1-1.6-2.9
			C251.4,382.3,249.4,383.9,248.7,385.7"/>
				<path fill="#D51566" d="M239.3,386.7c-0.8,1.8-0.1,3.1,1.5,2.9s3.6-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C242,383.4,240.1,385,239.3,386.7"/>
				<path fill="#D51566" d="M230,387.8c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.6-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C232.8,384.4,230.8,386,230,387.8"/>
				<path fill="#D51566" d="M395.9,361.5c-0.5,1.8,0.4,3.1,2.1,3c1.7-0.1,3.5-1.7,4-3.5c0.5-1.8-0.4-3.1-2.1-3
			C398.2,358.1,396.4,359.7,395.9,361.5"/>
				<path fill="#D51566" d="M376.4,363.2c-0.6,1.8,0.3,3.1,2,3c1.7-0.1,3.5-1.7,4.1-3.5c0.6-1.8-0.3-3.1-2-3
			C378.8,359.8,377,361.4,376.4,363.2"/>
				<path fill="#D51566" d="M366.8,364c-0.6,1.8,0.3,3.1,2,3c1.7-0.1,3.5-1.7,4.1-3.5c0.6-1.8-0.3-3.1-2-3
			C369.2,360.6,367.3,362.2,366.8,364"/>
				<path fill="#D51566" d="M357.1,364.9c-0.6,1.8,0.3,3.1,1.9,3c1.7-0.2,3.5-1.7,4.1-3.5c0.6-1.8-0.3-3.1-1.9-3
			C359.6,361.5,357.7,363.1,357.1,364.9"/>
				<path fill="#D51566" d="M337.9,366.6c-0.6,1.8,0.2,3.1,1.9,3c1.7-0.2,3.5-1.7,4.2-3.5c0.6-1.8-0.2-3.1-1.9-3
			C340.4,363.3,338.5,364.9,337.9,366.6"/>
				<path fill="#D51566" d="M309.3,369.5c-0.7,1.8,0.1,3.1,1.8,3c1.7-0.2,3.5-1.8,4.2-3.6c0.7-1.8-0.1-3.1-1.8-3
			C311.9,366.1,310,367.7,309.3,369.5"/>
				<path fill="#D51566" d="M299.8,370.4c-0.7,1.8,0.1,3.1,1.7,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.7-1.8-0.1-3.1-1.7-2.9
			S300.5,368.6,299.8,370.4"/>
				<path fill="#D51566" d="M262.3,374.5c-0.8,1.8,0,3.1,1.6,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.7-1.8,0-3.1-1.6-2.9
			C265,371.1,263,372.7,262.3,374.5"/>
				<path fill="#D51566" d="M253,375.5c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C255.7,372.2,253.8,373.8,253,375.5"/>
				<path fill="#D51566" d="M243.7,376.6c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C246.4,373.2,244.5,374.8,243.7,376.6"/>
				<path fill="#D51566" d="M234.5,377.7c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C237.2,374.3,235.3,375.9,234.5,377.7"/>
				<path fill="#D51566" d="M418.3,349.8c-0.5,1.8,0.4,3.2,2.1,3c1.7-0.1,3.5-1.7,4-3.5c0.5-1.8-0.4-3.2-2.1-3
			C420.6,346.4,418.8,348,418.3,349.8"/>
				<path fill="#D51566" d="M408.6,350.6c-0.5,1.8,0.4,3.1,2.1,3c1.7-0.1,3.5-1.7,4-3.5c0.5-1.8-0.4-3.1-2.1-3
			C411,347.2,409.2,348.8,408.6,350.6"/>
				<path fill="#D51566" d="M379.7,353c-0.6,1.8,0.3,3.1,2,3c1.7-0.1,3.5-1.7,4.1-3.5c0.6-1.8-0.3-3.1-2-3
			C382.1,349.6,380.3,351.2,379.7,353"/>
				<path fill="#D51566" d="M370.1,353.8c-0.6,1.8,0.3,3.1,1.9,3c1.7-0.1,3.5-1.7,4.1-3.5c0.6-1.8-0.3-3.1-1.9-3
			C372.6,350.5,370.7,352,370.1,353.8"/>
				<path fill="#D51566" d="M294.4,361.3c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.7-1.8,0-3.1-1.6-2.9
			C297.1,357.9,295.2,359.5,294.4,361.3"/>
				<path fill="#D51566" d="M266.6,364.4c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C269.3,361,267.3,362.6,266.6,364.4"/>
				<path fill="#D51566" d="M257.4,365.4c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C260.1,362,258.1,363.6,257.4,365.4"/>
				<path fill="#D51566" d="M248.2,366.5c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C250.9,363.1,249,364.7,248.2,366.5"/>
				<path fill="#D51566" d="M239,367.6c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C241.8,364.2,239.8,365.8,239,367.6"/>
				<path fill="#D51566" d="M211.8,370.9c-0.8,1.8-0.3,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.4-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C214.6,367.5,212.6,369.1,211.8,370.9"/>
				<path fill="#D51566" d="M243.6,357.4c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C246.4,354,244.4,355.6,243.6,357.4"/>
				<path fill="#D51566" d="M234.6,358.5c-0.8,1.8-0.2,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C237.3,355.1,235.4,356.8,234.6,358.5"/>
				<path fill="#D51566" d="M225.6,359.7c-0.8,1.8-0.3,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C228.4,356.3,226.4,357.9,225.6,359.7"/>
				<path fill="#D51566" d="M443.4,328c-0.5,1.8,0.4,3.2,2.1,3c1.7-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.2-2.1-3
			C445.6,324.6,443.9,326.2,443.4,328"/>
				<path fill="#D51566" d="M433.8,328.7c-0.5,1.8,0.4,3.2,2.1,3c1.7-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.2-2.1-3
			C436.1,325.3,434.4,326.9,433.8,328.7"/>
				<path fill="#D51566" d="M424.3,329.4c-0.5,1.8,0.4,3.1,2,3c1.7-0.1,3.4-1.7,4-3.5c0.5-1.8-0.4-3.1-2-3
			C426.6,326.1,424.8,327.6,424.3,329.4"/>
				<path fill="#D51566" d="M414.8,330.2c-0.6,1.8,0.3,3.1,2,3c1.7-0.1,3.4-1.7,4-3.5c0.5-1.8-0.3-3.1-2-3
			C417.1,326.9,415.4,328.4,414.8,330.2"/>
				<path fill="#D51566" d="M239.3,348.4c-0.8,1.8-0.3,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C242.1,345,240.1,346.6,239.3,348.4"/>
				<path fill="#D51566" d="M230.4,349.5c-0.9,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C233.1,346.1,231.2,347.8,230.4,349.5"/>
				<path fill="#D51566" d="M221.5,350.7c-0.9,1.8-0.3,3.1,1.2,2.9s3.5-1.8,4.3-3.6c0.9-1.8,0.3-3.1-1.2-2.9
			C224.3,347.3,222.3,348.9,221.5,350.7"/>
				<path fill="#D51566" d="M203.8,353c-0.9,1.8-0.4,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.4-3.6c0.9-1.8,0.4-3.1-1.2-2.9
			C206.7,349.6,204.7,351.2,203.8,353"/>
				<path fill="#D51566" d="M455.7,317.1c-0.5,1.8,0.4,3.2,2.1,3c1.7-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.5-3.2-2.1-3
			C458,313.7,456.2,315.3,455.7,317.1"/>
				<path fill="#D51566" d="M446.3,317.8c-0.5,1.8,0.4,3.1,2.1,3c1.6-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.1-2.1-3
			C448.5,314.5,446.8,316,446.3,317.8"/>
				<path fill="#D51566" d="M436.8,318.5c-0.5,1.8,0.4,3.1,2,3c1.6-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.1-2-3
			C439.1,315.2,437.3,316.7,436.8,318.5"/>
				<path fill="#D51566" d="M427.4,319.3c-0.5,1.8,0.3,3.1,2,3c1.6-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.1-2-3
			C429.7,315.9,427.9,317.5,427.4,319.3"/>
				<path fill="#D51566" d="M235.2,339.4c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C238,336,236.1,337.6,235.2,339.4"/>
				<path fill="#D51566" d="M226.4,340.6c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6s0.3-3.1-1.2-2.9
			C229.2,337.2,227.3,338.8,226.4,340.6"/>
				<path fill="#D51566" d="M217.6,341.7c-0.9,1.8-0.4,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.2-2.9
			C220.4,338.3,218.5,339.9,217.6,341.7"/>
				<path fill="#D51566" d="M208.9,342.9c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.5-1.8,4.4-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C211.7,339.5,209.8,341.1,208.9,342.9"/>
				<path fill="#D51566" d="M458.5,307c-0.5,1.8,0.4,3.1,2.1,3c1.6-0.1,3.4-1.7,3.8-3.5c0.5-1.8-0.4-3.1-2-3
			C460.8,303.6,459.1,305.2,458.5,307"/>
				<path fill="#D51566" d="M449.2,307.7c-0.5,1.8,0.4,3.1,2,3c1.6-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.4-3.1-2-3
			C451.4,304.3,449.7,305.9,449.2,307.7"/>
				<path fill="#D51566" d="M430.5,309.2c-0.6,1.8,0.3,3.1,1.9,3c1.6-0.1,3.4-1.7,3.9-3.5c0.5-1.8-0.3-3.1-1.9-3
			C432.8,305.8,431,307.4,430.5,309.2"/>
				<path fill="#D51566" d="M222.6,331.6c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C225.5,328.2,223.5,329.8,222.6,331.6"/>
				<path fill="#D51566" d="M214,332.8c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.5-1.8,4.4-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C216.8,329.4,214.9,331,214,332.8"/>
				<path fill="#D51566" d="M205.3,334c-0.9,1.8-0.4,3.1,1,2.9c1.5-0.2,3.5-1.8,4.4-3.6c0.9-1.8,0.4-3.1-1-2.9
			C208.2,330.6,206.3,332.2,205.3,334"/>
				<path fill="#D51566" d="M196.8,335.2c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.5-1.8,4.4-3.6c0.9-1.8,0.5-3.1-1-2.9
			C199.7,331.8,197.7,333.4,196.8,335.2"/>
				<path fill="#D51566" d="M188.2,336.4c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.4-3.6c0.9-1.8,0.5-3.1-1-2.8
			C191.1,333,189.2,334.6,188.2,336.4"/>
			</g>
			<g className={classNames("g-apac", { 'g-map-active': highlight === 'apac' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('apac')}>
				<path fill="#FAA634" d="M1423.4,616.2c0.6,1.6,2.6,3.1,4.5,3.3s2.9-0.9,2.3-2.6c-0.6-1.6-2.6-3.1-4.5-3.3S1422.8,614.6,1423.4,616.2"
				/>
				<path fill="#FAA634" d="M1412.5,615c0.6,1.6,2.6,3.1,4.5,3.3s2.9-0.9,2.3-2.6c-0.6-1.6-2.6-3.1-4.5-3.3
		C1413,612.2,1411.9,613.4,1412.5,615"/>
				<path fill="#FAA634" d="M1401.6,613.8c0.6,1.6,2.6,3.1,4.4,3.3c1.9,0.2,3-0.9,2.4-2.6c-0.6-1.6-2.6-3.1-4.5-3.3
		C1402.1,611.1,1401.1,612.2,1401.6,613.8"/>
				<path fill="#FAA634" d="M1390.7,612.7c0.5,1.6,2.5,3.1,4.4,3.3s3-0.9,2.4-2.5s-2.5-3.1-4.4-3.3C1391.3,609.9,1390.2,611,1390.7,612.7"
				/>
				<path fill="#FAA634" d="M1379.8,611.5c0.5,1.6,2.5,3.1,4.4,3.3s3-0.9,2.5-2.5s-2.5-3.1-4.4-3.3S1379.3,609.9,1379.8,611.5" />
				<path fill="#FAA634" d="M1430.6,608.2c0.6,1.6,2.7,3.1,4.5,3.3c1.9,0.2,2.8-0.9,2.2-2.6c-0.7-1.6-2.7-3.1-4.5-3.4
		C1430.9,605.4,1429.9,606.6,1430.6,608.2"/>
				<path fill="#FAA634" d="M1419.9,607c0.6,1.6,2.6,3.1,4.5,3.3s2.9-0.9,2.2-2.6c-0.6-1.6-2.7-3.1-4.5-3.3
		C1420.3,604.2,1419.3,605.4,1419.9,607"/>
				<path fill="#FAA634" d="M1409.2,605.8c0.6,1.6,2.6,3.1,4.5,3.3s2.9-0.9,2.3-2.6c-0.6-1.6-2.6-3.1-4.5-3.3S1408.5,604.2,1409.2,605.8"
				/>
				<path fill="#FAA634" d="M1398.4,604.7c0.6,1.6,2.6,3.1,4.4,3.3c1.9,0.2,2.9-1,2.3-2.6s-2.6-3.1-4.5-3.3
		C1398.8,601.9,1397.8,603,1398.4,604.7"/>
				<path fill="#FAA634" d="M1387.6,603.5c0.6,1.6,2.5,3.1,4.4,3.3s2.9-1,2.4-2.6c-0.6-1.6-2.6-3.1-4.4-3.3
		C1388.1,600.7,1387,601.9,1387.6,603.5"/>
				<path fill="#FAA634" d="M1376.8,602.4c0.5,1.6,2.5,3.1,4.4,3.3s3-1,2.4-2.6s-2.5-3.1-4.4-3.3C1377.3,599.6,1376.2,600.8,1376.8,602.4"
				/>
				<path fill="#FAA634" d="M1365.9,601.3c0.5,1.6,2.5,3.1,4.4,3.3s3-1,2.4-2.6c-0.5-1.6-2.5-3.1-4.4-3.3
		C1366.5,598.5,1365.4,599.6,1365.9,601.3"/>
				<path fill="#FAA634" d="M1437.5,600.1c0.7,1.7,2.7,3.2,4.6,3.4s2.8-1,2.1-2.6c-0.7-1.7-2.7-3.2-4.6-3.4
		C1437.7,597.2,1436.8,598.4,1437.5,600.1"/>
				<path fill="#FAA634" d="M1426.9,598.9c0.7,1.7,2.7,3.2,4.5,3.4s2.8-1,2.1-2.6c-0.7-1.7-2.7-3.2-4.5-3.4
		C1427.2,596.1,1426.2,597.2,1426.9,598.9"/>
				<path fill="#FAA634" d="M1416.3,597.7c0.6,1.6,2.7,3.1,4.5,3.3s2.8-1,2.2-2.6c-0.7-1.6-2.7-3.2-4.5-3.4
		C1416.6,594.9,1415.7,596.1,1416.3,597.7"/>
				<path fill="#FAA634" d="M1405.7,596.6c0.6,1.6,2.6,3.1,4.5,3.3c1.8,0.2,2.8-1,2.2-2.6c-0.6-1.6-2.6-3.2-4.5-3.3
		C1406,593.7,1405.1,594.9,1405.7,596.6"/>
				<path fill="#FAA634" d="M1395,595.4c0.6,1.6,2.6,3.1,4.5,3.3s2.9-1,2.2-2.6c-0.6-1.6-2.6-3.1-4.5-3.3
		C1395.4,592.6,1394.4,593.8,1395,595.4"/>
				<path fill="#FAA634" d="M1384.3,594.3c0.6,1.6,2.6,3.1,4.4,3.3c1.9,0.2,2.9-1,2.3-2.6s-2.6-3.1-4.4-3.3S1383.7,592.6,1384.3,594.3" />
				<path fill="#FAA634" d="M1373.6,593.2c0.6,1.6,2.5,3.1,4.4,3.3s2.9-1,2.3-2.6s-2.6-3.1-4.4-3.3S1373,591.5,1373.6,593.2" />
				<path fill="#FAA634" d="M1362.8,592.1c0.6,1.6,2.5,3.1,4.4,3.3s2.9-1,2.4-2.6c-0.6-1.6-2.5-3.1-4.4-3.3S1362.3,590.4,1362.8,592.1" />
				<path fill="#FAA634" d="M1275.7,583.9c0.4,1.6,2.3,3.1,4.2,3.2c1.9,0.2,3.1-1,2.7-2.6s-2.3-3.1-4.2-3.2
		C1276.5,581.1,1275.3,582.3,1275.7,583.9"/>
				<path fill="#FAA634" d="M1433.5,590.7c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.6c-0.7-1.7-2.8-3.2-4.6-3.4
		C1433.7,587.8,1432.8,589,1433.5,590.7"/>
				<path fill="#FAA634" d="M1423.1,589.5c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.6c-0.7-1.7-2.7-3.2-4.5-3.4
		C1423.3,586.7,1422.4,587.9,1423.1,589.5"/>
				<path fill="#FAA634" d="M1412.6,588.4c0.7,1.7,2.7,3.2,4.5,3.4s2.8-1,2.1-2.6c-0.7-1.7-2.7-3.2-4.5-3.4
		C1412.9,585.5,1411.9,586.7,1412.6,588.4"/>
				<path fill="#FAA634" d="M1402.1,587.2c0.6,1.7,2.6,3.2,4.5,3.4c1.8,0.2,2.8-1,2.1-2.6c-0.7-1.7-2.7-3.2-4.5-3.4
		C1402.4,584.4,1401.4,585.6,1402.1,587.2"/>
				<path fill="#FAA634" d="M1391.5,586.1c0.6,1.7,2.6,3.1,4.5,3.3c1.8,0.2,2.8-1,2.2-2.6c-0.6-1.7-2.6-3.2-4.5-3.4
		C1391.9,583.3,1390.9,584.4,1391.5,586.1"/>
				<path fill="#FAA634" d="M1380.9,585c0.6,1.7,2.6,3.1,4.4,3.3s2.8-1,2.2-2.6c-0.6-1.7-2.6-3.2-4.4-3.3
		C1381.3,582.1,1380.3,583.3,1380.9,585"/>
				<path fill="#FAA634" d="M1370.3,583.9c0.6,1.6,2.6,3.1,4.4,3.3s2.9-1,2.3-2.6s-2.6-3.1-4.4-3.3C1370.7,581,1369.7,582.2,1370.3,583.9"
				/>
				<path fill="#FAA634" d="M1359.7,582.8c0.6,1.6,2.5,3.1,4.4,3.3s2.9-1,2.3-2.6s-2.6-3.1-4.4-3.3C1360.1,580,1359.1,581.2,1359.7,582.8"
				/>
				<path fill="#FAA634" d="M1349,581.7c0.6,1.6,2.5,3.1,4.4,3.3s2.9-1,2.3-2.6s-2.5-3.1-4.4-3.3C1349.5,578.9,1348.4,580.1,1349,581.7" />
				<path fill="#FAA634" d="M1338.2,580.7c0.5,1.6,2.5,3.1,4.4,3.3s2.9-1,2.4-2.6c-0.6-1.6-2.5-3.1-4.4-3.3
		C1338.8,577.9,1337.7,579,1338.2,580.7"/>
				<path fill="#FAA634" d="M1316.7,578.6c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.6s-2.5-3.1-4.3-3.3C1317.3,575.8,1316.2,577,1316.7,578.6" />
				<path fill="#FAA634" d="M1305.9,577.6c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.6s-2.4-3.1-4.3-3.3S1305.4,576,1305.9,577.6" />
				<path fill="#FAA634" d="M1295,576.6c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.6s-2.4-3.1-4.3-3.3C1295.7,573.8,1294.5,575,1295,576.6" />
				<path fill="#FAA634" d="M1284.1,575.7c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.6-2.6c-0.5-1.6-2.4-3.1-4.3-3.3
		C1284.8,572.9,1283.7,574.1,1284.1,575.7"/>
				<path fill="#FAA634" d="M1273.2,574.7c0.4,1.6,2.3,3.1,4.2,3.2c1.9,0.2,3.1-1,2.6-2.6s-2.4-3.1-4.3-3.3
		C1273.9,571.9,1272.8,573.1,1273.2,574.7"/>
				<path fill="#FAA634" d="M1429.5,581.2c0.7,1.7,2.8,3.2,4.5,3.4c1.8,0.2,2.7-1,1.9-2.7c-0.7-1.7-2.8-3.2-4.6-3.4
		C1429.7,578.4,1428.8,579.6,1429.5,581.2"/>
				<path fill="#FAA634" d="M1419.2,580.1c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4C1419.4,577.2,1418.5,578.4,1419.2,580.1"
				/>
				<path fill="#FAA634" d="M1408.8,579c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4S1408.1,577.3,1408.8,579" />
				<path fill="#FAA634" d="M1398.4,577.8c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2.1-2.7c-0.7-1.7-2.7-3.2-4.5-3.4
		C1398.7,575,1397.7,576.2,1398.4,577.8"/>
				<path fill="#FAA634" d="M1387.9,576.7c0.6,1.7,2.6,3.2,4.5,3.4c1.8,0.2,2.8-1,2.1-2.7c-0.7-1.7-2.7-3.2-4.5-3.4
		S1387.3,575,1387.9,576.7"/>
				<path fill="#FAA634" d="M1377.4,575.6c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1377.8,572.7,1376.8,573.9,1377.4,575.6"/>
				<path fill="#FAA634" d="M1366.9,574.5c0.6,1.7,2.6,3.2,4.4,3.3c1.8,0.2,2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1367.3,571.7,1366.3,572.9,1366.9,574.5"/>
				<path fill="#FAA634" d="M1356.4,573.5c0.6,1.7,2.6,3.2,4.4,3.3c1.8,0.2,2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.3
		C1356.8,570.6,1355.8,571.8,1356.4,573.5"/>
				<path fill="#FAA634" d="M1345.8,572.4c0.6,1.7,2.5,3.1,4.4,3.3c1.8,0.2,2.9-1,2.3-2.7s-2.6-3.2-4.4-3.3
		C1346.2,569.5,1345.2,570.7,1345.8,572.4"/>
				<path fill="#FAA634" d="M1335.1,571.4c0.6,1.7,2.5,3.1,4.4,3.3s2.9-1,2.3-2.7s-2.5-3.1-4.4-3.3
		C1335.6,568.5,1334.6,569.7,1335.1,571.4"/>
				<path fill="#FAA634" d="M1324.5,570.3c0.5,1.7,2.5,3.1,4.3,3.3c1.9,0.2,2.9-1,2.4-2.7c-0.6-1.7-2.5-3.1-4.3-3.3
		C1325,567.5,1323.9,568.7,1324.5,570.3"/>
				<path fill="#FAA634" d="M1313.8,569.3c0.5,1.7,2.5,3.1,4.3,3.3c1.9,0.2,2.9-1,2.4-2.7s-2.5-3.1-4.3-3.3
		C1314.3,566.5,1313.2,567.7,1313.8,569.3"/>
				<path fill="#FAA634" d="M1303,568.3c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.4-2.7c-0.5-1.7-2.5-3.1-4.3-3.3
		C1303.6,565.5,1302.5,566.7,1303,568.3"/>
				<path fill="#FAA634" d="M1292.3,567.4c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.7c-0.5-1.6-2.4-3.1-4.3-3.3S1291.8,565.7,1292.3,567.4" />
				<path fill="#FAA634" d="M1281.5,566.4c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.7c-0.5-1.6-2.4-3.1-4.3-3.3
		C1282.1,563.6,1281,564.8,1281.5,566.4"/>
				<path fill="#FAA634" d="M1270.6,565.5c0.5,1.6,2.4,3.1,4.3,3.3s3-1,2.5-2.7c-0.5-1.6-2.4-3.1-4.3-3.3
		C1271.3,562.6,1270.2,563.8,1270.6,565.5"/>
				<path fill="#FAA634" d="M1425.4,571.7c0.7,1.7,2.8,3.2,4.5,3.4c1.8,0.2,2.6-1,1.9-2.7c-0.8-1.7-2.8-3.2-4.5-3.4
		C1425.5,568.8,1424.7,570.1,1425.4,571.7"/>
				<path fill="#FAA634" d="M1415.2,570.6c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.7s-2.8-3.2-4.5-3.4
		C1415.3,567.7,1414.5,568.9,1415.2,570.6"/>
				<path fill="#FAA634" d="M1404.9,569.5c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4S1404.2,567.8,1404.9,569.5" />
				<path fill="#FAA634" d="M1394.6,568.4c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4C1394.8,565.5,1393.9,566.7,1394.6,568.4"
				/>
				<path fill="#FAA634" d="M1384.2,567.3c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4C1384.5,564.4,1383.6,565.6,1384.2,567.3"
				/>
				<path fill="#FAA634" d="M1373.8,566.2c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1,2.1-2.7c-0.7-1.7-2.7-3.2-4.5-3.4
		C1374.1,563.3,1373.2,564.5,1373.8,566.2"/>
				<path fill="#FAA634" d="M1363.4,565.1c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.1-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1363.7,562.2,1362.8,563.4,1363.4,565.1"/>
				<path fill="#FAA634" d="M1352.9,564c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1353.3,561.2,1352.3,562.4,1352.9,564"/>
				<path fill="#FAA634" d="M1342.4,563c0.6,1.7,2.6,3.2,4.4,3.3c1.8,0.2,2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1342.8,560.1,1341.8,561.3,1342.4,563"/>
				<path fill="#FAA634" d="M1331.9,562c0.6,1.7,2.5,3.2,4.4,3.3c1.8,0.2,2.8-1,2.3-2.7c-0.6-1.7-2.6-3.2-4.4-3.3
		C1332.3,559.1,1331.3,560.3,1331.9,562"/>
				<path fill="#FAA634" d="M1321.3,561c0.6,1.7,2.5,3.2,4.4,3.3c1.8,0.2,2.9-1,2.3-2.7s-2.5-3.2-4.4-3.3
		C1321.8,558.1,1320.8,559.3,1321.3,561"/>
				<path fill="#FAA634" d="M1310.7,560c0.5,1.7,2.5,3.1,4.3,3.3s2.9-1,2.3-2.7s-2.5-3.2-4.3-3.3C1311.2,557.1,1310.2,558.3,1310.7,560" />
				<path fill="#FAA634" d="M1300.1,559c0.5,1.7,2.5,3.1,4.3,3.3c1.9,0.2,2.9-1,2.4-2.7s-2.5-3.2-4.3-3.3
		C1300.6,556.1,1299.5,557.3,1300.1,559"/>
				<path fill="#FAA634" d="M1289.4,558c0.5,1.7,2.4,3.1,4.3,3.3s2.9-1,2.4-2.7s-2.5-3.2-4.3-3.3C1289.9,555.1,1288.9,556.4,1289.4,558" />
				<path fill="#FAA634" d="M1278.7,557.1c0.5,1.7,2.4,3.1,4.3,3.3s3-1,2.4-2.7c-0.5-1.7-2.4-3.1-4.3-3.3
		C1279.3,554.2,1278.2,555.4,1278.7,557.1"/>
				<path fill="#FAA634" d="M1267.9,556.1c0.5,1.7,2.4,3.1,4.3,3.3s3-1,2.5-2.7s-2.4-3.1-4.3-3.3C1268.5,553.3,1267.4,554.5,1267.9,556.1"
				/>
				<path fill="#FAA634" d="M1257.1,555.2c0.5,1.7,2.4,3.1,4.3,3.3s3-1.1,2.5-2.7c-0.5-1.7-2.4-3.1-4.3-3.3
		C1257.8,552.3,1256.7,553.6,1257.1,555.2"/>
				<path fill="#FAA634" d="M1421.2,562.2c0.8,1.7,2.8,3.2,4.5,3.4c1.8,0.2,2.6-1,1.8-2.7c-0.8-1.7-2.8-3.2-4.5-3.4
		S1420.4,560.5,1421.2,562.2"/>
				<path fill="#FAA634" d="M1400.9,559.9c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.7s-2.7-3.2-4.5-3.4S1400.2,558.2,1400.9,559.9" />
				<path fill="#FAA634" d="M1390.7,558.8c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.7s-2.7-3.2-4.5-3.4C1390.8,555.9,1390,557.1,1390.7,558.8"
				/>
				<path fill="#FAA634" d="M1380.4,557.7c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4S1379.7,556.1,1380.4,557.7" />
				<path fill="#FAA634" d="M1370.1,556.7c0.7,1.7,2.7,3.2,4.4,3.4c1.8,0.2,2.7-1,2-2.7s-2.7-3.2-4.5-3.4
		C1370.4,553.8,1369.5,555,1370.1,556.7"/>
				<path fill="#FAA634" d="M1359.8,555.6c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1,2.1-2.7c-0.7-1.7-2.6-3.2-4.4-3.4
		C1360.1,552.7,1359.2,553.9,1359.8,555.6"/>
				<path fill="#FAA634" d="M1349.4,554.6c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.1-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1349.7,551.7,1348.8,552.9,1349.4,554.6"/>
				<path fill="#FAA634" d="M1339,553.5c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1339.4,550.6,1338.4,551.8,1339,553.5"/>
				<path fill="#FAA634" d="M1328.6,552.5c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1328.9,549.6,1328,550.8,1328.6,552.5"/>
				<path fill="#FAA634" d="M1318.1,551.5c0.6,1.7,2.5,3.2,4.4,3.3c1.8,0.2,2.8-1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1318.5,548.6,1317.5,549.8,1318.1,551.5"/>
				<path fill="#FAA634" d="M1307.5,550.5c0.6,1.7,2.5,3.2,4.3,3.3c1.8,0.2,2.9-1,2.3-2.7s-2.5-3.2-4.3-3.4
		C1308,547.6,1307,548.8,1307.5,550.5"/>
				<path fill="#FAA634" d="M1297,549.5c0.6,1.7,2.5,3.2,4.3,3.3s2.9-1,2.3-2.7s-2.5-3.2-4.3-3.3C1297.5,546.6,1296.4,547.9,1297,549.5" />
				<path fill="#FAA634" d="M1286.4,548.6c0.5,1.7,2.5,3.2,4.3,3.3c1.8,0.2,2.9-1.1,2.3-2.7c-0.5-1.7-2.5-3.2-4.3-3.3
		C1286.9,545.7,1285.8,546.9,1286.4,548.6"/>
				<path fill="#FAA634" d="M1275.8,547.7c0.5,1.7,2.4,3.2,4.3,3.3c1.9,0.2,2.9-1.1,2.4-2.7c-0.5-1.7-2.5-3.2-4.3-3.3
		C1276.3,544.7,1275.2,546,1275.8,547.7"/>
				<path fill="#FAA634" d="M1265.1,546.7c0.5,1.7,2.4,3.2,4.3,3.3c1.9,0.2,2.9-1.1,2.4-2.7c-0.5-1.7-2.4-3.2-4.3-3.3
		C1265.7,543.8,1264.6,545.1,1265.1,546.7"/>
				<path fill="#FAA634" d="M1254.4,545.8c0.5,1.7,2.4,3.1,4.3,3.3s3-1.1,2.5-2.7c-0.5-1.7-2.4-3.2-4.3-3.3
		C1255,542.9,1253.9,544.1,1254.4,545.8"/>
				<path fill="#FAA634" d="M1243.7,544.9c0.5,1.7,2.4,3.1,4.3,3.3s3-1.1,2.5-2.7c-0.5-1.7-2.4-3.2-4.3-3.3
		C1244.3,542,1243.2,543.3,1243.7,544.9"/>
				<path fill="#FAA634" d="M1396.8,550.3c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.8-2.7c-0.7-1.7-2.8-3.2-4.5-3.4
		C1396.9,547.4,1396.1,548.6,1396.8,550.3"/>
				<path fill="#FAA634" d="M1386.7,549.3c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.7s-2.7-3.2-4.5-3.4C1386.8,546.3,1386,547.6,1386.7,549.3"
				/>
				<path fill="#FAA634" d="M1376.5,548.2c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.7s-2.7-3.2-4.5-3.4
		C1376.7,545.2,1375.8,546.5,1376.5,548.2"/>
				<path fill="#FAA634" d="M1366.3,547.1c0.7,1.7,2.7,3.2,4.4,3.4c1.8,0.2,2.7-1,2-2.7s-2.7-3.2-4.5-3.4
		C1366.5,544.2,1365.6,545.4,1366.3,547.1"/>
				<path fill="#FAA634" d="M1356.1,546.1c0.7,1.7,2.6,3.2,4.4,3.4s2.7-1,2-2.7s-2.7-3.2-4.4-3.4C1356.3,543.1,1355.4,544.4,1356.1,546.1"
				/>
				<path fill="#FAA634" d="M1345.8,545c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1,2-2.7s-2.6-3.2-4.4-3.4C1346.1,542.1,1345.1,543.3,1345.8,545" />
				<path fill="#FAA634" d="M1335.5,544c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1,2.1-2.7s-2.6-3.2-4.4-3.4C1335.8,541.1,1334.8,542.3,1335.5,544" />
				<path fill="#FAA634" d="M1325.1,543c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1.1,2.1-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1325.4,540.1,1324.5,541.3,1325.1,543"/>
				<path fill="#FAA634" d="M1314.7,542c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1.1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		C1315.1,539.1,1314.1,540.3,1314.7,542"/>
				<path fill="#FAA634" d="M1304.3,541c0.6,1.7,2.5,3.2,4.4,3.4c1.8,0.2,2.8-1.1,2.2-2.7c-0.6-1.7-2.6-3.2-4.4-3.4
		S1303.7,539.3,1304.3,541"/>
				<path fill="#FAA634" d="M1293.8,540c0.6,1.7,2.5,3.2,4.3,3.4s2.8-1.1,2.2-2.8c-0.6-1.7-2.5-3.2-4.3-3.4
		C1294.2,537.1,1293.2,538.4,1293.8,540"/>
				<path fill="#FAA634" d="M1283.3,539.1c0.6,1.7,2.5,3.2,4.3,3.3c1.8,0.2,2.9-1.1,2.3-2.8s-2.5-3.2-4.3-3.4
		C1283.7,536.2,1282.7,537.4,1283.3,539.1"/>
				<path fill="#FAA634" d="M1272.7,538.2c0.5,1.7,2.5,3.2,4.3,3.3c1.8,0.2,2.9-1.1,2.3-2.8s-2.5-3.2-4.3-3.4
		C1273.2,535.2,1272.2,536.5,1272.7,538.2"/>
				<path fill="#FAA634" d="M1262.1,537.2c0.5,1.7,2.4,3.2,4.3,3.3c1.8,0.2,2.9-1.1,2.4-2.8s-2.5-3.2-4.3-3.3
		C1262.7,534.3,1261.6,535.6,1262.1,537.2"/>
				<path fill="#FAA634" d="M1251.5,536.3c0.5,1.7,2.4,3.2,4.3,3.3c1.8,0.2,2.9-1.1,2.4-2.8s-2.4-3.2-4.3-3.3
		C1252.1,533.4,1251,534.6,1251.5,536.3"/>
				<path fill="#FAA634" d="M1176.3,530.5c0.4,1.7,2.3,3.1,4.2,3.3c1.9,0.1,3.1-1.1,2.6-2.8c-0.4-1.7-2.3-3.2-4.2-3.3
		C1177.1,527.6,1175.9,528.8,1176.3,530.5"/>
				<path fill="#FAA634" d="M1402.6,541.8c0.8,1.7,2.8,3.3,4.5,3.4c1.7,0.2,2.5-1,1.7-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1402.6,538.9,1401.8,540.1,1402.6,541.8"/>
				<path fill="#FAA634" d="M1392.6,540.7c0.8,1.7,2.8,3.2,4.5,3.4s2.5-1,1.8-2.8c-0.8-1.7-2.8-3.3-4.5-3.4
		C1392.6,537.8,1391.8,539,1392.6,540.7"/>
				<path fill="#FAA634" d="M1382.6,539.6c0.7,1.7,2.7,3.2,4.5,3.4c1.7,0.2,2.6-1,1.8-2.8c-0.7-1.7-2.8-3.3-4.5-3.4
		C1382.7,536.7,1381.8,537.9,1382.6,539.6"/>
				<path fill="#FAA634" d="M1372.5,538.5c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.8c-0.7-1.7-2.7-3.2-4.5-3.4
		C1372.6,535.6,1371.8,536.8,1372.5,538.5"/>
				<path fill="#FAA634" d="M1362.4,537.5c0.7,1.7,2.7,3.2,4.5,3.4s2.6-1,1.9-2.8c-0.7-1.7-2.7-3.2-4.5-3.4
		C1362.6,534.5,1361.7,535.8,1362.4,537.5"/>
				<path fill="#FAA634" d="M1352.3,536.4c0.7,1.7,2.7,3.2,4.4,3.4c1.8,0.2,2.6-1.1,1.9-2.8s-2.7-3.2-4.4-3.4
		C1352.4,533.5,1351.6,534.7,1352.3,536.4"/>
				<path fill="#FAA634" d="M1342.1,535.4c0.7,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2-2.8s-2.7-3.2-4.4-3.4
		C1342.3,532.5,1341.4,533.7,1342.1,535.4"/>
				<path fill="#FAA634" d="M1331.8,534.4c0.7,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2-2.8s-2.6-3.2-4.4-3.4
		C1332.1,531.4,1331.2,532.7,1331.8,534.4"/>
				<path fill="#FAA634" d="M1321.6,533.4c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2.1-2.8s-2.6-3.2-4.4-3.4
		C1321.8,530.4,1320.9,531.7,1321.6,533.4"/>
				<path fill="#FAA634" d="M1311.2,532.4c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2.1-2.8s-2.6-3.2-4.4-3.4
		C1311.6,529.5,1310.6,530.7,1311.2,532.4"/>
				<path fill="#FAA634" d="M1300.9,531.4c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1.1,2.1-2.8c-0.6-1.7-2.6-3.2-4.4-3.4
		C1301.2,528.5,1300.3,529.7,1300.9,531.4"/>
				<path fill="#FAA634" d="M1290.5,530.5c0.6,1.7,2.5,3.2,4.3,3.4s2.8-1.1,2.2-2.8c-0.6-1.7-2.6-3.2-4.3-3.4
		C1290.9,527.5,1289.9,528.8,1290.5,530.5"/>
				<path fill="#FAA634" d="M1280.1,529.5c0.6,1.7,2.5,3.2,4.3,3.4s2.8-1.1,2.2-2.8c-0.6-1.7-2.5-3.2-4.3-3.4
		C1280.5,526.6,1279.5,527.8,1280.1,529.5"/>
				<path fill="#FAA634" d="M1269.6,528.6c0.6,1.7,2.5,3.2,4.3,3.4s2.8-1.1,2.3-2.8c-0.6-1.7-2.5-3.2-4.3-3.4
		C1270,525.6,1269,526.9,1269.6,528.6"/>
				<path fill="#FAA634" d="M1259.1,527.7c0.5,1.7,2.5,3.2,4.3,3.4s2.9-1.1,2.3-2.8s-2.5-3.2-4.3-3.4S1258.5,526,1259.1,527.7" />
				<path fill="#FAA634" d="M1173.9,521c0.4,1.7,2.3,3.2,4.2,3.3c1.9,0.1,3-1.1,2.6-2.8c-0.5-1.7-2.3-3.2-4.2-3.3
		C1174.6,518,1173.4,519.3,1173.9,521"/>
				<path fill="#FAA634" d="M1378.4,529.9c0.8,1.7,2.8,3.3,4.5,3.4c1.7,0.2,2.5-1.1,1.8-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1378.4,527,1377.6,528.2,1378.4,529.9"/>
				<path fill="#FAA634" d="M1368.4,528.9c0.7,1.7,2.7,3.3,4.5,3.4c1.7,0.2,2.5-1.1,1.8-2.8s-2.8-3.3-4.5-3.4
		C1368.5,525.9,1367.7,527.2,1368.4,528.9"/>
				<path fill="#FAA634" d="M1358.4,527.8c0.7,1.7,2.7,3.3,4.5,3.4c1.7,0.2,2.6-1.1,1.8-2.8c-0.7-1.7-2.7-3.3-4.5-3.4
		C1358.5,524.9,1357.7,526.1,1358.4,527.8"/>
				<path fill="#FAA634" d="M1348.3,526.8c0.7,1.7,2.7,3.2,4.4,3.4c1.8,0.2,2.6-1.1,1.9-2.8s-2.7-3.3-4.4-3.4
		C1348.5,523.8,1347.6,525.1,1348.3,526.8"/>
				<path fill="#FAA634" d="M1338.2,525.7c0.7,1.7,2.7,3.2,4.4,3.4c1.8,0.2,2.6-1.1,1.9-2.8s-2.7-3.3-4.4-3.4
		C1338.4,522.8,1337.5,524,1338.2,525.7"/>
				<path fill="#FAA634" d="M1328.1,524.7c0.7,1.7,2.6,3.2,4.4,3.4s2.6-1.1,2-2.8c-0.7-1.7-2.7-3.2-4.4-3.4
		C1328.3,521.8,1327.4,523,1328.1,524.7"/>
				<path fill="#FAA634" d="M1317.9,523.7c0.7,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2-2.8s-2.6-3.2-4.4-3.4C1318.1,520.8,1317.2,522,1317.9,523.7"
				/>
				<path fill="#FAA634" d="M1307.7,522.8c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2-2.8s-2.6-3.2-4.4-3.4C1307.9,519.8,1307,521,1307.7,522.8" />
				<path fill="#FAA634" d="M1297.4,521.8c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2.1-2.8s-2.6-3.2-4.4-3.4S1296.8,520.1,1297.4,521.8" />
				<path fill="#FAA634" d="M1287.1,520.8c0.6,1.7,2.6,3.2,4.4,3.4s2.8-1.1,2.1-2.8c-0.6-1.7-2.6-3.2-4.4-3.4
		C1287.4,517.9,1286.5,519.1,1287.1,520.8"/>
				<path fill="#FAA634" d="M1276.7,519.9c0.6,1.7,2.5,3.2,4.3,3.4s2.8-1.1,2.2-2.8c-0.6-1.7-2.6-3.2-4.3-3.4
		C1277.1,516.9,1276.2,518.2,1276.7,519.9"/>
				<path fill="#FAA634" d="M1182,512.1c0.5,1.7,2.4,3.2,4.2,3.3c1.9,0.1,3-1.1,2.5-2.8s-2.4-3.2-4.2-3.3
		C1182.7,509.2,1181.5,510.4,1182,512.1"/>
				<path fill="#FAA634" d="M1171.3,511.4c0.5,1.7,2.3,3.2,4.2,3.3c1.9,0.1,3-1.1,2.5-2.8s-2.4-3.2-4.2-3.3
		C1172,508.4,1170.9,509.6,1171.3,511.4"/>
				<path fill="#FAA634" d="M1160.6,510.6c0.4,1.7,2.3,3.2,4.2,3.3c1.9,0.1,3-1.1,2.6-2.8c-0.5-1.7-2.3-3.2-4.2-3.3
		C1161.3,507.6,1160.2,508.9,1160.6,510.6"/>
				<path fill="#FAA634" d="M1374.1,520.2c0.8,1.7,2.8,3.3,4.5,3.5s2.5-1.1,1.7-2.8s-2.8-3.3-4.5-3.5S1373.3,518.5,1374.1,520.2" />
				<path fill="#FAA634" d="M1364.2,519.1c0.8,1.7,2.8,3.3,4.5,3.5s2.5-1.1,1.7-2.8s-2.8-3.3-4.5-3.5
		C1364.2,516.2,1363.5,517.4,1364.2,519.1"/>
				<path fill="#FAA634" d="M1344.3,517.1c0.7,1.7,2.7,3.3,4.4,3.4c1.7,0.2,2.6-1.1,1.8-2.8c-0.7-1.7-2.7-3.3-4.4-3.5
		C1344.4,514.1,1343.6,515.3,1344.3,517.1"/>
				<path fill="#FAA634" d="M1334.3,516c0.7,1.7,2.7,3.3,4.4,3.4c1.7,0.2,2.6-1.1,1.9-2.8s-2.7-3.3-4.4-3.4
		C1334.4,513,1333.6,514.3,1334.3,516"/>
				<path fill="#FAA634" d="M1324.2,515c0.7,1.7,2.7,3.3,4.4,3.4c1.8,0.2,2.6-1.1,1.9-2.8s-2.7-3.3-4.4-3.4
		C1324.4,512,1323.6,513.3,1324.2,515"/>
				<path fill="#FAA634" d="M1314.1,514c0.7,1.7,2.6,3.3,4.4,3.4c1.8,0.2,2.6-1.1,2-2.8c-0.7-1.7-2.7-3.3-4.4-3.4
		C1314.3,511,1313.5,512.3,1314.1,514"/>
				<path fill="#FAA634" d="M1304,513c0.7,1.7,2.6,3.3,4.4,3.4c1.8,0.2,2.7-1.1,2-2.8s-2.6-3.3-4.4-3.4
		C1304.2,510.1,1303.3,511.3,1304,513"/>
				<path fill="#FAA634" d="M1293.8,512.1c0.6,1.7,2.6,3.2,4.4,3.4s2.7-1.1,2-2.8s-2.6-3.3-4.4-3.4
		C1294.1,509.1,1293.2,510.4,1293.8,512.1"/>
				<path fill="#FAA634" d="M1252.7,508.4c0.6,1.7,2.5,3.2,4.3,3.4c1.8,0.2,2.8-1.1,2.2-2.8c-0.6-1.7-2.5-3.2-4.3-3.4
		C1253.1,505.4,1252.1,506.6,1252.7,508.4"/>
				<path fill="#FAA634" d="M1221.4,505.7c0.5,1.7,2.5,3.2,4.3,3.4c1.8,0.1,2.8-1.1,2.3-2.8c-0.6-1.7-2.5-3.2-4.3-3.4
		C1221.9,502.7,1220.9,504,1221.4,505.7"/>
				<path fill="#FAA634" d="M1211,504.9c0.5,1.7,2.4,3.2,4.3,3.4c1.8,0.1,2.9-1.1,2.3-2.8c-0.5-1.7-2.5-3.2-4.3-3.4
		C1211.5,501.9,1210.4,503.2,1211,504.9"/>
				<path fill="#FAA634" d="M1200.4,504.1c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.1,2.4-2.8c-0.5-1.7-2.4-3.2-4.3-3.4
		C1201,501.1,1199.9,502.3,1200.4,504.1"/>
				<path fill="#FAA634" d="M1189.9,503.2c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.1,2.4-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1190.4,500.2,1189.4,501.5,1189.9,503.2"/>
				<path fill="#FAA634" d="M1179.3,502.5c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.1,2.4-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1179.9,499.5,1178.8,500.7,1179.3,502.5"/>
				<path fill="#FAA634" d="M1359.9,509.4c0.8,1.7,2.8,3.3,4.5,3.5s2.5-1.1,1.7-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1359.9,506.4,1359.2,507.6,1359.9,509.4"/>
				<path fill="#FAA634" d="M1340.2,507.3c0.7,1.7,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.8c-0.7-1.7-2.7-3.3-4.4-3.5
		C1340.3,504.3,1339.5,505.5,1340.2,507.3"/>
				<path fill="#FAA634" d="M1330.3,506.3c0.7,1.7,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.8c-0.7-1.7-2.7-3.3-4.4-3.5
		S1329.5,504.5,1330.3,506.3"/>
				<path fill="#FAA634" d="M1320.3,505.3c0.7,1.7,2.7,3.3,4.4,3.4c1.7,0.2,2.6-1.1,1.8-2.8c-0.7-1.7-2.7-3.3-4.4-3.5
		C1320.4,502.3,1319.6,503.5,1320.3,505.3"/>
				<path fill="#FAA634" d="M1310.3,504.3c0.7,1.7,2.7,3.3,4.4,3.4c1.7,0.2,2.6-1.1,1.9-2.8c-0.7-1.7-2.7-3.3-4.4-3.4
		C1310.4,501.3,1309.6,502.5,1310.3,504.3"/>
				<path fill="#FAA634" d="M1249.3,498.6c0.6,1.7,2.5,3.3,4.3,3.4c1.8,0.2,2.7-1.1,2.1-2.8c-0.6-1.7-2.6-3.3-4.3-3.4
		C1249.7,495.6,1248.7,496.9,1249.3,498.6"/>
				<path fill="#FAA634" d="M1207.9,495.1c0.5,1.7,2.5,3.2,4.3,3.4c1.8,0.1,2.8-1.1,2.3-2.9c-0.6-1.7-2.5-3.3-4.3-3.4
		S1207.4,493.4,1207.9,495.1"/>
				<path fill="#FAA634" d="M1197.5,494.3c0.5,1.7,2.4,3.2,4.3,3.4c1.8,0.1,2.9-1.1,2.3-2.9c-0.5-1.7-2.5-3.3-4.3-3.4
		S1196.9,492.6,1197.5,494.3"/>
				<path fill="#FAA634" d="M1187,493.5c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.1,2.3-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1187.5,490.5,1186.5,491.8,1187,493.5"/>
				<path fill="#FAA634" d="M1176.5,492.7c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.1,2.4-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1177,489.7,1176,491,1176.5,492.7"/>
				<path fill="#FAA634" d="M1165.9,491.9c0.5,1.7,2.4,3.2,4.2,3.4c1.8,0.1,2.9-1.2,2.4-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1166.5,488.9,1165.4,490.2,1165.9,491.9"/>
				<path fill="#FAA634" d="M1144.8,490.4c0.5,1.7,2.3,3.2,4.2,3.4c1.8,0.1,3-1.2,2.5-2.9c-0.5-1.7-2.4-3.2-4.2-3.4
		C1145.4,487.4,1144.3,488.7,1144.8,490.4"/>
				<path fill="#FAA634" d="M1422.4,507.1c0.9,1.7,2.9,3.3,4.6,3.5c1.6,0.2,2.2-1.1,1.3-2.8c-0.9-1.7-2.9-3.3-4.6-3.5
		C1422.1,504.1,1421.6,505.4,1422.4,507.1"/>
				<path fill="#FAA634" d="M1394.1,503.8c0.8,1.7,2.9,3.3,4.5,3.5s2.3-1.1,1.5-2.8c-0.9-1.7-2.9-3.3-4.5-3.5
		C1393.9,500.8,1393.2,502.1,1394.1,503.8"/>
				<path fill="#FAA634" d="M1365.2,500.6c0.8,1.7,2.8,3.3,4.5,3.5s2.4-1.1,1.6-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1365.2,497.6,1364.4,498.8,1365.2,500.6"/>
				<path fill="#FAA634" d="M1355.5,499.5c0.8,1.7,2.8,3.3,4.5,3.5s2.4-1.1,1.6-2.8s-2.8-3.3-4.5-3.5
		C1355.5,496.5,1354.8,497.8,1355.5,499.5"/>
				<path fill="#FAA634" d="M1245.9,488.8c0.6,1.7,2.6,3.3,4.3,3.4c1.8,0.2,2.7-1.1,2.1-2.9c-0.6-1.7-2.6-3.3-4.3-3.4
		C1246.2,485.8,1245.2,487.1,1245.9,488.8"/>
				<path fill="#FAA634" d="M1163.1,482.1c0.5,1.7,2.4,3.3,4.2,3.4c1.8,0.1,2.9-1.2,2.4-2.9c-0.5-1.7-2.4-3.3-4.2-3.4
		C1163.7,479.1,1162.6,480.4,1163.1,482.1"/>
				<path fill="#FAA634" d="M1398.7,495c0.9,1.8,2.9,3.3,4.5,3.5s2.2-1.1,1.4-2.8c-0.9-1.7-2.9-3.3-4.5-3.5
		C1398.4,492,1397.8,493.3,1398.7,495"/>
				<path fill="#FAA634" d="M1379.8,492.9c0.8,1.7,2.9,3.3,4.5,3.5s2.3-1.1,1.4-2.8c-0.9-1.8-2.9-3.3-4.5-3.5
		C1379.6,489.8,1378.9,491.1,1379.8,492.9"/>
				<path fill="#FAA634" d="M1370.3,491.8c0.8,1.7,2.8,3.3,4.5,3.5s2.3-1.1,1.5-2.8c-0.8-1.7-2.9-3.3-4.5-3.5
		C1370.1,488.8,1369.4,490,1370.3,491.8"/>
				<path fill="#FAA634" d="M1360.7,490.7c0.8,1.7,2.8,3.3,4.5,3.5s2.4-1.1,1.5-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1360.6,487.7,1359.9,489,1360.7,490.7"/>
				<path fill="#FAA634" d="M1351.1,489.7c0.8,1.7,2.8,3.3,4.5,3.5s2.4-1.1,1.6-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1351,486.6,1350.3,487.9,1351.1,489.7"/>
				<path fill="#FAA634" d="M1341.4,488.6c0.8,1.7,2.8,3.3,4.5,3.5s2.4-1.1,1.6-2.8c-0.8-1.7-2.8-3.3-4.5-3.5S1340.6,486.9,1341.4,488.6"
				/>
				<path fill="#FAA634" d="M1331.7,487.6c0.8,1.7,2.8,3.3,4.4,3.5c1.7,0.2,2.4-1.1,1.7-2.8c-0.8-1.7-2.8-3.3-4.5-3.5
		C1331.7,484.6,1330.9,485.9,1331.7,487.6"/>
				<path fill="#FAA634" d="M1262.5,480.8c0.7,1.7,2.6,3.3,4.4,3.5c1.7,0.2,2.6-1.1,1.9-2.9c-0.7-1.7-2.6-3.3-4.4-3.5
		C1262.7,477.7,1261.8,479,1262.5,480.8"/>
				<path fill="#FAA634" d="M1211.8,476.3c0.6,1.7,2.5,3.3,4.3,3.4s2.7-1.1,2.1-2.9c-0.6-1.7-2.5-3.3-4.3-3.4S1211.2,474.6,1211.8,476.3"
				/>
				<path fill="#FAA634" d="M1201.5,475.5c0.6,1.7,2.5,3.3,4.3,3.4s2.8-1.2,2.2-2.9c-0.6-1.7-2.5-3.3-4.3-3.4
		C1201.9,472.4,1201,473.7,1201.5,475.5"/>
				<path fill="#FAA634" d="M1393.7,485.1c0.9,1.8,2.9,3.3,4.5,3.5s2.2-1.1,1.3-2.8c-0.9-1.8-2.9-3.3-4.5-3.5S1392.8,483.4,1393.7,485.1"
				/>
				<path fill="#FAA634" d="M1365.5,481.9c0.8,1.8,2.9,3.3,4.5,3.5s2.3-1.1,1.4-2.8c-0.9-1.8-2.9-3.3-4.5-3.5S1364.7,480.1,1365.5,481.9"
				/>
				<path fill="#FAA634" d="M1356.1,480.8c0.8,1.8,2.8,3.3,4.5,3.5s2.3-1.1,1.5-2.8c-0.8-1.8-2.8-3.3-4.5-3.5S1355.2,479.1,1356.1,480.8"
				/>
				<path fill="#FAA634" d="M1346.5,479.8c0.8,1.8,2.8,3.3,4.5,3.5s2.3-1.1,1.5-2.8c-0.8-1.8-2.8-3.3-4.5-3.5
		C1346.4,476.7,1345.7,478,1346.5,479.8"/>
				<path fill="#FAA634" d="M1336.9,478.7c0.8,1.8,2.8,3.3,4.5,3.5s2.4-1.1,1.6-2.8c-0.8-1.8-2.8-3.3-4.5-3.5S1336.2,477,1336.9,478.7" />
				<path fill="#FAA634" d="M1327.3,477.7c0.8,1.8,2.8,3.3,4.4,3.5s2.4-1.1,1.6-2.9s-2.8-3.3-4.5-3.5
		C1327.3,474.7,1326.5,475.9,1327.3,477.7"/>
				<path fill="#FAA634" d="M1218.5,467.3c0.6,1.8,2.6,3.3,4.3,3.5c1.8,0.2,2.7-1.1,2-2.9c-0.6-1.8-2.6-3.3-4.3-3.5
		C1218.8,464.2,1217.9,465.5,1218.5,467.3"/>
				<path fill="#FAA634" d="M1208.4,466.4c0.6,1.8,2.5,3.3,4.3,3.4s2.7-1.2,2.1-2.9c-0.6-1.8-2.5-3.3-4.3-3.5
		C1208.7,463.4,1207.8,464.7,1208.4,466.4"/>
				<path fill="#FAA634" d="M1188,464.8c0.6,1.8,2.5,3.3,4.3,3.4s2.7-1.2,2.1-2.9c-0.6-1.8-2.5-3.3-4.3-3.4
		C1188.4,461.7,1187.4,463,1188,464.8"/>
				<path fill="#FAA634" d="M1167.5,463.2c0.6,1.8,2.4,3.3,4.2,3.4c1.8,0.1,2.8-1.2,2.2-2.9c-0.6-1.8-2.5-3.3-4.2-3.4
		C1167.9,460.1,1166.9,461.4,1167.5,463.2"/>
				<path fill="#FAA634" d="M1388.7,475.2c0.9,1.8,2.9,3.3,4.5,3.5s2.2-1.1,1.3-2.8c-0.9-1.8-2.9-3.3-4.5-3.5
		C1388.3,472.2,1387.8,473.4,1388.7,475.2"/>
				<path fill="#FAA634" d="M1332.4,468.8c0.8,1.8,2.8,3.3,4.5,3.5s2.3-1.1,1.5-2.9s-2.8-3.3-4.5-3.5
		C1332.3,465.7,1331.6,467,1332.4,468.8"/>
				<path fill="#FAA634" d="M1322.9,467.8c0.8,1.8,2.8,3.3,4.4,3.5c1.7,0.2,2.4-1.1,1.6-2.9s-2.8-3.3-4.5-3.5
		C1322.8,464.7,1322.1,466,1322.9,467.8"/>
				<path fill="#FAA634" d="M1293.9,464.7c0.8,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.4-1.1,1.7-2.9c-0.8-1.8-2.7-3.3-4.4-3.5
		C1293.9,461.7,1293.2,463,1293.9,464.7"/>
				<path fill="#FAA634" d="M1284.2,463.8c0.7,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.7-2.9c-0.7-1.8-2.7-3.3-4.4-3.5
		C1284.2,460.7,1283.5,462,1284.2,463.8"/>
				<path fill="#FAA634" d="M1254.8,460.9c0.7,1.8,2.6,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.9s-2.7-3.3-4.4-3.5
		C1254.9,457.9,1254.1,459.2,1254.8,460.9"/>
				<path fill="#FAA634" d="M1234.9,459.1c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.6-1.1,1.9-2.9s-2.6-3.3-4.3-3.5S1234.3,457.3,1234.9,459.1"
				/>
				<path fill="#FAA634" d="M1299.2,455.8c0.8,1.8,2.8,3.3,4.4,3.5c1.7,0.2,2.4-1.1,1.6-2.9s-2.8-3.3-4.4-3.5
		C1299.2,452.7,1298.5,454,1299.2,455.8"/>
				<path fill="#FAA634" d="M1260.6,451.9c0.7,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.7-2.9c-0.7-1.8-2.7-3.3-4.4-3.5
		C1260.6,448.8,1259.8,450.1,1260.6,451.9"/>
				<path fill="#FAA634" d="M1250.8,450.9c0.7,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.9s-2.7-3.3-4.4-3.5
		C1250.9,447.9,1250.1,449.2,1250.8,450.9"/>
				<path fill="#FAA634" d="M1241,450c0.7,1.8,2.6,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.9s-2.7-3.3-4.4-3.5S1240.3,448.2,1241,450" />
				<path fill="#FAA634" d="M1231.1,449.1c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.5-1.1,1.9-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1231.3,446,1230.4,447.3,1231.1,449.1"/>
				<path fill="#FAA634" d="M1221.2,448.2c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.6-1.2,1.9-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1221.4,445.1,1220.6,446.4,1221.2,448.2"/>
				<path fill="#FAA634" d="M1211.3,447.3c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.1,2.6-1.2,1.9-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1211.5,444.3,1210.6,445.6,1211.3,447.3"/>
				<path fill="#FAA634" d="M1201.3,446.5c0.6,1.8,2.6,3.3,4.3,3.5c1.7,0.1,2.6-1.2,2-2.9c-0.6-1.8-2.6-3.3-4.3-3.5
		S1200.7,444.7,1201.3,446.5"/>
				<path fill="#FAA634" d="M1191.3,445.6c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.6-1.2,2-2.9c-0.6-1.8-2.6-3.3-4.3-3.5
		S1190.7,443.9,1191.3,445.6"/>
				<path fill="#FAA634" d="M1181.3,444.8c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.7-1.2,2-2.9c-0.6-1.8-2.5-3.3-4.3-3.5
		C1181.6,441.7,1180.7,443,1181.3,444.8"/>
				<path fill="#FAA634" d="M1161.1,443.2c0.6,1.8,2.5,3.3,4.2,3.5c1.8,0.1,2.7-1.2,2.1-3s-2.5-3.3-4.2-3.5
		C1161.4,440.1,1160.5,441.4,1161.1,443.2"/>
				<path fill="#FAA634" d="M1266.1,442.8c0.8,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.4-1.1,1.7-2.9c-0.8-1.8-2.7-3.3-4.4-3.5
		C1266.1,439.7,1265.3,441,1266.1,442.8"/>
				<path fill="#FAA634" d="M1237,440c0.7,1.8,2.7,3.3,4.4,3.5c1.7,0.2,2.5-1.1,1.8-2.9s-2.7-3.3-4.4-3.5S1236.3,438.2,1237,440" />
				<path fill="#FAA634" d="M1227.2,439.1c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.5-1.2,1.8-2.9c-0.7-1.8-2.7-3.3-4.3-3.5
		C1227.3,436,1226.5,437.3,1227.2,439.1"/>
				<path fill="#FAA634" d="M1217.4,438.2c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.5-1.2,1.8-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1217.6,435.1,1216.7,436.4,1217.4,438.2"/>
				<path fill="#FAA634" d="M1207.6,437.3c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.6-1.2,1.9-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1207.7,434.2,1206.9,435.5,1207.6,437.3"/>
				<path fill="#FAA634" d="M1197.7,436.5c0.6,1.8,2.6,3.3,4.3,3.5c1.7,0.1,2.6-1.2,1.9-2.9c-0.7-1.8-2.6-3.3-4.3-3.5
		C1197.9,433.4,1197,434.7,1197.7,436.5"/>
				<path fill="#FAA634" d="M1177.8,434.8c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.6-1.2,2-3s-2.5-3.3-4.3-3.5
		C1178,431.7,1177.1,433,1177.8,434.8"/>
				<path fill="#FAA634" d="M1167.8,434c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.7-1.2,2-3c-0.6-1.8-2.5-3.3-4.3-3.5
		C1168.1,430.9,1167.2,432.2,1167.8,434"/>
				<path fill="#FAA634" d="M1157.7,433.2c0.6,1.8,2.5,3.3,4.2,3.5c1.7,0.1,2.7-1.2,2.1-3s-2.5-3.3-4.2-3.5
		C1158.1,430.1,1157.1,431.4,1157.7,433.2"/>
				<path fill="#FAA634" d="M1232.9,429.9c0.7,1.8,2.7,3.4,4.4,3.5c1.7,0.2,2.5-1.2,1.7-2.9c-0.7-1.8-2.7-3.4-4.4-3.5
		C1233,426.8,1232.2,428.2,1232.9,429.9"/>
				<path fill="#FAA634" d="M1223.3,429c0.7,1.8,2.6,3.4,4.3,3.5c1.7,0.2,2.5-1.2,1.8-2.9s-2.7-3.4-4.3-3.5
		C1223.3,425.9,1222.5,427.3,1223.3,429"/>
				<path fill="#FAA634" d="M1213.5,428.1c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.5-1.2,1.8-2.9c-0.7-1.8-2.6-3.4-4.3-3.5
		C1213.6,425,1212.8,426.4,1213.5,428.1"/>
				<path fill="#FAA634" d="M1203.7,427.3c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.2,2.5-1.2,1.8-2.9c-0.7-1.8-2.6-3.4-4.3-3.5
		C1203.9,424.2,1203.1,425.5,1203.7,427.3"/>
				<path fill="#FAA634" d="M1193.9,426.4c0.7,1.8,2.6,3.3,4.3,3.5c1.7,0.1,2.5-1.2,1.9-3c-0.7-1.8-2.6-3.4-4.3-3.5
		S1193.3,424.6,1193.9,426.4"/>
				<path fill="#FAA634" d="M1164.3,423.9c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.6-1.2,2-3s-2.5-3.3-4.3-3.5
		C1164.5,420.8,1163.7,422.1,1164.3,423.9"/>
				<path fill="#FAA634" d="M1154.3,423.1c0.6,1.8,2.5,3.3,4.2,3.5c1.7,0.1,2.6-1.2,2-3s-2.5-3.3-4.2-3.5
		C1154.6,420,1153.7,421.3,1154.3,423.1"/>
				<path fill="#FAA634" d="M1144.3,422.3c0.6,1.8,2.5,3.3,4.2,3.5c1.7,0.1,2.7-1.2,2.1-3s-2.5-3.3-4.2-3.5
		C1144.7,419.2,1143.7,420.5,1144.3,422.3"/>
				<path fill="#FAA634" d="M1257.4,422.7c0.8,1.8,2.7,3.4,4.4,3.5c1.6,0.2,2.3-1.1,1.6-2.9c-0.8-1.8-2.8-3.4-4.4-3.5
		C1257.3,419.6,1256.6,420.9,1257.4,422.7"/>
				<path fill="#FAA634" d="M1228.8,419.9c0.7,1.8,2.7,3.4,4.3,3.5c1.7,0.2,2.4-1.2,1.7-2.9c-0.7-1.8-2.7-3.4-4.4-3.5
		S1228.1,418.1,1228.8,419.9"/>
				<path fill="#FAA634" d="M1219.2,419c0.7,1.8,2.7,3.4,4.3,3.5c1.7,0.2,2.4-1.2,1.7-2.9c-0.7-1.8-2.7-3.4-4.3-3.5
		C1219.2,415.8,1218.5,417.2,1219.2,419"/>
				<path fill="#FAA634" d="M1160.7,413.8c0.6,1.8,2.5,3.3,4.3,3.5c1.7,0.1,2.6-1.2,1.9-3c-0.6-1.8-2.6-3.4-4.3-3.5
		C1160.9,410.7,1160.1,412,1160.7,413.8"/>
				<path fill="#FAA634" d="M1150.8,413c0.6,1.8,2.5,3.3,4.2,3.5c1.7,0.1,2.6-1.2,2-3s-2.5-3.4-4.2-3.5
		C1151.1,409.9,1150.2,411.2,1150.8,413"/>
				<path fill="#FAA634" d="M1380,427.4c1,1.8,3,3.4,4.5,3.6s1.9-1.1,1-2.9c-1-1.8-3-3.4-4.5-3.6C1379.5,424.4,1379.1,425.6,1380,427.4" />
				<path fill="#FAA634" d="M1371.3,426.3c1,1.8,3,3.4,4.5,3.6s2-1.1,1-2.9s-3-3.4-4.5-3.6C1370.8,423.2,1370.3,424.5,1371.3,426.3" />
				<path fill="#FAA634" d="M1362.5,425.2c1,1.8,3,3.4,4.5,3.6s2-1.1,1-2.9s-3-3.4-4.5-3.6S1361.5,423.4,1362.5,425.2" />
				<path fill="#FAA634" d="M1308.6,418.7c0.9,1.8,2.9,3.4,4.5,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.9-3.4-4.5-3.6S1307.7,416.9,1308.6,418.7"
				/>
				<path fill="#FAA634" d="M1253,412.6c0.8,1.8,2.8,3.4,4.4,3.5c1.6,0.2,2.3-1.1,1.5-2.9s-2.8-3.4-4.4-3.5
		C1252.9,409.5,1252.2,410.8,1253,412.6"/>
				<path fill="#FAA634" d="M1243.6,411.6c0.8,1.8,2.7,3.4,4.4,3.5c1.6,0.2,2.3-1.1,1.5-2.9s-2.7-3.4-4.4-3.5
		C1243.5,408.5,1242.8,409.9,1243.6,411.6"/>
				<path fill="#FAA634" d="M1224.6,409.8c0.7,1.8,2.7,3.4,4.3,3.5c1.7,0.2,2.4-1.2,1.6-2.9c-0.8-1.8-2.7-3.4-4.4-3.5
		C1224.6,406.7,1223.9,408,1224.6,409.8"/>
				<path fill="#FAA634" d="M1157.1,403.7c0.6,1.8,2.5,3.4,4.2,3.5s2.5-1.2,1.9-3c-0.7-1.8-2.6-3.4-4.3-3.5
		C1157.3,400.6,1156.4,401.9,1157.1,403.7"/>
				<path fill="#FAA634" d="M1147.3,402.9c0.6,1.8,2.5,3.4,4.2,3.5s2.6-1.2,1.9-3c-0.6-1.8-2.5-3.4-4.2-3.5
		C1147.5,399.8,1146.7,401.1,1147.3,402.9"/>
				<path fill="#FAA634" d="M1137.5,402.1c0.6,1.8,2.5,3.4,4.2,3.5s2.6-1.2,2-3s-2.5-3.4-4.2-3.5C1137.7,399,1136.8,400.3,1137.5,402.1" />
				<path fill="#FAA634" d="M1391.7,419.7c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.8-2.9c-1-1.8-3-3.4-4.5-3.6
		C1391.1,416.6,1390.7,417.9,1391.7,419.7"/>
				<path fill="#FAA634" d="M1383.1,418.5c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6S1382.1,416.7,1383.1,418.5" />
				<path fill="#FAA634" d="M1374.5,417.4c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6S1373.5,415.6,1374.5,417.4" />
				<path fill="#FAA634" d="M1365.8,416.2c1,1.8,3,3.4,4.5,3.6s1.9-1.1,1-2.9c-1-1.8-3-3.4-4.5-3.6
		C1365.2,413.2,1364.8,414.4,1365.8,416.2"/>
				<path fill="#FAA634" d="M1357.1,415.1c1,1.8,3,3.4,4.5,3.6s2-1.1,1-2.9s-3-3.4-4.5-3.6C1356.5,412,1356.1,413.3,1357.1,415.1" />
				<path fill="#FAA634" d="M1348.3,414c0.9,1.8,3,3.4,4.5,3.6s2-1.1,1-2.9s-3-3.4-4.5-3.6S1347.3,412.2,1348.3,414" />
				<path fill="#FAA634" d="M1330.5,411.8c0.9,1.8,2.9,3.4,4.5,3.6c1.5,0.2,2-1.1,1.1-2.9s-2.9-3.4-4.5-3.6
		C1330.1,408.7,1329.6,410,1330.5,411.8"/>
				<path fill="#FAA634" d="M1285.4,406.5c0.9,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1285.1,403.4,1284.5,404.7,1285.4,406.5"/>
				<path fill="#FAA634" d="M1276.2,405.5c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1276,402.4,1275.4,403.7,1276.2,405.5"/>
				<path fill="#FAA634" d="M1267,404.5c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6S1266.2,402.7,1267,404.5" />
				<path fill="#FAA634" d="M1257.8,403.5c0.8,1.8,2.8,3.4,4.4,3.5c1.6,0.2,2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6
		C1257.6,400.4,1257,401.7,1257.8,403.5"/>
				<path fill="#FAA634" d="M1248.5,402.5c0.8,1.8,2.8,3.4,4.4,3.5c1.6,0.2,2.3-1.1,1.5-2.9s-2.8-3.4-4.4-3.5
		C1248.3,399.4,1247.7,400.7,1248.5,402.5"/>
				<path fill="#FAA634" d="M1153.4,393.6c0.7,1.8,2.6,3.4,4.2,3.5c1.7,0.1,2.5-1.2,1.8-3s-2.6-3.4-4.2-3.5
		C1153.5,390.4,1152.7,391.8,1153.4,393.6"/>
				<path fill="#FAA634" d="M1143.7,392.7c0.6,1.8,2.5,3.4,4.2,3.5s2.5-1.2,1.9-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1143.9,389.6,1143,391,1143.7,392.7"/>
				<path fill="#FAA634" d="M1377.4,408.4c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.8-2.9c-1-1.8-3-3.4-4.5-3.6
		C1376.8,405.4,1376.4,406.7,1377.4,408.4"/>
				<path fill="#FAA634" d="M1368.9,407.3c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6C1368.3,404.2,1367.9,405.5,1368.9,407.3" />
				<path fill="#FAA634" d="M1351.6,405c1,1.8,3,3.4,4.5,3.6s1.9-1.1,1-2.9c-1-1.8-3-3.4-4.5-3.6C1351,401.9,1350.6,403.2,1351.6,405" />
				<path fill="#FAA634" d="M1271.4,395.4c0.9,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1271.2,392.3,1270.6,393.6,1271.4,395.4"/>
				<path fill="#FAA634" d="M1262.3,394.3c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1262,391.2,1261.5,392.6,1262.3,394.3"/>
				<path fill="#FAA634" d="M1243.9,392.4c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6
		C1243.7,389.3,1243.1,390.6,1243.9,392.4"/>
				<path fill="#FAA634" d="M1234.6,391.4c0.8,1.8,2.8,3.4,4.4,3.5c1.6,0.2,2.3-1.1,1.5-2.9s-2.8-3.4-4.4-3.5
		C1234.5,388.3,1233.8,389.6,1234.6,391.4"/>
				<path fill="#FAA634" d="M1178.3,385.9c0.7,1.8,2.6,3.4,4.3,3.5c1.7,0.2,2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.3-3.5
		C1178.3,382.8,1177.6,384.1,1178.3,385.9"/>
				<path fill="#FAA634" d="M1168.8,385.1c0.7,1.8,2.6,3.4,4.3,3.5c1.7,0.1,2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.3-3.5
		C1168.8,381.9,1168.1,383.3,1168.8,385.1"/>
				<path fill="#FAA634" d="M1159.2,384.2c0.7,1.8,2.6,3.4,4.3,3.5c1.7,0.1,2.5-1.2,1.8-3s-2.6-3.4-4.3-3.5
		C1159.3,381.1,1158.5,382.4,1159.2,384.2"/>
				<path fill="#FAA634" d="M1140,382.6c0.7,1.8,2.5,3.4,4.2,3.5s2.5-1.2,1.8-3s-2.6-3.4-4.2-3.5C1140.2,379.5,1139.3,380.8,1140,382.6" />
				<path fill="#FAA634" d="M1061.9,376.8c0.5,1.8,2.4,3.4,4.1,3.5s2.7-1.3,2.1-3.1c-0.5-1.8-2.4-3.4-4.1-3.5
		C1062.3,373.6,1061.3,375,1061.9,376.8"/>
				<path fill="#FAA634" d="M1371.7,398.4c1,1.8,3,3.4,4.5,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.5-3.6C1371.1,395.3,1370.7,396.6,1371.7,398.4" />
				<path fill="#FAA634" d="M1363.2,397.2c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.8-2.9c-1-1.8-3-3.4-4.5-3.6
		C1362.6,394.1,1362.2,395.4,1363.2,397.2"/>
				<path fill="#FAA634" d="M1354.7,396.1c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6S1353.7,394.3,1354.7,396.1" />
				<path fill="#FAA634" d="M1346.1,394.9c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6C1345.5,391.9,1345.1,393.1,1346.1,394.9" />
				<path fill="#FAA634" d="M1275.6,386.3c0.9,1.8,2.9,3.4,4.4,3.6c1.6,0.2,2.1-1.1,1.2-2.9s-2.9-3.4-4.4-3.6
		C1275.2,383.2,1274.7,384.5,1275.6,386.3"/>
				<path fill="#FAA634" d="M1257.5,384.2c0.9,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1257.2,381.1,1256.7,382.4,1257.5,384.2"/>
				<path fill="#FAA634" d="M1239.3,382.2c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6
		C1239.1,379.1,1238.4,380.4,1239.3,382.2"/>
				<path fill="#FAA634" d="M1230.1,381.3c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6
		C1229.9,378.1,1229.3,379.5,1230.1,381.3"/>
				<path fill="#FAA634" d="M1174.2,375.8c0.7,1.8,2.6,3.4,4.3,3.5c1.6,0.2,2.4-1.2,1.6-3s-2.7-3.4-4.3-3.5
		C1174.2,372.6,1173.5,374,1174.2,375.8"/>
				<path fill="#FAA634" d="M1164.8,374.9c0.7,1.8,2.6,3.4,4.3,3.5c1.6,0.1,2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.3-3.5
		C1164.8,371.8,1164.1,373.1,1164.8,374.9"/>
				<path fill="#FAA634" d="M1155.3,374.1c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1155.4,370.9,1154.6,372.3,1155.3,374.1"/>
				<path fill="#FAA634" d="M1136.3,372.4c0.7,1.8,2.6,3.4,4.2,3.5c1.7,0.1,2.5-1.2,1.8-3s-2.6-3.4-4.2-3.5
		C1136.4,369.3,1135.6,370.6,1136.3,372.4"/>
				<path fill="#FAA634" d="M1049,366c0.5,1.8,2.3,3.4,4,3.5s2.7-1.3,2.1-3.1c-0.5-1.8-2.4-3.4-4-3.5C1049.4,362.8,1048.5,364.2,1049,366"
				/>
				<path fill="#FAA634" d="M1039.2,365.4c0.5,1.8,2.3,3.3,4,3.5c1.7,0.1,2.7-1.3,2.2-3.1c-0.5-1.8-2.3-3.4-4-3.5
		C1039.6,362.2,1038.7,363.6,1039.2,365.4"/>
				<path fill="#FAA634" d="M1415.5,395.5c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1414.4,393.7,1415.5,395.5"
				/>
				<path fill="#FAA634" d="M1399.2,393c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1398.4,390,1398.1,391.3,1399.2,393"/>
				<path fill="#FAA634" d="M1279.5,377.2c0.9,1.8,2.9,3.4,4.4,3.6s2.1-1.1,1.1-2.9c-0.9-1.8-2.9-3.4-4.4-3.6S1278.6,375.4,1279.5,377.2"
				/>
				<path fill="#FAA634" d="M1270.6,376.1c0.9,1.8,2.9,3.4,4.4,3.6s2.1-1.1,1.2-2.9s-2.9-3.4-4.4-3.6
		C1270.2,373,1269.7,374.3,1270.6,376.1"/>
				<path fill="#FAA634" d="M1261.6,375.1c0.9,1.8,2.8,3.4,4.4,3.6s2.1-1.1,1.2-2.9s-2.9-3.4-4.4-3.6
		C1261.3,372,1260.8,373.3,1261.6,375.1"/>
				<path fill="#FAA634" d="M1225.5,371.1c0.8,1.8,2.8,3.4,4.4,3.6s2.2-1.1,1.4-2.9c-0.8-1.8-2.8-3.4-4.4-3.6S1224.7,369.3,1225.5,371.1"
				/>
				<path fill="#FAA634" d="M1170.1,365.6c0.7,1.8,2.6,3.4,4.3,3.5c1.6,0.2,2.3-1.2,1.6-3c-0.7-1.8-2.7-3.4-4.3-3.5
		C1170.1,362.5,1169.3,363.8,1170.1,365.6"/>
				<path fill="#FAA634" d="M1160.7,364.7c0.7,1.8,2.6,3.4,4.3,3.5c1.6,0.1,2.4-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.3-3.5
		C1160.7,361.6,1160,362.9,1160.7,364.7"/>
				<path fill="#FAA634" d="M1151.3,363.9c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5S1150.6,362.1,1151.3,363.9" />
				<path fill="#FAA634" d="M1141.9,363.1c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1142,359.9,1141.2,361.3,1141.9,363.1"/>
				<path fill="#FAA634" d="M1132.5,362.3c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1132.6,359.1,1131.8,360.5,1132.5,362.3"/>
				<path fill="#FAA634" d="M1046,355.8c0.5,1.8,2.3,3.4,4,3.5s2.6-1.3,2.1-3.1c-0.5-1.8-2.4-3.4-4-3.5
		C1046.4,352.6,1045.5,354,1046,355.8"/>
				<path fill="#FAA634" d="M1036.3,355.2c0.5,1.8,2.3,3.3,4,3.5c1.7,0.1,2.6-1.3,2.1-3.1c-0.5-1.8-2.3-3.4-4-3.5
		C1036.7,352,1035.8,353.4,1036.3,355.2"/>
				<path fill="#FAA634" d="M1026.5,354.6c0.5,1.8,2.3,3.3,4,3.4s2.7-1.3,2.1-3.1c-0.5-1.8-2.3-3.3-4-3.5
		C1027,351.4,1026,352.8,1026.5,354.6"/>
				<path fill="#FAA634" d="M1433.2,389.1c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.4-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1432,387.4,1433.2,389.1" />
				<path fill="#FAA634" d="M1425.3,387.9c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6
		C1424.3,384.8,1424.1,386.1,1425.3,387.9"/>
				<path fill="#FAA634" d="M1417.3,386.6c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1416.2,384.9,1417.3,386.6"
				/>
				<path fill="#FAA634" d="M1409.3,385.4c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1408.2,383.6,1409.3,385.4"
				/>
				<path fill="#FAA634" d="M1401.2,384.2c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1400.4,381.1,1400.1,382.4,1401.2,384.2"/>
				<path fill="#FAA634" d="M1393.1,383c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1392.3,379.9,1392,381.2,1393.1,383"/>
				<path fill="#FAA634" d="M1384.9,381.8c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1384.2,378.7,1383.9,380,1384.9,381.8"/>
				<path fill="#FAA634" d="M1351.8,377c1,1.8,3,3.4,4.5,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.5-3.6C1351.1,374,1350.7,375.3,1351.8,377" />
				<path fill="#FAA634" d="M1343.3,375.9c1,1.8,3,3.4,4.5,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.5-3.6C1342.7,372.8,1342.3,374.1,1343.3,375.9" />
				<path fill="#FAA634" d="M1326.4,373.6c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6S1325.4,371.8,1326.4,373.6" />
				<path fill="#FAA634" d="M1317.8,372.5c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6C1317.3,369.4,1316.8,370.7,1317.8,372.5" />
				<path fill="#FAA634" d="M1274.4,367c0.9,1.8,2.9,3.4,4.4,3.6s2-1.1,1.1-2.9s-2.9-3.4-4.4-3.6C1273.9,363.9,1273.4,365.3,1274.4,367" />
				<path fill="#FAA634" d="M1265.5,366c0.9,1.8,2.9,3.4,4.4,3.6s2-1.1,1.1-2.9s-2.9-3.4-4.4-3.6S1264.6,364.2,1265.5,366" />
				<path fill="#FAA634" d="M1238.8,362.9c0.9,1.8,2.8,3.4,4.4,3.6s2.1-1.1,1.3-2.9c-0.9-1.8-2.8-3.4-4.4-3.6
		C1238.5,359.8,1238,361.1,1238.8,362.9"/>
				<path fill="#FAA634" d="M1220.8,361c0.8,1.8,2.8,3.4,4.3,3.6c1.6,0.2,2.2-1.1,1.3-2.9c-0.8-1.8-2.8-3.4-4.3-3.6
		C1220.6,357.8,1220,359.2,1220.8,361"/>
				<path fill="#FAA634" d="M1156.6,354.6c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1156.6,351.4,1155.9,352.8,1156.6,354.6"/>
				<path fill="#FAA634" d="M1147.3,353.7c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5S1146.6,351.9,1147.3,353.7" />
				<path fill="#FAA634" d="M1138,352.9c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1138,349.8,1137.3,351.1,1138,352.9"/>
				<path fill="#FAA634" d="M1128.6,352.1c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1128.7,348.9,1127.9,350.3,1128.6,352.1"/>
				<path fill="#FAA634" d="M1119.2,351.3c0.7,1.8,2.5,3.4,4.2,3.5c1.6,0.1,2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1119.3,348.1,1118.5,349.5,1119.2,351.3"/>
				<path fill="#FAA634" d="M1109.8,350.5c0.7,1.8,2.5,3.4,4.2,3.5c1.6,0.1,2.4-1.2,1.8-3c-0.7-1.8-2.5-3.4-4.2-3.5
		C1109.9,347.4,1109.1,348.7,1109.8,350.5"/>
				<path fill="#FAA634" d="M1043,345.6c0.5,1.8,2.3,3.4,4,3.5s2.6-1.3,2-3.1c-0.6-1.8-2.4-3.4-4-3.5
		C1043.3,342.4,1042.4,343.8,1043,345.6"/>
				<path fill="#FAA634" d="M1033.3,345c0.5,1.8,2.3,3.3,4,3.5s2.6-1.3,2.1-3.1c-0.5-1.8-2.3-3.3-4-3.5S1032.8,343.2,1033.3,345" />
				<path fill="#FAA634" d="M1418.9,377.9c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.4-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1418,374.8,1417.8,376.1,1418.9,377.9"/>
				<path fill="#FAA634" d="M1411,376.6c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6S1409.9,374.8,1411,376.6" />
				<path fill="#FAA634" d="M1403.1,375.4c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1402.2,372.3,1402,373.6,1403.1,375.4"/>
				<path fill="#FAA634" d="M1395.1,374.1c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1394,372.4,1395.1,374.1" />
				<path fill="#FAA634" d="M1387,372.9c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1385.9,371.1,1387,372.9" />
				<path fill="#FAA634" d="M1378.9,371.7c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1377.8,369.9,1378.9,371.7"
				/>
				<path fill="#FAA634" d="M1370.8,370.5c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1369.7,368.7,1370.8,370.5"
				/>
				<path fill="#FAA634" d="M1362.5,369.3c1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.7-2.9c-1.1-1.8-3.1-3.4-4.5-3.6
		C1361.8,366.3,1361.5,367.5,1362.5,369.3"/>
				<path fill="#FAA634" d="M1354.3,368.1c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.8-1.1,0.7-2.9c-1-1.8-3.1-3.4-4.5-3.6
		C1353.6,365.1,1353.2,366.3,1354.3,368.1"/>
				<path fill="#FAA634" d="M1320.8,363.5c1,1.8,3,3.4,4.5,3.6s1.9-1.1,0.9-2.9s-3-3.4-4.5-3.6C1320.2,360.4,1319.8,361.7,1320.8,363.5" />
				<path fill="#FAA634" d="M1260.4,355.9c0.9,1.8,2.9,3.4,4.4,3.6s2-1.1,1.1-2.9s-2.9-3.4-4.4-3.6S1259.5,354.1,1260.4,355.9" />
				<path fill="#FAA634" d="M1179.9,347.1c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1179.8,343.9,1179.2,345.3,1179.9,347.1"/>
				<path fill="#FAA634" d="M1170.8,346.2c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.5-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1170.7,343,1170.1,344.4,1170.8,346.2"/>
				<path fill="#FAA634" d="M1152.5,344.4c0.7,1.8,2.6,3.4,4.2,3.5c1.6,0.2,2.3-1.2,1.5-3c-0.7-1.8-2.6-3.4-4.2-3.5
		S1151.7,342.6,1152.5,344.4"/>
				<path fill="#FAA634" d="M1143.2,343.6c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1143.2,340.4,1142.5,341.8,1143.2,343.6"/>
				<path fill="#FAA634" d="M1134,342.7c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5S1133.3,340.9,1134,342.7" />
				<path fill="#FAA634" d="M1124.7,341.9c0.7,1.8,2.6,3.4,4.2,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1124.7,338.8,1124,340.1,1124.7,341.9"/>
				<path fill="#FAA634" d="M1115.4,341.1c0.7,1.8,2.5,3.4,4.2,3.5c1.6,0.1,2.4-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		S1114.7,339.3,1115.4,341.1"/>
				<path fill="#FAA634" d="M1106,340.3c0.7,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.5-3.4-4.1-3.5
		C1106.1,337.2,1105.4,338.5,1106,340.3"/>
				<path fill="#FAA634" d="M1058.9,336.7c0.6,1.8,2.4,3.4,4,3.5c1.7,0.1,2.5-1.2,1.9-3s-2.4-3.4-4-3.5
		C1059.2,333.6,1058.3,334.9,1058.9,336.7"/>
				<path fill="#FAA634" d="M1049.4,336.1c0.6,1.8,2.4,3.4,4,3.5c1.7,0.1,2.5-1.3,2-3.1c-0.6-1.8-2.4-3.4-4-3.5
		C1049.7,332.9,1048.8,334.3,1049.4,336.1"/>
				<path fill="#FAA634" d="M1039.9,335.4c0.6,1.8,2.3,3.3,4,3.5c1.7,0.1,2.6-1.3,2-3.1c-0.6-1.8-2.4-3.3-4-3.5
		C1040.2,332.3,1039.3,333.6,1039.9,335.4"/>
				<path fill="#FAA634" d="M1030.3,334.8c0.5,1.8,2.3,3.3,4,3.5c1.7,0.1,2.6-1.3,2-3.1c-0.5-1.8-2.3-3.3-4-3.5
		C1030.7,331.6,1029.8,333,1030.3,334.8"/>
				<path fill="#FAA634" d="M1020.7,334.2c0.5,1.8,2.3,3.3,4,3.4s2.6-1.3,2.1-3.1c-0.5-1.8-2.3-3.3-4-3.4S1020.2,332.4,1020.7,334.2" />
				<path fill="#FAA634" d="M1412.6,367.8c1.1,1.8,3.1,3.4,4.5,3.6s1.5-1,0.4-2.8s-3.1-3.4-4.5-3.6
		C1411.7,364.8,1411.5,366.1,1412.6,367.8"/>
				<path fill="#FAA634" d="M1396.9,365.3c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6S1395.8,363.6,1396.9,365.3" />
				<path fill="#FAA634" d="M1388.9,364.1c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1388,361,1387.8,362.3,1388.9,364.1"/>
				<path fill="#FAA634" d="M1380.9,362.9c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1380.1,359.8,1379.8,361.1,1380.9,362.9"/>
				<path fill="#FAA634" d="M1372.9,361.6c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1372,358.6,1371.8,359.9,1372.9,361.6"/>
				<path fill="#FAA634" d="M1364.7,360.4c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1364,357.4,1363.7,358.7,1364.7,360.4"/>
				<path fill="#FAA634" d="M1356.6,359.2c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.9s-3.1-3.4-4.5-3.6
		C1355.8,356.2,1355.5,357.5,1356.6,359.2"/>
				<path fill="#FAA634" d="M1348.4,358c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.7-1.1,0.7-2.9c-1.1-1.8-3.1-3.4-4.5-3.6
		C1347.7,355,1347.4,356.3,1348.4,358"/>
				<path fill="#FAA634" d="M1340.2,356.9c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.8-1.1,0.7-2.9c-1-1.8-3-3.4-4.5-3.6
		C1339.4,353.8,1339.1,355.1,1340.2,356.9"/>
				<path fill="#FAA634" d="M1331.9,355.7c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.8-1.1,0.7-2.9c-1-1.8-3-3.4-4.5-3.6
		C1331.2,352.6,1330.8,353.9,1331.9,355.7"/>
				<path fill="#FAA634" d="M1323.5,354.5c1,1.8,3,3.4,4.5,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.5-3.6C1322.9,351.5,1322.5,352.8,1323.5,354.5" />
				<path fill="#FAA634" d="M1306.7,352.3c1,1.8,3,3.4,4.4,3.6c1.5,0.2,1.8-1.1,0.9-2.9c-1-1.8-3-3.4-4.4-3.6
		C1306.1,349.2,1305.7,350.5,1306.7,352.3"/>
				<path fill="#FAA634" d="M1237.8,343.7c0.9,1.8,2.8,3.4,4.4,3.6c1.5,0.2,2-1.1,1.1-2.9s-2.9-3.4-4.4-3.6S1236.9,341.9,1237.8,343.7" />
				<path fill="#FAA634" d="M1229,342.6c0.9,1.8,2.8,3.4,4.4,3.6c1.5,0.2,2.1-1.1,1.2-2.9s-2.8-3.4-4.4-3.6
		C1228.7,339.5,1228.1,340.9,1229,342.6"/>
				<path fill="#FAA634" d="M1220.2,341.6c0.9,1.8,2.8,3.4,4.3,3.6s2.1-1.1,1.2-2.9s-2.8-3.4-4.3-3.6
		C1219.9,338.5,1219.3,339.9,1220.2,341.6"/>
				<path fill="#FAA634" d="M1211.3,340.7c0.8,1.8,2.8,3.4,4.3,3.6s2.1-1.1,1.2-2.9s-2.8-3.4-4.3-3.6S1210.5,338.9,1211.3,340.7" />
				<path fill="#FAA634" d="M1184.5,337.8c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1184.3,334.7,1183.7,336,1184.5,337.8"/>
				<path fill="#FAA634" d="M1175.5,336.9c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1175.3,333.8,1174.7,335.1,1175.5,336.9"/>
				<path fill="#FAA634" d="M1166.4,336c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.4-3s-2.7-3.4-4.3-3.5
		C1166.3,332.9,1165.7,334.2,1166.4,336"/>
				<path fill="#FAA634" d="M1157.4,335.1c0.8,1.8,2.7,3.4,4.2,3.5c1.6,0.2,2.2-1.2,1.5-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1157.3,332,1156.6,333.3,1157.4,335.1"/>
				<path fill="#FAA634" d="M1148.3,334.2c0.7,1.8,2.6,3.4,4.2,3.5c1.6,0.2,2.3-1.2,1.5-3s-2.6-3.4-4.2-3.5
		C1148.2,331.1,1147.5,332.4,1148.3,334.2"/>
				<path fill="#FAA634" d="M1139.1,333.4c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.5-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1139.1,330.3,1138.4,331.6,1139.1,333.4"/>
				<path fill="#FAA634" d="M1130,332.6c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1129.9,329.4,1129.2,330.8,1130,332.6"/>
				<path fill="#FAA634" d="M1120.8,331.7c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5S1120.1,329.9,1120.8,331.7" />
				<path fill="#FAA634" d="M1111.5,331c0.7,1.8,2.5,3.4,4.2,3.5c1.6,0.1,2.3-1.2,1.7-3c-0.7-1.8-2.6-3.4-4.2-3.5
		C1111.6,327.8,1110.8,329.2,1111.5,331"/>
				<path fill="#FAA634" d="M1102.3,330.2c0.7,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.5-3.4-4.1-3.5
		C1102.3,327,1101.6,328.4,1102.3,330.2"/>
				<path fill="#FAA634" d="M1093,329.4c0.7,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.5-3.4-4.1-3.5S1092.3,327.6,1093,329.4" />
				<path fill="#FAA634" d="M1083.7,328.7c0.6,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.8-3s-2.5-3.4-4.1-3.5C1083.8,325.5,1083,326.9,1083.7,328.7"
				/>
				<path fill="#FAA634" d="M1065,327.3c0.6,1.8,2.4,3.4,4.1,3.5c1.6,0.1,2.5-1.2,1.8-3c-0.6-1.8-2.4-3.4-4.1-3.5
		C1065.2,324.1,1064.3,325.5,1065,327.3"/>
				<path fill="#FAA634" d="M1055.6,326.6c0.6,1.8,2.4,3.4,4,3.5s2.5-1.2,1.9-3s-2.4-3.4-4-3.5C1055.8,323.4,1055,324.8,1055.6,326.6" />
				<path fill="#FAA634" d="M1046.1,325.9c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.5-1.3,1.9-3c-0.6-1.8-2.4-3.3-4-3.5
		C1046.4,322.7,1045.6,324.1,1046.1,325.9"/>
				<path fill="#FAA634" d="M1036.7,325.3c0.6,1.8,2.3,3.3,4,3.5c1.6,0.1,2.5-1.3,1.9-3.1c-0.6-1.8-2.4-3.3-4-3.5
		C1037,322.1,1036.1,323.5,1036.7,325.3"/>
				<path fill="#FAA634" d="M1027.2,324.6c0.5,1.8,2.3,3.3,4,3.5c1.6,0.1,2.5-1.3,2-3.1c-0.6-1.8-2.3-3.3-4-3.4
		C1027.6,321.5,1026.7,322.8,1027.2,324.6"/>
				<path fill="#FAA634" d="M1017.8,324c0.5,1.8,2.3,3.3,3.9,3.4c1.7,0.1,2.6-1.3,2-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C1018.1,320.9,1017.2,322.2,1017.8,324"/>
				<path fill="#FAA634" d="M1008.2,323.5c0.5,1.8,2.3,3.3,3.9,3.4c1.7,0.1,2.6-1.3,2.1-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C1008.7,320.3,1007.7,321.7,1008.2,323.5"/>
				<path fill="#FAA634" d="M1390.6,355.3c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6S1389.5,353.5,1390.6,355.3" />
				<path fill="#FAA634" d="M1382.7,354c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1381.8,351,1381.6,352.3,1382.7,354"/>
				<path fill="#FAA634" d="M1374.8,352.8c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6S1373.7,351,1374.8,352.8" />
				<path fill="#FAA634" d="M1366.8,351.6c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1365.9,348.5,1365.7,349.8,1366.8,351.6"/>
				<path fill="#FAA634" d="M1358.7,350.4c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1357.9,347.3,1357.6,348.6,1358.7,350.4"/>
				<path fill="#FAA634" d="M1350.6,349.2c1.1,1.8,3.1,3.4,4.5,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1349.8,346.1,1349.6,347.4,1350.6,349.2"/>
				<path fill="#FAA634" d="M1342.5,348c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.7-1.1,0.7-2.9c-1.1-1.8-3.1-3.4-4.5-3.6
		C1341.7,344.9,1341.4,346.2,1342.5,348"/>
				<path fill="#FAA634" d="M1334.3,346.8c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.7-1.1,0.7-2.9s-3-3.4-4.5-3.6
		C1333.6,343.7,1333.3,345,1334.3,346.8"/>
				<path fill="#FAA634" d="M1326.1,345.6c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.8-1.1,0.7-2.9c-1-1.8-3-3.4-4.5-3.6
		C1325.4,342.5,1325,343.8,1326.1,345.6"/>
				<path fill="#FAA634" d="M1317.8,344.4c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1317.1,341.4,1316.8,342.7,1317.8,344.4" />
				<path fill="#FAA634" d="M1309.5,343.3c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1308.8,340.2,1308.5,341.5,1309.5,343.3" />
				<path fill="#FAA634" d="M1292.7,341c1,1.8,3,3.4,4.4,3.6c1.5,0.2,1.8-1.1,0.9-2.9c-1-1.8-3-3.4-4.4-3.6
		C1292.1,338,1291.7,339.2,1292.7,341"/>
				<path fill="#FAA634" d="M1206.5,330.5c0.9,1.8,2.8,3.4,4.3,3.6s2.1-1.1,1.2-2.9s-2.8-3.4-4.3-3.6S1205.6,328.7,1206.5,330.5" />
				<path fill="#FAA634" d="M1197.7,329.6c0.8,1.8,2.8,3.4,4.3,3.6s2.1-1.2,1.2-2.9c-0.9-1.8-2.8-3.4-4.3-3.6
		C1197.4,326.4,1196.8,327.8,1197.7,329.6"/>
				<path fill="#FAA634" d="M1188.8,328.6c0.8,1.8,2.7,3.4,4.3,3.5c1.5,0.2,2.1-1.2,1.3-2.9c-0.8-1.8-2.8-3.4-4.3-3.5
		C1188.6,325.5,1188,326.8,1188.8,328.6"/>
				<path fill="#FAA634" d="M1179.9,327.7c0.8,1.8,2.7,3.4,4.3,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1179.7,324.6,1179.1,325.9,1179.9,327.7"/>
				<path fill="#FAA634" d="M1171,326.7c0.8,1.8,2.7,3.4,4.3,3.5c1.6,0.2,2.2-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.3-3.5
		C1170.8,323.6,1170.2,325,1171,326.7"/>
				<path fill="#FAA634" d="M1162,325.8c0.8,1.8,2.7,3.4,4.2,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1161.9,322.7,1161.3,324.1,1162,325.8"/>
				<path fill="#FAA634" d="M1153,325c0.8,1.8,2.7,3.4,4.2,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1152.9,321.8,1152.3,323.2,1153,325"/>
				<path fill="#FAA634" d="M1144,324.1c0.8,1.8,2.6,3.4,4.2,3.5c1.6,0.2,2.2-1.2,1.5-3s-2.6-3.4-4.2-3.5S1143.3,322.3,1144,324.1" />
				<path fill="#FAA634" d="M1135,323.2c0.7,1.8,2.6,3.4,4.2,3.5s2.2-1.2,1.5-3c-0.8-1.8-2.6-3.4-4.2-3.5S1134.2,321.4,1135,323.2" />
				<path fill="#FAA634" d="M1125.9,322.4c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.5-3c-0.7-1.8-2.6-3.4-4.2-3.5S1125.2,320.6,1125.9,322.4" />
				<path fill="#FAA634" d="M1116.8,321.6c0.7,1.8,2.6,3.4,4.2,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.2-3.5S1116.1,319.8,1116.8,321.6" />
				<path fill="#FAA634" d="M1107.6,320.8c0.7,1.8,2.5,3.4,4.1,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.1-3.5
		C1107.6,317.7,1106.9,319,1107.6,320.8"/>
				<path fill="#FAA634" d="M1098.4,320c0.7,1.8,2.5,3.4,4.1,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.5-3.4-4.1-3.5
		C1098.5,316.9,1097.8,318.2,1098.4,320"/>
				<path fill="#FAA634" d="M1089.3,319.3c0.7,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.5-3.4-4.1-3.5
		C1089.3,316.1,1088.6,317.5,1089.3,319.3"/>
				<path fill="#FAA634" d="M1080,318.5c0.6,1.8,2.5,3.4,4.1,3.5s2.4-1.2,1.7-3c-0.7-1.8-2.5-3.4-4.1-3.5S1079.4,316.7,1080,318.5" />
				<path fill="#FAA634" d="M1070.8,317.8c0.6,1.8,2.4,3.4,4.1,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.5-3.4-4.1-3.5
		C1070.9,314.6,1070.1,316,1070.8,317.8"/>
				<path fill="#FAA634" d="M1061.5,317.1c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.4-3.3-4-3.5
		C1061.7,313.9,1060.9,315.3,1061.5,317.1"/>
				<path fill="#FAA634" d="M1052.2,316.4c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.4-3.3-4-3.5
		C1052.4,313.3,1051.6,314.6,1052.2,316.4"/>
				<path fill="#FAA634" d="M1042.9,315.8c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.5-1.2,1.9-3s-2.4-3.3-4-3.5
		C1043.1,312.6,1042.3,314,1042.9,315.8"/>
				<path fill="#FAA634" d="M1033.5,315.1c0.6,1.8,2.3,3.3,4,3.5c1.6,0.1,2.5-1.3,1.9-3.1c-0.6-1.8-2.4-3.3-4-3.4S1033,313.3,1033.5,315.1
		"/>
				<path fill="#FAA634" d="M1024.2,314.5c0.5,1.8,2.3,3.3,3.9,3.4s2.5-1.3,1.9-3.1c-0.6-1.8-2.3-3.3-3.9-3.4S1023.6,312.7,1024.2,314.5"
				/>
				<path fill="#FAA634" d="M1014.8,313.9c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C1015.1,310.7,1014.2,312.1,1014.8,313.9"/>
				<path fill="#FAA634" d="M1005.3,313.3c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C1005.7,310.1,1004.8,311.5,1005.3,313.3"/>
				<path fill="#FAA634" d="M1392.1,346.5c1.1,1.8,3.1,3.4,4.5,3.6c1.3,0.2,1.5-1,0.4-2.8s-3.1-3.4-4.5-3.6
		C1391.2,343.5,1391,344.7,1392.1,346.5"/>
				<path fill="#FAA634" d="M1384.3,345.3c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.4-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1383.4,342.2,1383.2,343.5,1384.3,345.3"/>
				<path fill="#FAA634" d="M1376.5,344c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6S1375.4,342.2,1376.5,344" />
				<path fill="#FAA634" d="M1368.6,342.8c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1367.7,339.7,1367.5,341,1368.6,342.8"/>
				<path fill="#FAA634" d="M1360.6,341.5c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1359.8,338.5,1359.6,339.7,1360.6,341.5"/>
				<path fill="#FAA634" d="M1328.4,336.7c1,1.8,3,3.4,4.5,3.6c1.4,0.2,1.7-1.1,0.7-2.9s-3-3.4-4.5-3.6
		C1327.7,333.6,1327.4,334.9,1328.4,336.7"/>
				<path fill="#FAA634" d="M1320.2,335.5c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1319.5,332.5,1319.2,333.7,1320.2,335.5" />
				<path fill="#FAA634" d="M1312,334.4c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.7-2.9c-1-1.8-3-3.4-4.4-3.6C1311.3,331.3,1311,332.6,1312,334.4" />
				<path fill="#FAA634" d="M1303.8,333.2c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1303.1,330.1,1302.8,331.4,1303.8,333.2" />
				<path fill="#FAA634" d="M1295.5,332.1c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1294.8,329,1294.5,330.3,1295.5,332.1" />
				<path fill="#FAA634" d="M1287.1,330.9c1,1.8,3,3.4,4.4,3.6c1.5,0.2,1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6
		C1286.5,327.9,1286.2,329.1,1287.1,330.9"/>
				<path fill="#FAA634" d="M1201.6,320.4c0.9,1.8,2.8,3.4,4.3,3.6s2-1.1,1.2-2.9c-0.9-1.8-2.8-3.4-4.3-3.6
		C1201.3,317.3,1200.8,318.6,1201.6,320.4"/>
				<path fill="#FAA634" d="M1192.9,319.4c0.8,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2.1-1.1,1.2-2.9s-2.8-3.4-4.3-3.5
		C1192.6,316.3,1192,317.6,1192.9,319.4"/>
				<path fill="#FAA634" d="M1184.1,318.5c0.8,1.8,2.7,3.4,4.3,3.5c1.5,0.2,2.1-1.2,1.2-2.9c-0.8-1.8-2.8-3.4-4.3-3.5
		C1183.8,315.4,1183.3,316.7,1184.1,318.5"/>
				<path fill="#FAA634" d="M1175.3,317.5c0.8,1.8,2.7,3.4,4.3,3.5c1.5,0.2,2.1-1.2,1.3-2.9c-0.8-1.8-2.7-3.4-4.3-3.5
		C1175.1,314.4,1174.5,315.7,1175.3,317.5"/>
				<path fill="#FAA634" d="M1166.5,316.6c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1166.2,313.5,1165.7,314.8,1166.5,316.6"/>
				<path fill="#FAA634" d="M1157.6,315.7c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.2-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.2-3.5
		S1156.8,313.9,1157.6,315.7"/>
				<path fill="#FAA634" d="M1148.7,314.8c0.8,1.8,2.7,3.4,4.2,3.5c1.6,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1148.5,311.7,1147.9,313,1148.7,314.8"/>
				<path fill="#FAA634" d="M1139.7,314c0.8,1.8,2.6,3.4,4.2,3.5s2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1139.6,310.8,1139,312.2,1139.7,314"/>
				<path fill="#FAA634" d="M1130.8,313.1c0.7,1.8,2.6,3.4,4.2,3.5s2.2-1.2,1.5-3c-0.8-1.8-2.6-3.4-4.2-3.5
		C1130.7,310,1130,311.3,1130.8,313.1"/>
				<path fill="#FAA634" d="M1121.8,312.3c0.7,1.8,2.6,3.4,4.2,3.5s2.2-1.2,1.5-3s-2.6-3.4-4.2-3.5S1121,310.5,1121.8,312.3" />
				<path fill="#FAA634" d="M1112.7,311.5c0.7,1.8,2.6,3.4,4.1,3.5c1.6,0.1,2.3-1.2,1.5-3c-0.7-1.8-2.6-3.4-4.1-3.5
		C1112.7,308.3,1112,309.7,1112.7,311.5"/>
				<path fill="#FAA634" d="M1103.7,310.7c0.7,1.8,2.5,3.4,4.1,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.6-3.4-4.1-3.5
		C1103.7,307.5,1103,308.9,1103.7,310.7"/>
				<path fill="#FAA634" d="M1094.6,309.9c0.7,1.8,2.5,3.4,4.1,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.5-3.4-4.1-3.5
		C1094.6,306.7,1093.9,308.1,1094.6,309.9"/>
				<path fill="#FAA634" d="M1085.5,309.1c0.7,1.8,2.5,3.4,4.1,3.5s2.3-1.2,1.6-3c-0.7-1.8-2.5-3.4-4.1-3.5S1084.8,307.3,1085.5,309.1" />
				<path fill="#FAA634" d="M1076.3,308.4c0.7,1.8,2.5,3.3,4.1,3.5c1.6,0.1,2.3-1.2,1.7-3c-0.7-1.8-2.5-3.3-4.1-3.5
		C1076.4,305.2,1075.7,306.6,1076.3,308.4"/>
				<path fill="#FAA634" d="M1067.2,307.7c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.7-3c-0.6-1.8-2.5-3.3-4-3.5
		C1067.3,304.5,1066.5,305.9,1067.2,307.7"/>
				<path fill="#FAA634" d="M1058,307c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.4-3.3-4-3.5C1058.2,303.8,1057.4,305.2,1058,307" />
				<path fill="#FAA634" d="M1048.8,306.3c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.4-3.3-4-3.5
		C1049,303.1,1048.2,304.5,1048.8,306.3"/>
				<path fill="#FAA634" d="M1039.6,305.6c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.4-1.2,1.8-3s-2.4-3.3-4-3.4
		C1039.8,302.5,1039,303.8,1039.6,305.6"/>
				<path fill="#FAA634" d="M1030.3,305c0.6,1.8,2.3,3.3,4,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.6-1.8-2.4-3.3-4-3.4
		C1030.6,301.8,1029.7,303.2,1030.3,305"/>
				<path fill="#FAA634" d="M1021,304.4c0.6,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,1.9-3.1c-0.6-1.8-2.3-3.3-3.9-3.4
		C1021.3,301.2,1020.5,302.6,1021,304.4"/>
				<path fill="#FAA634" d="M1011.7,303.8c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,1.9-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C1012.1,300.6,1011.2,302,1011.7,303.8"/>
				<path fill="#FAA634" d="M1002.4,303.2c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		S1001.9,301.4,1002.4,303.2"/>
				<path fill="#FAA634" d="M993.1,302.6c0.5,1.8,2.2,3.3,3.9,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.9-3.4
		C993.5,299.4,992.6,300.8,993.1,302.6"/>
				<path fill="#FAA634" d="M983.7,302.1c0.5,1.8,2.2,3.3,3.8,3.4s2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
		C984.1,298.9,983.2,300.3,983.7,302.1"/>
				<path fill="#FAA634" d="M1370.2,334c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.4-2.8c-1.1-1.8-3.1-3.4-4.5-3.6
		C1369.3,330.9,1369.1,332.2,1370.2,334"/>
				<path fill="#FAA634" d="M1362.4,332.7c1.1,1.8,3.1,3.4,4.5,3.6s1.6-1,0.5-2.8s-3.1-3.4-4.5-3.6S1361.3,330.9,1362.4,332.7" />
				<path fill="#FAA634" d="M1322.5,326.6c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.9s-3-3.4-4.4-3.6C1321.7,323.6,1321.4,324.9,1322.5,326.6" />
				<path fill="#FAA634" d="M1314.4,325.4c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1313.6,322.4,1313.3,323.7,1314.4,325.4" />
				<path fill="#FAA634" d="M1306.2,324.3c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1305.5,321.2,1305.2,322.5,1306.2,324.3" />
				<path fill="#FAA634" d="M1298,323.1c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1297.3,320.1,1297,321.3,1298,323.1" />
				<path fill="#FAA634" d="M1289.8,322c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1289.1,318.9,1288.8,320.2,1289.8,322" />
				<path fill="#FAA634" d="M1281.5,320.8c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1280.9,317.8,1280.5,319.1,1281.5,320.8" />
				<path fill="#FAA634" d="M1239.6,315.4c0.9,1.8,2.9,3.4,4.4,3.6s1.9-1.1,1-2.9s-2.9-3.4-4.4-3.6S1238.7,313.6,1239.6,315.4" />
				<path fill="#FAA634" d="M1196.8,310.3c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.3-3.5
		C1196.4,307.2,1195.9,308.5,1196.8,310.3"/>
				<path fill="#FAA634" d="M1188.1,309.3c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.2-2.9s-2.8-3.4-4.3-3.5
		C1187.7,306.2,1187.2,307.5,1188.1,309.3"/>
				<path fill="#FAA634" d="M1179.4,308.4c0.8,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2.1-1.1,1.2-2.9s-2.8-3.4-4.3-3.5
		C1179.1,305.3,1178.5,306.6,1179.4,308.4"/>
				<path fill="#FAA634" d="M1170.6,307.4c0.8,1.8,2.7,3.4,4.3,3.5c1.5,0.2,2.1-1.2,1.2-2.9c-0.8-1.8-2.7-3.4-4.3-3.5
		C1170.4,304.3,1169.8,305.6,1170.6,307.4"/>
				<path fill="#FAA634" d="M1161.9,306.5c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-2.9c-0.8-1.8-2.7-3.4-4.2-3.5
		C1161.6,303.4,1161.1,304.7,1161.9,306.5"/>
				<path fill="#FAA634" d="M1153.1,305.6c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.2-3.5
		S1152.3,303.8,1153.1,305.6"/>
				<path fill="#FAA634" d="M1144.3,304.7c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1144.1,301.6,1143.5,302.9,1144.3,304.7"/>
				<path fill="#FAA634" d="M1135.4,303.8c0.8,1.8,2.6,3.4,4.2,3.5c1.5,0.2,2.2-1.2,1.4-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1135.2,300.7,1134.6,302.1,1135.4,303.8"/>
				<path fill="#FAA634" d="M1126.5,303c0.8,1.8,2.6,3.4,4.2,3.5c1.5,0.1,2.2-1.2,1.4-3c-0.8-1.8-2.6-3.4-4.2-3.5
		C1126.4,299.9,1125.8,301.2,1126.5,303"/>
				<path fill="#FAA634" d="M1117.6,302.2c0.7,1.8,2.6,3.4,4.1,3.5c1.6,0.1,2.2-1.2,1.5-3s-2.6-3.4-4.1-3.5
		C1117.5,299,1116.9,300.4,1117.6,302.2"/>
				<path fill="#FAA634" d="M1108.7,301.3c0.7,1.8,2.6,3.4,4.1,3.5c1.6,0.1,2.2-1.2,1.5-3s-2.6-3.3-4.1-3.5
		C1108.6,298.2,1107.9,299.6,1108.7,301.3"/>
				<path fill="#FAA634" d="M1099.7,300.5c0.7,1.8,2.5,3.3,4.1,3.5c1.6,0.1,2.2-1.2,1.5-3s-2.6-3.3-4.1-3.5
		C1099.7,297.4,1099,298.8,1099.7,300.5"/>
				<path fill="#FAA634" d="M1090.7,299.8c0.7,1.8,2.5,3.3,4.1,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4.1-3.5
		C1090.7,296.6,1090,298,1090.7,299.8"/>
				<path fill="#FAA634" d="M1081.7,299c0.7,1.8,2.5,3.3,4.1,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4.1-3.5
		C1081.7,295.9,1081,297.2,1081.7,299"/>
				<path fill="#FAA634" d="M1072.6,298.3c0.7,1.8,2.5,3.3,4,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4-3.5
		C1072.7,295.1,1072,296.5,1072.6,298.3"/>
				<path fill="#FAA634" d="M1063.6,297.5c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.3-1.2,1.7-3c-0.7-1.8-2.5-3.3-4-3.5
		C1063.7,294.4,1062.9,295.8,1063.6,297.5"/>
				<path fill="#FAA634" d="M1054.5,296.8c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-4-3.5
		C1054.6,293.7,1053.8,295.1,1054.5,296.8"/>
				<path fill="#FAA634" d="M1045.4,296.2c0.6,1.8,2.4,3.3,4,3.4s2.4-1.2,1.8-3s-2.4-3.3-4-3.4C1045.5,293,1044.7,294.4,1045.4,296.2" />
				<path fill="#FAA634" d="M1036.2,295.5c0.6,1.8,2.4,3.3,4,3.4s2.4-1.2,1.8-3s-2.4-3.3-4-3.4S1035.6,293.7,1036.2,295.5" />
				<path fill="#FAA634" d="M1027.1,294.9c0.6,1.8,2.3,3.3,3.9,3.4s2.4-1.2,1.8-3s-2.4-3.3-3.9-3.4
		C1027.3,291.7,1026.5,293.1,1027.1,294.9"/>
				<path fill="#FAA634" d="M1017.9,294.2c0.6,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.6-1.8-2.3-3.3-3.9-3.4
		C1018.1,291.1,1017.3,292.5,1017.9,294.2"/>
				<path fill="#FAA634" d="M1008.7,293.6c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,1.9-3c-0.6-1.8-2.3-3.3-3.9-3.4
		S1008.1,291.9,1008.7,293.6"/>
				<path fill="#FAA634" d="M999.4,293.1c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.5-1.3,1.9-3.1c-0.5-1.8-2.3-3.3-3.9-3.4
		C999.8,289.9,998.9,291.3,999.4,293.1"/>
				<path fill="#FAA634" d="M1316.5,316.6c1.1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3-3.4-4.4-3.6
		C1315.8,313.5,1315.5,314.8,1316.5,316.6"/>
				<path fill="#FAA634" d="M1308.5,315.4c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.9s-3-3.4-4.4-3.6S1307.4,313.6,1308.5,315.4" />
				<path fill="#FAA634" d="M1300.4,314.2c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1299.7,311.2,1299.4,312.4,1300.4,314.2" />
				<path fill="#FAA634" d="M1292.3,313c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1291.6,310,1291.2,311.3,1292.3,313" />
				<path fill="#FAA634" d="M1284.1,311.9c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1283.4,308.8,1283.1,310.1,1284.1,311.9" />
				<path fill="#FAA634" d="M1275.9,310.8c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1275.2,307.7,1274.9,309,1275.9,310.8" />
				<path fill="#FAA634" d="M1267.6,309.6c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6C1267,306.6,1266.7,307.9,1267.6,309.6" />
				<path fill="#FAA634" d="M1259.4,308.5c1,1.8,2.9,3.4,4.4,3.6c1.4,0.2,1.8-1.1,0.8-2.9s-2.9-3.4-4.4-3.6
		C1258.8,305.5,1258.4,306.7,1259.4,308.5"/>
				<path fill="#FAA634" d="M1251,307.4c1,1.8,2.9,3.4,4.4,3.6c1.4,0.2,1.8-1.1,0.9-2.9c-1-1.8-2.9-3.4-4.4-3.6
		C1250.5,304.4,1250.1,305.7,1251,307.4"/>
				<path fill="#FAA634" d="M1242.7,306.4c1,1.8,2.9,3.4,4.4,3.6s1.9-1.1,0.9-2.9s-2.9-3.4-4.4-3.6S1241.7,304.6,1242.7,306.4" />
				<path fill="#FAA634" d="M1234.3,305.3c0.9,1.8,2.9,3.4,4.3,3.6c1.5,0.2,1.9-1.1,0.9-2.9s-2.9-3.4-4.3-3.6
		C1233.8,302.2,1233.3,303.5,1234.3,305.3"/>
				<path fill="#FAA634" d="M1191.8,300.2c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.3-3.5
		C1191.4,297.1,1190.9,298.4,1191.8,300.2"/>
				<path fill="#FAA634" d="M1183.2,299.2c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.3-3.5
		C1182.9,296.1,1182.4,297.4,1183.2,299.2"/>
				<path fill="#FAA634" d="M1174.6,298.3c0.8,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.2-2.9c-0.9-1.8-2.8-3.4-4.3-3.5
		C1174.3,295.2,1173.8,296.5,1174.6,298.3"/>
				<path fill="#FAA634" d="M1165.9,297.3c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2-1.2,1.2-2.9c-0.8-1.8-2.7-3.4-4.2-3.5
		C1165.7,294.2,1165.1,295.5,1165.9,297.3"/>
				<path fill="#FAA634" d="M1157.3,296.4c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.2-2.9c-0.8-1.8-2.7-3.4-4.2-3.5
		C1157,293.3,1156.4,294.6,1157.3,296.4"/>
				<path fill="#FAA634" d="M1148.5,295.5c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-2.9c-0.8-1.8-2.7-3.4-4.2-3.5
		C1148.3,292.4,1147.7,293.7,1148.5,295.5"/>
				<path fill="#FAA634" d="M1139.8,294.6c0.8,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.4-4.2-3.5
		C1139.6,291.5,1139,292.8,1139.8,294.6"/>
				<path fill="#FAA634" d="M1131,293.7c0.8,1.8,2.6,3.4,4.2,3.5c1.5,0.2,2.1-1.2,1.3-3c-0.8-1.8-2.7-3.3-4.2-3.5
		C1130.9,290.6,1130.3,292,1131,293.7"/>
				<path fill="#FAA634" d="M1122.2,292.9c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.4-3c-0.8-1.8-2.6-3.3-4.1-3.5
		C1122.1,289.8,1121.5,291.1,1122.2,292.9"/>
				<path fill="#FAA634" d="M1113.4,292.1c0.7,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.4-3c-0.8-1.8-2.6-3.3-4.1-3.5
		C1113.3,289,1112.7,290.3,1113.4,292.1"/>
				<path fill="#FAA634" d="M1104.6,291.2c0.7,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.5-3s-2.6-3.3-4.1-3.5
		C1104.5,288.1,1103.8,289.5,1104.6,291.2"/>
				<path fill="#FAA634" d="M1095.7,290.5c0.7,1.8,2.5,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.5-3s-2.6-3.3-4.1-3.5
		C1095.6,287.3,1095,288.7,1095.7,290.5"/>
				<path fill="#FAA634" d="M1086.8,289.7c0.7,1.8,2.5,3.3,4.1,3.5c1.6,0.1,2.2-1.2,1.5-3s-2.5-3.3-4.1-3.5
		C1086.8,286.6,1086.1,287.9,1086.8,289.7"/>
				<path fill="#FAA634" d="M1077.8,288.9c0.7,1.8,2.5,3.3,4,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4-3.5
		C1077.9,285.8,1077.2,287.1,1077.8,288.9"/>
				<path fill="#FAA634" d="M1068.9,288.2c0.7,1.8,2.5,3.3,4,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4-3.5
		C1068.9,285.1,1068.2,286.4,1068.9,288.2"/>
				<path fill="#FAA634" d="M1059.9,287.5c0.6,1.8,2.4,3.3,4,3.5c1.6,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.5-3.3-4-3.5S1059.3,285.7,1059.9,287.5
		"/>
				<path fill="#FAA634" d="M1050.9,286.8c0.6,1.8,2.4,3.3,4,3.4s2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-4-3.4
		C1051,283.6,1050.3,285,1050.9,286.8"/>
				<path fill="#FAA634" d="M1041.9,286.1c0.6,1.8,2.4,3.3,4,3.4s2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-4-3.4
		C1042,282.9,1041.3,284.3,1041.9,286.1"/>
				<path fill="#FAA634" d="M1032.8,285.4c0.6,1.8,2.4,3.3,3.9,3.4c1.6,0.1,2.4-1.2,1.7-3c-0.6-1.8-2.4-3.3-3.9-3.4
		S1032.2,283.6,1032.8,285.4"/>
				<path fill="#FAA634" d="M1023.8,284.8c0.6,1.8,2.3,3.3,3.9,3.4s2.4-1.2,1.8-3s-2.3-3.3-3.9-3.4C1024,281.6,1023.2,283,1023.8,284.8" />
				<path fill="#FAA634" d="M1014.7,284.2c0.6,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.4-1.3,1.8-3c-0.6-1.8-2.3-3.3-3.9-3.4
		C1014.9,281,1014.1,282.4,1014.7,284.2"/>
				<path fill="#FAA634" d="M1005.6,283.6c0.5,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.6-1.8-2.3-3.3-3.9-3.4
		C1005.9,280.4,1005,281.8,1005.6,283.6"/>
				<path fill="#FAA634" d="M1318.5,307.7c1.1,1.8,3.1,3.4,4.4,3.6c1.4,0.2,1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
		S1317.5,305.9,1318.5,307.7"/>
				<path fill="#FAA634" d="M1310.6,306.5c1.1,1.8,3,3.4,4.4,3.6s1.6-1.1,0.6-2.8c-1.1-1.8-3-3.4-4.4-3.6
		C1309.8,303.5,1309.5,304.7,1310.6,306.5"/>
				<path fill="#FAA634" d="M1302.6,305.3c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.8c-1.1-1.8-3-3.4-4.4-3.6
		C1301.8,302.3,1301.5,303.6,1302.6,305.3"/>
				<path fill="#FAA634" d="M1294.5,304.2c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.8c-1-1.8-3-3.4-4.4-3.6
		C1293.8,301.1,1293.5,302.4,1294.5,304.2"/>
				<path fill="#FAA634" d="M1278.4,301.8c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1277.7,298.8,1277.3,300.1,1278.4,301.8" />
				<path fill="#FAA634" d="M1262,299.6c1,1.8,3,3.4,4.4,3.6s1.8-1.1,0.8-2.9s-3-3.4-4.4-3.6S1261,297.8,1262,299.6" />
				<path fill="#FAA634" d="M1253.8,298.5c1,1.8,2.9,3.4,4.4,3.6c1.4,0.2,1.8-1.1,0.8-2.9s-2.9-3.4-4.4-3.6
		C1253.2,295.4,1252.8,296.7,1253.8,298.5"/>
				<path fill="#FAA634" d="M1237.3,296.3c1,1.8,2.9,3.4,4.3,3.6s1.8-1.1,0.9-2.9c-1-1.8-2.9-3.4-4.3-3.6
		C1236.7,293.2,1236.3,294.5,1237.3,296.3"/>
				<path fill="#FAA634" d="M1220.6,294.2c0.9,1.8,2.9,3.4,4.3,3.6c1.5,0.2,1.9-1.1,0.9-2.9c-0.9-1.8-2.9-3.4-4.3-3.5
		C1220.1,291.1,1219.7,292.4,1220.6,294.2"/>
				<path fill="#FAA634" d="M1212.2,293.1c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,1.9-1.1,1-2.9s-2.9-3.4-4.3-3.5
		C1211.7,290.1,1211.3,291.4,1212.2,293.1"/>
				<path fill="#FAA634" d="M1195.3,291.1c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,1.9-1.1,1-2.9s-2.8-3.4-4.3-3.5
		C1194.9,288,1194.4,289.3,1195.3,291.1"/>
				<path fill="#FAA634" d="M1186.9,290.1c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.3-3.5
		C1186.5,287,1186,288.3,1186.9,290.1"/>
				<path fill="#FAA634" d="M1178.3,289.2c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.3-3.5
		C1178,286.1,1177.5,287.4,1178.3,289.2"/>
				<path fill="#FAA634" d="M1169.8,288.2c0.9,1.8,2.7,3.4,4.2,3.5c1.5,0.2,2-1.1,1.1-2.9s-2.8-3.4-4.2-3.5
		C1169.4,285.1,1168.9,286.4,1169.8,288.2"/>
				<path fill="#FAA634" d="M1161.2,287.3c0.8,1.8,2.7,3.4,4.2,3.5s2-1.1,1.2-2.9s-2.7-3.4-4.2-3.5
		C1160.9,284.2,1160.4,285.5,1161.2,287.3"/>
				<path fill="#FAA634" d="M1152.6,286.3c0.8,1.8,2.7,3.3,4.2,3.5s2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.2-3.5
		C1152.3,283.3,1151.8,284.6,1152.6,286.3"/>
				<path fill="#FAA634" d="M1144,285.4c0.8,1.8,2.7,3.3,4.2,3.5s2.1-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.2-3.5S1143.2,283.7,1144,285.4" />
				<path fill="#FAA634" d="M1135.3,284.6c0.8,1.8,2.7,3.3,4.2,3.5s2.1-1.2,1.3-2.9c-0.8-1.8-2.7-3.3-4.2-3.5
		C1135.1,281.5,1134.5,282.8,1135.3,284.6"/>
				<path fill="#FAA634" d="M1126.6,283.7c0.8,1.8,2.6,3.3,4.2,3.5c1.5,0.2,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.2-3.5
		C1126.4,280.6,1125.9,281.9,1126.6,283.7"/>
				<path fill="#FAA634" d="M1117.9,282.8c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-3c-0.8-1.8-2.6-3.3-4.1-3.5
		C1117.8,279.7,1117.2,281.1,1117.9,282.8"/>
				<path fill="#FAA634" d="M1109.2,282c0.7,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.4-3c-0.8-1.8-2.6-3.3-4.1-3.5
		C1109.1,278.9,1108.4,280.2,1109.2,282"/>
				<path fill="#FAA634" d="M1100.4,281.2c0.7,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.4-3c-0.7-1.8-2.6-3.3-4.1-3.5
		C1100.3,278.1,1099.7,279.4,1100.4,281.2"/>
				<path fill="#FAA634" d="M1091.6,280.4c0.7,1.8,2.5,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.5-3s-2.6-3.3-4.1-3.5
		C1091.6,277.3,1090.9,278.6,1091.6,280.4"/>
				<path fill="#FAA634" d="M1082.8,279.6c0.7,1.8,2.5,3.3,4.1,3.5c1.5,0.1,2.2-1.2,1.5-3s-2.5-3.3-4.1-3.5
		C1082.8,276.5,1082.1,277.8,1082.8,279.6"/>
				<path fill="#FAA634" d="M1074,278.9c0.7,1.8,2.5,3.3,4,3.5c1.5,0.1,2.2-1.2,1.5-3s-2.5-3.3-4-3.5S1073.3,277.1,1074,278.9" />
				<path fill="#FAA634" d="M1065.1,278.1c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.6-3c-0.7-1.8-2.5-3.3-4-3.4
		C1065.1,275,1064.4,276.3,1065.1,278.1"/>
				<path fill="#FAA634" d="M1056.2,277.4c0.7,1.8,2.4,3.3,4,3.4c1.5,0.1,2.3-1.2,1.6-3c-0.7-1.8-2.4-3.3-4-3.4
		C1056.3,274.3,1055.6,275.6,1056.2,277.4"/>
				<path fill="#FAA634" d="M1047.3,276.7c0.6,1.8,2.4,3.3,4,3.4s2.3-1.2,1.6-3c-0.6-1.8-2.4-3.3-4-3.4
		C1047.4,273.6,1046.7,274.9,1047.3,276.7"/>
				<path fill="#FAA634" d="M1038.4,276c0.6,1.8,2.4,3.3,3.9,3.4c1.6,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-3.9-3.4
		C1038.5,272.9,1037.8,274.3,1038.4,276"/>
				<path fill="#FAA634" d="M1029.4,275.4c0.6,1.8,2.4,3.3,3.9,3.4c1.6,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-3.9-3.4
		C1029.6,272.2,1028.8,273.6,1029.4,275.4"/>
				<path fill="#FAA634" d="M1020.5,274.7c0.6,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.3-3.3-3.9-3.4
		C1020.7,271.6,1019.9,273,1020.5,274.7"/>
				<path fill="#FAA634" d="M1011.5,274.1c0.6,1.8,2.3,3.3,3.9,3.4c1.6,0.1,2.4-1.2,1.8-3c-0.6-1.8-2.3-3.3-3.9-3.4
		C1011.7,271,1010.9,272.3,1011.5,274.1"/>
				<path fill="#FAA634" d="M1335.9,301.4c1.1,1.8,3.1,3.4,4.4,3.6s1.5-1,0.4-2.8s-3.1-3.4-4.4-3.6C1335,298.3,1334.8,299.6,1335.9,301.4"
				/>
				<path fill="#FAA634" d="M1264.5,290.7c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.7-2.9s-3-3.4-4.4-3.6C1263.8,287.6,1263.5,288.9,1264.5,290.7" />
				<path fill="#FAA634" d="M1207,283.1c0.9,1.8,2.9,3.4,4.3,3.5c1.4,0.2,1.9-1.1,0.9-2.9c-0.9-1.8-2.9-3.4-4.3-3.5
		C1206.5,280,1206.1,281.3,1207,283.1"/>
				<path fill="#FAA634" d="M1181.9,280.1c0.9,1.8,2.8,3.4,4.3,3.5c1.5,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.3-3.5S1181,278.3,1181.9,280.1" />
				<path fill="#FAA634" d="M1173.4,279.1c0.9,1.8,2.8,3.3,4.2,3.5c1.5,0.2,1.9-1.1,1.1-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
		C1173,276,1172.6,277.3,1173.4,279.1"/>
				<path fill="#FAA634" d="M1165,278.2c0.9,1.8,2.7,3.3,4.2,3.5s2-1.1,1.1-2.9s-2.8-3.3-4.2-3.5C1164.6,275.1,1164.1,276.4,1165,278.2" />
				<path fill="#FAA634" d="M1156.5,277.2c0.8,1.8,2.7,3.3,4.2,3.5s2-1.1,1.1-2.9s-2.7-3.3-4.2-3.5S1155.6,275.4,1156.5,277.2" />
				<path fill="#FAA634" d="M1147.9,276.3c0.8,1.8,2.7,3.3,4.2,3.5s2-1.1,1.2-2.9s-2.7-3.3-4.2-3.5
		C1147.6,273.2,1147.1,274.5,1147.9,276.3"/>
				<path fill="#FAA634" d="M1139.4,275.4c0.8,1.8,2.7,3.3,4.2,3.5s2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.2-3.5S1138.6,273.6,1139.4,275.4" />
				<path fill="#FAA634" d="M1130.8,274.5c0.8,1.8,2.7,3.3,4.2,3.5s2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.2-3.5
		C1130.6,271.4,1130,272.7,1130.8,274.5"/>
				<path fill="#FAA634" d="M1122.2,273.6c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		C1122,270.6,1121.4,271.9,1122.2,273.6"/>
				<path fill="#FAA634" d="M1113.6,272.8c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		C1113.4,269.7,1112.8,271,1113.6,272.8"/>
				<path fill="#FAA634" d="M1104.9,272c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		C1104.8,268.9,1104.2,270.2,1104.9,272"/>
				<path fill="#FAA634" d="M1096.3,271.2c0.7,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.4-3c-0.8-1.8-2.6-3.3-4.1-3.5
		C1096.1,268.1,1095.5,269.4,1096.3,271.2"/>
				<path fill="#FAA634" d="M1087.6,270.4c0.7,1.8,2.5,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.4-3s-2.5-3.3-4.1-3.5
		C1087.5,267.3,1086.8,268.6,1087.6,270.4"/>
				<path fill="#FAA634" d="M1078.8,269.6c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.5-3s-2.5-3.3-4-3.4S1078.1,267.8,1078.8,269.6" />
				<path fill="#FAA634" d="M1070.1,268.8c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.5-3s-2.5-3.3-4-3.4C1070.1,265.7,1069.4,267.1,1070.1,268.8" />
				<path fill="#FAA634" d="M1061.3,268.1c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.5-3s-2.5-3.3-4-3.4S1060.6,266.3,1061.3,268.1" />
				<path fill="#FAA634" d="M1052.5,267.4c0.7,1.8,2.4,3.3,4,3.4c1.5,0.1,2.2-1.2,1.6-3c-0.7-1.8-2.4-3.3-4-3.4
		C1052.6,264.3,1051.9,265.6,1052.5,267.4"/>
				<path fill="#FAA634" d="M1043.7,266.7c0.6,1.8,2.4,3.3,3.9,3.4s2.2-1.2,1.6-3c-0.7-1.8-2.4-3.3-3.9-3.4
		C1043.8,263.6,1043.1,264.9,1043.7,266.7"/>
				<path fill="#FAA634" d="M1034.9,266c0.6,1.8,2.4,3.3,3.9,3.4s2.3-1.2,1.6-3c-0.6-1.8-2.4-3.3-3.9-3.4S1034.2,264.2,1034.9,266" />
				<path fill="#FAA634" d="M1026,265.4c0.6,1.8,2.4,3.3,3.9,3.4s2.3-1.2,1.7-3c-0.6-1.8-2.4-3.3-3.9-3.4
		C1026.1,262.2,1025.4,263.6,1026,265.4"/>
				<path fill="#FAA634" d="M1017.1,264.7c0.6,1.8,2.3,3.3,3.9,3.4c1.5,0.1,2.3-1.2,1.7-3s-2.3-3.3-3.9-3.4
		C1017.3,261.6,1016.5,263,1017.1,264.7"/>
				<path fill="#FAA634" d="M1290.7,285.3c1.1,1.8,3,3.4,4.4,3.6s1.6-1.1,0.6-2.8c-1.1-1.8-3-3.4-4.4-3.6
		C1289.9,282.2,1289.6,283.5,1290.7,285.3"/>
				<path fill="#FAA634" d="M1266.8,281.8c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.8c-1-1.8-3-3.4-4.4-3.6
		C1266.1,278.7,1265.8,280,1266.8,281.8"/>
				<path fill="#FAA634" d="M1210,274.1c0.9,1.8,2.9,3.4,4.3,3.5c1.4,0.2,1.8-1.1,0.9-2.9s-2.9-3.4-4.3-3.5
		C1209.4,271.1,1209,272.3,1210,274.1"/>
				<path fill="#FAA634" d="M1201.7,273.1c0.9,1.8,2.8,3.4,4.3,3.5c1.4,0.2,1.8-1.1,0.9-2.9s-2.9-3.3-4.3-3.5S1200.8,271.3,1201.7,273.1"
				/>
				<path fill="#FAA634" d="M1193.5,272.1c0.9,1.8,2.8,3.3,4.3,3.5c1.4,0.2,1.9-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.3-3.5
		C1193,269,1192.6,270.3,1193.5,272.1"/>
				<path fill="#FAA634" d="M1185.2,271.1c0.9,1.8,2.8,3.3,4.3,3.5c1.4,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.3-3.5S1184.3,269.3,1185.2,271.1" />
				<path fill="#FAA634" d="M1176.8,270.1c0.9,1.8,2.8,3.3,4.2,3.5c1.5,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.2-3.5
		C1176.4,267,1176,268.3,1176.8,270.1"/>
				<path fill="#FAA634" d="M1168.5,269.1c0.9,1.8,2.8,3.3,4.2,3.5c1.5,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.2-3.5
		C1168.1,266,1167.6,267.3,1168.5,269.1"/>
				<path fill="#FAA634" d="M1160.1,268.2c0.9,1.8,2.7,3.3,4.2,3.5s1.9-1.1,1.1-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
		C1159.7,265.1,1159.2,266.4,1160.1,268.2"/>
				<path fill="#FAA634" d="M1151.7,267.2c0.8,1.8,2.7,3.3,4.2,3.5s2-1.1,1.1-2.9s-2.7-3.3-4.2-3.5
		C1151.3,264.1,1150.8,265.4,1151.7,267.2"/>
				<path fill="#FAA634" d="M1143.2,266.3c0.8,1.8,2.7,3.3,4.2,3.5s2-1.1,1.1-2.9c-0.8-1.8-2.7-3.3-4.2-3.5
		C1142.9,263.2,1142.4,264.5,1143.2,266.3"/>
				<path fill="#FAA634" d="M1134.8,265.4c0.8,1.8,2.7,3.3,4.2,3.5s2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.2-3.5S1134,263.6,1134.8,265.4" />
				<path fill="#FAA634" d="M1126.3,264.5c0.8,1.8,2.7,3.3,4.1,3.5c1.5,0.2,2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.1-3.5
		C1126,261.4,1125.5,262.8,1126.3,264.5"/>
				<path fill="#FAA634" d="M1117.8,263.7c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2-1.2,1.2-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		S1117,261.9,1117.8,263.7"/>
				<path fill="#FAA634" d="M1109.2,262.8c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		C1109,259.7,1108.4,261,1109.2,262.8"/>
				<path fill="#FAA634" d="M1100.7,262c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		S1099.9,260.2,1100.7,262"/>
				<path fill="#FAA634" d="M1092.1,261.2c0.7,1.8,2.6,3.3,4.1,3.4s2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.4
		C1091.9,258.1,1091.3,259.4,1092.1,261.2"/>
				<path fill="#FAA634" d="M1083.5,260.4c0.7,1.8,2.5,3.3,4,3.4s2.1-1.2,1.4-2.9c-0.7-1.8-2.5-3.3-4-3.4
		C1083.3,257.3,1082.7,258.6,1083.5,260.4"/>
				<path fill="#FAA634" d="M1074.8,259.6c0.7,1.8,2.5,3.3,4,3.4s2.1-1.2,1.4-3s-2.5-3.3-4-3.4C1074.7,256.5,1074.1,257.8,1074.8,259.6" />
				<path fill="#FAA634" d="M1066.2,258.9c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.5-3s-2.5-3.3-4-3.4C1066.1,255.8,1065.5,257.1,1066.2,258.9" />
				<path fill="#FAA634" d="M1057.5,258.1c0.7,1.8,2.5,3.3,4,3.4s2.2-1.2,1.5-3s-2.5-3.3-4-3.4C1057.5,255,1056.8,256.4,1057.5,258.1" />
				<path fill="#FAA634" d="M1048.8,257.4c0.7,1.8,2.4,3.3,3.9,3.4s2.2-1.2,1.5-3c-0.7-1.8-2.4-3.3-3.9-3.4S1048.1,255.7,1048.8,257.4" />
				<path fill="#FAA634" d="M1040.1,256.7c0.6,1.8,2.4,3.3,3.9,3.4s2.2-1.2,1.6-3c-0.7-1.8-2.4-3.3-3.9-3.4
		C1040.1,253.6,1039.4,255,1040.1,256.7"/>
				<path fill="#FAA634" d="M1031.3,256c0.6,1.8,2.4,3.3,3.9,3.4s2.2-1.2,1.6-3c-0.6-1.8-2.4-3.3-3.9-3.4
		C1031.4,253,1030.7,254.3,1031.3,256"/>
				<path fill="#FAA634" d="M1022.6,255.4c0.6,1.8,2.3,3.3,3.9,3.4c1.5,0.1,2.3-1.2,1.6-3c-0.6-1.8-2.4-3.3-3.9-3.4
		C1022.7,252.3,1021.9,253.6,1022.6,255.4"/>
				<path fill="#FAA634" d="M1013.8,254.8c0.6,1.8,2.3,3.3,3.8,3.4s2.3-1.2,1.7-3c-0.6-1.8-2.3-3.3-3.8-3.4
		C1013.9,251.7,1013.2,253,1013.8,254.8"/>
				<path fill="#FAA634" d="M1300.3,277.7c1.1,1.8,3,3.4,4.4,3.6c1.3,0.2,1.6-1.1,0.5-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
		S1299.2,275.9,1300.3,277.7"/>
				<path fill="#FAA634" d="M1292.5,276.5c1.1,1.8,3,3.4,4.4,3.6s1.6-1.1,0.5-2.8c-1.1-1.8-3-3.4-4.4-3.6
		C1291.7,273.4,1291.4,274.7,1292.5,276.5"/>
				<path fill="#FAA634" d="M1261,271.8c1,1.8,3,3.4,4.4,3.6s1.7-1.1,0.6-2.8c-1-1.8-3-3.4-4.4-3.6C1260.3,268.7,1260,270,1261,271.8" />
				<path fill="#FAA634" d="M1180.1,261.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.9-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
		C1179.6,258,1179.1,259.3,1180.1,261.1"/>
				<path fill="#FAA634" d="M1171.8,260.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.2-3.5
		C1171.3,257,1170.9,258.3,1171.8,260.1"/>
				<path fill="#FAA634" d="M1163.5,259.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.2-3.5
		C1163.1,256.1,1162.6,257.4,1163.5,259.1"/>
				<path fill="#FAA634" d="M1155.2,258.2c0.9,1.8,2.7,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9s-2.8-3.3-4.2-3.5
		C1154.8,255.1,1154.3,256.4,1155.2,258.2"/>
				<path fill="#FAA634" d="M1146.9,257.2c0.9,1.8,2.7,3.3,4.2,3.5s1.9-1.1,1.1-2.9c-0.9-1.8-2.7-3.3-4.2-3.5
		C1146.5,254.2,1146,255.5,1146.9,257.2"/>
				<path fill="#FAA634" d="M1138.5,256.3c0.8,1.8,2.7,3.3,4.2,3.5s1.9-1.1,1.1-2.9c-0.8-1.8-2.7-3.3-4.2-3.5
		C1138.2,253.3,1137.7,254.6,1138.5,256.3"/>
				<path fill="#FAA634" d="M1130.1,255.4c0.8,1.8,2.7,3.3,4.1,3.5c1.5,0.2,2-1.1,1.1-2.9c-0.8-1.8-2.7-3.3-4.1-3.5
		C1129.8,252.4,1129.3,253.7,1130.1,255.4"/>
				<path fill="#FAA634" d="M1121.7,254.6c0.8,1.8,2.7,3.3,4.1,3.5c1.5,0.2,2-1.2,1.2-2.9c-0.8-1.8-2.7-3.3-4.1-3.5
		C1121.4,251.5,1120.9,252.8,1121.7,254.6"/>
				<path fill="#FAA634" d="M1113.3,253.7c0.8,1.8,2.6,3.3,4.1,3.5c1.5,0.1,2-1.2,1.2-2.9c-0.8-1.8-2.6-3.3-4.1-3.5
		C1113,250.6,1112.5,251.9,1113.3,253.7"/>
				<path fill="#FAA634" d="M1104.8,252.9c0.8,1.8,2.6,3.3,4.1,3.4s2-1.2,1.2-2.9c-0.8-1.8-2.6-3.3-4.1-3.4
		C1104.6,249.8,1104.1,251.1,1104.8,252.9"/>
				<path fill="#FAA634" d="M1096.4,252c0.8,1.8,2.6,3.3,4.1,3.4c1.5,0.1,2-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4.1-3.4
		C1096.2,249,1095.6,250.3,1096.4,252"/>
				<path fill="#FAA634" d="M1087.9,251.2c0.7,1.8,2.6,3.3,4,3.4c1.5,0.1,2.1-1.2,1.3-2.9c-0.8-1.8-2.6-3.3-4-3.4
		C1087.7,248.2,1087.1,249.5,1087.9,251.2"/>
				<path fill="#FAA634" d="M1079.3,250.4c0.7,1.8,2.5,3.3,4,3.4c1.5,0.1,2.1-1.2,1.3-2.9c-0.7-1.8-2.5-3.3-4-3.4
		C1079.2,247.4,1078.6,248.7,1079.3,250.4"/>
				<path fill="#FAA634" d="M1070.8,249.7c0.7,1.8,2.5,3.3,4,3.4c1.5,0.1,2.1-1.2,1.4-2.9c-0.7-1.8-2.5-3.3-4-3.4
		C1070.7,246.6,1070.1,247.9,1070.8,249.7"/>
				<path fill="#FAA634" d="M1062.2,248.9c0.7,1.8,2.5,3.3,4,3.4c1.5,0.1,2.1-1.2,1.4-2.9c-0.7-1.8-2.5-3.3-4-3.4
		C1062.2,245.9,1061.5,247.2,1062.2,248.9"/>
				<path fill="#FAA634" d="M1053.6,248.2c0.7,1.8,2.4,3.3,3.9,3.4c1.5,0.1,2.1-1.2,1.5-2.9c-0.7-1.8-2.5-3.3-3.9-3.4
		C1053.6,245.1,1052.9,246.4,1053.6,248.2"/>
				<path fill="#FAA634" d="M1045,247.5c0.7,1.8,2.4,3.3,3.9,3.4c1.5,0.1,2.2-1.2,1.5-3s-2.4-3.3-3.9-3.4
		C1045,244.4,1044.4,245.7,1045,247.5"/>
				<path fill="#FAA634" d="M1036.4,246.8c0.6,1.8,2.4,3.3,3.9,3.4c1.5,0.1,2.2-1.2,1.5-3s-2.4-3.3-3.9-3.4
		C1036.4,243.7,1035.7,245,1036.4,246.8"/>
				<path fill="#FAA634" d="M1027.8,246.1c0.6,1.8,2.4,3.3,3.9,3.4c1.5,0.1,2.2-1.2,1.6-3s-2.4-3.3-3.9-3.4
		C1027.8,243,1027.1,244.4,1027.8,246.1"/>
				<path fill="#FAA634" d="M1019.1,245.5c0.6,1.8,2.3,3.3,3.8,3.4c1.5,0.1,2.2-1.2,1.6-3s-2.3-3.3-3.8-3.4
		C1019.2,242.4,1018.5,243.7,1019.1,245.5"/>
				<path fill="#FAA634" d="M1309.6,270.1c1.1,1.8,3.1,3.4,4.4,3.6s1.5-1,0.4-2.8s-3.1-3.4-4.4-3.6S1308.5,268.4,1309.6,270.1" />
				<path fill="#FAA634" d="M1166.7,250.2c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
		C1166.3,247.1,1165.8,248.4,1166.7,250.2"/>
				<path fill="#FAA634" d="M1158.5,249.2c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
		C1158.1,246.2,1157.6,247.4,1158.5,249.2"/>
				<path fill="#FAA634" d="M1150.3,248.2c0.9,1.8,2.7,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.8-2.7-3.3-4.2-3.5
		C1149.9,245.2,1149.4,246.5,1150.3,248.2"/>
				<path fill="#FAA634" d="M1142,247.3c0.9,1.8,2.7,3.3,4.2,3.5c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.8-2.7-3.3-4.1-3.5
		C1141.7,244.3,1141.2,245.6,1142,247.3"/>
				<path fill="#FAA634" d="M1133.8,246.4c0.8,1.8,2.7,3.3,4.1,3.5s1.9-1.1,1.1-2.9c-0.8-1.8-2.7-3.3-4.1-3.5
		C1133.4,243.4,1132.9,244.7,1133.8,246.4"/>
				<path fill="#FAA634" d="M1010.4,244.8c0.6,1.7,2.3,3.3,3.8,3.4c1.5,0.1,2.2-1.2,1.6-3c-0.6-1.8-2.3-3.3-3.8-3.4
		C1010.5,241.8,1009.8,243.1,1010.4,244.8"/>
				<path fill="#FAA634" d="M1001.7,244.2c0.6,1.7,2.3,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.7-3s-2.3-3.3-3.8-3.4S1001.1,242.5,1001.7,244.2" />
				<path fill="#FAA634" d="M993,243.6c0.6,1.7,2.3,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.7-3c-0.6-1.7-2.3-3.2-3.8-3.3
		C993.2,240.6,992.4,241.9,993,243.6"/>
				<path fill="#FAA634" d="M984.3,243.1c0.5,1.7,2.2,3.2,3.7,3.3s2.3-1.2,1.7-3c-0.6-1.7-2.2-3.2-3.7-3.3S983.7,241.3,984.3,243.1" />
				<path fill="#FAA634" d="M975.5,242.5c0.5,1.7,2.2,3.2,3.7,3.3s2.3-1.2,1.8-3c-0.5-1.7-2.2-3.2-3.7-3.3S975,240.8,975.5,242.5" />
				<path fill="#FAA634" d="M1125.5,245.5c0.8,1.8,2.7,3.3,4.1,3.5s1.9-1.1,1.1-2.9c-0.8-1.8-2.7-3.3-4.1-3.4
		C1125.1,242.5,1124.6,243.8,1125.5,245.5"/>
				<path fill="#FAA634" d="M1117.1,244.6c0.8,1.8,2.6,3.3,4.1,3.4c1.4,0.2,2-1.1,1.1-2.9s-2.7-3.3-4.1-3.4
		C1116.8,241.6,1116.3,242.9,1117.1,244.6"/>
				<path fill="#FAA634" d="M1108.8,243.8c0.8,1.7,2.6,3.3,4.1,3.4c1.5,0.1,2-1.2,1.2-2.9s-2.6-3.3-4.1-3.4
		C1108.5,240.7,1108,242,1108.8,243.8"/>
				<path fill="#FAA634" d="M1100.4,242.9c0.8,1.7,2.6,3.3,4.1,3.4c1.5,0.1,2-1.2,1.2-2.9s-2.6-3.3-4.1-3.4
		C1100.2,239.9,1099.6,241.2,1100.4,242.9"/>
				<path fill="#FAA634" d="M1092,242.1c0.8,1.7,2.6,3.3,4,3.4c1.5,0.1,2-1.2,1.2-2.9s-2.6-3.3-4-3.4
		C1091.8,239.1,1091.3,240.4,1092,242.1"/>
				<path fill="#FAA634" d="M1083.6,241.3c0.8,1.7,2.5,3.3,4,3.4c1.5,0.1,2-1.2,1.3-2.9c-0.8-1.7-2.6-3.3-4-3.4
		C1083.4,238.3,1082.9,239.6,1083.6,241.3"/>
				<path fill="#FAA634" d="M1075.2,240.5c0.7,1.7,2.5,3.3,4,3.4c1.5,0.1,2.1-1.2,1.3-2.9c-0.7-1.7-2.5-3.3-4-3.4
		C1075,237.5,1074.5,238.8,1075.2,240.5"/>
				<path fill="#FAA634" d="M1066.7,239.8c0.7,1.7,2.5,3.3,4,3.4c1.5,0.1,2.1-1.2,1.3-2.9c-0.7-1.7-2.5-3.3-4-3.4
		C1066.6,236.7,1066,238,1066.7,239.8"/>
				<path fill="#FAA634" d="M1058.3,239c0.7,1.7,2.5,3.3,3.9,3.4c1.5,0.1,2.1-1.2,1.4-2.9s-2.5-3.3-3.9-3.4
		C1058.2,236,1057.6,237.3,1058.3,239"/>
				<path fill="#FAA634" d="M1049.8,238.3c0.7,1.7,2.4,3.3,3.9,3.4c1.5,0.1,2.1-1.2,1.4-2.9s-2.5-3.3-3.9-3.4
		C1049.7,235.3,1049.1,236.6,1049.8,238.3"/>
				<path fill="#FAA634" d="M1041.2,237.6c0.7,1.7,2.4,3.3,3.9,3.4c1.5,0.1,2.1-1.2,1.5-2.9c-0.7-1.7-2.4-3.3-3.9-3.4
		C1041.2,234.6,1040.6,235.9,1041.2,237.6"/>
				<path fill="#FAA634" d="M1032.7,236.9c0.7,1.7,2.4,3.3,3.9,3.4c1.5,0.1,2.2-1.2,1.5-2.9s-2.4-3.2-3.9-3.4
		C1032.7,233.9,1032.1,235.2,1032.7,236.9"/>
				<path fill="#FAA634" d="M1024.2,236.3c0.6,1.7,2.4,3.2,3.8,3.4s2.2-1.2,1.5-2.9c-0.6-1.7-2.4-3.2-3.8-3.4
		C1024.2,233.2,1023.5,234.5,1024.2,236.3"/>
				<path fill="#FAA634" d="M1015.6,235.6c0.6,1.7,2.3,3.2,3.8,3.4c1.5,0.1,2.2-1.2,1.6-2.9c-0.6-1.7-2.3-3.2-3.8-3.4
		C1015.7,232.5,1015,233.9,1015.6,235.6"/>
				<path fill="#FAA634" d="M1007,235c0.6,1.7,2.3,3.2,3.8,3.3s2.2-1.2,1.6-3c-0.6-1.7-2.3-3.2-3.8-3.3S1006.4,233.2,1007,235" />
				<path fill="#FAA634" d="M998.4,234.4c0.6,1.7,2.3,3.2,3.8,3.3s2.2-1.2,1.6-3c-0.6-1.7-2.3-3.2-3.8-3.3
		C998.6,231.3,997.8,232.6,998.4,234.4"/>
				<path fill="#FAA634" d="M989.8,233.8c0.6,1.7,2.2,3.2,3.7,3.3s2.3-1.2,1.7-3c-0.6-1.7-2.3-3.2-3.7-3.3
		C990,230.7,989.2,232,989.8,233.8"/>
				<path fill="#FAA634" d="M981.2,233.2c0.5,1.7,2.2,3.2,3.7,3.3s2.3-1.2,1.7-3c-0.6-1.7-2.2-3.2-3.7-3.3
		C981.4,230.2,980.6,231.5,981.2,233.2"/>
				<path fill="#FAA634" d="M1112.5,234.8c0.8,1.7,2.6,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.3-4.1-3.4
		C1112.2,231.7,1111.7,233,1112.5,234.8"/>
				<path fill="#FAA634" d="M1104.3,233.9c0.8,1.7,2.6,3.3,4.1,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.3-4-3.4
		C1104,230.9,1103.5,232.2,1104.3,233.9"/>
				<path fill="#FAA634" d="M1096,233.1c0.8,1.7,2.6,3.3,4,3.4c1.4,0.1,2-1.1,1.2-2.9c-0.8-1.7-2.6-3.3-4-3.4
		C1095.7,230.1,1095.2,231.4,1096,233.1"/>
				<path fill="#FAA634" d="M1087.7,232.3c0.8,1.7,2.6,3.3,4,3.4c1.4,0.1,2-1.2,1.2-2.9s-2.6-3.3-4-3.4
		C1087.5,229.2,1086.9,230.5,1087.7,232.3"/>
				<path fill="#FAA634" d="M1079.4,231.5c0.8,1.7,2.5,3.3,4,3.4c1.5,0.1,2-1.2,1.2-2.9s-2.5-3.3-4-3.4
		C1079.2,228.4,1078.6,229.7,1079.4,231.5"/>
				<path fill="#FAA634" d="M1071,230.7c0.7,1.7,2.5,3.3,4,3.4c1.5,0.1,2-1.2,1.3-2.9s-2.5-3.3-4-3.4C1070.9,227.7,1070.3,229,1071,230.7"
				/>
				<path fill="#FAA634" d="M1062.7,229.9c0.7,1.7,2.5,3.2,3.9,3.4c1.5,0.1,2-1.2,1.3-2.9s-2.5-3.2-3.9-3.4
		C1062.5,226.9,1061.9,228.2,1062.7,229.9"/>
				<path fill="#FAA634" d="M1054.3,229.2c0.7,1.7,2.5,3.2,3.9,3.4c1.5,0.1,2.1-1.2,1.3-2.9c-0.7-1.7-2.5-3.2-3.9-3.4
		C1054.2,226.2,1053.6,227.5,1054.3,229.2"/>
				<path fill="#FAA634" d="M1045.9,228.5c0.7,1.7,2.4,3.2,3.9,3.4c1.5,0.1,2.1-1.2,1.4-2.9s-2.4-3.2-3.9-3.4
		C1045.8,225.4,1045.2,226.8,1045.9,228.5"/>
				<path fill="#FAA634" d="M1037.5,227.8c0.7,1.7,2.4,3.2,3.9,3.4c1.5,0.1,2.1-1.2,1.4-2.9s-2.4-3.2-3.9-3.4
		C1037.4,224.7,1036.8,226.1,1037.5,227.8"/>
				<path fill="#FAA634" d="M1029,227.1c0.7,1.7,2.4,3.2,3.8,3.4c1.5,0.1,2.1-1.2,1.5-2.9c-0.7-1.7-2.4-3.2-3.8-3.3
		C1029,224.1,1028.4,225.4,1029,227.1"/>
				<path fill="#FAA634" d="M1020.6,226.4c0.6,1.7,2.3,3.2,3.8,3.3c1.5,0.1,2.1-1.2,1.5-2.9s-2.4-3.2-3.8-3.3
		C1020.6,223.4,1019.9,224.7,1020.6,226.4"/>
				<path fill="#FAA634" d="M1012.1,225.8c0.6,1.7,2.3,3.2,3.8,3.3s2.2-1.2,1.5-2.9c-0.6-1.7-2.3-3.2-3.8-3.3
		C1012.2,222.8,1011.5,224.1,1012.1,225.8"/>
				<path fill="#FAA634" d="M1003.6,225.2c0.6,1.7,2.3,3.2,3.8,3.3s2.2-1.2,1.6-2.9c-0.6-1.7-2.3-3.2-3.8-3.3
		C1003.7,222.1,1003,223.4,1003.6,225.2"/>
				<path fill="#FAA634" d="M995.1,224.6c0.6,1.7,2.3,3.2,3.7,3.3s2.2-1.2,1.6-2.9c-0.6-1.7-2.3-3.2-3.7-3.3S994.5,222.8,995.1,224.6" />
				<path fill="#FAA634" d="M986.6,224c0.6,1.7,2.2,3.2,3.7,3.3s2.2-1.2,1.6-2.9c-0.6-1.7-2.2-3.2-3.7-3.3C986.8,221,986,222.3,986.6,224"
				/>
				<path fill="#FAA634" d="M1099.8,224.1c0.8,1.7,2.6,3.3,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.3-4-3.4
		C1099.5,221.1,1099,222.4,1099.8,224.1"/>
				<path fill="#FAA634" d="M1091.6,223.3c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.2-4-3.4
		C1091.3,220.3,1090.8,221.6,1091.6,223.3"/>
				<path fill="#FAA634" d="M1083.3,222.5c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,2-1.1,1.2-2.9c-0.8-1.7-2.6-3.2-4-3.4
		C1083.1,219.5,1082.6,220.8,1083.3,222.5"/>
				<path fill="#FAA634" d="M1075.1,221.7c0.8,1.7,2.5,3.2,4,3.4c1.4,0.1,2-1.2,1.2-2.9s-2.5-3.2-4-3.4
		C1074.9,218.7,1074.4,220,1075.1,221.7"/>
				<path fill="#FAA634" d="M1066.9,220.9c0.7,1.7,2.5,3.2,3.9,3.4c1.4,0.1,2-1.2,1.2-2.9s-2.5-3.2-3.9-3.4
		C1066.7,217.9,1066.1,219.2,1066.9,220.9"/>
				<path fill="#FAA634" d="M1058.6,220.2c0.7,1.7,2.5,3.2,3.9,3.4c1.4,0.1,2-1.2,1.3-2.9s-2.5-3.2-3.9-3.4
		C1058.4,217.2,1057.9,218.4,1058.6,220.2"/>
				<path fill="#FAA634" d="M1050.3,219.4c0.7,1.7,2.4,3.2,3.9,3.4c1.4,0.1,2-1.2,1.3-2.9s-2.5-3.2-3.9-3.3
		C1050.2,216.4,1049.6,217.7,1050.3,219.4"/>
				<path fill="#FAA634" d="M1042,218.7c0.7,1.7,2.4,3.2,3.9,3.3c1.4,0.1,2.1-1.2,1.4-2.9s-2.4-3.2-3.9-3.3S1041.3,217,1042,218.7" />
				<path fill="#FAA634" d="M1033.7,218c0.7,1.7,2.4,3.2,3.8,3.3c1.5,0.1,2.1-1.2,1.4-2.9s-2.4-3.2-3.8-3.3
		C1033.6,215,1033,216.3,1033.7,218"/>
				<path fill="#FAA634" d="M1025.3,217.3c0.7,1.7,2.4,3.2,3.8,3.3c1.5,0.1,2.1-1.2,1.4-2.9s-2.4-3.2-3.8-3.3S1024.7,215.6,1025.3,217.3"
				/>
				<path fill="#FAA634" d="M1017,216.7c0.6,1.7,2.3,3.2,3.8,3.3s2.1-1.2,1.5-2.9c-0.7-1.7-2.3-3.2-3.8-3.3S1016.3,215,1017,216.7" />
				<path fill="#FAA634" d="M1008.6,216.1c0.6,1.7,2.3,3.2,3.8,3.3s2.1-1.2,1.5-2.9s-2.3-3.2-3.8-3.3C1008.6,213,1008,214.3,1008.6,216.1"
				/>
				<path fill="#FAA634" d="M1000.2,215.4c0.6,1.7,2.3,3.2,3.7,3.3c1.5,0.1,2.1-1.2,1.5-2.9s-2.3-3.2-3.7-3.3S999.6,213.7,1000.2,215.4" />
				<path fill="#FAA634" d="M991.8,214.8c0.6,1.7,2.2,3.2,3.7,3.3s2.2-1.2,1.6-2.9c-0.6-1.7-2.3-3.2-3.7-3.3
		C991.9,211.8,991.2,213.1,991.8,214.8"/>
				<path fill="#FAA634" d="M1087.1,213.5c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.2-4-3.4
		C1086.8,210.6,1086.3,211.8,1087.1,213.5"/>
				<path fill="#FAA634" d="M1079,212.7c0.8,1.7,2.5,3.2,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.2-4-3.4
		C1078.7,209.8,1078.2,211,1079,212.7"/>
				<path fill="#FAA634" d="M1070.8,212c0.8,1.7,2.5,3.2,3.9,3.4c1.4,0.1,1.9-1.1,1.2-2.9c-0.8-1.7-2.5-3.2-3.9-3.3
		C1070.6,209,1070.1,210.2,1070.8,212"/>
				<path fill="#FAA634" d="M1062.7,211.2c0.7,1.7,2.5,3.2,3.9,3.3s2-1.2,1.2-2.9s-2.5-3.2-3.9-3.3
		C1062.5,208.2,1061.9,209.5,1062.7,211.2"/>
				<path fill="#FAA634" d="M1054.5,210.5c0.7,1.7,2.5,3.2,3.9,3.3s2-1.2,1.2-2.9c-0.7-1.7-2.5-3.2-3.9-3.3
		C1054.3,207.5,1053.8,208.7,1054.5,210.5"/>
				<path fill="#FAA634" d="M1046.3,209.7c0.7,1.7,2.4,3.2,3.9,3.3c1.4,0.1,2-1.2,1.3-2.9s-2.4-3.2-3.9-3.3
		C1046.2,206.7,1045.6,208,1046.3,209.7"/>
				<path fill="#FAA634" d="M1038.1,209c0.7,1.7,2.4,3.2,3.8,3.3s2-1.2,1.3-2.9s-2.4-3.2-3.8-3.3S1037.4,207.3,1038.1,209" />
				<path fill="#FAA634" d="M1029.8,208.3c0.7,1.7,2.4,3.2,3.8,3.3s2-1.2,1.4-2.9c-0.7-1.7-2.4-3.2-3.8-3.3S1029.2,206.6,1029.8,208.3" />
				<path fill="#FAA634" d="M1021.6,207.7c0.7,1.7,2.4,3.2,3.8,3.3s2.1-1.2,1.4-2.9s-2.4-3.2-3.8-3.3
		C1021.6,204.7,1020.9,206,1021.6,207.7"/>
				<path fill="#FAA634" d="M1013.3,207c0.6,1.7,2.3,3.2,3.8,3.3c1.4,0.1,2.1-1.2,1.4-2.9c-0.7-1.7-2.3-3.2-3.8-3.3
		C1013.3,204,1012.7,205.3,1013.3,207"/>
				<path fill="#FAA634" d="M1005.1,206.4c0.6,1.7,2.3,3.2,3.7,3.3s2.1-1.2,1.5-2.9s-2.3-3.2-3.7-3.3
		C1005.1,203.4,1004.4,204.7,1005.1,206.4"/>
				<g>
					<path fill="#FAA634" d="M1484.3,640.7c0.6,1.6,2.7,3.1,4.5,3.3c1.9,0.2,2.8-0.9,2.2-2.6c-0.7-1.6-2.7-3.1-4.5-3.4
			C1484.6,637.9,1483.6,639.1,1484.3,640.7z"/>
					<path fill="#FAA634" d="M1473.6,639.5c0.6,1.6,2.6,3.1,4.5,3.3s2.9-0.9,2.2-2.6c-0.6-1.6-2.7-3.1-4.5-3.3
			C1474,636.7,1473,637.9,1473.6,639.5z"/>
					<path fill="#FAA634" d="M1480.6,631.4c0.7,1.7,2.7,3.2,4.5,3.4s2.8-1,2.1-2.6c-0.7-1.7-2.7-3.2-4.5-3.4
			C1480.9,628.6,1479.9,629.8,1480.6,631.4z"/>
					<path fill="#FAA634" d="M1476.8,622c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.6c-0.7-1.7-2.7-3.2-4.5-3.4S1476.1,620.4,1476.8,622z" />
					<path fill="#FAA634" d="M1483.2,613.8c0.7,1.7,2.8,3.2,4.5,3.4c1.8,0.2,2.7-1,1.9-2.7c-0.7-1.7-2.8-3.2-4.6-3.4
			C1483.4,610.9,1482.5,612.1,1483.2,613.8z"/>
					<path fill="#FAA634" d="M1472.9,612.6c0.7,1.7,2.7,3.2,4.5,3.4s2.7-1,2-2.7s-2.7-3.2-4.5-3.4C1473.1,609.7,1472.2,610.9,1472.9,612.6
			z"/>
					<path fill="#FAA634" d="M1479.1,604.3c0.7,1.7,2.8,3.2,4.5,3.4c1.8,0.2,2.6-1,1.9-2.7c-0.8-1.7-2.8-3.2-4.5-3.4
			C1479.2,601.4,1478.4,602.6,1479.1,604.3z"/>
				</g>
			</g>
			<g className={classNames("g-africa", { 'g-map-active': highlight === 'africa' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('africa')}>
				<path fill="#0074BB" d="M807.9,543.8c0.1,1.6,1.7,2.9,3.7,3c2,0,3.6-1.3,3.5-2.9c-0.1-1.6-1.7-2.9-3.7-3
			C809.4,540.9,807.8,542.2,807.9,543.8"/>
				<path fill="#0074BB" d="M842,535c0.1,1.6,1.8,3,3.8,3c2,0,3.5-1.3,3.4-2.9c-0.1-1.6-1.8-3-3.8-3C843.5,532,841.9,533.3,842,535" />
				<path fill="#0074BB" d="M830.5,534.8c0.1,1.6,1.8,3,3.8,3c2,0,3.5-1.3,3.5-2.9c-0.1-1.6-1.8-3-3.8-3
			C832,531.9,830.4,533.2,830.5,534.8"/>
				<path fill="#0074BB" d="M819,534.7c0.1,1.6,1.8,3,3.8,3c2,0,3.6-1.3,3.5-2.9c-0.1-1.6-1.8-3-3.8-3
			C820.5,531.7,819,533.1,819,534.7"/>
				<path fill="#0074BB" d="M807.5,534.6c0.1,1.6,1.7,3,3.7,3c2,0,3.6-1.3,3.5-2.9c-0.1-1.6-1.7-3-3.7-3
			C809,531.6,807.4,533,807.5,534.6"/>
				<path fill="#0074BB" d="M796,534.5c0.1,1.6,1.7,3,3.7,3c2,0,3.6-1.3,3.5-2.9c-0.1-1.6-1.7-3-3.7-3
			C797.5,531.6,795.9,532.9,796,534.5"/>
				<path fill="#0074BB" d="M784.5,534.5c0,1.6,1.7,3,3.7,3c2,0,3.6-1.3,3.5-2.9c-0.1-1.6-1.7-3-3.7-3
			C786,531.5,784.4,532.8,784.5,534.5"/>
				<path fill="#0074BB" d="M841.5,525.7c0.1,1.7,1.8,3,3.8,3c2,0,3.5-1.3,3.4-2.9c-0.1-1.7-1.8-3-3.8-3.1
			C842.9,522.7,841.4,524,841.5,525.7"/>
				<path fill="#0074BB" d="M830,525.5c0.1,1.7,1.8,3,3.8,3c2,0,3.5-1.3,3.4-2.9c-0.1-1.7-1.8-3-3.8-3
			C831.5,522.5,830,523.9,830,525.5"/>
				<path fill="#0074BB" d="M818.6,525.4c0.1,1.7,1.8,3,3.7,3c2,0,3.5-1.3,3.5-2.9c-0.1-1.7-1.8-3-3.7-3
			C820.1,522.4,818.5,523.8,818.6,525.4"/>
				<path fill="#0074BB" d="M807.1,525.3c0.1,1.7,1.7,3,3.7,3c2,0,3.5-1.3,3.5-3c-0.1-1.7-1.7-3-3.7-3
			C808.6,522.3,807.1,523.7,807.1,525.3"/>
				<path fill="#0074BB" d="M795.7,525.2c0.1,1.7,1.7,3,3.7,3c2,0,3.6-1.3,3.5-3c-0.1-1.7-1.7-3-3.7-3
			C797.2,522.2,795.6,523.6,795.7,525.2"/>
				<path fill="#0074BB" d="M852.3,516.4c0.1,1.7,1.8,3,3.8,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C853.7,513.4,852.2,514.8,852.3,516.4"/>
				<path fill="#0074BB" d="M840.9,516.3c0.1,1.7,1.8,3,3.8,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C842.4,513.3,840.8,514.6,840.9,516.3"/>
				<path fill="#0074BB" d="M829.5,516.1c0.1,1.7,1.8,3,3.8,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C831,513.1,829.4,514.5,829.5,516.1"/>
				<path fill="#0074BB" d="M818.1,516c0.1,1.7,1.8,3,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3-3.7-3.1
			C819.6,513,818.1,514.3,818.1,516"/>
				<path fill="#0074BB" d="M806.7,515.9c0.1,1.7,1.7,3,3.7,3c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3-3.7-3.1
			C808.2,512.9,806.6,514.2,806.7,515.9"/>
				<path fill="#0074BB" d="M795.3,515.8c0.1,1.7,1.7,3,3.7,3c2,0,3.5-1.3,3.5-3c-0.1-1.7-1.7-3-3.7-3.1
			C796.8,512.8,795.2,514.2,795.3,515.8"/>
				<path fill="#0074BB" d="M783.9,515.8c0.1,1.7,1.7,3,3.7,3c2,0,3.5-1.3,3.5-3c-0.1-1.7-1.7-3-3.7-3
			C785.4,512.8,783.8,514.1,783.9,515.8"/>
				<path fill="#0074BB" d="M772.5,515.8c0,1.7,1.7,3,3.7,3c2,0,3.6-1.3,3.5-3c-0.1-1.7-1.7-3-3.7-3
			C774,512.7,772.4,514.1,772.5,515.8"/>
				<path fill="#0074BB" d="M919.5,508.4c0.2,1.7,1.9,3.1,3.9,3.1c2,0.1,3.4-1.3,3.2-2.9c-0.2-1.7-1.9-3.1-3.9-3.2
			C920.8,505.4,919.3,506.7,919.5,508.4"/>
				<path fill="#0074BB" d="M851.7,507c0.1,1.7,1.8,3.1,3.8,3.1c2,0,3.5-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C853.1,503.9,851.6,505.3,851.7,507"/>
				<path fill="#0074BB" d="M840.3,506.8c0.1,1.7,1.8,3.1,3.8,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C841.7,503.8,840.2,505.1,840.3,506.8"/>
				<path fill="#0074BB" d="M829,506.6c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.7-3.1
			C830.4,503.6,828.9,505,829,506.6"/>
				<path fill="#0074BB" d="M817.7,506.5c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.7-3.1
			C819.1,503.5,817.6,504.8,817.7,506.5"/>
				<path fill="#0074BB" d="M806.3,506.4c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.7-3.1-3.7-3.1
			C807.7,503.4,806.2,504.7,806.3,506.4"/>
				<path fill="#0074BB" d="M794.9,506.4c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.7-3.1-3.7-3.1
			C796.4,503.3,794.9,504.7,794.9,506.4"/>
				<path fill="#0074BB" d="M783.6,506.3c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.3,3.5-3c-0.1-1.7-1.7-3.1-3.7-3.1
			S783.5,504.6,783.6,506.3"/>
				<path fill="#0074BB" d="M772.2,506.3c0,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.5-3c-0.1-1.7-1.7-3.1-3.7-3.1
			C773.7,503.2,772.1,504.6,772.2,506.3"/>
				<path fill="#0074BB" d="M929.7,499.1c0.2,1.7,1.9,3.1,3.9,3.2c1.9,0.1,3.4-1.3,3.2-3c-0.2-1.7-1.9-3.1-3.9-3.2
			C930.9,496.1,929.5,497.4,929.7,499.1"/>
				<path fill="#0074BB" d="M918.5,498.8c0.2,1.7,1.9,3.1,3.9,3.2c1.9,0.1,3.4-1.3,3.2-3c-0.2-1.7-1.9-3.1-3.9-3.2
			C919.7,495.8,918.3,497.1,918.5,498.8"/>
				<path fill="#0074BB" d="M862.3,497.6c0.1,1.7,1.8,3.1,3.8,3.1c2,0,3.4-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C863.6,494.5,862.1,495.9,862.3,497.6"/>
				<path fill="#0074BB" d="M851,497.4c0.1,1.7,1.8,3.1,3.8,3.1c2,0,3.4-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C852.4,494.3,850.9,495.7,851,497.4"/>
				<path fill="#0074BB" d="M839.7,497.2c0.1,1.7,1.8,3.1,3.8,3.1c2,0,3.5-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.1
			C841.1,494.2,839.6,495.5,839.7,497.2"/>
				<path fill="#0074BB" d="M828.4,497.1c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.7-3.1
			C829.8,494,828.3,495.4,828.4,497.1"/>
				<path fill="#0074BB" d="M817.1,497c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.8-3.1-3.7-3.1
			C818.6,493.9,817.1,495.3,817.1,497"/>
				<path fill="#0074BB" d="M805.8,496.9c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3c-0.1-1.7-1.7-3.1-3.7-3.1
			C807.3,493.8,805.8,495.2,805.8,496.9"/>
				<path fill="#0074BB" d="M794.5,496.8c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C796,493.7,794.5,495.1,794.5,496.8"/>
				<path fill="#0074BB" d="M783.2,496.7c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C784.7,493.7,783.2,495,783.2,496.7"/>
				<path fill="#0074BB" d="M771.9,496.7c0,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.5-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C773.4,493.6,771.9,495,771.9,496.7"/>
				<path fill="#0074BB" d="M928.5,489.5c0.2,1.7,1.9,3.1,3.9,3.2c1.9,0.1,3.3-1.3,3.1-3c-0.2-1.7-2-3.2-3.9-3.2
			C929.7,486.4,928.3,487.8,928.5,489.5"/>
				<path fill="#0074BB" d="M917.4,489.2c0.2,1.7,1.9,3.1,3.9,3.2c1.9,0.1,3.3-1.3,3.1-3c-0.2-1.7-1.9-3.2-3.9-3.2
			C918.6,486.1,917.2,487.5,917.4,489.2"/>
				<path fill="#0074BB" d="M872.7,488.1c0.2,1.7,1.8,3.1,3.8,3.2c1.9,0,3.4-1.3,3.2-3c-0.2-1.7-1.9-3.1-3.8-3.2
			C874,485,872.6,486.4,872.7,488.1"/>
				<path fill="#0074BB" d="M861.5,487.9c0.1,1.7,1.8,3.1,3.8,3.2c1.9,0,3.4-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.2
			C862.8,484.8,861.4,486.2,861.5,487.9"/>
				<path fill="#0074BB" d="M850.3,487.7c0.1,1.7,1.8,3.1,3.8,3.2c1.9,0,3.4-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.8-3.2
			C851.6,484.6,850.2,486,850.3,487.7"/>
				<path fill="#0074BB" d="M839.1,487.6c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.4-1.3,3.3-3c-0.1-1.7-1.8-3.1-3.7-3.2
			C840.4,484.5,839,485.9,839.1,487.6"/>
				<path fill="#0074BB" d="M827.8,487.4c0.1,1.7,1.8,3.1,3.7,3.1c1.9,0,3.4-1.3,3.3-3.1c-0.1-1.7-1.8-3.1-3.7-3.2
			C829.2,484.3,827.7,485.7,827.8,487.4"/>
				<path fill="#0074BB" d="M816.6,487.3c0.1,1.7,1.8,3.1,3.7,3.1c2,0,3.5-1.3,3.4-3.1c-0.1-1.7-1.8-3.1-3.7-3.1
			C818,484.2,816.5,485.6,816.6,487.3"/>
				<path fill="#0074BB" d="M805.4,487.2c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C806.8,484.1,805.3,485.5,805.4,487.2"/>
				<path fill="#0074BB" d="M794.1,487.1c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C795.6,484,794,485.4,794.1,487.1"/>
				<path fill="#0074BB" d="M782.9,487.1c0.1,1.7,1.7,3.1,3.7,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.1
			C784.3,484,782.8,485.4,782.9,487.1"/>
				<path fill="#0074BB" d="M771.6,487.1c0.1,1.7,1.7,3.1,3.6,3.1c2,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.6-3.1
			C773.1,483.9,771.6,485.3,771.6,487.1"/>
				<path fill="#0074BB" d="M760.4,487c0,1.7,1.7,3.1,3.6,3.1c2,0,3.5-1.4,3.5-3.1s-1.7-3.1-3.6-3.1
			C761.9,483.9,760.3,485.3,760.4,487"/>
				<path fill="#0074BB" d="M927.3,479.8c0.2,1.7,1.9,3.2,3.9,3.2c1.9,0.1,3.3-1.3,3.1-3c-0.2-1.7-2-3.2-3.9-3.2
			C928.5,476.7,927.1,478,927.3,479.8"/>
				<path fill="#0074BB" d="M916.3,479.4c0.2,1.7,1.9,3.2,3.9,3.2c1.9,0.1,3.3-1.3,3.1-3c-0.2-1.7-1.9-3.2-3.9-3.2
			C917.5,476.4,916.1,477.7,916.3,479.4"/>
				<path fill="#0074BB" d="M871.8,478.4c0.2,1.7,1.9,3.2,3.8,3.2c1.9,0,3.4-1.3,3.2-3.1c-0.2-1.7-1.9-3.2-3.8-3.2
			C873.1,475.3,871.7,476.7,871.8,478.4"/>
				<path fill="#0074BB" d="M860.7,478.2c0.1,1.7,1.8,3.2,3.8,3.2c1.9,0,3.4-1.3,3.2-3.1c-0.2-1.7-1.8-3.2-3.8-3.2
			C862,475.1,860.6,476.5,860.7,478.2"/>
				<path fill="#0074BB" d="M849.5,478c0.1,1.7,1.8,3.1,3.8,3.2c1.9,0,3.4-1.3,3.3-3.1c-0.1-1.7-1.8-3.2-3.8-3.2
			C850.9,474.9,849.4,476.3,849.5,478"/>
				<path fill="#0074BB" d="M838.4,477.8c0.1,1.7,1.8,3.1,3.7,3.2c1.9,0,3.4-1.3,3.3-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C839.7,474.7,838.3,476.1,838.4,477.8"/>
				<path fill="#0074BB" d="M827.2,477.7c0.1,1.7,1.8,3.1,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C828.6,474.6,827.1,476,827.2,477.7"/>
				<path fill="#0074BB" d="M816.1,477.6c0.1,1.7,1.8,3.1,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.8-3.1-3.7-3.2
			C817.4,474.5,816,475.9,816.1,477.6"/>
				<path fill="#0074BB" d="M804.9,477.5c0.1,1.7,1.7,3.1,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.7-3.1-3.7-3.2
			C806.3,474.4,804.8,475.8,804.9,477.5"/>
				<path fill="#0074BB" d="M793.7,477.4c0.1,1.7,1.7,3.1,3.7,3.1c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.7-3.2
			C795.1,474.3,793.6,475.7,793.7,477.4"/>
				<path fill="#0074BB" d="M782.5,477.4c0.1,1.7,1.7,3.1,3.6,3.1c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.6-3.1
			C784,474.2,782.4,475.6,782.5,477.4"/>
				<path fill="#0074BB" d="M771.3,477.3c0.1,1.7,1.7,3.1,3.6,3.1c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.6-3.1
			C772.8,474.2,771.3,475.6,771.3,477.3"/>
				<path fill="#0074BB" d="M760.1,477.3c0,1.7,1.7,3.1,3.6,3.1c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.1-3.6-3.1
			C761.6,474.2,760.1,475.6,760.1,477.3"/>
				<path fill="#0074BB" d="M926.1,470c0.2,1.7,2,3.2,3.9,3.2c1.9,0.1,3.3-1.3,3-3c-0.2-1.7-2-3.2-3.9-3.3
			C927.2,466.9,925.9,468.2,926.1,470"/>
				<path fill="#0074BB" d="M915.1,469.6c0.2,1.7,1.9,3.2,3.8,3.2c1.9,0.1,3.3-1.3,3.1-3c-0.2-1.7-1.9-3.2-3.9-3.3
			C916.2,466.5,914.9,467.9,915.1,469.6"/>
				<path fill="#0074BB" d="M893,469.1c0.2,1.7,1.9,3.2,3.8,3.2c1.9,0,3.3-1.3,3.1-3.1c-0.2-1.7-1.9-3.2-3.8-3.2
			C894.2,466,892.8,467.3,893,469.1"/>
				<path fill="#0074BB" d="M882,468.8c0.2,1.7,1.9,3.2,3.8,3.2c1.9,0,3.3-1.3,3.1-3.1c-0.2-1.7-1.9-3.2-3.8-3.2
			C883.2,465.7,881.8,467.1,882,468.8"/>
				<path fill="#0074BB" d="M870.9,468.6c0.2,1.7,1.9,3.2,3.8,3.2c1.9,0,3.3-1.3,3.2-3.1c-0.2-1.7-1.9-3.2-3.8-3.2
			C872.2,465.5,870.8,466.9,870.9,468.6"/>
				<path fill="#0074BB" d="M859.9,468.4c0.2,1.7,1.8,3.2,3.8,3.2c1.9,0,3.4-1.3,3.2-3.1c-0.2-1.7-1.8-3.2-3.8-3.2
			C861.1,465.3,859.7,466.7,859.9,468.4"/>
				<path fill="#0074BB" d="M848.8,468.2c0.1,1.7,1.8,3.2,3.7,3.2c1.9,0,3.4-1.3,3.2-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C850.1,465.1,848.6,466.5,848.8,468.2"/>
				<path fill="#0074BB" d="M837.7,468c0.1,1.7,1.8,3.2,3.7,3.2c1.9,0,3.4-1.4,3.2-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C839,464.9,837.6,466.3,837.7,468"/>
				<path fill="#0074BB" d="M826.6,467.9c0.1,1.7,1.8,3.2,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C827.9,464.8,826.5,466.2,826.6,467.9"/>
				<path fill="#0074BB" d="M815.5,467.8c0.1,1.7,1.8,3.2,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C816.9,464.6,815.4,466,815.5,467.8"/>
				<path fill="#0074BB" d="M804.4,467.7c0.1,1.7,1.7,3.2,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.7-3.2-3.7-3.2
			C805.8,464.5,804.3,465.9,804.4,467.7"/>
				<path fill="#0074BB" d="M793.3,467.6c0.1,1.7,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C794.7,464.5,793.2,465.9,793.3,467.6"/>
				<path fill="#0074BB" d="M782.1,467.6c0.1,1.7,1.7,3.1,3.6,3.2c1.9,0,3.4-1.4,3.4-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C783.6,464.4,782.1,465.8,782.1,467.6"/>
				<path fill="#0074BB" d="M771,467.5c0.1,1.7,1.7,3.1,3.6,3.2c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C772.5,464.4,770.9,465.8,771,467.5"/>
				<path fill="#0074BB" d="M759.9,467.5c0,1.7,1.6,3.1,3.6,3.1c1.9,0,3.5-1.4,3.4-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C761.4,464.4,759.8,465.8,759.9,467.5"/>
				<path fill="#0074BB" d="M935.7,460.4c0.2,1.7,2,3.2,3.9,3.3c1.9,0.1,3.2-1.3,3-3.1c-0.3-1.8-2-3.2-3.9-3.3
			C936.8,457.3,935.5,458.7,935.7,460.4"/>
				<path fill="#0074BB" d="M881,459c0.2,1.8,1.9,3.2,3.8,3.2c1.9,0,3.3-1.3,3.1-3.1c-0.2-1.8-1.9-3.2-3.8-3.2
			C882.2,455.8,880.8,457.2,881,459"/>
				<path fill="#0074BB" d="M870,458.7c0.2,1.8,1.9,3.2,3.8,3.2c1.9,0,3.3-1.3,3.1-3.1c-0.2-1.8-1.9-3.2-3.8-3.2
			C871.2,455.6,869.8,457,870,458.7"/>
				<path fill="#0074BB" d="M859,458.5c0.2,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.1c-0.2-1.7-1.8-3.2-3.8-3.2
			C860.2,455.4,858.8,456.8,859,458.5"/>
				<path fill="#0074BB" d="M848,458.3c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.1c-0.2-1.8-1.8-3.2-3.7-3.2
			C849.2,455.2,847.8,456.6,848,458.3"/>
				<path fill="#0074BB" d="M836.9,458.2c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.4-1.4,3.2-3.1c-0.1-1.8-1.8-3.2-3.7-3.2
			C838.3,455,836.8,456.4,836.9,458.2"/>
				<path fill="#0074BB" d="M825.9,458c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.4-1.4,3.2-3.1c-0.1-1.7-1.8-3.2-3.7-3.2
			C827.2,454.9,825.8,456.3,825.9,458"/>
				<path fill="#0074BB" d="M814.9,457.9c0.1,1.8,1.7,3.2,3.7,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.8-1.8-3.2-3.7-3.2
			C816.2,454.8,814.8,456.2,814.9,457.9"/>
				<path fill="#0074BB" d="M803.8,457.8c0.1,1.7,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C805.2,454.7,803.7,456.1,803.8,457.8"/>
				<path fill="#0074BB" d="M792.8,457.8c0.1,1.7,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.8-1.7-3.2-3.6-3.2
			C794.2,454.6,792.7,456,792.8,457.8"/>
				<path fill="#0074BB" d="M781.7,457.7c0.1,1.7,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.1c-0.1-1.7-1.7-3.2-3.6-3.2
			C783.2,454.5,781.7,456,781.7,457.7"/>
				<path fill="#0074BB" d="M770.7,457.7c0.1,1.7,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.4-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C772.1,454.5,770.6,455.9,770.7,457.7"/>
				<path fill="#0074BB" d="M890.8,449.3c0.2,1.8,1.9,3.2,3.8,3.3c1.9,0,3.3-1.3,3-3.1c-0.2-1.8-1.9-3.2-3.8-3.3
			C892,446.1,890.6,447.5,890.8,449.3"/>
				<path fill="#0074BB" d="M879.9,449c0.2,1.8,1.9,3.2,3.8,3.3c1.9,0,3.3-1.3,3.1-3.1c-0.2-1.8-1.9-3.2-3.8-3.3
			C881.1,445.9,879.7,447.3,879.9,449"/>
				<path fill="#0074BB" d="M869,448.8c0.2,1.8,1.9,3.2,3.8,3.3c1.9,0,3.3-1.4,3.1-3.1c-0.2-1.8-1.9-3.2-3.8-3.3
			C870.2,445.7,868.8,447.1,869,448.8"/>
				<path fill="#0074BB" d="M858.1,448.6c0.2,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.1-3.1c-0.2-1.8-1.8-3.2-3.7-3.3
			C859.3,445.4,857.9,446.9,858.1,448.6"/>
				<path fill="#0074BB" d="M847.1,448.4c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.1c-0.2-1.8-1.8-3.2-3.7-3.2
			C848.4,445.3,847,446.7,847.1,448.4"/>
				<path fill="#0074BB" d="M836.2,448.3c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.1c-0.1-1.8-1.8-3.2-3.7-3.2
			C837.5,445.1,836,446.5,836.2,448.3"/>
				<path fill="#0074BB" d="M825.2,448.1c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.1c-0.1-1.8-1.8-3.2-3.7-3.2
			C826.5,445,825.1,446.4,825.2,448.1"/>
				<path fill="#0074BB" d="M814.3,448c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.2-3.1c-0.1-1.8-1.8-3.2-3.7-3.2
			C815.6,444.8,814.2,446.2,814.3,448"/>
				<path fill="#0074BB" d="M803.3,447.9c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C804.7,444.7,803.2,446.1,803.3,447.9"/>
				<path fill="#0074BB" d="M792.3,447.8c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C793.7,444.7,792.2,446.1,792.3,447.8"/>
				<path fill="#0074BB" d="M781.3,447.8c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			S781.3,446,781.3,447.8"/>
				<path fill="#0074BB" d="M770.4,447.8c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C771.8,444.6,770.3,446,770.4,447.8"/>
				<path fill="#0074BB" d="M878.8,439.1c0.2,1.8,1.9,3.2,3.8,3.3c1.9,0,3.2-1.4,3-3.1c-0.2-1.8-1.9-3.2-3.8-3.3
			C880,435.9,878.6,437.3,878.8,439.1"/>
				<path fill="#0074BB" d="M868,438.8c0.2,1.8,1.9,3.2,3.7,3.3c1.9,0,3.3-1.4,3.1-3.1c-0.2-1.8-1.9-3.2-3.7-3.3
			C869.2,435.7,867.8,437.1,868,438.8"/>
				<path fill="#0074BB" d="M857.1,438.6c0.2,1.8,1.8,3.2,3.7,3.3c1.9,0,3.3-1.4,3.1-3.1c-0.2-1.8-1.8-3.2-3.7-3.3
			C858.3,435.5,857,436.9,857.1,438.6"/>
				<path fill="#0074BB" d="M846.3,438.5c0.2,1.8,1.8,3.2,3.7,3.3c1.9,0,3.3-1.4,3.1-3.1c-0.2-1.8-1.8-3.2-3.7-3.3
			C847.5,435.3,846.1,436.7,846.3,438.5"/>
				<path fill="#0074BB" d="M835.4,438.3c0.1,1.8,1.8,3.2,3.7,3.2s3.3-1.4,3.2-3.1c-0.2-1.8-1.8-3.2-3.7-3.3
			C836.7,435.1,835.3,436.5,835.4,438.3"/>
				<path fill="#0074BB" d="M824.5,438.1c0.1,1.8,1.8,3.2,3.7,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.8-3.2-3.7-3.2
			C825.8,435,824.4,436.4,824.5,438.1"/>
				<path fill="#0074BB" d="M813.6,438c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.8-3.2-3.6-3.2
			C814.9,434.8,813.5,436.3,813.6,438"/>
				<path fill="#0074BB" d="M802.7,437.9c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C804.1,434.7,802.6,436.2,802.7,437.9"/>
				<path fill="#0074BB" d="M791.8,437.9c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C793.2,434.7,791.7,436.1,791.8,437.9"/>
				<path fill="#0074BB" d="M780.9,437.8c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C782.3,434.6,780.9,436,780.9,437.8"/>
				<path fill="#0074BB" d="M770,437.8c0.1,1.8,1.6,3.2,3.5,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.5-3.2
			C771.4,434.6,770,436,770,437.8"/>
				<path fill="#0074BB" d="M877.7,429.1c0.2,1.8,1.9,3.2,3.7,3.3c1.9,0,3.2-1.4,3-3.1c-0.2-1.8-1.9-3.3-3.8-3.3
			C878.8,425.9,877.5,427.3,877.7,429.1"/>
				<path fill="#0074BB" d="M866.9,428.8c0.2,1.8,1.9,3.2,3.7,3.3c1.9,0,3.2-1.4,3-3.1c-0.2-1.8-1.9-3.3-3.7-3.3
			C868.1,425.6,866.7,427.1,866.9,428.8"/>
				<path fill="#0074BB" d="M856.2,428.6c0.2,1.8,1.8,3.2,3.7,3.3c1.9,0,3.2-1.4,3.1-3.1c-0.2-1.8-1.8-3.2-3.7-3.3
			C857.4,425.4,856,426.8,856.2,428.6"/>
				<path fill="#0074BB" d="M845.4,428.4c0.2,1.8,1.8,3.2,3.7,3.3c1.9,0,3.3-1.4,3.1-3.2c-0.2-1.8-1.8-3.2-3.7-3.3
			C846.6,425.2,845.2,426.7,845.4,428.4"/>
				<path fill="#0074BB" d="M834.6,428.3c0.1,1.8,1.8,3.2,3.7,3.3c1.9,0,3.3-1.4,3.1-3.2c-0.2-1.8-1.8-3.2-3.7-3.3
			C835.8,425.1,834.4,426.5,834.6,428.3"/>
				<path fill="#0074BB" d="M823.8,428.1c0.1,1.8,1.8,3.2,3.6,3.3c1.9,0,3.3-1.4,3.1-3.2s-1.8-3.2-3.6-3.3
			C825.1,424.9,823.7,426.4,823.8,428.1"/>
				<path fill="#0074BB" d="M813,428c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.6-3.3
			C814.3,424.8,812.9,426.2,813,428"/>
				<path fill="#0074BB" d="M802.2,427.9c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C803.5,424.7,802,426.1,802.2,427.9"/>
				<path fill="#0074BB" d="M791.3,427.8c0.1,1.8,1.7,3.2,3.6,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.6-3.2
			C792.7,424.6,791.2,426.1,791.3,427.8"/>
				<path fill="#0074BB" d="M780.5,427.8c0.1,1.8,1.7,3.2,3.5,3.2c1.9,0,3.3-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.5-3.2
			C781.9,424.6,780.4,426,780.5,427.8"/>
				<path fill="#0074BB" d="M769.7,427.8c0.1,1.8,1.6,3.2,3.5,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.7-3.2-3.5-3.2
			C771.1,424.6,769.6,426,769.7,427.8"/>
				<path fill="#0074BB" d="M758.8,427.8c0,1.8,1.6,3.2,3.5,3.2c1.9,0,3.4-1.4,3.3-3.2c-0.1-1.8-1.6-3.2-3.5-3.2
			S758.8,426,758.8,427.8"/>
				<path fill="#0074BB" d="M876.5,419c0.2,1.8,1.9,3.3,3.7,3.3c1.9,0,3.2-1.4,3-3.1c-0.2-1.8-1.9-3.3-3.7-3.3
			C877.7,415.8,876.3,417.2,876.5,419"/>
				<path fill="#0074BB" d="M865.9,418.8c0.2,1.8,1.9,3.3,3.7,3.3c1.9,0,3.2-1.4,3-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C867,415.6,865.7,417,865.9,418.8"/>
				<path fill="#0074BB" d="M855.2,418.6c0.2,1.8,1.8,3.3,3.7,3.3c1.9,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.7-3.3
			C856.3,415.4,855,416.8,855.2,418.6"/>
				<path fill="#0074BB" d="M844.5,418.4c0.2,1.8,1.8,3.3,3.7,3.3c1.9,0,3.2-1.4,3.1-3.2c-0.2-1.8-1.8-3.3-3.7-3.3
			C845.7,415.2,844.3,416.6,844.5,418.4"/>
				<path fill="#0074BB" d="M833.7,418.2c0.1,1.8,1.8,3.2,3.6,3.3c1.9,0,3.2-1.4,3.1-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C835,415,833.6,416.4,833.7,418.2"/>
				<path fill="#0074BB" d="M823,418.1c0.1,1.8,1.8,3.2,3.6,3.3c1.9,0,3.3-1.4,3.1-3.2c-0.1-1.8-1.8-3.3-3.6-3.3
			C824.3,414.9,822.9,416.3,823,418.1"/>
				<path fill="#0074BB" d="M812.3,418c0.1,1.8,1.7,3.2,3.6,3.3c1.9,0,3.3-1.4,3.1-3.2c-0.1-1.8-1.7-3.2-3.6-3.3
			C813.6,414.7,812.2,416.2,812.3,418"/>
				<path fill="#0074BB" d="M801.6,417.9c0.1,1.8,1.7,3.2,3.6,3.3c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.6-3.3
			C802.9,414.6,801.5,416.1,801.6,417.9"/>
				<path fill="#0074BB" d="M790.8,417.8c0.1,1.8,1.7,3.2,3.5,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.2-3.5-3.3
			C792.2,414.6,790.7,416,790.8,417.8"/>
				<path fill="#0074BB" d="M780.1,417.7c0.1,1.8,1.7,3.2,3.5,3.2c1.9,0,3.3-1.4,3.2-3.2s-1.7-3.2-3.5-3.2S780,416,780.1,417.7" />
				<path fill="#0074BB" d="M769.3,417.7c0.1,1.8,1.6,3.2,3.5,3.2c1.9,0,3.3-1.4,3.3-3.2c-0.1-1.8-1.6-3.2-3.5-3.2
			C770.7,414.5,769.3,415.9,769.3,417.7"/>
				<path fill="#0074BB" d="M758.6,417.7c0,1.8,1.6,3.2,3.5,3.2c1.9,0,3.3-1.4,3.3-3.2c-0.1-1.8-1.6-3.2-3.5-3.2
			C760,414.5,758.5,415.9,758.6,417.7"/>
				<path fill="#0074BB" d="M747.8,417.7c0,1.8,1.6,3.2,3.4,3.2c1.9,0,3.4-1.5,3.3-3.2c0-1.8-1.6-3.2-3.4-3.2
			C749.2,414.5,747.8,415.9,747.8,417.7"/>
				<path fill="#0074BB" d="M885.9,409.2c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C887,406,885.7,407.4,885.9,409.2"/>
				<path fill="#0074BB" d="M875.4,408.9c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.2-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C876.5,405.7,875.1,407.1,875.4,408.9"/>
				<path fill="#0074BB" d="M864.8,408.7c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C865.9,405.5,864.6,406.9,864.8,408.7"/>
				<path fill="#0074BB" d="M854.1,408.5c0.2,1.8,1.8,3.3,3.7,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.7-3.3
			C855.3,405.2,854,406.7,854.1,408.5"/>
				<path fill="#0074BB" d="M843.5,408.3c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.7-3.3
			C844.7,405.1,843.4,406.5,843.5,408.3"/>
				<path fill="#0074BB" d="M832.9,408.1c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C834.1,404.9,832.7,406.3,832.9,408.1"/>
				<path fill="#0074BB" d="M822.3,408c0.1,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C823.5,404.7,822.1,406.2,822.3,408"/>
				<path fill="#0074BB" d="M811.6,407.9c0.1,1.8,1.7,3.3,3.6,3.3c1.9,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.6-3.3
			C812.9,404.6,811.5,406.1,811.6,407.9"/>
				<path fill="#0074BB" d="M800.9,407.8c0.1,1.8,1.7,3.2,3.6,3.3c1.9,0,3.3-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.6-3.3
			C802.2,404.5,800.8,406,800.9,407.8"/>
				<path fill="#0074BB" d="M790.3,407.7c0.1,1.8,1.7,3.2,3.5,3.3c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C791.6,404.5,790.2,405.9,790.3,407.7"/>
				<path fill="#0074BB" d="M779.6,407.6c0.1,1.8,1.6,3.2,3.5,3.3c1.9,0,3.3-1.4,3.2-3.2s-1.7-3.2-3.5-3.3
			C781,404.4,779.5,405.8,779.6,407.6"/>
				<path fill="#0074BB" d="M769,407.6c0.1,1.8,1.6,3.2,3.5,3.2c1.9,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.6-3.2-3.5-3.2
			C770.3,404.4,768.9,405.8,769,407.6"/>
				<path fill="#0074BB" d="M758.3,407.6c0,1.8,1.6,3.2,3.4,3.2c1.9,0,3.3-1.4,3.3-3.2c-0.1-1.8-1.6-3.2-3.5-3.2
			C759.7,404.4,758.2,405.8,758.3,407.6"/>
				<path fill="#0074BB" d="M747.6,407.6c0,1.8,1.6,3.2,3.4,3.2c1.9,0,3.3-1.5,3.3-3.2c0-1.8-1.6-3.2-3.4-3.2
			C749,404.4,747.6,405.8,747.6,407.6"/>
				<path fill="#0074BB" d="M736.9,407.7c0,1.8,1.5,3.2,3.4,3.2c1.9,0,3.3-1.5,3.3-3.3c0-1.8-1.6-3.2-3.4-3.2
			C738.4,404.4,736.9,405.9,736.9,407.7"/>
				<path fill="#0074BB" d="M895.1,399.3c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0.1,3.1-1.4,2.8-3.2c-0.3-1.8-1.9-3.3-3.8-3.3
			C896.1,396.1,894.9,397.5,895.1,399.3"/>
				<path fill="#0074BB" d="M884.6,399c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C885.7,395.8,884.4,397.2,884.6,399"/>
				<path fill="#0074BB" d="M874.1,398.8c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C875.2,395.6,873.9,397,874.1,398.8"/>
				<path fill="#0074BB" d="M863.6,398.5c0.2,1.8,1.8,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C864.7,395.3,863.4,396.7,863.6,398.5"/>
				<path fill="#0074BB" d="M853.1,398.3c0.2,1.8,1.8,3.3,3.7,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.7-3.3
			C854.2,395.1,852.9,396.5,853.1,398.3"/>
				<path fill="#0074BB" d="M842.6,398.1c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C843.7,394.9,842.4,396.4,842.6,398.1"/>
				<path fill="#0074BB" d="M832,398c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C833.2,394.8,831.9,396.2,832,398"/>
				<path fill="#0074BB" d="M821.5,397.8c0.1,1.8,1.7,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C822.7,394.6,821.3,396.1,821.5,397.8"/>
				<path fill="#0074BB" d="M810.9,397.7c0.1,1.8,1.7,3.3,3.6,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.6-3.3
			C812.1,394.5,810.8,395.9,810.9,397.7"/>
				<path fill="#0074BB" d="M800.3,397.6c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C801.6,394.4,800.2,395.8,800.3,397.6"/>
				<path fill="#0074BB" d="M789.8,397.6c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C791.1,394.3,789.7,395.8,789.8,397.6"/>
				<path fill="#0074BB" d="M779.2,397.5c0.1,1.8,1.6,3.3,3.5,3.3c1.8,0,3.3-1.4,3.2-3.2s-1.7-3.3-3.5-3.3
			C780.5,394.3,779.1,395.7,779.2,397.5"/>
				<path fill="#0074BB" d="M768.6,397.5c0.1,1.8,1.6,3.2,3.5,3.3c1.8,0,3.3-1.4,3.2-3.2c-0.1-1.8-1.6-3.3-3.5-3.3
			C769.9,394.2,768.5,395.7,768.6,397.5"/>
				<path fill="#0074BB" d="M758,397.5c0,1.8,1.6,3.2,3.4,3.2c1.8,0,3.3-1.5,3.2-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C759.4,394.2,757.9,395.7,758,397.5"/>
				<path fill="#0074BB" d="M747.4,397.5c0,1.8,1.6,3.2,3.4,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.6-3.2-3.4-3.2
			C748.8,394.2,747.4,395.7,747.4,397.5"/>
				<path fill="#0074BB" d="M914.5,389.8c0.3,1.8,2,3.3,3.8,3.4c1.8,0.1,3-1.3,2.7-3.1c-0.3-1.8-2-3.3-3.8-3.4
			C915.4,386.6,914.2,388,914.5,389.8"/>
				<path fill="#0074BB" d="M904.1,389.5c0.3,1.8,1.9,3.3,3.8,3.4c1.8,0.1,3-1.4,2.8-3.2c-0.3-1.8-2-3.3-3.8-3.4
			C905.1,386.2,903.8,387.7,904.1,389.5"/>
				<path fill="#0074BB" d="M893.7,389.2c0.3,1.8,1.9,3.3,3.7,3.3c1.8,0.1,3.1-1.4,2.8-3.2c-0.3-1.8-1.9-3.3-3.7-3.3
			S893.4,387.4,893.7,389.2"/>
				<path fill="#0074BB" d="M883.3,388.9c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.8-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C884.3,385.7,883.1,387.1,883.3,388.9"/>
				<path fill="#0074BB" d="M872.9,388.6c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C873.9,385.4,872.7,386.8,872.9,388.6"/>
				<path fill="#0074BB" d="M862.5,388.4c0.2,1.8,1.8,3.3,3.7,3.3c1.8,0,3.1-1.4,2.9-3.2s-1.9-3.3-3.7-3.3
			C863.5,385.2,862.3,386.6,862.5,388.4"/>
				<path fill="#0074BB" d="M852,388.2c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C853.1,385,851.8,386.4,852,388.2"/>
				<path fill="#0074BB" d="M841.6,388c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C842.7,384.8,841.4,386.2,841.6,388"/>
				<path fill="#0074BB" d="M831.1,387.8c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C832.3,384.6,831,386,831.1,387.8"/>
				<path fill="#0074BB" d="M820.6,387.7c0.1,1.8,1.7,3.3,3.6,3.3c1.8,0,3.2-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C821.8,384.5,820.5,385.9,820.6,387.7"/>
				<path fill="#0074BB" d="M810.2,387.6c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C811.4,384.3,810,385.8,810.2,387.6"/>
				<path fill="#0074BB" d="M799.7,387.5c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C801,384.2,799.6,385.7,799.7,387.5"/>
				<path fill="#0074BB" d="M789.2,387.4c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C790.5,384.2,789.1,385.6,789.2,387.4"/>
				<path fill="#0074BB" d="M778.7,387.4c0.1,1.8,1.6,3.3,3.5,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.6-3.3-3.5-3.3
			C780,384.1,778.6,385.6,778.7,387.4"/>
				<path fill="#0074BB" d="M768.2,387.3c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.4,3.2-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C769.6,384.1,768.1,385.5,768.2,387.3"/>
				<path fill="#0074BB" d="M757.7,387.3c0,1.8,1.6,3.3,3.4,3.3c1.8,0,3.3-1.5,3.2-3.3c-0.1-1.8-1.6-3.3-3.4-3.3
			C759.1,384.1,757.7,385.5,757.7,387.3"/>
				<path fill="#0074BB" d="M747.2,387.3c0,1.8,1.5,3.3,3.4,3.2c1.8,0,3.3-1.5,3.2-3.3c0-1.8-1.6-3.3-3.4-3.2
			C748.6,384.1,747.2,385.5,747.2,387.3"/>
				<path fill="#0074BB" d="M736.7,387.4c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.2
			C738.1,384.1,736.7,385.6,736.7,387.4"/>
				<path fill="#0074BB" d="M912.8,379.6c0.3,1.8,2,3.3,3.8,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-2-3.3-3.8-3.4
			C913.7,376.4,912.5,377.8,912.8,379.6"/>
				<path fill="#0074BB" d="M902.5,379.3c0.3,1.8,1.9,3.3,3.7,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C903.5,376.1,902.3,377.5,902.5,379.3"/>
				<path fill="#0074BB" d="M892.2,379c0.3,1.8,1.9,3.3,3.7,3.4c1.8,0,3-1.4,2.8-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C893.2,375.8,892,377.2,892.2,379"/>
				<path fill="#0074BB" d="M881.9,378.7c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3-1.4,2.8-3.2c-0.3-1.8-1.9-3.3-3.7-3.3
			C882.9,375.5,881.7,376.9,881.9,378.7"/>
				<path fill="#0074BB" d="M871.6,378.5c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3.1-1.4,2.8-3.2c-0.2-1.8-1.9-3.3-3.7-3.3
			C872.6,375.2,871.4,376.7,871.6,378.5"/>
				<path fill="#0074BB" d="M861.3,378.2c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.9-3.3-3.6-3.3
			C862.3,375,861.1,376.4,861.3,378.2"/>
				<path fill="#0074BB" d="M850.9,378c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C852,374.8,850.7,376.2,850.9,378"/>
				<path fill="#0074BB" d="M840.6,377.8c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C841.7,374.6,840.4,376,840.6,377.8"/>
				<path fill="#0074BB" d="M830.2,377.7c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,3-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			S830,375.9,830.2,377.7"/>
				<path fill="#0074BB" d="M819.8,377.5c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,3-3.2c-0.2-1.8-1.7-3.3-3.5-3.3
			C821,374.3,819.7,375.7,819.8,377.5"/>
				<path fill="#0074BB" d="M809.4,377.4c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.2-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C810.6,374.2,809.3,375.6,809.4,377.4"/>
				<path fill="#0074BB" d="M799,377.3c0.1,1.8,1.7,3.3,3.5,3.3s3.2-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C800.3,374.1,798.9,375.5,799,377.3"/>
				<path fill="#0074BB" d="M788.6,377.2c0.1,1.8,1.6,3.3,3.5,3.3s3.2-1.4,3.1-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C789.9,374,788.5,375.4,788.6,377.2"/>
				<path fill="#0074BB" d="M778.2,377.2c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C779.5,373.9,778.2,375.4,778.2,377.2"/>
				<path fill="#0074BB" d="M767.8,377.2c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.5,3.1-3.3c-0.1-1.8-1.6-3.3-3.4-3.3
			C769.2,373.9,767.8,375.4,767.8,377.2"/>
				<path fill="#0074BB" d="M757.4,377.2c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.5,3.2-3.3c-0.1-1.8-1.6-3.3-3.4-3.3
			C758.8,373.9,757.4,375.4,757.4,377.2"/>
				<path fill="#0074BB" d="M747,377.2c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.3
			C748.4,373.9,747,375.4,747,377.2"/>
				<path fill="#0074BB" d="M736.6,377.2c0,1.8,1.5,3.3,3.3,3.2c1.8,0,3.3-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.2
			C738,373.9,736.6,375.4,736.6,377.2"/>
				<path fill="#0074BB" d="M726.2,377.3c0,1.8,1.5,3.3,3.3,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.2
			C727.6,374,726.2,375.5,726.2,377.3"/>
				<path fill="#0074BB" d="M694.9,377.6c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.2-3.2-3.2
			C696.5,374.3,695,375.8,694.9,377.6"/>
				<path fill="#0074BB" d="M684.5,377.8c-0.1,1.8,1.4,3.2,3.2,3.2c1.8,0,3.3-1.5,3.4-3.3c0-1.8-1.4-3.2-3.2-3.2
			C686.1,374.5,684.6,376,684.5,377.8"/>
				<path fill="#0074BB" d="M663.7,378.2c-0.1,1.8,1.3,3.2,3.1,3.2s3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C665.3,374.9,663.8,376.4,663.7,378.2"/>
				<path fill="#0074BB" d="M911.2,369.4c0.3,1.8,2,3.3,3.7,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C912.1,366.2,910.9,367.6,911.2,369.4"/>
				<path fill="#0074BB" d="M901,369.1c0.3,1.8,1.9,3.3,3.7,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C901.9,365.9,900.7,367.3,901,369.1"/>
				<path fill="#0074BB" d="M890.8,368.8c0.3,1.8,1.9,3.3,3.7,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C891.7,365.6,890.5,367,890.8,368.8"/>
				<path fill="#0074BB" d="M880.5,368.5c0.2,1.8,1.9,3.3,3.7,3.3c1.8,0,3-1.4,2.8-3.2c-0.3-1.8-1.9-3.3-3.7-3.3
			C881.5,365.3,880.3,366.7,880.5,368.5"/>
				<path fill="#0074BB" d="M870.3,368.3c0.2,1.8,1.9,3.3,3.6,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.9-3.3-3.6-3.3
			C871.3,365,870.1,366.5,870.3,368.3"/>
				<path fill="#0074BB" d="M860.1,368c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C861.1,364.8,859.8,366.2,860.1,368"/>
				<path fill="#0074BB" d="M849.8,367.8c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C850.9,364.6,849.6,366,849.8,367.8"/>
				<path fill="#0074BB" d="M839.5,367.6c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C840.6,364.4,839.4,365.8,839.5,367.6"/>
				<path fill="#0074BB" d="M829.3,367.5c0.2,1.8,1.8,3.3,3.5,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C830.4,364.2,829.1,365.7,829.3,367.5"/>
				<path fill="#0074BB" d="M819,367.3c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.7-3.3-3.5-3.3
			C820.1,364.1,818.8,365.5,819,367.3"/>
				<path fill="#0074BB" d="M808.7,367.2c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C809.9,364,808.5,365.4,808.7,367.2"/>
				<path fill="#0074BB" d="M798.4,367.1c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C799.6,363.9,798.3,365.3,798.4,367.1"/>
				<path fill="#0074BB" d="M788.1,367c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.4-3.3
			C789.3,363.8,788,365.2,788.1,367"/>
				<path fill="#0074BB" d="M777.8,367c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.4,3.1-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C779.1,363.7,777.7,365.2,777.8,367"/>
				<path fill="#0074BB" d="M767.4,367c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.2-1.5,3.1-3.3c-0.1-1.8-1.6-3.3-3.4-3.3
			C768.8,363.7,767.4,365.2,767.4,367"/>
				<path fill="#0074BB" d="M757.1,367c0.1,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.1-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C758.5,363.7,757.1,365.2,757.1,367"/>
				<path fill="#0074BB" d="M746.8,367c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.3
			C748.2,363.7,746.8,365.2,746.8,367"/>
				<path fill="#0074BB" d="M736.5,367c0,1.8,1.5,3.3,3.3,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.3
			C737.9,363.8,736.5,365.2,736.5,367"/>
				<path fill="#0074BB" d="M726.1,367.1c0,1.8,1.5,3.3,3.3,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.2
			C727.6,363.8,726.1,365.3,726.1,367.1"/>
				<path fill="#0074BB" d="M715.8,367.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.3-3.2-3.2
			C717.3,363.9,715.8,365.4,715.8,367.2"/>
				<path fill="#0074BB" d="M705.5,367.3c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.2-3.2-3.2
			C707,364,705.5,365.5,705.5,367.3"/>
				<path fill="#0074BB" d="M695.2,367.4c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.2-3.2-3.2
			C696.7,364.1,695.2,365.6,695.2,367.4"/>
				<path fill="#0074BB" d="M684.8,367.6c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.3-1.5,3.3-3.3s-1.4-3.2-3.1-3.2
			C686.4,364.3,684.9,365.8,684.8,367.6"/>
				<path fill="#0074BB" d="M674.5,367.8c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C676.1,364.5,674.6,366,674.5,367.8"/>
				<path fill="#0074BB" d="M664.2,368c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C665.8,364.7,664.3,366.2,664.2,368"/>
				<path fill="#0074BB" d="M653.9,368.2c-0.1,1.8,1.3,3.2,3,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3-3.2
			C655.5,364.9,654,366.4,653.9,368.2"/>
				<path fill="#0074BB" d="M643.6,368.5c-0.1,1.8,1.2,3.2,3,3.2c1.8,0,3.3-1.5,3.5-3.3c0.1-1.8-1.2-3.2-3-3.2
			C645.3,365.2,643.7,366.6,643.6,368.5"/>
				<path fill="#0074BB" d="M929.6,359.9c0.3,1.8,2,3.3,3.8,3.4c1.8,0.1,2.9-1.3,2.5-3.1c-0.3-1.8-2-3.3-3.8-3.4
			C930.4,356.7,929.3,358.1,929.6,359.9"/>
				<path fill="#0074BB" d="M919.6,359.6c0.3,1.8,2,3.3,3.8,3.4c1.8,0.1,2.9-1.3,2.6-3.1c-0.3-1.8-2-3.3-3.8-3.4
			C920.4,356.4,919.2,357.8,919.6,359.6"/>
				<path fill="#0074BB" d="M909.5,359.2c0.3,1.8,2,3.3,3.7,3.4c1.8,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C910.3,356,909.2,357.4,909.5,359.2"/>
				<path fill="#0074BB" d="M899.4,358.9c0.3,1.8,1.9,3.3,3.7,3.4s2.9-1.4,2.6-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C900.3,355.7,899.1,357.1,899.4,358.9"/>
				<path fill="#0074BB" d="M889.3,358.6c0.3,1.8,1.9,3.3,3.7,3.4c1.8,0.1,3-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C890.2,355.4,889,356.8,889.3,358.6"/>
				<path fill="#0074BB" d="M879.1,358.3c0.3,1.8,1.9,3.3,3.6,3.4c1.8,0,3-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C880.1,355.1,878.9,356.5,879.1,358.3"/>
				<path fill="#0074BB" d="M869,358.1c0.2,1.8,1.9,3.3,3.6,3.3c1.8,0,3-1.4,2.7-3.2c-0.2-1.8-1.9-3.3-3.6-3.3
			C870,354.8,868.7,356.3,869,358.1"/>
				<path fill="#0074BB" d="M858.8,357.8c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C859.8,354.6,858.6,356,858.8,357.8"/>
				<path fill="#0074BB" d="M848.7,357.6c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C849.7,354.4,848.5,355.8,848.7,357.6"/>
				<path fill="#0074BB" d="M838.5,357.4c0.2,1.8,1.8,3.3,3.5,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C839.6,354.2,838.3,355.6,838.5,357.4"/>
				<path fill="#0074BB" d="M828.3,357.3c0.2,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C829.4,354,828.1,355.5,828.3,357.3"/>
				<path fill="#0074BB" d="M818.1,357.1c0.2,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.7-3.3-3.5-3.3
			C819.3,353.9,818,355.3,818.1,357.1"/>
				<path fill="#0074BB" d="M807.9,357c0.1,1.8,1.7,3.3,3.5,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.1-1.8-1.7-3.3-3.5-3.3
			C809.1,353.8,807.8,355.2,807.9,357"/>
				<path fill="#0074BB" d="M797.7,356.9c0.1,1.8,1.7,3.3,3.4,3.3c1.8,0,3.1-1.4,3-3.2c-0.1-1.8-1.7-3.3-3.4-3.3
			C798.9,353.7,797.6,355.1,797.7,356.9"/>
				<path fill="#0074BB" d="M787.5,356.9c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.1-1.4,3-3.2s-1.6-3.3-3.4-3.3
			C788.7,353.6,787.4,355,787.5,356.9"/>
				<path fill="#0074BB" d="M777.3,356.8c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.1-1.4,3-3.3c-0.1-1.8-1.6-3.3-3.4-3.3
			S777.2,355,777.3,356.8"/>
				<path fill="#0074BB" d="M767.1,356.8c0.1,1.8,1.6,3.3,3.3,3.3c1.8,0,3.2-1.5,3.1-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C768.4,353.5,767,355,767.1,356.8"/>
				<path fill="#0074BB" d="M756.8,356.8c0.1,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.1-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C758.2,353.5,756.8,355,756.8,356.8"/>
				<path fill="#0074BB" d="M746.6,356.8c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.1-3.3c0-1.8-1.5-3.3-3.3-3.3
			C748,353.5,746.6,355,746.6,356.8"/>
				<path fill="#0074BB" d="M736.4,356.8c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.3-3.3
			C737.8,353.6,736.3,355,736.4,356.8"/>
				<path fill="#0074BB" d="M726.1,356.9c0,1.8,1.4,3.3,3.2,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.5-3.3-3.2-3.2
			C727.6,353.6,726.1,355.1,726.1,356.9"/>
				<path fill="#0074BB" d="M715.9,357c0,1.8,1.4,3.3,3.2,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.3-3.2-3.2
			C717.4,353.7,715.9,355.2,715.9,357"/>
				<path fill="#0074BB" d="M705.7,357.1c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.5,3.3-3.3c0-1.8-1.4-3.3-3.2-3.2
			C707.2,353.8,705.7,355.3,705.7,357.1"/>
				<path fill="#0074BB" d="M695.4,357.2c0,1.8,1.4,3.2,3.1,3.2c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.4-3.2-3.1-3.2
			C697,354,695.5,355.4,695.4,357.2"/>
				<path fill="#0074BB" d="M685.2,357.4c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.3-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C686.8,354.1,685.3,355.6,685.2,357.4"/>
				<path fill="#0074BB" d="M675,357.6c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.3-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C676.6,354.3,675.1,355.8,675,357.6"/>
				<path fill="#0074BB" d="M664.8,357.8c-0.1,1.8,1.3,3.2,3,3.2s3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3-3.2
			C666.4,354.5,664.9,356,664.8,357.8"/>
				<path fill="#0074BB" d="M654.5,358c-0.1,1.8,1.2,3.2,3,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.2-3.2-3-3.2
			C656.2,354.7,654.6,356.2,654.5,358"/>
				<path fill="#0074BB" d="M644.3,358.3c-0.1,1.8,1.2,3.2,3,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.2-3.2-3-3.2
			C646,355,644.4,356.5,644.3,358.3"/>
				<path fill="#0074BB" d="M634.1,358.5c-0.2,1.8,1.2,3.2,2.9,3.2c1.8,0,3.3-1.5,3.5-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			S634.3,356.7,634.1,358.5"/>
				<path fill="#0074BB" d="M623.9,358.8c-0.2,1.8,1.1,3.2,2.9,3.2c1.8-0.1,3.3-1.6,3.5-3.4c0.2-1.8-1.2-3.2-2.9-3.2
			C625.6,355.5,624.1,357,623.9,358.8"/>
				<path fill="#0074BB" d="M573,360.6c-0.2,1.8,1,3.2,2.8,3.1c1.8-0.1,3.4-1.6,3.6-3.4c0.2-1.8-1-3.2-2.8-3.1
			C574.9,357.3,573.3,358.8,573,360.6"/>
				<path fill="#0074BB" d="M532.5,362.5c-0.3,1.8,0.9,3.2,2.6,3.1c1.8-0.1,3.4-1.6,3.7-3.4c0.3-1.8-0.9-3.2-2.6-3.1
			C534.5,359.2,532.8,360.7,532.5,362.5"/>
				<path fill="#0074BB" d="M867.6,347.9c0.2,1.8,1.9,3.3,3.6,3.3c1.8,0,3-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C868.6,344.6,867.4,346.1,867.6,347.9"/>
				<path fill="#0074BB" d="M857.6,347.6c0.2,1.8,1.8,3.3,3.6,3.3c1.8,0,3-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C858.6,344.4,857.3,345.8,857.6,347.6"/>
				<path fill="#0074BB" d="M847.5,347.4c0.2,1.8,1.8,3.3,3.5,3.3c1.8,0,3-1.4,2.8-3.2s-1.8-3.3-3.5-3.3
			C848.5,344.2,847.3,345.6,847.5,347.4"/>
				<path fill="#0074BB" d="M837.4,347.2c0.2,1.8,1.8,3.3,3.5,3.3c1.8,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C838.5,344,837.2,345.4,837.4,347.2"/>
				<path fill="#0074BB" d="M827.3,347.1c0.2,1.8,1.7,3.3,3.5,3.3c1.8,0,3-1.4,2.8-3.2s-1.7-3.3-3.5-3.3
			C828.4,343.8,827.2,345.3,827.3,347.1"/>
				<path fill="#0074BB" d="M817.2,346.9c0.2,1.8,1.7,3.3,3.5,3.3c1.8,0,3-1.4,2.9-3.2c-0.2-1.8-1.7-3.3-3.5-3.3
			C818.4,343.7,817.1,345.1,817.2,346.9"/>
				<path fill="#0074BB" d="M807.1,346.8c0.1,1.8,1.7,3.3,3.4,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C808.3,343.6,807,345,807.1,346.8"/>
				<path fill="#0074BB" d="M797,346.7c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.1-1.4,2.9-3.2c-0.1-1.8-1.7-3.3-3.4-3.3
			C798.2,343.5,796.9,344.9,797,346.7"/>
				<path fill="#0074BB" d="M786.9,346.7c0.1,1.8,1.6,3.3,3.4,3.3c1.8,0,3.1-1.4,3-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C788.1,343.4,786.8,344.8,786.9,346.7"/>
				<path fill="#0074BB" d="M776.8,346.6c0.1,1.8,1.6,3.3,3.3,3.3c1.8,0,3.1-1.4,3-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C778,343.3,776.7,344.8,776.8,346.6"/>
				<path fill="#0074BB" d="M766.7,346.6c0.1,1.8,1.6,3.3,3.3,3.3c1.8,0,3.1-1.5,3-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C767.9,343.3,766.6,344.8,766.7,346.6"/>
				<path fill="#0074BB" d="M756.5,346.6c0.1,1.8,1.5,3.3,3.3,3.3s3.1-1.5,3.1-3.3c-0.1-1.8-1.5-3.3-3.3-3.3
			C757.9,343.3,756.5,344.8,756.5,346.6"/>
				<path fill="#0074BB" d="M746.4,346.6c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.2-1.5,3.1-3.3c0-1.8-1.5-3.3-3.3-3.3
			C747.7,343.3,746.4,344.8,746.4,346.6"/>
				<path fill="#0074BB" d="M736.3,346.6c0,1.8,1.5,3.3,3.2,3.3c1.8,0,3.2-1.5,3.1-3.3c0-1.8-1.5-3.3-3.2-3.3
			C737.6,343.4,736.2,344.8,736.3,346.6"/>
				<path fill="#0074BB" d="M726.1,346.7c0,1.8,1.4,3.3,3.2,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.3-3.2-3.2
			C727.5,343.4,726.1,344.9,726.1,346.7"/>
				<path fill="#0074BB" d="M716,346.8c0,1.8,1.4,3.3,3.2,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.3-3.2-3.2
			C717.4,343.5,716,345,716,346.8"/>
				<path fill="#0074BB" d="M705.8,346.9c0,1.8,1.4,3.2,3.1,3.2c1.8,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.3-3.1-3.2
			C707.3,343.6,705.9,345.1,705.8,346.9"/>
				<path fill="#0074BB" d="M695.7,347c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.2-1.5,3.3-3.3c0-1.8-1.3-3.2-3.1-3.2
			C697.2,343.8,695.8,345.2,695.7,347"/>
				<path fill="#0074BB" d="M685.6,347.2c-0.1,1.8,1.3,3.2,3.1,3.2c1.8,0,3.2-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3.1-3.2
			C687.1,343.9,685.6,345.4,685.6,347.2"/>
				<path fill="#0074BB" d="M675.4,347.4c-0.1,1.8,1.3,3.2,3,3.2c1.8,0,3.3-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3-3.2
			C677,344.1,675.5,345.6,675.4,347.4"/>
				<path fill="#0074BB" d="M665.3,347.6c-0.1,1.8,1.2,3.2,3,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.3-3.2-3-3.2
			C666.9,344.3,665.4,345.8,665.3,347.6"/>
				<path fill="#0074BB" d="M655.2,347.8c-0.1,1.8,1.2,3.2,3,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.2-3.2-3-3.2
			C656.8,344.5,655.3,346,655.2,347.8"/>
				<path fill="#0074BB" d="M645.1,348.1c-0.1,1.8,1.2,3.2,2.9,3.2c1.8,0,3.3-1.5,3.4-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C646.7,344.8,645.2,346.3,645.1,348.1"/>
				<path fill="#0074BB" d="M635,348.3c-0.2,1.8,1.2,3.2,2.9,3.2c1.8,0,3.3-1.5,3.4-3.4c0.1-1.8-1.2-3.2-2.9-3.2
			C636.6,345,635.1,346.5,635,348.3"/>
				<path fill="#0074BB" d="M624.8,348.6c-0.2,1.8,1.1,3.2,2.9,3.2c1.8-0.1,3.3-1.6,3.5-3.4c0.2-1.8-1.1-3.2-2.9-3.2
			C626.6,345.3,625,346.8,624.8,348.6"/>
				<path fill="#0074BB" d="M554.3,351.3c-0.3,1.8,0.9,3.2,2.6,3.1c1.7-0.1,3.4-1.6,3.7-3.4c0.3-1.8-0.9-3.2-2.6-3.1
			C556.3,348,554.6,349.5,554.3,351.3"/>
				<path fill="#0074BB" d="M544.3,351.8c-0.3,1.8,0.9,3.2,2.6,3.1c1.7-0.1,3.4-1.6,3.7-3.4c0.3-1.8-0.9-3.2-2.6-3.1
			C546.3,348.5,544.6,350,544.3,351.8"/>
				<path fill="#0074BB" d="M866.3,337.7c0.2,1.8,1.8,3.3,3.6,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C867.2,334.5,866,335.9,866.3,337.7"/>
				<path fill="#0074BB" d="M856.3,337.4c0.2,1.8,1.8,3.3,3.6,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.6-3.3
			C857.3,334.2,856.1,335.6,856.3,337.4"/>
				<path fill="#0074BB" d="M846.3,337.2c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,3-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C847.3,334,846.1,335.4,846.3,337.2"/>
				<path fill="#0074BB" d="M836.3,337.1c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C837.4,333.8,836.1,335.2,836.3,337.1"/>
				<path fill="#0074BB" d="M826.3,336.9c0.2,1.8,1.7,3.3,3.5,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.5-3.3
			C827.4,333.6,826.2,335.1,826.3,336.9"/>
				<path fill="#0074BB" d="M816.3,336.7c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C817.5,333.5,816.2,334.9,816.3,336.7"/>
				<path fill="#0074BB" d="M806.3,336.6c0.1,1.8,1.7,3.3,3.4,3.3c1.7,0,3-1.4,2.9-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C807.5,333.4,806.2,334.8,806.3,336.6"/>
				<path fill="#0074BB" d="M796.3,336.5c0.1,1.8,1.6,3.3,3.4,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C797.5,333.3,796.2,334.7,796.3,336.5"/>
				<path fill="#0074BB" d="M786.3,336.5c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3.1-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C787.5,333.2,786.2,334.7,786.3,336.5"/>
				<path fill="#0074BB" d="M776.3,336.4c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3.1-1.4,3-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C777.5,333.2,776.2,334.6,776.3,336.4"/>
				<path fill="#0074BB" d="M766.3,336.4c0.1,1.8,1.5,3.3,3.3,3.3c1.7,0,3.1-1.5,3-3.3c-0.1-1.8-1.6-3.3-3.3-3.3
			C767.5,333.1,766.2,334.6,766.3,336.4"/>
				<path fill="#0074BB" d="M756.2,336.4c0.1,1.8,1.5,3.3,3.3,3.3c1.7,0,3.1-1.5,3-3.3c-0.1-1.8-1.5-3.3-3.3-3.3
			C757.5,333.1,756.2,334.6,756.2,336.4"/>
				<path fill="#0074BB" d="M746.2,336.4c0,1.8,1.5,3.3,3.2,3.3c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.5-3.3-3.2-3.3
			C747.5,333.1,746.2,334.6,746.2,336.4"/>
				<path fill="#0074BB" d="M736.1,336.4c0,1.8,1.4,3.3,3.2,3.2c1.7,0,3.1-1.5,3.1-3.3s-1.5-3.3-3.2-3.3
			C737.5,333.2,736.1,334.6,736.1,336.4"/>
				<path fill="#0074BB" d="M726.1,336.5c0,1.8,1.4,3.3,3.2,3.2c1.7,0,3.2-1.5,3.1-3.3c0-1.8-1.4-3.3-3.2-3.2
			C727.5,333.2,726.1,334.7,726.1,336.5"/>
				<path fill="#0074BB" d="M716.1,336.6c0,1.8,1.4,3.3,3.1,3.2c1.7,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.3-3.1-3.2
			C717.5,333.3,716.1,334.8,716.1,336.6"/>
				<path fill="#0074BB" d="M706,336.7c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.2-3.1-3.2
			C707.5,333.4,706.1,334.9,706,336.7"/>
				<path fill="#0074BB" d="M696,336.8c-0.1,1.8,1.3,3.2,3.1,3.2c1.7,0,3.2-1.5,3.2-3.3c0-1.8-1.3-3.2-3.1-3.2
			C697.5,333.6,696,335,696,336.8"/>
				<path fill="#0074BB" d="M686,337c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3-3.2
			C687.5,333.7,686,335.2,686,337"/>
				<path fill="#0074BB" d="M675.9,337.2c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3-3.2
			C677.5,333.9,676,335.4,675.9,337.2"/>
				<path fill="#0074BB" d="M665.9,337.4c-0.1,1.8,1.2,3.2,3,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-3-3.2
			C667.5,334.1,666,335.6,665.9,337.4"/>
				<path fill="#0074BB" d="M655.9,337.6c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.2-1.5,3.4-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C657.5,334.3,656,335.8,655.9,337.6"/>
				<path fill="#0074BB" d="M645.8,337.9c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.3-1.5,3.4-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C647.5,334.6,646,336.1,645.8,337.9"/>
				<path fill="#0074BB" d="M635.8,338.1c-0.2,1.8,1.1,3.2,2.9,3.2c1.7,0,3.3-1.5,3.4-3.4c0.1-1.8-1.1-3.2-2.9-3.2
			C637.5,334.8,636,336.3,635.8,338.1"/>
				<path fill="#0074BB" d="M625.8,338.4c-0.2,1.8,1.1,3.2,2.8,3.2c1.7-0.1,3.3-1.6,3.4-3.4c0.2-1.8-1.1-3.2-2.8-3.2
			C627.5,335.1,626,336.6,625.8,338.4"/>
				<path fill="#0074BB" d="M864.9,327.5c0.2,1.8,1.8,3.3,3.6,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C865.8,324.3,864.6,325.7,864.9,327.5"/>
				<path fill="#0074BB" d="M855,327.3c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C856,324,854.8,325.5,855,327.3"/>
				<path fill="#0074BB" d="M845.1,327.1c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C846.1,323.8,844.9,325.3,845.1,327.1"/>
				<path fill="#0074BB" d="M835.2,326.9c0.2,1.8,1.7,3.3,3.5,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			S835,325.1,835.2,326.9"/>
				<path fill="#0074BB" d="M825.3,326.7c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C826.4,323.5,825.2,324.9,825.3,326.7"/>
				<path fill="#0074BB" d="M815.4,326.6c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C816.5,323.3,815.3,324.8,815.4,326.6"/>
				<path fill="#0074BB" d="M805.5,326.4c0.1,1.8,1.7,3.3,3.4,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C806.7,323.2,805.4,324.6,805.5,326.4"/>
				<path fill="#0074BB" d="M795.6,326.3c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.4-3.3
			C796.8,323.1,795.5,324.5,795.6,326.3"/>
				<path fill="#0074BB" d="M785.7,326.3c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C786.9,323,785.6,324.5,785.7,326.3"/>
				<path fill="#0074BB" d="M775.8,326.2c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			S775.7,324.4,775.8,326.2"/>
				<path fill="#0074BB" d="M765.9,326.2c0.1,1.8,1.5,3.3,3.3,3.3c1.7,0,3.1-1.5,3-3.3c-0.1-1.8-1.5-3.3-3.3-3.3
			C767.1,322.9,765.8,324.4,765.9,326.2"/>
				<path fill="#0074BB" d="M755.9,326.2c0.1,1.8,1.5,3.3,3.2,3.3c1.7,0,3.1-1.5,3-3.3c-0.1-1.8-1.5-3.3-3.2-3.3
			C757.2,322.9,755.9,324.4,755.9,326.2"/>
				<path fill="#0074BB" d="M746,326.2c0,1.8,1.5,3.3,3.2,3.3c1.7,0,3.1-1.5,3-3.3c0-1.8-1.5-3.3-3.2-3.3
			C747.3,322.9,745.9,324.4,746,326.2"/>
				<path fill="#0074BB" d="M736,326.3c0,1.8,1.4,3.3,3.2,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.3-3.2-3.2
			C737.4,323,736,324.5,736,326.3"/>
				<path fill="#0074BB" d="M726.1,326.3c0,1.8,1.4,3.3,3.1,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.3-3.1-3.2
			C727.5,323.1,726.1,324.5,726.1,326.3"/>
				<path fill="#0074BB" d="M716.2,326.4c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.2-3.1-3.2
			C717.6,323.1,716.2,324.6,716.2,326.4"/>
				<path fill="#0074BB" d="M706.2,326.5c0,1.8,1.3,3.2,3.1,3.2c1.7,0,3.2-1.5,3.2-3.3c0-1.8-1.4-3.2-3.1-3.2
			C707.7,323.2,706.3,324.7,706.2,326.5"/>
				<path fill="#0074BB" d="M696.3,326.7c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.2-1.5,3.2-3.3c0-1.8-1.3-3.2-3-3.2
			C697.8,323.4,696.3,324.9,696.3,326.7"/>
				<path fill="#0074BB" d="M686.3,326.8c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.2-1.5,3.2-3.3c0.1-1.8-1.3-3.2-3-3.2
			C687.9,323.5,686.4,325,686.3,326.8"/>
				<path fill="#0074BB" d="M676.4,327c-0.1,1.8,1.2,3.2,3,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.3-3.2-3-3.2
			C678,323.7,676.5,325.2,676.4,327"/>
				<path fill="#0074BB" d="M666.5,327.2c-0.1,1.8,1.2,3.2,2.9,3.2s3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C668.1,323.9,666.6,325.4,666.5,327.2"/>
				<path fill="#0074BB" d="M656.5,327.4c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C658.2,324.1,656.7,325.6,656.5,327.4"/>
				<path fill="#0074BB" d="M646.6,327.7c-0.1,1.8,1.1,3.2,2.9,3.2c1.7,0,3.2-1.5,3.4-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C648.3,324.4,646.8,325.9,646.6,327.7"/>
				<path fill="#0074BB" d="M636.7,328c-0.2,1.8,1.1,3.2,2.8,3.2c1.7,0,3.2-1.5,3.4-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C638.4,324.7,636.9,326.2,636.7,328"/>
				<path fill="#0074BB" d="M626.8,328.3c-0.2,1.8,1.1,3.2,2.8,3.2c1.7-0.1,3.3-1.6,3.4-3.4c0.2-1.8-1.1-3.2-2.8-3.2
			C628.5,325,627,326.5,626.8,328.3"/>
				<path fill="#0074BB" d="M528.1,332.5c-0.3,1.8,0.8,3.2,2.5,3.1c1.7-0.1,3.4-1.6,3.7-3.4c0.3-1.8-0.8-3.2-2.5-3.1
			C530.1,329.1,528.5,330.7,528.1,332.5"/>
				<path fill="#0074BB" d="M518.3,333c-0.4,1.8,0.7,3.2,2.4,3.1c1.7-0.1,3.4-1.6,3.7-3.4c0.4-1.8-0.7-3.2-2.4-3.1
			C520.4,329.7,518.7,331.2,518.3,333"/>
				<path fill="#0074BB" d="M853.7,317.1c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C854.6,313.9,853.5,315.3,853.7,317.1"/>
				<path fill="#0074BB" d="M843.9,316.9c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C844.9,313.7,843.7,315.1,843.9,316.9"/>
				<path fill="#0074BB" d="M834.1,316.7c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.8-3.3-3.4-3.3
			C835.1,313.5,833.9,314.9,834.1,316.7"/>
				<path fill="#0074BB" d="M824.3,316.5c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C825.4,313.3,824.2,314.7,824.3,316.5"/>
				<path fill="#0074BB" d="M814.5,316.4c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C815.6,313.1,814.4,314.6,814.5,316.4"/>
				<path fill="#0074BB" d="M804.7,316.3c0.1,1.8,1.6,3.3,3.4,3.3c1.7,0,3-1.4,2.8-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C805.8,313,804.6,314.5,804.7,316.3"/>
				<path fill="#0074BB" d="M794.9,316.2c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.8-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C796.1,312.9,794.8,314.4,794.9,316.2"/>
				<path fill="#0074BB" d="M785.1,316.1c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C786.3,312.9,785,314.3,785.1,316.1"/>
				<path fill="#0074BB" d="M775.3,316c0.1,1.8,1.5,3.3,3.3,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C776.5,312.8,775.2,314.3,775.3,316"/>
				<path fill="#0074BB" d="M765.4,316c0.1,1.8,1.5,3.3,3.2,3.3c1.7,0,3-1.5,2.9-3.2c-0.1-1.8-1.5-3.3-3.2-3.3
			C766.7,312.8,765.4,314.2,765.4,316"/>
				<path fill="#0074BB" d="M755.6,316c0.1,1.8,1.5,3.3,3.2,3.3c1.7,0,3-1.5,3-3.3c-0.1-1.8-1.5-3.3-3.2-3.3
			C756.9,312.8,755.6,314.2,755.6,316"/>
				<path fill="#0074BB" d="M745.8,316c0,1.8,1.5,3.3,3.2,3.2c1.7,0,3.1-1.5,3-3.3c0-1.8-1.5-3.3-3.2-3.2
			C747.1,312.8,745.7,314.2,745.8,316"/>
				<path fill="#0074BB" d="M735.9,316.1c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.1-1.5,3-3.3c0-1.8-1.4-3.2-3.1-3.2
			C737.3,312.8,735.9,314.3,735.9,316.1"/>
				<path fill="#0074BB" d="M726.1,316.2c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.2-3.1-3.2
			C727.5,312.9,726.1,314.4,726.1,316.2"/>
				<path fill="#0074BB" d="M716.3,316.2c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.2-3.1-3.2
			C717.7,313,716.3,314.4,716.3,316.2"/>
				<path fill="#0074BB" d="M706.4,316.4c0,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.3-3.2-3-3.2
			C707.9,313.1,706.5,314.6,706.4,316.4"/>
				<path fill="#0074BB" d="M696.6,316.5c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.2-3.3c0-1.8-1.3-3.2-3-3.2
			C698.1,313.2,696.6,314.7,696.6,316.5"/>
				<path fill="#0074BB" d="M686.7,316.6c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.3-3.2-3-3.2
			C688.3,313.4,686.8,314.9,686.7,316.6"/>
				<path fill="#0074BB" d="M676.9,316.8c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.2-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C678.4,313.5,677,315,676.9,316.8"/>
				<path fill="#0074BB" d="M667.1,317c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C668.6,313.8,667.2,315.2,667.1,317"/>
				<path fill="#0074BB" d="M657.2,317.3c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C658.8,314,657.4,315.5,657.2,317.3"/>
				<path fill="#0074BB" d="M647.4,317.5c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C649.1,314.2,647.6,315.7,647.4,317.5"/>
				<path fill="#0074BB" d="M637.6,317.8c-0.2,1.8,1.1,3.2,2.8,3.2c1.7,0,3.2-1.5,3.4-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C639.3,314.5,637.8,316,637.6,317.8"/>
				<path fill="#0074BB" d="M627.8,318.1c-0.2,1.8,1.1,3.2,2.8,3.2c1.7-0.1,3.2-1.6,3.4-3.3c0.2-1.8-1.1-3.2-2.8-3.2
			C629.5,314.8,628,316.3,627.8,318.1"/>
				<path fill="#0074BB" d="M842.7,306.7c0.2,1.8,1.8,3.3,3.4,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.2-1.8-1.8-3.3-3.4-3.3
			C843.6,303.5,842.5,304.9,842.7,306.7"/>
				<path fill="#0074BB" d="M833,306.5c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C834,303.3,832.8,304.7,833,306.5"/>
				<path fill="#0074BB" d="M823.3,306.4c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C824.3,303.1,823.1,304.6,823.3,306.4"/>
				<path fill="#0074BB" d="M813.6,306.2c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C814.7,303,813.5,304.4,813.6,306.2"/>
				<path fill="#0074BB" d="M803.9,306.1c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.2-1.8-1.6-3.3-3.3-3.3
			C805,302.9,803.8,304.3,803.9,306.1"/>
				<path fill="#0074BB" d="M794.2,306c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C795.3,302.8,794.1,304.2,794.2,306"/>
				<path fill="#0074BB" d="M784.5,305.9c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,3-1.4,2.8-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C785.6,302.7,784.4,304.2,784.5,305.9"/>
				<path fill="#0074BB" d="M774.8,305.9c0.1,1.8,1.5,3.3,3.2,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.5-3.3-3.2-3.3
			C776,302.7,774.7,304.1,774.8,305.9"/>
				<path fill="#0074BB" d="M765,305.9c0.1,1.8,1.5,3.3,3.2,3.3c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.5-3.3-3.2-3.3
			C766.3,302.6,765,304.1,765,305.9"/>
				<path fill="#0074BB" d="M755.3,305.9c0.1,1.8,1.5,3.2,3.2,3.2c1.7,0,3-1.5,2.9-3.2c-0.1-1.8-1.5-3.2-3.2-3.2
			C756.6,302.6,755.2,304.1,755.3,305.9"/>
				<path fill="#0074BB" d="M745.6,305.9c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.5-3.2-3.1-3.2
			C746.9,302.6,745.5,304.1,745.6,305.9"/>
				<path fill="#0074BB" d="M735.8,305.9c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.4-3.2-3.1-3.2
			C737.2,302.7,735.8,304.1,735.8,305.9"/>
				<path fill="#0074BB" d="M726.1,306c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.1-1.5,3-3.3c0-1.8-1.4-3.2-3.1-3.2
			C727.5,302.7,726.1,304.2,726.1,306"/>
				<path fill="#0074BB" d="M716.4,306.1c0,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.4-3.2-3-3.2
			C717.8,302.8,716.4,304.3,716.4,306.1"/>
				<path fill="#0074BB" d="M706.6,306.2c0,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.3-3.2-3-3.2
			C708.1,302.9,706.7,304.4,706.6,306.2"/>
				<path fill="#0074BB" d="M696.9,306.3c-0.1,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.3-3.2-3-3.2
			C698.3,303.1,696.9,304.5,696.9,306.3"/>
				<path fill="#0074BB" d="M687.1,306.5c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.3-3.2-2.9-3.2
			C688.6,303.2,687.2,304.7,687.1,306.5"/>
				<path fill="#0074BB" d="M677.4,306.7c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C678.9,303.4,677.5,304.9,677.4,306.7"/>
				<path fill="#0074BB" d="M667.7,306.9c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C669.2,303.6,667.8,305.1,667.7,306.9"/>
				<path fill="#0074BB" d="M658,307.1c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.2-1.5,3.3-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C659.6,303.8,658.1,305.3,658,307.1"/>
				<path fill="#0074BB" d="M648.2,307.4c-0.1,1.8,1.1,3.2,2.8,3.2s3.2-1.5,3.3-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C649.9,304.1,648.4,305.6,648.2,307.4"/>
				<path fill="#0074BB" d="M638.5,307.6c-0.2,1.8,1.1,3.2,2.8,3.2c1.7,0,3.2-1.5,3.3-3.3c0.2-1.8-1.1-3.2-2.8-3.2
			C640.2,304.3,638.7,305.8,638.5,307.6"/>
				<path fill="#0074BB" d="M628.8,307.9c-0.2,1.8,1,3.2,2.7,3.2c1.7-0.1,3.2-1.6,3.4-3.3c0.2-1.8-1.1-3.2-2.7-3.2
			C630.5,304.6,629,306.1,628.8,307.9"/>
				<path fill="#0074BB" d="M570.7,310.2c-0.3,1.8,0.8,3.2,2.5,3.1c1.7-0.1,3.3-1.6,3.5-3.4s-0.9-3.2-2.5-3.1
			C572.6,306.9,571,308.4,570.7,310.2"/>
				<path fill="#0074BB" d="M561,310.6c-0.3,1.8,0.8,3.2,2.5,3.1c1.7-0.1,3.3-1.6,3.6-3.4c0.3-1.8-0.8-3.2-2.5-3.1
			C562.9,307.3,561.3,308.9,561,310.6"/>
				<path fill="#0074BB" d="M541.8,311.6c-0.3,1.8,0.7,3.2,2.4,3.1c1.7-0.1,3.3-1.6,3.6-3.4c0.3-1.8-0.8-3.2-2.4-3.1
			C543.7,308.3,542.1,309.8,541.8,311.6"/>
				<path fill="#0074BB" d="M532.1,312.1c-0.4,1.8,0.7,3.2,2.4,3.1s3.3-1.6,3.7-3.4c0.3-1.8-0.7-3.2-2.4-3.1
			C534.1,308.8,532.5,310.4,532.1,312.1"/>
				<path fill="#0074BB" d="M831.9,296.4c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C832.8,293.2,831.7,294.6,831.9,296.4"/>
				<path fill="#0074BB" d="M822.3,296.2c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C823.3,293,822.1,294.5,822.3,296.2"/>
				<path fill="#0074BB" d="M812.7,296.1c0.2,1.8,1.7,3.3,3.3,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.7-3.3-3.3-3.3
			C813.7,292.9,812.5,294.3,812.7,296.1"/>
				<path fill="#0074BB" d="M803.1,296c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.6-3.3-3.3-3.3
			C804.2,292.8,802.9,294.2,803.1,296"/>
				<path fill="#0074BB" d="M793.5,295.9c0.1,1.8,1.6,3.3,3.3,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.6-3.3-3.3-3.3
			C794.6,292.7,793.3,294.1,793.5,295.9"/>
				<path fill="#0074BB" d="M783.9,295.8c0.1,1.8,1.6,3.3,3.2,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.6-3.2-3.2-3.3
			C785,292.6,783.7,294,783.9,295.8"/>
				<path fill="#0074BB" d="M774.2,295.8c0.1,1.8,1.5,3.2,3.2,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.5-3.2-3.2-3.3
			C775.4,292.5,774.1,294,774.2,295.8"/>
				<path fill="#0074BB" d="M764.6,295.7c0.1,1.8,1.5,3.2,3.2,3.2c1.7,0,3-1.4,2.9-3.2c-0.1-1.8-1.5-3.2-3.2-3.2
			C765.8,292.5,764.5,294,764.6,295.7"/>
				<path fill="#0074BB" d="M755,295.7c0.1,1.8,1.5,3.2,3.1,3.2c1.7,0,3-1.5,2.9-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			C756.2,292.5,754.9,294,755,295.7"/>
				<path fill="#0074BB" d="M745.4,295.8c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3-1.5,2.9-3.2c0-1.8-1.4-3.2-3.1-3.2
			C746.6,292.5,745.3,294,745.4,295.8"/>
				<path fill="#0074BB" d="M735.7,295.8c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.4-3.2-3.1-3.2
			C737,292.6,735.7,294,735.7,295.8"/>
				<path fill="#0074BB" d="M726.1,295.9c0,1.8,1.4,3.2,3,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.4-3.2-3-3.2
			C727.4,292.6,726.1,294.1,726.1,295.9"/>
				<path fill="#0074BB" d="M716.5,296c0,1.8,1.3,3.2,3,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.3-3.2-3-3.2C717.8,292.7,716.5,294.2,716.5,296
			"/>
				<path fill="#0074BB" d="M706.8,296.1c0,1.8,1.3,3.2,3,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.3-3.2-3-3.2
			C708.2,292.8,706.9,294.3,706.8,296.1"/>
				<path fill="#0074BB" d="M697.2,296.2c-0.1,1.8,1.3,3.2,2.9,3.2c1.7,0,3.1-1.5,3.1-3.3c0-1.8-1.3-3.2-2.9-3.2
			C698.6,292.9,697.3,294.4,697.2,296.2"/>
				<path fill="#0074BB" d="M687.6,296.4c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C689,293.1,687.6,294.6,687.6,296.4"/>
				<path fill="#0074BB" d="M677.9,296.5c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.2-3.3s-1.2-3.2-2.9-3.2
			C679.5,293.3,678,294.8,677.9,296.5"/>
				<path fill="#0074BB" d="M668.3,296.8c-0.1,1.8,1.2,3.2,2.8,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C669.9,293.5,668.4,295,668.3,296.8"/>
				<path fill="#0074BB" d="M658.7,297c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C660.3,293.7,658.8,295.2,658.7,297"/>
				<path fill="#0074BB" d="M649.1,297.2c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.1-1.5,3.3-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			C650.7,294,649.2,295.4,649.1,297.2"/>
				<path fill="#0074BB" d="M639.4,297.5c-0.2,1.8,1.1,3.2,2.7,3.2c1.7,0,3.2-1.5,3.3-3.3c0.2-1.8-1.1-3.2-2.7-3.2
			C641.1,294.2,639.6,295.7,639.4,297.5"/>
				<path fill="#0074BB" d="M821.2,286.1c0.2,1.8,1.7,3.3,3.3,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.7-3.3-3.3-3.3
			C822.2,282.9,821,284.4,821.2,286.1"/>
				<path fill="#0074BB" d="M811.7,286c0.2,1.8,1.6,3.2,3.3,3.3c1.7,0,2.8-1.4,2.7-3.2c-0.2-1.8-1.7-3.2-3.3-3.3
			C812.8,282.8,811.6,284.2,811.7,286"/>
				<path fill="#0074BB" d="M802.2,285.9c0.1,1.8,1.6,3.2,3.3,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.2-1.8-1.6-3.2-3.3-3.3
			C803.3,282.7,802.1,284.1,802.2,285.9"/>
				<path fill="#0074BB" d="M792.7,285.8c0.1,1.8,1.6,3.2,3.2,3.3c1.7,0,2.9-1.4,2.7-3.2c-0.1-1.8-1.6-3.2-3.2-3.3
			C793.8,282.6,792.6,284,792.7,285.8"/>
				<path fill="#0074BB" d="M783.2,285.7c0.1,1.8,1.5,3.2,3.2,3.3c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.6-3.2-3.2-3.2
			C784.4,282.5,783.1,283.9,783.2,285.7"/>
				<path fill="#0074BB" d="M773.7,285.7c0.1,1.8,1.5,3.2,3.2,3.2c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.5-3.2-3.2-3.2
			C774.9,282.4,773.6,283.9,773.7,285.7"/>
				<path fill="#0074BB" d="M764.2,285.6c0.1,1.8,1.5,3.2,3.1,3.2c1.7,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			C765.4,282.4,764.1,283.9,764.2,285.6"/>
				<path fill="#0074BB" d="M754.7,285.6c0.1,1.8,1.4,3.2,3.1,3.2c1.7,0,2.9-1.4,2.9-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			C755.9,282.4,754.6,283.9,754.7,285.6"/>
				<path fill="#0074BB" d="M745.2,285.7c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3-1.5,2.9-3.2c0-1.8-1.4-3.2-3.1-3.2
			C746.4,282.4,745.1,283.9,745.2,285.7"/>
				<path fill="#0074BB" d="M735.6,285.7c0,1.8,1.4,3.2,3,3.2c1.7,0,3-1.5,2.9-3.2c0-1.8-1.4-3.2-3-3.2
			C736.9,282.5,735.6,283.9,735.6,285.7"/>
				<path fill="#0074BB" d="M726.1,285.8c0,1.8,1.3,3.2,3,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.4-3.2-3-3.2
			C727.4,282.5,726.1,284,726.1,285.8"/>
				<path fill="#0074BB" d="M716.6,285.9c0,1.8,1.3,3.2,3,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.3-3.2-3-3.2S716.6,284.1,716.6,285.9" />
				<path fill="#0074BB" d="M707,286c0,1.8,1.3,3.2,2.9,3.2c1.7,0,3-1.5,3-3.3c0-1.8-1.3-3.2-2.9-3.2C708.4,282.7,707.1,284.2,707,286
			"/>
				<path fill="#0074BB" d="M697.5,286.1c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3-1.5,3.1-3.3c0-1.8-1.3-3.2-2.9-3.2
			C699,282.9,697.6,284.3,697.5,286.1"/>
				<path fill="#0074BB" d="M688,286.3c-0.1,1.8,1.2,3.2,2.9,3.2c1.7,0,3.1-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.9-3.2
			C689.5,283,688.1,284.5,688,286.3"/>
				<path fill="#0074BB" d="M678.5,286.5c-0.1,1.8,1.2,3.2,2.8,3.2s3.1-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C680,283.2,678.6,284.7,678.5,286.5"/>
				<path fill="#0074BB" d="M668.9,286.7c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C670.5,283.4,669.1,284.9,668.9,286.7"/>
				<path fill="#0074BB" d="M659.4,286.9c-0.1,1.8,1.1,3.2,2.8,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.8-3.2
			S659.6,285.1,659.4,286.9"/>
				<path fill="#0074BB" d="M649.9,287.1c-0.2,1.8,1.1,3.2,2.7,3.2c1.7,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C651.5,283.9,650.1,285.4,649.9,287.1"/>
				<path fill="#0074BB" d="M820.2,276.1c0.2,1.8,1.7,3.2,3.3,3.3c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.7-3.2-3.3-3.3
			C821.2,272.9,820,274.3,820.2,276.1"/>
				<path fill="#0074BB" d="M810.8,275.9c0.2,1.8,1.6,3.2,3.3,3.3c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.6-3.2-3.3-3.3
			C811.8,272.7,810.6,274.2,810.8,275.9"/>
				<path fill="#0074BB" d="M801.4,275.8c0.1,1.8,1.6,3.2,3.2,3.3c1.6,0,2.8-1.4,2.7-3.2c-0.2-1.8-1.6-3.2-3.2-3.3
			C802.4,272.6,801.3,274,801.4,275.8"/>
				<path fill="#0074BB" d="M792,275.7c0.1,1.8,1.6,3.2,3.2,3.2c1.6,0,2.8-1.4,2.7-3.2c-0.1-1.8-1.6-3.2-3.2-3.2S791.9,274,792,275.7"
				/>
				<path fill="#0074BB" d="M782.6,275.7c0.1,1.8,1.5,3.2,3.2,3.2c1.6,0,2.9-1.4,2.7-3.2c-0.1-1.8-1.5-3.2-3.2-3.2
			C783.7,272.5,782.5,273.9,782.6,275.7"/>
				<path fill="#0074BB" d="M773.2,275.6c0.1,1.8,1.5,3.2,3.1,3.2c1.6,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			S773.1,273.8,773.2,275.6"/>
				<path fill="#0074BB" d="M763.8,275.6c0.1,1.8,1.5,3.2,3.1,3.2c1.6,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			C765,272.4,763.7,273.8,763.8,275.6"/>
				<path fill="#0074BB" d="M754.4,275.6c0.1,1.8,1.4,3.2,3.1,3.2c1.6,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.4-3.2-3.1-3.2
			C755.6,272.4,754.3,273.8,754.4,275.6"/>
				<path fill="#0074BB" d="M744.9,275.6c0,1.8,1.4,3.2,3,3.2c1.6,0,2.9-1.4,2.9-3.2c0-1.8-1.4-3.2-3-3.2
			C746.2,272.4,744.9,273.8,744.9,275.6"/>
				<path fill="#0074BB" d="M735.5,275.6c0,1.8,1.4,3.2,3,3.2c1.6,0,2.9-1.5,2.9-3.2c0-1.8-1.4-3.2-3-3.2
			C736.8,272.4,735.5,273.9,735.5,275.6"/>
				<path fill="#0074BB" d="M726.1,275.7c0,1.8,1.3,3.2,3,3.2c1.6,0,3-1.5,3-3.2c0-1.8-1.3-3.2-3-3.2
			C727.4,272.5,726.1,273.9,726.1,275.7"/>
				<path fill="#0074BB" d="M716.7,275.8c0,1.8,1.3,3.2,2.9,3.2c1.6,0,3-1.5,3-3.2c0-1.8-1.3-3.2-2.9-3.2
			C718,272.6,716.7,274,716.7,275.8"/>
				<path fill="#0074BB" d="M707.3,275.9c0,1.8,1.3,3.2,2.9,3.2c1.6,0,3-1.5,3-3.3c0-1.8-1.3-3.2-2.9-3.2
			C708.7,272.7,707.3,274.1,707.3,275.9"/>
				<path fill="#0074BB" d="M697.8,276c-0.1,1.8,1.2,3.2,2.9,3.2s3-1.5,3.1-3.3c0-1.8-1.2-3.2-2.9-3.2
			C699.3,272.8,697.9,274.3,697.8,276"/>
				<path fill="#0074BB" d="M688.4,276.2c-0.1,1.8,1.2,3.2,2.8,3.2c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C689.9,273,688.5,274.4,688.4,276.2"/>
				<path fill="#0074BB" d="M679,276.4c-0.1,1.8,1.2,3.2,2.8,3.2c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.8-3.2
			C680.5,273.1,679.1,274.6,679,276.4"/>
				<path fill="#0074BB" d="M669.6,276.6c-0.1,1.8,1.1,3.2,2.8,3.2c1.6,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.8-3.1
			C671.1,273.3,669.7,274.8,669.6,276.6"/>
				<path fill="#0074BB" d="M660.2,276.8c-0.1,1.8,1.1,3.2,2.7,3.1c1.6,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C661.7,273.6,660.3,275,660.2,276.8"/>
				<path fill="#0074BB" d="M650.8,277.1c-0.2,1.8,1.1,3.2,2.7,3.1c1.6,0,3.1-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C652.4,273.8,650.9,275.3,650.8,277.1"/>
				<path fill="#0074BB" d="M809.8,265.9c0.2,1.8,1.6,3.2,3.2,3.2c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.6-3.2-3.2-3.2
			C810.8,262.7,809.7,264.2,809.8,265.9"/>
				<path fill="#0074BB" d="M800.6,265.8c0.1,1.8,1.6,3.2,3.2,3.2c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.6-3.2-3.2-3.2
			S800.4,264,800.6,265.8"/>
				<path fill="#0074BB" d="M791.3,265.7c0.1,1.8,1.5,3.2,3.2,3.2c1.6,0,2.8-1.4,2.7-3.2c-0.1-1.8-1.6-3.2-3.2-3.2
			C792.3,262.5,791.1,263.9,791.3,265.7"/>
				<path fill="#0074BB" d="M782,265.6c0.1,1.8,1.5,3.2,3.1,3.2c1.6,0,2.8-1.4,2.7-3.2c-0.1-1.8-1.5-3.2-3.1-3.2
			C783.1,262.4,781.9,263.9,782,265.6"/>
				<path fill="#0074BB" d="M754.1,265.6c0.1,1.8,1.4,3.2,3,3.2c1.6,0,2.9-1.4,2.8-3.2c-0.1-1.8-1.4-3.2-3-3.2
			C755.3,262.4,754,263.8,754.1,265.6"/>
				<path fill="#0074BB" d="M744.7,265.6c0,1.8,1.4,3.2,3,3.2c1.6,0,2.9-1.4,2.8-3.2c0-1.8-1.4-3.2-3-3.2
			C746,262.4,744.7,263.8,744.7,265.6"/>
				<path fill="#0074BB" d="M735.4,265.6c0,1.8,1.3,3.2,3,3.2c1.6,0,2.9-1.4,2.9-3.2c0-1.8-1.4-3.2-3-3.2
			C736.7,262.4,735.4,263.9,735.4,265.6"/>
				<path fill="#0074BB" d="M726.1,265.7c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.9-1.5,2.9-3.2s-1.3-3.2-2.9-3.2
			C727.4,262.5,726.1,263.9,726.1,265.7"/>
				<path fill="#0074BB" d="M716.8,265.8c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.9-1.5,3-3.2c0-1.8-1.3-3.2-2.9-3.2
			C718.1,262.6,716.8,264,716.8,265.8"/>
				<path fill="#0074BB" d="M707.5,265.9c0,1.8,1.2,3.2,2.9,3.2c1.6,0,3-1.5,3-3.2c0-1.8-1.3-3.2-2.9-3.2
			C708.9,262.7,707.5,264.1,707.5,265.9"/>
				<path fill="#0074BB" d="M698.2,266c-0.1,1.8,1.2,3.2,2.8,3.2c1.6,0,3-1.5,3-3.2c0-1.8-1.2-3.2-2.8-3.2
			C699.6,262.8,698.2,264.3,698.2,266"/>
				<path fill="#0074BB" d="M688.9,266.2c-0.1,1.8,1.2,3.2,2.8,3.2c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.8-3.1
			C690.3,263,688.9,264.4,688.9,266.2"/>
				<path fill="#0074BB" d="M679.5,266.4c-0.1,1.8,1.1,3.2,2.8,3.1c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.2-3.2-2.8-3.1
			S679.6,264.6,679.5,266.4"/>
				<path fill="#0074BB" d="M670.2,266.6c-0.1,1.8,1.1,3.2,2.7,3.1c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C671.7,263.3,670.3,264.8,670.2,266.6"/>
				<path fill="#0074BB" d="M660.9,266.8c-0.1,1.8,1.1,3.2,2.7,3.1c1.6,0,3-1.5,3.2-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C662.5,263.6,661.1,265,660.9,266.8"/>
				<path fill="#0074BB" d="M735.3,255.7c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.9-1.4,2.8-3.2c0-1.8-1.3-3.2-2.9-3.2
			C736.6,252.5,735.3,253.9,735.3,255.7"/>
				<path fill="#0074BB" d="M726.1,255.7c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.9-1.5,2.9-3.2c0-1.8-1.3-3.2-2.9-3.2
			C727.4,252.5,726.1,254,726.1,255.7"/>
				<path fill="#0074BB" d="M716.9,255.8c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.9-1.5,2.9-3.2c0-1.8-1.3-3.2-2.9-3.2
			C718.2,252.6,716.9,254.1,716.9,255.8"/>
				<path fill="#0074BB" d="M707.7,255.9c0,1.8,1.2,3.2,2.8,3.2c1.6,0,2.9-1.5,3-3.2c0-1.8-1.2-3.2-2.8-3.1
			C709.1,252.7,707.8,254.2,707.7,255.9"/>
				<path fill="#0074BB" d="M698.5,256.1c-0.1,1.8,1.2,3.2,2.8,3.1c1.6,0,2.9-1.5,3-3.2c0-1.8-1.2-3.2-2.8-3.1
			S698.6,254.3,698.5,256.1"/>
				<path fill="#0074BB" d="M689.3,256.2c-0.1,1.8,1.2,3.2,2.8,3.1c1.6,0,3-1.5,3-3.2c0.1-1.8-1.2-3.2-2.8-3.1
			C690.7,253,689.4,254.5,689.3,256.2"/>
				<path fill="#0074BB" d="M680.1,256.4c-0.1,1.8,1.1,3.2,2.7,3.1c1.6,0,3-1.5,3.1-3.2c0.1-1.8-1.1-3.2-2.7-3.1
			C681.6,253.2,680.2,254.6,680.1,256.4"/>
				<path fill="#0074BB" d="M670.9,256.6c-0.1,1.8,1.1,3.2,2.7,3.1c1.6,0,3-1.5,3.1-3.3c0.1-1.8-1.1-3.2-2.7-3.1
			C672.4,253.4,671,254.8,670.9,256.6"/>
				<path fill="#0074BB" d="M817,246.2c0.2,1.8,1.6,3.2,3.2,3.2c1.6,0,2.7-1.4,2.5-3.1c-0.2-1.8-1.6-3.2-3.2-3.2
			C817.9,243,816.8,244.4,817,246.2"/>
				<path fill="#0074BB" d="M798.8,245.9c0.2,1.8,1.6,3.2,3.1,3.2c1.6,0,2.7-1.4,2.6-3.1c-0.2-1.8-1.6-3.2-3.1-3.2
			C799.8,242.8,798.7,244.2,798.8,245.9"/>
				<path fill="#0074BB" d="M735.2,245.7c0,1.8,1.3,3.2,2.9,3.2c1.6,0,2.8-1.4,2.8-3.2c0-1.7-1.3-3.2-2.9-3.2
			C736.5,242.6,735.2,244,735.2,245.7"/>
				<path fill="#0074BB" d="M726.1,245.8c0,1.8,1.3,3.2,2.9,3.1c1.6,0,2.9-1.4,2.9-3.2c0-1.8-1.3-3.2-2.9-3.1
			C727.4,242.6,726.1,244.1,726.1,245.8"/>
				<path fill="#0074BB" d="M717,245.9c0,1.8,1.2,3.2,2.8,3.1s2.9-1.4,2.9-3.2c0-1.7-1.3-3.2-2.8-3.1
			C718.4,242.7,717.1,244.1,717,245.9"/>
				<path fill="#0074BB" d="M707.9,246c0,1.8,1.2,3.2,2.8,3.1c1.6,0,2.9-1.5,2.9-3.2c0-1.7-1.2-3.1-2.8-3.1
			C709.3,242.8,708,244.3,707.9,246"/>
				<path fill="#0074BB" d="M698.8,246.1c-0.1,1.8,1.2,3.2,2.8,3.1c1.6,0,2.9-1.5,3-3.2c0-1.8-1.2-3.1-2.8-3.1
			C700.2,243,698.9,244.4,698.8,246.1"/>
			</g>
			<g className={classNames("g-middle_east", { 'g-map-active': highlight === 'middle_east' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('middle_east')}>
				<path fill="#662D91" d="M897.7,348.7c0.3,1.8,1.9,3.3,3.7,3.4c1.7,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-2-3.3-3.7-3.4
			S897.4,346.9,897.7,348.7"/>
				<path fill="#662D91" d="M887.7,348.4c0.3,1.8,1.9,3.3,3.7,3.4c1.7,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C888.6,345.2,887.4,346.6,887.7,348.4"/>
				<path fill="#662D91" d="M877.7,348.1c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0,2.9-1.4,2.7-3.2c-0.3-1.8-1.9-3.3-3.6-3.4
			C878.6,344.9,877.4,346.3,877.7,348.1"/>
				<path fill="#662D91" d="M925.8,339.5c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.5-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C926.5,336.3,925.4,337.7,925.8,339.5"/>
				<path fill="#662D91" d="M915.9,339.2c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.5-3.1c-0.3-1.8-2-3.3-3.7-3.4
			C916.7,336,915.6,337.4,915.9,339.2"/>
				<path fill="#662D91" d="M906,338.8c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.9-1.4,2.5-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C906.8,335.6,905.7,337,906,338.8"/>
				<path fill="#662D91" d="M896.1,338.5c0.3,1.8,1.9,3.3,3.7,3.4c1.7,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.7-3.4
			C896.9,335.3,895.8,336.7,896.1,338.5"/>
				<path fill="#662D91" d="M886.1,338.2c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.4
			C887,335,885.9,336.4,886.1,338.2"/>
				<path fill="#662D91" d="M876.2,337.9c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C877.1,334.7,875.9,336.1,876.2,337.9"/>
				<path fill="#662D91" d="M943.3,330.2c0.4,1.8,2.1,3.3,3.8,3.4c1.7,0.1,2.7-1.3,2.4-3.1c-0.4-1.8-2.1-3.3-3.8-3.4
			C944,327,942.9,328.4,943.3,330.2"/>
				<path fill="#662D91" d="M933.6,329.8c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.4-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C934.3,326.5,933.2,327.9,933.6,329.8"/>
				<path fill="#662D91" d="M923.8,329.4c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.4-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C924.5,326.2,923.4,327.6,923.8,329.4"/>
				<path fill="#662D91" d="M914,329c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.5-3.1c-0.3-1.8-2-3.3-3.7-3.4
			C914.8,325.8,913.7,327.2,914,329"/>
				<path fill="#662D91" d="M904.2,328.6c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-2-3.3-3.7-3.4
			C905,325.4,903.9,326.8,904.2,328.6"/>
				<path fill="#662D91" d="M894.4,328.3c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.6-3.4
			C895.2,325.1,894.1,326.5,894.4,328.3"/>
				<path fill="#662D91" d="M884.6,328c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.4
			C885.4,324.8,884.3,326.2,884.6,328"/>
				<path fill="#662D91" d="M874.7,327.7c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C875.6,324.5,874.5,325.9,874.7,327.7"/>
				<path fill="#662D91" d="M950.8,320.4c0.4,1.8,2.1,3.3,3.8,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2.1-3.3-3.8-3.4
			C951.4,317.2,950.4,318.6,950.8,320.4"/>
				<path fill="#662D91" d="M941.1,320c0.4,1.8,2.1,3.3,3.7,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C941.8,316.8,940.7,318.2,941.1,320"/>
				<path fill="#662D91" d="M931.5,319.6c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C932.1,316.4,931.1,317.8,931.5,319.6"/>
				<path fill="#662D91" d="M921.8,319.2c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.4-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C922.5,316,921.4,317.4,921.8,319.2"/>
				<path fill="#662D91" d="M912.1,318.8c0.3,1.8,2,3.3,3.7,3.4c1.7,0.1,2.8-1.3,2.4-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C912.8,315.6,911.8,317,912.1,318.8"/>
				<path fill="#662D91" d="M902.4,318.5c0.3,1.8,2,3.3,3.6,3.4c1.7,0.1,2.8-1.4,2.5-3.1c-0.3-1.8-2-3.3-3.6-3.4
			C903.2,315.3,902.1,316.7,902.4,318.5"/>
				<path fill="#662D91" d="M892.7,318.2c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.6-3.4
			C893.5,314.9,892.4,316.4,892.7,318.2"/>
				<path fill="#662D91" d="M883,317.9c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C883.8,314.6,882.7,316.1,883,317.9"/>
				<path fill="#662D91" d="M873.2,317.6c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C874.1,314.4,872.9,315.8,873.2,317.6"/>
				<path fill="#662D91" d="M863.5,317.3c0.3,1.8,1.8,3.3,3.5,3.3c1.7,0,2.9-1.4,2.6-3.2c-0.3-1.8-1.8-3.3-3.5-3.3
			C864.4,314.1,863.2,315.5,863.5,317.3"/>
				<path fill="#662D91" d="M948.4,310.3c0.4,1.8,2.1,3.3,3.8,3.4c1.7,0.1,2.7-1.3,2.2-3.1s-2.1-3.3-3.8-3.4
			C949,307.1,948,308.5,948.4,310.3"/>
				<path fill="#662D91" d="M938.9,309.8c0.4,1.8,2.1,3.3,3.7,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C939.5,306.6,938.5,308,938.9,309.8"/>
				<path fill="#662D91" d="M929.3,309.4c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C930,306.2,929,307.6,929.3,309.4"/>
				<path fill="#662D91" d="M919.8,309c0.4,1.8,2,3.3,3.7,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C920.5,305.8,919.4,307.2,919.8,309"/>
				<path fill="#662D91" d="M910.2,308.7c0.3,1.8,2,3.3,3.6,3.4s2.7-1.3,2.4-3.1c-0.4-1.8-2-3.3-3.6-3.4
			C910.9,305.5,909.8,306.9,910.2,308.7"/>
				<path fill="#662D91" d="M900.6,308.3c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.8-1.3,2.4-3.1c-0.3-1.8-2-3.3-3.6-3.4
			C901.3,305.1,900.2,306.5,900.6,308.3"/>
				<path fill="#662D91" d="M891,308c0.3,1.8,1.9,3.3,3.6,3.4c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C891.7,304.8,890.6,306.2,891,308"/>
				<path fill="#662D91" d="M881.3,307.7c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0.1,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.6-3.3
			C882.1,304.5,881,305.9,881.3,307.7"/>
				<path fill="#662D91" d="M871.7,307.4c0.3,1.8,1.9,3.3,3.5,3.3c1.7,0,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.5-3.3
			C872.5,304.2,871.4,305.6,871.7,307.4"/>
				<path fill="#662D91" d="M862,307.2c0.3,1.8,1.8,3.3,3.5,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.3-1.8-1.8-3.3-3.5-3.3
			C862.9,304,861.8,305.4,862,307.2"/>
				<path fill="#662D91" d="M852.4,306.9c0.2,1.8,1.8,3.3,3.5,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.8-3.3-3.5-3.3
			C853.3,303.7,852.1,305.1,852.4,306.9"/>
				<path fill="#662D91" d="M936.6,299.7c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C937.3,296.5,936.2,297.9,936.6,299.7"/>
				<path fill="#662D91" d="M927.2,299.3c0.4,1.8,2,3.3,3.7,3.4c1.6,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.7-3.4
			C927.8,296.1,926.8,297.5,927.2,299.3"/>
				<path fill="#662D91" d="M917.7,298.9c0.4,1.8,2,3.3,3.6,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.6-3.4
			C918.4,295.7,917.3,297.1,917.7,298.9"/>
				<path fill="#662D91" d="M908.2,298.5c0.3,1.8,2,3.3,3.6,3.4c1.7,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.6-3.4
			C908.9,295.3,907.9,296.7,908.2,298.5"/>
				<path fill="#662D91" d="M898.7,298.2c0.3,1.8,1.9,3.3,3.6,3.3s2.7-1.3,2.4-3.1c-0.3-1.8-1.9-3.3-3.6-3.3
			C899.5,295,898.4,296.4,898.7,298.2"/>
				<path fill="#662D91" d="M889.2,297.9c0.3,1.8,1.9,3.3,3.6,3.3c1.7,0.1,2.7-1.4,2.4-3.1c-0.3-1.8-1.9-3.3-3.6-3.3
			C890,294.7,888.9,296.1,889.2,297.9"/>
				<path fill="#662D91" d="M879.7,297.6c0.3,1.8,1.9,3.3,3.5,3.3c1.7,0.1,2.8-1.4,2.5-3.1c-0.3-1.8-1.9-3.3-3.5-3.3
			C880.5,294.4,879.4,295.8,879.7,297.6"/>
				<path fill="#662D91" d="M870.1,297.3c0.3,1.8,1.8,3.3,3.5,3.3c1.7,0,2.8-1.4,2.5-3.2c-0.3-1.8-1.9-3.3-3.5-3.3
			C871,294.1,869.9,295.5,870.1,297.3"/>
				<path fill="#662D91" d="M860.6,297c0.3,1.8,1.8,3.3,3.5,3.3c1.7,0,2.8-1.4,2.5-3.2c-0.3-1.8-1.8-3.3-3.5-3.3
			C861.5,293.8,860.3,295.2,860.6,297"/>
				<path fill="#662D91" d="M851,296.8c0.2,1.8,1.8,3.3,3.4,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.3-1.8-1.8-3.3-3.4-3.3
			C851.9,293.6,850.8,295,851,296.8"/>
				<path fill="#662D91" d="M841.4,296.6c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.8-3.3-3.4-3.3
			C842.4,293.4,841.2,294.8,841.4,296.6"/>
				<path fill="#662D91" d="M990.2,292.5c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			C990.6,289.3,989.7,290.7,990.2,292.5"/>
				<path fill="#662D91" d="M980.9,292c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			C981.3,288.8,980.5,290.2,980.9,292"/>
				<path fill="#662D91" d="M971.7,291.4c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			S971.2,289.7,971.7,291.4"/>
				<path fill="#662D91" d="M962.4,290.9c0.5,1.8,2.1,3.3,3.8,3.4c1.6,0.1,2.6-1.3,2.1-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			C962.8,287.8,961.9,289.2,962.4,290.9"/>
				<path fill="#662D91" d="M953.1,290.5c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.1-3.1c-0.5-1.8-2.1-3.3-3.7-3.4
			C953.6,287.3,952.6,288.7,953.1,290.5"/>
				<path fill="#662D91" d="M943.7,290c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C944.3,286.9,943.3,288.2,943.7,290"/>
				<path fill="#662D91" d="M934.4,289.6c0.4,1.8,2,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C935,286.4,934,287.8,934.4,289.6"/>
				<path fill="#662D91" d="M906.2,288.4c0.3,1.8,2,3.3,3.6,3.3c1.6,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C906.9,285.2,905.9,286.6,906.2,288.4"/>
				<path fill="#662D91" d="M896.8,288.1c0.3,1.8,1.9,3.3,3.6,3.3c1.6,0.1,2.7-1.3,2.3-3.1c-0.3-1.8-1.9-3.3-3.6-3.3
			S896.5,286.3,896.8,288.1"/>
				<path fill="#662D91" d="M887.4,287.8c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.7-1.3,2.4-3.1s-1.9-3.3-3.5-3.3
			C888.2,284.6,887.1,286,887.4,287.8"/>
				<path fill="#662D91" d="M878,287.5c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.7-1.4,2.4-3.1c-0.3-1.8-1.9-3.3-3.5-3.3
			C878.8,284.3,877.7,285.7,878,287.5"/>
				<path fill="#662D91" d="M868.6,287.2c0.3,1.8,1.8,3.3,3.5,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.3-1.8-1.8-3.3-3.5-3.3
			S868.3,285.4,868.6,287.2"/>
				<path fill="#662D91" d="M859.1,286.9c0.3,1.8,1.8,3.3,3.4,3.3c1.6,0,2.8-1.4,2.5-3.2c-0.3-1.8-1.8-3.3-3.4-3.3
			C860,283.7,858.9,285.2,859.1,286.9"/>
				<path fill="#662D91" d="M849.7,286.7c0.2,1.8,1.8,3.3,3.4,3.3c1.6,0,2.8-1.4,2.5-3.2c-0.3-1.8-1.8-3.3-3.4-3.3
			C850.6,283.5,849.4,284.9,849.7,286.7"/>
				<path fill="#662D91" d="M840.2,286.5c0.2,1.8,1.7,3.3,3.4,3.3c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.8-3.3-3.4-3.3
			C841.1,283.3,840,284.7,840.2,286.5"/>
				<path fill="#662D91" d="M830.7,286.3c0.2,1.8,1.7,3.3,3.4,3.3c1.7,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.7-3.3-3.4-3.3
			C831.7,283.1,830.5,284.5,830.7,286.3"/>
				<path fill="#662D91" d="M996.5,283c0.5,1.8,2.3,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.3-3.3-3.8-3.4
			C996.8,279.8,995.9,281.2,996.5,283"/>
				<path fill="#662D91" d="M987.3,282.4c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.5-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.8-3.4
			C987.7,279.3,986.8,280.6,987.3,282.4"/>
				<path fill="#662D91" d="M978.1,281.9c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			C978.5,278.7,977.6,280.1,978.1,281.9"/>
				<path fill="#662D91" d="M969,281.4c0.5,1.8,2.2,3.3,3.8,3.4s2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.8-3.4
			C969.4,278.2,968.5,279.6,969,281.4"/>
				<path fill="#662D91" d="M959.8,280.9c0.5,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.1-3.3-3.7-3.4
			C960.2,277.7,959.3,279.1,959.8,280.9"/>
				<path fill="#662D91" d="M950.6,280.4c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.5-1.3,2.1-3.1c-0.5-1.8-2.1-3.3-3.7-3.4
			C951,277.2,950.1,278.6,950.6,280.4"/>
				<path fill="#662D91" d="M941.3,279.9c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.1-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			S940.9,278.2,941.3,279.9"/>
				<path fill="#662D91" d="M932.1,279.5c0.4,1.8,2,3.3,3.7,3.4c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2.1-3.3-3.7-3.4
			C932.6,276.4,931.7,277.7,932.1,279.5"/>
				<path fill="#662D91" d="M922.8,279.1c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C923.4,275.9,922.4,277.3,922.8,279.1"/>
				<path fill="#662D91" d="M913.5,278.7c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C914.2,275.6,913.2,276.9,913.5,278.7"/>
				<path fill="#662D91" d="M895,278c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.7-1.3,2.3-3.1c-0.4-1.8-1.9-3.3-3.5-3.3
			C895.7,274.8,894.6,276.2,895,278"/>
				<path fill="#662D91" d="M885.6,277.7c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.7-1.3,2.3-3.1c-0.3-1.8-1.9-3.3-3.5-3.3
			S885.3,275.9,885.6,277.7"/>
				<path fill="#662D91" d="M876.3,277.4c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.7-1.3,2.4-3.1c-0.3-1.8-1.9-3.3-3.5-3.3
			C877.1,274.2,876,275.6,876.3,277.4"/>
				<path fill="#662D91" d="M867,277.1c0.3,1.8,1.8,3.3,3.4,3.3s2.7-1.4,2.4-3.1c-0.3-1.8-1.8-3.3-3.4-3.3
			C867.8,273.9,866.7,275.3,867,277.1"/>
				<path fill="#662D91" d="M857.6,276.9c0.3,1.8,1.8,3.3,3.4,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.3-1.8-1.8-3.3-3.4-3.3
			C858.5,273.7,857.4,275.1,857.6,276.9"/>
				<path fill="#662D91" d="M848.3,276.6c0.2,1.8,1.8,3.3,3.4,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C849.2,273.5,848,274.9,848.3,276.6"/>
				<path fill="#662D91" d="M838.9,276.4c0.2,1.8,1.7,3.2,3.4,3.3c1.6,0,2.8-1.4,2.5-3.2c-0.2-1.8-1.7-3.2-3.4-3.3
			C839.8,273.2,838.7,274.7,838.9,276.4"/>
				<path fill="#662D91" d="M829.6,276.2c0.2,1.8,1.7,3.2,3.3,3.3c1.6,0,2.8-1.4,2.6-3.2c-0.2-1.8-1.7-3.2-3.3-3.3
			C830.5,273.1,829.4,274.5,829.6,276.2"/>
				<path fill="#662D91" d="M1002.5,273.5c0.6,1.8,2.3,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.8-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C1002.7,270.4,1001.9,271.7,1002.5,273.5"/>
				<path fill="#662D91" d="M993.4,272.9c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.3-3.3-3.8-3.4
			C993.7,269.8,992.9,271.2,993.4,272.9"/>
				<path fill="#662D91" d="M984.4,272.4c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.8-3.4
			S983.9,270.6,984.4,272.4"/>
				<path fill="#662D91" d="M975.3,271.8c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.8-3.4
			C975.7,268.7,974.8,270.1,975.3,271.8"/>
				<path fill="#662D91" d="M966.2,271.3c0.5,1.8,2.2,3.3,3.7,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.2-3.3-3.7-3.4
			C966.6,268.2,965.8,269.6,966.2,271.3"/>
				<path fill="#662D91" d="M957.1,270.8c0.5,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.1-3.3-3.7-3.4
			C957.6,267.7,956.7,269.1,957.1,270.8"/>
				<path fill="#662D91" d="M948,270.4c0.4,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.5-1.3,2-3.1c-0.5-1.8-2.1-3.3-3.7-3.4
			C948.5,267.2,947.6,268.6,948,270.4"/>
				<path fill="#662D91" d="M938.9,269.9c0.4,1.8,2.1,3.3,3.7,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2.1-3.3-3.7-3.3
			C939.4,266.8,938.5,268.1,938.9,269.9"/>
				<path fill="#662D91" d="M929.8,269.5c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C930.3,266.3,929.4,267.7,929.8,269.5"/>
				<path fill="#662D91" d="M920.6,269.1c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.6-1.3,2.2-3.1s-2-3.3-3.6-3.3
			C921.2,265.9,920.2,267.3,920.6,269.1"/>
				<path fill="#662D91" d="M911.4,268.7c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C912,265.5,911.1,266.9,911.4,268.7"/>
				<path fill="#662D91" d="M902.2,268.3c0.4,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-2-3.3-3.5-3.3
			C902.9,265.2,901.9,266.6,902.2,268.3"/>
				<path fill="#662D91" d="M893.1,268c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.6-1.3,2.3-3.1c-0.4-1.8-1.9-3.3-3.5-3.3
			C893.7,264.8,892.7,266.2,893.1,268"/>
				<path fill="#662D91" d="M883.8,267.7c0.3,1.8,1.9,3.3,3.5,3.3c1.6,0.1,2.6-1.3,2.3-3.1c-0.3-1.8-1.9-3.2-3.5-3.3
			C884.6,264.5,883.5,265.9,883.8,267.7"/>
				<path fill="#662D91" d="M874.6,267.4c0.3,1.8,1.8,3.2,3.5,3.3c1.6,0.1,2.7-1.3,2.3-3.1c-0.3-1.8-1.9-3.2-3.5-3.3
			C875.4,264.2,874.3,265.6,874.6,267.4"/>
				<path fill="#662D91" d="M865.4,267.1c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.7-1.4,2.4-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C866.2,263.9,865.1,265.3,865.4,267.1"/>
				<path fill="#662D91" d="M856.2,266.8c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.7-1.4,2.4-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C857,263.7,855.9,265.1,856.2,266.8"/>
				<path fill="#662D91" d="M846.9,266.6c0.2,1.8,1.7,3.2,3.4,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C847.8,263.4,846.7,264.8,846.9,266.6"/>
				<path fill="#662D91" d="M837.7,266.4c0.2,1.8,1.7,3.2,3.3,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.2-1.8-1.7-3.2-3.3-3.3
			C838.5,263.2,837.4,264.6,837.7,266.4"/>
				<path fill="#662D91" d="M828.4,266.2c0.2,1.8,1.7,3.2,3.3,3.3c1.6,0,2.7-1.4,2.5-3.1c-0.2-1.8-1.7-3.2-3.3-3.3
			C829.3,263,828.2,264.5,828.4,266.2"/>
				<path fill="#662D91" d="M1008.2,264.1c0.6,1.8,2.3,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C1008.4,261,1007.7,262.3,1008.2,264.1"/>
				<path fill="#662D91" d="M999.3,263.5c0.6,1.8,2.3,3.3,3.8,3.4c1.6,0.1,2.4-1.2,1.8-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C999.6,260.4,998.8,261.7,999.3,263.5"/>
				<path fill="#662D91" d="M990.4,262.9c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.8-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C990.7,259.8,989.9,261.2,990.4,262.9"/>
				<path fill="#662D91" d="M981.4,262.4c0.5,1.8,2.2,3.3,3.8,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.8-3.4
			C981.8,259.3,980.9,260.6,981.4,262.4"/>
				<path fill="#662D91" d="M972.5,261.8c0.5,1.8,2.2,3.3,3.7,3.4s2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.7-3.4
			C972.8,258.7,972,260.1,972.5,261.8"/>
				<path fill="#662D91" d="M963.5,261.3c0.5,1.8,2.1,3.3,3.7,3.4c1.6,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.7-3.4
			C963.9,258.2,963,259.6,963.5,261.3"/>
				<path fill="#662D91" d="M954.5,260.8c0.5,1.8,2.1,3.3,3.7,3.3c1.6,0.1,2.4-1.3,2-3c-0.5-1.8-2.1-3.3-3.7-3.3
			C954.9,257.7,954,259.1,954.5,260.8"/>
				<path fill="#662D91" d="M945.5,260.4c0.5,1.8,2.1,3.3,3.7,3.3c1.6,0.1,2.5-1.3,2-3c-0.5-1.8-2.1-3.3-3.7-3.3
			C945.9,257.2,945,258.6,945.5,260.4"/>
				<path fill="#662D91" d="M936.5,259.9c0.4,1.8,2.1,3.3,3.6,3.3c1.6,0.1,2.5-1.3,2-3.1c-0.4-1.8-2.1-3.3-3.6-3.3
			C936.9,256.8,936,258.2,936.5,259.9"/>
				<path fill="#662D91" d="M927.4,259.5c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2-3.3-3.6-3.3
			C927.9,256.4,927,257.7,927.4,259.5"/>
				<path fill="#662D91" d="M918.4,259.1c0.4,1.8,2,3.3,3.6,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2-3.2-3.6-3.3
			C918.9,256,918,257.3,918.4,259.1"/>
				<path fill="#662D91" d="M909.3,258.7c0.4,1.8,2,3.2,3.5,3.3c1.6,0.1,2.5-1.3,2.2-3.1c-0.4-1.8-2-3.2-3.5-3.3
			C909.9,255.6,908.9,256.9,909.3,258.7"/>
				<path fill="#662D91" d="M900.2,258.3c0.4,1.8,1.9,3.2,3.5,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-1.9-3.2-3.5-3.3
			C900.8,255.2,899.9,256.6,900.2,258.3"/>
				<path fill="#662D91" d="M891.1,258c0.3,1.8,1.9,3.2,3.5,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.4-1.8-1.9-3.2-3.5-3.3
			C891.8,254.9,890.8,256.2,891.1,258"/>
				<path fill="#662D91" d="M882,257.7c0.3,1.8,1.9,3.2,3.5,3.3c1.6,0.1,2.6-1.3,2.3-3.1c-0.3-1.8-1.9-3.2-3.5-3.3
			C882.7,254.5,881.7,255.9,882,257.7"/>
				<path fill="#662D91" d="M872.9,257.4c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0.1,2.6-1.3,2.3-3.1c-0.3-1.8-1.9-3.2-3.4-3.3
			C873.6,254.2,872.6,255.6,872.9,257.4"/>
				<path fill="#662D91" d="M863.8,257.1c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C864.6,254,863.5,255.4,863.8,257.1"/>
				<path fill="#662D91" d="M854.7,256.9c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.7-1.4,2.4-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C855.5,253.7,854.4,255.1,854.7,256.9"/>
				<path fill="#662D91" d="M845.5,256.6c0.2,1.8,1.7,3.2,3.3,3.3c1.6,0,2.7-1.4,2.4-3.1c-0.3-1.8-1.7-3.2-3.3-3.3
			C846.4,253.5,845.3,254.9,845.5,256.6"/>
				<path fill="#662D91" d="M1005,254.1c0.6,1.8,2.3,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C1005.2,251,1004.4,252.4,1005,254.1"/>
				<path fill="#662D91" d="M996.2,253.5c0.6,1.8,2.3,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.7-3c-0.6-1.8-2.3-3.3-3.8-3.4
			C996.4,250.4,995.6,251.8,996.2,253.5"/>
				<path fill="#662D91" d="M987.3,253c0.5,1.8,2.2,3.3,3.8,3.4c1.5,0.1,2.3-1.2,1.8-3c-0.6-1.8-2.2-3.3-3.8-3.4
			C987.6,249.9,986.8,251.2,987.3,253"/>
				<path fill="#662D91" d="M978.5,252.4c0.5,1.8,2.2,3.3,3.7,3.4c1.5,0.1,2.4-1.3,1.8-3c-0.5-1.8-2.5-3.7-4-3.8
			C978.5,248.9,978,250.7,978.5,252.4"/>
				<path fill="#662D91" d="M969.6,251.9c0.5,1.8,2.2,3.3,3.7,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.3-3.7-3.3
			C969.9,248.8,969.1,250.1,969.6,251.9"/>
				<path fill="#662D91" d="M960.7,251.4c0.5,1.8,2.1,3.3,3.7,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.2-3.2-3.7-3.3
			C961.1,248.3,960.3,249.6,960.7,251.4"/>
				<path fill="#662D91" d="M951.8,250.9c0.5,1.8,2.1,3.2,3.7,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.8-2.1-3.2-3.7-3.3
			C952.2,247.8,951.4,249.1,951.8,250.9"/>
				<path fill="#662D91" d="M942.9,250.4c0.5,1.8,2.1,3.2,3.6,3.3c1.6,0.1,2.4-1.3,2-3c-0.5-1.8-2.1-3.2-3.6-3.3
			C943.4,247.3,942.5,248.7,942.9,250.4"/>
				<path fill="#662D91" d="M934,250c0.4,1.8,2,3.2,3.6,3.3c1.6,0.1,2.5-1.3,2-3c-0.4-1.8-2.1-3.2-3.6-3.3
			C934.5,246.9,933.6,248.2,934,250"/>
				<path fill="#662D91" d="M925.1,249.6c0.4,1.8,2,3.2,3.6,3.3c1.6,0.1,2.5-1.3,2-3c-0.4-1.8-2-3.2-3.6-3.3
			C925.6,246.4,924.7,247.8,925.1,249.6"/>
				<path fill="#662D91" d="M916.1,249.2c0.4,1.8,2,3.2,3.5,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2-3.2-3.5-3.3
			C916.7,246,915.7,247.4,916.1,249.2"/>
				<path fill="#662D91" d="M907.2,248.8c0.4,1.8,2,3.2,3.5,3.3c1.6,0.1,2.5-1.3,2.1-3.1c-0.4-1.8-2-3.2-3.5-3.3
			C907.7,245.7,906.8,247,907.2,248.8"/>
				<path fill="#662D91" d="M898.2,248.4c0.4,1.8,1.9,3.2,3.5,3.3c1.6,0.1,2.5-1.3,2.2-3.1c-0.4-1.8-1.9-3.2-3.5-3.3
			C898.8,245.3,897.8,246.7,898.2,248.4"/>
				<path fill="#662D91" d="M889.2,248.1c0.3,1.8,1.9,3.2,3.5,3.3c1.6,0.1,2.5-1.3,2.2-3.1c-0.4-1.8-1.9-3.2-3.5-3.3
			C889.8,244.9,888.9,246.3,889.2,248.1"/>
				<path fill="#662D91" d="M880.2,247.8c0.3,1.8,1.9,3.2,3.4,3.3c1.6,0.1,2.6-1.3,2.2-3.1c-0.3-1.8-1.9-3.2-3.4-3.3
			C880.9,244.6,879.9,246,880.2,247.8"/>
				<path fill="#662D91" d="M871.2,247.5c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C871.9,244.3,870.9,245.7,871.2,247.5"/>
				<path fill="#662D91" d="M862.2,247.2c0.3,1.8,1.8,3.2,3.4,3.3c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.8-1.8-3.2-3.4-3.3
			C862.9,244.1,861.9,245.4,862.2,247.2"/>
				<path fill="#662D91" d="M853.2,246.9c0.3,1.8,1.8,3.2,3.3,3.3c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.8-1.8-3.2-3.3-3.2
			C853.9,243.8,852.9,245.2,853.2,246.9"/>
				<path fill="#662D91" d="M844.1,246.7c0.2,1.8,1.7,3.2,3.3,3.2c1.6,0,2.6-1.4,2.4-3.1c-0.3-1.8-1.7-3.2-3.3-3.2
			C844.9,243.6,843.9,245,844.1,246.7"/>
				<path fill="#662D91" d="M835.1,246.5c0.2,1.8,1.7,3.2,3.3,3.2c1.6,0,2.7-1.4,2.4-3.1c-0.2-1.8-1.7-3.2-3.3-3.2
			C835.9,243.4,834.8,244.8,835.1,246.5"/>
				<path fill="#662D91" d="M966.7,242c0.5,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.3-1.3,1.8-3c-0.5-1.7-2.2-3.2-3.7-3.3
			C967.1,238.9,966.2,240.2,966.7,242"/>
				<path fill="#662D91" d="M958,241.5c0.5,1.7,2.1,3.2,3.7,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.7-3.3
			C958.3,238.4,957.5,239.7,958,241.5"/>
				<path fill="#662D91" d="M949.2,241c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C949.5,237.9,948.7,239.3,949.2,241"/>
				<path fill="#662D91" d="M940.4,240.5c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C940.8,237.4,939.9,238.8,940.4,240.5"/>
				<path fill="#662D91" d="M931.5,240.1c0.4,1.7,2,3.2,3.6,3.3c1.5,0.1,2.4-1.3,2-3c-0.5-1.7-2-3.2-3.6-3.3
			C932,237,931.1,238.3,931.5,240.1"/>
				<path fill="#662D91" d="M922.7,239.7c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-2-3.2-3.5-3.3
			C923.2,236.6,922.3,237.9,922.7,239.7"/>
				<path fill="#662D91" d="M913.9,239.3c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.5-1.3,2-3c-0.4-1.7-2-3.2-3.5-3.3
			C914.4,236.2,913.5,237.5,913.9,239.3"/>
				<path fill="#662D91" d="M905,238.9c0.4,1.7,1.9,3.2,3.5,3.3c1.5,0.1,2.5-1.3,2.1-3c-0.4-1.7-2-3.2-3.5-3.3
			C905.6,235.8,904.6,237.1,905,238.9"/>
				<path fill="#662D91" d="M896.1,238.5c0.4,1.7,1.9,3.2,3.5,3.3c1.5,0.1,2.5-1.3,2.1-3c-0.4-1.7-1.9-3.2-3.5-3.3
			C896.7,235.4,895.8,236.8,896.1,238.5"/>
				<path fill="#662D91" d="M887.3,238.2c0.3,1.7,1.9,3.2,3.4,3.3c1.5,0.1,2.5-1.3,2.2-3.1c-0.4-1.7-1.9-3.2-3.4-3.3
			C887.9,235.1,886.9,236.5,887.3,238.2"/>
				<path fill="#662D91" d="M878.4,237.9c0.3,1.7,1.8,3.2,3.4,3.3c1.6,0.1,2.5-1.3,2.2-3.1c-0.3-1.7-1.9-3.2-3.4-3.2
			C879,234.8,878,236.1,878.4,237.9"/>
				<path fill="#662D91" d="M869.5,237.6c0.3,1.7,1.8,3.2,3.4,3.2c1.6,0,2.5-1.3,2.2-3.1c-0.3-1.7-1.8-3.2-3.4-3.2
			C870.2,234.5,869.2,235.8,869.5,237.6"/>
				<path fill="#662D91" d="M860.6,237.3c0.3,1.7,1.8,3.2,3.3,3.2c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.7-1.8-3.2-3.3-3.2
			C861.3,234.2,860.3,235.6,860.6,237.3"/>
				<path fill="#662D91" d="M851.6,237.1c0.3,1.7,1.7,3.2,3.3,3.2c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.7-1.8-3.2-3.3-3.2
			C852.4,234,851.4,235.3,851.6,237.1"/>
				<path fill="#662D91" d="M842.7,236.8c0.2,1.7,1.7,3.2,3.3,3.2c1.6,0,2.6-1.3,2.3-3.1c-0.3-1.7-1.7-3.2-3.3-3.2
			C843.5,233.7,842.5,235.1,842.7,236.8"/>
				<path fill="#662D91" d="M833.8,236.6c0.2,1.7,1.7,3.2,3.2,3.2c1.6,0,2.6-1.3,2.4-3.1c-0.2-1.7-1.7-3.2-3.2-3.2
			C834.6,233.5,833.5,234.9,833.8,236.6"/>
				<path fill="#662D91" d="M824.8,236.5c0.2,1.7,1.6,3.2,3.2,3.2c1.6,0,2.6-1.4,2.4-3.1c-0.2-1.7-1.7-3.2-3.2-3.2
			C825.7,233.3,824.6,234.7,824.8,236.5"/>
				<path fill="#662D91" d="M920.3,229.8c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-2-3.2-3.5-3.3
			C920.8,226.8,919.9,228.1,920.3,229.8"/>
				<path fill="#662D91" d="M911.6,229.4c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-2-3.2-3.5-3.3
			C912.1,226.4,911.2,227.7,911.6,229.4"/>
				<path fill="#662D91" d="M902.8,229.1c0.4,1.7,1.9,3.2,3.5,3.3c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.2-3.5-3.3
			C903.4,226,902.5,227.3,902.8,229.1"/>
				<path fill="#662D91" d="M894.1,228.7c0.4,1.7,1.9,3.2,3.4,3.2c1.5,0.1,2.5-1.3,2.1-3c-0.4-1.7-1.9-3.2-3.4-3.2
			C894.6,225.6,893.7,227,894.1,228.7"/>
				<path fill="#662D91" d="M885.3,228.4c0.3,1.7,1.9,3.2,3.4,3.2c1.5,0.1,2.5-1.3,2.1-3c-0.4-1.7-1.9-3.2-3.4-3.2
			C885.9,225.3,885,226.6,885.3,228.4"/>
				<path fill="#662D91" d="M876.5,228.1c0.3,1.7,1.8,3.2,3.4,3.2c1.5,0.1,2.5-1.3,2.2-3c-0.3-1.7-1.8-3.2-3.4-3.2
			C877.2,225,876.2,226.3,876.5,228.1"/>
				<path fill="#662D91" d="M867.7,227.8c0.3,1.7,1.8,3.2,3.3,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.8-3.2-3.3-3.2
			C868.4,224.7,867.4,226,867.7,227.8"/>
				<path fill="#662D91" d="M858.9,227.5c0.3,1.7,1.8,3.2,3.3,3.2c1.5,0,2.5-1.3,2.2-3.1c-0.3-1.7-1.8-3.2-3.3-3.2
			C859.6,224.4,858.6,225.8,858.9,227.5"/>
				<path fill="#662D91" d="M850.1,227.3c0.3,1.7,1.7,3.2,3.3,3.2c1.5,0,2.5-1.3,2.3-3.1c-0.3-1.7-1.7-3.2-3.3-3.2
			C850.9,224.2,849.8,225.5,850.1,227.3"/>
				<path fill="#662D91" d="M841.3,227c0.2,1.7,1.7,3.2,3.2,3.2c1.5,0,2.6-1.3,2.3-3.1c-0.3-1.7-1.7-3.2-3.2-3.2
			C842.1,223.9,841,225.3,841.3,227"/>
				<path fill="#662D91" d="M832.5,226.8c0.2,1.7,1.7,3.2,3.2,3.2c1.5,0,2.6-1.3,2.3-3.1c-0.2-1.7-1.7-3.2-3.2-3.2
			C833.3,223.7,832.2,225.1,832.5,226.8"/>
				<path fill="#662D91" d="M823.6,226.7c0.2,1.7,1.6,3.2,3.2,3.2c1.5,0,2.6-1.3,2.4-3.1c-0.2-1.7-1.6-3.2-3.2-3.2
			C824.5,223.6,823.4,224.9,823.6,226.7"/>
				<path fill="#662D91" d="M883.3,218.6c0.3,1.7,1.9,3.2,3.4,3.2c1.5,0.1,2.4-1.3,2.1-3c-0.4-1.7-1.9-3.2-3.4-3.2
			C883.9,215.6,883,216.9,883.3,218.6"/>
				<path fill="#662D91" d="M874.7,218.3c0.3,1.7,1.8,3.2,3.3,3.2c1.5,0.1,2.5-1.3,2.1-3c-0.3-1.7-1.8-3.2-3.3-3.2
			C875.3,215.3,874.3,216.6,874.7,218.3"/>
				<path fill="#662D91" d="M866,218c0.3,1.7,1.8,3.2,3.3,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.8-3.1-3.3-3.2
			C866.6,215,865.7,216.3,866,218"/>
				<path fill="#662D91" d="M857.3,217.8c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.8-3.1-3.3-3.2
			C858,214.7,857,216.1,857.3,217.8"/>
				<path fill="#662D91" d="M848.6,217.5c0.3,1.7,1.7,3.1,3.2,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.2-3.2
			C849.3,214.5,848.3,215.8,848.6,217.5"/>
				<path fill="#662D91" d="M839.9,217.3c0.3,1.7,1.7,3.1,3.2,3.2c1.5,0,2.5-1.3,2.3-3c-0.3-1.7-1.7-3.1-3.2-3.2
			C840.6,214.2,839.6,215.6,839.9,217.3"/>
				<path fill="#662D91" d="M831.2,217.1c0.2,1.7,1.6,3.1,3.2,3.2c1.5,0,2.6-1.3,2.3-3c-0.2-1.7-1.7-3.1-3.2-3.2
			C832,214,830.9,215.4,831.2,217.1"/>
				<path fill="#662D91" d="M855.6,208.1c0.3,1.7,1.7,3.1,3.2,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.8-3.1-3.2-3.2
			C856.3,205.1,855.4,206.4,855.6,208.1"/>
				<path fill="#662D91" d="M847.1,207.9c0.3,1.7,1.7,3.1,3.2,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.2-3.2
			C847.8,204.8,846.8,206.2,847.1,207.9"/>
				<path fill="#662D91" d="M838.5,207.6c0.3,1.7,1.7,3.1,3.2,3.2c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.2-3.1
			C839.2,204.6,838.2,205.9,838.5,207.6"/>
			</g>
			<g className={classNames("g-eur", { 'g-map-active': highlight === 'europe' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('europe')}>
				<path fill="#00BCF1" d="M744.1,235.8c0,1.7,1.3,3.1,2.9,3.1c1.6,0,2.8-1.4,2.7-3.2c0-1.7-1.3-3.1-2.9-3.1
			C745.3,232.7,744.1,234.1,744.1,235.8"/>
				<path fill="#00BCF1" d="M690.2,236.4c-0.1,1.7,1.1,3.1,2.7,3.1c1.6,0,2.9-1.5,3-3.2c0.1-1.7-1.1-3.1-2.7-3.1
			C691.6,233.3,690.3,234.7,690.2,236.4"/>
				<path fill="#00BCF1" d="M681.2,236.6c-0.1,1.7,1.1,3.1,2.7,3.1c1.6,0,2.9-1.5,3-3.2c0.1-1.7-1.1-3.1-2.7-3.1
			C682.7,233.4,681.3,234.9,681.2,236.6"/>
				<path fill="#00BCF1" d="M672.2,236.8c-0.1,1.7,1,3.1,2.6,3.1c1.6,0,2.9-1.5,3-3.2s-1.1-3.1-2.6-3.1
			C673.7,233.6,672.4,235.1,672.2,236.8"/>
				<path fill="#00BCF1" d="M663.3,237c-0.1,1.7,1,3.1,2.6,3.1c1.6,0,2.9-1.5,3.1-3.2c0.1-1.7-1-3.1-2.6-3.1
			C664.8,233.9,663.4,235.3,663.3,237"/>
				<path fill="#00BCF1" d="M761.7,226c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			C762.8,222.9,761.6,224.3,761.7,226"/>
				<path fill="#00BCF1" d="M752.8,226c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.7-1.4,2.7-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			S752.7,224.3,752.8,226"/>
				<path fill="#00BCF1" d="M699.5,226.5c-0.1,1.7,1.1,3.1,2.7,3.1c1.5,0,2.8-1.4,2.9-3.2c0-1.7-1.2-3.1-2.7-3.1
			C700.9,223.3,699.6,224.8,699.5,226.5"/>
				<path fill="#00BCF1" d="M690.7,226.6c-0.1,1.7,1.1,3.1,2.7,3.1c1.5,0,2.9-1.5,2.9-3.2c0.1-1.7-1.1-3.1-2.6-3.1
			C692.1,223.5,690.7,224.9,690.7,226.6"/>
				<path fill="#00BCF1" d="M681.8,226.8c-0.1,1.7,1.1,3.1,2.6,3.1c1.5,0,2.9-1.5,3-3.2s-1.1-3.1-2.6-3.1
			C683.2,223.7,681.9,225.1,681.8,226.8"/>
				<path fill="#00BCF1" d="M672.9,227c-0.1,1.7,1,3.1,2.6,3.1c1.5,0,2.9-1.5,3-3.2c0.1-1.7-1-3.1-2.6-3.1
			C674.4,223.9,673,225.3,672.9,227"/>
				<path fill="#00BCF1" d="M664,227.2c-0.1,1.7,1,3.1,2.5,3.1c1.5,0,2.9-1.5,3-3.2s-1-3.1-2.5-3.1S664.2,225.5,664,227.2" />
				<path fill="#00BCF1" d="M743.7,216.3c0,1.7,1.3,3.1,2.8,3.1c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.3-3.1-2.8-3.1
			C744.9,213.2,743.7,214.6,743.7,216.3"/>
				<path fill="#00BCF1" d="M735,216.4c0,1.7,1.3,3.1,2.8,3.1c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.3-3.1-2.8-3.1
			C736.2,213.3,734.9,214.6,735,216.4"/>
				<path fill="#00BCF1" d="M717.4,216.5c0,1.7,1.2,3.1,2.7,3.1c1.5,0,2.8-1.4,2.8-3.1c0-1.7-1.2-3.1-2.7-3.1S717.4,214.8,717.4,216.5
			"/>
				<path fill="#00BCF1" d="M699.9,216.8c-0.1,1.7,1.1,3.1,2.7,3.1c1.5,0,2.8-1.4,2.9-3.1c0-1.7-1.1-3.1-2.7-3.1
			C701.2,213.6,700,215,699.9,216.8"/>
				<path fill="#00BCF1" d="M691.1,216.9c-0.1,1.7,1.1,3.1,2.6,3.1c1.5,0,2.8-1.4,2.9-3.2c0.1-1.7-1.1-3.1-2.6-3.1
			C692.5,213.8,691.2,215.2,691.1,216.9"/>
				<path fill="#00BCF1" d="M752.2,206.6c0.1,1.7,1.3,3.1,2.8,3.1c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.3-3.1-2.8-3.1
			C753.3,203.6,752.1,204.9,752.2,206.6"/>
				<path fill="#00BCF1" d="M743.5,206.7c0,1.7,1.3,3.1,2.8,3.1c1.5,0,2.7-1.4,2.6-3.1c0-1.7-1.3-3.1-2.8-3.1
			C744.7,203.6,743.5,205,743.5,206.7"/>
				<path fill="#00BCF1" d="M734.9,206.7c0,1.7,1.2,3.1,2.8,3.1s2.7-1.4,2.7-3.1c0-1.7-1.3-3.1-2.8-3.1
			C736,203.6,734.9,205,734.9,206.7"/>
				<path fill="#00BCF1" d="M726.2,206.8c0,1.7,1.2,3.1,2.7,3.1c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.2-3.1-2.7-3.1
			C727.4,203.7,726.2,205.1,726.2,206.8"/>
				<path fill="#00BCF1" d="M717.6,206.9c0,1.7,1.2,3.1,2.7,3.1c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.2-3.1-2.7-3
			C718.8,203.8,717.6,205.2,717.6,206.9"/>
				<path fill="#00BCF1" d="M708.9,207c0,1.7,1.1,3.1,2.6,3c1.5,0,2.8-1.4,2.8-3.1c0-1.7-1.2-3.1-2.7-3
			C710.2,203.9,708.9,205.3,708.9,207"/>
				<path fill="#00BCF1" d="M700.3,207.1c-0.1,1.7,1.1,3.1,2.6,3c1.5,0,2.8-1.4,2.8-3.1c0-1.7-1.1-3.1-2.6-3
			C701.6,204,700.3,205.4,700.3,207.1"/>
				<path fill="#00BCF1" d="M691.6,207.3c-0.1,1.7,1.1,3.1,2.6,3c1.5,0,2.8-1.4,2.9-3.1c0.1-1.7-1.1-3.1-2.6-3
			C693,204.2,691.7,205.5,691.6,207.3"/>
				<path fill="#00BCF1" d="M743.3,197.1c0,1.7,1.3,3.1,2.8,3c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.3-3-2.8-3
			C744.4,194,743.3,195.4,743.3,197.1"/>
				<path fill="#00BCF1" d="M734.8,197.1c0,1.7,1.2,3,2.7,3c1.5,0,2.7-1.4,2.6-3.1c0-1.7-1.2-3-2.7-3
			C735.9,194.1,734.8,195.5,734.8,197.1"/>
				<path fill="#00BCF1" d="M726.2,197.2c0,1.7,1.2,3,2.7,3c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.2-3-2.7-3
			C727.4,194.1,726.2,195.5,726.2,197.2"/>
				<path fill="#00BCF1" d="M717.7,197.3c0,1.7,1.2,3,2.6,3c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.2-3-2.7-3
			C718.9,194.2,717.7,195.6,717.7,197.3"/>
				<path fill="#00BCF1" d="M709.2,197.4c0,1.7,1.1,3,2.6,3c1.5,0,2.7-1.4,2.8-3.1c0-1.7-1.1-3-2.6-3
			C710.4,194.3,709.2,195.7,709.2,197.4"/>
				<path fill="#00BCF1" d="M700.6,197.5c-0.1,1.7,1.1,3,2.6,3c1.5,0,2.7-1.4,2.8-3.1c0-1.7-1.1-3-2.6-3
			C701.9,194.5,700.7,195.8,700.6,197.5"/>
				<path fill="#00BCF1" d="M692.1,197.7c-0.1,1.7,1.1,3,2.5,3c1.5,0,2.8-1.4,2.8-3.1c0.1-1.7-1.1-3-2.5-3
			C693.4,194.6,692.2,196,692.1,197.7"/>
				<path fill="#00BCF1" d="M683.5,197.9c-0.1,1.7,1,3,2.5,3c1.5,0,2.8-1.4,2.9-3.1c0.1-1.7-1-3-2.5-3
			C684.9,194.8,683.6,196.2,683.5,197.9"/>
				<path fill="#00BCF1" d="M734.7,187.7c0,1.7,1.2,3,2.7,3c1.5,0,2.6-1.4,2.6-3c0-1.7-1.2-3-2.7-3C735.8,184.6,734.7,186,734.7,187.7
			"/>
				<path fill="#00BCF1" d="M726.3,187.7c0,1.7,1.2,3,2.6,3c1.5,0,2.7-1.4,2.6-3c0-1.7-1.2-3-2.6-3
			C727.5,184.7,726.3,186.1,726.3,187.7"/>
				<path fill="#00BCF1" d="M717.8,187.8c0,1.7,1.1,3,2.6,3c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.2-3-2.6-3
			C719.1,184.8,717.9,186.1,717.8,187.8"/>
				<path fill="#00BCF1" d="M709.4,187.9c0,1.7,1.1,3,2.6,3c1.5,0,2.7-1.4,2.7-3.1c0-1.7-1.1-3-2.6-3
			C710.7,184.9,709.4,186.2,709.4,187.9"/>
				<path fill="#00BCF1" d="M684.1,188.4c-0.1,1.7,1,3,2.5,3c1.5,0,2.7-1.4,2.8-3.1c0.1-1.7-1-3-2.5-3
			C685.5,185.3,684.2,186.7,684.1,188.4"/>
				<path fill="#00BCF1" d="M734.6,178.3c0,1.7,1.2,3,2.6,3c1.4,0,2.6-1.4,2.6-3c0-1.7-1.2-3-2.7-3
			C735.7,175.3,734.6,176.6,734.6,178.3"/>
				<path fill="#00BCF1" d="M726.3,178.3c0,1.7,1.2,3,2.6,3c1.4,0,2.6-1.4,2.6-3c0-1.7-1.2-3-2.6-3
			C727.5,175.3,726.3,176.7,726.3,178.3"/>
				<path fill="#00BCF1" d="M718,178.4c0,1.7,1.1,3,2.6,3c1.5,0,2.6-1.4,2.6-3c0-1.7-1.1-3-2.6-3C719.2,175.4,718,176.8,718,178.4" />
				<path fill="#00BCF1" d="M709.7,178.5c0,1.7,1.1,3,2.5,3c1.4,0,2.6-1.4,2.7-3c0-1.7-1.1-3-2.5-3
			C710.9,175.5,709.7,176.9,709.7,178.5"/>
				<path fill="#00BCF1" d="M701.3,178.6c-0.1,1.7,1.1,3,2.5,3c1.5,0,2.7-1.4,2.7-3c0.1-1.7-1.1-3-2.5-2.9
			C702.6,175.6,701.4,177,701.3,178.6"/>
				<path fill="#00BCF1" d="M693,178.8c-0.1,1.7,1,3,2.5,3c1.4,0,2.7-1.4,2.8-3c0.1-1.7-1-3-2.5-2.9
			C694.3,175.8,693.1,177.1,693,178.8"/>
				<path fill="#00BCF1" d="M684.7,179c-0.1,1.7,1,3,2.4,2.9c1.5,0,2.7-1.4,2.8-3.1c0.1-1.7-1-3-2.4-2.9
			C686.1,176,684.8,177.3,684.7,179"/>
				<path fill="#00BCF1" d="M668.1,179.4c-0.1,1.7,0.9,3,2.4,2.9c1.5,0,2.7-1.4,2.9-3.1c0.1-1.7-0.9-3-2.4-2.9
			C669.5,176.3,668.2,177.7,668.1,179.4"/>
				<path fill="#00BCF1" d="M726.3,169c0,1.6,1.1,3,2.6,2.9c1.4,0,2.6-1.4,2.6-3c0-1.6-1.2-2.9-2.6-2.9
			C727.5,166.1,726.3,167.4,726.3,169"/>
				<path fill="#00BCF1" d="M685.3,169.7c-0.1,1.6,1,2.9,2.4,2.9c1.4,0,2.7-1.4,2.8-3c0.1-1.6-1-2.9-2.4-2.9
			C686.6,166.7,685.4,168,685.3,169.7"/>
				<path fill="#00BCF1" d="M677.1,169.9c-0.1,1.6,0.9,2.9,2.4,2.9c1.4,0,2.7-1.4,2.8-3c0.1-1.6-0.9-2.9-2.4-2.9
			C678.5,166.9,677.2,168.2,677.1,169.9"/>
				<path fill="#00BCF1" d="M677.8,160.7c-0.1,1.6,0.9,2.9,2.3,2.9c1.4,0,2.6-1.4,2.8-3c0.1-1.6-0.9-2.9-2.3-2.9
			C679.2,157.7,677.9,159,677.8,160.7"/>
				<path fill="#00BCF1" d="M669.7,160.9c-0.1,1.6,0.9,2.9,2.3,2.9c1.4,0,2.7-1.4,2.8-3c0.1-1.6-0.9-2.9-2.3-2.9
			C671.1,157.9,669.9,159.2,669.7,160.9"/>
				<path fill="#00BCF1" d="M686.5,151.4c-0.1,1.6,0.9,2.9,2.3,2.8c1.4,0,2.6-1.4,2.7-2.9c0.1-1.6-0.9-2.9-2.3-2.8
			C687.8,148.5,686.6,149.8,686.5,151.4"/>
				<path fill="#00BCF1" d="M1229,267.3c1,1.8,2.9,3.4,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.9c-1-1.8-2.9-3.3-4.3-3.5
			C1228.3,264.3,1228,265.5,1229,267.3"/>
				<path fill="#00BCF1" d="M1204.6,264.1c0.9,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.8-1.1,0.8-2.9c-1-1.8-2.9-3.3-4.3-3.5
			C1204.1,261.1,1203.7,262.4,1204.6,264.1"/>
				<path fill="#00BCF1" d="M1196.5,263.1c0.9,1.8,2.8,3.3,4.3,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.8-2.9-3.3-4.3-3.5
			C1195.9,260,1195.5,261.3,1196.5,263.1"/>
				<path fill="#00BCF1" d="M1188.3,262.1c0.9,1.8,2.8,3.3,4.3,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.3-3.5
			C1187.8,259,1187.4,260.3,1188.3,262.1"/>
				<path fill="#00BCF1" d="M815.9,236.3c0.2,1.7,1.6,3.2,3.2,3.2c1.6,0,2.7-1.4,2.5-3.1c-0.2-1.7-1.6-3.2-3.2-3.2
			C816.8,233.2,815.7,234.6,815.9,236.3"/>
				<path fill="#00BCF1" d="M789,236c0.1,1.7,1.5,3.2,3.1,3.2c1.6,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.5-3.2-3.1-3.2
			C790,232.8,788.9,234.2,789,236"/>
				<path fill="#00BCF1" d="M780,235.9c0.1,1.7,1.5,3.2,3,3.2c1.6,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.5-3.2-3-3.2
			C781.1,232.8,779.9,234.1,780,235.9"/>
				<path fill="#00BCF1" d="M1231.4,258.4c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1230.7,255.4,1230.4,256.7,1231.4,258.4"/>
				<path fill="#00BCF1" d="M1223.4,257.4c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1222.8,254.3,1222.4,255.6,1223.4,257.4"/>
				<path fill="#00BCF1" d="M1215.4,256.3c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1214.8,253.2,1214.4,254.5,1215.4,256.3"/>
				<path fill="#00BCF1" d="M1207.4,255.2c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.8-2.9c-1-1.8-2.9-3.3-4.3-3.5
			C1206.7,252.2,1206.4,253.5,1207.4,255.2"/>
				<path fill="#00BCF1" d="M1199.3,254.2c0.9,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.8-1.1,0.8-2.9c-1-1.8-2.9-3.3-4.3-3.5
			C1198.7,251.1,1198.3,252.4,1199.3,254.2"/>
				<path fill="#00BCF1" d="M1191.2,253.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.8-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
			C1190.6,250.1,1190.3,251.4,1191.2,253.1"/>
				<path fill="#00BCF1" d="M1183.1,252.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
			C1182.5,249.1,1182.1,250.4,1183.1,252.1"/>
				<path fill="#00BCF1" d="M1174.9,251.1c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
			C1174.4,248.1,1174,249.4,1174.9,251.1"/>
				<path fill="#00BCF1" d="M972.5,232.7c0.5,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.3-1.2,1.7-3c-0.5-1.7-2.2-3.2-3.7-3.3
			C972.8,229.6,972,230.9,972.5,232.7"/>
				<path fill="#00BCF1" d="M963.9,232.1c0.5,1.7,2.1,3.2,3.7,3.3s2.3-1.2,1.8-3c-0.5-1.7-2.2-3.2-3.7-3.3
			C964.1,229.1,963.3,230.4,963.9,232.1"/>
				<path fill="#00BCF1" d="M955.2,231.6c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.3,1.8-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C955.5,228.6,954.7,229.9,955.2,231.6"/>
				<path fill="#00BCF1" d="M946.5,231.2c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C946.8,228.1,946,229.4,946.5,231.2"/>
				<path fill="#00BCF1" d="M937.8,230.7c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C938.2,227.6,937.3,229,937.8,230.7"/>
				<path fill="#00BCF1" d="M929.1,230.3c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.4-1.3,1.9-3c-0.5-1.7-2-3.2-3.5-3.3
			C929.5,227.2,928.6,228.5,929.1,230.3"/>
				<path fill="#00BCF1" d="M814.8,226.5c0.2,1.7,1.6,3.2,3.1,3.2c1.5,0,2.6-1.4,2.4-3.1c-0.2-1.7-1.6-3.1-3.1-3.2
			C815.7,223.4,814.6,224.8,814.8,226.5"/>
				<path fill="#00BCF1" d="M806,226.4c0.2,1.7,1.6,3.1,3.1,3.2c1.5,0,2.6-1.4,2.5-3.1c-0.2-1.7-1.6-3.1-3.1-3.2
			C806.9,223.3,805.8,224.6,806,226.4"/>
				<path fill="#00BCF1" d="M797.1,226.3c0.2,1.7,1.5,3.1,3.1,3.2c1.5,0,2.7-1.4,2.5-3.1c-0.2-1.7-1.5-3.1-3.1-3.2
			C798.1,223.1,797,224.5,797.1,226.3"/>
				<path fill="#00BCF1" d="M788.3,226.2c0.1,1.7,1.5,3.1,3,3.2c1.5,0,2.7-1.4,2.5-3.1c-0.1-1.7-1.5-3.1-3-3.2
			C789.3,223,788.1,224.4,788.3,226.2"/>
				<path fill="#00BCF1" d="M779.4,226.1c0.1,1.7,1.5,3.1,3,3.1c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.5-3.1-3-3.1
			C780.4,223,779.3,224.4,779.4,226.1"/>
				<path fill="#00BCF1" d="M1326,263.9c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
			C1325.1,260.9,1324.9,262.2,1326,263.9"/>
				<path fill="#00BCF1" d="M1257.2,253c1,1.8,3,3.3,4.3,3.5s1.6-1.1,0.5-2.8c-1.1-1.8-3-3.3-4.3-3.5
			C1256.4,250,1256.1,251.3,1257.2,253"/>
				<path fill="#00BCF1" d="M1249.3,251.9c1,1.8,3,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.8-3-3.3-4.3-3.5
			C1248.6,248.8,1248.3,250.1,1249.3,251.9"/>
				<path fill="#00BCF1" d="M1241.5,250.7c1,1.8,3,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.8-3-3.3-4.3-3.5
			C1240.8,247.7,1240.5,249,1241.5,250.7"/>
				<path fill="#00BCF1" d="M1233.6,249.6c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.6-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1232.9,246.6,1232.6,247.9,1233.6,249.6"/>
				<path fill="#00BCF1" d="M1225.8,248.5c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1225,245.5,1224.8,246.8,1225.8,248.5"/>
				<path fill="#00BCF1" d="M1217.8,247.4c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1217.2,244.4,1216.8,245.7,1217.8,247.4"/>
				<path fill="#00BCF1" d="M1209.9,246.4c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1209.2,243.3,1208.9,244.6,1209.9,246.4"/>
				<path fill="#00BCF1" d="M1201.9,245.3c1,1.8,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-1-1.8-2.9-3.3-4.3-3.5
			C1201.3,242.3,1200.9,243.5,1201.9,245.3"/>
				<path fill="#00BCF1" d="M1193.9,244.3c1,1.8,2.9,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-1-1.8-2.9-3.3-4.2-3.5
			C1193.3,241.2,1193,242.5,1193.9,244.3"/>
				<path fill="#00BCF1" d="M1185.9,243.2c0.9,1.8,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.8-2.8c-0.9-1.8-2.8-3.3-4.2-3.5
			C1185.3,240.2,1184.9,241.5,1185.9,243.2"/>
				<path fill="#00BCF1" d="M1177.8,242.2c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.8-2.9c-0.9-1.8-2.8-3.3-4.2-3.5
			C1177.3,239.2,1176.9,240.5,1177.8,242.2"/>
				<path fill="#00BCF1" d="M1169.8,241.2c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.7-2.8-3.3-4.2-3.5
			C1169.2,238.2,1168.8,239.5,1169.8,241.2"/>
				<path fill="#00BCF1" d="M1161.7,240.3c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.7-2.8-3.3-4.2-3.5
			C1161.2,237.2,1160.8,238.5,1161.7,240.3"/>
				<path fill="#00BCF1" d="M1153.5,239.3c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.7-2.8-3.3-4.2-3.5
			C1153.1,236.3,1152.6,237.6,1153.5,239.3"/>
				<path fill="#00BCF1" d="M1145.4,238.4c0.9,1.7,2.7,3.3,4.1,3.5c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.7-2.7-3.3-4.1-3.5
			C1144.9,235.3,1144.5,236.6,1145.4,238.4"/>
				<path fill="#00BCF1" d="M1137.2,237.4c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.7-2.7-3.3-4.1-3.4
			C1136.8,234.4,1136.3,235.7,1137.2,237.4"/>
				<path fill="#00BCF1" d="M1129,236.5c0.8,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.7-2.7-3.3-4.1-3.4
			C1128.6,233.5,1128.2,234.8,1129,236.5"/>
				<path fill="#00BCF1" d="M1120.8,235.6c0.8,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1.1-2.9c-0.8-1.7-2.7-3.3-4.1-3.4
			C1120.4,232.6,1120,233.9,1120.8,235.6"/>
				<path fill="#00BCF1" d="M978.1,223.4c0.5,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.2-1.2,1.7-3c-0.6-1.7-2.2-3.2-3.7-3.3
			C978.3,220.4,977.5,221.7,978.1,223.4"/>
				<path fill="#00BCF1" d="M969.5,222.9c0.5,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.3-1.2,1.7-3c-0.5-1.7-2.2-3.2-3.7-3.3
			S969,221.2,969.5,222.9"/>
				<path fill="#00BCF1" d="M960.9,222.4c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.2,1.7-3c-0.5-1.7-2.2-3.2-3.6-3.3
			C961.2,219.3,960.4,220.6,960.9,222.4"/>
				<path fill="#00BCF1" d="M952.4,221.9c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.2,1.8-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C952.7,218.8,951.9,220.1,952.4,221.9"/>
				<path fill="#00BCF1" d="M943.8,221.4c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.3,1.8-3c-0.5-1.7-2.1-3.2-3.6-3.3
			C944.1,218.3,943.3,219.7,943.8,221.4"/>
				<path fill="#00BCF1" d="M935.2,220.9c0.5,1.7,2,3.2,3.5,3.3c1.5,0.1,2.3-1.3,1.9-3c-0.5-1.7-2.1-3.2-3.5-3.3
			C935.6,217.9,934.7,219.2,935.2,220.9"/>
				<path fill="#00BCF1" d="M926.6,220.5c0.4,1.7,2,3.2,3.5,3.3c1.5,0.1,2.3-1.3,1.9-3c-0.5-1.7-2-3.2-3.5-3.3
			C927,217.4,926.1,218.8,926.6,220.5"/>
				<path fill="#00BCF1" d="M917.9,220.1c0.4,1.7,2,3.2,3.5,3.2c1.5,0.1,2.4-1.3,1.9-3c-0.4-1.7-2-3.2-3.5-3.2
			C918.4,217,917.5,218.4,917.9,220.1"/>
				<path fill="#00BCF1" d="M909.3,219.7c0.4,1.7,1.9,3.2,3.5,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-2-3.2-3.5-3.2
			C909.8,216.6,908.9,218,909.3,219.7"/>
				<path fill="#00BCF1" d="M900.7,219.3c0.4,1.7,1.9,3.2,3.4,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.2-3.4-3.2
			C901.2,216.3,900.3,217.6,900.7,219.3"/>
				<path fill="#00BCF1" d="M892,219c0.4,1.7,1.9,3.2,3.4,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.2-3.4-3.2
			C892.6,215.9,891.6,217.2,892,219"/>
				<path fill="#00BCF1" d="M822.4,216.9c0.2,1.7,1.6,3.1,3.1,3.2c1.5,0,2.6-1.3,2.3-3.1c-0.2-1.7-1.6-3.1-3.1-3.2
			C823.3,213.8,822.2,215.2,822.4,216.9"/>
				<path fill="#00BCF1" d="M813.7,216.8c0.2,1.7,1.6,3.1,3.1,3.2c1.5,0,2.6-1.3,2.4-3.1c-0.2-1.7-1.6-3.1-3.1-3.2
			C814.6,213.7,813.5,215.1,813.7,216.8"/>
				<path fill="#00BCF1" d="M805,216.6c0.2,1.7,1.5,3.1,3.1,3.1c1.5,0,2.6-1.4,2.4-3.1c-0.2-1.7-1.6-3.1-3.1-3.1
			C805.9,213.6,804.8,214.9,805,216.6"/>
				<path fill="#00BCF1" d="M796.2,216.5c0.2,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.4,2.5-3.1c-0.2-1.7-1.5-3.1-3-3.1
			C797.2,213.4,796.1,214.8,796.2,216.5"/>
				<path fill="#00BCF1" d="M787.5,216.4c0.1,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.4,2.5-3.1c-0.1-1.7-1.5-3.1-3-3.1
			C788.5,213.3,787.4,214.7,787.5,216.4"/>
				<path fill="#00BCF1" d="M778.7,216.4c0.1,1.7,1.4,3.1,3,3.1c1.5,0,2.7-1.4,2.5-3.1c-0.1-1.7-1.5-3.1-3-3.1
			C779.8,213.3,778.6,214.7,778.7,216.4"/>
				<path fill="#00BCF1" d="M761.2,216.3c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			C762.3,213.2,761.2,214.6,761.2,216.3"/>
				<path fill="#00BCF1" d="M1327.1,255.3c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
			C1326.1,252.3,1326,253.5,1327.1,255.3"/>
				<path fill="#00BCF1" d="M1312.2,252.7c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
			C1311.2,249.7,1311.1,251,1312.2,252.7"/>
				<path fill="#00BCF1" d="M1304.7,251.5c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.5-1,0.3-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
			C1303.7,248.5,1303.6,249.7,1304.7,251.5"/>
				<path fill="#00BCF1" d="M1297.1,250.2c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.8-3.1-3.4-4.4-3.6
			S1296,248.5,1297.1,250.2"/>
				<path fill="#00BCF1" d="M1243.5,242c1,1.8,3,3.3,4.3,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.8-3-3.3-4.3-3.5
			C1242.7,238.9,1242.5,240.2,1243.5,242"/>
				<path fill="#00BCF1" d="M1235.7,240.8c1,1.7,3,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.8-3-3.3-4.3-3.5
			C1235,237.8,1234.7,239.1,1235.7,240.8"/>
				<path fill="#00BCF1" d="M1227.9,239.7c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1227.2,236.7,1226.9,238,1227.9,239.7"/>
				<path fill="#00BCF1" d="M1220.1,238.6c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1219.4,235.6,1219.1,236.9,1220.1,238.6"/>
				<path fill="#00BCF1" d="M1212.2,237.5c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1211.5,234.5,1211.2,235.8,1212.2,237.5"/>
				<path fill="#00BCF1" d="M1204.4,236.5c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1203.7,233.5,1203.4,234.7,1204.4,236.5"/>
				<path fill="#00BCF1" d="M1196.4,235.4c1,1.7,2.9,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1195.8,232.4,1195.5,233.7,1196.4,235.4"/>
				<path fill="#00BCF1" d="M1188.5,234.4c1,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1187.9,231.4,1187.6,232.6,1188.5,234.4"/>
				<path fill="#00BCF1" d="M1180.6,233.4c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-1-1.7-2.8-3.3-4.2-3.5
			C1180,230.4,1179.6,231.6,1180.6,233.4"/>
				<path fill="#00BCF1" d="M1172.6,232.4c0.9,1.7,2.8,3.3,4.2,3.5s1.8-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.2-3.5
			C1172,229.4,1171.7,230.6,1172.6,232.4"/>
				<path fill="#00BCF1" d="M1164.6,231.4c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.2-3.5
			C1164,228.4,1163.7,229.6,1164.6,231.4"/>
				<path fill="#00BCF1" d="M1156.6,230.4c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.8-3.3-4.2-3.4
			C1156,227.4,1155.7,228.7,1156.6,230.4"/>
				<path fill="#00BCF1" d="M1148.5,229.5c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.8-3.3-4.1-3.4
			S1147.6,227.7,1148.5,229.5"/>
				<path fill="#00BCF1" d="M1140.4,228.5c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.9c-0.9-1.7-2.7-3.3-4.1-3.4
			C1140,225.5,1139.6,226.8,1140.4,228.5"/>
				<path fill="#00BCF1" d="M1132.3,227.6c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,1-2.9c-0.9-1.7-2.7-3.3-4.1-3.4
			C1131.9,224.6,1131.5,225.9,1132.3,227.6"/>
				<path fill="#00BCF1" d="M1124.2,226.7c0.8,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1-2.9c-0.9-1.7-2.7-3.3-4.1-3.4
			C1123.8,223.7,1123.4,225,1124.2,226.7"/>
				<path fill="#00BCF1" d="M1116.1,225.8c0.8,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.9-1.1,1-2.9c-0.8-1.7-2.7-3.3-4.1-3.4
			C1115.7,222.8,1115.3,224.1,1116.1,225.8"/>
				<path fill="#00BCF1" d="M1107.9,225c0.8,1.7,2.6,3.3,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.3-4-3.4
			C1107.6,222,1107.1,223.2,1107.9,225"/>
				<path fill="#00BCF1" d="M983.4,214.3c0.6,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.2-1.2,1.6-2.9c-0.6-1.7-2.2-3.2-3.7-3.3
			C983.5,211.2,982.8,212.6,983.4,214.3"/>
				<path fill="#00BCF1" d="M974.9,213.7c0.6,1.7,2.2,3.2,3.7,3.3c1.5,0.1,2.2-1.2,1.6-2.9c-0.6-1.7-2.2-3.2-3.7-3.3
			C975.1,210.7,974.4,212,974.9,213.7"/>
				<path fill="#00BCF1" d="M966.5,213.2c0.5,1.7,2.2,3.2,3.6,3.3c1.5,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.2-3.2-3.6-3.3
			C966.7,210.2,966,211.5,966.5,213.2"/>
				<path fill="#00BCF1" d="M958,212.7c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.1-3.2-3.6-3.3
			C958.3,209.6,957.5,210.9,958,212.7"/>
				<path fill="#00BCF1" d="M949.6,212.2c0.5,1.7,2.1,3.2,3.6,3.3c1.5,0.1,2.3-1.2,1.8-2.9c-0.5-1.7-2.1-3.2-3.6-3.3
			C949.8,209.1,949.1,210.5,949.6,212.2"/>
				<path fill="#00BCF1" d="M941.1,211.7c0.5,1.7,2.1,3.2,3.5,3.2c1.5,0.1,2.3-1.2,1.8-3c-0.5-1.7-2.1-3.2-3.5-3.2
			C941.4,208.7,940.6,210,941.1,211.7"/>
				<path fill="#00BCF1" d="M932.6,211.2c0.5,1.7,2,3.2,3.5,3.2c1.5,0.1,2.3-1.2,1.8-3c-0.5-1.7-2-3.2-3.5-3.2
			C932.9,208.2,932.1,209.5,932.6,211.2"/>
				<path fill="#00BCF1" d="M924.1,210.8c0.4,1.7,2,3.2,3.5,3.2c1.5,0.1,2.3-1.3,1.9-3c-0.5-1.7-2-3.2-3.5-3.2
			C924.5,207.8,923.6,209.1,924.1,210.8"/>
				<path fill="#00BCF1" d="M915.6,210.4c0.4,1.7,2,3.2,3.5,3.2s2.3-1.3,1.9-3c-0.4-1.7-2-3.1-3.5-3.2
			C916,207.4,915.1,208.7,915.6,210.4"/>
				<path fill="#00BCF1" d="M907,210c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.4-1.3,1.9-3c-0.4-1.7-1.9-3.1-3.4-3.2
			C907.5,207,906.6,208.3,907,210"/>
				<path fill="#00BCF1" d="M898.5,209.6c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.1-3.4-3.2
			C899,206.6,898.1,207.9,898.5,209.6"/>
				<path fill="#00BCF1" d="M889.9,209.3c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.1-3.4-3.2
			C890.5,206.2,889.6,207.6,889.9,209.3"/>
				<path fill="#00BCF1" d="M881.4,209c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.1-3.3-3.2
			C881.9,205.9,881,207.3,881.4,209"/>
				<path fill="#00BCF1" d="M872.8,208.6c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0.1,2.4-1.3,2.1-3c-0.3-1.7-1.8-3.1-3.3-3.2
			C873.4,205.6,872.5,206.9,872.8,208.6"/>
				<path fill="#00BCF1" d="M864.2,208.4c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0,2.4-1.3,2.1-3c-0.3-1.7-1.8-3.1-3.3-3.2
			C864.9,205.3,863.9,206.7,864.2,208.4"/>
				<path fill="#00BCF1" d="M829.9,207.4c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.6-3.1-3.1-3.1
			C830.6,204.4,829.6,205.7,829.9,207.4"/>
				<path fill="#00BCF1" d="M821.2,207.3c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.6-3.1-3.1-3.1
			C822.1,204.2,821,205.6,821.2,207.3"/>
				<path fill="#00BCF1" d="M812.6,207.1c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.6-1.3,2.3-3c-0.2-1.7-1.6-3.1-3.1-3.1
			C813.5,204.1,812.4,205.4,812.6,207.1"/>
				<path fill="#00BCF1" d="M804,207c0.2,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.3,2.4-3c-0.2-1.7-1.5-3.1-3-3.1
			C804.9,203.9,803.8,205.3,804,207"/>
				<path fill="#00BCF1" d="M795.4,206.9c0.2,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.3,2.4-3c-0.2-1.7-1.5-3.1-3-3.1
			C796.3,203.8,795.2,205.2,795.4,206.9"/>
				<path fill="#00BCF1" d="M786.7,206.8c0.1,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.4,2.5-3.1c-0.1-1.7-1.5-3.1-3-3.1
			C787.7,203.7,786.6,205.1,786.7,206.8"/>
				<path fill="#00BCF1" d="M778.1,206.7c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.6-1.4,2.5-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			C779.1,203.6,778,205,778.1,206.7"/>
				<path fill="#00BCF1" d="M769.5,206.7c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.6-1.4,2.5-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			C770.5,203.6,769.4,205,769.5,206.7"/>
				<path fill="#00BCF1" d="M760.8,206.6c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.7-1.4,2.6-3.1c-0.1-1.7-1.4-3.1-2.9-3.1
			C761.9,203.6,760.7,204.9,760.8,206.6"/>
				<path fill="#00BCF1" d="M1298.4,241.6c1.1,1.8,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.8-3.1-3.3-4.4-3.6
			C1297.4,238.6,1297.3,239.8,1298.4,241.6"/>
				<path fill="#00BCF1" d="M1290.9,240.3c1.1,1.8,3.1,3.3,4.4,3.6c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.8-3.1-3.3-4.4-3.6
			C1289.9,237.3,1289.8,238.6,1290.9,240.3"/>
				<path fill="#00BCF1" d="M1283.4,239.1c1.1,1.8,3,3.3,4.4,3.6c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.8-3-3.3-4.4-3.6
			S1282.3,237.4,1283.4,239.1"/>
				<path fill="#00BCF1" d="M1237.6,232.1c1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.6-1.1,0.5-2.8c-1-1.7-3-3.3-4.3-3.5
			C1236.8,229.1,1236.6,230.3,1237.6,232.1"/>
				<path fill="#00BCF1" d="M1229.9,231c1,1.7,2.9,3.3,4.3,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-3-3.3-4.3-3.5
			C1229.1,228,1228.9,229.2,1229.9,231"/>
				<path fill="#00BCF1" d="M1222.2,229.9c1,1.7,2.9,3.3,4.3,3.5s1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1221.4,226.9,1221.2,228.1,1222.2,229.9"/>
				<path fill="#00BCF1" d="M1214.4,228.8c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1213.7,225.8,1213.4,227,1214.4,228.8"/>
				<path fill="#00BCF1" d="M1206.6,227.7c1,1.7,2.9,3.3,4.3,3.5c1.4,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.3-3.5
			C1205.9,224.7,1205.6,225.9,1206.6,227.7"/>
				<path fill="#00BCF1" d="M1198.8,226.6c1,1.7,2.9,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1198.1,223.6,1197.8,224.9,1198.8,226.6"/>
				<path fill="#00BCF1" d="M1191,225.6c1,1.7,2.9,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1190.3,222.6,1190,223.8,1191,225.6"/>
				<path fill="#00BCF1" d="M1183.1,224.6c1,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.8-3.3-4.2-3.5
			C1182.5,221.6,1182.2,222.8,1183.1,224.6"/>
				<path fill="#00BCF1" d="M1175.2,223.5c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-1-1.7-2.8-3.3-4.2-3.5
			C1174.6,220.5,1174.3,221.8,1175.2,223.5"/>
				<path fill="#00BCF1" d="M1167.3,222.5c0.9,1.7,2.8,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.2-3.4
			C1166.8,219.6,1166.4,220.8,1167.3,222.5"/>
				<path fill="#00BCF1" d="M1159.4,221.6c0.9,1.7,2.8,3.3,4.2,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.2-3.4
			C1158.9,218.6,1158.5,219.8,1159.4,221.6"/>
				<path fill="#00BCF1" d="M1151.5,220.6c0.9,1.7,2.8,3.3,4.1,3.4s1.8-1.1,0.9-2.8c-0.9-1.7-2.8-3.3-4.1-3.4
			C1150.9,217.6,1150.5,218.9,1151.5,220.6"/>
				<path fill="#00BCF1" d="M1143.5,219.7c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.3-4.1-3.4
			C1143,216.7,1142.6,217.9,1143.5,219.7"/>
				<path fill="#00BCF1" d="M1135.5,218.7c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.3-4.1-3.4
			C1135,215.7,1134.6,217,1135.5,218.7"/>
				<path fill="#00BCF1" d="M1127.5,217.8c0.9,1.7,2.7,3.3,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1127,214.8,1126.6,216.1,1127.5,217.8"/>
				<path fill="#00BCF1" d="M1119.4,216.9c0.8,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.8-1.1,1-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1119,213.9,1118.6,215.2,1119.4,216.9"/>
				<path fill="#00BCF1" d="M1111.4,216.1c0.8,1.7,2.6,3.2,4,3.4c1.4,0.2,1.9-1.1,1-2.8c-0.8-1.7-2.7-3.2-4-3.4
			S1110.6,214.3,1111.4,216.1"/>
				<path fill="#00BCF1" d="M1103.3,215.2c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.9-1.1,1-2.9c-0.8-1.7-2.6-3.2-4-3.4
			C1103,212.2,1102.5,213.5,1103.3,215.2"/>
				<path fill="#00BCF1" d="M1095.2,214.4c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.9-1.1,1.1-2.9c-0.8-1.7-2.6-3.2-4-3.4
			C1094.9,211.4,1094.4,212.6,1095.2,214.4"/>
				<path fill="#00BCF1" d="M996.8,205.8c0.6,1.7,2.3,3.2,3.7,3.3c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.3-3.2-3.7-3.3
			C996.8,202.8,996.2,204.1,996.8,205.8"/>
				<path fill="#00BCF1" d="M988.5,205.2c0.6,1.7,2.2,3.2,3.7,3.3c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.2-3.7-3.3
			C988.6,202.2,987.9,203.5,988.5,205.2"/>
				<path fill="#00BCF1" d="M980.2,204.6c0.6,1.7,2.2,3.2,3.7,3.3c1.4,0.1,2.2-1.2,1.6-2.9c-0.6-1.7-2.2-3.2-3.7-3.3
			C980.3,201.6,979.6,202.9,980.2,204.6"/>
				<path fill="#00BCF1" d="M971.8,204.1c0.6,1.7,2.2,3.2,3.6,3.3c1.5,0.1,2.2-1.2,1.6-2.9c-0.6-1.7-2.2-3.2-3.6-3.2
			C972,201.1,971.3,202.4,971.8,204.1"/>
				<path fill="#00BCF1" d="M963.5,203.5c0.5,1.7,2.1,3.2,3.6,3.2c1.5,0.1,2.2-1.2,1.6-2.9c-0.5-1.7-2.2-3.1-3.6-3.2
			C963.7,200.5,962.9,201.8,963.5,203.5"/>
				<path fill="#00BCF1" d="M955.1,203c0.5,1.7,2.1,3.1,3.6,3.2c1.5,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.1-3.1-3.6-3.2
			C955.4,200,954.6,201.3,955.1,203"/>
				<path fill="#00BCF1" d="M946.7,202.5c0.5,1.7,2.1,3.1,3.5,3.2c1.5,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C947,199.5,946.3,200.8,946.7,202.5"/>
				<path fill="#00BCF1" d="M938.4,202.1c0.5,1.7,2.1,3.1,3.5,3.2c1.5,0.1,2.2-1.2,1.8-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C938.7,199.1,937.9,200.4,938.4,202.1"/>
				<path fill="#00BCF1" d="M930,201.6c0.5,1.7,2,3.1,3.5,3.2c1.5,0.1,2.3-1.2,1.8-2.9c-0.5-1.7-2-3.1-3.5-3.2
			C930.3,198.6,929.5,199.9,930,201.6"/>
				<path fill="#00BCF1" d="M921.6,201.2c0.4,1.7,2,3.1,3.5,3.2c1.5,0.1,2.3-1.2,1.8-2.9c-0.5-1.7-2-3.1-3.5-3.2
			C921.9,198.2,921.1,199.5,921.6,201.2"/>
				<path fill="#00BCF1" d="M913.2,200.8c0.4,1.7,2,3.1,3.4,3.2c1.5,0.1,2.3-1.2,1.9-2.9c-0.4-1.7-2-3.1-3.4-3.2
			C913.6,197.8,912.7,199.1,913.2,200.8"/>
				<path fill="#00BCF1" d="M904.7,200.4c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.3-1.3,1.9-2.9c-0.4-1.7-1.9-3.1-3.4-3.2
			C905.2,197.4,904.3,198.7,904.7,200.4"/>
				<path fill="#00BCF1" d="M896.3,200c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.3-1.3,1.9-3c-0.4-1.7-1.9-3.1-3.4-3.2
			C896.8,197,895.9,198.3,896.3,200"/>
				<path fill="#00BCF1" d="M887.9,199.7c0.4,1.7,1.9,3.1,3.3,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.9-3.1-3.3-3.2
			C888.4,196.7,887.5,198,887.9,199.7"/>
				<path fill="#00BCF1" d="M879.4,199.4c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0.1,2.4-1.3,2-3c-0.4-1.7-1.8-3.1-3.3-3.2
			S879.1,197.7,879.4,199.4"/>
				<path fill="#00BCF1" d="M870.9,199.1c0.3,1.7,1.8,3.1,3.3,3.2c1.5,0.1,2.4-1.3,2-3c-0.3-1.7-1.8-3.1-3.3-3.1
			C871.5,196,870.6,197.4,870.9,199.1"/>
				<path fill="#00BCF1" d="M862.5,198.8c0.3,1.7,1.8,3.1,3.2,3.2c1.5,0,2.4-1.3,2.1-3c-0.3-1.7-1.8-3.1-3.2-3.1
			C863.1,195.8,862.2,197.1,862.5,198.8"/>
				<path fill="#00BCF1" d="M854,198.5c0.3,1.7,1.7,3.1,3.2,3.1c1.5,0,2.4-1.3,2.1-3c-0.3-1.7-1.7-3.1-3.2-3.1
			C854.7,195.5,853.7,196.8,854,198.5"/>
				<path fill="#00BCF1" d="M845.5,198.3c0.3,1.7,1.7,3.1,3.2,3.1c1.5,0,2.4-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.2-3.1
			C846.2,195.3,845.3,196.6,845.5,198.3"/>
				<path fill="#00BCF1" d="M837,198.1c0.3,1.7,1.7,3.1,3.1,3.1c1.5,0,2.5-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.1-3.1
			C837.8,195,836.8,196.4,837,198.1"/>
				<path fill="#00BCF1" d="M828.5,197.9c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.5-1.3,2.2-3c-0.2-1.7-1.6-3.1-3.1-3.1
			C829.3,194.8,828.3,196.2,828.5,197.9"/>
				<path fill="#00BCF1" d="M820,197.7c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.6-3.1-3.1-3.1
			C820.8,194.7,819.8,196,820,197.7"/>
				<path fill="#00BCF1" d="M811.5,197.5c0.2,1.7,1.6,3.1,3,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.6-3.1-3-3.1
			C812.4,194.5,811.3,195.9,811.5,197.5"/>
				<path fill="#00BCF1" d="M803,197.4c0.2,1.7,1.5,3.1,3,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3.1-3-3.1
			C803.9,194.4,802.8,195.7,803,197.4"/>
				<path fill="#00BCF1" d="M794.5,197.3c0.2,1.7,1.5,3.1,3,3.1c1.5,0,2.6-1.3,2.4-3c-0.2-1.7-1.5-3.1-3-3.1
			C795.4,194.3,794.3,195.6,794.5,197.3"/>
				<path fill="#00BCF1" d="M786,197.2c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.6-1.3,2.4-3c-0.1-1.7-1.5-3.1-2.9-3.1
			C786.9,194.2,785.8,195.5,786,197.2"/>
				<path fill="#00BCF1" d="M777.4,197.1c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.6-1.3,2.5-3c-0.1-1.7-1.4-3.1-2.9-3.1
			C778.4,194.1,777.3,195.5,777.4,197.1"/>
				<path fill="#00BCF1" d="M768.9,197.1c0.1,1.7,1.4,3.1,2.9,3.1c1.5,0,2.6-1.4,2.5-3c-0.1-1.7-1.4-3.1-2.9-3.1
			S768.8,195.4,768.9,197.1"/>
				<path fill="#00BCF1" d="M760.4,197.1c0.1,1.7,1.3,3.1,2.8,3.1c1.5,0,2.6-1.4,2.5-3c-0.1-1.7-1.4-3-2.8-3.1
			C761.4,194,760.3,195.4,760.4,197.1"/>
				<path fill="#00BCF1" d="M751.9,197.1c0.1,1.7,1.3,3.1,2.8,3.1c1.5,0,2.6-1.4,2.6-3.1c-0.1-1.7-1.3-3-2.8-3
			C752.9,194,751.8,195.4,751.9,197.1"/>
				<path fill="#00BCF1" d="M1329,238c1.1,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.4-1,0.2-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1327.9,235,1327.8,236.3,1329,238"/>
				<path fill="#00BCF1" d="M1299.5,232.9c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.8-3.1-3.3-4.4-3.6
			C1298.5,229.9,1298.4,231.2,1299.5,232.9"/>
				<path fill="#00BCF1" d="M1292.1,231.7c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.4-3.6
			C1291.1,228.7,1291,229.9,1292.1,231.7"/>
				<path fill="#00BCF1" d="M1284.6,230.4c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1283.7,227.5,1283.5,228.7,1284.6,230.4"/>
				<path fill="#00BCF1" d="M1208.7,218.9c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1208,216,1207.7,217.2,1208.7,218.9"/>
				<path fill="#00BCF1" d="M1201,217.9c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1200.3,214.9,1200,216.1,1201,217.9"/>
				<path fill="#00BCF1" d="M1193.3,216.8c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1192.6,213.8,1192.3,215.1,1193.3,216.8"/>
				<path fill="#00BCF1" d="M1185.5,215.8c1,1.7,2.9,3.3,4.2,3.5c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1184.8,212.8,1184.5,214.1,1185.5,215.8"/>
				<path fill="#00BCF1" d="M1177.7,214.8c1,1.7,2.8,3.3,4.2,3.4c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.8-3.3-4.2-3.4
			C1177.1,211.8,1176.7,213,1177.7,214.8"/>
				<path fill="#00BCF1" d="M1169.9,213.8c0.9,1.7,2.8,3.3,4.2,3.4c1.4,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.8-3.3-4.2-3.4
			C1169.3,210.8,1169,212,1169.9,213.8"/>
				<path fill="#00BCF1" d="M1162.1,212.8c0.9,1.7,2.8,3.3,4.2,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.2-3.4
			C1161.5,209.8,1161.1,211.1,1162.1,212.8"/>
				<path fill="#00BCF1" d="M1154.2,211.8c0.9,1.7,2.8,3.3,4.1,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.3-4.1-3.4
			C1153.6,208.8,1153.3,210.1,1154.2,211.8"/>
				<path fill="#00BCF1" d="M1146.3,210.9c0.9,1.7,2.8,3.2,4.1,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1145.8,207.9,1145.4,209.1,1146.3,210.9"/>
				<path fill="#00BCF1" d="M1138.4,209.9c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1137.9,207,1137.6,208.2,1138.4,209.9"/>
				<path fill="#00BCF1" d="M1130.5,209c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1130.1,206,1129.7,207.3,1130.5,209"/>
				<path fill="#00BCF1" d="M1122.6,208.1c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1122.1,205.1,1121.7,206.4,1122.6,208.1"/>
				<path fill="#00BCF1" d="M1114.6,207.2c0.8,1.7,2.7,3.2,4,3.4c1.4,0.2,1.8-1.1,1-2.8c-0.9-1.7-2.7-3.2-4-3.4
			C1114.2,204.2,1113.8,205.5,1114.6,207.2"/>
				<path fill="#00BCF1" d="M1106.7,206.4c0.8,1.7,2.6,3.2,4,3.4c1.4,0.2,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.4
			C1106.3,203.4,1105.8,204.6,1106.7,206.4"/>
				<path fill="#00BCF1" d="M1098.7,205.5c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.4
			C1098.3,202.5,1097.9,203.8,1098.7,205.5"/>
				<path fill="#00BCF1" d="M1090.7,204.7c0.8,1.7,2.6,3.2,4,3.4c1.4,0.1,1.9-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.4
			C1090.4,201.7,1089.9,203,1090.7,204.7"/>
				<path fill="#00BCF1" d="M1082.7,203.9c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.6-3.2-4-3.3
			C1082.4,200.9,1081.9,202.2,1082.7,203.9"/>
				<path fill="#00BCF1" d="M1074.6,203.1c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1074.3,200.1,1073.8,201.4,1074.6,203.1"/>
				<path fill="#00BCF1" d="M1066.6,202.3c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1066.3,199.3,1065.8,200.6,1066.6,202.3"/>
				<path fill="#00BCF1" d="M1058.5,201.5c0.7,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.2-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1058.3,198.6,1057.7,199.8,1058.5,201.5"/>
				<path fill="#00BCF1" d="M1050.4,200.8c0.7,1.7,2.5,3.2,3.9,3.3c1.4,0.1,2-1.1,1.2-2.9c-0.7-1.7-2.5-3.2-3.9-3.3
			C1050.2,197.8,1049.7,199.1,1050.4,200.8"/>
				<path fill="#00BCF1" d="M1042.3,200.1c0.7,1.7,2.4,3.2,3.8,3.3c1.4,0.1,2-1.2,1.3-2.9c-0.7-1.7-2.4-3.2-3.8-3.3
			C1042.1,197.1,1041.6,198.4,1042.3,200.1"/>
				<path fill="#00BCF1" d="M1034.2,199.4c0.7,1.7,2.4,3.2,3.8,3.3s2-1.2,1.3-2.9c-0.7-1.7-2.4-3.2-3.8-3.3
			C1034.1,196.4,1033.5,197.7,1034.2,199.4"/>
				<path fill="#00BCF1" d="M1026,198.7c0.7,1.7,2.4,3.2,3.8,3.3c1.4,0.1,2-1.2,1.3-2.9c-0.7-1.7-2.4-3.2-3.8-3.3
			C1026,195.7,1025.4,197,1026,198.7"/>
				<path fill="#00BCF1" d="M1017.9,198c0.7,1.7,2.3,3.2,3.8,3.3c1.4,0.1,2-1.2,1.4-2.9c-0.7-1.7-2.4-3.2-3.8-3.3
			C1017.8,195.1,1017.2,196.4,1017.9,198"/>
				<path fill="#00BCF1" d="M1009.7,197.4c0.6,1.7,2.3,3.2,3.7,3.3c1.4,0.1,2-1.2,1.4-2.9c-0.7-1.7-2.3-3.2-3.7-3.3
			C1009.7,194.4,1009.1,195.7,1009.7,197.4"/>
				<path fill="#00BCF1" d="M1001.5,196.8c0.6,1.7,2.3,3.2,3.7,3.3c1.4,0.1,2.1-1.2,1.4-2.9c-0.6-1.7-2.3-3.1-3.7-3.3
			C1001.6,193.8,1000.9,195.1,1001.5,196.8"/>
				<path fill="#00BCF1" d="M993.3,196.2c0.6,1.7,2.3,3.1,3.7,3.3c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.3-3.1-3.7-3.2
			C993.4,193.2,992.7,194.5,993.3,196.2"/>
				<path fill="#00BCF1" d="M985.1,195.6c0.6,1.7,2.2,3.1,3.7,3.2c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.1-3.7-3.2
			C985.2,192.6,984.6,193.9,985.1,195.6"/>
				<path fill="#00BCF1" d="M976.9,195c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.1-3.6-3.2
			C977,192.1,976.3,193.3,976.9,195"/>
				<path fill="#00BCF1" d="M968.7,194.5c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.6-2.9c-0.6-1.7-2.2-3.1-3.6-3.2
			C968.8,191.5,968.1,192.8,968.7,194.5"/>
				<path fill="#00BCF1" d="M960.4,194c0.5,1.7,2.1,3.1,3.6,3.2c1.4,0.1,2.2-1.2,1.6-2.9s-2.1-3.1-3.6-3.2
			C960.6,191,959.9,192.3,960.4,194"/>
				<path fill="#00BCF1" d="M952.2,193.5c0.5,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.2-1.2,1.6-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C952.4,190.5,951.7,191.8,952.2,193.5"/>
				<path fill="#00BCF1" d="M943.9,193c0.5,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C944.2,190,943.4,191.3,943.9,193"/>
				<path fill="#00BCF1" d="M935.6,192.5c0.5,1.7,2,3.1,3.5,3.2c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C935.9,189.5,935.2,190.8,935.6,192.5"/>
				<path fill="#00BCF1" d="M927.4,192.1c0.5,1.7,2,3.1,3.5,3.2c1.4,0.1,2.2-1.2,1.8-2.9c-0.5-1.7-2-3.1-3.5-3.2
			C927.7,189.1,926.9,190.4,927.4,192.1"/>
				<path fill="#00BCF1" d="M919.1,191.6c0.4,1.7,2,3.1,3.4,3.2c1.4,0.1,2.2-1.2,1.8-2.9c-0.5-1.7-2-3.1-3.4-3.2
			C919.4,188.7,918.6,190,919.1,191.6"/>
				<path fill="#00BCF1" d="M910.8,191.2c0.4,1.7,1.9,3.1,3.4,3.2c1.4,0.1,2.3-1.2,1.8-2.9c-0.4-1.7-2-3.1-3.4-3.2
			C911.1,188.3,910.3,189.6,910.8,191.2"/>
				<path fill="#00BCF1" d="M902.4,190.9c0.4,1.7,1.9,3.1,3.4,3.2c1.5,0.1,2.3-1.2,1.9-2.9c-0.4-1.7-1.9-3.1-3.4-3.2
			C902.9,187.9,902,189.2,902.4,190.9"/>
				<path fill="#00BCF1" d="M894.1,190.5c0.4,1.7,1.9,3.1,3.3,3.2c1.5,0.1,2.3-1.3,1.9-2.9c-0.4-1.7-1.9-3.1-3.3-3.1
			C894.6,187.5,893.7,188.8,894.1,190.5"/>
				<path fill="#00BCF1" d="M885.8,190.2c0.4,1.7,1.8,3.1,3.3,3.1c1.5,0.1,2.3-1.3,1.9-2.9c-0.4-1.7-1.9-3.1-3.3-3.1
			C886.3,187.2,885.4,188.5,885.8,190.2"/>
				<path fill="#00BCF1" d="M877.4,189.8c0.3,1.7,1.8,3.1,3.3,3.1c1.5,0.1,2.3-1.3,2-2.9c-0.4-1.7-1.8-3.1-3.3-3.1
			C878,186.9,877.1,188.2,877.4,189.8"/>
				<path fill="#00BCF1" d="M869.1,189.5c0.3,1.7,1.8,3.1,3.2,3.1c1.5,0.1,2.4-1.3,2-2.9c-0.3-1.7-1.8-3.1-3.2-3.1
			C869.7,186.6,868.8,187.9,869.1,189.5"/>
				<path fill="#00BCF1" d="M860.7,189.3c0.3,1.7,1.7,3.1,3.2,3.1c1.5,0,2.4-1.3,2.1-2.9c-0.3-1.7-1.8-3.1-3.2-3.1
			C861.3,186.3,860.4,187.6,860.7,189.3"/>
				<path fill="#00BCF1" d="M852.4,189c0.3,1.7,1.7,3.1,3.2,3.1c1.5,0,2.4-1.3,2.1-3c-0.3-1.7-1.7-3.1-3.2-3.1
			C853,186,852.1,187.3,852.4,189"/>
				<path fill="#00BCF1" d="M844,188.8c0.3,1.7,1.7,3.1,3.1,3.1c1.5,0,2.4-1.3,2.1-3c-0.3-1.7-1.7-3.1-3.1-3.1
			C844.7,185.8,843.7,187.1,844,188.8"/>
				<path fill="#00BCF1" d="M835.6,188.6c0.3,1.7,1.6,3.1,3.1,3.1c1.5,0,2.4-1.3,2.2-3c-0.3-1.7-1.7-3.1-3.1-3.1
			C836.3,185.6,835.4,186.9,835.6,188.6"/>
				<path fill="#00BCF1" d="M827.2,188.4c0.2,1.7,1.6,3.1,3.1,3.1c1.5,0,2.4-1.3,2.2-3s-1.6-3-3.1-3.1
			C828,185.4,827,186.7,827.2,188.4"/>
				<path fill="#00BCF1" d="M818.8,188.2c0.2,1.7,1.6,3.1,3,3.1c1.5,0,2.5-1.3,2.2-3c-0.2-1.7-1.6-3-3-3.1S818.6,186.5,818.8,188.2" />
				<path fill="#00BCF1" d="M810.4,188c0.2,1.7,1.5,3,3,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3-3-3.1
			C811.3,185,810.2,186.4,810.4,188"/>
				<path fill="#00BCF1" d="M802,187.9c0.2,1.7,1.5,3,3,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3-3-3.1
			C802.9,184.9,801.9,186.2,802,187.9"/>
				<path fill="#00BCF1" d="M793.6,187.8c0.2,1.7,1.5,3,2.9,3.1c1.5,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3-2.9-3.1
			C794.5,184.8,793.5,186.1,793.6,187.8"/>
				<path fill="#00BCF1" d="M785.2,187.7c0.1,1.7,1.4,3,2.9,3.1c1.5,0,2.5-1.3,2.4-3c-0.1-1.7-1.4-3-2.9-3
			C786.2,184.7,785.1,186.1,785.2,187.7"/>
				<path fill="#00BCF1" d="M776.8,187.7c0.1,1.7,1.4,3,2.9,3c1.5,0,2.6-1.3,2.4-3c-0.1-1.7-1.4-3-2.9-3
			C777.8,184.6,776.7,186,776.8,187.7"/>
				<path fill="#00BCF1" d="M768.4,187.6c0.1,1.7,1.4,3,2.8,3c1.5,0,2.6-1.3,2.5-3c-0.1-1.7-1.4-3-2.8-3
			C769.4,184.6,768.3,185.9,768.4,187.6"/>
				<path fill="#00BCF1" d="M760,187.6c0.1,1.7,1.3,3,2.8,3c1.5,0,2.6-1.4,2.5-3c-0.1-1.7-1.3-3-2.8-3
			C761,184.6,759.9,185.9,760,187.6"/>
				<path fill="#00BCF1" d="M751.5,187.6c0.1,1.7,1.3,3,2.8,3c1.5,0,2.6-1.4,2.5-3c-0.1-1.7-1.3-3-2.8-3
			C752.6,184.6,751.5,185.9,751.5,187.6"/>
				<path fill="#00BCF1" d="M743.1,187.6c0,1.7,1.3,3,2.7,3c1.5,0,2.6-1.4,2.6-3c0-1.7-1.3-3-2.7-3
			C744.2,184.6,743.1,185.9,743.1,187.6"/>
				<path fill="#00BCF1" d="M1379.7,238.8c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.2-1,0.1-2.7c-1.2-1.8-3.1-3.4-4.4-3.6
			C1378.6,235.8,1378.6,237.1,1379.7,238.8"/>
				<path fill="#00BCF1" d="M1372.7,237.5c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.7c-1.2-1.8-3.1-3.4-4.4-3.6
			C1371.5,234.5,1371.5,235.7,1372.7,237.5"/>
				<path fill="#00BCF1" d="M1358.5,234.7c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1357.3,231.7,1357.3,233,1358.5,234.7"/>
				<path fill="#00BCF1" d="M1344.1,232c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1343,229.1,1343,230.3,1344.1,232"/>
				<path fill="#00BCF1" d="M1329.7,229.4c1.2,1.8,3.1,3.4,4.4,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1328.6,226.4,1328.6,227.7,1329.7,229.4"/>
				<path fill="#00BCF1" d="M1322.5,228.1c1.1,1.7,3.1,3.4,4.4,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1321.4,225.1,1321.3,226.4,1322.5,228.1"/>
				<path fill="#00BCF1" d="M1315.2,226.8c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.8-3.1-3.3-4.4-3.6
			C1314.1,223.8,1314,225.1,1315.2,226.8"/>
				<path fill="#00BCF1" d="M1307.9,225.5c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.4-3.6
			C1306.9,222.6,1306.7,223.8,1307.9,225.5"/>
				<path fill="#00BCF1" d="M1300.5,224.3c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.3-3.6
			C1299.5,221.3,1299.4,222.5,1300.5,224.3"/>
				<path fill="#00BCF1" d="M1293.2,223c1.1,1.7,3.1,3.3,4.3,3.6c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1292.2,220.1,1292.1,221.3,1293.2,223"/>
				<path fill="#00BCF1" d="M1271,219.4c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1270,216.4,1269.9,217.6,1271,219.4"/>
				<path fill="#00BCF1" d="M1256,217c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1255.1,214,1255,215.3,1256,217"/>
				<path fill="#00BCF1" d="M1210.7,210.3c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1209.9,207.3,1209.7,208.5,1210.7,210.3"/>
				<path fill="#00BCF1" d="M1203,209.2c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1202.3,206.2,1202,207.5,1203,209.2"/>
				<path fill="#00BCF1" d="M1195.4,208.1c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1194.7,205.2,1194.4,206.4,1195.4,208.1"/>
				<path fill="#00BCF1" d="M1187.7,207.1c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1187,204.1,1186.7,205.4,1187.7,207.1"/>
				<path fill="#00BCF1" d="M1180,206.1c1,1.7,2.8,3.3,4.2,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1179.3,203.1,1179,204.3,1180,206.1"/>
				<path fill="#00BCF1" d="M1172.3,205c1,1.7,2.8,3.3,4.2,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-1-1.7-2.8-3.2-4.2-3.4
			C1171.6,202.1,1171.3,203.3,1172.3,205"/>
				<path fill="#00BCF1" d="M1164.6,204.1c0.9,1.7,2.8,3.2,4.2,3.4c1.3,0.2,1.7-1.1,0.7-2.8c-1-1.7-2.8-3.2-4.1-3.4
			C1163.9,201.1,1163.6,202.3,1164.6,204.1"/>
				<path fill="#00BCF1" d="M1156.8,203.1c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.7-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1156.2,200.1,1155.9,201.4,1156.8,203.1"/>
				<path fill="#00BCF1" d="M1149,202.1c0.9,1.7,2.8,3.2,4.1,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1148.4,199.2,1148.1,200.4,1149,202.1"/>
				<path fill="#00BCF1" d="M1141.2,201.2c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1140.7,198.2,1140.3,199.5,1141.2,201.2"/>
				<path fill="#00BCF1" d="M1133.4,200.2c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1132.9,197.3,1132.5,198.5,1133.4,200.2"/>
				<path fill="#00BCF1" d="M1125.6,199.3c0.9,1.7,2.7,3.2,4.1,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1125.1,196.4,1124.7,197.6,1125.6,199.3"/>
				<path fill="#00BCF1" d="M1117.7,198.4c0.9,1.7,2.7,3.2,4,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4-3.4
			C1117.3,195.5,1116.9,196.7,1117.7,198.4"/>
				<path fill="#00BCF1" d="M1109.8,197.6c0.8,1.7,2.6,3.2,4,3.4c1.4,0.2,1.8-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4-3.4
			C1109.4,194.6,1109,195.9,1109.8,197.6"/>
				<path fill="#00BCF1" d="M1102,196.7c0.8,1.7,2.6,3.2,4,3.3s1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.3
			C1101.6,193.8,1101.1,195,1102,196.7"/>
				<path fill="#00BCF1" d="M1094.1,195.9c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.3
			C1093.7,192.9,1093.2,194.2,1094.1,195.9"/>
				<path fill="#00BCF1" d="M1086.1,195.1c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-4-3.3
			C1085.8,192.1,1085.3,193.4,1086.1,195.1"/>
				<path fill="#00BCF1" d="M1078.2,194.3c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.6-3.2-3.9-3.3
			C1077.9,191.3,1077.4,192.6,1078.2,194.3"/>
				<path fill="#00BCF1" d="M1070.2,193.5c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1070,190.5,1069.5,191.8,1070.2,193.5"/>
				<path fill="#00BCF1" d="M1062.3,192.7c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1062,189.8,1061.5,191,1062.3,192.7"/>
				<path fill="#00BCF1" d="M1054.3,192c0.7,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.9-1.1,1.2-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			S1053.6,190.3,1054.3,192"/>
				<path fill="#00BCF1" d="M1046.3,191.2c0.7,1.7,2.4,3.2,3.8,3.3c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.5-3.2-3.8-3.3
			C1046.1,188.3,1045.6,189.5,1046.3,191.2"/>
				<path fill="#00BCF1" d="M1038.3,190.5c0.7,1.7,2.4,3.2,3.8,3.3c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.8-3.3
			C1038.1,187.6,1037.6,188.8,1038.3,190.5"/>
				<path fill="#00BCF1" d="M1030.3,189.8c0.7,1.7,2.4,3.1,3.8,3.3c1.4,0.1,2-1.2,1.3-2.8c-0.7-1.7-2.4-3.1-3.8-3.3
			C1030.1,186.9,1029.6,188.1,1030.3,189.8"/>
				<path fill="#00BCF1" d="M1022.2,189.2c0.7,1.7,2.4,3.1,3.8,3.3c1.4,0.1,2-1.2,1.3-2.8c-0.7-1.7-2.4-3.1-3.8-3.3
			C1022.1,186.2,1021.5,187.5,1022.2,189.2"/>
				<path fill="#00BCF1" d="M1014.2,188.5c0.7,1.7,2.3,3.1,3.7,3.3c1.4,0.1,2-1.2,1.3-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1014.1,185.6,1013.5,186.8,1014.2,188.5"/>
				<path fill="#00BCF1" d="M1006.1,187.9c0.6,1.7,2.3,3.1,3.7,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1006.1,184.9,1005.4,186.2,1006.1,187.9"/>
				<path fill="#00BCF1" d="M998,187.3c0.6,1.7,2.3,3.1,3.7,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.7-2.3-3.1-3.7-3.2
			C998,184.3,997.4,185.6,998,187.3"/>
				<path fill="#00BCF1" d="M989.9,186.7c0.6,1.7,2.2,3.1,3.7,3.2c1.4,0.1,2.1-1.2,1.4-2.9s-2.3-3.1-3.7-3.2
			C989.9,183.7,989.3,185,989.9,186.7"/>
				<path fill="#00BCF1" d="M981.8,186.1c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.1-3.6-3.2
			C981.9,183.1,981.2,184.4,981.8,186.1"/>
				<path fill="#00BCF1" d="M973.7,185.5c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.1-3.6-3.2
			C973.8,182.6,973.1,183.9,973.7,185.5"/>
				<path fill="#00BCF1" d="M965.6,185c0.6,1.7,2.1,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.5-2.9c-0.6-1.7-2.2-3.1-3.6-3.2
			C965.7,182,965,183.3,965.6,185"/>
				<path fill="#00BCF1" d="M957.4,184.5c0.5,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.1-1.2,1.6-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C957.6,181.5,956.9,182.8,957.4,184.5"/>
				<path fill="#00BCF1" d="M949.3,184c0.5,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.1-1.2,1.6-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C949.5,181,948.7,182.3,949.3,184"/>
				<path fill="#00BCF1" d="M941.1,183.5c0.5,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.2-1.2,1.6-2.9c-0.5-1.7-2.1-3.1-3.5-3.2
			C941.3,180.6,940.6,181.8,941.1,183.5"/>
				<path fill="#00BCF1" d="M932.9,183c0.5,1.7,2,3.1,3.4,3.2c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2-3.1-3.4-3.2
			C933.2,180.1,932.4,181.4,932.9,183"/>
				<path fill="#00BCF1" d="M924.7,182.6c0.5,1.7,2,3.1,3.4,3.2c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.7-2-3.1-3.4-3.1
			S924.3,180.9,924.7,182.6"/>
				<path fill="#00BCF1" d="M916.6,182.2c0.4,1.7,2,3.1,3.4,3.1s2.2-1.2,1.8-2.9c-0.5-1.7-2-3.1-3.4-3.1
			C916.9,179.2,916.1,180.5,916.6,182.2"/>
				<path fill="#00BCF1" d="M908.3,181.8c0.4,1.7,1.9,3.1,3.4,3.1c1.4,0.1,2.2-1.2,1.8-2.9c-0.4-1.7-1.9-3.1-3.4-3.1
			C908.7,178.8,907.9,180.1,908.3,181.8"/>
				<path fill="#00BCF1" d="M900.1,181.4c0.4,1.7,1.9,3.1,3.3,3.1c1.4,0.1,2.3-1.2,1.8-2.9c-0.4-1.7-1.9-3.1-3.3-3.1
			C900.6,178.5,899.7,179.8,900.1,181.4"/>
				<path fill="#00BCF1" d="M891.9,181.1c0.4,1.7,1.9,3.1,3.3,3.1c1.4,0.1,2.3-1.2,1.9-2.9c-0.4-1.7-1.9-3.1-3.3-3.1
			C892.4,178.1,891.5,179.4,891.9,181.1"/>
				<path fill="#00BCF1" d="M883.7,180.7c0.4,1.7,1.8,3.1,3.3,3.1c1.4,0.1,2.3-1.2,1.9-2.9c-0.4-1.7-1.8-3-3.3-3.1
			C884.2,177.8,883.3,179.1,883.7,180.7"/>
				<path fill="#00BCF1" d="M875.5,180.4c0.3,1.7,1.8,3.1,3.2,3.1c1.4,0.1,2.3-1.3,1.9-2.9c-0.4-1.7-1.8-3-3.2-3.1
			C876,177.5,875.1,178.8,875.5,180.4"/>
				<path fill="#00BCF1" d="M867.2,180.1c0.3,1.7,1.8,3,3.2,3.1c1.4,0.1,2.3-1.3,2-2.9c-0.3-1.7-1.8-3-3.2-3.1
			C867.8,177.2,866.9,178.5,867.2,180.1"/>
				<path fill="#00BCF1" d="M859,179.8c0.3,1.7,1.7,3,3.2,3.1c1.4,0,2.3-1.3,2-2.9c-0.3-1.7-1.7-3-3.2-3.1
			C859.6,176.9,858.7,178.2,859,179.8"/>
				<path fill="#00BCF1" d="M850.7,179.6c0.3,1.7,1.7,3,3.1,3.1c1.4,0,2.4-1.3,2.1-2.9c-0.3-1.7-1.7-3-3.1-3.1
			C851.3,176.6,850.4,177.9,850.7,179.6"/>
				<path fill="#00BCF1" d="M842.4,179.4c0.3,1.7,1.7,3,3.1,3.1c1.4,0,2.4-1.3,2.1-2.9c-0.3-1.7-1.7-3-3.1-3.1
			C843.1,176.4,842.2,177.7,842.4,179.4"/>
				<path fill="#00BCF1" d="M834.2,179.2c0.3,1.7,1.6,3,3.1,3.1c1.4,0,2.4-1.3,2.1-2.9c-0.3-1.7-1.6-3-3.1-3.1
			C834.9,176.2,833.9,177.5,834.2,179.2"/>
				<path fill="#00BCF1" d="M825.9,179c0.2,1.7,1.6,3,3,3.1c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.7-1.6-3-3-3.1
			C826.6,176,825.7,177.3,825.9,179"/>
				<path fill="#00BCF1" d="M817.6,178.8c0.2,1.7,1.6,3,3,3.1c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.7-1.6-3-3-3
			C818.4,175.8,817.4,177.1,817.6,178.8"/>
				<path fill="#00BCF1" d="M809.3,178.6c0.2,1.7,1.5,3,3,3c1.4,0,2.4-1.3,2.2-3c-0.2-1.7-1.5-3-3-3
			C810.1,175.7,809.1,177,809.3,178.6"/>
				<path fill="#00BCF1" d="M801,178.5c0.2,1.7,1.5,3,2.9,3c1.4,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3-2.9-3
			C801.9,175.5,800.9,176.9,801,178.5"/>
				<path fill="#00BCF1" d="M792.8,178.4c0.2,1.7,1.5,3,2.9,3c1.4,0,2.5-1.3,2.3-3c-0.2-1.7-1.5-3-2.9-3
			C793.6,175.4,792.6,176.8,792.8,178.4"/>
				<path fill="#00BCF1" d="M784.5,178.3c0.1,1.7,1.4,3,2.9,3c1.4,0,2.5-1.3,2.4-3c-0.1-1.7-1.4-3-2.9-3
			C785.4,175.4,784.3,176.7,784.5,178.3"/>
				<path fill="#00BCF1" d="M776.2,178.3c0.1,1.7,1.4,3,2.8,3c1.4,0,2.5-1.3,2.4-3c-0.1-1.7-1.4-3-2.8-3
			C777.1,175.3,776,176.6,776.2,178.3"/>
				<path fill="#00BCF1" d="M767.8,178.2c0.1,1.7,1.3,3,2.8,3s2.5-1.3,2.4-3c-0.1-1.7-1.4-3-2.8-3
			C768.8,175.2,767.8,176.6,767.8,178.2"/>
				<path fill="#00BCF1" d="M759.5,178.2c0.1,1.7,1.3,3,2.8,3c1.4,0,2.6-1.3,2.5-3c-0.1-1.7-1.3-3-2.8-3
			C760.6,175.2,759.5,176.6,759.5,178.2"/>
				<path fill="#00BCF1" d="M751.2,178.2c0.1,1.7,1.3,3,2.7,3c1.4,0,2.6-1.3,2.5-3c-0.1-1.7-1.3-3-2.7-3
			C752.3,175.2,751.2,176.6,751.2,178.2"/>
				<path fill="#00BCF1" d="M742.9,178.2c0,1.7,1.2,3,2.7,3c1.4,0,2.6-1.4,2.5-3c0-1.7-1.3-3-2.7-3C744,175.2,742.9,176.6,742.9,178.2
			"/>
				<path fill="#00BCF1" d="M1366,227.5c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1364.8,224.5,1364.8,225.8,1366,227.5"/>
				<path fill="#00BCF1" d="M1358.9,226.2c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1357.8,223.2,1357.7,224.4,1358.9,226.2"/>
				<path fill="#00BCF1" d="M1351.8,224.8c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8s-3.1-3.4-4.4-3.6
			C1350.7,221.8,1350.7,223.1,1351.8,224.8"/>
				<path fill="#00BCF1" d="M1344.7,223.5c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1343.6,220.5,1343.5,221.7,1344.7,223.5"/>
				<path fill="#00BCF1" d="M1337.6,222.1c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1336.5,219.2,1336.4,220.4,1337.6,222.1"/>
				<path fill="#00BCF1" d="M1330.4,220.8c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.4-3.6
			C1329.3,217.8,1329.2,219.1,1330.4,220.8"/>
				<path fill="#00BCF1" d="M1323.2,219.5c1.2,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.2-1.8-3.1-3.3-4.4-3.6
			C1322.1,216.5,1322,217.8,1323.2,219.5"/>
				<path fill="#00BCF1" d="M1316,218.2c1.1,1.7,3.1,3.3,4.4,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.2-1.7-3.1-3.3-4.4-3.6
			C1314.9,215.2,1314.8,216.5,1316,218.2"/>
				<path fill="#00BCF1" d="M1308.7,216.9c1.1,1.7,3.1,3.3,4.3,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.6
			C1307.7,214,1307.6,215.2,1308.7,216.9"/>
				<path fill="#00BCF1" d="M1301.5,215.7c1.1,1.7,3.1,3.3,4.3,3.6c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.6
			C1300.4,212.7,1300.3,213.9,1301.5,215.7"/>
				<path fill="#00BCF1" d="M1294.2,214.4c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1293.2,211.5,1293,212.7,1294.2,214.4"/>
				<path fill="#00BCF1" d="M1286.9,213.2c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1285.9,210.2,1285.7,211.5,1286.9,213.2"/>
				<path fill="#00BCF1" d="M1279.5,212c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1278.5,209,1278.4,210.2,1279.5,212"/>
				<path fill="#00BCF1" d="M1272.2,210.8c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8s-3-3.3-4.3-3.5
			C1271.2,207.8,1271.1,209,1272.2,210.8"/>
				<path fill="#00BCF1" d="M1264.8,209.6c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1263.8,206.6,1263.7,207.8,1264.8,209.6"/>
				<path fill="#00BCF1" d="M1257.4,208.4c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1256.4,205.4,1256.3,206.7,1257.4,208.4"/>
				<path fill="#00BCF1" d="M1249.9,207.2c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			S1248.9,205.5,1249.9,207.2"/>
				<path fill="#00BCF1" d="M1242.5,206.1c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1241.6,203.1,1241.4,204.3,1242.5,206.1"/>
				<path fill="#00BCF1" d="M1235,204.9c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1234.1,202,1234,203.2,1235,204.9"/>
				<path fill="#00BCF1" d="M1227.5,203.8c1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1226.7,200.8,1226.5,202.1,1227.5,203.8"/>
				<path fill="#00BCF1" d="M1220,202.7c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.5-1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1219.2,199.7,1219,201,1220,202.7"/>
				<path fill="#00BCF1" d="M1212.5,201.6c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.5
			C1211.7,198.6,1211.5,199.9,1212.5,201.6"/>
				<path fill="#00BCF1" d="M1204.9,200.5c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1204.1,197.6,1203.9,198.8,1204.9,200.5"/>
				<path fill="#00BCF1" d="M1197.4,199.5c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.6-1.1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1196.6,196.5,1196.4,197.7,1197.4,199.5"/>
				<path fill="#00BCF1" d="M1189.8,198.4c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1189,195.5,1188.8,196.7,1189.8,198.4"/>
				<path fill="#00BCF1" d="M1182.2,197.4c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1181.4,194.4,1181.2,195.7,1182.2,197.4"/>
				<path fill="#00BCF1" d="M1174.5,196.4c1,1.7,2.8,3.2,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.8-3.2-4.2-3.4
			C1173.8,193.4,1173.6,194.7,1174.5,196.4"/>
				<path fill="#00BCF1" d="M1166.9,195.4c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-1-1.7-2.8-3.2-4.1-3.4
			C1166.2,192.4,1165.9,193.7,1166.9,195.4"/>
				<path fill="#00BCF1" d="M1159.2,194.4c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-1-1.7-2.8-3.2-4.1-3.4
			C1158.6,191.4,1158.3,192.7,1159.2,194.4"/>
				<path fill="#00BCF1" d="M1151.5,193.4c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.7-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1150.9,190.5,1150.6,191.7,1151.5,193.4"/>
				<path fill="#00BCF1" d="M1143.8,192.5c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.7-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1143.2,189.5,1142.9,190.8,1143.8,192.5"/>
				<path fill="#00BCF1" d="M1136.1,191.5c0.9,1.7,2.7,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1135.5,188.6,1135.2,189.9,1136.1,191.5"/>
				<path fill="#00BCF1" d="M1128.4,190.6c0.9,1.7,2.7,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4.1-3.4
			C1127.8,187.7,1127.5,188.9,1128.4,190.6"/>
				<path fill="#00BCF1" d="M1120.6,189.7c0.9,1.7,2.7,3.2,4,3.4c1.4,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1120.1,186.8,1119.7,188,1120.6,189.7"/>
				<path fill="#00BCF1" d="M1112.8,188.9c0.9,1.7,2.7,3.2,4,3.3c1.4,0.2,1.7-1.1,0.9-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1112.4,185.9,1112,187.2,1112.8,188.9"/>
				<path fill="#00BCF1" d="M1105,188c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.8-1.1,0.9-2.8c-0.9-1.7-2.6-3.2-4-3.3
			C1104.6,185.1,1104.2,186.3,1105,188"/>
				<path fill="#00BCF1" d="M1097.2,187.1c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.8-1.1,0.9-2.8c-0.8-1.7-2.6-3.2-4-3.3
			S1096.4,185.5,1097.2,187.1"/>
				<path fill="#00BCF1" d="M1089.4,186.3c0.8,1.7,2.6,3.2,4,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-3.9-3.3
			C1089,183.4,1088.6,184.6,1089.4,186.3"/>
				<path fill="#00BCF1" d="M1081.6,185.5c0.8,1.7,2.6,3.2,3.9,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.2-3.9-3.3
			C1081.2,182.6,1080.8,183.8,1081.6,185.5"/>
				<path fill="#00BCF1" d="M1073.7,184.7c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.5-3.2-3.9-3.3
			C1073.4,181.8,1072.9,183,1073.7,184.7"/>
				<path fill="#00BCF1" d="M1065.9,183.9c0.8,1.7,2.5,3.2,3.9,3.3c1.4,0.1,1.8-1.1,1.1-2.8c-0.8-1.7-2.5-3.1-3.9-3.3
			C1065.6,181,1065.1,182.3,1065.9,183.9"/>
				<path fill="#00BCF1" d="M1058,183.2c0.8,1.7,2.5,3.1,3.9,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.1-3.9-3.3
			C1057.7,180.3,1057.2,181.5,1058,183.2"/>
				<path fill="#00BCF1" d="M1050.1,182.5c0.7,1.7,2.5,3.1,3.8,3.3c1.4,0.1,1.9-1.1,1.1-2.8c-0.8-1.7-2.5-3.1-3.8-3.3
			C1049.9,179.5,1049.4,180.8,1050.1,182.5"/>
				<path fill="#00BCF1" d="M1042.2,181.7c0.7,1.7,2.4,3.1,3.8,3.3c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.8-3.3
			C1042,178.8,1041.5,180.1,1042.2,181.7"/>
				<path fill="#00BCF1" d="M1034.3,181c0.7,1.7,2.4,3.1,3.8,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.8-3.2
			C1034.1,178.1,1033.6,179.4,1034.3,181"/>
				<path fill="#00BCF1" d="M1026.3,180.4c0.7,1.7,2.4,3.1,3.8,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.8-3.2
			C1026.2,177.4,1025.6,178.7,1026.3,180.4"/>
				<path fill="#00BCF1" d="M1018.4,179.7c0.7,1.7,2.3,3.1,3.7,3.2c1.4,0.1,2-1.1,1.3-2.8c-0.7-1.7-2.4-3.1-3.7-3.2
			C1018.3,176.8,1017.7,178,1018.4,179.7"/>
				<path fill="#00BCF1" d="M1010.4,179c0.7,1.7,2.3,3.1,3.7,3.2c1.4,0.1,2-1.2,1.3-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1010.4,176.1,1009.8,177.4,1010.4,179"/>
				<path fill="#00BCF1" d="M1002.5,178.4c0.6,1.7,2.3,3.1,3.7,3.2c1.4,0.1,2-1.2,1.3-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1002.4,175.5,1001.8,176.8,1002.5,178.4"/>
				<path fill="#00BCF1" d="M994.5,177.8c0.6,1.7,2.3,3.1,3.6,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.7-2.3-3.1-3.6-3.2
			S993.8,176.2,994.5,177.8"/>
				<path fill="#00BCF1" d="M986.5,177.2c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.7-2.2-3.1-3.6-3.2
			C986.5,174.3,985.9,175.6,986.5,177.2"/>
				<path fill="#00BCF1" d="M978.5,176.7c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.7-2.2-3.1-3.6-3.2
			C978.5,173.7,977.9,175,978.5,176.7"/>
				<path fill="#00BCF1" d="M970.5,176.1c0.6,1.7,2.2,3.1,3.6,3.2c1.4,0.1,2.1-1.2,1.5-2.8c-0.6-1.7-2.2-3.1-3.6-3.2
			C970.5,173.2,969.9,174.5,970.5,176.1"/>
				<path fill="#00BCF1" d="M962.4,175.6c0.6,1.7,2.1,3.1,3.5,3.2c1.4,0.1,2.1-1.2,1.5-2.8c-0.6-1.7-2.2-3.1-3.5-3.2
			C962.5,172.7,961.9,173.9,962.4,175.6"/>
				<path fill="#00BCF1" d="M954.4,175.1c0.5,1.7,2.1,3.1,3.5,3.2s2.1-1.2,1.5-2.8c-0.5-1.7-2.1-3.1-3.5-3.1
			C954.5,172.2,953.8,173.4,954.4,175.1"/>
				<path fill="#00BCF1" d="M946.3,174.6c0.5,1.7,2.1,3.1,3.5,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.7-2.1-3.1-3.5-3.1
			C946.5,171.7,945.8,172.9,946.3,174.6"/>
				<path fill="#00BCF1" d="M938.3,174.1c0.5,1.7,2,3.1,3.4,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.7-2.1-3.1-3.4-3.1
			C938.5,171.2,937.8,172.5,938.3,174.1"/>
				<path fill="#00BCF1" d="M930.2,173.7c0.5,1.7,2,3.1,3.4,3.1c1.4,0.1,2.1-1.2,1.7-2.9c-0.5-1.7-2-3-3.4-3.1
			C930.5,170.8,929.7,172,930.2,173.7"/>
				<path fill="#00BCF1" d="M922.1,173.2c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.6-2-3-3.4-3.1
			C922.4,170.3,921.7,171.6,922.1,173.2"/>
				<path fill="#00BCF1" d="M914,172.8c0.4,1.6,1.9,3,3.4,3.1c1.4,0.1,2.2-1.2,1.7-2.9c-0.5-1.6-2-3-3.4-3.1
			C914.4,169.9,913.6,171.2,914,172.8"/>
				<path fill="#00BCF1" d="M905.9,172.4c0.4,1.6,1.9,3,3.3,3.1c1.4,0.1,2.2-1.2,1.8-2.9c-0.4-1.6-1.9-3-3.3-3.1
			C906.3,169.5,905.5,170.8,905.9,172.4"/>
				<path fill="#00BCF1" d="M897.8,172.1c0.4,1.6,1.9,3,3.3,3.1c1.4,0.1,2.2-1.2,1.8-2.9c-0.4-1.6-1.9-3-3.3-3.1
			C898.2,169.1,897.4,170.4,897.8,172.1"/>
				<path fill="#00BCF1" d="M889.7,171.7c0.4,1.6,1.8,3,3.3,3.1c1.4,0.1,2.2-1.2,1.8-2.9c-0.4-1.6-1.9-3-3.3-3.1
			C890.2,168.8,889.3,170.1,889.7,171.7"/>
				<path fill="#00BCF1" d="M881.6,171.4c0.4,1.6,1.8,3,3.2,3.1c1.4,0.1,2.3-1.2,1.9-2.9c-0.4-1.6-1.8-3-3.2-3.1
			C882.1,168.5,881.2,169.8,881.6,171.4"/>
				<path fill="#00BCF1" d="M873.5,171.1c0.3,1.6,1.8,3,3.2,3.1c1.4,0.1,2.3-1.2,1.9-2.9c-0.4-1.6-1.8-3-3.2-3.1
			C874,168.2,873.1,169.4,873.5,171.1"/>
				<path fill="#00BCF1" d="M865.3,170.8c0.3,1.6,1.7,3,3.2,3.1c1.4,0,2.3-1.2,1.9-2.9c-0.3-1.6-1.8-3-3.2-3.1
			C865.9,167.9,865,169.2,865.3,170.8"/>
				<path fill="#00BCF1" d="M857.2,170.5c0.3,1.6,1.7,3,3.1,3.1c1.4,0,2.3-1.3,2-2.9s-1.7-3-3.1-3
			C857.8,167.6,856.9,168.9,857.2,170.5"/>
				<path fill="#00BCF1" d="M849.1,170.3c0.3,1.6,1.7,3,3.1,3.1c1.4,0,2.3-1.3,2-2.9s-1.7-3-3.1-3
			C849.7,167.3,848.8,168.6,849.1,170.3"/>
				<path fill="#00BCF1" d="M840.9,170.1c0.3,1.6,1.6,3,3.1,3c1.4,0,2.3-1.3,2.1-2.9c-0.3-1.6-1.7-3-3.1-3
			C841.6,167.1,840.6,168.4,840.9,170.1"/>
				<path fill="#00BCF1" d="M832.7,169.8c0.3,1.6,1.6,3,3,3c1.4,0,2.4-1.3,2.1-2.9c-0.3-1.6-1.6-3-3-3
			C833.4,166.9,832.5,168.2,832.7,169.8"/>
				<path fill="#00BCF1" d="M824.6,169.7c0.2,1.6,1.6,3,3,3c1.4,0,2.4-1.3,2.1-2.9c-0.2-1.6-1.6-3-3-3
			C825.3,166.7,824.4,168,824.6,169.7"/>
				<path fill="#00BCF1" d="M816.4,169.5c0.2,1.6,1.5,3,3,3c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.6-3-3-3
			C817.2,166.6,816.2,167.9,816.4,169.5"/>
				<path fill="#00BCF1" d="M808.2,169.3c0.2,1.6,1.5,3,2.9,3c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.5-3-2.9-3
			C809,166.4,808.1,167.7,808.2,169.3"/>
				<path fill="#00BCF1" d="M800.1,169.2c0.2,1.6,1.5,3,2.9,3c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.5-3-2.9-3
			C800.9,166.3,799.9,167.6,800.1,169.2"/>
				<path fill="#00BCF1" d="M791.9,169.1c0.2,1.6,1.4,3,2.9,3c1.4,0,2.4-1.3,2.3-2.9c-0.2-1.6-1.4-3-2.9-3
			C792.8,166.2,791.7,167.5,791.9,169.1"/>
				<path fill="#00BCF1" d="M783.7,169c0.1,1.6,1.4,3,2.8,3c1.4,0,2.5-1.3,2.3-2.9c-0.1-1.6-1.4-3-2.8-3
			C784.6,166.1,783.6,167.4,783.7,169"/>
				<path fill="#00BCF1" d="M775.5,169c0.1,1.6,1.4,3,2.8,3c1.4,0,2.5-1.3,2.4-2.9c-0.1-1.6-1.4-3-2.8-3
			C776.4,166,775.4,167.3,775.5,169"/>
				<path fill="#00BCF1" d="M767.3,168.9c0.1,1.6,1.3,3,2.8,3c1.4,0,2.5-1.3,2.4-3c-0.1-1.6-1.3-3-2.8-3S767.2,167.3,767.3,168.9" />
				<path fill="#00BCF1" d="M759.1,168.9c0.1,1.6,1.3,3,2.7,3c1.4,0,2.5-1.3,2.4-3c-0.1-1.6-1.3-3-2.7-3
			C760.1,166,759,167.3,759.1,168.9"/>
				<path fill="#00BCF1" d="M750.9,168.9c0.1,1.6,1.3,3,2.7,3c1.4,0,2.5-1.3,2.5-3c-0.1-1.6-1.3-3-2.7-3
			C752,166,750.9,167.3,750.9,168.9"/>
				<path fill="#00BCF1" d="M742.7,168.9c0,1.6,1.2,3,2.6,3c1.4,0,2.6-1.3,2.5-3c0-1.6-1.2-3-2.6-2.9
			C743.8,166,742.7,167.3,742.7,168.9"/>
				<path fill="#00BCF1" d="M734.5,169c0,1.6,1.2,3,2.6,3c1.4,0,2.6-1.3,2.5-3c0-1.6-1.2-2.9-2.6-2.9C735.6,166,734.5,167.3,734.5,169
			"/>
				<path fill="#00BCF1" d="M1359.3,217.6c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.2-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1358.2,214.6,1358.1,215.8,1359.3,217.6"/>
				<path fill="#00BCF1" d="M1352.3,216.2c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1351.1,213.3,1351.1,214.5,1352.3,216.2"/>
				<path fill="#00BCF1" d="M1345.2,214.9c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1344.1,211.9,1344,213.1,1345.2,214.9"/>
				<path fill="#00BCF1" d="M1338.1,213.6c1.2,1.8,3.1,3.4,4.4,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1337,210.6,1336.9,211.8,1338.1,213.6"/>
				<path fill="#00BCF1" d="M1331,212.2c1.2,1.7,3.1,3.4,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1329.9,209.3,1329.8,210.5,1331,212.2"/>
				<path fill="#00BCF1" d="M1323.8,210.9c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1322.7,208,1322.7,209.2,1323.8,210.9"/>
				<path fill="#00BCF1" d="M1316.7,209.6c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.2-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1315.6,206.7,1315.5,207.9,1316.7,209.6"/>
				<path fill="#00BCF1" d="M1309.5,208.4c1.1,1.7,3.1,3.3,4.3,3.6c1.3,0.2,1.3-1,0.2-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1308.4,205.4,1308.3,206.6,1309.5,208.4"/>
				<path fill="#00BCF1" d="M1302.3,207.1c1.1,1.7,3.1,3.3,4.3,3.6c1.3,0.2,1.3-1,0.2-2.8s-3.1-3.3-4.3-3.5
			C1301.2,204.1,1301.1,205.4,1302.3,207.1"/>
				<path fill="#00BCF1" d="M1295,205.8c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1294,202.9,1293.9,204.1,1295,205.8"/>
				<path fill="#00BCF1" d="M1287.8,204.6c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1286.8,201.6,1286.7,202.9,1287.8,204.6"/>
				<path fill="#00BCF1" d="M1280.5,203.4c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8s-3.1-3.3-4.3-3.5
			C1279.5,200.4,1279.4,201.6,1280.5,203.4"/>
				<path fill="#00BCF1" d="M1273.2,202.2c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1272.2,199.2,1272.1,200.4,1273.2,202.2"/>
				<path fill="#00BCF1" d="M1265.9,201c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1264.9,198,1264.8,199.2,1265.9,201"/>
				<path fill="#00BCF1" d="M1258.6,199.8c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1257.6,196.8,1257.5,198.1,1258.6,199.8"/>
				<path fill="#00BCF1" d="M1251.2,198.6c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1250.3,195.7,1250.1,196.9,1251.2,198.6"/>
				<path fill="#00BCF1" d="M1243.8,197.5c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1242.9,194.5,1242.8,195.7,1243.8,197.5"/>
				<path fill="#00BCF1" d="M1236.4,196.3c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1235.5,193.4,1235.4,194.6,1236.4,196.3"/>
				<path fill="#00BCF1" d="M1229,195.2c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1228.2,192.2,1228,193.5,1229,195.2"/>
				<path fill="#00BCF1" d="M1221.6,194.1c1,1.7,2.9,3.3,4.2,3.5c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.2-3.5
			C1220.7,191.1,1220.5,192.4,1221.6,194.1"/>
				<path fill="#00BCF1" d="M1214.1,193c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.5-1,0.5-2.8c-1-1.7-2.9-3.3-4.2-3.4
			C1213.3,190,1213.1,191.3,1214.1,193"/>
				<path fill="#00BCF1" d="M1206.7,191.9c1,1.7,2.9,3.3,4.2,3.4c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1205.9,189,1205.6,190.2,1206.7,191.9"/>
				<path fill="#00BCF1" d="M1199.2,190.8c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1198.4,187.9,1198.2,189.1,1199.2,190.8"/>
				<path fill="#00BCF1" d="M1191.7,189.8c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1190.9,186.9,1190.7,188.1,1191.7,189.8"/>
				<path fill="#00BCF1" d="M1184.1,188.8c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1183.4,185.8,1183.1,187.1,1184.1,188.8"/>
				<path fill="#00BCF1" d="M1176.6,187.7c1,1.7,2.8,3.2,4.2,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.9-3.2-4.2-3.4
			C1175.9,184.8,1175.6,186,1176.6,187.7"/>
				<path fill="#00BCF1" d="M1169,186.7c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.8-3.2-4.1-3.4S1168.1,185,1169,186.7
			"/>
				<path fill="#00BCF1" d="M1161.5,185.8c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.6-2.8c-1-1.7-2.8-3.2-4.1-3.4
			C1160.8,182.8,1160.5,184.1,1161.5,185.8"/>
				<path fill="#00BCF1" d="M1153.9,184.8c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-1-1.7-2.8-3.2-4.1-3.4
			C1153.2,181.9,1152.9,183.1,1153.9,184.8"/>
				<path fill="#00BCF1" d="M1146.2,183.8c0.9,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.7-2.8c-0.9-1.7-2.8-3.2-4.1-3.4
			C1145.6,180.9,1145.3,182.1,1146.2,183.8"/>
				<path fill="#00BCF1" d="M1138.6,182.9c0.9,1.7,2.7,3.2,4.1,3.4c1.3,0.2,1.7-1.1,0.7-2.8c-0.9-1.7-2.7-3.2-4.1-3.3
			C1138,180,1137.7,181.2,1138.6,182.9"/>
				<path fill="#00BCF1" d="M1131,182c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1130.4,179.1,1130.1,180.3,1131,182"/>
				<path fill="#00BCF1" d="M1123.3,181.1c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1122.8,178.2,1122.4,179.4,1123.3,181.1"/>
				<path fill="#00BCF1" d="M1115.6,180.2c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1115.1,177.3,1114.8,178.5,1115.6,180.2"/>
				<path fill="#00BCF1" d="M1107.9,179.3c0.9,1.7,2.6,3.2,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1107.5,176.4,1107.1,177.7,1107.9,179.3"/>
				<path fill="#00BCF1" d="M1100.2,178.5c0.8,1.7,2.6,3.2,4,3.3c1.3,0.1,1.7-1.1,0.9-2.8c-0.9-1.7-2.6-3.2-4-3.3
			C1099.8,175.6,1099.4,176.8,1100.2,178.5"/>
				<path fill="#00BCF1" d="M1092.5,177.7c0.8,1.7,2.6,3.2,3.9,3.3c1.3,0.1,1.8-1.1,0.9-2.8c-0.8-1.7-2.6-3.1-3.9-3.3
			C1092.1,174.7,1091.7,176,1092.5,177.7"/>
				<path fill="#00BCF1" d="M1084.8,176.8c0.8,1.7,2.6,3.1,3.9,3.3c1.3,0.1,1.8-1.1,0.9-2.8c-0.8-1.7-2.6-3.1-3.9-3.3
			C1084.4,173.9,1084,175.2,1084.8,176.8"/>
				<path fill="#00BCF1" d="M1077,176c0.8,1.7,2.6,3.1,3.9,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.6-3.1-3.9-3.3
			C1076.7,173.1,1076.2,174.4,1077,176"/>
				<path fill="#00BCF1" d="M1069.3,175.3c0.8,1.7,2.5,3.1,3.9,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.5-3.1-3.9-3.3
			C1068.9,172.4,1068.5,173.6,1069.3,175.3"/>
				<path fill="#00BCF1" d="M1061.5,174.5c0.8,1.7,2.5,3.1,3.9,3.3c1.4,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.5-3.1-3.9-3.2
			C1061.2,171.6,1060.7,172.8,1061.5,174.5"/>
				<path fill="#00BCF1" d="M1053.7,173.8c0.8,1.7,2.5,3.1,3.8,3.2c1.4,0.1,1.8-1.1,1.1-2.8c-0.8-1.7-2.5-3.1-3.8-3.2
			C1053.4,170.9,1052.9,172.1,1053.7,173.8"/>
				<path fill="#00BCF1" d="M1045.9,173c0.7,1.7,2.4,3.1,3.8,3.2c1.4,0.1,1.8-1.1,1.1-2.8c-0.8-1.7-2.5-3.1-3.8-3.2
			C1045.7,170.1,1045.2,171.4,1045.9,173"/>
				<path fill="#00BCF1" d="M1038.1,172.3c0.7,1.7,2.4,3.1,3.8,3.2c1.4,0.1,1.9-1.1,1.1-2.8c-0.7-1.7-2.4-3.1-3.8-3.2
			C1037.9,169.4,1037.4,170.7,1038.1,172.3"/>
				<path fill="#00BCF1" d="M1030.3,171.6c0.7,1.7,2.4,3.1,3.8,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.8-3.2
			C1030.1,168.7,1029.6,170,1030.3,171.6"/>
				<path fill="#00BCF1" d="M1022.4,171c0.7,1.7,2.4,3.1,3.7,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.4-3.1-3.7-3.2
			C1022.3,168.1,1021.7,169.3,1022.4,171"/>
				<path fill="#00BCF1" d="M1014.6,170.3c0.7,1.7,2.3,3.1,3.7,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1014.4,167.4,1013.9,168.7,1014.6,170.3"/>
				<path fill="#00BCF1" d="M1006.7,169.7c0.7,1.6,2.3,3.1,3.7,3.2c1.4,0.1,1.9-1.1,1.3-2.8c-0.7-1.7-2.3-3.1-3.7-3.2
			C1006.6,166.8,1006,168,1006.7,169.7"/>
				<path fill="#00BCF1" d="M998.8,169.1c0.6,1.6,2.3,3.1,3.6,3.2c1.4,0.1,2-1.1,1.3-2.8c-0.7-1.6-2.3-3.1-3.6-3.2
			C998.8,166.2,998.2,167.4,998.8,169.1"/>
				<path fill="#00BCF1" d="M990.9,168.5c0.6,1.6,2.2,3.1,3.6,3.2c1.4,0.1,2-1.2,1.3-2.8c-0.6-1.6-2.3-3.1-3.6-3.2
			C990.9,165.6,990.3,166.8,990.9,168.5"/>
				<path fill="#00BCF1" d="M983,167.9c0.6,1.6,2.2,3.1,3.6,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.2-3.1-3.6-3.2
			C983.1,165,982.4,166.2,983,167.9"/>
				<path fill="#00BCF1" d="M975.1,167.3c0.6,1.6,2.2,3.1,3.6,3.2c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.2-3-3.6-3.1
			C975.2,164.4,974.5,165.7,975.1,167.3"/>
				<path fill="#00BCF1" d="M967.2,166.8c0.6,1.6,2.2,3.1,3.5,3.1c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.2-3-3.5-3.1
			C967.3,163.9,966.6,165.2,967.2,166.8"/>
				<path fill="#00BCF1" d="M959.3,166.3c0.6,1.6,2.1,3,3.5,3.1c1.4,0.1,2-1.2,1.5-2.8c-0.6-1.6-2.1-3-3.5-3.1
			C959.4,163.4,958.7,164.6,959.3,166.3"/>
				<path fill="#00BCF1" d="M951.4,165.8c0.5,1.6,2.1,3,3.5,3.1c1.4,0.1,2.1-1.2,1.5-2.8c-0.5-1.6-2.1-3-3.5-3.1
			C951.5,162.9,950.8,164.1,951.4,165.8"/>
				<path fill="#00BCF1" d="M943.4,165.3c0.5,1.6,2.1,3,3.4,3.1c1.4,0.1,2.1-1.2,1.5-2.8c-0.5-1.6-2.1-3-3.4-3.1
			C943.6,162.4,942.9,163.7,943.4,165.3"/>
				<path fill="#00BCF1" d="M935.4,164.8c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-3-3.4-3.1
			C935.7,161.9,935,163.2,935.4,164.8"/>
				<path fill="#00BCF1" d="M927.5,164.4c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-3-3.4-3.1
			C927.7,161.5,927,162.8,927.5,164.4"/>
				<path fill="#00BCF1" d="M919.5,164c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2.1-1.2,1.7-2.8c-0.5-1.6-2-3-3.4-3.1
			C919.8,161.1,919,162.3,919.5,164"/>
				<path fill="#00BCF1" d="M911.5,163.6c0.4,1.6,1.9,3,3.3,3.1c1.4,0.1,2.2-1.2,1.7-2.8c-0.5-1.6-1.9-3-3.3-3.1
			C911.8,160.7,911.1,161.9,911.5,163.6"/>
				<path fill="#00BCF1" d="M903.5,163.2c0.4,1.6,1.9,3,3.3,3.1c1.4,0.1,2.2-1.2,1.7-2.8c-0.4-1.6-1.9-3-3.3-3.1
			C903.9,160.3,903.1,161.6,903.5,163.2"/>
				<path fill="#00BCF1" d="M895.5,162.8c0.4,1.6,1.9,3,3.3,3.1c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.9-3-3.3-3.1
			C895.9,159.9,895.1,161.2,895.5,162.8"/>
				<path fill="#00BCF1" d="M887.5,162.5c0.4,1.6,1.8,3,3.2,3.1c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-3-3.2-3
			C888,159.6,887.2,160.9,887.5,162.5"/>
				<path fill="#00BCF1" d="M879.5,162.2c0.4,1.6,1.8,3,3.2,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-3-3.2-3
			C880,159.3,879.2,160.5,879.5,162.2"/>
				<path fill="#00BCF1" d="M871.5,161.9c0.3,1.6,1.8,3,3.2,3c1.4,0.1,2.2-1.2,1.9-2.9c-0.4-1.6-1.8-3-3.2-3
			C872,159,871.2,160.2,871.5,161.9"/>
				<path fill="#00BCF1" d="M863.5,161.6c0.3,1.6,1.7,3,3.1,3c1.4,0,2.3-1.2,1.9-2.9c-0.3-1.6-1.7-3-3.1-3
			C864,158.7,863.2,159.9,863.5,161.6"/>
				<path fill="#00BCF1" d="M855.5,161.3c0.3,1.6,1.7,3,3.1,3c1.4,0,2.3-1.2,1.9-2.9c-0.3-1.6-1.7-3-3.1-3
			C856,158.4,855.1,159.7,855.5,161.3"/>
				<path fill="#00BCF1" d="M847.4,161.1c0.3,1.6,1.7,3,3.1,3c1.4,0,2.3-1.2,2-2.9c-0.3-1.6-1.7-3-3.1-3
			C848,158.2,847.1,159.4,847.4,161.1"/>
				<path fill="#00BCF1" d="M839.4,160.8c0.3,1.6,1.6,3,3,3c1.4,0,2.3-1.2,2-2.9c-0.3-1.6-1.6-3-3-3
			C840,157.9,839.1,159.2,839.4,160.8"/>
				<path fill="#00BCF1" d="M831.3,160.6c0.3,1.6,1.6,3,3,3c1.4,0,2.3-1.3,2.1-2.9c-0.3-1.6-1.6-3-3-3
			C832,157.7,831.1,159,831.3,160.6"/>
				<path fill="#00BCF1" d="M823.3,160.5c0.2,1.6,1.6,3,3,3c1.4,0,2.3-1.3,2.1-2.9c-0.2-1.6-1.6-3-3-3
			C824,157.6,823,158.8,823.3,160.5"/>
				<path fill="#00BCF1" d="M815.2,160.3c0.2,1.6,1.5,3,2.9,3c1.4,0,2.4-1.3,2.1-2.9c-0.2-1.6-1.5-2.9-2.9-3
			C816,157.4,815,158.7,815.2,160.3"/>
				<path fill="#00BCF1" d="M807.1,160.1c0.2,1.6,1.5,3,2.9,3s2.4-1.3,2.2-2.9c-0.2-1.6-1.5-2.9-2.9-3
			C807.9,157.2,807,158.5,807.1,160.1"/>
				<path fill="#00BCF1" d="M799.1,160c0.2,1.6,1.5,2.9,2.9,3c1.4,0,2.4-1.3,2.2-2.9s-1.5-2.9-2.9-3
			C799.9,157.1,798.9,158.4,799.1,160"/>
				<path fill="#00BCF1" d="M791,159.9c0.2,1.6,1.4,2.9,2.8,3c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.4-2.9-2.8-3
			C791.9,157,790.9,158.3,791,159.9"/>
				<path fill="#00BCF1" d="M782.9,159.8c0.1,1.6,1.4,2.9,2.8,3c1.4,0,2.4-1.3,2.3-2.9c-0.1-1.6-1.4-2.9-2.8-2.9
			C783.8,156.9,782.8,158.2,782.9,159.8"/>
				<path fill="#00BCF1" d="M774.9,159.8c0.1,1.6,1.3,2.9,2.8,2.9c1.4,0,2.4-1.3,2.3-2.9c-0.1-1.6-1.4-2.9-2.8-2.9
			C775.8,156.9,774.8,158.2,774.9,159.8"/>
				<path fill="#00BCF1" d="M766.8,159.7c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.5-1.3,2.4-2.9c-0.1-1.6-1.3-2.9-2.7-2.9
			C767.7,156.8,766.7,158.1,766.8,159.7"/>
				<path fill="#00BCF1" d="M758.7,159.7c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.5-1.3,2.4-2.9c-0.1-1.6-1.3-2.9-2.7-2.9
			C759.7,156.8,758.6,158.1,758.7,159.7"/>
				<path fill="#00BCF1" d="M750.6,159.7c0.1,1.6,1.2,2.9,2.6,2.9c1.4,0,2.5-1.3,2.4-2.9c-0.1-1.6-1.3-2.9-2.6-2.9
			C751.7,156.8,750.6,158.1,750.6,159.7"/>
				<path fill="#00BCF1" d="M1338.6,205c1.2,1.8,3.1,3.4,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1337.4,202,1337.4,203.2,1338.6,205"/>
				<path fill="#00BCF1" d="M1331.5,203.7c1.2,1.7,3.1,3.4,4.3,3.6s1.3-1,0.1-2.8c-1.2-1.8-3.1-3.4-4.3-3.6
			C1330.4,200.7,1330.3,201.9,1331.5,203.7"/>
				<path fill="#00BCF1" d="M1324.4,202.4c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1323.3,199.4,1323.2,200.6,1324.4,202.4"/>
				<path fill="#00BCF1" d="M1317.3,201.1c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1316.2,198.1,1316.1,199.3,1317.3,201.1"/>
				<path fill="#00BCF1" d="M1310.1,199.8c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1309.1,196.8,1309,198,1310.1,199.8"/>
				<path fill="#00BCF1" d="M1303,198.5c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.8c-1.2-1.7-3.1-3.3-4.3-3.5
			C1301.9,195.6,1301.8,196.8,1303,198.5"/>
				<path fill="#00BCF1" d="M1295.8,197.3c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1294.8,194.3,1294.7,195.5,1295.8,197.3"/>
				<path fill="#00BCF1" d="M1288.6,196c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.3-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1287.6,193.1,1287.5,194.3,1288.6,196"/>
				<path fill="#00BCF1" d="M1281.4,194.8c1.1,1.7,3.1,3.3,4.3,3.5c1.3,0.2,1.3-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1280.4,191.8,1280.3,193.1,1281.4,194.8"/>
				<path fill="#00BCF1" d="M1274.2,193.6c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.2-2.8c-1.1-1.7-3.1-3.3-4.3-3.5
			C1273.2,190.6,1273.1,191.9,1274.2,193.6"/>
				<path fill="#00BCF1" d="M1266.9,192.4c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1265.9,189.4,1265.8,190.7,1266.9,192.4"/>
				<path fill="#00BCF1" d="M1259.7,191.2c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1258.7,188.3,1258.6,189.5,1259.7,191.2"/>
				<path fill="#00BCF1" d="M1252.4,190c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1251.4,187.1,1251.3,188.3,1252.4,190"/>
				<path fill="#00BCF1" d="M1245.1,188.9c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1244.1,185.9,1244,187.2,1245.1,188.9"/>
				<path fill="#00BCF1" d="M1237.7,187.7c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.8c-1.1-1.7-3-3.3-4.3-3.5
			C1236.8,184.8,1236.7,186,1237.7,187.7"/>
				<path fill="#00BCF1" d="M1230.4,186.6c1.1,1.7,3,3.3,4.2,3.5c1.3,0.2,1.4-1,0.4-2.8c-1.1-1.7-3-3.3-4.2-3.5
			C1229.5,183.7,1229.3,184.9,1230.4,186.6"/>
				<path fill="#00BCF1" d="M1223,185.5c1.1,1.7,3,3.3,4.2,3.4c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-3-3.3-4.2-3.4
			C1222.2,182.6,1222,183.8,1223,185.5"/>
				<path fill="#00BCF1" d="M1215.7,184.4c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.8c-1.1-1.7-2.9-3.2-4.2-3.4
			C1214.8,181.5,1214.6,182.7,1215.7,184.4"/>
				<path fill="#00BCF1" d="M1208.3,183.3c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1207.4,180.4,1207.2,181.6,1208.3,183.3"/>
				<path fill="#00BCF1" d="M1200.9,182.3c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1200,179.3,1199.8,180.6,1200.9,182.3"/>
				<path fill="#00BCF1" d="M1193.4,181.2c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1192.6,178.3,1192.4,179.5,1193.4,181.2"/>
				<path fill="#00BCF1" d="M1186,180.2c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1.1,0.5-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1185.2,177.3,1185,178.5,1186,180.2"/>
				<path fill="#00BCF1" d="M1178.5,179.2c1,1.7,2.9,3.2,4.1,3.4c1.3,0.2,1.5-1.1,0.5-2.8c-1-1.7-2.9-3.2-4.1-3.4
			C1177.8,176.2,1177.5,177.5,1178.5,179.2"/>
				<path fill="#00BCF1" d="M1171,178.2c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.4
			C1170.3,175.2,1170.1,176.5,1171,178.2"/>
				<path fill="#00BCF1" d="M1163.6,177.2c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.4
			C1162.8,174.3,1162.6,175.5,1163.6,177.2"/>
				<path fill="#00BCF1" d="M1156,176.2c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.4
			C1155.4,173.3,1155.1,174.5,1156,176.2"/>
				<path fill="#00BCF1" d="M1148.5,175.3c0.9,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1147.9,172.3,1147.6,173.6,1148.5,175.3"/>
				<path fill="#00BCF1" d="M1141,174.3c0.9,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.6-1.1,0.7-2.8c-0.9-1.7-2.8-3.2-4.1-3.3
			C1140.4,171.4,1140,172.6,1141,174.3"/>
				<path fill="#00BCF1" d="M1133.4,173.4c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.6-1.1,0.7-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1132.8,170.5,1132.5,171.7,1133.4,173.4"/>
				<path fill="#00BCF1" d="M1125.9,172.5c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.6-1.1,0.7-2.8c-0.9-1.7-2.7-3.2-4-3.3
			C1125.3,169.6,1125,170.8,1125.9,172.5"/>
				<path fill="#00BCF1" d="M1118.3,171.6c0.9,1.7,2.7,3.2,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.1-4-3.3
			C1117.7,168.7,1117.4,169.9,1118.3,171.6"/>
				<path fill="#00BCF1" d="M1110.7,170.7c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.7-1.1,0.8-2.8c-0.9-1.7-2.7-3.1-4-3.3
			C1110.2,167.8,1109.8,169.1,1110.7,170.7"/>
				<path fill="#00BCF1" d="M1103.1,169.9c0.9,1.7,2.6,3.1,4,3.3c1.3,0.1,1.7-1.1,0.8-2.8c-0.9-1.7-2.6-3.1-4-3.3
			C1102.6,167,1102.2,168.2,1103.1,169.9"/>
				<path fill="#00BCF1" d="M1095.4,169c0.8,1.7,2.6,3.1,3.9,3.3c1.3,0.1,1.7-1.1,0.9-2.8c-0.9-1.7-2.6-3.1-3.9-3.3
			C1095,166.2,1094.6,167.4,1095.4,169"/>
				<path fill="#00BCF1" d="M1087.8,168.2c0.8,1.7,2.6,3.1,3.9,3.3c1.3,0.1,1.7-1.1,0.9-2.8c-0.8-1.7-2.6-3.1-3.9-3.3
			C1087.4,165.3,1087,166.6,1087.8,168.2"/>
				<path fill="#00BCF1" d="M1080.2,167.4c0.8,1.7,2.6,3.1,3.9,3.3c1.3,0.1,1.7-1.1,0.9-2.8c-0.8-1.7-2.6-3.1-3.9-3.2
			C1079.7,164.5,1079.3,165.8,1080.2,167.4"/>
				<path fill="#00BCF1" d="M1072.5,166.6c0.8,1.7,2.5,3.1,3.9,3.2c1.3,0.1,1.8-1.1,0.9-2.8c-0.8-1.7-2.5-3.1-3.9-3.2
			C1072.1,163.8,1071.7,165,1072.5,166.6"/>
				<path fill="#00BCF1" d="M1064.8,165.9c0.8,1.7,2.5,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.5-3.1-3.8-3.2
			C1064.5,163,1064,164.2,1064.8,165.9"/>
				<path fill="#00BCF1" d="M1057.1,165.1c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1-2.8c-0.8-1.7-2.5-3.1-3.8-3.2
			C1056.8,162.3,1056.4,163.5,1057.1,165.1"/>
				<path fill="#00BCF1" d="M1049.4,164.4c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1-2.8c-0.8-1.6-2.5-3.1-3.8-3.2
			C1049.1,161.5,1048.7,162.8,1049.4,164.4"/>
				<path fill="#00BCF1" d="M1041.7,163.7c0.7,1.6,2.4,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1.1-2.8c-0.8-1.6-2.4-3.1-3.8-3.2
			S1041,162,1041.7,163.7"/>
				<path fill="#00BCF1" d="M1034,163c0.7,1.6,2.4,3.1,3.7,3.2c1.3,0.1,1.8-1.1,1.1-2.8c-0.7-1.6-2.4-3.1-3.7-3.2
			C1033.8,160.1,1033.3,161.4,1034,163"/>
				<path fill="#00BCF1" d="M1026.3,162.3c0.7,1.6,2.4,3.1,3.7,3.2c1.3,0.1,1.9-1.1,1.1-2.8c-0.7-1.6-2.4-3.1-3.7-3.2
			C1026.1,159.4,1025.5,160.7,1026.3,162.3"/>
				<path fill="#00BCF1" d="M1018.5,161.7c0.7,1.6,2.3,3.1,3.7,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.6-2.4-3.1-3.7-3.2
			C1018.3,158.8,1017.8,160,1018.5,161.7"/>
				<path fill="#00BCF1" d="M1010.8,161c0.7,1.6,2.3,3.1,3.7,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.6-2.3-3-3.7-3.2
			C1010.6,158.2,1010.1,159.4,1010.8,161"/>
				<path fill="#00BCF1" d="M1003,160.4c0.7,1.6,2.3,3,3.6,3.2c1.4,0.1,1.9-1.1,1.2-2.8c-0.7-1.6-2.3-3-3.6-3.1
			C1002.9,157.5,1002.3,158.8,1003,160.4"/>
				<path fill="#00BCF1" d="M995.2,159.8c0.6,1.6,2.3,3,3.6,3.1c1.4,0.1,1.9-1.1,1.3-2.8c-0.7-1.6-2.3-3-3.6-3.1
			C995.1,156.9,994.6,158.2,995.2,159.8"/>
				<path fill="#00BCF1" d="M987.4,159.2c0.6,1.6,2.2,3,3.6,3.1c1.4,0.1,1.9-1.1,1.3-2.8c-0.6-1.6-2.2-3-3.6-3.1
			C987.4,156.3,986.8,157.6,987.4,159.2"/>
				<path fill="#00BCF1" d="M979.6,158.6c0.6,1.6,2.2,3,3.6,3.1c1.4,0.1,2-1.1,1.3-2.8c-0.6-1.6-2.2-3-3.6-3.1
			C979.6,155.8,979,157,979.6,158.6"/>
				<path fill="#00BCF1" d="M971.8,158.1c0.6,1.6,2.2,3,3.5,3.1c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.2-3-3.5-3.1
			C971.8,155.2,971.2,156.5,971.8,158.1"/>
				<path fill="#00BCF1" d="M964,157.6c0.6,1.6,2.1,3,3.5,3.1c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.1-3-3.5-3.1
			C964.1,154.7,963.4,155.9,964,157.6"/>
				<path fill="#00BCF1" d="M956.2,157.1c0.6,1.6,2.1,3,3.5,3.1c1.4,0.1,2-1.2,1.4-2.8c-0.6-1.6-2.1-3-3.5-3.1
			C956.3,154.2,955.6,155.4,956.2,157.1"/>
				<path fill="#00BCF1" d="M948.3,156.6c0.5,1.6,2.1,3,3.4,3.1c1.4,0.1,2-1.2,1.5-2.8c-0.5-1.6-2.1-3-3.4-3.1
			C948.5,153.7,947.8,154.9,948.3,156.6"/>
				<path fill="#00BCF1" d="M940.5,156.1c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2-1.2,1.5-2.8c-0.5-1.6-2.1-3-3.4-3.1
			C940.6,153.2,940,154.5,940.5,156.1"/>
				<path fill="#00BCF1" d="M932.6,155.6c0.5,1.6,2,3,3.4,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-3-3.4-3.1
			C932.8,152.8,932.1,154,932.6,155.6"/>
				<path fill="#00BCF1" d="M924.8,155.2c0.5,1.6,2,3,3.3,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-3-3.3-3.1
			C925,152.4,924.3,153.6,924.8,155.2"/>
				<path fill="#00BCF1" d="M916.9,154.8c0.5,1.6,1.9,3,3.3,3.1c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-3-3.3-3
			C917.2,151.9,916.4,153.2,916.9,154.8"/>
				<path fill="#00BCF1" d="M909,154.4c0.4,1.6,1.9,3,3.3,3c1.4,0.1,2.1-1.2,1.7-2.8c-0.5-1.6-1.9-3-3.3-3
			C909.3,151.5,908.6,152.8,909,154.4"/>
				<path fill="#00BCF1" d="M901.1,154c0.4,1.6,1.9,3,3.3,3c1.4,0.1,2.1-1.2,1.7-2.8c-0.4-1.6-1.9-3-3.3-3
			C901.5,151.2,900.7,152.4,901.1,154"/>
				<path fill="#00BCF1" d="M893.2,153.7c0.4,1.6,1.8,3,3.2,3c1.4,0.1,2.2-1.2,1.7-2.8c-0.4-1.6-1.9-3-3.2-3
			C893.6,150.8,892.8,152.1,893.2,153.7"/>
				<path fill="#00BCF1" d="M885.3,153.3c0.4,1.6,1.8,3,3.2,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-3-3.2-3
			C885.8,150.5,885,151.7,885.3,153.3"/>
				<path fill="#00BCF1" d="M877.4,153c0.4,1.6,1.8,3,3.2,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-2.9-3.2-3
			C877.9,150.2,877.1,151.4,877.4,153"/>
				<path fill="#00BCF1" d="M869.5,152.7c0.3,1.6,1.7,3,3.1,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-2.9-3.1-3
			C870,149.9,869.2,151.1,869.5,152.7"/>
				<path fill="#00BCF1" d="M861.6,152.4c0.3,1.6,1.7,2.9,3.1,3c1.4,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.7-2.9-3.1-3
			C862.1,149.6,861.3,150.8,861.6,152.4"/>
				<path fill="#00BCF1" d="M853.7,152.2c0.3,1.6,1.7,2.9,3.1,3c1.4,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.7-2.9-3.1-3
			C854.2,149.3,853.4,150.6,853.7,152.2"/>
				<path fill="#00BCF1" d="M845.8,152c0.3,1.6,1.6,2.9,3,3c1.4,0,2.3-1.2,2-2.8c-0.3-1.6-1.7-2.9-3-3
			C846.4,149.1,845.5,150.3,845.8,152"/>
				<path fill="#00BCF1" d="M837.8,151.7c0.3,1.6,1.6,2.9,3,3c1.4,0,2.3-1.2,2-2.8c-0.3-1.6-1.6-2.9-3-3
			C838.5,148.9,837.6,150.1,837.8,151.7"/>
				<path fill="#00BCF1" d="M829.9,151.5c0.3,1.6,1.6,2.9,3,3s2.3-1.2,2-2.8c-0.3-1.6-1.6-2.9-3-3
			C830.6,148.7,829.6,149.9,829.9,151.5"/>
				<path fill="#00BCF1" d="M822,151.4c0.2,1.6,1.5,2.9,2.9,3c1.4,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.6-2.9-2.9-2.9
			C822.7,148.5,821.7,149.8,822,151.4"/>
				<path fill="#00BCF1" d="M814,151.2c0.2,1.6,1.5,2.9,2.9,2.9c1.4,0,2.3-1.3,2.1-2.9c-0.2-1.6-1.5-2.9-2.9-2.9
			C814.7,148.3,813.8,149.6,814,151.2"/>
				<path fill="#00BCF1" d="M806.1,151.1c0.2,1.6,1.5,2.9,2.9,2.9c1.4,0,2.3-1.3,2.1-2.9c-0.2-1.6-1.5-2.9-2.9-2.9
			C806.8,148.2,805.9,149.5,806.1,151.1"/>
				<path fill="#00BCF1" d="M798.1,150.9c0.2,1.6,1.4,2.9,2.8,2.9c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.5-2.9-2.8-2.9
			C798.9,148.1,797.9,149.3,798.1,150.9"/>
				<path fill="#00BCF1" d="M790.1,150.8c0.2,1.6,1.4,2.9,2.8,2.9c1.4,0,2.4-1.3,2.2-2.9c-0.2-1.6-1.4-2.9-2.8-2.9
			C791,148,790,149.2,790.1,150.8"/>
				<path fill="#00BCF1" d="M782.2,150.8c0.1,1.6,1.4,2.9,2.8,2.9c1.4,0,2.4-1.3,2.2-2.9c-0.1-1.6-1.4-2.9-2.8-2.9
			C783.1,147.9,782.1,149.2,782.2,150.8"/>
				<path fill="#00BCF1" d="M774.2,150.7c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.4-1.3,2.3-2.9c-0.1-1.6-1.3-2.9-2.7-2.9
			C775.1,147.8,774.1,149.1,774.2,150.7"/>
				<path fill="#00BCF1" d="M766.3,150.7c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.4-1.3,2.3-2.9c-0.1-1.6-1.3-2.9-2.7-2.9
			C767.2,147.8,766.2,149.1,766.3,150.7"/>
				<path fill="#00BCF1" d="M758.3,150.6c0.1,1.6,1.3,2.9,2.6,2.9c1.4,0,2.4-1.3,2.4-2.9c-0.1-1.6-1.3-2.9-2.6-2.9
			C759.3,147.8,758.2,149,758.3,150.6"/>
				<path fill="#00BCF1" d="M750.3,150.6c0.1,1.6,1.2,2.9,2.6,2.9c1.4,0,2.5-1.3,2.4-2.9s-1.2-2.9-2.6-2.9S750.3,149,750.3,150.6" />
				<path fill="#00BCF1" d="M742.3,150.7c0,1.6,1.2,2.9,2.6,2.9c1.4,0,2.5-1.3,2.4-2.9s-1.2-2.9-2.6-2.9
			C743.4,147.8,742.3,149.1,742.3,150.7"/>
				<path fill="#00BCF1" d="M734.4,150.7c0,1.6,1.2,2.9,2.5,2.9c1.4,0,2.5-1.3,2.5-2.9c0-1.6-1.2-2.9-2.5-2.9
			C735.5,147.8,734.3,149.1,734.4,150.7"/>
				<path fill="#00BCF1" d="M726.4,150.8c0,1.6,1.1,2.9,2.5,2.9c1.4,0,2.5-1.3,2.5-2.9c0-1.6-1.1-2.9-2.5-2.9
			C727.5,147.9,726.4,149.2,726.4,150.8"/>
				<path fill="#00BCF1" d="M1324.9,193.8c1.2,1.7,3.1,3.3,4.3,3.6c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.6
			C1323.7,190.8,1323.7,192,1324.9,193.8"/>
				<path fill="#00BCF1" d="M1303.6,190c1.2,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.5
			C1302.5,187,1302.5,188.2,1303.6,190"/>
				<path fill="#00BCF1" d="M1289.4,187.5c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3.1-3.3-4.3-3.5
			C1288.3,184.5,1288.2,185.7,1289.4,187.5"/>
				<path fill="#00BCF1" d="M1282.2,186.2c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3.1-3.3-4.3-3.5
			C1281.2,183.3,1281.1,184.5,1282.2,186.2"/>
				<path fill="#00BCF1" d="M1275,185c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3.1-3.3-4.3-3.5
			C1274,182.1,1273.9,183.3,1275,185"/>
				<path fill="#00BCF1" d="M1267.9,183.8c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.4-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1266.8,180.9,1266.7,182.1,1267.9,183.8"/>
				<path fill="#00BCF1" d="M1260.6,182.7c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1259.6,179.7,1259.5,180.9,1260.6,182.7"/>
				<path fill="#00BCF1" d="M1253.4,181.5c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1252.4,178.5,1252.3,179.8,1253.4,181.5"/>
				<path fill="#00BCF1" d="M1246.2,180.3c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1245.2,177.4,1245.1,178.6,1246.2,180.3"/>
				<path fill="#00BCF1" d="M1238.9,179.2c1.1,1.7,3,3.3,4.3,3.5c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1238,176.3,1237.8,177.5,1238.9,179.2"/>
				<path fill="#00BCF1" d="M1231.7,178.1c1.1,1.7,3,3.3,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7s-3-3.2-4.2-3.4
			C1230.7,175.1,1230.6,176.4,1231.7,178.1"/>
				<path fill="#00BCF1" d="M1224.4,177c1.1,1.7,3,3.2,4.2,3.4c1.3,0.2,1.4-1,0.4-2.7s-3-3.2-4.2-3.4
			C1223.5,174,1223.3,175.3,1224.4,177"/>
				<path fill="#00BCF1" d="M1217.1,175.9c1.1,1.7,2.9,3.2,4.2,3.4s1.4-1,0.4-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1216.2,172.9,1216,174.2,1217.1,175.9"/>
				<path fill="#00BCF1" d="M1209.7,174.8c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1208.9,171.9,1208.7,173.1,1209.7,174.8"/>
				<path fill="#00BCF1" d="M1202.4,173.7c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1201.6,170.8,1201.4,172,1202.4,173.7"/>
				<path fill="#00BCF1" d="M1195.1,172.7c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.9-3.2-4.2-3.4S1194,171,1195.1,172.7
			"/>
				<path fill="#00BCF1" d="M1187.7,171.6c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1186.9,168.7,1186.7,170,1187.7,171.6"/>
				<path fill="#00BCF1" d="M1180.3,170.6c1,1.7,2.9,3.2,4.1,3.4c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.1-3.4
			C1179.5,167.7,1179.3,168.9,1180.3,170.6"/>
				<path fill="#00BCF1" d="M1172.9,169.6c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.5-1.1,0.5-2.7c-1-1.7-2.8-3.2-4.1-3.4
			C1172.2,166.7,1171.9,167.9,1172.9,169.6"/>
				<path fill="#00BCF1" d="M1165.5,168.6c1,1.7,2.8,3.2,4.1,3.4c1.3,0.2,1.5-1.1,0.5-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1164.8,165.7,1164.5,167,1165.5,168.6"/>
				<path fill="#00BCF1" d="M1158.1,167.7c1,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.5-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1157.4,164.8,1157.1,166,1158.1,167.7"/>
				<path fill="#00BCF1" d="M1150.6,166.7c1,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1150,163.8,1149.7,165.1,1150.6,166.7"/>
				<path fill="#00BCF1" d="M1143.2,165.8c0.9,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1142.5,162.9,1142.2,164.1,1143.2,165.8"/>
				<path fill="#00BCF1" d="M1135.7,164.9c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1135.1,162,1134.8,163.2,1135.7,164.9"/>
				<path fill="#00BCF1" d="M1128.2,164c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1127.6,161.1,1127.3,162.3,1128.2,164"/>
				<path fill="#00BCF1" d="M1120.7,163.1c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1120.2,160.2,1119.8,161.4,1120.7,163.1"/>
				<path fill="#00BCF1" d="M1113.2,162.2c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1112.7,159.3,1112.4,160.5,1113.2,162.2"/>
				<path fill="#00BCF1" d="M1105.7,161.3c0.9,1.7,2.6,3.1,4,3.3c1.3,0.1,1.7-1.1,0.8-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1105.2,158.5,1104.9,159.7,1105.7,161.3"/>
				<path fill="#00BCF1" d="M1098.2,160.5c0.9,1.7,2.6,3.1,3.9,3.3c1.3,0.1,1.7-1.1,0.8-2.7c-0.9-1.7-2.6-3.1-3.9-3.2
			C1097.7,157.6,1097.3,158.9,1098.2,160.5"/>
				<path fill="#00BCF1" d="M1090.7,159.7c0.8,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.7-1.1,0.8-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1090.2,156.8,1089.8,158,1090.7,159.7"/>
				<path fill="#00BCF1" d="M1083.1,158.9c0.8,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.6-3.1-3.9-3.2
			C1082.7,156,1082.3,157.2,1083.1,158.9"/>
				<path fill="#00BCF1" d="M1075.5,158.1c0.8,1.6,2.5,3.1,3.9,3.2c1.3,0.1,1.7-1.1,0.9-2.7s-2.6-3.1-3.9-3.2
			C1075.1,155.2,1074.7,156.5,1075.5,158.1"/>
				<path fill="#00BCF1" d="M1068,157.3c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3.1-3.8-3.2
			C1067.6,154.5,1067.2,155.7,1068,157.3"/>
				<path fill="#00BCF1" d="M1060.4,156.6c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.7-1.1,1-2.7c-0.8-1.6-2.5-3.1-3.8-3.2
			C1060,153.7,1059.6,154.9,1060.4,156.6"/>
				<path fill="#00BCF1" d="M1052.8,155.9c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1-2.7c-0.8-1.6-2.5-3.1-3.8-3.2
			C1052.4,153,1052,154.2,1052.8,155.9"/>
				<path fill="#00BCF1" d="M1045.2,155.1c0.8,1.6,2.4,3.1,3.8,3.2c1.3,0.1,1.8-1.1,1-2.7s-2.5-3.1-3.8-3.2
			C1044.9,152.3,1044.4,153.5,1045.2,155.1"/>
				<path fill="#00BCF1" d="M1037.5,154.4c0.7,1.6,2.4,3.1,3.7,3.2c1.3,0.1,1.8-1.1,1-2.7c-0.7-1.6-2.4-3-3.7-3.2
			C1037.3,151.6,1036.8,152.8,1037.5,154.4"/>
				<path fill="#00BCF1" d="M1029.9,153.8c0.7,1.6,2.4,3,3.7,3.2c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.4-3-3.7-3.2
			C1029.7,150.9,1029.2,152.1,1029.9,153.8"/>
				<path fill="#00BCF1" d="M1022.3,153.1c0.7,1.6,2.4,3,3.7,3.2c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.4-3-3.7-3.1
			C1022,150.3,1021.5,151.5,1022.3,153.1"/>
				<path fill="#00BCF1" d="M1014.6,152.4c0.7,1.6,2.3,3,3.7,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-3-3.7-3.1
			C1014.4,149.6,1013.9,150.8,1014.6,152.4"/>
				<path fill="#00BCF1" d="M1006.9,151.8c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.9-1.1,1.2-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C1006.8,149,1006.3,150.2,1006.9,151.8"/>
				<path fill="#00BCF1" d="M999.3,151.2c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.9-1.1,1.2-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C999.2,148.4,998.6,149.6,999.3,151.2"/>
				<path fill="#00BCF1" d="M991.6,150.6c0.6,1.6,2.2,3,3.6,3.1c1.3,0.1,1.9-1.1,1.2-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C991.5,147.8,990.9,149,991.6,150.6"/>
				<path fill="#00BCF1" d="M983.9,150.1c0.6,1.6,2.2,3,3.6,3.1c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-3-3.6-3.1
			C983.8,147.2,983.3,148.4,983.9,150.1"/>
				<path fill="#00BCF1" d="M976.2,149.5c0.6,1.6,2.2,3,3.5,3.1c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-3-3.5-3.1
			C976.2,146.7,975.6,147.9,976.2,149.5"/>
				<path fill="#00BCF1" d="M968.5,149c0.6,1.6,2.2,3,3.5,3.1c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-3-3.5-3.1
			C968.5,146.1,967.9,147.4,968.5,149"/>
				<path fill="#00BCF1" d="M960.8,148.4c0.6,1.6,2.1,3,3.5,3.1c1.3,0.1,2-1.1,1.4-2.8c-0.6-1.6-2.1-3-3.5-3.1
			C960.8,145.6,960.2,146.8,960.8,148.4"/>
				<path fill="#00BCF1" d="M953,147.9c0.5,1.6,2.1,3,3.4,3.1c1.3,0.1,2-1.1,1.4-2.8c-0.6-1.6-2.1-3-3.4-3.1
			C953.1,145.1,952.5,146.3,953,147.9"/>
				<path fill="#00BCF1" d="M945.3,147.5c0.5,1.6,2.1,3,3.4,3.1c1.3,0.1,2-1.2,1.5-2.8c-0.5-1.6-2.1-3-3.4-3
			C945.4,144.6,944.8,145.9,945.3,147.5"/>
				<path fill="#00BCF1" d="M937.6,147c0.5,1.6,2,3,3.4,3c1.4,0.1,2-1.2,1.5-2.8c-0.5-1.6-2-3-3.4-3C937.7,144.2,937,145.4,937.6,147"
				/>
				<path fill="#00BCF1" d="M929.8,146.6c0.5,1.6,2,3,3.3,3c1.3,0.1,2-1.2,1.5-2.8c-0.5-1.6-2-3-3.3-3
			C930,143.7,929.3,145,929.8,146.6"/>
				<path fill="#00BCF1" d="M922.1,146.1c0.5,1.6,2,3,3.3,3c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-2-2.9-3.3-3
			C922.3,143.3,921.6,144.5,922.1,146.1"/>
				<path fill="#00BCF1" d="M914.3,145.7c0.5,1.6,1.9,2.9,3.3,3c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-1.9-2.9-3.3-3
			C914.5,142.9,913.8,144.1,914.3,145.7"/>
				<path fill="#00BCF1" d="M906.5,145.3c0.4,1.6,1.9,2.9,3.3,3c1.4,0.1,2.1-1.2,1.6-2.8c-0.5-1.6-1.9-2.9-3.3-3
			C906.8,142.5,906.1,143.8,906.5,145.3"/>
				<path fill="#00BCF1" d="M898.7,145c0.4,1.6,1.9,2.9,3.2,3c1.4,0.1,2.1-1.2,1.7-2.8c-0.4-1.6-1.9-2.9-3.2-3
			C899.1,142.2,898.3,143.4,898.7,145"/>
				<path fill="#00BCF1" d="M891,144.6c0.4,1.6,1.8,2.9,3.2,3c1.4,0.1,2.1-1.2,1.7-2.8c-0.4-1.6-1.8-2.9-3.2-3
			C891.3,141.8,890.6,143,891,144.6"/>
				<path fill="#00BCF1" d="M883.2,144.3c0.4,1.6,1.8,2.9,3.2,3c1.4,0.1,2.1-1.2,1.7-2.8c-0.4-1.6-1.8-2.9-3.2-3
			C883.6,141.5,882.8,142.7,883.2,144.3"/>
				<path fill="#00BCF1" d="M875.4,144c0.4,1.6,1.8,2.9,3.1,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.8-2.9-3.1-3
			C875.8,141.2,875,142.4,875.4,144"/>
				<path fill="#00BCF1" d="M867.6,143.7c0.3,1.6,1.7,2.9,3.1,3c1.4,0.1,2.2-1.2,1.8-2.8c-0.4-1.6-1.7-2.9-3.1-3
			C868,140.9,867.2,142.1,867.6,143.7"/>
				<path fill="#00BCF1" d="M859.8,143.4c0.3,1.6,1.7,2.9,3.1,3c1.4,0,2.2-1.2,1.8-2.8c-0.3-1.6-1.7-2.9-3.1-2.9
			C860.3,140.6,859.4,141.9,859.8,143.4"/>
				<path fill="#00BCF1" d="M851.9,143.2c0.3,1.6,1.7,2.9,3,2.9c1.4,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.7-2.9-3-2.9
			C852.5,140.4,851.6,141.6,851.9,143.2"/>
				<path fill="#00BCF1" d="M844.1,143c0.3,1.6,1.6,2.9,3,2.9c1.4,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.6-2.9-3-2.9
			C844.7,140.1,843.8,141.4,844.1,143"/>
				<path fill="#00BCF1" d="M836.3,142.7c0.3,1.6,1.6,2.9,3,2.9c1.4,0,2.2-1.2,2-2.8c-0.3-1.6-1.6-2.9-3-2.9
			C836.9,139.9,836,141.2,836.3,142.7"/>
				<path fill="#00BCF1" d="M828.5,142.5c0.3,1.6,1.6,2.9,2.9,2.9c1.4,0,2.3-1.2,2-2.8c-0.3-1.6-1.6-2.9-2.9-2.9
			C829.1,139.7,828.2,141,828.5,142.5"/>
				<path fill="#00BCF1" d="M820.6,142.4c0.2,1.6,1.5,2.9,2.9,2.9c1.4,0,2.3-1.2,2-2.8c-0.2-1.6-1.5-2.9-2.9-2.9
			C821.3,139.5,820.4,140.8,820.6,142.4"/>
				<path fill="#00BCF1" d="M812.8,142.2c0.2,1.6,1.5,2.9,2.9,2.9c1.4,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.5-2.9-2.9-2.9
			C813.5,139.4,812.6,140.6,812.8,142.2"/>
				<path fill="#00BCF1" d="M805,142.1c0.2,1.6,1.5,2.9,2.8,2.9c1.4,0,2.3-1.2,2.1-2.8s-1.5-2.9-2.8-2.9
			C805.7,139.2,804.8,140.5,805,142.1"/>
				<path fill="#00BCF1" d="M797.1,142c0.2,1.6,1.4,2.9,2.8,2.9c1.4,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.4-2.9-2.8-2.9
			C797.9,139.1,797,140.4,797.1,142"/>
				<path fill="#00BCF1" d="M789.3,141.9c0.2,1.6,1.4,2.9,2.8,2.9c1.4,0,2.3-1.3,2.2-2.8c-0.2-1.6-1.4-2.9-2.8-2.9
			C790.1,139,789.1,140.3,789.3,141.9"/>
				<path fill="#00BCF1" d="M781.4,141.8c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.4-1.3,2.2-2.8c-0.1-1.6-1.4-2.9-2.7-2.9
			C782.3,139,781.3,140.2,781.4,141.8"/>
				<path fill="#00BCF1" d="M773.6,141.7c0.1,1.6,1.3,2.9,2.7,2.9c1.4,0,2.4-1.3,2.3-2.8c-0.1-1.6-1.3-2.9-2.7-2.9
			C774.5,138.9,773.5,140.2,773.6,141.7"/>
				<path fill="#00BCF1" d="M765.7,141.7c0.1,1.6,1.3,2.9,2.6,2.9c1.4,0,2.4-1.3,2.3-2.8c-0.1-1.6-1.3-2.9-2.6-2.9
			C766.7,138.9,765.6,140.1,765.7,141.7"/>
				<path fill="#00BCF1" d="M757.9,141.7c0.1,1.6,1.2,2.9,2.6,2.9c1.4,0,2.4-1.3,2.3-2.9c-0.1-1.6-1.3-2.8-2.6-2.9
			C758.8,138.8,757.8,140.1,757.9,141.7"/>
				<path fill="#00BCF1" d="M750,141.7c0.1,1.6,1.2,2.9,2.6,2.9c1.4,0,2.4-1.3,2.4-2.9c-0.1-1.6-1.2-2.8-2.6-2.8
			C751,138.8,750,140.1,750,141.7"/>
				<path fill="#00BCF1" d="M742.1,141.7c0,1.6,1.2,2.9,2.5,2.8c1.4,0,2.4-1.3,2.4-2.9c0-1.6-1.2-2.8-2.5-2.8
			C743.2,138.9,742.1,140.1,742.1,141.7"/>
				<path fill="#00BCF1" d="M734.3,141.7c0,1.6,1.1,2.9,2.5,2.8c1.4,0,2.5-1.3,2.4-2.9s-1.1-2.8-2.5-2.8
			C735.4,138.9,734.3,140.2,734.3,141.7"/>
				<path fill="#00BCF1" d="M726.4,141.8c0,1.6,1.1,2.8,2.5,2.8c1.4,0,2.5-1.3,2.5-2.9c0-1.6-1.1-2.8-2.5-2.8
			C727.5,138.9,726.4,140.2,726.4,141.8"/>
				<path fill="#00BCF1" d="M718.6,141.9c0,1.6,1.1,2.8,2.4,2.8c1.4,0,2.5-1.3,2.5-2.9c0-1.6-1.1-2.8-2.4-2.8
			C719.7,139,718.6,140.3,718.6,141.9"/>
				<path fill="#00BCF1" d="M1297.1,180.1c1.2,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.1-2.8c-1.2-1.7-3.1-3.3-4.3-3.5
			C1296,177.2,1296,178.4,1297.1,180.1"/>
				<path fill="#00BCF1" d="M1290,178.9c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.1-2.7c-1.2-1.7-3.1-3.3-4.3-3.5
			C1288.9,176,1288.9,177.2,1290,178.9"/>
				<path fill="#00BCF1" d="M1282.9,177.7c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7s-3.1-3.3-4.3-3.5
			C1281.9,174.7,1281.8,176,1282.9,177.7"/>
				<path fill="#00BCF1" d="M1275.8,176.5c1.1,1.7,3.1,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3.1-3.3-4.3-3.5
			C1274.8,173.5,1274.7,174.8,1275.8,176.5"/>
				<path fill="#00BCF1" d="M1268.7,175.3c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1267.6,172.3,1267.5,173.6,1268.7,175.3"/>
				<path fill="#00BCF1" d="M1261.5,174.1c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1260.5,171.2,1260.4,172.4,1261.5,174.1"/>
				<path fill="#00BCF1" d="M1254.4,173c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1253.4,170,1253.3,171.2,1254.4,173"/>
				<path fill="#00BCF1" d="M1247.2,171.8c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1246.2,168.9,1246.1,170.1,1247.2,171.8"/>
				<path fill="#00BCF1" d="M1240,170.7c1.1,1.7,3,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.3-4.2-3.4
			C1239,167.7,1238.9,169,1240,170.7"/>
				<path fill="#00BCF1" d="M1232.8,169.6c1.1,1.7,3,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1231.8,166.6,1231.7,167.9,1232.8,169.6"/>
				<path fill="#00BCF1" d="M1225.6,168.4c1.1,1.7,3,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1224.6,165.5,1224.5,166.7,1225.6,168.4"/>
				<path fill="#00BCF1" d="M1218.3,167.4c1.1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1217.4,164.4,1217.3,165.7,1218.3,167.4"/>
				<path fill="#00BCF1" d="M1211.1,166.3c1.1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.4-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1210.2,163.4,1210,164.6,1211.1,166.3"/>
				<path fill="#00BCF1" d="M1203.8,165.2c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.4-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1202.9,162.3,1202.8,163.5,1203.8,165.2"/>
				<path fill="#00BCF1" d="M1196.5,164.2c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1195.7,161.3,1195.5,162.5,1196.5,164.2"/>
				<path fill="#00BCF1" d="M1189.3,163.2c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.9-3.2-4.2-3.4
			C1188.4,160.3,1188.2,161.5,1189.3,163.2"/>
				<path fill="#00BCF1" d="M1182,162.1c1,1.7,2.9,3.2,4.1,3.4c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.1-3.4
			C1181.1,159.2,1180.9,160.5,1182,162.1"/>
				<path fill="#00BCF1" d="M1174.6,161.1c1,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.9-3.2-4.1-3.3
			C1173.8,158.3,1173.6,159.5,1174.6,161.1"/>
				<path fill="#00BCF1" d="M1167.3,160.2c1,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1166.5,157.3,1166.3,158.5,1167.3,160.2"/>
				<path fill="#00BCF1" d="M1160,159.2c1,1.7,2.8,3.2,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.8-3.2-4.1-3.3
			C1159.2,156.3,1159,157.5,1160,159.2"/>
				<path fill="#00BCF1" d="M1152.6,158.2c1,1.7,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1.1,0.6-2.7c-1-1.7-2.8-3.1-4.1-3.3
			S1151.6,156.6,1152.6,158.2"/>
				<path fill="#00BCF1" d="M1145.2,157.3c1,1.7,2.8,3.1,4,3.3c1.3,0.2,1.5-1.1,0.6-2.7c-1-1.7-2.8-3.1-4-3.3
			C1144.5,154.4,1144.3,155.6,1145.2,157.3"/>
				<path fill="#00BCF1" d="M1137.9,156.4c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.6-2.7c-1-1.7-2.8-3.1-4-3.3
			C1137.2,153.5,1136.9,154.7,1137.9,156.4"/>
				<path fill="#00BCF1" d="M1130.5,155.5c0.9,1.7,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.6-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1129.8,152.6,1129.5,153.8,1130.5,155.5"/>
				<path fill="#00BCF1" d="M1123.1,154.6c0.9,1.6,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.7-2.7-3.1-4-3.3
			C1122.4,151.7,1122.1,153,1123.1,154.6"/>
				<path fill="#00BCF1" d="M1115.6,153.7c0.9,1.6,2.7,3.1,4,3.3c1.3,0.2,1.6-1.1,0.7-2.7c-0.9-1.6-2.7-3.1-4-3.3
			C1115.1,150.9,1114.7,152.1,1115.6,153.7"/>
				<path fill="#00BCF1" d="M1108.2,152.9c0.9,1.6,2.7,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.7-3.1-3.9-3.2
			C1107.7,150,1107.3,151.2,1108.2,152.9"/>
				<path fill="#00BCF1" d="M1100.8,152c0.9,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1100.2,149.2,1099.9,150.4,1100.8,152"/>
				<path fill="#00BCF1" d="M1093.3,151.2c0.9,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.8-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1092.8,148.4,1092.5,149.6,1093.3,151.2"/>
				<path fill="#00BCF1" d="M1085.9,150.4c0.8,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.7-1.1,0.8-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1085.4,147.6,1085,148.8,1085.9,150.4"/>
				<path fill="#00BCF1" d="M1078.4,149.6c0.8,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.7-1.1,0.8-2.7c-0.8-1.6-2.6-3.1-3.9-3.2
			C1077.9,146.8,1077.6,148,1078.4,149.6"/>
				<path fill="#00BCF1" d="M1070.9,148.9c0.8,1.6,2.5,3.1,3.8,3.2c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3.1-3.8-3.2
			C1070.5,146,1070.1,147.2,1070.9,148.9"/>
				<path fill="#00BCF1" d="M1063.4,148.1c0.8,1.6,2.5,3,3.8,3.2c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.8-3.2
			C1063,145.3,1062.6,146.5,1063.4,148.1"/>
				<path fill="#00BCF1" d="M1055.9,147.4c0.8,1.6,2.5,3,3.8,3.2c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.8-3.2
			C1055.6,144.6,1055.1,145.8,1055.9,147.4"/>
				<path fill="#00BCF1" d="M1048.4,146.7c0.8,1.6,2.5,3,3.8,3.2c1.3,0.1,1.7-1.1,1-2.7c-0.8-1.6-2.5-3-3.8-3.2
			C1048.1,143.8,1047.6,145,1048.4,146.7"/>
				<path fill="#00BCF1" d="M1040.9,146c0.8,1.6,2.4,3,3.7,3.1c1.3,0.1,1.8-1.1,1-2.7c-0.8-1.6-2.4-3-3.7-3.1
			C1040.6,143.1,1040.1,144.3,1040.9,146"/>
				<path fill="#00BCF1" d="M1033.4,145.3c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.8-1.1,1-2.7c-0.8-1.6-2.4-3-3.7-3.1
			C1033.1,142.5,1032.6,143.7,1033.4,145.3"/>
				<path fill="#00BCF1" d="M1025.8,144.6c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.4-3-3.7-3.1
			C1025.6,141.8,1025.1,143,1025.8,144.6"/>
				<path fill="#00BCF1" d="M1018.3,144c0.7,1.6,2.3,3,3.7,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.4-3-3.7-3.1
			C1018,141.2,1017.6,142.4,1018.3,144"/>
				<path fill="#00BCF1" d="M1010.7,143.3c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C1010.5,140.5,1010,141.7,1010.7,143.3"/>
				<path fill="#00BCF1" d="M1003.1,142.7c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.2-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C1003,139.9,1002.5,141.1,1003.1,142.7"/>
				<path fill="#00BCF1" d="M995.6,142.1c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.2-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C995.4,139.3,994.9,140.5,995.6,142.1"/>
				<path fill="#00BCF1" d="M988,141.6c0.6,1.6,2.2,3,3.5,3.1c1.3,0.1,1.9-1.1,1.2-2.7c-0.6-1.6-2.2-3-3.5-3.1
			C987.9,138.8,987.3,140,988,141.6"/>
				<path fill="#00BCF1" d="M980.4,141c0.6,1.6,2.2,3,3.5,3.1c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-3-3.5-3.1
			C980.3,138.2,979.8,139.4,980.4,141"/>
				<path fill="#00BCF1" d="M972.8,140.5c0.6,1.6,2.2,3,3.5,3.1c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-3-3.5-3
			C972.7,137.7,972.2,138.9,972.8,140.5"/>
				<path fill="#00BCF1" d="M965.2,139.9c0.6,1.6,2.1,3,3.5,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.1-2.9-3.5-3
			C965.2,137.1,964.6,138.3,965.2,139.9"/>
				<path fill="#00BCF1" d="M957.5,139.4c0.6,1.6,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.4-2.7c-0.6-1.6-2.1-2.9-3.4-3
			C957.6,136.6,957,137.8,957.5,139.4"/>
				<path fill="#00BCF1" d="M949.9,138.9c0.5,1.6,2.1,2.9,3.4,3c1.3,0.1,2-1.1,1.4-2.7c-0.6-1.6-2.1-2.9-3.4-3
			C950,136.1,949.4,137.4,949.9,138.9"/>
				<path fill="#00BCF1" d="M942.3,138.5c0.5,1.6,2,2.9,3.4,3c1.3,0.1,2-1.1,1.4-2.7c-0.5-1.6-2.1-2.9-3.4-3
			C942.4,135.7,941.8,136.9,942.3,138.5"/>
				<path fill="#00BCF1" d="M934.7,138c0.5,1.6,2,2.9,3.3,3c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.6-2-2.9-3.3-3
			C934.8,135.2,934.1,136.4,934.7,138"/>
				<path fill="#00BCF1" d="M927,137.6c0.5,1.6,2,2.9,3.3,3c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.6-2-2.9-3.3-3
			C927.2,134.8,926.5,136,927,137.6"/>
				<path fill="#00BCF1" d="M919.3,137.2c0.5,1.6,1.9,2.9,3.3,3c1.3,0.1,2-1.2,1.5-2.7s-2-2.9-3.3-3
			C919.6,134.4,918.9,135.6,919.3,137.2"/>
				<path fill="#00BCF1" d="M911.7,136.8c0.5,1.6,1.9,2.9,3.2,3c1.3,0.1,2-1.2,1.6-2.7c-0.5-1.6-1.9-2.9-3.2-3
			C911.9,134,911.2,135.2,911.7,136.8"/>
				<path fill="#00BCF1" d="M904,136.4c0.4,1.6,1.9,2.9,3.2,3c1.3,0.1,2.1-1.2,1.6-2.7c-0.5-1.6-1.9-2.9-3.2-3
			C904.3,133.6,903.6,134.8,904,136.4"/>
				<path fill="#00BCF1" d="M896.4,136c0.4,1.6,1.8,2.9,3.2,3c1.3,0.1,2.1-1.2,1.6-2.7c-0.4-1.6-1.9-2.9-3.2-3
			C896.7,133.3,895.9,134.5,896.4,136"/>
				<path fill="#00BCF1" d="M888.7,135.7c0.4,1.6,1.8,2.9,3.2,3c1.3,0.1,2.1-1.2,1.7-2.7c-0.4-1.6-1.8-2.9-3.2-2.9
			C889,132.9,888.3,134.1,888.7,135.7"/>
				<path fill="#00BCF1" d="M881,135.4c0.4,1.6,1.8,2.9,3.1,2.9c1.3,0.1,2.1-1.2,1.7-2.7c-0.4-1.6-1.8-2.9-3.1-2.9
			C881.4,132.6,880.6,133.8,881,135.4"/>
				<path fill="#00BCF1" d="M873.3,135.1c0.4,1.6,1.7,2.9,3.1,2.9c1.3,0.1,2.1-1.2,1.7-2.7c-0.4-1.6-1.8-2.9-3.1-2.9
			C873.7,132.3,872.9,133.5,873.3,135.1"/>
				<path fill="#00BCF1" d="M865.6,134.8c0.3,1.6,1.7,2.9,3.1,2.9c1.3,0,2.1-1.2,1.8-2.8c-0.4-1.6-1.7-2.9-3.1-2.9
			C866.1,132,865.3,133.2,865.6,134.8"/>
				<path fill="#00BCF1" d="M857.9,134.5c0.3,1.6,1.7,2.9,3,2.9c1.3,0,2.2-1.2,1.8-2.8c-0.3-1.6-1.7-2.9-3-2.9
			C858.4,131.8,857.6,133,857.9,134.5"/>
				<path fill="#00BCF1" d="M850.2,134.3c0.3,1.6,1.6,2.9,3,2.9c1.3,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.7-2.9-3-2.9
			C850.7,131.5,849.9,132.7,850.2,134.3"/>
				<path fill="#00BCF1" d="M842.5,134.1c0.3,1.6,1.6,2.9,3,2.9c1.3,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.6-2.9-3-2.9
			C843.1,131.3,842.2,132.5,842.5,134.1"/>
				<path fill="#00BCF1" d="M834.8,133.9c0.3,1.6,1.6,2.9,2.9,2.9c1.3,0,2.2-1.2,1.9-2.8c-0.3-1.6-1.6-2.8-2.9-2.9
			C835.4,131.1,834.5,132.3,834.8,133.9"/>
				<path fill="#00BCF1" d="M827.1,133.7c0.2,1.6,1.5,2.9,2.9,2.9c1.3,0,2.2-1.2,2-2.8c-0.3-1.6-1.6-2.8-2.9-2.9
			C827.7,130.9,826.8,132.1,827.1,133.7"/>
				<path fill="#00BCF1" d="M819.3,133.5c0.2,1.6,1.5,2.8,2.9,2.9c1.3,0,2.2-1.2,2-2.8c-0.2-1.6-1.5-2.8-2.9-2.9
			C820,130.7,819.1,131.9,819.3,133.5"/>
				<path fill="#00BCF1" d="M811.6,133.4c0.2,1.6,1.5,2.8,2.8,2.9c1.3,0,2.3-1.2,2-2.8c-0.2-1.6-1.5-2.8-2.8-2.9
			C812.3,130.6,811.4,131.8,811.6,133.4"/>
				<path fill="#00BCF1" d="M803.9,133.2c0.2,1.6,1.4,2.8,2.8,2.9c1.3,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.5-2.8-2.8-2.9
			S803.7,131.7,803.9,133.2"/>
				<path fill="#00BCF1" d="M796.2,133.1c0.2,1.6,1.4,2.8,2.8,2.9c1.3,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.4-2.8-2.8-2.8
			C796.9,130.3,796,131.6,796.2,133.1"/>
				<path fill="#00BCF1" d="M788.4,133c0.1,1.6,1.4,2.8,2.7,2.8c1.3,0,2.3-1.2,2.1-2.8c-0.2-1.6-1.4-2.8-2.7-2.8
			C789.2,130.2,788.3,131.5,788.4,133"/>
				<path fill="#00BCF1" d="M780.7,132.9c0.1,1.6,1.3,2.8,2.7,2.8c1.3,0,2.3-1.2,2.2-2.8c-0.1-1.6-1.3-2.8-2.7-2.8
			C781.5,130.1,780.6,131.4,780.7,132.9"/>
				<path fill="#00BCF1" d="M772.9,132.9c0.1,1.6,1.3,2.8,2.6,2.8c1.3,0,2.3-1.2,2.2-2.8c-0.1-1.6-1.3-2.8-2.6-2.8
			C773.8,130.1,772.8,131.3,772.9,132.9"/>
				<path fill="#00BCF1" d="M765.2,132.9c0.1,1.6,1.3,2.8,2.6,2.8c1.4,0,2.4-1.3,2.3-2.8c-0.1-1.6-1.3-2.8-2.6-2.8
			C766.1,130,765.1,131.3,765.2,132.9"/>
				<path fill="#00BCF1" d="M757.5,132.8c0.1,1.6,1.2,2.8,2.6,2.8c1.3,0,2.4-1.3,2.3-2.8c-0.1-1.6-1.2-2.8-2.6-2.8
			C758.4,130,757.4,131.3,757.5,132.8"/>
				<path fill="#00BCF1" d="M749.7,132.8c0.1,1.6,1.2,2.8,2.5,2.8c1.4,0,2.4-1.3,2.3-2.8c-0.1-1.6-1.2-2.8-2.5-2.8
			C750.7,130,749.7,131.3,749.7,132.8"/>
				<path fill="#00BCF1" d="M742,132.9c0,1.6,1.2,2.8,2.5,2.8c1.3,0,2.4-1.3,2.4-2.8c0-1.6-1.2-2.8-2.5-2.8
			C743,130,741.9,131.3,742,132.9"/>
				<path fill="#00BCF1" d="M734.2,132.9c0,1.6,1.1,2.8,2.5,2.8c1.4,0,2.4-1.3,2.4-2.8c0-1.6-1.1-2.8-2.5-2.8
			C735.3,130.1,734.2,131.3,734.2,132.9"/>
				<path fill="#00BCF1" d="M1255.2,164.4c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.3-4.3-3.5
			C1254.2,161.5,1254.1,162.7,1255.2,164.4"/>
				<path fill="#00BCF1" d="M1248.1,163.3c1.1,1.7,3,3.3,4.3,3.5c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.3-4.2-3.4
			C1247.1,160.4,1247,161.6,1248.1,163.3"/>
				<path fill="#00BCF1" d="M1241,162.2c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1240,159.2,1239.9,160.5,1241,162.2"/>
				<path fill="#00BCF1" d="M1233.8,161.1c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1232.8,158.1,1232.7,159.4,1233.8,161.1"/>
				<path fill="#00BCF1" d="M1226.7,160c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1225.7,157,1225.6,158.3,1226.7,160"/>
				<path fill="#00BCF1" d="M1219.5,158.9c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			S1218.4,157.2,1219.5,158.9"/>
				<path fill="#00BCF1" d="M1212.3,157.8c1.1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1211.4,154.9,1211.2,156.1,1212.3,157.8"/>
				<path fill="#00BCF1" d="M1205.1,156.8c1.1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.3-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1204.2,153.9,1204.1,155.1,1205.1,156.8"/>
				<path fill="#00BCF1" d="M1197.9,155.7c1,1.7,2.9,3.2,4.2,3.4c1.3,0.2,1.4-1,0.4-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1197,152.8,1196.9,154,1197.9,155.7"/>
				<path fill="#00BCF1" d="M1190.7,154.7c1,1.7,2.9,3.2,4.1,3.4c1.3,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.2-4.1-3.3
			C1189.8,151.8,1189.7,153,1190.7,154.7"/>
				<path fill="#00BCF1" d="M1183.5,153.7c1,1.7,2.9,3.2,4.1,3.3c1.3,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.2-4.1-3.3
			S1182.4,152,1183.5,153.7"/>
				<path fill="#00BCF1" d="M1176.2,152.7c1,1.7,2.9,3.2,4.1,3.3c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.9-3.2-4.1-3.3
			C1175.4,149.8,1175.2,151,1176.2,152.7"/>
				<path fill="#00BCF1" d="M1169,151.7c1,1.7,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.8-3.1-4.1-3.3
			C1168.2,148.8,1168,150.1,1169,151.7"/>
				<path fill="#00BCF1" d="M1161.7,150.8c1,1.7,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.8-3.1-4.1-3.3
			C1160.9,147.9,1160.7,149.1,1161.7,150.8"/>
				<path fill="#00BCF1" d="M1154.4,149.8c1,1.7,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.7-2.8-3.1-4.1-3.3
			C1153.7,146.9,1153.5,148.2,1154.4,149.8"/>
				<path fill="#00BCF1" d="M1147.2,148.9c1,1.7,2.8,3.1,4,3.3c1.3,0.2,1.5-1.1,0.5-2.7c-1-1.7-2.8-3.1-4-3.3
			C1146.4,146,1146.2,147.2,1147.2,148.9"/>
				<path fill="#00BCF1" d="M1139.9,148c0.9,1.6,2.8,3.1,4,3.3c1.3,0.2,1.5-1.1,0.6-2.7c-1-1.6-2.8-3.1-4-3.3
			C1139.2,145.1,1138.9,146.3,1139.9,148"/>
				<path fill="#00BCF1" d="M1132.5,147.1c0.9,1.6,2.7,3.1,4,3.3c1.3,0.2,1.5-1.1,0.6-2.7c-0.9-1.6-2.7-3.1-4-3.3
			C1131.9,144.2,1131.6,145.4,1132.5,147.1"/>
				<path fill="#00BCF1" d="M1125.2,146.2c0.9,1.6,2.7,3.1,4,3.2c1.3,0.2,1.6-1.1,0.6-2.7c-0.9-1.6-2.7-3.1-4-3.2
			C1124.6,143.3,1124.3,144.5,1125.2,146.2"/>
				<path fill="#00BCF1" d="M1117.9,145.3c0.9,1.6,2.7,3.1,4,3.2c1.3,0.2,1.6-1.1,0.6-2.7c-0.9-1.6-2.7-3.1-4-3.2
			S1117,143.7,1117.9,145.3"/>
				<path fill="#00BCF1" d="M1110.6,144.5c0.9,1.6,2.7,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.7-3.1-3.9-3.2
			C1110,141.6,1109.7,142.8,1110.6,144.5"/>
				<path fill="#00BCF1" d="M1103.2,143.6c0.9,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1102.6,140.8,1102.3,142,1103.2,143.6"/>
				<path fill="#00BCF1" d="M1095.9,142.8c0.9,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3.1-3.9-3.2
			C1095.3,140,1095,141.2,1095.9,142.8"/>
				<path fill="#00BCF1" d="M1088.5,142c0.9,1.6,2.6,3.1,3.9,3.2c1.3,0.1,1.6-1.1,0.8-2.7c-0.9-1.6-2.6-3-3.9-3.2
			C1088,139.2,1087.6,140.4,1088.5,142"/>
				<path fill="#00BCF1" d="M1081.1,141.2c0.8,1.6,2.6,3,3.9,3.2c1.3,0.1,1.6-1.1,0.8-2.7c-0.9-1.6-2.6-3-3.9-3.2
			C1080.6,138.4,1080.3,139.6,1081.1,141.2"/>
				<path fill="#00BCF1" d="M1073.7,140.5c0.8,1.6,2.5,3,3.8,3.2c1.3,0.1,1.6-1.1,0.8-2.7c-0.8-1.6-2.6-3-3.8-3.2
			C1073.3,137.7,1072.9,138.9,1073.7,140.5"/>
				<path fill="#00BCF1" d="M1066.3,139.7c0.8,1.6,2.5,3,3.8,3.2c1.3,0.1,1.7-1.1,0.8-2.7c-0.8-1.6-2.5-3-3.8-3.1
			C1065.9,136.9,1065.5,138.1,1066.3,139.7"/>
				<path fill="#00BCF1" d="M1058.9,139c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.8-3.1
			C1058.5,136.2,1058.1,137.4,1058.9,139"/>
				<path fill="#00BCF1" d="M1051.5,138.3c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.8-3.1
			C1051.1,135.5,1050.7,136.7,1051.5,138.3"/>
				<path fill="#00BCF1" d="M1044.1,137.6c0.8,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,0.9-2.7s-2.4-3-3.7-3.1
			C1043.7,134.8,1043.3,136,1044.1,137.6"/>
				<path fill="#00BCF1" d="M1036.6,136.9c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,1-2.7c-0.8-1.6-2.4-3-3.7-3.1
			C1036.3,134.1,1035.9,135.3,1036.6,136.9"/>
				<path fill="#00BCF1" d="M1029.2,136.2c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,1-2.7c-0.7-1.6-2.4-3-3.7-3.1
			C1028.9,133.4,1028.5,134.6,1029.2,136.2"/>
				<path fill="#00BCF1" d="M1021.7,135.6c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.8-1.1,1-2.7c-0.7-1.6-2.4-3-3.7-3.1
			C1021.5,132.8,1021,134,1021.7,135.6"/>
				<path fill="#00BCF1" d="M1014.3,134.9c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C1014.1,132.2,1013.6,133.4,1014.3,134.9"/>
				<path fill="#00BCF1" d="M1006.8,134.3c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-3-3.6-3.1
			C1006.6,131.5,1006.1,132.7,1006.8,134.3"/>
				<path fill="#00BCF1" d="M999.3,133.7c0.7,1.6,2.3,3,3.6,3.1c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-2.9-3.6-3
			C999.2,131,998.7,132.1,999.3,133.7"/>
				<path fill="#00BCF1" d="M991.9,133.2c0.7,1.6,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.2-2.7c-0.7-1.6-2.2-2.9-3.5-3
			C991.7,130.4,991.2,131.6,991.9,133.2"/>
				<path fill="#00BCF1" d="M984.4,132.6c0.6,1.6,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.2-2.7c-0.6-1.6-2.2-2.9-3.5-3
			C984.3,129.8,983.7,131,984.4,132.6"/>
				<path fill="#00BCF1" d="M976.9,132c0.6,1.6,2.2,2.9,3.5,3c1.3,0.1,1.9-1.1,1.2-2.7c-0.6-1.6-2.2-2.9-3.5-3
			C976.8,129.3,976.3,130.5,976.9,132"/>
				<path fill="#00BCF1" d="M969.4,131.5c0.6,1.6,2.1,2.9,3.5,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.2-2.9-3.5-3
			C969.3,128.8,968.8,129.9,969.4,131.5"/>
				<path fill="#00BCF1" d="M961.9,131c0.6,1.6,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.1-2.9-3.4-3
			C961.9,128.2,961.3,129.4,961.9,131"/>
				<path fill="#00BCF1" d="M954.3,130.5c0.6,1.6,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.6-2.1-2.9-3.4-3
			C954.4,127.8,953.8,129,954.3,130.5"/>
				<path fill="#00BCF1" d="M946.8,130c0.5,1.6,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.4-2.7c-0.6-1.6-2.1-2.9-3.4-3
			C946.9,127.3,946.3,128.5,946.8,130"/>
				<path fill="#00BCF1" d="M939.3,129.6c0.5,1.6,2,2.9,3.3,3c1.3,0.1,1.9-1.1,1.4-2.7c-0.5-1.6-2-2.9-3.3-3
			C939.4,126.8,938.8,128,939.3,129.6"/>
				<path fill="#00BCF1" d="M931.7,129.2c0.5,1.6,2,2.9,3.3,3c1.3,0.1,2-1.1,1.4-2.7c-0.5-1.6-2-2.9-3.3-3
			C931.9,126.4,931.2,127.6,931.7,129.2"/>
				<path fill="#00BCF1" d="M924.2,128.7c0.5,1.6,2,2.9,3.3,3c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.6-2-2.9-3.3-2.9
			C924.4,126,923.7,127.2,924.2,128.7"/>
				<path fill="#00BCF1" d="M916.7,128.3c0.5,1.6,1.9,2.9,3.2,2.9c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.6-1.9-2.9-3.2-2.9
			C916.8,125.6,916.2,126.8,916.7,128.3"/>
				<path fill="#00BCF1" d="M909.1,127.9c0.5,1.6,1.9,2.9,3.2,2.9c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.6-1.9-2.9-3.2-2.9
			C909.3,125.2,908.6,126.4,909.1,127.9"/>
				<path fill="#00BCF1" d="M901.5,127.6c0.4,1.6,1.9,2.9,3.2,2.9c1.3,0.1,2-1.1,1.6-2.7c-0.5-1.6-1.9-2.9-3.2-2.9
			C901.8,124.8,901.1,126,901.5,127.6"/>
				<path fill="#00BCF1" d="M894,127.2c0.4,1.5,1.8,2.9,3.1,2.9c1.3,0.1,2-1.2,1.6-2.7c-0.4-1.5-1.8-2.8-3.1-2.9
			C894.3,124.5,893.6,125.7,894,127.2"/>
				<path fill="#00BCF1" d="M886.4,126.9c0.4,1.5,1.8,2.9,3.1,2.9c1.3,0.1,2.1-1.2,1.6-2.7c-0.4-1.5-1.8-2.8-3.1-2.9
			C886.7,124.2,886,125.4,886.4,126.9"/>
				<path fill="#00BCF1" d="M878.8,126.6c0.4,1.5,1.8,2.8,3.1,2.9c1.3,0.1,2.1-1.2,1.7-2.7c-0.4-1.5-1.8-2.8-3.1-2.9
			C879.2,123.8,878.4,125,878.8,126.6"/>
				<path fill="#00BCF1" d="M871.2,126.3c0.4,1.5,1.7,2.8,3.1,2.9c1.3,0.1,2.1-1.2,1.7-2.7c-0.4-1.5-1.7-2.8-3.1-2.9
			C871.6,123.5,870.9,124.8,871.2,126.3"/>
				<path fill="#00BCF1" d="M863.7,126c0.3,1.5,1.7,2.8,3,2.9c1.3,0,2.1-1.2,1.7-2.7c-0.4-1.5-1.7-2.8-3-2.9
			C864.1,123.3,863.3,124.5,863.7,126"/>
				<path fill="#00BCF1" d="M856.1,125.8c0.3,1.5,1.7,2.8,3,2.9c1.3,0,2.1-1.2,1.8-2.7c-0.3-1.5-1.7-2.8-3-2.9
			C856.5,123,855.7,124.2,856.1,125.8"/>
				<path fill="#00BCF1" d="M848.5,125.5c0.3,1.5,1.6,2.8,3,2.9c1.3,0,2.1-1.2,1.8-2.7c-0.3-1.5-1.6-2.8-3-2.9
			C849,122.8,848.2,124,848.5,125.5"/>
				<path fill="#00BCF1" d="M840.9,125.3c0.3,1.5,1.6,2.8,2.9,2.9c1.3,0,2.2-1.2,1.9-2.7c-0.3-1.5-1.6-2.8-2.9-2.8
			C841.4,122.6,840.6,123.8,840.9,125.3"/>
				<path fill="#00BCF1" d="M833.3,125.1c0.3,1.5,1.6,2.8,2.9,2.9c1.3,0,2.2-1.2,1.9-2.7c-0.3-1.5-1.6-2.8-2.9-2.8
			C833.8,122.4,833,123.6,833.3,125.1"/>
				<path fill="#00BCF1" d="M810.4,124.6c0.2,1.5,1.5,2.8,2.8,2.8c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.5-2.8-2.8-2.8
			C811.1,121.9,810.2,123.1,810.4,124.6"/>
				<path fill="#00BCF1" d="M802.8,124.5c0.2,1.5,1.4,2.8,2.7,2.8c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.4-2.8-2.7-2.8
			S802.6,123,802.8,124.5"/>
				<path fill="#00BCF1" d="M795.2,124.4c0.2,1.5,1.4,2.8,2.7,2.8c1.3,0,2.3-1.2,2.1-2.7c-0.2-1.5-1.4-2.8-2.7-2.8
			C795.9,121.6,795,122.8,795.2,124.4"/>
				<path fill="#00BCF1" d="M787.6,124.3c0.1,1.5,1.3,2.8,2.7,2.8c1.3,0,2.3-1.2,2.1-2.8c-0.2-1.5-1.4-2.8-2.7-2.8
			C788.4,121.5,787.4,122.8,787.6,124.3"/>
				<path fill="#00BCF1" d="M779.9,124.2c0.1,1.5,1.3,2.8,2.6,2.8s2.3-1.2,2.1-2.8c-0.1-1.5-1.3-2.8-2.6-2.8
			C780.8,121.5,779.8,122.7,779.9,124.2"/>
				<path fill="#00BCF1" d="M772.3,124.2c0.1,1.5,1.3,2.8,2.6,2.8c1.3,0,2.3-1.2,2.2-2.8c-0.1-1.5-1.3-2.8-2.6-2.8
			C773.2,121.4,772.2,122.6,772.3,124.2"/>
				<path fill="#00BCF1" d="M764.7,124.1c0.1,1.5,1.2,2.8,2.6,2.8c1.3,0,2.3-1.2,2.2-2.8c-0.1-1.5-1.3-2.8-2.6-2.8
			C765.6,121.4,764.6,122.6,764.7,124.1"/>
				<path fill="#00BCF1" d="M757,124.1c0.1,1.5,1.2,2.8,2.5,2.8c1.3,0,2.3-1.2,2.3-2.8c-0.1-1.5-1.2-2.8-2.5-2.8
			C758,121.4,757,122.6,757,124.1"/>
				<path fill="#00BCF1" d="M749.4,124.1c0.1,1.5,1.2,2.8,2.5,2.8c1.3,0,2.4-1.2,2.3-2.8c-0.1-1.5-1.2-2.8-2.5-2.8
			C750.4,121.4,749.4,122.6,749.4,124.1"/>
				<path fill="#00BCF1" d="M1234.7,152.6c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.3-1,0.2-2.7c-1.1-1.7-3-3.2-4.2-3.4
			C1233.8,149.7,1233.6,150.9,1234.7,152.6"/>
				<path fill="#00BCF1" d="M1220.5,150.4c1.1,1.7,3,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-3-3.2-4.2-3.4
			S1219.5,148.7,1220.5,150.4"/>
				<path fill="#00BCF1" d="M1213.4,149.3c1.1,1.7,2.9,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1212.5,146.5,1212.4,147.7,1213.4,149.3"/>
				<path fill="#00BCF1" d="M1206.3,148.3c1.1,1.7,2.9,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-2.9-3.2-4.2-3.4
			C1205.4,145.4,1205.2,146.6,1206.3,148.3"/>
				<path fill="#00BCF1" d="M1199.2,147.3c1.1,1.7,2.9,3.2,4.2,3.4c1.2,0.2,1.4-1,0.3-2.7c-1.1-1.7-2.9-3.2-4.2-3.3
			C1198.3,144.4,1198.1,145.6,1199.2,147.3"/>
				<path fill="#00BCF1" d="M1192,146.3c1,1.7,2.9,3.2,4.1,3.3c1.2,0.2,1.4-1,0.4-2.7c-1.1-1.7-2.9-3.2-4.1-3.3
			C1191.1,143.4,1191,144.6,1192,146.3"/>
				<path fill="#00BCF1" d="M1184.9,145.3c1,1.7,2.9,3.2,4.1,3.3c1.2,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.2-4.1-3.3
			C1184,142.4,1183.8,143.6,1184.9,145.3"/>
				<path fill="#00BCF1" d="M1177.7,144.3c1,1.7,2.9,3.1,4.1,3.3c1.2,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.1-4.1-3.3
			C1176.9,141.4,1176.7,142.6,1177.7,144.3"/>
				<path fill="#00BCF1" d="M1170.5,143.3c1,1.7,2.8,3.1,4.1,3.3c1.3,0.2,1.4-1,0.4-2.7c-1-1.7-2.8-3.1-4.1-3.3
			C1169.7,140.5,1169.5,141.7,1170.5,143.3"/>
				<path fill="#00BCF1" d="M1163.3,142.4c1,1.6,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1,0.4-2.7c-1-1.7-2.8-3.1-4.1-3.3
			C1162.5,139.5,1162.3,140.7,1163.3,142.4"/>
				<path fill="#00BCF1" d="M1156.1,141.4c1,1.6,2.8,3.1,4.1,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.6-2.8-3.1-4.1-3.3
			C1155.4,138.6,1155.1,139.8,1156.1,141.4"/>
				<path fill="#00BCF1" d="M1148.9,140.5c1,1.6,2.8,3.1,4,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.6-2.8-3.1-4-3.3
			C1148.2,137.7,1148,138.9,1148.9,140.5"/>
				<path fill="#00BCF1" d="M1141.7,139.6c1,1.6,2.8,3.1,4,3.3c1.3,0.2,1.5-1,0.5-2.7c-1-1.6-2.8-3.1-4-3.2
			C1141,136.8,1140.7,138,1141.7,139.6"/>
				<path fill="#00BCF1" d="M1134.5,138.7c0.9,1.6,2.7,3.1,4,3.2c1.3,0.2,1.5-1,0.5-2.7c-1-1.6-2.7-3.1-4-3.2
			C1133.8,135.9,1133.5,137.1,1134.5,138.7"/>
				<path fill="#00BCF1" d="M1127.2,137.8c0.9,1.6,2.7,3.1,4,3.2c1.3,0.2,1.5-1,0.6-2.7c-0.9-1.6-2.7-3.1-4-3.2
			C1126.6,135,1126.3,136.2,1127.2,137.8"/>
				<path fill="#00BCF1" d="M1120,137c0.9,1.6,2.7,3.1,4,3.2c1.3,0.2,1.5-1,0.6-2.7c-0.9-1.6-2.7-3.1-4-3.2
			C1119.3,134.1,1119.1,135.3,1120,137"/>
				<path fill="#00BCF1" d="M1112.7,136.1c0.9,1.6,2.7,3.1,3.9,3.2c1.3,0.1,1.5-1.1,0.6-2.7c-0.9-1.6-2.7-3.1-3.9-3.2
			C1112.1,133.3,1111.8,134.5,1112.7,136.1"/>
				<path fill="#00BCF1" d="M1105.5,135.3c0.9,1.6,2.6,3,3.9,3.2c1.3,0.1,1.6-1.1,0.6-2.7c-0.9-1.6-2.7-3-3.9-3.2
			S1104.6,133.7,1105.5,135.3"/>
				<path fill="#00BCF1" d="M1098.2,134.5c0.9,1.6,2.6,3,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3-3.9-3.2
			C1097.6,131.7,1097.3,132.9,1098.2,134.5"/>
				<path fill="#00BCF1" d="M1090.9,133.7c0.9,1.6,2.6,3,3.9,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3-3.9-3.2
			C1090.4,130.9,1090.1,132.1,1090.9,133.7"/>
				<path fill="#00BCF1" d="M1083.6,132.9c0.9,1.6,2.6,3,3.8,3.2c1.3,0.1,1.6-1.1,0.7-2.7c-0.9-1.6-2.6-3-3.8-3.1
			C1083.1,130.1,1082.8,131.3,1083.6,132.9"/>
				<path fill="#00BCF1" d="M1076.3,132.1c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.6-1.1,0.8-2.7c-0.8-1.6-2.6-3-3.8-3.1
			C1075.9,129.4,1075.5,130.5,1076.3,132.1"/>
				<path fill="#00BCF1" d="M1069,131.4c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.6-1.1,0.8-2.7c-0.8-1.6-2.5-3-3.8-3.1
			C1068.6,128.6,1068.2,129.8,1069,131.4"/>
				<path fill="#00BCF1" d="M1061.7,130.7c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.6-1.1,0.8-2.7c-0.8-1.6-2.5-3-3.8-3.1
			C1061.3,127.9,1060.9,129.1,1061.7,130.7"/>
				<path fill="#00BCF1" d="M1054.4,130c0.8,1.6,2.5,3,3.7,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.7-3.1
			C1054,127.2,1053.6,128.4,1054.4,130"/>
				<path fill="#00BCF1" d="M1047.1,129.3c0.8,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.5-3-3.7-3.1
			S1046.3,127.7,1047.1,129.3"/>
				<path fill="#00BCF1" d="M1039.7,128.6c0.8,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.4-3-3.7-3.1
			C1039.4,125.8,1039,127,1039.7,128.6"/>
				<path fill="#00BCF1" d="M1032.4,127.9c0.7,1.6,2.4,3,3.7,3.1c1.3,0.1,1.7-1.1,0.9-2.7c-0.8-1.6-2.4-2.9-3.7-3.1
			C1032.1,125.2,1031.7,126.3,1032.4,127.9"/>
				<path fill="#00BCF1" d="M1025,127.3c0.7,1.6,2.4,2.9,3.6,3.1c1.3,0.1,1.7-1.1,1-2.7c-0.7-1.6-2.4-2.9-3.6-3.1
			C1024.8,124.5,1024.3,125.7,1025,127.3"/>
				<path fill="#00BCF1" d="M1017.7,126.6c0.7,1.6,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,1-2.7c-0.7-1.6-2.3-2.9-3.6-3
			C1017.4,123.9,1017,125.1,1017.7,126.6"/>
				<path fill="#00BCF1" d="M1010.3,126c0.7,1.6,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,1-2.7c-0.7-1.6-2.3-2.9-3.6-3
			C1010.1,123.3,1009.6,124.5,1010.3,126"/>
				<path fill="#00BCF1" d="M1002.9,125.4c0.7,1.6,2.3,2.9,3.6,3c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-2.9-3.6-3
			C1002.7,122.7,1002.3,123.9,1002.9,125.4"/>
				<path fill="#00BCF1" d="M995.6,124.8c0.7,1.6,2.3,2.9,3.5,3c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.3-2.9-3.5-3
			C995.4,122.1,994.9,123.3,995.6,124.8"/>
				<path fill="#00BCF1" d="M988.2,124.3c0.6,1.6,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.1-2.7c-0.7-1.6-2.2-2.9-3.5-3
			C988,121.5,987.5,122.7,988.2,124.3"/>
				<path fill="#00BCF1" d="M980.8,123.7c0.6,1.6,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.2-2.7c-0.6-1.6-2.2-2.9-3.5-3
			C980.7,121,980.2,122.2,980.8,123.7"/>
				<path fill="#00BCF1" d="M973.4,123.2c0.6,1.6,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.2-2.7c-0.6-1.6-2.2-2.9-3.4-3
			C973.3,120.5,972.8,121.7,973.4,123.2"/>
				<path fill="#00BCF1" d="M966,122.7c0.6,1.5,2.1,2.9,3.4,3c1.3,0.1,1.8-1.1,1.2-2.7s-2.1-2.9-3.4-3
			C965.9,120,965.4,121.1,966,122.7"/>
				<path fill="#00BCF1" d="M958.6,122.2c0.6,1.5,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.5-2.1-2.9-3.4-3
			C958.6,119.5,958,120.7,958.6,122.2"/>
				<path fill="#00BCF1" d="M951.1,121.7c0.6,1.5,2.1,2.9,3.4,3c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.5-2.1-2.9-3.4-2.9
			C951.2,119,950.6,120.2,951.1,121.7"/>
				<path fill="#00BCF1" d="M943.7,121.3c0.5,1.5,2,2.9,3.3,2.9c1.3,0.1,1.9-1.1,1.3-2.7c-0.6-1.5-2.1-2.9-3.3-2.9
			C943.8,118.5,943.2,119.7,943.7,121.3"/>
				<path fill="#00BCF1" d="M936.3,120.8c0.5,1.5,2,2.9,3.3,2.9c1.3,0.1,1.9-1.1,1.4-2.7c-0.5-1.5-2-2.8-3.3-2.9
			C936.4,118.1,935.8,119.3,936.3,120.8"/>
				<path fill="#00BCF1" d="M928.9,120.4c0.5,1.5,2,2.8,3.3,2.9c1.3,0.1,1.9-1.1,1.4-2.7c-0.5-1.5-2-2.8-3.3-2.9
			C929,117.7,928.3,118.9,928.9,120.4"/>
				<path fill="#00BCF1" d="M921.4,120c0.5,1.5,1.9,2.8,3.2,2.9c1.3,0.1,1.9-1.1,1.4-2.7c-0.5-1.5-2-2.8-3.2-2.9
			C921.6,117.3,920.9,118.5,921.4,120"/>
				<path fill="#00BCF1" d="M914,119.6c0.5,1.5,1.9,2.8,3.2,2.9c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.5-1.9-2.8-3.2-2.9
			C914.2,116.9,913.5,118.1,914,119.6"/>
				<path fill="#00BCF1" d="M906.5,119.2c0.5,1.5,1.9,2.8,3.2,2.9c1.3,0.1,2-1.1,1.5-2.7c-0.5-1.5-1.9-2.8-3.2-2.9
			S906.1,117.7,906.5,119.2"/>
				<path fill="#00BCF1" d="M899.1,118.9c0.4,1.5,1.8,2.8,3.1,2.9c1.3,0.1,2-1.1,1.5-2.7c-0.4-1.5-1.9-2.8-3.1-2.9
			C899.3,116.2,898.6,117.3,899.1,118.9"/>
				<path fill="#00BCF1" d="M891.6,118.5c0.4,1.5,1.8,2.8,3.1,2.9c1.3,0.1,2-1.1,1.6-2.7c-0.4-1.5-1.8-2.8-3.1-2.9
			C891.9,115.8,891.2,117,891.6,118.5"/>
				<path fill="#00BCF1" d="M884.1,118.2c0.4,1.5,1.8,2.8,3.1,2.9c1.3,0.1,2-1.1,1.6-2.7c-0.4-1.5-1.8-2.8-3.1-2.9
			C884.5,115.5,883.7,116.7,884.1,118.2"/>
				<path fill="#00BCF1" d="M876.7,117.9c0.4,1.5,1.7,2.8,3,2.9c1.3,0.1,2-1.1,1.6-2.7c-0.4-1.5-1.8-2.8-3-2.8
			C877,115.2,876.3,116.4,876.7,117.9"/>
				<path fill="#00BCF1" d="M869.2,117.6c0.4,1.5,1.7,2.8,3,2.8c1.3,0,2.1-1.2,1.7-2.7c-0.4-1.5-1.7-2.8-3-2.8
			C869.6,114.9,868.8,116.1,869.2,117.6"/>
				<path fill="#00BCF1" d="M861.7,117.4c0.3,1.5,1.7,2.8,3,2.8c1.3,0,2.1-1.2,1.7-2.7c-0.4-1.5-1.7-2.8-3-2.8
			C862.1,114.7,861.4,115.9,861.7,117.4"/>
				<path fill="#00BCF1" d="M854.2,117.1c0.3,1.5,1.6,2.8,2.9,2.8c1.3,0,2.1-1.2,1.8-2.7c-0.3-1.5-1.7-2.8-2.9-2.8
			C854.7,114.4,853.9,115.6,854.2,117.1"/>
				<path fill="#00BCF1" d="M839.2,116.7c0.3,1.5,1.6,2.8,2.9,2.8c1.3,0,2.1-1.2,1.8-2.7c-0.3-1.5-1.6-2.8-2.9-2.8
			C839.8,114,839,115.2,839.2,116.7"/>
				<path fill="#00BCF1" d="M831.8,116.5c0.3,1.5,1.5,2.8,2.8,2.8c1.3,0,2.1-1.2,1.9-2.7c-0.3-1.5-1.6-2.8-2.8-2.8
			C832.3,113.8,831.5,115,831.8,116.5"/>
				<path fill="#00BCF1" d="M824.3,116.3c0.2,1.5,1.5,2.8,2.8,2.8c1.3,0,2.2-1.2,1.9-2.7c-0.3-1.5-1.5-2.8-2.8-2.8
			C824.9,113.6,824,114.8,824.3,116.3"/>
				<path fill="#00BCF1" d="M816.8,116.1c0.2,1.5,1.5,2.8,2.8,2.8c1.3,0,2.2-1.2,1.9-2.7c-0.2-1.5-1.5-2.8-2.8-2.8
			C817.4,113.4,816.5,114.6,816.8,116.1"/>
				<path fill="#00BCF1" d="M809.2,116c0.2,1.5,1.4,2.8,2.7,2.8c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.5-2.8-2.7-2.8
			C809.9,113.3,809,114.5,809.2,116"/>
				<path fill="#00BCF1" d="M801.7,115.9c0.2,1.5,1.4,2.8,2.7,2.8c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.4-2.7-2.7-2.8
			C802.4,113.2,801.6,114.4,801.7,115.9"/>
				<path fill="#00BCF1" d="M794.2,115.8c0.2,1.5,1.4,2.8,2.7,2.8c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.4-2.7-2.7-2.8
			C795,113.1,794.1,114.3,794.2,115.8"/>
				<path fill="#00BCF1" d="M786.7,115.7c0.1,1.5,1.3,2.8,2.6,2.8c1.3,0,2.2-1.2,2.1-2.7c-0.2-1.5-1.3-2.7-2.6-2.8
			C787.5,113,786.6,114.2,786.7,115.7"/>
				<path fill="#00BCF1" d="M779.2,115.6c0.1,1.5,1.3,2.7,2.6,2.8c1.3,0,2.3-1.2,2.1-2.7c-0.1-1.5-1.3-2.7-2.6-2.7
			C780,112.9,779.1,114.1,779.2,115.6"/>
				<path fill="#00BCF1" d="M771.7,115.6c0.1,1.5,1.3,2.7,2.6,2.8c1.3,0,2.3-1.2,2.1-2.7c-0.1-1.5-1.3-2.7-2.6-2.7
			C772.5,112.9,771.6,114.1,771.7,115.6"/>
				<path fill="#00BCF1" d="M764.2,115.5c0.1,1.5,1.2,2.7,2.5,2.7c1.3,0,2.3-1.2,2.2-2.7c-0.1-1.5-1.2-2.7-2.5-2.7
			C765,112.8,764.1,114,764.2,115.5"/>
				<path fill="#00BCF1" d="M1171.9,134.9c1,1.6,2.8,3.1,4.1,3.3c1.2,0.2,1.4-1,0.4-2.7c-1-1.7-2.9-3.1-4.1-3.3
			C1171.1,132.1,1170.9,133.3,1171.9,134.9"/>
				<path fill="#00BCF1" d="M1164.8,134c1,1.6,2.8,3.1,4.1,3.3c1.2,0.2,1.4-1,0.4-2.7c-1-1.6-2.8-3.1-4.1-3.3
			C1164,131.2,1163.8,132.4,1164.8,134"/>
				<path fill="#00BCF1" d="M1122,128.7c0.9,1.6,2.7,3,3.9,3.2c1.2,0.1,1.5-1,0.6-2.7c-0.9-1.6-2.7-3-3.9-3.2
			C1121.3,125.9,1121,127,1122,128.7"/>
				<path fill="#00BCF1" d="M1114.8,127.8c0.9,1.6,2.7,3,3.9,3.2c1.2,0.1,1.5-1,0.6-2.7c-0.9-1.6-2.7-3-3.9-3.2
			C1114.1,125,1113.9,126.2,1114.8,127.8"/>
				<path fill="#00BCF1" d="M1107.6,127c0.9,1.6,2.7,3,3.9,3.2c1.3,0.1,1.5-1,0.6-2.7c-0.9-1.6-2.7-3-3.9-3.2S1106.7,125.4,1107.6,127
			"/>
				<path fill="#00BCF1" d="M1100.4,126.2c0.9,1.6,2.6,3,3.9,3.2c1.3,0.1,1.5-1,0.6-2.7c-0.9-1.6-2.6-3-3.9-3.1
			C1099.8,123.4,1099.5,124.6,1100.4,126.2"/>
				<path fill="#00BCF1" d="M1086,124.6c0.9,1.6,2.6,3,3.8,3.1c1.3,0.1,1.6-1.1,0.7-2.6c-0.9-1.6-2.6-3-3.8-3.1
			C1085.5,121.9,1085.2,123.1,1086,124.6"/>
				<path fill="#00BCF1" d="M1078.8,123.9c0.9,1.6,2.6,3,3.8,3.1c1.3,0.1,1.6-1.1,0.7-2.6c-0.9-1.6-2.6-3-3.8-3.1
			C1078.3,121.1,1078,122.3,1078.8,123.9"/>
				<path fill="#00BCF1" d="M1071.6,123.1c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.6-1.1,0.7-2.6c-0.8-1.6-2.5-3-3.8-3.1
			C1071.1,120.4,1070.8,121.6,1071.6,123.1"/>
				<path fill="#00BCF1" d="M1064.4,122.4c0.8,1.6,2.5,3,3.8,3.1c1.3,0.1,1.6-1.1,0.8-2.6c-0.8-1.6-2.5-3-3.8-3.1
			C1063.9,119.7,1063.6,120.8,1064.4,122.4"/>
				<path fill="#00BCF1" d="M1057.2,121.7c0.8,1.6,2.5,3,3.7,3.1c1.3,0.1,1.6-1.1,0.8-2.6c-0.8-1.6-2.5-2.9-3.7-3.1
			C1056.7,119,1056.4,120.1,1057.2,121.7"/>
				<path fill="#00BCF1" d="M1049.9,121c0.8,1.6,2.5,2.9,3.7,3.1c1.3,0.1,1.6-1.1,0.8-2.6c-0.8-1.6-2.5-2.9-3.7-3.1
			C1049.5,118.3,1049.1,119.5,1049.9,121"/>
				<path fill="#00BCF1" d="M1042.7,120.3c0.8,1.6,2.4,2.9,3.7,3.1c1.3,0.1,1.6-1.1,0.9-2.6c-0.8-1.6-2.4-2.9-3.7-3
			C1042.3,117.6,1041.9,118.8,1042.7,120.3"/>
				<path fill="#00BCF1" d="M1035.4,119.7c0.8,1.6,2.4,2.9,3.7,3c1.3,0.1,1.7-1.1,0.9-2.6c-0.8-1.6-2.4-2.9-3.7-3
			C1035.1,117,1034.7,118.1,1035.4,119.7"/>
				<path fill="#00BCF1" d="M1028.2,119c0.7,1.6,2.4,2.9,3.6,3c1.3,0.1,1.7-1.1,0.9-2.6c-0.8-1.6-2.4-2.9-3.6-3
			C1027.8,116.3,1027.4,117.5,1028.2,119"/>
				<path fill="#00BCF1" d="M1020.9,118.4c0.7,1.6,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.6-2.4-2.9-3.6-3
			C1020.6,115.7,1020.2,116.9,1020.9,118.4"/>
				<path fill="#00BCF1" d="M1013.6,117.8c0.7,1.6,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.6-2.3-2.9-3.6-3
			C1013.4,115.1,1012.9,116.3,1013.6,117.8"/>
				<path fill="#00BCF1" d="M1006.4,117.2c0.7,1.5,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.3-2.9-3.6-3
			C1006.1,114.5,1005.7,115.7,1006.4,117.2"/>
				<path fill="#00BCF1" d="M999.1,116.6c0.7,1.5,2.3,2.9,3.5,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.3-2.9-3.5-3
			C998.9,113.9,998.4,115.1,999.1,116.6"/>
				<path fill="#00BCF1" d="M991.8,116.1c0.7,1.5,2.2,2.9,3.5,3s1.7-1.1,1.1-2.6c-0.7-1.5-2.2-2.9-3.5-3
			C991.6,113.4,991.1,114.5,991.8,116.1"/>
				<path fill="#00BCF1" d="M984.5,115.5c0.6,1.5,2.2,2.9,3.5,3c1.3,0.1,1.8-1.1,1.1-2.6c-0.7-1.5-2.2-2.9-3.5-3
			C984.4,112.8,983.9,114,984.5,115.5"/>
				<path fill="#00BCF1" d="M977.2,115c0.6,1.5,2.2,2.9,3.4,3c1.3,0.1,1.8-1.1,1.1-2.6c-0.6-1.5-2.2-2.9-3.4-2.9
			C977.1,112.3,976.6,113.5,977.2,115"/>
				<path fill="#00BCF1" d="M969.9,114.5c0.6,1.5,2.1,2.9,3.4,2.9c1.3,0.1,1.8-1.1,1.2-2.6c-0.6-1.5-2.2-2.8-3.4-2.9
			C969.8,111.8,969.3,113,969.9,114.5"/>
				<path fill="#00BCF1" d="M962.6,114c0.6,1.5,2.1,2.8,3.4,2.9c1.3,0.1,1.8-1.1,1.2-2.6c-0.6-1.5-2.1-2.8-3.4-2.9
			C962.5,111.3,962,112.5,962.6,114"/>
				<path fill="#00BCF1" d="M955.3,113.5c0.6,1.5,2.1,2.8,3.4,2.9c1.3,0.1,1.8-1.1,1.2-2.6c-0.6-1.5-2.1-2.8-3.4-2.9
			C955.3,110.8,954.7,112,955.3,113.5"/>
				<path fill="#00BCF1" d="M948,113.1c0.6,1.5,2,2.8,3.3,2.9c1.3,0.1,1.8-1.1,1.3-2.6c-0.6-1.5-2.1-2.8-3.3-2.9
			C948,110.4,947.4,111.5,948,113.1"/>
				<path fill="#00BCF1" d="M940.6,112.6c0.5,1.5,2,2.8,3.3,2.9c1.3,0.1,1.9-1.1,1.3-2.6c-0.6-1.5-2-2.8-3.3-2.9
			C940.7,109.9,940.1,111.1,940.6,112.6"/>
				<path fill="#00BCF1" d="M933.3,112.2c0.5,1.5,2,2.8,3.3,2.9c1.3,0.1,1.9-1.1,1.3-2.6c-0.5-1.5-2-2.8-3.3-2.9
			C933.4,109.5,932.8,110.7,933.3,112.2"/>
				<path fill="#00BCF1" d="M889.2,110c0.4,1.5,1.8,2.8,3.1,2.8c1.3,0.1,2-1.1,1.5-2.6c-0.4-1.5-1.8-2.8-3.1-2.8
			C889.5,107.3,888.8,108.5,889.2,110"/>
				<path fill="#00BCF1" d="M881.9,109.7c0.4,1.5,1.8,2.8,3,2.8c1.3,0.1,2-1.1,1.6-2.6c-0.4-1.5-1.8-2.8-3-2.8
			C882.2,107,881.5,108.2,881.9,109.7"/>
				<path fill="#00BCF1" d="M785.9,107.2c0.1,1.5,1.3,2.7,2.6,2.7c1.3,0,2.2-1.2,2-2.7c-0.2-1.5-1.3-2.7-2.6-2.7
			C786.6,104.6,785.7,105.7,785.9,107.2"/>
				<path fill="#00BCF1" d="M1088.3,116.4c0.9,1.6,2.6,3,3.8,3.1c1.2,0.1,1.5-1,0.6-2.6c-0.9-1.6-2.6-3-3.8-3.1
			C1087.7,113.7,1087.4,114.9,1088.3,116.4"/>
				<path fill="#00BCF1" d="M1081.2,115.7c0.9,1.6,2.6,3,3.8,3.1c1.2,0.1,1.5-1,0.7-2.6c-0.9-1.6-2.6-3-3.8-3.1
			C1080.6,113,1080.3,114.1,1081.2,115.7"/>
				<path fill="#00BCF1" d="M1074,115c0.8,1.6,2.5,2.9,3.8,3.1c1.2,0.1,1.6-1,0.7-2.6c-0.9-1.6-2.5-2.9-3.8-3.1
			C1073.5,112.2,1073.2,113.4,1074,115"/>
				<path fill="#00BCF1" d="M1066.9,114.3c0.8,1.6,2.5,2.9,3.8,3.1c1.2,0.1,1.6-1,0.7-2.6c-0.8-1.6-2.5-2.9-3.8-3.1
			C1066.4,111.5,1066.1,112.7,1066.9,114.3"/>
				<path fill="#00BCF1" d="M1059.8,113.6c0.8,1.6,2.5,2.9,3.7,3.1c1.2,0.1,1.6-1,0.8-2.6c-0.8-1.6-2.5-2.9-3.7-3
			C1059.3,110.8,1058.9,112,1059.8,113.6"/>
				<path fill="#00BCF1" d="M1052.6,112.9c0.8,1.6,2.5,2.9,3.7,3c1.2,0.1,1.6-1.1,0.8-2.6s-2.5-2.9-3.7-3
			C1052.2,110.2,1051.8,111.3,1052.6,112.9"/>
				<path fill="#00BCF1" d="M1045.5,112.2c0.8,1.6,2.4,2.9,3.7,3c1.2,0.1,1.6-1.1,0.8-2.6c-0.8-1.6-2.4-2.9-3.7-3
			C1045,109.5,1044.7,110.6,1045.5,112.2"/>
				<path fill="#00BCF1" d="M1038.3,111.5c0.8,1.5,2.4,2.9,3.7,3c1.2,0.1,1.6-1.1,0.8-2.6c-0.8-1.5-2.4-2.9-3.7-3
			C1037.9,108.8,1037.5,110,1038.3,111.5"/>
				<path fill="#00BCF1" d="M1031.1,110.9c0.8,1.5,2.4,2.9,3.6,3c1.2,0.1,1.6-1.1,0.9-2.6c-0.8-1.5-2.4-2.9-3.6-3
			C1030.8,108.2,1030.4,109.4,1031.1,110.9"/>
				<path fill="#00BCF1" d="M1024,110.3c0.7,1.5,2.4,2.9,3.6,3c1.2,0.1,1.6-1.1,0.9-2.6c-0.8-1.5-2.4-2.9-3.6-3
			C1023.6,107.6,1023.2,108.8,1024,110.3"/>
				<path fill="#00BCF1" d="M1016.8,109.7c0.7,1.5,2.3,2.9,3.6,3c1.3,0.1,1.7-1.1,0.9-2.6c-0.7-1.5-2.3-2.9-3.6-3
			C1016.5,107,1016.1,108.1,1016.8,109.7"/>
				<path fill="#00BCF1" d="M1009.6,109.1c0.7,1.5,2.3,2.9,3.5,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.3-2.9-3.5-3
			C1009.3,106.4,1008.9,107.6,1009.6,109.1"/>
				<path fill="#00BCF1" d="M1002.4,108.5c0.7,1.5,2.3,2.9,3.5,3c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.3-2.8-3.5-2.9
			C1002.2,105.8,1001.7,107,1002.4,108.5"/>
				<path fill="#00BCF1" d="M995.3,108c0.7,1.5,2.2,2.8,3.5,2.9c1.3,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.3-2.8-3.5-2.9
			C995,105.3,994.6,106.4,995.3,108"/>
				<path fill="#00BCF1" d="M988.1,107.4c0.7,1.5,2.2,2.8,3.5,2.9c1.3,0.1,1.7-1.1,1.1-2.6c-0.7-1.5-2.2-2.8-3.5-2.9
			S987.4,105.9,988.1,107.4"/>
				<path fill="#00BCF1" d="M980.9,106.9c0.6,1.5,2.2,2.8,3.4,2.9c1.3,0.1,1.7-1.1,1.1-2.6c-0.7-1.5-2.2-2.8-3.4-2.9
			C980.7,104.2,980.2,105.4,980.9,106.9"/>
				<path fill="#00BCF1" d="M952,105c0.6,1.5,2.1,2.8,3.3,2.9c1.3,0.1,1.8-1.1,1.2-2.6c-0.6-1.5-2.1-2.8-3.3-2.9
			C952,102.3,951.4,103.5,952,105"/>
				<path fill="#00BCF1" d="M944.8,104.5c0.6,1.5,2,2.8,3.3,2.9c1.3,0.1,1.8-1.1,1.2-2.6c-0.6-1.5-2-2.8-3.3-2.9
			C944.8,101.9,944.2,103,944.8,104.5"/>
				<path fill="#00BCF1" d="M937.6,104.1c0.5,1.5,2,2.8,3.3,2.9c1.3,0.1,1.8-1.1,1.3-2.6c-0.6-1.5-2-2.8-3.3-2.8
			C937.6,101.4,937,102.6,937.6,104.1"/>
				<path fill="#00BCF1" d="M886.9,101.5c0.4,1.5,1.8,2.7,3,2.8c1.3,0.1,1.9-1.1,1.5-2.6c-0.4-1.5-1.8-2.7-3-2.8
			C887.2,98.9,886.5,100.1,886.9,101.5"/>
				<path fill="#00BCF1" d="M879.6,101.3c0.4,1.5,1.7,2.7,3,2.8c1.3,0.1,2-1.1,1.6-2.6c-0.4-1.5-1.8-2.7-3-2.8
			C879.9,98.6,879.2,99.8,879.6,101.3"/>
				<path fill="#00BCF1" d="M1055.1,104.8c0.8,1.5,2.5,2.9,3.7,3c1.2,0.1,1.6-1,0.7-2.6c-0.8-1.5-2.5-2.9-3.7-3
			C1054.7,102.1,1054.3,103.2,1055.1,104.8"/>
				<path fill="#00BCF1" d="M1048.1,104.1c0.8,1.5,2.4,2.9,3.7,3c1.2,0.1,1.6-1,0.8-2.6c-0.8-1.5-2.5-2.9-3.7-3
			C1047.6,101.4,1047.3,102.6,1048.1,104.1"/>
				<path fill="#00BCF1" d="M1041,103.5c0.8,1.5,2.4,2.9,3.6,3c1.2,0.1,1.6-1,0.8-2.6c-0.8-1.5-2.4-2.9-3.6-3
			C1040.6,100.8,1040.2,102,1041,103.5"/>
				<path fill="#00BCF1" d="M1033.9,102.9c0.8,1.5,2.4,2.9,3.6,3c1.2,0.1,1.6-1,0.8-2.6c-0.8-1.5-2.4-2.9-3.6-3
			C1033.5,100.2,1033.2,101.3,1033.9,102.9"/>
				<path fill="#00BCF1" d="M1026.9,102.2c0.8,1.5,2.4,2.8,3.6,3c1.2,0.1,1.6-1,0.8-2.6c-0.8-1.5-2.4-2.8-3.6-2.9
			C1026.5,99.6,1026.1,100.7,1026.9,102.2"/>
				<path fill="#00BCF1" d="M1019.8,101.6c0.7,1.5,2.3,2.8,3.6,2.9c1.2,0.1,1.6-1,0.9-2.6c-0.7-1.5-2.3-2.8-3.6-2.9
			C1019.5,99,1019.1,100.1,1019.8,101.6"/>
				<path fill="#00BCF1" d="M1005.6,100.5c0.7,1.5,2.3,2.8,3.5,2.9s1.7-1.1,0.9-2.6c-0.7-1.5-2.3-2.8-3.5-2.9
			C1005.3,97.9,1004.9,99,1005.6,100.5"/>
				<path fill="#00BCF1" d="M991.4,99.4c0.7,1.5,2.2,2.8,3.5,2.9c1.2,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.2-2.8-3.5-2.9
			C991.2,96.8,990.8,97.9,991.4,99.4"/>
				<path fill="#00BCF1" d="M984.3,98.9c0.7,1.5,2.2,2.8,3.4,2.9c1.2,0.1,1.7-1.1,1-2.6c-0.7-1.5-2.2-2.8-3.4-2.9
			C984.1,96.3,983.7,97.4,984.3,98.9"/>
				<path fill="#00BCF1" d="M977.2,98.4c0.6,1.5,2.2,2.8,3.4,2.9c1.2,0.1,1.7-1.1,1.1-2.6c-0.7-1.5-2.2-2.8-3.4-2.9
			C977.1,95.8,976.6,96.9,977.2,98.4"/>
				<path fill="#00BCF1" d="M927.4,95.3c0.5,1.5,1.9,2.7,3.2,2.8c1.2,0.1,1.8-1.1,1.3-2.5c-0.5-1.5-2-2.7-3.2-2.8
			C927.5,92.7,926.9,93.8,927.4,95.3"/>
				<path fill="#00BCF1" d="M920.3,94.9c0.5,1.5,1.9,2.7,3.2,2.8c1.2,0.1,1.8-1.1,1.3-2.5c-0.5-1.5-1.9-2.7-3.2-2.8
			C920.4,92.3,919.8,93.5,920.3,94.9"/>
				<path fill="#00BCF1" d="M906,94.2c0.5,1.5,1.9,2.7,3.1,2.8c1.2,0.1,1.9-1.1,1.4-2.5c-0.5-1.5-1.9-2.7-3.1-2.7
			C906.2,91.6,905.5,92.7,906,94.2"/>
				<path fill="#00BCF1" d="M898.9,93.9c0.4,1.5,1.8,2.7,3.1,2.8c1.2,0.1,1.9-1.1,1.4-2.5c-0.5-1.5-1.8-2.7-3.1-2.7
			C899,91.3,898.4,92.4,898.9,93.9"/>
				<path fill="#00BCF1" d="M891.7,93.6c0.4,1.5,1.8,2.7,3,2.7c1.2,0.1,1.9-1.1,1.5-2.5c-0.4-1.5-1.8-2.7-3-2.7
			C891.9,91,891.3,92.1,891.7,93.6"/>
				<path fill="#00BCF1" d="M1029.6,94.3c0.8,1.5,2.4,2.8,3.6,2.9c1.2,0.1,1.6-1,0.8-2.5c-0.8-1.5-2.4-2.8-3.6-2.9
			C1029.2,91.6,1028.9,92.8,1029.6,94.3"/>
				<path fill="#00BCF1" d="M1022.6,93.7c0.7,1.5,2.3,2.8,3.6,2.9c1.2,0.1,1.6-1,0.8-2.5c-0.8-1.5-2.4-2.8-3.6-2.9
			C1022.3,91.1,1021.9,92.2,1022.6,93.7"/>
				<path fill="#00BCF1" d="M1015.6,93.1c0.7,1.5,2.3,2.8,3.5,2.9c1.2,0.1,1.6-1,0.9-2.5c-0.7-1.5-2.3-2.8-3.5-2.9
			C1015.3,90.5,1014.9,91.6,1015.6,93.1"/>
				<path fill="#00BCF1" d="M966.6,89.5c0.6,1.5,2.1,2.7,3.3,2.8c1.2,0.1,1.7-1,1.1-2.5c-0.6-1.5-2.1-2.7-3.3-2.8
			C966.5,87,966,88.1,966.6,89.5"/>
				<path fill="#00BCF1" d="M769.2,82.6c0.1,1.4,1.2,2.6,2.4,2.6c1.2,0,2.1-1.1,2-2.5c-0.1-1.4-1.2-2.5-2.4-2.5
			C770,80.1,769.1,81.2,769.2,82.6"/>
				<path fill="#00BCF1" d="M755,82.6c0.1,1.4,1.1,2.5,2.4,2.5c1.2,0,2.2-1.1,2.1-2.5c-0.1-1.4-1.1-2.5-2.4-2.5
			C755.9,80.1,755,81.2,755,82.6"/>
				<path fill="#00BCF1" d="M748,82.6c0,1.4,1.1,2.5,2.3,2.5c1.2,0,2.2-1.1,2.1-2.5c-0.1-1.4-1.1-2.5-2.3-2.5
			C748.9,80.1,747.9,81.2,748,82.6"/>
				<path fill="#00BCF1" d="M997.7,83.7c0.7,1.5,2.2,2.7,3.4,2.8c1.2,0.1,1.6-1,0.9-2.5c-0.7-1.5-2.2-2.7-3.4-2.8
			C997.4,81.1,997,82.2,997.7,83.7"/>
				<path fill="#00BCF1" d="M963.1,81.3c0.6,1.4,2.1,2.7,3.3,2.8c1.2,0.1,1.7-1,1-2.5s-2.1-2.7-3.3-2.7
			C963,78.8,962.5,79.9,963.1,81.3"/>
				<path fill="#00BCF1" d="M949.3,80.5c0.6,1.4,2,2.7,3.2,2.7c1.2,0.1,1.7-1,1.1-2.5c-0.6-1.4-2-2.7-3.2-2.7
			C949.2,77.9,948.7,79,949.3,80.5"/>
				<path fill="#00BCF1" d="M914.6,78.6c0.5,1.4,1.9,2.6,3.1,2.7s1.8-1,1.3-2.5c-0.5-1.4-1.9-2.6-3.1-2.7
			C914.7,76.1,914.1,77.2,914.6,78.6"/>
				<path fill="#00BCF1" d="M907.7,78.3c0.5,1.4,1.8,2.6,3.1,2.7c1.2,0.1,1.8-1,1.3-2.5c-0.5-1.4-1.9-2.6-3.1-2.7
			C907.8,75.8,907.2,76.9,907.7,78.3"/>
				<path fill="#00BCF1" d="M900.8,78c0.5,1.4,1.8,2.6,3,2.7c1.2,0.1,1.8-1.1,1.3-2.5c-0.5-1.4-1.8-2.6-3-2.6
			C900.9,75.5,900.3,76.6,900.8,78"/>
				<path fill="#00BCF1" d="M893.8,77.7c0.4,1.4,1.8,2.6,3,2.6c1.2,0.1,1.8-1.1,1.4-2.5c-0.5-1.4-1.8-2.6-3-2.6
			C894,75.2,893.4,76.3,893.8,77.7"/>
				<path fill="#00BCF1" d="M886.9,77.4c0.4,1.4,1.7,2.6,3,2.6c1.2,0.1,1.8-1.1,1.4-2.5c-0.4-1.4-1.8-2.6-3-2.6
			C887.1,74.9,886.5,76,886.9,77.4"/>
				<path fill="#00BCF1" d="M873,76.9c0.4,1.4,1.7,2.6,2.9,2.6c1.2,0,1.9-1.1,1.5-2.5c-0.4-1.4-1.7-2.6-2.9-2.6
			C873.3,74.4,872.6,75.5,873,76.9"/>
				<path fill="#00BCF1" d="M866,76.6c0.4,1.4,1.6,2.6,2.9,2.6c1.2,0,1.9-1.1,1.5-2.5c-0.4-1.4-1.7-2.6-2.9-2.6
			C866.4,74.1,865.7,75.2,866,76.6"/>
				<path fill="#00BCF1" d="M859.1,76.4c0.3,1.4,1.6,2.6,2.8,2.6c1.2,0,1.9-1.1,1.5-2.5c-0.4-1.4-1.6-2.5-2.8-2.6
			C859.4,73.9,858.7,75,859.1,76.4"/>
				<path fill="#00BCF1" d="M852.1,76.2c0.3,1.4,1.6,2.6,2.8,2.6s1.9-1.1,1.6-2.5c-0.3-1.4-1.6-2.5-2.8-2.6
			C852.5,73.7,851.8,74.8,852.1,76.2"/>
				<path fill="#00BCF1" d="M803.4,75.1c0.2,1.4,1.3,2.5,2.6,2.5c1.2,0,2-1.1,1.8-2.5c-0.2-1.4-1.4-2.5-2.6-2.5
			C804.1,72.6,803.2,73.7,803.4,75.1"/>
				<path fill="#00BCF1" d="M789.5,74.9c0.2,1.4,1.3,2.5,2.5,2.5c1.2,0,2.1-1.1,1.9-2.5c-0.2-1.4-1.3-2.5-2.5-2.5
			C790.2,72.5,789.3,73.6,789.5,74.9"/>
				<path fill="#00BCF1" d="M775.6,74.8c0.1,1.4,1.2,2.5,2.4,2.5c1.2,0,2.1-1.1,2-2.5c-0.1-1.4-1.2-2.5-2.4-2.5
			C776.3,72.3,775.4,73.4,775.6,74.8"/>
				<path fill="#00BCF1" d="M754.7,74.8c0.1,1.4,1.1,2.5,2.3,2.5c1.2,0,2.1-1.1,2.1-2.5c-0.1-1.4-1.1-2.5-2.3-2.5
			C755.5,72.3,754.6,73.4,754.7,74.8"/>
				<path fill="#00BCF1" d="M624.2,144.4c-0.3,1.6,0.6,2.8,2,2.8c1.4-0.1,2.7-1.4,2.9-3c0.2-1.6-0.6-2.8-2-2.8
			C625.7,141.5,624.4,142.8,624.2,144.4"/>
				<path fill="#00BCF1" d="M616.3,144.7c-0.3,1.6,0.6,2.8,2,2.8c1.4-0.1,2.7-1.4,3-3c0.3-1.6-0.6-2.8-2-2.8
			C617.9,141.8,616.6,143.1,616.3,144.7"/>
				<path fill="#00BCF1" d="M648.9,134.6c-0.2,1.6,0.7,2.8,2.1,2.7c1.4,0,2.6-1.4,2.8-2.9c0.2-1.6-0.7-2.8-2.1-2.7
			C650.4,131.8,649.1,133.1,648.9,134.6"/>
				<path fill="#00BCF1" d="M641.2,134.9c-0.2,1.6,0.7,2.8,2,2.7c1.4,0,2.6-1.4,2.8-2.9c0.2-1.6-0.7-2.8-2-2.7
			C642.6,132,641.4,133.3,641.2,134.9"/>
				<path fill="#00BCF1" d="M633.4,135.2c-0.2,1.6,0.7,2.8,2,2.7c1.4-0.1,2.6-1.4,2.9-2.9c0.2-1.6-0.7-2.8-2-2.7
			C634.9,132.3,633.6,133.6,633.4,135.2"/>
				<path fill="#00BCF1" d="M625.7,135.5c-0.3,1.6,0.6,2.8,2,2.7c1.4-0.1,2.6-1.4,2.9-2.9c0.2-1.6-0.6-2.8-2-2.7
			S625.9,133.9,625.7,135.5"/>
				<path fill="#00BCF1" d="M617.9,135.8c-0.3,1.6,0.6,2.8,1.9,2.7c1.4-0.1,2.7-1.4,2.9-2.9c0.3-1.6-0.6-2.8-1.9-2.7
			C619.5,132.9,618.2,134.3,617.9,135.8"/>
			</g>
			<g className={classNames("g-noram", { 'g-map-active': highlight === 'noram' })} onMouseLeave={clearHover} onMouseOver={() => onHoverRegion('noram')}>
				<path fill="#00A44F" d="M16.8,367.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.5-3.6c1.1-1.8,1-3-0.3-2.7
			C20,363.8,18,365.4,16.8,367.2"/>
				<path fill="#00A44F" d="M9.3,368.6c-1.2,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.5-3.6c1.1-1.8,1-3-0.3-2.7
			C12.4,365.2,10.4,366.8,9.3,368.6"/>
				<path fill="#00A44F" d="M1.8,370c-1.2,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.5-3.6c1.2-1.8,1-3-0.3-2.7C4.9,366.6,2.9,368.2,1.8,370"
				/>
				<path fill="#00A44F" d="M46.2,352.9c-1.1,1.8-1,3,0.4,2.8c1.3-0.2,3.3-1.9,4.5-3.6c1.1-1.8,0.9-3-0.4-2.8
			C49.3,349.5,47.3,351.2,46.2,352.9"/>
				<path fill="#00A44F" d="M38.5,354.4c-1.1,1.8-1,3,0.4,2.8c1.3-0.2,3.3-1.9,4.5-3.6c1.1-1.8,1-3-0.4-2.8
			C41.6,351,39.6,352.6,38.5,354.4"/>
				<path fill="#00A44F" d="M30.9,355.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.5-3.6c1.1-1.8,1-3-0.3-2.7
			C34,352.4,32,354,30.9,355.8"/>
				<path fill="#00A44F" d="M23.3,357.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C26.5,353.8,24.5,355.4,23.3,357.2"/>
				<path fill="#00A44F" d="M52.5,343c-1.1,1.8-1,3,0.4,2.8c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.4-2.8
			C55.7,339.6,53.7,341.2,52.5,343"/>
				<path fill="#00A44F" d="M44.9,344.4c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C48,341,46.1,342.6,44.9,344.4"/>
				<path fill="#00A44F" d="M37.3,345.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6s1-3-0.3-2.7C40.5,342.4,38.5,344.1,37.3,345.8"
				/>
				<path fill="#00A44F" d="M29.8,347.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C32.9,343.9,31,345.5,29.8,347.2"/>
				<path fill="#00A44F" d="M22.3,348.7c-1.2,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.2-1.8,1-3-0.3-2.7
			C25.5,345.3,23.5,346.9,22.3,348.7"/>
				<path fill="#00A44F" d="M7.5,351.6c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C10.7,348.2,8.7,349.8,7.5,351.6"/>
				<path fill="#00A44F" d="M28.9,338.8c-1.2,1.8-1,3,0.2,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.2-1.8,1-3-0.2-2.7
			C32,335.4,30,337,28.9,338.8"/>
				<path fill="#00A44F" d="M351,355.6c-0.6,1.8,0.2,3.1,1.9,3c1.7-0.2,3.5-1.7,4.1-3.5c0.6-1.8-0.2-3.1-1.9-3
			C353.5,352.2,351.7,353.8,351,355.6"/>
				<path fill="#00A44F" d="M332,357.4c-0.7,1.8,0.1,3.1,1.8,3c1.6-0.2,3.5-1.7,4.2-3.5c0.7-1.8-0.1-3.1-1.8-3
			C334.6,354,332.7,355.6,332,357.4"/>
				<path fill="#00A44F" d="M354.6,345.4c-0.6,1.8,0.2,3.1,1.8,3c1.6-0.2,3.5-1.7,4.1-3.5c0.6-1.8-0.2-3.1-1.8-3
			C357.1,342,355.3,343.6,354.6,345.4"/>
				<path fill="#00A44F" d="M345.2,346.3c-0.7,1.8,0.1,3.1,1.8,3c1.6-0.2,3.5-1.7,4.1-3.5c0.6-1.8-0.2-3.1-1.8-3
			C347.7,342.9,345.9,344.5,345.2,346.3"/>
				<path fill="#00A44F" d="M335.8,347.3c-0.7,1.8,0.1,3.1,1.7,3c1.6-0.2,3.5-1.7,4.2-3.5c0.7-1.8-0.1-3.1-1.7-3
			C338.3,343.9,336.5,345.5,335.8,347.3"/>
				<path fill="#00A44F" d="M270.9,354.2c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C273.6,350.8,271.7,352.4,270.9,354.2"/>
				<path fill="#00A44F" d="M261.8,355.3c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			C264.5,351.9,262.6,353.5,261.8,355.3"/>
				<path fill="#00A44F" d="M252.7,356.3c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C255.4,352.9,253.5,354.6,252.7,356.3"/>
				<path fill="#00A44F" d="M358.3,335.3c-0.7,1.8,0.1,3.1,1.8,3c1.6-0.2,3.5-1.7,4.1-3.5c0.6-1.8-0.2-3.1-1.8-3
			C360.8,331.9,358.9,333.5,358.3,335.3"/>
				<path fill="#00A44F" d="M348.9,336.2c-0.7,1.8,0.1,3.1,1.7,3c1.6-0.2,3.5-1.7,4.1-3.5c0.7-1.8-0.1-3.1-1.7-3
			C351.4,332.8,349.6,334.4,348.9,336.2"/>
				<path fill="#00A44F" d="M339.6,337.1c-0.7,1.8,0.1,3.1,1.7,3c1.6-0.2,3.5-1.7,4.1-3.5c0.7-1.8-0.1-3.1-1.7-3
			C342.2,333.7,340.3,335.3,339.6,337.1"/>
				<path fill="#00A44F" d="M275.3,344.1c-0.8,1.8-0.1,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.1-3.1-1.4-2.9
			C278,340.7,276.1,342.3,275.3,344.1"/>
				<path fill="#00A44F" d="M266.3,345.1c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C269,341.7,267.1,343.3,266.3,345.1"/>
				<path fill="#00A44F" d="M257.2,346.2c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C260,342.8,258,344.4,257.2,346.2"/>
				<path fill="#00A44F" d="M248.2,347.3c-0.8,1.8-0.2,3.1,1.3,2.9s3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C251,343.9,249.1,345.5,248.2,347.3"/>
				<path fill="#00A44F" d="M352.7,326c-0.7,1.8,0.1,3.1,1.7,3c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8-0.1-3.1-1.7-3
			C355.2,322.6,353.4,324.2,352.7,326"/>
				<path fill="#00A44F" d="M343.5,327c-0.7,1.8,0.1,3.1,1.7,3c1.6-0.2,3.5-1.7,4.1-3.5c0.7-1.8-0.1-3.1-1.7-3
			C346,323.6,344.2,325.2,343.5,327"/>
				<path fill="#00A44F" d="M297.8,331.9c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.8-1.8,0.1-3.1-1.5-2.9
			S298.6,330.1,297.8,331.9"/>
				<path fill="#00A44F" d="M288.8,332.9c-0.8,1.8-0.1,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.8-1.8,0.1-3.1-1.4-2.9
			C291.5,329.5,289.6,331.1,288.8,332.9"/>
				<path fill="#00A44F" d="M279.8,333.9c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C282.5,330.5,280.6,332.1,279.8,333.9"/>
				<path fill="#00A44F" d="M270.8,335c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C273.5,331.6,271.6,333.2,270.8,335"/>
				<path fill="#00A44F" d="M261.8,336.1c-0.8,1.8-0.2,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C264.6,332.7,262.7,334.3,261.8,336.1"/>
				<path fill="#00A44F" d="M252.9,337.2c-0.8,1.8-0.3,3.1,1.3,2.9c1.6-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C255.7,333.8,253.8,335.4,252.9,337.2"/>
				<path fill="#00A44F" d="M244,338.3c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C246.8,334.9,244.9,336.5,244,338.3"/>
				<path fill="#00A44F" d="M347.4,316.8c-0.7,1.8,0,3.1,1.6,3c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.6-3
			C349.9,313.4,348.1,315,347.4,316.8"/>
				<path fill="#00A44F" d="M338.3,317.8c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.8,4.1-3.5c0.7-1.8,0-3.1-1.6-2.9
			C340.9,314.4,339,316,338.3,317.8"/>
				<path fill="#00A44F" d="M329.2,318.7c-0.7,1.8,0,3.1,1.5,2.9c1.6-0.2,3.4-1.8,4.2-3.5c0.7-1.8,0-3.1-1.5-2.9
			C331.8,315.4,329.9,316.9,329.2,318.7"/>
				<path fill="#00A44F" d="M320.2,319.7c-0.7,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.4-1.8,4.2-3.6c0.7-1.8,0.1-3.1-1.5-2.9
			C322.8,316.3,320.9,317.9,320.2,319.7"/>
				<path fill="#00A44F" d="M311.1,320.7c-0.8,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.4-1.8,4.2-3.6c0.7-1.8,0.1-3.1-1.5-2.9
			C313.8,317.3,311.9,318.9,311.1,320.7"/>
				<path fill="#00A44F" d="M302.2,321.7c-0.8,1.8-0.1,3.1,1.4,2.9c1.6-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.1-3.1-1.4-2.9
			C304.8,318.3,302.9,319.9,302.2,321.7"/>
				<path fill="#00A44F" d="M293.2,322.8c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.8-1.8,0.1-3.1-1.4-2.9
			C295.9,319.4,294,321,293.2,322.8"/>
				<path fill="#00A44F" d="M284.3,323.8c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.5-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C287,320.4,285.1,322,284.3,323.8"/>
				<path fill="#00A44F" d="M275.4,324.9c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C278.1,321.5,276.2,323.1,275.4,324.9"/>
				<path fill="#00A44F" d="M266.5,326c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C269.2,322.6,267.3,324.2,266.5,326"/>
				<path fill="#00A44F" d="M257.6,327.1c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.5-1.8,4.3-3.6s0.3-3.1-1.3-2.9
			C260.4,323.7,258.5,325.3,257.6,327.1"/>
				<path fill="#00A44F" d="M248.8,328.2c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C251.6,324.8,249.7,326.4,248.8,328.2"/>
				<path fill="#00A44F" d="M240.1,329.3c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6s0.3-3.1-1.2-2.9
			C242.9,325.9,240.9,327.5,240.1,329.3"/>
				<path fill="#00A44F" d="M231.3,330.5c-0.9,1.8-0.4,3.1,1.2,2.9c1.5-0.2,3.5-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.2-2.9
			C234.2,327.1,232.2,328.7,231.3,330.5"/>
				<path fill="#00A44F" d="M360.4,305.8c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.6-2.9
			C362.9,302.4,361.1,304,360.4,305.8"/>
				<path fill="#00A44F" d="M351.4,306.7c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.6-2.9
			C353.9,303.3,352.1,304.9,351.4,306.7"/>
				<path fill="#00A44F" d="M342.3,307.7c-0.7,1.8,0,3.1,1.5,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.5-2.9
			C344.9,304.3,343.1,305.9,342.3,307.7"/>
				<path fill="#00A44F" d="M333.3,308.6c-0.7,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.4-1.8,4.1-3.5c0.7-1.8,0.1-3.1-1.5-2.9
			C335.9,305.2,334.1,306.8,333.3,308.6"/>
				<path fill="#00A44F" d="M324.4,309.6c-0.7,1.8-0.1,3.1,1.5,2.9s3.4-1.8,4.2-3.5c0.7-1.8,0.1-3.1-1.5-2.9
			C327,306.2,325.1,307.8,324.4,309.6"/>
				<path fill="#00A44F" d="M315.4,310.6c-0.8,1.8-0.1,3.1,1.4,2.9c1.6-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.1-3.1-1.4-2.9
			C318.1,307.2,316.2,308.8,315.4,310.6"/>
				<path fill="#00A44F" d="M306.5,311.6c-0.8,1.8-0.2,3.1,1.4,2.9c1.6-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.1-3.1-1.4-2.9
			S307.3,309.8,306.5,311.6"/>
				<path fill="#00A44F" d="M297.6,312.7c-0.8,1.8-0.2,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.4-2.9
			C300.3,309.3,298.4,310.9,297.6,312.7"/>
				<path fill="#00A44F" d="M288.8,313.7c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C291.5,310.3,289.6,311.9,288.8,313.7"/>
				<path fill="#00A44F" d="M280,314.8c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C282.7,311.4,280.8,313,280,314.8"/>
				<path fill="#00A44F" d="M271.2,315.9c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C273.9,312.5,272,314.1,271.2,315.9"/>
				<path fill="#00A44F" d="M262.4,317c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C265.2,313.6,263.3,315.2,262.4,317"/>
				<path fill="#00A44F" d="M253.7,318.1c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.3-3.1-1.2-2.9
			C256.5,314.7,254.6,316.3,253.7,318.1"/>
				<path fill="#00A44F" d="M245,319.2c-0.9,1.8-0.4,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.2-2.9
			C247.8,315.8,245.9,317.4,245,319.2"/>
				<path fill="#00A44F" d="M236.3,320.4c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C239.2,317,237.2,318.6,236.3,320.4"/>
				<path fill="#00A44F" d="M227.7,321.5c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C230.6,318.1,228.6,319.7,227.7,321.5"/>
				<path fill="#00A44F" d="M219.1,322.7c-0.9,1.8-0.4,3.1,1,2.9s3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1-2.9
			C222,319.3,220,320.9,219.1,322.7"/>
				<path fill="#00A44F" d="M210.6,323.9c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.4-3.6c0.9-1.8,0.5-3.1-1-2.9
			C213.4,320.5,211.5,322.1,210.6,323.9"/>
				<path fill="#00A44F" d="M202,325.1c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.4-3.6c0.9-1.8,0.5-3.1-1-2.9
			C204.9,321.7,203,323.3,202,325.1"/>
				<path fill="#00A44F" d="M193.6,326.3c-1,1.8-0.5,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.4-3.6c0.9-1.8,0.5-3.1-0.9-2.8
			C196.5,322.9,194.5,324.5,193.6,326.3"/>
				<path fill="#00A44F" d="M373.3,294.8c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.7,4-3.5c0.7-1.8-0.1-3.1-1.6-2.9
			C375.8,291.4,374,293,373.3,294.8"/>
				<path fill="#00A44F" d="M364.3,295.7c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.6-2.9
			C366.8,292.3,365,293.9,364.3,295.7"/>
				<path fill="#00A44F" d="M355.3,296.6c-0.7,1.8,0,3.1,1.5,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.5-2.9
			C357.9,293.3,356.1,294.8,355.3,296.6"/>
				<path fill="#00A44F" d="M346.4,297.6c-0.7,1.8-0.1,3.1,1.5,2.9c1.6-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.5-2.9
			C349,294.2,347.1,295.8,346.4,297.6"/>
				<path fill="#00A44F" d="M337.5,298.5c-0.7,1.8-0.1,3.1,1.5,2.9c1.5-0.2,3.4-1.7,4.1-3.5s0.1-3.1-1.5-2.9
			C340.1,295.2,338.2,296.7,337.5,298.5"/>
				<path fill="#00A44F" d="M328.6,299.5c-0.8,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C331.2,296.1,329.4,297.7,328.6,299.5"/>
				<path fill="#00A44F" d="M319.8,300.5c-0.8,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.1-3.1-1.4-2.9
			C322.4,297.1,320.5,298.7,319.8,300.5"/>
				<path fill="#00A44F" d="M310.9,301.5c-0.8,1.8-0.2,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.2-3.1-1.4-2.9
			C313.6,298.2,311.7,299.7,310.9,301.5"/>
				<path fill="#00A44F" d="M302.1,302.6c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C304.8,299.2,302.9,300.8,302.1,302.6"/>
				<path fill="#00A44F" d="M293.4,303.6c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.2-3.1-1.3-2.9
			C296.1,300.2,294.2,301.8,293.4,303.6"/>
				<path fill="#00A44F" d="M284.6,304.7c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C287.4,301.3,285.5,302.9,284.6,304.7"/>
				<path fill="#00A44F" d="M275.9,305.8c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C278.7,302.4,276.8,304,275.9,305.8"/>
				<path fill="#00A44F" d="M267.2,306.9c-0.9,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C270,303.5,268.1,305.1,267.2,306.9"/>
				<path fill="#00A44F" d="M258.6,308c-0.9,1.8-0.4,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.3-3.1-1.1-2.9
			C261.4,304.6,259.5,306.2,258.6,308"/>
				<path fill="#00A44F" d="M250,309.1c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C252.8,305.7,250.8,307.3,250,309.1"/>
				<path fill="#00A44F" d="M241.4,310.3c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C244.2,306.9,242.3,308.5,241.4,310.3"/>
				<path fill="#00A44F" d="M232.8,311.4c-0.9,1.8-0.4,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1-2.9
			C235.7,308,233.7,309.7,232.8,311.4"/>
				<path fill="#00A44F" d="M224.3,312.6c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3.1-1-2.9
			C227.2,309.2,225.2,310.8,224.3,312.6"/>
				<path fill="#00A44F" d="M215.8,313.8c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3.1-1-2.8
			C218.7,310.4,216.8,312,215.8,313.8"/>
				<path fill="#00A44F" d="M207.4,315c-0.9,1.8-0.5,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.4-3.6c0.9-1.8,0.5-3.1-0.9-2.8
			C210.3,311.6,208.3,313.2,207.4,315"/>
				<path fill="#00A44F" d="M199,316.2c-1,1.8-0.5,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.4-3.6s0.5-3.1-0.9-2.8
			C201.9,312.8,199.9,314.5,199,316.2"/>
				<path fill="#00A44F" d="M190.6,317.5c-1,1.8-0.6,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3.1-0.9-2.8
			C193.5,314.1,191.5,315.7,190.6,317.5"/>
				<path fill="#00A44F" d="M182.2,318.7c-1,1.8-0.6,3,0.8,2.8c1.5-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.9-2.8
			C185.2,315.3,183.2,316.9,182.2,318.7"/>
				<path fill="#00A44F" d="M377.1,284.7c-0.7,1.8,0,3.1,1.6,2.9c1.6-0.2,3.4-1.7,4-3.5c0.7-1.8,0-3.1-1.6-2.9
			C379.6,281.4,377.8,282.9,377.1,284.7"/>
				<path fill="#00A44F" d="M368.2,285.6c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.4-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C370.8,282.3,368.9,283.8,368.2,285.6"/>
				<path fill="#00A44F" d="M359.4,286.6c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0-3.1-1.5-2.9
			C361.9,283.2,360.1,284.8,359.4,286.6"/>
				<path fill="#00A44F" d="M350.5,287.5c-0.7,1.8-0.1,3.1,1.5,2.9c1.5-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.5-2.9
			S351.3,285.7,350.5,287.5"/>
				<path fill="#00A44F" d="M341.7,288.5c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C344.3,285.1,342.5,286.7,341.7,288.5"/>
				<path fill="#00A44F" d="M332.9,289.4c-0.8,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.1-3.5c0.8-1.8,0.1-3.1-1.4-2.9
			C335.5,286.1,333.7,287.7,332.9,289.4"/>
				<path fill="#00A44F" d="M324.1,290.4c-0.8,1.8-0.2,3.1,1.4,2.9c1.5-0.2,3.4-1.8,4.1-3.5c0.8-1.8,0.2-3.1-1.4-2.9
			C326.8,287.1,324.9,288.7,324.1,290.4"/>
				<path fill="#00A44F" d="M315.4,291.5c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C318.1,288.1,316.2,289.7,315.4,291.5"/>
				<path fill="#00A44F" d="M306.7,292.5c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C309.4,289.1,307.5,290.7,306.7,292.5"/>
				<path fill="#00A44F" d="M298,293.5c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.3-2.9
			C300.7,290.2,298.8,291.8,298,293.5"/>
				<path fill="#00A44F" d="M289.3,294.6c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C292,291.2,290.2,292.8,289.3,294.6"/>
				<path fill="#00A44F" d="M280.7,295.7c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.2-2.9
			C283.4,292.3,281.5,293.9,280.7,295.7"/>
				<path fill="#00A44F" d="M272.1,296.8c-0.9,1.8-0.3,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.3-3.1-1.1-2.9
			C274.8,293.4,272.9,295,272.1,296.8"/>
				<path fill="#00A44F" d="M263.5,297.9c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C266.3,294.5,264.4,296.1,263.5,297.9"/>
				<path fill="#00A44F" d="M255,299.1c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6s0.4-3.1-1.1-2.9
			C257.8,295.7,255.9,297.3,255,299.1"/>
				<path fill="#00A44F" d="M246.5,300.2c-0.9,1.8-0.4,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1-2.9
			S247.4,298.4,246.5,300.2"/>
				<path fill="#00A44F" d="M238,301.4c-0.9,1.8-0.5,3.1,1,2.9s3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1-2.9
			C240.8,298,238.9,299.6,238,301.4"/>
				<path fill="#00A44F" d="M229.5,302.5c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3.1-1-2.8
			C232.4,299.2,230.4,300.8,229.5,302.5"/>
				<path fill="#00A44F" d="M221.1,303.7c-0.9,1.8-0.5,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3.1-0.9-2.8
			C224,300.4,222,302,221.1,303.7"/>
				<path fill="#00A44F" d="M212.7,305c-1,1.8-0.5,3.1,0.9,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C215.6,301.6,213.7,303.2,212.7,305"/>
				<path fill="#00A44F" d="M204.4,306.2c-1,1.8-0.6,3,0.9,2.8c1.5-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.9-2.8
			C207.3,302.8,205.3,304.4,204.4,306.2"/>
				<path fill="#00A44F" d="M196.1,307.4c-1,1.8-0.6,3,0.9,2.8c1.4-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.9-2.8
			C199,304,197,305.6,196.1,307.4"/>
				<path fill="#00A44F" d="M187.8,308.7c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.8-2.8
			C190.7,305.3,188.8,306.9,187.8,308.7"/>
				<path fill="#00A44F" d="M381,274.7c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C383.5,271.4,381.7,272.9,381,274.7"/>
				<path fill="#00A44F" d="M372.2,275.6c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5s0-3.1-1.5-2.9
			C374.7,272.3,372.9,273.8,372.2,275.6"/>
				<path fill="#00A44F" d="M363.4,276.5c-0.7,1.8-0.1,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.5-2.9
			C366,273.2,364.2,274.8,363.4,276.5"/>
				<path fill="#00A44F" d="M354.7,277.5c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C357.2,274.1,355.4,275.7,354.7,277.5"/>
				<path fill="#00A44F" d="M345.9,278.4c-0.8,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.4-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			S346.7,276.7,345.9,278.4"/>
				<path fill="#00A44F" d="M337.2,279.4c-0.8,1.8-0.2,3.1,1.4,2.9c1.5-0.2,3.4-1.7,4.1-3.5c0.8-1.8,0.1-3.1-1.4-2.9
			C339.8,276.1,338,277.6,337.2,279.4"/>
				<path fill="#00A44F" d="M328.5,280.4c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.1-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C331.2,277.1,329.3,278.6,328.5,280.4"/>
				<path fill="#00A44F" d="M319.9,281.4c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C322.5,278.1,320.7,279.6,319.9,281.4"/>
				<path fill="#00A44F" d="M311.2,282.5c-0.8,1.8-0.3,3.1,1.3,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C313.9,279.1,312.1,280.7,311.2,282.5"/>
				<path fill="#00A44F" d="M302.6,283.5c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.3-3.1-1.2-2.9
			C305.3,280.1,303.5,281.7,302.6,283.5"/>
				<path fill="#00A44F" d="M294.1,284.6c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.3-3.1-1.2-2.9
			C296.8,281.2,294.9,282.8,294.1,284.6"/>
				<path fill="#00A44F" d="M285.5,285.7c-0.9,1.8-0.3,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.8-1.8,0.3-3.1-1.1-2.9
			C288.2,282.3,286.3,283.9,285.5,285.7"/>
				<path fill="#00A44F" d="M277,286.8c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C279.7,283.4,277.8,285,277,286.8"/>
				<path fill="#00A44F" d="M268.5,287.9c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1.1-2.9
			C271.3,284.5,269.3,286.1,268.5,287.9"/>
				<path fill="#00A44F" d="M260,289c-0.9,1.8-0.4,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3.1-1-2.9
			C262.8,285.6,260.9,287.2,260,289"/>
				<path fill="#00A44F" d="M251.6,290.2c-0.9,1.8-0.5,3.1,1,2.9c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.4-3-1-2.8
			C254.4,286.8,252.5,288.4,251.6,290.2"/>
				<path fill="#00A44F" d="M243.2,291.3c-0.9,1.8-0.5,3.1,1,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-1-2.8
			C246,287.9,244.1,289.5,243.2,291.3"/>
				<path fill="#00A44F" d="M234.8,292.5c-0.9,1.8-0.5,3,0.9,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C237.6,289.1,235.7,290.7,234.8,292.5"/>
				<path fill="#00A44F" d="M226.4,293.7c-0.9,1.8-0.5,3,0.9,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C229.3,290.3,227.4,291.9,226.4,293.7"/>
				<path fill="#00A44F" d="M218.1,294.9c-1,1.8-0.6,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.6-3-0.9-2.8
			C221,291.5,219.1,293.1,218.1,294.9"/>
				<path fill="#00A44F" d="M209.8,296.1c-1,1.8-0.6,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.9-2.8
			C212.7,292.7,210.8,294.4,209.8,296.1"/>
				<path fill="#00A44F" d="M201.6,297.4c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.8-2.8
			C204.5,294,202.6,295.6,201.6,297.4"/>
				<path fill="#00A44F" d="M59.6,321.7c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C62.7,318.3,60.7,320,59.6,321.7"/>
				<path fill="#00A44F" d="M420,261.3c-0.6,1.8,0.1,3.1,1.6,2.9c1.5-0.1,3.3-1.7,3.9-3.4s-0.1-3.1-1.6-2.9
			C422.4,258,420.6,259.5,420,261.3"/>
				<path fill="#00A44F" d="M411.2,262.1c-0.6,1.8,0.1,3.1,1.6,2.9c1.5-0.1,3.3-1.7,3.9-3.5c0.6-1.8-0.1-3.1-1.6-2.9
			C413.6,258.8,411.8,260.3,411.2,262.1"/>
				<path fill="#00A44F" d="M402.4,262.9c-0.7,1.8,0,3.1,1.6,2.9c1.5-0.1,3.3-1.7,3.9-3.5c0.7-1.8,0-3.1-1.6-2.9
			C404.9,259.6,403.1,261.2,402.4,262.9"/>
				<path fill="#00A44F" d="M393.7,263.8c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C396.1,260.5,394.3,262.1,393.7,263.8"/>
				<path fill="#00A44F" d="M384.9,264.7c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C387.4,261.4,385.6,262.9,384.9,264.7"/>
				<path fill="#00A44F" d="M376.2,265.6c-0.7,1.8-0.1,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C378.7,262.3,376.9,263.8,376.2,265.6"/>
				<path fill="#00A44F" d="M367.5,266.5c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C370.1,263.2,368.3,264.8,367.5,266.5"/>
				<path fill="#00A44F" d="M358.9,267.5c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C361.4,264.1,359.6,265.7,358.9,267.5"/>
				<path fill="#00A44F" d="M350.2,268.4c-0.8,1.8-0.2,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C352.8,265.1,351,266.7,350.2,268.4"/>
				<path fill="#00A44F" d="M341.6,269.4c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C344.2,266.1,342.4,267.6,341.6,269.4"/>
				<path fill="#00A44F" d="M333,270.4c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C335.6,267.1,333.8,268.6,333,270.4"/>
				<path fill="#00A44F" d="M324.4,271.4c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.3-1.8,4.1-3.5c0.8-1.8,0.2-3.1-1.3-2.9
			C327.1,268.1,325.2,269.6,324.4,271.4"/>
				<path fill="#00A44F" d="M315.8,272.4c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.3-1.8,4.2-3.5c0.8-1.8,0.3-3.1-1.2-2.9
			C318.5,269.1,316.7,270.7,315.8,272.4"/>
				<path fill="#00A44F" d="M307.3,273.5c-0.8,1.8-0.3,3.1,1.2,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.3-3.1-1.2-2.9
			C310,270.1,308.1,271.7,307.3,273.5"/>
				<path fill="#00A44F" d="M298.8,274.6c-0.8,1.8-0.3,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.3-3-1.1-2.9
			C301.5,271.2,299.7,272.8,298.8,274.6"/>
				<path fill="#00A44F" d="M290.3,275.6c-0.9,1.8-0.4,3.1,1.1,2.9c1.5-0.2,3.4-1.8,4.2-3.5c0.8-1.8,0.3-3-1.1-2.9
			C293.1,272.3,291.2,273.9,290.3,275.6"/>
				<path fill="#00A44F" d="M281.9,276.7c-0.9,1.8-0.4,3,1.1,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.9-1.8,0.4-3-1.1-2.9
			C284.6,273.4,282.8,275,281.9,276.7"/>
				<path fill="#00A44F" d="M273.5,277.9c-0.9,1.8-0.4,3,1,2.9c1.5-0.2,3.4-1.8,4.2-3.6c0.9-1.8,0.4-3-1-2.9
			C276.2,274.5,274.3,276.1,273.5,277.9"/>
				<path fill="#00A44F" d="M265.1,279c-0.9,1.8-0.4,3,1,2.8s3.4-1.8,4.3-3.6c0.9-1.8,0.4-3-1-2.8C267.9,275.6,266,277.2,265.1,279" />
				<path fill="#00A44F" d="M256.7,280.1c-0.9,1.8-0.5,3,1,2.8c1.5-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-1-2.8
			C259.5,276.8,257.6,278.4,256.7,280.1"/>
				<path fill="#00A44F" d="M248.4,281.3c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C251.2,277.9,249.3,279.5,248.4,281.3"/>
				<path fill="#00A44F" d="M240.1,282.5c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C242.9,279.1,241,280.7,240.1,282.5"/>
				<path fill="#00A44F" d="M231.8,283.7c-0.9,1.8-0.6,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C234.7,280.3,232.7,281.9,231.8,283.7"/>
				<path fill="#00A44F" d="M223.5,284.9c-1,1.8-0.6,3,0.9,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.9-2.8
			C226.4,281.5,224.5,283.1,223.5,284.9"/>
				<path fill="#00A44F" d="M215.3,286.1c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C218.2,282.7,216.3,284.3,215.3,286.1"/>
				<path fill="#00A44F" d="M207.1,287.4c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C210.1,284,208.1,285.6,207.1,287.4"/>
				<path fill="#00A44F" d="M199,288.6c-1,1.8-0.7,3,0.8,2.8c1.4-0.2,3.4-1.8,4.4-3.6c1-1.8,0.6-3-0.8-2.8
			C201.9,285.2,200,286.8,199,288.6"/>
				<path fill="#00A44F" d="M190.9,289.9c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.4-1.8,4.4-3.6c1-1.8,0.7-3-0.7-2.8
			C193.8,286.5,191.9,288.1,190.9,289.9"/>
				<path fill="#00A44F" d="M66,311.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C69.1,308.4,67.2,310,66,311.8"/>
				<path fill="#00A44F" d="M406.2,253c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.1,3.3-1.7,3.9-3.4c0.7-1.8,0-3.1-1.5-2.9
			C408.6,249.7,406.8,251.3,406.2,253"/>
				<path fill="#00A44F" d="M397.5,253.9c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,3.9-3.5c0.7-1.8,0-3.1-1.5-2.9
			C400,250.6,398.2,252.1,397.5,253.9"/>
				<path fill="#00A44F" d="M388.9,254.8c-0.7,1.8,0,3.1,1.5,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0-3.1-1.5-2.9
			C391.3,251.4,389.6,253,388.9,254.8"/>
				<path fill="#00A44F" d="M380.2,255.7c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0.1-3.1-1.4-2.9
			C382.8,252.3,381,253.9,380.2,255.7"/>
				<path fill="#00A44F" d="M371.6,256.6c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0.1-3-1.4-2.9
			C374.2,253.3,372.4,254.8,371.6,256.6"/>
				<path fill="#00A44F" d="M363.1,257.5c-0.7,1.8-0.1,3.1,1.4,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.8,0.1-3-1.4-2.9
			C365.6,254.2,363.8,255.7,363.1,257.5"/>
				<path fill="#00A44F" d="M354.5,258.5c-0.8,1.8-0.2,3.1,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3-1.3-2.9
			C357.1,255.1,355.3,256.7,354.5,258.5"/>
				<path fill="#00A44F" d="M346,259.4c-0.8,1.8-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3-1.3-2.9
			C348.6,256.1,346.7,257.7,346,259.4"/>
				<path fill="#00A44F" d="M337.4,260.4c-0.8,1.8-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3-1.3-2.9
			C340.1,257.1,338.2,258.7,337.4,260.4"/>
				<path fill="#00A44F" d="M328.9,261.4c-0.8,1.8-0.3,3,1.2,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.3-3-1.2-2.9
			C331.6,258.1,329.7,259.7,328.9,261.4"/>
				<path fill="#00A44F" d="M320.5,262.5c-0.8,1.8-0.3,3,1.2,2.9c1.5-0.2,3.3-1.8,4.1-3.5c0.8-1.8,0.3-3-1.2-2.9
			C323.1,259.1,321.3,260.7,320.5,262.5"/>
				<path fill="#00A44F" d="M312,263.5c-0.8,1.8-0.3,3,1.1,2.9c1.5-0.2,3.3-1.8,4.2-3.5c0.8-1.8,0.3-3-1.1-2.9
			C314.7,260.2,312.9,261.8,312,263.5"/>
				<path fill="#00A44F" d="M303.6,264.6c-0.9,1.8-0.4,3,1.1,2.9c1.5-0.2,3.3-1.8,4.2-3.5s0.3-3-1.1-2.9
			C306.3,261.2,304.4,262.8,303.6,264.6"/>
				<path fill="#00A44F" d="M295.2,265.7c-0.9,1.8-0.4,3,1.1,2.9c1.5-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1.1-2.8
			C297.9,262.3,296.1,263.9,295.2,265.7"/>
				<path fill="#00A44F" d="M286.8,266.8c-0.9,1.8-0.4,3,1,2.8c1.5-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1-2.8
			C289.6,263.4,287.7,265,286.8,266.8"/>
				<path fill="#00A44F" d="M278.5,267.9c-0.9,1.8-0.4,3,1,2.8c1.5-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1-2.8
			C281.3,264.5,279.4,266.1,278.5,267.9"/>
				<path fill="#00A44F" d="M270.2,269c-0.9,1.8-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.5-3-1-2.8
			C273,265.7,271.1,267.2,270.2,269"/>
				<path fill="#00A44F" d="M261.9,270.2c-0.9,1.8-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.5-3-1-2.8
			C264.7,266.8,262.8,268.4,261.9,270.2"/>
				<path fill="#00A44F" d="M253.6,271.3c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C256.4,268,254.5,269.5,253.6,271.3"/>
				<path fill="#00A44F" d="M245.4,272.5c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.5-3-0.9-2.8
			C248.2,269.1,246.3,270.7,245.4,272.5"/>
				<path fill="#00A44F" d="M237.2,273.7c-1,1.8-0.6,3,0.9,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.6-3-0.9-2.8
			C240,270.3,238.1,271.9,237.2,273.7"/>
				<path fill="#00A44F" d="M229,274.9c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C231.9,271.5,229.9,273.1,229,274.9"/>
				<path fill="#00A44F" d="M220.8,276.1c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C223.7,272.7,221.8,274.4,220.8,276.1"/>
				<path fill="#00A44F" d="M212.7,277.4c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C215.6,274,213.7,275.6,212.7,277.4"/>
				<path fill="#00A44F" d="M204.6,278.6c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.4-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C207.6,275.2,205.6,276.8,204.6,278.6"/>
				<path fill="#00A44F" d="M196.6,279.9c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.4-3.6c1-1.8,0.7-3-0.7-2.8
			C199.5,276.5,197.6,278.1,196.6,279.9"/>
				<path fill="#00A44F" d="M87.5,298.9c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C90.6,295.5,88.6,297.2,87.5,298.9"/>
				<path fill="#00A44F" d="M72.5,301.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7S73.6,300.1,72.5,301.8" />
				<path fill="#00A44F" d="M50.3,306.2c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C53.4,302.8,51.5,304.5,50.3,306.2"/>
				<path fill="#00A44F" d="M453.1,239.1c-0.6,1.7,0.2,3.1,1.7,2.9c1.5-0.1,3.2-1.6,3.8-3.4c0.6-1.7-0.2-3-1.7-2.9
			C455.3,235.9,453.7,237.4,453.1,239.1"/>
				<path fill="#00A44F" d="M444.4,239.9c-0.6,1.7,0.1,3.1,1.6,2.9c1.5-0.1,3.2-1.7,3.8-3.4c0.6-1.7-0.1-3-1.6-2.9
			C446.7,236.6,445,238.1,444.4,239.9"/>
				<path fill="#00A44F" d="M435.8,240.7c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.2-1.7,3.8-3.4c0.6-1.7-0.1-3-1.6-2.9
			C438.1,237.4,436.4,238.9,435.8,240.7"/>
				<path fill="#00A44F" d="M427.2,241.5c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.6-1.7-0.1-3-1.6-2.9
			C429.5,238.2,427.8,239.7,427.2,241.5"/>
				<path fill="#00A44F" d="M418.5,242.3c-0.7,1.7,0,3,1.5,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.6-1.7,0-3-1.5-2.9
			C420.9,239,419.2,240.5,418.5,242.3"/>
				<path fill="#00A44F" d="M410,243.1c-0.7,1.7,0,3,1.5,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0-3-1.5-2.9
			C412.4,239.8,410.6,241.4,410,243.1"/>
				<path fill="#00A44F" d="M401.4,244c-0.7,1.7,0,3,1.5,2.9c1.5-0.1,3.3-1.7,3.9-3.4c0.7-1.7,0-3-1.5-2.9
			C403.8,240.7,402.1,242.2,401.4,244"/>
				<path fill="#00A44F" d="M392.8,244.8c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.3-1.7,3.9-3.4s0.1-3-1.4-2.9
			C395.3,241.6,393.5,243.1,392.8,244.8"/>
				<path fill="#00A44F" d="M384.3,245.7c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.3-1.7,4-3.4c0.7-1.7,0.1-3-1.4-2.9
			C386.8,242.5,385,244,384.3,245.7"/>
				<path fill="#00A44F" d="M375.8,246.7c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.7,0.1-3-1.4-2.9
			C378.3,243.4,376.5,244.9,375.8,246.7"/>
				<path fill="#00A44F" d="M367.3,247.6c-0.8,1.8-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4-3.5c0.7-1.7,0.1-3-1.3-2.9
			C369.8,244.3,368,245.8,367.3,247.6"/>
				<path fill="#00A44F" d="M358.8,248.5c-0.8,1.8-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4-3.5c0.8-1.8,0.2-3-1.3-2.9
			C361.4,245.2,359.6,246.8,358.8,248.5"/>
				<path fill="#00A44F" d="M350.4,249.5c-0.8,1.8-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3-1.2-2.9
			C353,246.2,351.1,247.8,350.4,249.5"/>
				<path fill="#00A44F" d="M341.9,250.5c-0.8,1.8-0.3,3,1.2,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.2-3-1.2-2.9
			C344.5,247.2,342.7,248.8,341.9,250.5"/>
				<path fill="#00A44F" d="M333.5,251.5c-0.8,1.8-0.3,3,1.2,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.3-3-1.2-2.9
			C336.2,248.2,334.3,249.8,333.5,251.5"/>
				<path fill="#00A44F" d="M325.1,252.5c-0.8,1.8-0.3,3,1.1,2.9c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.8,0.3-3-1.1-2.8
			C327.8,249.2,325.9,250.8,325.1,252.5"/>
				<path fill="#00A44F" d="M316.8,253.6c-0.8,1.8-0.3,3,1.1,2.8c1.5-0.2,3.3-1.8,4.1-3.5c0.8-1.8,0.3-3-1.1-2.8
			C319.4,250.3,317.6,251.8,316.8,253.6"/>
				<path fill="#00A44F" d="M308.4,254.6c-0.9,1.8-0.4,3,1.1,2.8c1.5-0.2,3.3-1.8,4.2-3.5c0.8-1.8,0.4-3-1.1-2.8
			C311.1,251.3,309.3,252.9,308.4,254.6"/>
				<path fill="#00A44F" d="M300.1,255.7c-0.9,1.8-0.4,3,1,2.8s3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1-2.8C302.8,252.4,301,254,300.1,255.7"
				/>
				<path fill="#00A44F" d="M291.8,256.8c-0.9,1.8-0.4,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1-2.8
			C294.5,253.5,292.7,255.1,291.8,256.8"/>
				<path fill="#00A44F" d="M283.5,257.9c-0.9,1.8-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.4-3-1-2.8
			C286.3,254.6,284.4,256.2,283.5,257.9"/>
				<path fill="#00A44F" d="M275.3,259.1c-0.9,1.8-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.5-3-1-2.8
			C278.1,255.7,276.2,257.3,275.3,259.1"/>
				<path fill="#00A44F" d="M267.1,260.2c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.5-3-0.9-2.8
			C269.9,256.9,268,258.4,267.1,260.2"/>
				<path fill="#00A44F" d="M258.9,261.4c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.3-3.5c0.9-1.8,0.5-3-0.9-2.8
			C261.7,258,259.8,259.6,258.9,261.4"/>
				<path fill="#00A44F" d="M250.7,262.5c-0.9,1.8-0.6,3,0.9,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.6-3-0.9-2.8
			C253.5,259.2,251.6,260.8,250.7,262.5"/>
				<path fill="#00A44F" d="M242.6,263.7c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.6c0.9-1.8,0.6-3-0.8-2.8
			C245.4,260.4,243.5,262,242.6,263.7"/>
				<path fill="#00A44F" d="M234.4,264.9c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C237.3,261.6,235.4,263.2,234.4,264.9"/>
				<path fill="#00A44F" d="M226.4,266.1c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C229.3,262.8,227.3,264.4,226.4,266.1"/>
				<path fill="#00A44F" d="M218.3,267.4c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C221.2,264,219.3,265.6,218.3,267.4"/>
				<path fill="#00A44F" d="M210.3,268.6c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C213.2,265.3,211.3,266.9,210.3,268.6"/>
				<path fill="#00A44F" d="M202.3,269.9c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C205.2,266.5,203.3,268.1,202.3,269.9"/>
				<path fill="#00A44F" d="M194.3,271.2c-1,1.8-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C197.3,267.8,195.4,269.4,194.3,271.2"/>
				<path fill="#00A44F" d="M186.4,272.5c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.4-3.6c1-1.8,0.7-3-0.6-2.8
			C189.4,269.1,187.4,270.7,186.4,272.5"/>
				<path fill="#00A44F" d="M178.5,273.8c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.4-3.6c1-1.8,0.8-3-0.6-2.8
			C181.5,270.4,179.5,272,178.5,273.8"/>
				<path fill="#00A44F" d="M93.8,289c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7C97,285.6,95,287.2,93.8,289"
				/>
				<path fill="#00A44F" d="M86.4,290.4c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6s1-3-0.3-2.7C89.5,287,87.5,288.7,86.4,290.4" />
				<path fill="#00A44F" d="M78.9,291.9c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C82,288.5,80.1,290.1,78.9,291.9"/>
				<path fill="#00A44F" d="M71.5,293.3c-1.2,1.8-1,3,0.2,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C74.7,289.9,72.7,291.6,71.5,293.3"/>
				<path fill="#00A44F" d="M64.2,294.8c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C67.3,291.4,65.3,293.1,64.2,294.8"/>
				<path fill="#00A44F" d="M56.9,296.3c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7S58,294.5,56.9,296.3"
				/>
				<path fill="#00A44F" d="M49.6,297.8c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C52.7,294.4,50.7,296,49.6,297.8"/>
				<path fill="#00A44F" d="M482.1,227.2c-0.5,1.7,0.2,3,1.7,2.9c1.5-0.1,3.1-1.6,3.7-3.3c0.5-1.7-0.3-3-1.7-2.9
			C484.3,223.9,482.7,225.4,482.1,227.2"/>
				<path fill="#00A44F" d="M473.5,227.9c-0.6,1.7,0.2,3,1.7,2.9c1.5-0.1,3.2-1.6,3.7-3.4c0.5-1.7-0.2-3-1.7-2.9
			C475.8,224.6,474.1,226.1,473.5,227.9"/>
				<path fill="#00A44F" d="M456.4,229.3c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.2-1.6,3.7-3.4c0.6-1.7-0.2-3-1.6-2.9
			C458.7,226.1,457,227.6,456.4,229.3"/>
				<path fill="#00A44F" d="M430.8,231.6c-0.6,1.7,0,3,1.5,2.9c1.5-0.1,3.2-1.7,3.8-3.4c0.6-1.7-0.1-3-1.5-2.9
			C433.1,228.4,431.4,229.9,430.8,231.6"/>
				<path fill="#00A44F" d="M422.3,232.4c-0.7,1.7,0,3,1.5,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.6-1.7,0-3-1.5-2.9
			C424.6,229.2,422.9,230.7,422.3,232.4"/>
				<path fill="#00A44F" d="M413.8,233.3c-0.7,1.7,0,3,1.5,2.9s3.2-1.7,3.9-3.4c0.7-1.7,0-3-1.5-2.9
			C416.2,230,414.4,231.5,413.8,233.3"/>
				<path fill="#00A44F" d="M405.3,234.1c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0-3-1.4-2.9
			C407.7,230.9,406,232.4,405.3,234.1"/>
				<path fill="#00A44F" d="M396.8,235c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.2-1.7,3.9-3.4s0.1-3-1.4-2.9
			C399.3,231.7,397.5,233.3,396.8,235"/>
				<path fill="#00A44F" d="M388.4,235.9c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.4-2.9
			C390.9,232.6,389.1,234.1,388.4,235.9"/>
				<path fill="#00A44F" d="M379.9,236.8c-0.7,1.7-0.1,3,1.3,2.9c1.5-0.2,3.2-1.7,4-3.4c0.7-1.7,0.1-3-1.3-2.9
			C382.5,233.5,380.7,235.1,379.9,236.8"/>
				<path fill="#00A44F" d="M371.5,237.7c-0.8,1.7-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4-3.4c0.7-1.7,0.2-3-1.3-2.9
			C374.1,234.4,372.3,236,371.5,237.7"/>
				<path fill="#00A44F" d="M363.1,238.7c-0.8,1.7-0.2,3,1.3,2.9c1.5-0.2,3.3-1.7,4-3.5c0.8-1.7,0.2-3-1.3-2.9
			C365.7,235.4,363.9,236.9,363.1,238.7"/>
				<path fill="#00A44F" d="M354.8,239.6c-0.8,1.7-0.2,3,1.2,2.9c1.5-0.2,3.3-1.7,4-3.5c0.8-1.7,0.2-3-1.2-2.8
			C357.4,236.4,355.6,237.9,354.8,239.6"/>
				<path fill="#00A44F" d="M346.4,240.6c-0.8,1.7-0.3,3,1.2,2.8c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.2-2.8
			C349,237.3,347.2,238.9,346.4,240.6"/>
				<path fill="#00A44F" d="M338.1,241.6c-0.8,1.7-0.3,3,1.1,2.8c1.5-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.1-2.8
			C340.7,238.3,338.9,239.9,338.1,241.6"/>
				<path fill="#00A44F" d="M329.8,242.6c-0.8,1.7-0.3,3,1.1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.1-2.8
			C332.5,239.3,330.6,240.9,329.8,242.6"/>
				<path fill="#00A44F" d="M321.5,243.7c-0.8,1.7-0.4,3,1.1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.1-2.8
			C324.2,240.4,322.3,241.9,321.5,243.7"/>
				<path fill="#00A44F" d="M313.2,244.7c-0.9,1.7-0.4,3,1.1,2.8c1.4-0.2,3.3-1.8,4.1-3.5c0.8-1.7,0.4-3-1.1-2.8
			C315.9,241.4,314.1,243,313.2,244.7"/>
				<path fill="#00A44F" d="M305,245.8c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.4-3-1-2.8
			C307.7,242.5,305.9,244.1,305,245.8"/>
				<path fill="#00A44F" d="M296.8,246.9c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.4-3-1-2.8
			C299.5,243.6,297.7,245.2,296.8,246.9"/>
				<path fill="#00A44F" d="M288.6,248c-0.9,1.8-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-1-2.8
			C291.4,244.7,289.5,246.3,288.6,248"/>
				<path fill="#00A44F" d="M280.4,249.1c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5s0.5-3-0.9-2.8
			C283.2,245.8,281.3,247.4,280.4,249.1"/>
				<path fill="#00A44F" d="M272.3,250.3c-0.9,1.8-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.5-3-0.9-2.8
			C275.1,246.9,273.2,248.5,272.3,250.3"/>
				<path fill="#00A44F" d="M264.1,251.4c-0.9,1.8-0.6,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.8,0.5-3-0.9-2.8
			C267,248.1,265.1,249.7,264.1,251.4"/>
				<path fill="#00A44F" d="M256,252.6c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.5c0.9-1.8,0.6-3-0.8-2.8
			C258.9,249.3,257,250.8,256,252.6"/>
				<path fill="#00A44F" d="M248,253.8c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.5s0.6-3-0.8-2.8S248.9,252,248,253.8" />
				<path fill="#00A44F" d="M239.9,255c-1,1.8-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.6-3-0.8-2.8
			C242.8,251.6,240.9,253.2,239.9,255"/>
				<path fill="#00A44F" d="M231.9,256.2c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.6-3-0.7-2.8
			C234.8,252.9,232.9,254.5,231.9,256.2"/>
				<path fill="#00A44F" d="M223.9,257.4c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C226.8,254.1,224.9,255.7,223.9,257.4"/>
				<path fill="#00A44F" d="M216,258.7c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8
			C218.9,255.3,217,256.9,216,258.7"/>
				<path fill="#00A44F" d="M208,260c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.7-2.8C211,256.6,209,258.2,208,260"
				/>
				<path fill="#00A44F" d="M200.1,261.2c-1,1.8-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.6-2.8
			C203.1,257.9,201.2,259.5,200.1,261.2"/>
				<path fill="#00A44F" d="M192.3,262.5c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.8-3-0.6-2.8S193.3,260.8,192.3,262.5"
				/>
				<path fill="#00A44F" d="M184.4,263.8c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.4-3.6c1-1.8,0.8-3-0.6-2.8
			C187.4,260.5,185.5,262.1,184.4,263.8"/>
				<path fill="#00A44F" d="M176.6,265.1c-1.1,1.8-0.8,3,0.5,2.8c1.4-0.2,3.3-1.8,4.4-3.6c1-1.8,0.8-3-0.5-2.8
			C179.6,261.8,177.7,263.4,176.6,265.1"/>
				<path fill="#00A44F" d="M122.8,274.7c-1.1,1.8-1,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C125.9,271.4,123.9,273,122.8,274.7"/>
				<path fill="#00A44F" d="M115.2,276.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C118.3,272.8,116.4,274.4,115.2,276.2"/>
				<path fill="#00A44F" d="M107.7,277.6c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C110.8,274.2,108.9,275.8,107.7,277.6"/>
				<path fill="#00A44F" d="M100.3,279c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C103.4,275.6,101.4,277.3,100.3,279"/>
				<path fill="#00A44F" d="M92.8,280.5c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C95.9,277.1,94,278.7,92.8,280.5"/>
				<path fill="#00A44F" d="M85.4,281.9c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C88.5,278.5,86.6,280.2,85.4,281.9"/>
				<path fill="#00A44F" d="M78,283.4c-1.2,1.8-1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6s1-3-0.2-2.7C81.2,280,79.2,281.6,78,283.4" />
				<path fill="#00A44F" d="M70.7,284.9c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C73.8,281.5,71.9,283.1,70.7,284.9"/>
				<path fill="#00A44F" d="M63.4,286.4c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C66.5,283,64.6,284.6,63.4,286.4"/>
				<path fill="#00A44F" d="M48.9,289.4c-1.2,1.8-1.1,3,0.1,2.7c1.3-0.3,3.2-1.9,4.4-3.7c1.2-1.8,1.1-3-0.2-2.7
			C52.1,286,50.1,287.6,48.9,289.4"/>
				<path fill="#00A44F" d="M485.2,217.4c-0.5,1.7,0.2,3,1.7,2.9c1.5-0.1,3.1-1.6,3.6-3.3c0.5-1.7-0.2-3-1.7-2.9
			C487.4,214.2,485.7,215.7,485.2,217.4"/>
				<path fill="#00A44F" d="M459.7,219.5c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.6-2.9
			C462,216.3,460.3,217.8,459.7,219.5"/>
				<path fill="#00A44F" d="M451.3,220.3c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.2-1.6,3.7-3.4c0.6-1.7-0.1-3-1.6-2.9
			C453.6,217.1,451.9,218.6,451.3,220.3"/>
				<path fill="#00A44F" d="M442.8,221.1c-0.6,1.7,0.1,3,1.5,2.9c1.5-0.1,3.2-1.6,3.8-3.4c0.6-1.7-0.1-3-1.5-2.9
			C445.1,217.8,443.4,219.3,442.8,221.1"/>
				<path fill="#00A44F" d="M434.4,221.9c-0.6,1.7,0,3,1.5,2.9c1.5-0.1,3.2-1.6,3.8-3.4c0.6-1.7,0-3-1.5-2.9
			C436.7,218.6,435,220.1,434.4,221.9"/>
				<path fill="#00A44F" d="M426,222.7c-0.7,1.7,0,3,1.5,2.9s3.2-1.7,3.8-3.4c0.6-1.7,0-3-1.5-2.9C428.3,219.4,426.6,220.9,426,222.7"
				/>
				<path fill="#00A44F" d="M417.6,223.5c-0.7,1.7,0,3,1.4,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0-3-1.4-2.9
			C420,220.2,418.2,221.8,417.6,223.5"/>
				<path fill="#00A44F" d="M409.2,224.3c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.4-2.9
			C411.6,221.1,409.9,222.6,409.2,224.3"/>
				<path fill="#00A44F" d="M400.8,225.2c-0.7,1.7-0.1,3,1.4,2.9c1.5-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.4-2.9
			C403.3,222,401.5,223.5,400.8,225.2"/>
				<path fill="#00A44F" d="M392.5,226.1c-0.7,1.7-0.1,3,1.3,2.9c1.5-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.3-2.8
			C394.9,222.8,393.2,224.4,392.5,226.1"/>
				<path fill="#00A44F" d="M375.8,227.9c-0.8,1.7-0.2,3,1.3,2.8c1.4-0.2,3.2-1.7,4-3.4c0.7-1.7,0.2-3-1.3-2.8
			C378.3,224.7,376.6,226.2,375.8,227.9"/>
				<path fill="#00A44F" d="M367.5,228.9c-0.8,1.7-0.2,3,1.2,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.2-3-1.2-2.8
			C370.1,225.6,368.3,227.1,367.5,228.9"/>
				<path fill="#00A44F" d="M359.2,229.8c-0.8,1.7-0.3,3,1.2,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.2-3-1.2-2.8
			C361.8,226.5,360,228.1,359.2,229.8"/>
				<path fill="#00A44F" d="M351,230.8c-0.8,1.7-0.3,3,1.2,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.3-3-1.2-2.8
			C353.6,227.5,351.8,229.1,351,230.8"/>
				<path fill="#00A44F" d="M342.7,231.8c-0.8,1.7-0.3,3,1.1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.1-2.8
			C345.3,228.5,343.5,230.1,342.7,231.8"/>
				<path fill="#00A44F" d="M334.5,232.8c-0.8,1.7-0.3,3,1.1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.3-3-1.1-2.8
			C337.1,229.5,335.3,231.1,334.5,232.8"/>
				<path fill="#00A44F" d="M326.3,233.8c-0.8,1.7-0.4,3,1.1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.8-1.7,0.4-3-1.1-2.8
			C329,230.5,327.1,232.1,326.3,233.8"/>
				<path fill="#00A44F" d="M318.1,234.9c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.9-1.7,0.4-3-1-2.8
			C320.8,231.6,318.9,233.1,318.1,234.9"/>
				<path fill="#00A44F" d="M309.9,236c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.9-1.7,0.4-3-1-2.8
			C312.7,232.7,310.8,234.2,309.9,236"/>
				<path fill="#00A44F" d="M301.8,237c-0.9,1.7-0.5,3,1,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.4-3-1-2.8
			C304.5,233.7,302.7,235.3,301.8,237"/>
				<path fill="#00A44F" d="M293.7,238.1c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.9-2.8
			S294.6,236.4,293.7,238.1"/>
				<path fill="#00A44F" d="M285.6,239.3c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.9-2.8
			C288.4,235.9,286.5,237.5,285.6,239.3"/>
				<path fill="#00A44F" d="M277.5,240.4c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.9-2.8
			C280.3,237.1,278.4,238.6,277.5,240.4"/>
				<path fill="#00A44F" d="M269.4,241.5c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C272.3,238.2,270.4,239.8,269.4,241.5"/>
				<path fill="#00A44F" d="M261.4,242.7c-1,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C264.3,239.4,262.4,241,261.4,242.7"/>
				<path fill="#00A44F" d="M253.4,243.9c-1,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.6-3-0.8-2.8
			C256.3,240.6,254.4,242.1,253.4,243.9"/>
				<path fill="#00A44F" d="M245.4,245.1c-1,1.7-0.6,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.6-3-0.7-2.8
			C248.3,241.8,246.4,243.3,245.4,245.1"/>
				<path fill="#00A44F" d="M237.5,246.3c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.7-2.8
			C240.4,243,238.5,244.6,237.5,246.3"/>
				<path fill="#00A44F" d="M229.6,247.5c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.7,0.7-3-0.7-2.8
			C232.5,244.2,230.6,245.8,229.6,247.5"/>
				<path fill="#00A44F" d="M221.7,248.8c-1,1.8-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.6s0.7-3-0.7-2.8
			C224.6,245.4,222.7,247,221.7,248.8"/>
				<path fill="#00A44F" d="M213.8,250c-1,1.8-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.7-3-0.6-2.8
			C216.7,246.7,214.8,248.3,213.8,250"/>
				<path fill="#00A44F" d="M205.9,251.3c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.8-3-0.6-2.8
			C208.9,248,207,249.6,205.9,251.3"/>
				<path fill="#00A44F" d="M198.1,252.6c-1,1.8-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.8-3-0.6-2.8S199.2,250.8,198.1,252.6"
				/>
				<path fill="#00A44F" d="M190.3,253.9c-1.1,1.8-0.8,3,0.5,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.8,0.8-3-0.6-2.8
			C193.3,250.5,191.4,252.2,190.3,253.9"/>
				<path fill="#00A44F" d="M182.6,255.2c-1.1,1.8-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.8,0.8-3-0.5-2.8
			C185.6,251.9,183.6,253.5,182.6,255.2"/>
				<path fill="#00A44F" d="M174.8,256.5c-1.1,1.8-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.4-3.6c1.1-1.8,0.8-3-0.5-2.8
			C177.9,253.2,175.9,254.8,174.8,256.5"/>
				<path fill="#00A44F" d="M167.1,257.9c-1.1,1.8-0.9,3,0.5,2.8c1.3-0.2,3.3-1.8,4.4-3.6c1.1-1.8,0.9-3-0.5-2.8
			C170.2,254.5,168.2,256.1,167.1,257.9"/>
				<path fill="#00A44F" d="M159.5,259.2c-1.1,1.8-0.9,3,0.4,2.8c1.3-0.2,3.3-1.8,4.4-3.6c1.1-1.8,0.9-3-0.4-2.8
			C162.5,255.9,160.6,257.5,159.5,259.2"/>
				<path fill="#00A44F" d="M151.8,260.6c-1.1,1.8-0.9,3,0.4,2.8c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C154.9,257.2,152.9,258.9,151.8,260.6"/>
				<path fill="#00A44F" d="M144.2,262c-1.1,1.8-0.9,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C147.3,258.6,145.3,260.2,144.2,262"/>
				<path fill="#00A44F" d="M136.6,263.4c-1.1,1.8-0.9,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C139.7,260,137.8,261.6,136.6,263.4"/>
				<path fill="#00A44F" d="M129.1,264.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.4-2.7
			C132.2,261.4,130.2,263,129.1,264.8"/>
				<path fill="#00A44F" d="M121.6,266.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C124.7,262.8,122.7,264.5,121.6,266.2"/>
				<path fill="#00A44F" d="M114.1,267.6c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C117.2,264.3,115.2,265.9,114.1,267.6"/>
				<path fill="#00A44F" d="M106.7,269.1c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C109.8,265.7,107.8,267.3,106.7,269.1"/>
				<path fill="#00A44F" d="M99.3,270.5c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C102.4,267.1,100.4,268.8,99.3,270.5"/>
				<path fill="#00A44F" d="M91.9,272c-1.1,1.8-1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7S93,270.2,91.9,272" />
				<path fill="#00A44F" d="M84.5,273.5c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C87.7,270.1,85.7,271.7,84.5,273.5"/>
				<path fill="#00A44F" d="M77.2,275c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C80.4,271.6,78.4,273.2,77.2,275"/>
				<path fill="#00A44F" d="M70,276.4c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C73.1,273.1,71.1,274.7,70,276.4"/>
				<path fill="#00A44F" d="M62.7,277.9c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.7c1.2-1.8,1.1-3-0.2-2.7
			C65.9,274.6,63.9,276.2,62.7,277.9"/>
				<path fill="#00A44F" d="M463.1,209.9c-0.6,1.7,0.1,3,1.6,2.9c1.5-0.1,3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.6-2.9
			C465.3,206.7,463.7,208.2,463.1,209.9"/>
				<path fill="#00A44F" d="M454.7,210.6c-0.6,1.7,0.1,3,1.5,2.9c1.5-0.1,3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.5-2.9
			C457,207.4,455.3,208.9,454.7,210.6"/>
				<path fill="#00A44F" d="M446.4,211.4c-0.6,1.7,0,3,1.5,2.9c1.5-0.1,3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.5-2.9
			C448.7,208.2,447,209.7,446.4,211.4"/>
				<path fill="#00A44F" d="M438,212.1c-0.6,1.7,0,3,1.5,2.9c1.5-0.1,3.1-1.6,3.8-3.3c0.6-1.7,0-3-1.5-2.8
			C440.4,208.9,438.7,210.4,438,212.1"/>
				<path fill="#00A44F" d="M429.7,212.9c-0.7,1.7,0,3,1.4,2.9c1.4-0.1,3.2-1.6,3.8-3.4c0.6-1.7,0-3-1.4-2.8
			C432.1,209.7,430.4,211.2,429.7,212.9"/>
				<path fill="#00A44F" d="M421.4,213.8c-0.7,1.7-0.1,3,1.4,2.8c1.4-0.1,3.2-1.6,3.8-3.4c0.7-1.7,0-3-1.4-2.8
			C423.8,210.5,422.1,212.1,421.4,213.8"/>
				<path fill="#00A44F" d="M413.1,214.6c-0.7,1.7-0.1,3,1.4,2.8c1.4-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.4-2.8
			C415.5,211.4,413.8,212.9,413.1,214.6"/>
				<path fill="#00A44F" d="M404.8,215.5c-0.7,1.7-0.1,3,1.3,2.8c1.4-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.3-2.8
			C407.3,212.2,405.5,213.7,404.8,215.5"/>
				<path fill="#00A44F" d="M396.6,216.3c-0.7,1.7-0.2,3,1.3,2.8c1.4-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.3-2.8
			C399,213.1,397.3,214.6,396.6,216.3"/>
				<path fill="#00A44F" d="M388.3,217.2c-0.7,1.7-0.2,3,1.3,2.8c1.4-0.2,3.2-1.7,3.9-3.4s0.2-3-1.3-2.8
			C390.8,214,389.1,215.5,388.3,217.2"/>
				<path fill="#00A44F" d="M371.9,219.1c-0.8,1.7-0.2,3,1.2,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.2-3-1.2-2.8
			C374.4,215.8,372.6,217.4,371.9,219.1"/>
				<path fill="#00A44F" d="M363.7,220c-0.8,1.7-0.3,3,1.2,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.3-3-1.2-2.8
			C366.2,216.8,364.5,218.3,363.7,220"/>
				<path fill="#00A44F" d="M355.5,221c-0.8,1.7-0.3,3,1.1,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.3-3-1.1-2.8
			C358.1,217.8,356.3,219.3,355.5,221"/>
				<path fill="#00A44F" d="M347.3,222c-0.8,1.7-0.3,3,1.1,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.3-3-1.1-2.8
			C350,218.7,348.1,220.3,347.3,222"/>
				<path fill="#00A44F" d="M339.2,223c-0.8,1.7-0.4,3,1.1,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.8-1.7,0.3-3-1.1-2.8
			C341.8,219.7,340,221.3,339.2,223"/>
				<path fill="#00A44F" d="M331.1,224c-0.8,1.7-0.4,3,1,2.8s3.2-1.7,4.1-3.5c0.8-1.7,0.4-3-1-2.8C333.7,220.8,331.9,222.3,331.1,224"
				/>
				<path fill="#00A44F" d="M323,225.1c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.4-3-1-2.8
			C325.7,221.8,323.8,223.4,323,225.1"/>
				<path fill="#00A44F" d="M314.9,226.1c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.9-1.7,0.4-3-1-2.8
			C317.6,222.9,315.7,224.4,314.9,226.1"/>
				<path fill="#00A44F" d="M306.8,227.2c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.7,4.1-3.5c0.9-1.7,0.5-3-0.9-2.8
			C309.5,223.9,307.7,225.5,306.8,227.2"/>
				<path fill="#00A44F" d="M298.8,228.3c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.9-2.8
			C301.5,225,299.7,226.6,298.8,228.3"/>
				<path fill="#00A44F" d="M290.7,229.4c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.9-2.8
			C293.5,226.1,291.7,227.7,290.7,229.4"/>
				<path fill="#00A44F" d="M282.7,230.6c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.5-3-0.8-2.8
			C285.5,227.3,283.7,228.8,282.7,230.6"/>
				<path fill="#00A44F" d="M274.8,231.7c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C277.6,228.4,275.7,230,274.8,231.7"/>
				<path fill="#00A44F" d="M266.8,232.9c-1,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C269.6,229.5,267.8,231.1,266.8,232.9"/>
				<path fill="#00A44F" d="M258.9,234c-1,1.7-0.6,3,0.7,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.6-3-0.7-2.8
			C261.7,230.7,259.8,232.3,258.9,234"/>
				<path fill="#00A44F" d="M251,235.2c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.6-3-0.7-2.8
			C253.8,231.9,251.9,233.5,251,235.2"/>
				<path fill="#00A44F" d="M243.1,236.4c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.7-2.8
			C246,233.1,244.1,234.7,243.1,236.4"/>
				<path fill="#00A44F" d="M235.2,237.7c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.7-2.8
			C238.1,234.3,236.2,235.9,235.2,237.7"/>
				<path fill="#00A44F" d="M227.4,238.9c-1,1.7-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.6-2.8
			C230.3,235.6,228.4,237.2,227.4,238.9"/>
				<path fill="#00A44F" d="M219.6,240.2c-1,1.7-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.7,0.7-3-0.6-2.8
			C222.5,236.8,220.6,238.4,219.6,240.2"/>
				<path fill="#00A44F" d="M211.8,241.4c-1,1.7-0.8,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.6c1-1.7,0.8-3-0.6-2.8
			C214.7,238.1,212.8,239.7,211.8,241.4"/>
				<path fill="#00A44F" d="M204,242.7c-1,1.7-0.8,3,0.6,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1-1.7,0.8-3-0.6-2.8
			C207,239.4,205,241,204,242.7"/>
				<path fill="#00A44F" d="M196.3,244c-1.1,1.7-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1-1.7,0.8-3-0.5-2.8
			C199.3,240.7,197.3,242.3,196.3,244"/>
				<path fill="#00A44F" d="M188.6,245.3c-1.1,1.8-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.8-3-0.5-2.8
			C191.6,242,189.6,243.6,188.6,245.3"/>
				<path fill="#00A44F" d="M180.9,246.6c-1.1,1.8-0.9,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.8,0.8-3-0.5-2.8
			C183.9,243.3,182,244.9,180.9,246.6"/>
				<path fill="#00A44F" d="M173.2,248c-1.1,1.8-0.9,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.8,0.9-3-0.5-2.7
			C176.3,244.6,174.3,246.2,173.2,248"/>
				<path fill="#00A44F" d="M165.6,249.3c-1.1,1.8-0.9,3,0.4,2.7c1.3-0.2,3.3-1.8,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C168.6,246,166.7,247.6,165.6,249.3"/>
				<path fill="#00A44F" d="M158,250.7c-1.1,1.8-0.9,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C161.1,247.3,159.1,248.9,158,250.7"/>
				<path fill="#00A44F" d="M150.5,252.1c-1.1,1.8-0.9,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C153.5,248.7,151.6,250.3,150.5,252.1"/>
				<path fill="#00A44F" d="M142.9,253.5c-1.1,1.8-1,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,0.9-3-0.4-2.7
			C146,250.1,144,251.7,142.9,253.5"/>
				<path fill="#00A44F" d="M135.4,254.9c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C138.5,251.5,136.5,253.1,135.4,254.9"/>
				<path fill="#00A44F" d="M127.9,256.3c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C131,252.9,129.1,254.5,127.9,256.3"/>
				<path fill="#00A44F" d="M120.5,257.7c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C123.6,254.3,121.6,256,120.5,257.7"/>
				<path fill="#00A44F" d="M113.1,259.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C116.2,255.8,114.2,257.4,113.1,259.2"/>
				<path fill="#00A44F" d="M105.7,260.6c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C108.8,257.2,106.9,258.9,105.7,260.6"/>
				<path fill="#00A44F" d="M98.4,262.1c-1.1,1.8-1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C101.5,258.7,99.5,260.3,98.4,262.1"/>
				<path fill="#00A44F" d="M91.1,263.5c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1.1-3-0.2-2.7
			C94.2,260.2,92.2,261.8,91.1,263.5"/>
				<path fill="#00A44F" d="M83.8,265c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C86.9,261.6,84.9,263.3,83.8,265"/>
				<path fill="#00A44F" d="M76.5,266.5c-1.2,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.2-1.8,1.1-3-0.2-2.7
			C79.6,263.1,77.7,264.8,76.5,266.5"/>
				<path fill="#00A44F" d="M466.4,200.2c-0.6,1.7,0.1,3,1.5,2.8s3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.5-2.8
			C468.7,197.1,467,198.5,466.4,200.2"/>
				<path fill="#00A44F" d="M458.2,201c-0.6,1.7,0.1,3,1.5,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.6-1.7-0.1-3-1.5-2.8
			C460.4,197.8,458.8,199.3,458.2,201"/>
				<path fill="#00A44F" d="M449.9,201.7c-0.6,1.7,0,3,1.5,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.6-1.7,0-3-1.5-2.8
			C452.2,198.6,450.6,200,449.9,201.7"/>
				<path fill="#00A44F" d="M441.7,202.5c-0.6,1.7,0,3,1.4,2.8c1.4-0.1,3.1-1.6,3.7-3.3s0-3-1.4-2.8
			C444,199.3,442.3,200.8,441.7,202.5"/>
				<path fill="#00A44F" d="M433.5,203.3c-0.7,1.7,0,3,1.4,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0-3-1.4-2.8
			C435.8,200.1,434.1,201.6,433.5,203.3"/>
				<path fill="#00A44F" d="M425.2,204.1c-0.7,1.7-0.1,3,1.4,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.1-3-1.4-2.8
			C427.6,200.9,425.9,202.4,425.2,204.1"/>
				<path fill="#00A44F" d="M417,204.9c-0.7,1.7-0.1,3,1.3,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.1-3-1.3-2.8
			C419.4,201.7,417.7,203.2,417,204.9"/>
				<path fill="#00A44F" d="M408.9,205.8c-0.7,1.7-0.1,3,1.3,2.8c1.4-0.1,3.2-1.7,3.9-3.4c0.7-1.7,0.1-3-1.3-2.8
			C411.3,202.6,409.6,204.1,408.9,205.8"/>
				<path fill="#00A44F" d="M400.7,206.7c-0.7,1.7-0.2,3,1.3,2.8c1.4-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.2-3-1.3-2.8
			S401.4,205,400.7,206.7"/>
				<path fill="#00A44F" d="M392.5,207.6c-0.7,1.7-0.2,3,1.2,2.8c1.4-0.2,3.2-1.7,3.9-3.4c0.7-1.7,0.2-3-1.2-2.8
			C395,204.3,393.3,205.8,392.5,207.6"/>
				<path fill="#00A44F" d="M327.8,215.3c-0.9,1.7-0.4,3,1,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.4-3-1-2.8
			C330.5,212.1,328.7,213.6,327.8,215.3"/>
				<path fill="#00A44F" d="M319.8,216.4c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.5-3-0.9-2.8
			C322.5,213.1,320.7,214.7,319.8,216.4"/>
				<path fill="#00A44F" d="M311.8,217.4c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.5-3-0.9-2.8
			C314.6,214.2,312.7,215.7,311.8,217.4"/>
				<path fill="#00A44F" d="M303.9,218.5c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.5-3-0.9-2.8
			C306.6,215.3,304.8,216.8,303.9,218.5"/>
				<path fill="#00A44F" d="M295.9,219.6c-0.9,1.7-0.5,3,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.5-3-0.8-2.8
			C298.7,216.4,296.8,217.9,295.9,219.6"/>
				<path fill="#00A44F" d="M288,220.8c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.2-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C290.8,217.5,288.9,219,288,220.8"/>
				<path fill="#00A44F" d="M280.1,221.9c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C282.9,218.6,281,220.2,280.1,221.9"/>
				<path fill="#00A44F" d="M272.2,223c-1,1.7-0.6,3,0.8,2.8s3.3-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8C275,219.8,273.2,221.3,272.2,223"
				/>
				<path fill="#00A44F" d="M264.3,224.2c-1,1.7-0.6,3,0.7,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.6-3-0.7-2.8
			C267.2,220.9,265.3,222.5,264.3,224.2"/>
				<path fill="#00A44F" d="M256.5,225.4c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.7-3-0.7-2.8
			C259.4,222.1,257.5,223.7,256.5,225.4"/>
				<path fill="#00A44F" d="M248.7,226.6c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.3-1.8,4.2-3.5c1-1.7,0.7-3-0.7-2.8
			C251.6,223.3,249.7,224.9,248.7,226.6"/>
				<path fill="#00A44F" d="M240.9,227.8c-1,1.7-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.6-2.8
			C243.8,224.5,241.9,226.1,240.9,227.8"/>
				<path fill="#00A44F" d="M233.1,229.1c-1,1.7-0.7,3,0.6,2.8c1.4-0.2,3.3-1.8,4.3-3.5c1-1.7,0.7-3-0.6-2.8
			C236,225.7,234.1,227.3,233.1,229.1"/>
				<path fill="#00A44F" d="M225.3,230.3c-1,1.7-0.8,3,0.6,2.8c1.3-0.2,3.3-1.8,4.3-3.5c1-1.7,0.8-3-0.6-2.8
			C228.3,227,226.4,228.6,225.3,230.3"/>
				<path fill="#00A44F" d="M217.6,231.6c-1,1.7-0.8,3,0.6,2.8c1.3-0.2,3.3-1.8,4.3-3.5c1-1.7,0.8-3-0.6-2.8
			C220.6,228.2,218.6,229.8,217.6,231.6"/>
				<path fill="#00A44F" d="M209.9,232.8c-1,1.7-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6c1-1.7,0.8-3-0.5-2.8S210.9,231.1,209.9,232.8"
				/>
				<path fill="#00A44F" d="M202.2,234.1c-1.1,1.7-0.8,3,0.5,2.8c1.3-0.2,3.3-1.8,4.3-3.6s0.8-3-0.5-2.7
			C205.2,230.8,203.3,232.4,202.2,234.1"/>
				<path fill="#00A44F" d="M194.6,235.4c-1.1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.3-1.8,4.3-3.6s0.8-3-0.5-2.7
			C197.6,232.1,195.6,233.7,194.6,235.4"/>
				<path fill="#00A44F" d="M186.9,236.8c-1.1,1.7-0.9,3,0.5,2.7c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.9-3-0.5-2.7S188,235,186.9,236.8"
				/>
				<path fill="#00A44F" d="M179.3,238.1c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C182.4,234.7,180.4,236.3,179.3,238.1"/>
				<path fill="#00A44F" d="M171.8,239.4c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C174.8,236.1,172.9,237.7,171.8,239.4"/>
				<path fill="#00A44F" d="M164.2,240.8c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.3-1.8,4.4-3.6c1.1-1.7,0.9-3-0.4-2.7
			C167.3,237.4,165.3,239.1,164.2,240.8"/>
				<path fill="#00A44F" d="M156.7,242.2c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.7,0.9-3-0.4-2.7
			C159.7,238.8,157.8,240.4,156.7,242.2"/>
				<path fill="#00A44F" d="M149.2,243.6c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C152.3,240.2,150.3,241.8,149.2,243.6"/>
				<path fill="#00A44F" d="M141.7,245c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.3-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C144.8,241.6,142.9,243.2,141.7,245"/>
				<path fill="#00A44F" d="M134.3,246.4c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C137.4,243,135.4,244.6,134.3,246.4"/>
				<path fill="#00A44F" d="M126.9,247.8c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C130,244.4,128,246.1,126.9,247.8"/>
				<path fill="#00A44F" d="M119.5,249.2c-1.1,1.8-1,3,0.3,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.3-2.7
			C122.6,245.9,120.7,247.5,119.5,249.2"/>
				<path fill="#00A44F" d="M112.2,250.7c-1.1,1.8-1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C115.3,247.3,113.3,248.9,112.2,250.7"/>
				<path fill="#00A44F" d="M104.9,252.1c-1.1,1.8-1.1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C108,248.8,106,250.4,104.9,252.1"/>
				<path fill="#00A44F" d="M461.6,191.4c-0.6,1.7,0,2.9,1.5,2.8s3.1-1.6,3.7-3.3c0.6-1.7-0.1-2.9-1.5-2.8
			C463.9,188.3,462.3,189.7,461.6,191.4"/>
				<path fill="#00A44F" d="M453.5,192.2c-0.6,1.7,0,2.9,1.4,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.6-1.7,0-2.9-1.4-2.8
			C455.8,189,454.1,190.5,453.5,192.2"/>
				<path fill="#00A44F" d="M445.3,192.9c-0.6,1.7,0,2.9,1.4,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.6-1.7,0-2.9-1.4-2.8
			C447.7,189.8,446,191.3,445.3,192.9"/>
				<path fill="#00A44F" d="M437.2,193.7c-0.7,1.7-0.1,2.9,1.4,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.7-1.7,0-2.9-1.4-2.8
			C439.6,190.6,437.9,192,437.2,193.7"/>
				<path fill="#00A44F" d="M429.1,194.5c-0.7,1.7-0.1,2.9,1.3,2.8s3.1-1.6,3.8-3.3c0.7-1.7,0.1-2.9-1.3-2.8
			C431.5,191.4,429.8,192.8,429.1,194.5"/>
				<path fill="#00A44F" d="M421,195.4c-0.7,1.7-0.1,2.9,1.3,2.8s3.1-1.6,3.8-3.3c0.7-1.7,0.1-2.9-1.3-2.8
			C423.4,192.2,421.7,193.7,421,195.4"/>
				<path fill="#00A44F" d="M412.9,196.2c-0.7,1.7-0.2,2.9,1.3,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.1-2.9-1.3-2.8
			C415.3,193,413.6,194.5,412.9,196.2"/>
				<path fill="#00A44F" d="M404.8,197.1c-0.7,1.7-0.2,2.9,1.2,2.8c1.4-0.2,3.1-1.6,3.9-3.3c0.7-1.7,0.2-2.9-1.2-2.8
			C407.3,193.9,405.5,195.4,404.8,197.1"/>
				<path fill="#00A44F" d="M396.7,197.9c-0.7,1.7-0.2,2.9,1.2,2.8c1.4-0.2,3.1-1.7,3.9-3.3c0.7-1.7,0.2-2.9-1.2-2.8
			C399.2,194.7,397.5,196.2,396.7,197.9"/>
				<path fill="#00A44F" d="M332.7,205.6c-0.9,1.7-0.4,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.4-2.9-0.9-2.8
			C335.4,202.4,333.6,203.9,332.7,205.6"/>
				<path fill="#00A44F" d="M324.8,206.7c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.9-2.8
			C327.5,203.4,325.7,205,324.8,206.7"/>
				<path fill="#00A44F" d="M316.9,207.7c-0.9,1.7-0.5,3,0.9,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.9-2.8
			C319.6,204.5,317.8,206,316.9,207.7"/>
				<path fill="#00A44F" d="M309,208.8c-0.9,1.7-0.5,3,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.8-2.8
			C311.7,205.6,309.9,207.1,309,208.8"/>
				<path fill="#00A44F" d="M301.1,209.9c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.5-3-0.8-2.8
			C303.9,206.6,302,208.2,301.1,209.9"/>
				<path fill="#00A44F" d="M293.3,211c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.6-3-0.8-2.8
			C296,207.8,294.2,209.3,293.3,211"/>
				<path fill="#00A44F" d="M285.4,212.1c-0.9,1.7-0.6,3,0.8,2.8c1.4-0.2,3.2-1.8,4.2-3.5c0.9-1.7,0.6-3-0.8-2.8
			C288.2,208.9,286.4,210.4,285.4,212.1"/>
				<path fill="#00A44F" d="M277.6,213.3c-1,1.7-0.6,3,0.7,2.8c1.4-0.2,3.2-1.8,4.2-3.5c0.9-1.7,0.6-3-0.7-2.8
			C280.4,210,278.6,211.6,277.6,213.3"/>
				<path fill="#00A44F" d="M269.8,214.4c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.2-1.8,4.2-3.5c1-1.7,0.6-3-0.7-2.8
			C272.6,211.2,270.8,212.7,269.8,214.4"/>
				<path fill="#00A44F" d="M262,215.6c-1,1.7-0.7,3,0.7,2.8c1.4-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-3-0.7-2.8
			C264.9,212.3,263,213.9,262,215.6"/>
				<path fill="#00A44F" d="M254.3,216.8c-1,1.7-0.7,3,0.6,2.8c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-3-0.6-2.8
			C257.1,213.5,255.3,215.1,254.3,216.8"/>
				<path fill="#00A44F" d="M246.5,218c-1,1.7-0.7,3,0.6,2.8c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-3-0.6-2.7
			C249.4,214.7,247.5,216.3,246.5,218"/>
				<path fill="#00A44F" d="M238.8,219.3c-1,1.7-0.8,3,0.6,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.7-3-0.6-2.7
			C241.7,215.9,239.8,217.5,238.8,219.3"/>
				<path fill="#00A44F" d="M231.1,220.5c-1,1.7-0.8,3,0.6,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-3-0.6-2.7
			C234.1,217.2,232.1,218.8,231.1,220.5"/>
				<path fill="#00A44F" d="M223.5,221.7c-1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-3-0.5-2.7
			C226.4,218.4,224.5,220,223.5,221.7"/>
				<path fill="#00A44F" d="M215.8,223c-1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-3-0.5-2.7
			C218.8,219.7,216.8,221.3,215.8,223"/>
				<path fill="#00A44F" d="M208.2,224.3c-1.1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.3-1.8,4.3-3.5c1-1.7,0.8-3-0.5-2.7
			C211.2,221,209.2,222.6,208.2,224.3"/>
				<path fill="#00A44F" d="M200.6,225.6c-1.1,1.7-0.9,3,0.5,2.7c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.8-3-0.5-2.7
			C203.6,222.3,201.6,223.9,200.6,225.6"/>
				<path fill="#00A44F" d="M193,226.9c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.3-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C196,223.6,194.1,225.2,193,226.9"/>
				<path fill="#00A44F" d="M185.4,228.2c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.2-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			S186.5,226.5,185.4,228.2"/>
				<path fill="#00A44F" d="M177.9,229.6c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.2-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C180.9,226.2,179,227.8,177.9,229.6"/>
				<path fill="#00A44F" d="M170.4,230.9c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.2-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C173.5,227.6,171.5,229.2,170.4,230.9"/>
				<path fill="#00A44F" d="M162.9,232.3c-1.1,1.7-1,3,0.3,2.7c1.3-0.2,3.2-1.8,4.3-3.6c1.1-1.7,0.9-3-0.4-2.7
			C166,229,164,230.6,162.9,232.3"/>
				<path fill="#00A44F" d="M155.5,233.7c-1.1,1.7-1,3,0.3,2.7c1.3-0.2,3.2-1.9,4.3-3.6c1.1-1.7,1-3-0.3-2.7S156.6,231.9,155.5,233.7"
				/>
				<path fill="#00A44F" d="M148.1,235.1c-1.1,1.7-1,3,0.3,2.7c1.3-0.2,3.2-1.9,4.4-3.6c1.1-1.7,1-3-0.3-2.7S149.2,233.3,148.1,235.1"
				/>
				<path fill="#00A44F" d="M140.7,236.5c-1.1,1.7-1,3,0.3,2.7s3.2-1.9,4.4-3.6c1.1-1.7,1-3-0.3-2.7
			C143.7,233.1,141.8,234.7,140.7,236.5"/>
				<path fill="#00A44F" d="M133.3,237.9c-1.1,1.8-1,3,0.3,2.7c1.3-0.2,3.2-1.9,4.4-3.6c1.1-1.7,1-3-0.3-2.7
			C136.4,234.5,134.4,236.2,133.3,237.9"/>
				<path fill="#00A44F" d="M118.6,240.8c-1.1,1.8-1,3,0.2,2.7c1.3-0.3,3.2-1.9,4.4-3.6c1.1-1.8,1-3-0.2-2.7
			C121.7,237.4,119.8,239,118.6,240.8"/>
				<path fill="#00A44F" d="M457.1,182.7c-0.6,1.7,0,2.9,1.4,2.8c1.4-0.1,3-1.6,3.7-3.3c0.6-1.7,0-2.9-1.4-2.8
			C459.3,179.6,457.7,181,457.1,182.7"/>
				<path fill="#00A44F" d="M424.9,185.8c-0.7,1.7-0.1,2.9,1.3,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.1-2.9-1.3-2.8
			C427.3,182.7,425.6,184.2,424.9,185.8"/>
				<path fill="#00A44F" d="M416.9,186.7c-0.7,1.7-0.2,2.9,1.2,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.2-2.9-1.2-2.8
			C419.3,183.5,417.6,185,416.9,186.7"/>
				<path fill="#00A44F" d="M408.9,187.5c-0.7,1.7-0.2,2.9,1.2,2.8c1.4-0.1,3.1-1.6,3.8-3.3c0.7-1.7,0.2-2.9-1.2-2.8
			C411.4,184.4,409.7,185.8,408.9,187.5"/>
				<path fill="#00A44F" d="M401,188.4c-0.7,1.7-0.2,2.9,1.2,2.8c1.4-0.2,3.1-1.6,3.8-3.3c0.7-1.7,0.2-2.9-1.2-2.8
			C403.4,185.2,401.7,186.7,401,188.4"/>
				<path fill="#00A44F" d="M377.1,191.1c-0.8,1.7-0.3,2.9,1.1,2.8c1.4-0.2,3.1-1.7,3.9-3.3c0.8-1.7,0.3-2.9-1.1-2.8
			C379.6,187.9,377.9,189.4,377.1,191.1"/>
				<path fill="#00A44F" d="M353.4,194c-0.8,1.7-0.4,2.9,1,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.4-2.9-1-2.8
			C356,190.8,354.2,192.3,353.4,194"/>
				<path fill="#00A44F" d="M345.5,195c-0.9,1.7-0.4,2.9,0.9,2.8c1.4-0.2,3.2-1.7,4-3.4c0.8-1.7,0.4-2.9-0.9-2.8
			C348.1,191.8,346.3,193.3,345.5,195"/>
				<path fill="#00A44F" d="M337.6,196c-0.9,1.7-0.5,2.9,0.9,2.8c1.4-0.2,3.2-1.7,4-3.4c0.9-1.7,0.4-2.9-0.9-2.8
			C340.3,192.8,338.5,194.3,337.6,196"/>
				<path fill="#00A44F" d="M329.8,197c-0.9,1.7-0.5,2.9,0.9,2.8c1.4-0.2,3.2-1.7,4-3.4c0.9-1.7,0.5-2.9-0.9-2.8
			C332.5,193.8,330.6,195.3,329.8,197"/>
				<path fill="#00A44F" d="M321.9,198.1c-0.9,1.7-0.5,2.9,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C324.6,194.8,322.8,196.4,321.9,198.1"/>
				<path fill="#00A44F" d="M314.1,199.1c-0.9,1.7-0.5,2.9,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C316.8,195.9,315,197.4,314.1,199.1"/>
				<path fill="#00A44F" d="M306.3,200.2c-0.9,1.7-0.6,2.9,0.8,2.8c1.4-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.8-2.7
			C309.1,197,307.2,198.5,306.3,200.2"/>
				<path fill="#00A44F" d="M298.5,201.3c-0.9,1.7-0.6,2.9,0.8,2.7s3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.8-2.7
			C301.3,198.1,299.5,199.6,298.5,201.3"/>
				<path fill="#00A44F" d="M290.8,202.4c-0.9,1.7-0.6,2.9,0.7,2.7c1.4-0.2,3.2-1.7,4.1-3.5c0.9-1.7,0.6-2.9-0.7-2.7
			C293.6,199.2,291.7,200.7,290.8,202.4"/>
				<path fill="#00A44F" d="M283,203.6c-1,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.2-3.5c0.9-1.7,0.6-2.9-0.7-2.7
			C285.8,200.3,284,201.9,283,203.6"/>
				<path fill="#00A44F" d="M275.3,204.7c-1,1.7-0.7,2.9,0.7,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.7-2.7
			C278.1,201.5,276.3,203,275.3,204.7"/>
				<path fill="#00A44F" d="M267.6,205.9c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.7-2.7
			C270.4,202.6,268.6,204.2,267.6,205.9"/>
				<path fill="#00A44F" d="M259.9,207.1c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.6-2.7
			C262.8,203.8,260.9,205.4,259.9,207.1"/>
				<path fill="#00A44F" d="M252.2,208.3c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.6-2.7
			C255.1,205,253.2,206.6,252.2,208.3"/>
				<path fill="#00A44F" d="M244.6,209.5c-1,1.7-0.8,3,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.8-2.9-0.6-2.7
			C247.5,206.2,245.6,207.8,244.6,209.5"/>
				<path fill="#00A44F" d="M236.9,210.7c-1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.8-2.9-0.5-2.7
			C239.8,207.4,237.9,209,236.9,210.7"/>
				<path fill="#00A44F" d="M229.3,212c-1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-2.9-0.5-2.7
			C232.2,208.7,230.3,210.2,229.3,212"/>
				<path fill="#00A44F" d="M221.7,213.2c-1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-2.9-0.5-2.7
			C224.7,209.9,222.8,211.5,221.7,213.2"/>
				<path fill="#00A44F" d="M214.1,214.5c-1.1,1.7-0.8,3,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-3-0.5-2.7
			C217.1,211.2,215.2,212.8,214.1,214.5"/>
				<path fill="#00A44F" d="M206.6,215.8c-1.1,1.7-0.9,3,0.4,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1.1-1.7,0.9-3-0.4-2.7
			C209.6,212.5,207.7,214.1,206.6,215.8"/>
				<path fill="#00A44F" d="M524.5,168c-0.5,1.6,0.2,2.9,1.6,2.8c1.4-0.1,2.9-1.5,3.4-3.2c0.5-1.6-0.3-2.9-1.6-2.8
			C526.6,165,525,166.4,524.5,168"/>
				<path fill="#00A44F" d="M516.5,168.6c-0.5,1.6,0.2,2.9,1.6,2.8c1.4-0.1,2.9-1.5,3.4-3.2c0.5-1.6-0.2-2.9-1.6-2.8
			C518.6,165.6,517,167,516.5,168.6"/>
				<path fill="#00A44F" d="M428.9,176.4c-0.7,1.7-0.1,2.9,1.2,2.8c1.4-0.1,3.1-1.6,3.7-3.3c0.7-1.7,0.1-2.9-1.2-2.8
			C431.3,173.3,429.6,174.7,428.9,176.4"/>
				<path fill="#00A44F" d="M389.4,180.7c-0.8,1.7-0.3,2.9,1.1,2.7c1.4-0.2,3.1-1.6,3.9-3.3s0.3-2.9-1.1-2.7
			C391.9,177.5,390.2,179,389.4,180.7"/>
				<path fill="#00A44F" d="M381.6,181.6c-0.8,1.7-0.3,2.9,1,2.7c1.4-0.2,3.1-1.6,3.9-3.3c0.8-1.7,0.3-2.9-1-2.7
			C384.1,178.4,382.4,179.9,381.6,181.6"/>
				<path fill="#00A44F" d="M373.7,182.5c-0.8,1.7-0.4,2.9,1,2.7c1.4-0.2,3.1-1.7,3.9-3.3c0.8-1.7,0.3-2.9-1-2.7
			C376.3,179.4,374.5,180.8,373.7,182.5"/>
				<path fill="#00A44F" d="M365.9,183.5c-0.8,1.7-0.4,2.9,1,2.7c1.4-0.2,3.1-1.7,3.9-3.3c0.8-1.7,0.4-2.9-1-2.7
			S366.7,181.8,365.9,183.5"/>
				<path fill="#00A44F" d="M358.1,184.4c-0.8,1.7-0.4,2.9,0.9,2.7c1.4-0.2,3.1-1.7,4-3.4c0.8-1.7,0.4-2.9-0.9-2.7
			C360.7,181.3,358.9,182.8,358.1,184.4"/>
				<path fill="#00A44F" d="M350.3,185.4c-0.9,1.7-0.4,2.9,0.9,2.7c1.4-0.2,3.1-1.7,4-3.4c0.8-1.7,0.4-2.9-0.9-2.7
			S351.2,183.7,350.3,185.4"/>
				<path fill="#00A44F" d="M342.5,186.4c-0.9,1.7-0.5,2.9,0.9,2.7c1.4-0.2,3.1-1.7,4-3.4c0.9-1.7,0.5-2.9-0.9-2.7
			C345.2,183.2,343.4,184.7,342.5,186.4"/>
				<path fill="#00A44F" d="M334.7,187.4c-0.9,1.7-0.5,2.9,0.9,2.7c1.4-0.2,3.2-1.7,4-3.4c0.9-1.7,0.5-2.9-0.9-2.7
			C337.4,184.2,335.6,185.8,334.7,187.4"/>
				<path fill="#00A44F" d="M327,188.5c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.2-1.7,4-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C329.7,185.3,327.9,186.8,327,188.5"/>
				<path fill="#00A44F" d="M319.2,189.5c-0.9,1.7-0.6,2.9,0.8,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C322,186.3,320.1,187.8,319.2,189.5"/>
				<path fill="#00A44F" d="M311.5,190.6c-0.9,1.7-0.6,2.9,0.8,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.8-2.7
			C314.3,187.4,312.4,188.9,311.5,190.6"/>
				<path fill="#00A44F" d="M303.8,191.7c-0.9,1.7-0.6,2.9,0.7,2.7s3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C306.6,188.5,304.7,190,303.8,191.7"/>
				<path fill="#00A44F" d="M296.1,192.8c-0.9,1.7-0.6,2.9,0.7,2.7s3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C298.9,189.6,297.1,191.1,296.1,192.8"/>
				<path fill="#00A44F" d="M288.4,193.9c-1,1.7-0.7,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C291.2,190.7,289.4,192.2,288.4,193.9"/>
				<path fill="#00A44F" d="M280.8,195.1c-1,1.7-0.7,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.2-3.4c1-1.7,0.7-2.9-0.7-2.7
			C283.6,191.8,281.7,193.4,280.8,195.1"/>
				<path fill="#00A44F" d="M273.1,196.2c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.6-2.7
			C276,193,274.1,194.5,273.1,196.2"/>
				<path fill="#00A44F" d="M265.5,197.4c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.6-2.7
			C268.4,194.1,266.5,195.7,265.5,197.4"/>
				<path fill="#00A44F" d="M257.9,198.6c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.7-2.9-0.6-2.7
			C260.8,195.3,258.9,196.9,257.9,198.6"/>
				<path fill="#00A44F" d="M235.2,202.2c-1,1.7-0.8,2.9,0.5,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.8-2.9-0.5-2.7
			C238.1,198.9,236.2,200.5,235.2,202.2"/>
				<path fill="#00A44F" d="M227.6,203.5c-1,1.7-0.8,2.9,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-2.9-0.5-2.7
			C230.6,200.2,228.7,201.7,227.6,203.5"/>
				<path fill="#00A44F" d="M220.1,204.7c-1.1,1.7-0.9,2.9,0.5,2.7c1.3-0.2,3.2-1.8,4.3-3.5c1-1.7,0.8-2.9-0.5-2.7
			C223.1,201.4,221.2,203,220.1,204.7"/>
				<path fill="#00A44F" d="M535.2,158.3c-0.5,1.6,0.3,2.9,1.6,2.8c1.4-0.1,2.9-1.5,3.3-3.1c0.5-1.6-0.3-2.8-1.6-2.8
			C537.2,155.3,535.7,156.6,535.2,158.3"/>
				<path fill="#00A44F" d="M527.3,158.8c-0.5,1.6,0.2,2.9,1.6,2.8c1.4-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.2-2.9-1.6-2.8
			C529.3,155.8,527.8,157.2,527.3,158.8"/>
				<path fill="#00A44F" d="M519.4,159.4c-0.5,1.6,0.2,2.9,1.6,2.8c1.4-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.2-2.9-1.6-2.8
			C521.4,156.4,519.9,157.8,519.4,159.4"/>
				<path fill="#00A44F" d="M456.3,164.7c-0.7,1.6-0.1,2.9,1.3,2.7c1.4-0.1,3-1.6,3.6-3.2c0.6-1.6,0.1-2.9-1.3-2.7
			C458.6,161.7,457,163.1,456.3,164.7"/>
				<path fill="#00A44F" d="M448.5,165.5c-0.7,1.6-0.1,2.9,1.3,2.7c1.4-0.1,3-1.6,3.7-3.2c0.7-1.6,0.1-2.9-1.3-2.7
			C450.8,162.4,449.2,163.8,448.5,165.5"/>
				<path fill="#00A44F" d="M440.7,166.3c-0.7,1.6-0.1,2.9,1.2,2.7c1.4-0.1,3-1.6,3.7-3.2c0.7-1.6,0.1-2.9-1.2-2.7
			C443,163.2,441.3,164.6,440.7,166.3"/>
				<path fill="#00A44F" d="M432.8,167c-0.7,1.6-0.2,2.9,1.2,2.7c1.4-0.1,3-1.6,3.7-3.2c0.7-1.6,0.1-2.9-1.2-2.7
			C435.2,164,433.5,165.4,432.8,167"/>
				<path fill="#00A44F" d="M425,167.9c-0.7,1.6-0.2,2.9,1.2,2.7c1.4-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.9-1.2-2.7
			C427.4,164.8,425.7,166.2,425,167.9"/>
				<path fill="#00A44F" d="M417.2,168.7c-0.7,1.7-0.2,2.9,1.1,2.7c1.4-0.1,3-1.6,3.8-3.3c0.7-1.6,0.2-2.9-1.1-2.7
			C419.6,165.6,417.9,167,417.2,168.7"/>
				<path fill="#00A44F" d="M393.8,171.3c-0.8,1.7-0.3,2.9,1,2.7c1.4-0.2,3.1-1.6,3.8-3.3c0.8-1.7,0.3-2.9-1-2.7
			C396.3,168.1,394.6,169.6,393.8,171.3"/>
				<path fill="#00A44F" d="M386.1,172.2c-0.8,1.7-0.3,2.9,1,2.7c1.4-0.2,3.1-1.6,3.9-3.3c0.8-1.7,0.3-2.9-1-2.7
			C388.6,169,386.9,170.5,386.1,172.2"/>
				<path fill="#00A44F" d="M378.3,173.1c-0.8,1.7-0.4,2.9,1,2.7c1.4-0.2,3.1-1.6,3.9-3.3c0.8-1.7,0.4-2.9-1-2.7
			C380.9,169.9,379.1,171.4,378.3,173.1"/>
				<path fill="#00A44F" d="M370.6,174c-0.8,1.7-0.4,2.9,0.9,2.7c1.3-0.2,3.1-1.7,3.9-3.3c0.8-1.7,0.4-2.9-0.9-2.7
			C373.1,170.9,371.4,172.3,370.6,174"/>
				<path fill="#00A44F" d="M362.8,175c-0.8,1.7-0.4,2.9,0.9,2.7c1.3-0.2,3.1-1.7,3.9-3.3c0.8-1.7,0.4-2.9-0.9-2.7
			C365.4,171.8,363.7,173.3,362.8,175"/>
				<path fill="#00A44F" d="M355.1,175.9c-0.9,1.7-0.5,2.9,0.9,2.7c1.3-0.2,3.1-1.7,4-3.3c0.8-1.7,0.4-2.9-0.9-2.7
			C357.7,172.8,356,174.3,355.1,175.9"/>
				<path fill="#00A44F" d="M347.4,176.9c-0.9,1.7-0.5,2.9,0.9,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.5-2.9-0.9-2.7
			C350.1,173.8,348.3,175.3,347.4,176.9"/>
				<path fill="#00A44F" d="M339.7,177.9c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C342.4,174.8,340.6,176.3,339.7,177.9"/>
				<path fill="#00A44F" d="M332,179c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.4c0.9-1.7,0.5-2.9-0.8-2.7
			C334.7,175.8,332.9,177.3,332,179"/>
				<path fill="#00A44F" d="M324.4,180c-0.9,1.7-0.6,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.4c0.9-1.7,0.6-2.9-0.8-2.7
			S325.3,178.3,324.4,180"/>
				<path fill="#00A44F" d="M316.7,181.1c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C319.5,177.9,317.6,179.4,316.7,181.1"/>
				<path fill="#00A44F" d="M309.1,182.1c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C311.8,178.9,310,180.4,309.1,182.1"/>
				<path fill="#00A44F" d="M301.5,183.2c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C304.2,180,302.4,181.5,301.5,183.2"/>
				<path fill="#00A44F" d="M293.8,184.3c-1,1.7-0.7,2.9,0.7,2.7c1.3-0.2,3.2-1.7,4.1-3.4c1-1.7,0.7-2.9-0.7-2.7
			C296.6,181.1,294.8,182.6,293.8,184.3"/>
				<path fill="#00A44F" d="M286.2,185.4c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C289.1,182.2,287.2,183.7,286.2,185.4"/>
				<path fill="#00A44F" d="M278.7,186.6c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.7,4.2-3.4c1-1.7,0.7-2.9-0.6-2.7
			C281.5,183.4,279.6,184.9,278.7,186.6"/>
				<path fill="#00A44F" d="M271.1,187.7c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.7,4.2-3.4c1-1.7,0.7-2.9-0.6-2.7
			C274,184.5,272.1,186,271.1,187.7"/>
				<path fill="#00A44F" d="M248.5,191.3c-1,1.7-0.8,2.9,0.5,2.7s3.2-1.8,4.2-3.5c1-1.7,0.8-2.9-0.5-2.7S249.5,189.6,248.5,191.3" />
				<path fill="#00A44F" d="M241,192.5c-1,1.7-0.8,2.9,0.5,2.7c1.3-0.2,3.2-1.8,4.2-3.5c1-1.7,0.8-2.9-0.5-2.7
			C243.9,189.3,242.1,190.8,241,192.5"/>
				<path fill="#00A44F" d="M545.7,148.6c-0.5,1.6,0.3,2.8,1.6,2.7c1.4-0.1,2.8-1.5,3.3-3.1c0.4-1.6-0.3-2.8-1.6-2.7
			C547.6,145.7,546.2,147,545.7,148.6"/>
				<path fill="#00A44F" d="M537.9,149.2c-0.5,1.6,0.3,2.8,1.6,2.7c1.4-0.1,2.8-1.5,3.3-3.1c0.5-1.6-0.3-2.8-1.6-2.7
			C539.9,146.2,538.4,147.6,537.9,149.2"/>
				<path fill="#00A44F" d="M530.1,149.7c-0.5,1.6,0.2,2.8,1.6,2.7c1.4-0.1,2.9-1.5,3.3-3.1c0.5-1.6-0.2-2.8-1.6-2.7
			C532.1,146.7,530.6,148.1,530.1,149.7"/>
				<path fill="#00A44F" d="M522.3,150.3c-0.5,1.6,0.2,2.8,1.5,2.7c1.4-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.2-2.8-1.5-2.7
			C524.3,147.3,522.8,148.7,522.3,150.3"/>
				<path fill="#00A44F" d="M514.5,150.9c-0.5,1.6,0.1,2.8,1.5,2.7c1.4-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.2-2.8-1.5-2.7
			C516.5,147.9,515,149.3,514.5,150.9"/>
				<path fill="#00A44F" d="M506.7,151.5c-0.5,1.6,0.1,2.8,1.5,2.7c1.4-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.1-2.8-1.5-2.7
			S507.2,149.9,506.7,151.5"/>
				<path fill="#00A44F" d="M460,155.5c-0.6,1.6-0.1,2.8,1.3,2.7c1.4-0.1,3-1.5,3.6-3.2c0.6-1.6,0.1-2.8-1.3-2.7
			C462.3,152.5,460.7,153.9,460,155.5"/>
				<path fill="#00A44F" d="M452.3,156.2c-0.7,1.6-0.1,2.8,1.2,2.7c1.4-0.1,3-1.6,3.6-3.2c0.7-1.6,0.1-2.8-1.2-2.7
			C454.5,153.2,452.9,154.6,452.3,156.2"/>
				<path fill="#00A44F" d="M444.5,157c-0.7,1.6-0.1,2.8,1.2,2.7s3-1.6,3.7-3.2s0.1-2.8-1.2-2.7S445.2,155.4,444.5,157" />
				<path fill="#00A44F" d="M436.8,157.8c-0.7,1.6-0.2,2.8,1.2,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.2-2.7
			C439.1,154.7,437.5,156.1,436.8,157.8"/>
				<path fill="#00A44F" d="M429,158.6c-0.7,1.6-0.2,2.8,1.1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.1-2.7
			S429.8,156.9,429,158.6"/>
				<path fill="#00A44F" d="M421.3,159.4c-0.7,1.6-0.2,2.9,1.1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.1-2.7
			C423.7,156.3,422.1,157.7,421.3,159.4"/>
				<path fill="#00A44F" d="M405.9,161.1c-0.8,1.6-0.3,2.9,1,2.7c1.3-0.1,3-1.6,3.8-3.2c0.8-1.6,0.3-2.8-1-2.7
			C408.4,158,406.7,159.4,405.9,161.1"/>
				<path fill="#00A44F" d="M398.2,161.9c-0.8,1.6-0.3,2.9,1,2.7c1.3-0.2,3-1.6,3.8-3.3c0.8-1.6,0.3-2.8-1-2.7
			C400.7,158.8,399,160.3,398.2,161.9"/>
				<path fill="#00A44F" d="M382.9,163.7c-0.8,1.7-0.4,2.9,1,2.7c1.3-0.2,3.1-1.6,3.9-3.3c0.8-1.6,0.4-2.9-1-2.7
			C385.4,160.6,383.7,162.1,382.9,163.7"/>
				<path fill="#00A44F" d="M375.2,164.6c-0.8,1.7-0.4,2.9,0.9,2.7c1.3-0.2,3.1-1.6,3.9-3.3c0.8-1.7,0.4-2.9-0.9-2.7
			C377.8,161.5,376,163,375.2,164.6"/>
				<path fill="#00A44F" d="M359.9,166.5c-0.9,1.7-0.5,2.9,0.9,2.7c1.3-0.2,3.1-1.6,3.9-3.3c0.8-1.7,0.5-2.9-0.9-2.7
			S360.8,164.9,359.9,166.5"/>
				<path fill="#00A44F" d="M352.3,167.5c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.5-2.9-0.8-2.7
			C354.9,164.4,353.2,165.8,352.3,167.5"/>
				<path fill="#00A44F" d="M344.7,168.5c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.5-2.9-0.8-2.7
			C347.3,165.3,345.6,166.8,344.7,168.5"/>
				<path fill="#00A44F" d="M337.1,169.5c-0.9,1.7-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.5-2.9-0.8-2.7
			C339.8,166.3,338,167.8,337.1,169.5"/>
				<path fill="#00A44F" d="M329.5,170.5c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.6-2.9-0.8-2.7
			C332.2,167.4,330.4,168.8,329.5,170.5"/>
				<path fill="#00A44F" d="M314.3,172.6c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.1-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C317.1,169.4,315.3,170.9,314.3,172.6"/>
				<path fill="#00A44F" d="M306.8,173.7c-0.9,1.7-0.6,2.9,0.7,2.7c1.3-0.2,3.1-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.7-2.7
			C309.6,170.5,307.7,172,306.8,173.7"/>
				<path fill="#00A44F" d="M291.7,175.9c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C294.5,172.7,292.7,174.2,291.7,175.9"/>
				<path fill="#00A44F" d="M284.2,177c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.2-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C287,173.8,285.2,175.3,284.2,177"/>
				<path fill="#00A44F" d="M261.8,180.5c-1,1.7-0.8,2.9,0.5,2.7c1.3-0.2,3.2-1.7,4.2-3.4c1-1.7,0.8-2.9-0.5-2.7
			C264.6,177.2,262.8,178.8,261.8,180.5"/>
				<path fill="#00A44F" d="M563.7,138.7c-0.4,1.6,0.3,2.8,1.7,2.7c1.3-0.1,2.8-1.4,3.2-3c0.4-1.6-0.4-2.8-1.7-2.7
			S564.2,137.1,563.7,138.7"/>
				<path fill="#00A44F" d="M556,139.1c-0.4,1.6,0.3,2.8,1.7,2.7c1.3-0.1,2.8-1.4,3.2-3c0.4-1.6-0.3-2.8-1.7-2.7
			C557.9,136.2,556.4,137.6,556,139.1"/>
				<path fill="#00A44F" d="M548.3,139.6c-0.5,1.6,0.3,2.8,1.6,2.7s2.8-1.4,3.2-3c0.4-1.6-0.3-2.8-1.6-2.7
			C550.2,136.7,548.7,138.1,548.3,139.6"/>
				<path fill="#00A44F" d="M540.6,140.2c-0.5,1.6,0.2,2.8,1.6,2.7c1.3-0.1,2.8-1.5,3.3-3c0.5-1.6-0.3-2.8-1.6-2.7
			S541,138.6,540.6,140.2"/>
				<path fill="#00A44F" d="M532.9,140.7c-0.5,1.6,0.2,2.8,1.5,2.7c1.3-0.1,2.8-1.5,3.3-3c0.5-1.6-0.2-2.8-1.5-2.7
			C534.8,137.8,533.3,139.1,532.9,140.7"/>
				<path fill="#00A44F" d="M525.1,141.3c-0.5,1.6,0.2,2.8,1.5,2.7c1.3-0.1,2.8-1.5,3.3-3.1c0.5-1.6-0.2-2.8-1.5-2.7
			C527.1,138.3,525.7,139.7,525.1,141.3"/>
				<path fill="#00A44F" d="M517.4,141.8c-0.5,1.6,0.1,2.8,1.5,2.7c1.3-0.1,2.8-1.5,3.4-3.1c0.5-1.6-0.1-2.8-1.5-2.7
			C519.5,138.9,518,140.2,517.4,141.8"/>
				<path fill="#00A44F" d="M509.7,142.4c-0.5,1.6,0.1,2.8,1.4,2.7c1.3-0.1,2.9-1.5,3.4-3.1c0.5-1.6-0.1-2.8-1.4-2.7
			C511.8,139.5,510.3,140.8,509.7,142.4"/>
				<path fill="#00A44F" d="M456,147.1c-0.7,1.6-0.1,2.8,1.2,2.7c1.3-0.1,2.9-1.5,3.6-3.1c0.7-1.6,0.1-2.8-1.2-2.7
			C458.3,144.1,456.7,145.5,456,147.1"/>
				<path fill="#00A44F" d="M448.4,147.8c-0.7,1.6-0.2,2.8,1.2,2.7c1.3-0.1,3-1.5,3.6-3.2s0.1-2.8-1.2-2.7
			C450.7,144.8,449,146.2,448.4,147.8"/>
				<path fill="#00A44F" d="M440.7,148.6c-0.7,1.6-0.2,2.8,1.1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.1-2.7
			C443.1,145.6,441.4,147,440.7,148.6"/>
				<path fill="#00A44F" d="M433.1,149.4c-0.7,1.6-0.2,2.8,1.1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.1-2.7
			C435.4,146.4,433.8,147.8,433.1,149.4"/>
				<path fill="#00A44F" d="M425.5,150.2c-0.7,1.6-0.2,2.8,1.1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.2-2.8-1.1-2.7
			C427.8,147.1,426.2,148.6,425.5,150.2"/>
				<path fill="#00A44F" d="M410.2,151.8c-0.8,1.6-0.3,2.8,1,2.7c1.3-0.1,3-1.6,3.8-3.2c0.7-1.6,0.3-2.8-1-2.7
			C412.7,148.8,411,150.2,410.2,151.8"/>
				<path fill="#00A44F" d="M402.6,152.7c-0.8,1.6-0.3,2.8,1,2.7c1.3-0.1,3-1.6,3.8-3.2c0.8-1.6,0.3-2.8-1-2.7
			C405.1,149.6,403.4,151,402.6,152.7"/>
				<path fill="#00A44F" d="M395,153.5c-0.8,1.6-0.4,2.8,1,2.7c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.3-2.8-1-2.7
			C397.5,150.5,395.8,151.9,395,153.5"/>
				<path fill="#00A44F" d="M379.9,155.3c-0.8,1.6-0.4,2.8,0.9,2.7c1.3-0.2,3-1.6,3.9-3.3c0.8-1.6,0.4-2.8-0.9-2.7
			C382.4,152.2,380.7,153.7,379.9,155.3"/>
				<path fill="#00A44F" d="M357.2,158.1c-0.9,1.6-0.5,2.9,0.8,2.7c1.3-0.2,3.1-1.6,3.9-3.3c0.9-1.6,0.5-2.8-0.8-2.7
			C359.8,155,358.1,156.5,357.2,158.1"/>
				<path fill="#00A44F" d="M342.1,160.1c-0.9,1.7-0.6,2.9,0.8,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.7,0.5-2.8-0.8-2.7
			C344.8,157,343,158.5,342.1,160.1"/>
				<path fill="#00A44F" d="M312.1,164.2c-0.9,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.1-1.7,4.1-3.4c0.9-1.7,0.6-2.9-0.6-2.7
			C314.9,161.1,313.1,162.6,312.1,164.2"/>
				<path fill="#00A44F" d="M304.7,165.3c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.1-1.7,4.1-3.4c0.9-1.7,0.7-2.9-0.6-2.7
			C307.4,162.1,305.6,163.6,304.7,165.3"/>
				<path fill="#00A44F" d="M297.2,166.4c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.1-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C300,163.2,298.2,164.7,297.2,166.4"/>
				<path fill="#00A44F" d="M289.8,167.5c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.1-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C292.6,164.3,290.7,165.8,289.8,167.5"/>
				<path fill="#00A44F" d="M596.6,128.1c-0.3,1.5,0.5,2.8,1.8,2.7c1.3-0.1,2.7-1.4,3-2.9c0.3-1.5-0.5-2.7-1.8-2.7
			C598.3,125.3,596.9,126.6,596.6,128.1"/>
				<path fill="#00A44F" d="M589,128.5c-0.4,1.5,0.4,2.8,1.8,2.7c1.3-0.1,2.7-1.4,3-2.9c0.3-1.5-0.4-2.7-1.8-2.7
			C590.7,125.7,589.3,127,589,128.5"/>
				<path fill="#00A44F" d="M581.3,128.9c-0.4,1.6,0.4,2.8,1.7,2.7c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.4-2.7-1.7-2.7
			C583.1,126.1,581.7,127.4,581.3,128.9"/>
				<path fill="#00A44F" d="M573.7,129.4c-0.4,1.6,0.4,2.8,1.7,2.7c1.3-0.1,2.7-1.4,3.1-3c0.4-1.6-0.4-2.7-1.7-2.7
			C575.5,126.5,574.1,127.8,573.7,129.4"/>
				<path fill="#00A44F" d="M566.1,129.8c-0.4,1.6,0.3,2.8,1.7,2.7c1.3-0.1,2.7-1.4,3.1-3c0.4-1.6-0.3-2.7-1.7-2.7
			C567.9,126.9,566.5,128.3,566.1,129.8"/>
				<path fill="#00A44F" d="M558.5,130.3c-0.4,1.6,0.3,2.8,1.6,2.7c1.3-0.1,2.7-1.4,3.2-3c0.4-1.6-0.3-2.7-1.6-2.7
			C560.3,127.4,558.9,128.7,558.5,130.3"/>
				<path fill="#00A44F" d="M550.8,130.8c-0.5,1.6,0.3,2.8,1.6,2.7c1.3-0.1,2.8-1.4,3.2-3c0.4-1.6-0.3-2.7-1.6-2.7
			C552.7,127.9,551.3,129.2,550.8,130.8"/>
				<path fill="#00A44F" d="M543.2,131.3c-0.5,1.6,0.2,2.8,1.6,2.7c1.3-0.1,2.8-1.4,3.2-3c0.5-1.6-0.2-2.7-1.6-2.7
			C545.1,128.4,543.7,129.7,543.2,131.3"/>
				<path fill="#00A44F" d="M535.6,131.8c-0.5,1.6,0.2,2.8,1.5,2.7c1.3-0.1,2.8-1.4,3.3-3c0.5-1.6-0.2-2.7-1.5-2.7
			C537.6,128.9,536.1,130.2,535.6,131.8"/>
				<path fill="#00A44F" d="M528,132.3c-0.5,1.6,0.2,2.8,1.5,2.7c1.3-0.1,2.8-1.4,3.3-3c0.5-1.6-0.2-2.8-1.5-2.7
			C530,129.4,528.5,130.8,528,132.3"/>
				<path fill="#00A44F" d="M520.4,132.9c-0.5,1.6,0.1,2.8,1.4,2.7c1.3-0.1,2.8-1.5,3.3-3c0.5-1.6-0.1-2.8-1.4-2.7
			C522.4,130,520.9,131.3,520.4,132.9"/>
				<path fill="#00A44F" d="M505.2,134.1c-0.6,1.6,0.1,2.8,1.4,2.7c1.3-0.1,2.8-1.5,3.4-3c0.5-1.6-0.1-2.8-1.4-2.7
			S505.8,132.5,505.2,134.1"/>
				<path fill="#00A44F" d="M422,141.9c-0.7,1.6-0.3,2.8,1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.3-2.8-1-2.7
			C424.4,138.9,422.8,140.3,422,141.9"/>
				<path fill="#00A44F" d="M414.5,142.7c-0.8,1.6-0.3,2.8,1,2.7c1.3-0.1,3-1.6,3.7-3.2c0.7-1.6,0.3-2.8-1-2.7
			C416.9,139.7,415.3,141.1,414.5,142.7"/>
				<path fill="#00A44F" d="M407,143.5c-0.8,1.6-0.3,2.8,1,2.7c1.3-0.1,3-1.6,3.8-3.2c0.8-1.6,0.3-2.8-1-2.7
			C409.5,140.5,407.8,141.9,407,143.5"/>
				<path fill="#00A44F" d="M399.5,144.4c-0.8,1.6-0.4,2.8,0.9,2.7c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.4-2.8-0.9-2.7
			C402,141.3,400.3,142.8,399.5,144.4"/>
				<path fill="#00A44F" d="M392,145.2c-0.8,1.6-0.4,2.8,0.9,2.7c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.4-2.8-0.9-2.7
			C394.5,142.2,392.8,143.6,392,145.2"/>
				<path fill="#00A44F" d="M377,147c-0.8,1.6-0.5,2.8,0.8,2.7c1.3-0.2,3-1.6,3.9-3.2c0.8-1.6,0.4-2.8-0.8-2.7
			C379.6,144,377.9,145.4,377,147"/>
				<path fill="#00A44F" d="M362.1,148.9c-0.9,1.6-0.5,2.8,0.8,2.7c1.3-0.2,3-1.6,3.9-3.3c0.9-1.6,0.5-2.8-0.8-2.7
			C364.7,145.8,362.9,147.2,362.1,148.9"/>
				<path fill="#00A44F" d="M354.6,149.8c-0.9,1.6-0.5,2.8,0.8,2.7c1.3-0.2,3.1-1.6,3.9-3.3c0.9-1.6,0.5-2.8-0.8-2.7
			C357.2,146.7,355.5,148.2,354.6,149.8"/>
				<path fill="#00A44F" d="M324.9,153.8c-0.9,1.7-0.6,2.8,0.7,2.7c1.3-0.2,3.1-1.7,4-3.3c0.9-1.6,0.6-2.8-0.7-2.7
			C327.6,150.7,325.8,152.2,324.9,153.8"/>
				<path fill="#00A44F" d="M302.7,157c-1,1.7-0.7,2.9,0.6,2.7c1.3-0.2,3.1-1.7,4.1-3.3c1-1.7,0.7-2.9-0.6-2.7
			C305.5,153.8,303.6,155.3,302.7,157"/>
				<path fill="#00A44F" d="M295.3,158c-1,1.7-0.7,2.9,0.6,2.7s3.1-1.7,4.1-3.4c1-1.7,0.7-2.9-0.6-2.7
			C298.1,154.9,296.3,156.4,295.3,158"/>
				<path fill="#00A44F" d="M613.6,118.7c-0.3,1.5,0.5,2.7,1.8,2.7c1.3-0.1,2.6-1.3,2.9-2.9c0.3-1.5-0.5-2.7-1.8-2.6
			C615.1,115.9,613.9,117.2,613.6,118.7"/>
				<path fill="#00A44F" d="M606,119.1c-0.3,1.5,0.5,2.7,1.8,2.7c1.3-0.1,2.6-1.4,2.9-2.9c0.3-1.5-0.5-2.7-1.8-2.6
			C607.7,116.3,606.3,117.5,606,119.1"/>
				<path fill="#00A44F" d="M598.5,119.4c-0.3,1.5,0.4,2.7,1.8,2.6c1.3-0.1,2.6-1.4,3-2.9c0.3-1.5-0.5-2.7-1.8-2.6
			C600.2,116.6,598.8,117.9,598.5,119.4"/>
				<path fill="#00A44F" d="M591,119.8c-0.4,1.5,0.4,2.7,1.7,2.6c1.3-0.1,2.7-1.4,3-2.9c0.3-1.5-0.4-2.7-1.7-2.6
			C592.7,117,591.3,118.3,591,119.8"/>
				<path fill="#00A44F" d="M583.5,120.2c-0.4,1.5,0.4,2.7,1.7,2.6c1.3-0.1,2.7-1.4,3-2.9c0.4-1.5-0.4-2.7-1.7-2.6
			C585.2,117.4,583.8,118.7,583.5,120.2"/>
				<path fill="#00A44F" d="M575.9,120.6c-0.4,1.5,0.3,2.7,1.7,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.4-2.7-1.7-2.6
			C577.7,117.8,576.3,119.1,575.9,120.6"/>
				<path fill="#00A44F" d="M568.4,121.1c-0.4,1.5,0.3,2.7,1.6,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			S568.8,119.6,568.4,121.1"/>
				<path fill="#00A44F" d="M560.9,121.5c-0.4,1.5,0.3,2.7,1.6,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			C562.7,118.7,561.3,120,560.9,121.5"/>
				<path fill="#00A44F" d="M553.4,122c-0.5,1.5,0.2,2.7,1.6,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			C555.3,119.2,553.8,120.5,553.4,122"/>
				<path fill="#00A44F" d="M545.9,122.5c-0.5,1.5,0.2,2.7,1.5,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.2-2.7-1.5-2.6
			C547.8,119.7,546.3,121,545.9,122.5"/>
				<path fill="#00A44F" d="M538.4,123c-0.5,1.5,0.2,2.7,1.5,2.6c1.3-0.1,2.8-1.4,3.2-3s-0.2-2.7-1.5-2.6
			C540.3,120.2,538.9,121.5,538.4,123"/>
				<path fill="#00A44F" d="M530.9,123.6c-0.5,1.5,0.1,2.7,1.5,2.6c1.3-0.1,2.8-1.4,3.3-3c0.5-1.5-0.2-2.7-1.5-2.6
			C532.8,120.7,531.4,122,530.9,123.6"/>
				<path fill="#00A44F" d="M523.4,124.1c-0.5,1.5,0.1,2.7,1.4,2.6c1.3-0.1,2.8-1.4,3.3-3c0.5-1.5-0.1-2.7-1.4-2.6
			C525.4,121.2,523.9,122.6,523.4,124.1"/>
				<path fill="#00A44F" d="M515.9,124.7c-0.5,1.6,0.1,2.7,1.4,2.6c1.3-0.1,2.8-1.4,3.3-3c0.5-1.5-0.1-2.7-1.4-2.6
			C517.9,121.8,516.4,123.1,515.9,124.7"/>
				<path fill="#00A44F" d="M508.4,125.3c-0.6,1.6,0,2.7,1.4,2.6c1.3-0.1,2.8-1.4,3.4-3c0.5-1.6-0.1-2.7-1.4-2.6
			C510.4,122.4,508.9,123.7,508.4,125.3"/>
				<path fill="#00A44F" d="M500.9,125.9c-0.6,1.6,0,2.7,1.3,2.6c1.3-0.1,2.8-1.5,3.4-3c0.6-1.6,0-2.7-1.3-2.6
			C503,123,501.5,124.3,500.9,125.9"/>
				<path fill="#00A44F" d="M411.4,134.5c-0.8,1.6-0.4,2.8,0.9,2.6c1.3-0.1,3-1.6,3.7-3.1c0.8-1.6,0.3-2.8-0.9-2.6
			C413.8,131.5,412.2,132.9,411.4,134.5"/>
				<path fill="#00A44F" d="M381.7,137.9c-0.8,1.6-0.5,2.8,0.8,2.6c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.5-2.8-0.8-2.6
			C384.3,134.8,382.6,136.3,381.7,137.9"/>
				<path fill="#00A44F" d="M366.9,139.7c-0.9,1.6-0.5,2.8,0.8,2.6c1.3-0.2,3-1.6,3.9-3.2c0.9-1.6,0.5-2.8-0.8-2.6
			C369.5,136.6,367.8,138.1,366.9,139.7"/>
				<path fill="#00A44F" d="M352.2,141.6c-0.9,1.6-0.6,2.8,0.7,2.6c1.3-0.2,3-1.6,3.9-3.2c0.9-1.6,0.6-2.8-0.7-2.6
			C354.8,138.5,353.1,139.9,352.2,141.6"/>
				<path fill="#00A44F" d="M330.1,144.5c-0.9,1.6-0.6,2.8,0.6,2.7c1.3-0.2,3.1-1.6,4-3.3c0.9-1.6,0.6-2.8-0.6-2.6
			C332.8,141.4,331.1,142.9,330.1,144.5"/>
				<path fill="#00A44F" d="M630.1,109.6c-0.3,1.5,0.6,2.7,1.9,2.6c1.3,0,2.5-1.3,2.8-2.8c0.2-1.5-0.6-2.7-1.9-2.6
			C631.6,106.8,630.3,108.1,630.1,109.6"/>
				<path fill="#00A44F" d="M622.7,109.9c-0.3,1.5,0.5,2.7,1.8,2.6c1.3-0.1,2.6-1.3,2.8-2.8c0.3-1.5-0.6-2.7-1.8-2.6
			C624.2,107.1,622.9,108.4,622.7,109.9"/>
				<path fill="#00A44F" d="M615.2,110.2c-0.3,1.5,0.5,2.7,1.8,2.6c1.3-0.1,2.6-1.3,2.9-2.8c0.3-1.5-0.5-2.7-1.8-2.6
			C616.8,107.4,615.5,108.7,615.2,110.2"/>
				<path fill="#00A44F" d="M607.8,110.5c-0.3,1.5,0.5,2.7,1.8,2.6c1.3-0.1,2.6-1.3,2.9-2.8c0.3-1.5-0.5-2.7-1.8-2.6
			C609.4,107.8,608.1,109,607.8,110.5"/>
				<path fill="#00A44F" d="M600.4,110.9c-0.3,1.5,0.4,2.7,1.7,2.6s2.6-1.3,2.9-2.8c0.3-1.5-0.5-2.7-1.7-2.6
			C602.1,108.1,600.7,109.4,600.4,110.9"/>
				<path fill="#00A44F" d="M593,111.3c-0.4,1.5,0.4,2.7,1.7,2.6c1.3-0.1,2.6-1.3,3-2.8c0.3-1.5-0.4-2.7-1.7-2.6
			C594.7,108.5,593.3,109.8,593,111.3"/>
				<path fill="#00A44F" d="M585.6,111.6c-0.4,1.5,0.4,2.7,1.7,2.6c1.3-0.1,2.6-1.4,3-2.9c0.4-1.5-0.4-2.7-1.7-2.6
			C587.3,108.9,585.9,110.1,585.6,111.6"/>
				<path fill="#00A44F" d="M578.2,112.1c-0.4,1.5,0.3,2.7,1.6,2.6c1.3-0.1,2.6-1.4,3-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			C579.9,109.3,578.5,110.5,578.2,112.1"/>
				<path fill="#00A44F" d="M570.7,112.5c-0.4,1.5,0.3,2.7,1.6,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			C572.5,109.7,571.2,111,570.7,112.5"/>
				<path fill="#00A44F" d="M563.3,112.9c-0.4,1.5,0.3,2.7,1.6,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.3-2.7-1.6-2.6
			C565.1,110.1,563.8,111.4,563.3,112.9"/>
				<path fill="#00A44F" d="M555.9,113.4c-0.5,1.5,0.2,2.7,1.5,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.4-1.5-0.2-2.7-1.5-2.6
			C557.8,110.6,556.4,111.9,555.9,113.4"/>
				<path fill="#00A44F" d="M548.5,113.9c-0.5,1.5,0.2,2.7,1.5,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.2-2.7-1.5-2.6
			C550.4,111.1,549,112.4,548.5,113.9"/>
				<path fill="#00A44F" d="M541.1,114.4c-0.5,1.5,0.2,2.7,1.5,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.2-2.7-1.5-2.6
			C543,111.6,541.6,112.9,541.1,114.4"/>
				<path fill="#00A44F" d="M533.7,114.9c-0.5,1.5,0.1,2.7,1.4,2.6c1.3-0.1,2.7-1.4,3.2-2.9s-0.1-2.7-1.4-2.6
			C535.7,112.1,534.2,113.4,533.7,114.9"/>
				<path fill="#00A44F" d="M526.3,115.4c-0.5,1.5,0.1,2.7,1.4,2.6c1.3-0.1,2.7-1.4,3.3-2.9c0.5-1.5-0.1-2.7-1.4-2.6
			C528.3,112.6,526.8,113.9,526.3,115.4"/>
				<path fill="#00A44F" d="M518.9,116c-0.5,1.5,0.1,2.7,1.4,2.6c1.3-0.1,2.8-1.4,3.3-2.9c0.5-1.5-0.1-2.7-1.4-2.6
			C520.9,113.1,519.5,114.4,518.9,116"/>
				<path fill="#00A44F" d="M511.5,116.5c-0.6,1.5,0,2.7,1.3,2.6c1.3-0.1,2.8-1.4,3.3-3c0.5-1.5-0.1-2.7-1.3-2.6
			C513.6,113.7,512.1,115,511.5,116.5"/>
				<path fill="#00A44F" d="M504.1,117.1c-0.6,1.5,0,2.7,1.3,2.6c1.3-0.1,2.8-1.4,3.3-3c0.6-1.5,0-2.7-1.3-2.6
			C506.2,114.3,504.7,115.6,504.1,117.1"/>
				<path fill="#00A44F" d="M496.7,117.7c-0.6,1.5,0,2.7,1.3,2.6c1.3-0.1,2.8-1.4,3.4-3c0.6-1.5,0-2.7-1.3-2.6
			C498.8,114.9,497.3,116.2,496.7,117.7"/>
				<path fill="#00A44F" d="M489.4,118.3c-0.6,1.5-0.1,2.7,1.2,2.6s2.8-1.5,3.4-3c0.6-1.5,0-2.7-1.2-2.6S490,116.8,489.4,118.3" />
				<path fill="#00A44F" d="M482,119c-0.6,1.5-0.1,2.7,1.2,2.6c1.3-0.1,2.8-1.5,3.4-3c0.6-1.5,0.1-2.7-1.2-2.6
			C484.1,116.1,482.6,117.4,482,119"/>
				<path fill="#00A44F" d="M474.6,119.6c-0.6,1.5-0.1,2.7,1.2,2.6c1.3-0.1,2.8-1.5,3.5-3c0.6-1.5,0.1-2.7-1.2-2.6
			C476.8,116.7,475.2,118.1,474.6,119.6"/>
				<path fill="#00A44F" d="M467.2,120.3c-0.7,1.6-0.2,2.7,1.1,2.6c1.3-0.1,2.9-1.5,3.5-3c0.6-1.5,0.1-2.7-1.1-2.6
			C469.4,117.4,467.9,118.8,467.2,120.3"/>
				<path fill="#00A44F" d="M459.9,121c-0.7,1.6-0.2,2.7,1.1,2.6c1.3-0.1,2.9-1.5,3.5-3c0.7-1.6,0.2-2.7-1.1-2.6
			C462.1,118.1,460.5,119.4,459.9,121"/>
				<path fill="#00A44F" d="M437.8,123.2c-0.7,1.6-0.3,2.7,1,2.6c1.3-0.1,2.9-1.5,3.6-3.1c0.7-1.6,0.3-2.7-1-2.6
			C440.1,120.2,438.5,121.6,437.8,123.2"/>
				<path fill="#00A44F" d="M430.4,123.9c-0.7,1.6-0.3,2.7,1,2.6c1.3-0.1,2.9-1.5,3.6-3.1c0.7-1.6,0.3-2.7-1-2.6
			C432.8,121,431.2,122.4,430.4,123.9"/>
				<path fill="#00A44F" d="M423.1,124.7c-0.8,1.6-0.3,2.7,0.9,2.6c1.3-0.1,2.9-1.5,3.7-3.1c0.7-1.6,0.3-2.7-1-2.6
			C425.5,121.7,423.8,123.1,423.1,124.7"/>
				<path fill="#00A44F" d="M415.7,125.5c-0.8,1.6-0.4,2.7,0.9,2.6c1.3-0.1,2.9-1.5,3.7-3.1c0.8-1.6,0.3-2.7-0.9-2.6
			C418.2,122.5,416.5,123.9,415.7,125.5"/>
				<path fill="#00A44F" d="M408.4,126.3c-0.8,1.6-0.4,2.8,0.9,2.6c1.3-0.1,2.9-1.5,3.7-3.1c0.8-1.6,0.4-2.7-0.9-2.6
			C410.8,123.3,409.2,124.7,408.4,126.3"/>
				<path fill="#00A44F" d="M401.1,127.1c-0.8,1.6-0.4,2.8,0.9,2.6c1.3-0.1,3-1.5,3.7-3.1c0.8-1.6,0.4-2.7-0.9-2.6
			C403.5,124.1,401.9,125.5,401.1,127.1"/>
				<path fill="#00A44F" d="M393.7,128c-0.8,1.6-0.4,2.8,0.8,2.6s3-1.6,3.8-3.1c0.8-1.6,0.4-2.8-0.8-2.6
			C396.2,125,394.6,126.4,393.7,128"/>
				<path fill="#00A44F" d="M386.4,128.8c-0.8,1.6-0.5,2.8,0.8,2.6c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.5-2.8-0.8-2.6
			C389,125.8,387.3,127.2,386.4,128.8"/>
				<path fill="#00A44F" d="M379.1,129.7c-0.8,1.6-0.5,2.8,0.8,2.6c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.5-2.8-0.8-2.6
			C381.7,126.7,380,128.1,379.1,129.7"/>
				<path fill="#00A44F" d="M371.8,130.6c-0.9,1.6-0.5,2.8,0.7,2.6c1.3-0.2,3-1.6,3.8-3.2c0.8-1.6,0.5-2.8-0.8-2.6
			C374.4,127.6,372.7,129,371.8,130.6"/>
				<path fill="#00A44F" d="M364.5,131.5c-0.9,1.6-0.6,2.8,0.7,2.6c1.3-0.2,3-1.6,3.9-3.2c0.9-1.6,0.5-2.8-0.7-2.6
			C367.1,128.5,365.4,129.9,364.5,131.5"/>
				<path fill="#00A44F" d="M638.9,100.9c-0.2,1.5,0.6,2.6,1.9,2.6c1.3,0,2.5-1.3,2.7-2.7c0.2-1.5-0.6-2.6-1.9-2.6
			C640.3,98.2,639.1,99.4,638.9,100.9"/>
				<path fill="#00A44F" d="M624.2,101.5c-0.3,1.5,0.5,2.6,1.8,2.6c1.3-0.1,2.5-1.3,2.8-2.8c0.3-1.5-0.5-2.6-1.8-2.6
			C625.8,98.8,624.5,100,624.2,101.5"/>
				<path fill="#00A44F" d="M616.9,101.8c-0.3,1.5,0.5,2.6,1.8,2.6c1.3-0.1,2.5-1.3,2.8-2.8c0.3-1.5-0.5-2.6-1.8-2.6
			C618.5,99.1,617.2,100.3,616.9,101.8"/>
				<path fill="#00A44F" d="M609.6,102.1c-0.3,1.5,0.5,2.6,1.7,2.6c1.3-0.1,2.6-1.3,2.9-2.8c0.3-1.5-0.5-2.6-1.7-2.6
			C611.2,99.4,609.9,100.6,609.6,102.1"/>
				<path fill="#00A44F" d="M602.3,102.5c-0.3,1.5,0.4,2.6,1.7,2.6c1.3-0.1,2.6-1.3,2.9-2.8c0.3-1.5-0.4-2.6-1.7-2.6
			C603.9,99.7,602.6,101,602.3,102.5"/>
				<path fill="#00A44F" d="M595,102.8c-0.4,1.5,0.4,2.6,1.7,2.6c1.3-0.1,2.6-1.3,2.9-2.8c0.3-1.5-0.4-2.6-1.7-2.6
			C596.7,100.1,595.3,101.3,595,102.8"/>
				<path fill="#00A44F" d="M587.7,103.2c-0.4,1.5,0.4,2.6,1.6,2.6c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.4-2.6-1.6-2.6
			C589.4,100.5,588.1,101.7,587.7,103.2"/>
				<path fill="#00A44F" d="M580.4,103.6c-0.4,1.5,0.3,2.6,1.6,2.6c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.3-2.6-1.6-2.6
			C582.1,100.9,580.8,102.1,580.4,103.6"/>
				<path fill="#00A44F" d="M573.1,104c-0.4,1.5,0.3,2.6,1.6,2.6c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.3-2.6-1.6-2.6
			C574.8,101.3,573.5,102.5,573.1,104"/>
				<path fill="#00A44F" d="M565.8,104.4c-0.4,1.5,0.3,2.6,1.5,2.6s2.6-1.3,3.1-2.8c0.4-1.5-0.3-2.6-1.5-2.6
			C567.6,101.7,566.2,103,565.8,104.4"/>
				<path fill="#00A44F" d="M558.4,104.9c-0.4,1.5,0.2,2.6,1.5,2.6c1.3-0.1,2.7-1.4,3.1-2.8c0.4-1.5-0.2-2.6-1.5-2.5
			C560.3,102.1,558.9,103.4,558.4,104.9"/>
				<path fill="#00A44F" d="M551.1,105.4c-0.5,1.5,0.2,2.6,1.5,2.6c1.3-0.1,2.7-1.4,3.1-2.9c0.5-1.5-0.2-2.6-1.5-2.6
			C553,102.6,551.6,103.9,551.1,105.4"/>
				<path fill="#00A44F" d="M543.8,105.8c-0.5,1.5,0.2,2.6,1.4,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.2-2.6-1.4-2.5
			C545.7,103.1,544.3,104.3,543.8,105.8"/>
				<path fill="#00A44F" d="M536.5,106.3c-0.5,1.5,0.1,2.7,1.4,2.6c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.1-2.6-1.4-2.6
			C538.5,103.6,537,104.8,536.5,106.3"/>
				<path fill="#00A44F" d="M529.2,106.9c-0.5,1.5,0.1,2.7,1.4,2.6s2.7-1.4,3.2-2.9c0.5-1.5-0.1-2.6-1.4-2.5
			C531.2,104.1,529.8,105.4,529.2,106.9"/>
				<path fill="#00A44F" d="M521.9,107.4c-0.5,1.5,0.1,2.7,1.3,2.6c1.3-0.1,2.7-1.4,3.3-2.9c0.5-1.5-0.1-2.6-1.3-2.5
			C523.9,104.6,522.5,105.9,521.9,107.4"/>
				<path fill="#00A44F" d="M514.7,107.9c-0.6,1.5,0,2.7,1.3,2.6c1.3-0.1,2.7-1.4,3.3-2.9c0.5-1.5,0-2.6-1.3-2.6
			C516.7,105.1,515.2,106.4,514.7,107.9"/>
				<path fill="#00A44F" d="M507.4,108.5c-0.6,1.5,0,2.7,1.3,2.6c1.3-0.1,2.8-1.4,3.3-2.9c0.6-1.5,0-2.6-1.3-2.6
			C509.4,105.7,507.9,107,507.4,108.5"/>
				<path fill="#00A44F" d="M500.1,109.1c-0.6,1.5,0,2.7,1.2,2.6c1.3-0.1,2.8-1.4,3.3-2.9c0.6-1.5,0-2.7-1.2-2.6
			C502.2,106.3,500.7,107.6,500.1,109.1"/>
				<path fill="#00A44F" d="M492.8,109.7c-0.6,1.5-0.1,2.7,1.2,2.6c1.3-0.1,2.8-1.4,3.4-2.9c0.6-1.5,0.1-2.7-1.2-2.6
			C494.9,106.9,493.4,108.2,492.8,109.7"/>
				<path fill="#00A44F" d="M485.5,110.3c-0.6,1.5-0.1,2.7,1.2,2.6c1.3-0.1,2.8-1.4,3.4-3c0.6-1.5,0.1-2.7-1.2-2.6
			C487.6,107.5,486.1,108.8,485.5,110.3"/>
				<path fill="#00A44F" d="M478.2,110.9c-0.6,1.5-0.1,2.7,1.1,2.6c1.3-0.1,2.8-1.4,3.4-3c0.6-1.5,0.1-2.7-1.1-2.6
			C480.4,108.1,478.9,109.4,478.2,110.9"/>
				<path fill="#00A44F" d="M463.7,112.3c-0.7,1.5-0.2,2.7,1.1,2.6c1.3-0.1,2.8-1.5,3.5-3c0.7-1.5,0.2-2.7-1.1-2.6
			S464.3,110.7,463.7,112.3"/>
				<path fill="#00A44F" d="M456.4,112.9c-0.7,1.5-0.2,2.7,1,2.6c1.3-0.1,2.8-1.5,3.5-3c0.7-1.5,0.2-2.7-1-2.6
			S457.1,111.4,456.4,112.9"/>
				<path fill="#00A44F" d="M441.9,114.4c-0.7,1.5-0.3,2.7,1,2.6c1.3-0.1,2.9-1.5,3.6-3c0.7-1.5,0.3-2.7-1-2.6
			C444.2,111.5,442.6,112.8,441.9,114.4"/>
				<path fill="#00A44F" d="M434.6,115.1c-0.7,1.6-0.3,2.7,1,2.6c1.3-0.1,2.9-1.5,3.6-3c0.7-1.5,0.3-2.7-1-2.6
			C436.9,112.2,435.3,113.6,434.6,115.1"/>
				<path fill="#00A44F" d="M427.3,115.9c-0.8,1.6-0.3,2.7,0.9,2.6c1.3-0.1,2.9-1.5,3.6-3.1c0.7-1.6,0.3-2.7-0.9-2.6
			C429.7,112.9,428.1,114.3,427.3,115.9"/>
				<path fill="#00A44F" d="M420.1,116.6c-0.8,1.6-0.4,2.7,0.9,2.6c1.3-0.1,2.9-1.5,3.7-3.1c0.8-1.6,0.4-2.7-0.9-2.6
			C422.5,113.7,420.8,115.1,420.1,116.6"/>
				<path fill="#00A44F" d="M412.8,117.4c-0.8,1.6-0.4,2.7,0.9,2.6c1.3-0.1,2.9-1.5,3.7-3.1c0.8-1.6,0.4-2.7-0.9-2.6
			C415.3,114.5,413.6,115.8,412.8,117.4"/>
				<path fill="#00A44F" d="M405.6,118.2c-0.8,1.6-0.4,2.7,0.8,2.6s2.9-1.5,3.7-3.1c0.8-1.6,0.4-2.7-0.8-2.6
			C408,115.3,406.4,116.6,405.6,118.2"/>
				<path fill="#00A44F" d="M391.1,119.9c-0.8,1.6-0.5,2.7,0.8,2.6c1.3-0.1,2.9-1.5,3.8-3.1c0.8-1.6,0.5-2.7-0.8-2.6
			C393.6,116.9,391.9,118.3,391.1,119.9"/>
				<path fill="#00A44F" d="M625.8,93.2c-0.3,1.4,0.5,2.6,1.8,2.5c1.3-0.1,2.5-1.3,2.8-2.7c0.3-1.4-0.5-2.6-1.8-2.5
			C627.3,90.6,626.1,91.8,625.8,93.2"/>
				<path fill="#00A44F" d="M618.6,93.5c-0.3,1.4,0.5,2.6,1.7,2.5c1.3-0.1,2.5-1.3,2.8-2.7c0.3-1.4-0.5-2.6-1.7-2.5
			C620.1,90.9,618.9,92.1,618.6,93.5"/>
				<path fill="#00A44F" d="M611.4,93.8c-0.3,1.4,0.4,2.6,1.7,2.5c1.3-0.1,2.5-1.3,2.8-2.7c0.3-1.4-0.5-2.6-1.7-2.5
			C613,91.2,611.7,92.4,611.4,93.8"/>
				<path fill="#00A44F" d="M604.2,94.2c-0.3,1.5,0.4,2.6,1.7,2.5c1.3-0.1,2.5-1.3,2.9-2.7c0.3-1.5-0.4-2.6-1.7-2.5
			C605.8,91.5,604.5,92.7,604.2,94.2"/>
				<path fill="#00A44F" d="M597,94.5c-0.4,1.5,0.4,2.6,1.6,2.5c1.3-0.1,2.6-1.3,2.9-2.7c0.3-1.5-0.4-2.6-1.6-2.5
			C598.6,91.9,597.3,93.1,597,94.5"/>
				<path fill="#00A44F" d="M589.8,94.9c-0.4,1.5,0.3,2.6,1.6,2.5c1.3-0.1,2.6-1.3,2.9-2.8c0.4-1.5-0.4-2.6-1.6-2.5
			C591.5,92.2,590.2,93.4,589.8,94.9"/>
				<path fill="#00A44F" d="M582.6,95.3c-0.4,1.5,0.3,2.6,1.6,2.5c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.3-2.6-1.6-2.5
			C584.3,92.6,583,93.8,582.6,95.3"/>
				<path fill="#00A44F" d="M575.4,95.7c-0.4,1.5,0.3,2.6,1.5,2.5c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.3-2.6-1.5-2.5
			C577.1,93,575.8,94.2,575.4,95.7"/>
				<path fill="#00A44F" d="M568.2,96.1c-0.4,1.5,0.2,2.6,1.5,2.5c1.3-0.1,2.6-1.3,3-2.8c0.4-1.5-0.3-2.6-1.5-2.5
			C569.9,93.4,568.6,94.6,568.2,96.1"/>
				<path fill="#00A44F" d="M561,96.5c-0.4,1.5,0.2,2.6,1.5,2.5c1.3-0.1,2.6-1.3,3.1-2.8c0.4-1.5-0.2-2.6-1.5-2.5
			C562.8,93.8,561.4,95.1,561,96.5"/>
				<path fill="#00A44F" d="M553.8,97c-0.5,1.5,0.2,2.6,1.4,2.5c1.3-0.1,2.6-1.3,3.1-2.8c0.5-1.5-0.2-2.6-1.4-2.5
			C555.6,94.3,554.2,95.5,553.8,97"/>
				<path fill="#00A44F" d="M546.6,97.4c-0.5,1.5,0.1,2.6,1.4,2.5c1.3-0.1,2.7-1.3,3.1-2.8c0.5-1.5-0.2-2.6-1.4-2.5
			C548.4,94.7,547,96,546.6,97.4"/>
				<path fill="#00A44F" d="M539.4,97.9c-0.5,1.5,0.1,2.6,1.4,2.5c1.3-0.1,2.7-1.4,3.2-2.8c0.5-1.5-0.1-2.6-1.4-2.5
			C541.3,95.2,539.9,96.5,539.4,97.9"/>
				<path fill="#00A44F" d="M532.2,98.4c-0.5,1.5,0.1,2.6,1.3,2.5c1.3-0.1,2.7-1.4,3.2-2.8c0.5-1.5-0.1-2.6-1.3-2.5
			C534.1,95.7,532.7,97,532.2,98.4"/>
				<path fill="#00A44F" d="M525,98.9c-0.5,1.5,0,2.6,1.3,2.5c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5-0.1-2.6-1.3-2.5
			C526.9,96.2,525.5,97.5,525,98.9"/>
				<path fill="#00A44F" d="M517.8,99.5c-0.6,1.5,0,2.6,1.3,2.5c1.3-0.1,2.7-1.4,3.2-2.9c0.5-1.5,0-2.6-1.3-2.5
			C519.8,96.7,518.3,98,517.8,99.5"/>
				<path fill="#00A44F" d="M510.6,100c-0.6,1.5,0,2.6,1.2,2.5c1.3-0.1,2.7-1.4,3.3-2.9c0.6-1.5,0-2.6-1.2-2.5
			C512.6,97.3,511.1,98.5,510.6,100"/>
				<path fill="#00A44F" d="M503.4,100.6c-0.6,1.5,0,2.6,1.2,2.5c1.3-0.1,2.7-1.4,3.3-2.9c0.6-1.5,0-2.6-1.2-2.5
			C505.5,97.8,504,99.1,503.4,100.6"/>
				<path fill="#00A44F" d="M496.2,101.2c-0.6,1.5-0.1,2.6,1.2,2.5c1.3-0.1,2.7-1.4,3.3-2.9c0.6-1.5,0.1-2.6-1.2-2.5
			C498.3,98.4,496.8,99.7,496.2,101.2"/>
				<path fill="#00A44F" d="M489,101.8c-0.6,1.5-0.1,2.6,1.1,2.5c1.3-0.1,2.8-1.4,3.4-2.9c0.6-1.5,0.1-2.6-1.1-2.5
			C491.1,99,489.6,100.3,489,101.8"/>
				<path fill="#00A44F" d="M474.6,103c-0.7,1.5-0.2,2.6,1.1,2.5c1.3-0.1,2.8-1.4,3.4-2.9c0.6-1.5,0.2-2.6-1.1-2.5
			C476.8,100.2,475.3,101.5,474.6,103"/>
				<path fill="#00A44F" d="M467.5,103.7c-0.7,1.5-0.2,2.7,1.1,2.5c1.3-0.1,2.8-1.4,3.5-2.9c0.7-1.5,0.2-2.6-1.1-2.5
			C469.7,100.8,468.1,102.1,467.5,103.7"/>
				<path fill="#00A44F" d="M460.3,104.3c-0.7,1.5-0.2,2.7,1,2.5c1.3-0.1,2.8-1.4,3.5-3c0.7-1.5,0.2-2.6-1-2.5
			C462.5,101.5,461,102.8,460.3,104.3"/>
				<path fill="#00A44F" d="M453.1,105c-0.7,1.5-0.3,2.7,1,2.5c1.3-0.1,2.8-1.5,3.5-3c0.7-1.5,0.2-2.6-1-2.5
			C455.4,102.1,453.8,103.5,453.1,105"/>
				<path fill="#00A44F" d="M445.9,105.7c-0.7,1.5-0.3,2.7,1,2.5c1.3-0.1,2.8-1.5,3.5-3c0.7-1.5,0.3-2.7-1-2.5
			C448.2,102.8,446.6,104.2,445.9,105.7"/>
				<path fill="#00A44F" d="M438.7,106.4c-0.7,1.5-0.3,2.7,0.9,2.5s2.8-1.5,3.6-3c0.7-1.5,0.3-2.7-0.9-2.5
			C441.1,103.5,439.5,104.9,438.7,106.4"/>
				<path fill="#00A44F" d="M431.6,107.1c-0.7,1.5-0.3,2.7,0.9,2.6c1.2-0.1,2.9-1.5,3.6-3c0.7-1.5,0.3-2.7-0.9-2.5
			C433.9,104.2,432.3,105.6,431.6,107.1"/>
				<path fill="#00A44F" d="M641.6,84.6c-0.2,1.4,0.6,2.5,1.8,2.5c1.2,0,2.4-1.2,2.6-2.6c0.2-1.4-0.6-2.5-1.8-2.5
			C643,82,641.8,83.2,641.6,84.6"/>
				<path fill="#00A44F" d="M634.5,84.9c-0.3,1.4,0.5,2.5,1.8,2.5c1.2,0,2.4-1.2,2.7-2.7c0.2-1.4-0.6-2.5-1.8-2.5
			C635.9,82.3,634.7,83.4,634.5,84.9"/>
				<path fill="#00A44F" d="M627.4,85.1c-0.3,1.4,0.5,2.5,1.7,2.5c1.2,0,2.5-1.2,2.7-2.7c0.3-1.4-0.5-2.5-1.7-2.5
			S627.6,83.7,627.4,85.1"/>
				<path fill="#00A44F" d="M620.3,85.4c-0.3,1.4,0.5,2.5,1.7,2.5c1.2-0.1,2.5-1.3,2.8-2.7s-0.5-2.5-1.7-2.5
			C621.8,82.8,620.6,84,620.3,85.4"/>
				<path fill="#00A44F" d="M613.2,85.7c-0.3,1.4,0.4,2.5,1.7,2.5c1.2-0.1,2.5-1.3,2.8-2.7c0.3-1.4-0.5-2.5-1.7-2.5
			C614.7,83.1,613.5,84.3,613.2,85.7"/>
				<path fill="#00A44F" d="M606.1,86.1c-0.3,1.4,0.4,2.5,1.6,2.5c1.2-0.1,2.5-1.3,2.8-2.7c0.3-1.4-0.4-2.5-1.6-2.5
			C607.7,83.4,606.4,84.6,606.1,86.1"/>
				<path fill="#00A44F" d="M599,86.4c-0.4,1.4,0.4,2.5,1.6,2.5c1.2-0.1,2.5-1.3,2.9-2.7c0.3-1.4-0.4-2.5-1.6-2.5
			C600.6,83.8,599.3,85,599,86.4"/>
				<path fill="#00A44F" d="M591.9,86.7c-0.4,1.4,0.3,2.5,1.6,2.5c1.2-0.1,2.5-1.3,2.9-2.7c0.4-1.4-0.3-2.5-1.6-2.5
			C593.5,84.1,592.2,85.3,591.9,86.7"/>
				<path fill="#00A44F" d="M584.8,87.1c-0.4,1.4,0.3,2.5,1.5,2.5c1.2-0.1,2.5-1.3,2.9-2.7c0.4-1.4-0.3-2.5-1.5-2.5
			C586.5,84.5,585.2,85.7,584.8,87.1"/>
				<path fill="#00A44F" d="M577.7,87.5c-0.4,1.4,0.3,2.6,1.5,2.5c1.2-0.1,2.6-1.3,3-2.7c0.4-1.4-0.3-2.5-1.5-2.5
			C579.4,84.9,578.1,86.1,577.7,87.5"/>
				<path fill="#00A44F" d="M570.6,87.9c-0.4,1.4,0.2,2.6,1.5,2.5c1.2-0.1,2.6-1.3,3-2.7c0.4-1.4-0.3-2.5-1.5-2.5
			C572.3,85.2,571,86.5,570.6,87.9"/>
				<path fill="#00A44F" d="M563.5,88.3c-0.4,1.4,0.2,2.6,1.4,2.5c1.2-0.1,2.6-1.3,3-2.7c0.4-1.4-0.2-2.5-1.4-2.5
			C565.3,85.7,563.9,86.9,563.5,88.3"/>
				<path fill="#00A44F" d="M556.4,88.7c-0.5,1.4,0.2,2.6,1.4,2.5c1.2-0.1,2.6-1.3,3.1-2.8c0.4-1.4-0.2-2.5-1.4-2.5
			C558.2,86.1,556.8,87.3,556.4,88.7"/>
				<path fill="#00A44F" d="M549.3,89.2c-0.5,1.4,0.1,2.6,1.4,2.5c1.2-0.1,2.6-1.3,3.1-2.8c0.5-1.4-0.2-2.5-1.4-2.5
			C551.1,86.5,549.7,87.7,549.3,89.2"/>
				<path fill="#00A44F" d="M528,90.6c-0.5,1.5,0,2.6,1.3,2.5c1.2-0.1,2.7-1.3,3.2-2.8c0.5-1.5-0.1-2.6-1.3-2.5
			C529.9,87.9,528.5,89.2,528,90.6"/>
				<path fill="#00A44F" d="M520.9,91.1c-0.5,1.5,0,2.6,1.2,2.5c1.2-0.1,2.7-1.4,3.2-2.8c0.5-1.5,0-2.6-1.2-2.5
			C522.9,88.4,521.4,89.7,520.9,91.1"/>
				<path fill="#00A44F" d="M513.8,91.7c-0.6,1.5,0,2.6,1.2,2.5c1.2-0.1,2.7-1.4,3.2-2.8c0.6-1.5,0-2.6-1.2-2.5
			C515.8,88.9,514.3,90.2,513.8,91.7"/>
				<path fill="#00A44F" d="M663.9,76c-0.2,1.4,0.7,2.5,1.9,2.4c1.2,0,2.3-1.2,2.5-2.6c0.2-1.4-0.7-2.5-1.9-2.4
			C665.2,73.5,664.1,74.6,663.9,76"/>
				<path fill="#00A44F" d="M656.9,76.2c-0.2,1.4,0.6,2.5,1.8,2.4c1.2,0,2.4-1.2,2.5-2.6c0.2-1.4-0.6-2.5-1.8-2.4
			C658.2,73.7,657.1,74.9,656.9,76.2"/>
				<path fill="#00A44F" d="M642.9,76.7c-0.2,1.4,0.6,2.5,1.8,2.4c1.2,0,2.4-1.2,2.6-2.6c0.2-1.4-0.6-2.5-1.8-2.4
			C644.3,74.1,643.1,75.3,642.9,76.7"/>
				<path fill="#00A44F" d="M614.9,77.8c-0.3,1.4,0.4,2.5,1.6,2.4c1.2-0.1,2.5-1.2,2.8-2.6c0.3-1.4-0.4-2.5-1.6-2.4
			C616.5,75.2,615.2,76.4,614.9,77.8"/>
				<path fill="#00A44F" d="M607.9,78.1c-0.3,1.4,0.4,2.5,1.6,2.4c1.2-0.1,2.5-1.2,2.8-2.6c0.3-1.4-0.4-2.5-1.6-2.4
			C609.5,75.5,608.3,76.7,607.9,78.1"/>
				<path fill="#00A44F" d="M600.9,78.4c-0.3,1.4,0.4,2.5,1.6,2.4c1.2-0.1,2.5-1.2,2.8-2.6c0.3-1.4-0.4-2.5-1.6-2.4
			C602.5,75.8,601.3,77,600.9,78.4"/>
				<path fill="#00A44F" d="M593.9,78.7c-0.4,1.4,0.3,2.5,1.5,2.4c1.2-0.1,2.5-1.3,2.9-2.7c0.4-1.4-0.3-2.5-1.5-2.4
			C595.6,76.2,594.3,77.3,593.9,78.7"/>
				<path fill="#00A44F" d="M586.9,79.1c-0.4,1.4,0.3,2.5,1.5,2.4c1.2-0.1,2.5-1.3,2.9-2.7c0.4-1.4-0.3-2.5-1.5-2.4
			C588.6,76.5,587.3,77.7,586.9,79.1"/>
				<path fill="#00A44F" d="M579.9,79.5c-0.4,1.4,0.3,2.5,1.5,2.4c1.2-0.1,2.5-1.3,2.9-2.7s-0.3-2.5-1.5-2.4
			C581.7,76.9,580.4,78,579.9,79.5"/>
				<path fill="#00A44F" d="M566,80.2c-0.4,1.4,0.2,2.5,1.4,2.4c1.2-0.1,2.6-1.3,3-2.7c0.4-1.4-0.2-2.5-1.4-2.4
			C567.7,77.6,566.4,78.8,566,80.2"/>
				<path fill="#00A44F" d="M559,80.7c-0.5,1.4,0.2,2.5,1.4,2.4c1.2-0.1,2.6-1.3,3-2.7c0.4-1.4-0.2-2.5-1.4-2.4
			C560.8,78,559.4,79.2,559,80.7"/>
				<path fill="#00A44F" d="M273.7,223.2 M273.2,148.2 M273.2,148.2" />
			</g>

			<path d="M384.4,280.009128 C364.039389,280.153109 347.646995,296.76932 347.78,317.13 C347.92,337.49 385.22,399.69 385.22,399.69 C385.22,399.69 421.66,336.98 421.520402,316.62 C421.3769,296.26098 404.759051,279.871448 384.4,280.009128 Z M384.77,333.4 C377.622515,333.45242 371.147408,329.19365 368.364603,322.609936 C365.581798,316.026222 367.039454,308.41443 372.057736,303.324622 C377.076017,298.234815 384.666419,296.669586 391.288854,299.358942 C397.91129,302.048298 402.261267,308.462489 402.31,315.61 C402.344599,320.295482 400.515735,324.802649 397.226036,328.139236 C393.936337,331.475824 389.455502,333.368289 384.77,333.4 L384.77,333.4 Z"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'latam' })} id="Shape" fill="#D51566" fillRule="nonzero"></path>

			<path d="M246.45,96.4791455 C236.671398,96.5460798 227.319577,100.494104 220.451946,107.455503 C213.584316,114.416903 209.763471,123.821401 209.83,133.6 C209.97,153.96 247.27,216.16 247.27,216.16 C247.27,216.16 283.71,153.45 283.570402,133.1 C283.50392,123.321398 279.555896,113.969577 272.594497,107.101946 C265.633097,100.234316 256.228599,96.4134706 246.45,96.4791455 Z M246.81,149.87 C239.662474,149.918402 233.189754,145.655977 230.410659,139.070683 C227.631563,132.485389 229.093524,124.874407 234.114694,119.787432 C239.135865,114.700457 246.727168,113.139529 253.348086,115.832651 C259.969004,118.525773 264.31534,124.94245 264.36,132.09 C264.394592,136.776273 262.564041,141.283916 259.271898,144.619204 C255.979754,147.954493 251.496327,149.843576 246.81,149.87 L246.81,149.87 Z"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'noram' })} id="Shape" fill="#00A44F" fillRule="nonzero"></path>

			<path d="M768.38,205.339196 C758.601398,205.40608 749.249577,209.354104 742.381946,216.315503 C735.514316,223.276903 731.693471,232.681401 731.76,242.46 C731.9,262.82 769.2,325.03 769.2,325.03 C769.2,325.03 805.63,262.32 805.500347,241.96 C805.356891,221.599389 788.74068,205.206995 768.38,205.339196 Z M768.74,258.74 C758.981458,258.806047 751.017014,250.948807 750.950817,241.190266 C750.88462,231.431725 758.741737,223.46716 768.500277,223.400813 C778.258817,223.334466 786.223503,231.191461 786.290353,240.95 C786.350967,250.706715 778.496643,258.668449 768.74,258.74 L768.74,258.74 Z"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'africa' })} id="Shape" fill="#0072BC" fillRule="nonzero"></path>

			<path d="M872.2,114.5c-23,0.2-41.6,18.9-41.4,42c0.2,23,42.3,93.4,42.3,93.4s41.2-70.9,41-93.9 C914.1,132.9,895.3,114.4,872.2,114.5 M872.7,174.9c-11,0.1-20-8.8-20.1-19.8c-0.1-11,8.8-20,19.8-20.1c11-0.1,20,8.8,20.1,19.8 C892.6,165.8,883.7,174.8,872.7,174.9"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'middle_east' })} id="Shape" fill="#662D91" fillRule="nonzero"></path>

			<path d="M949.89,-0.000854476269 C940.111398,0.0660797979 930.759577,4.01410368 923.891946,10.9755031 C917.024316,17.9369025 913.203471,27.3414006 913.27,37.12 C913.41,57.48 950.71,119.68 950.71,119.68 C950.71,119.68 987.15,56.97 987.010402,36.62 C986.94392,26.8413976 982.995896,17.4895769 976.034497,10.6219463 C969.073097,3.75431577 959.668599,-0.0665293815 949.89,-0.000854476269 Z M950.25,53.39 C943.102474,53.4384017 936.629754,49.1759767 933.850659,42.5906827 C931.071563,36.0053887 932.533524,28.3944066 937.554694,23.3074316 C942.575865,18.2204567 950.167168,16.6595287 956.788086,19.3526508 C963.409004,22.0457729 967.75534,28.4624499 967.8,35.61 C967.834592,40.2962735 966.004041,44.803916 962.711898,48.1392043 C959.419754,51.4744926 954.936327,53.3635758 950.25,53.39 L950.25,53.39 Z"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'europe' })} id="Shape" fill="#00BCF1" fillRule="nonzero"></path>

			<path d="M1076.54,138.219146 C1066.7614,138.28608 1057.40958,142.234104 1050.54195,149.195503 C1043.67432,156.156903 1039.85347,165.561401 1039.92,175.34 C1040.06,195.7 1077.36,257.9 1077.36,257.9 C1077.36,257.9 1113.79,195.19 1113.66035,174.84 C1113.59392,165.061398 1109.6459,155.709577 1102.6845,148.841946 C1095.7231,141.974316 1086.3186,138.153471 1076.54,138.219146 L1076.54,138.219146 Z M1076.9,191.61 C1069.75247,191.658402 1063.27975,187.395977 1060.50066,180.810683 C1057.72156,174.225389 1059.18352,166.614407 1064.20469,161.527432 C1069.22586,156.440457 1076.81717,154.879529 1083.43809,157.572651 C1090.059,160.265773 1094.40534,166.68245 1094.45,173.83 C1094.4846,178.518134 1092.65468,183.027845 1089.36311,186.366307 C1086.07153,189.70477 1081.58815,191.598289 1076.9,191.63 L1076.9,191.61 Z"
				className={classNames("map-pin", { 'map-pin-active': highlight === 'apac' })} id="Shape" fill="#FAA634" fillRule="nonzero"></path>

		</svg>
	)
}

export default Maps;
