import React, { useEffect, useState } from 'react';
import { BGContainer, SteppedLineTo } from 'src/components';
import './style.css';
import ResponsibeForPayment from './Partners';
import RateTypes from './RateTypes2';
import HotelContentProvider from './HotelContentProvider';
import HotelContentProviderGrid from './HotelContentProviderGrid';
import PaymentOptions from './PaymentOptions';
import Currencies from './Currencies';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import HotelCountryIc from 'src/assets/icon/hotel-country.png';
import ResponsibleForPaymentIc from 'src/assets/icon/responsive-for-payment.png';
import HotelPaymentOptionIc from 'src/assets/icon/hotel-payment-option.png';
import RateTypeIc from 'src/assets/icon/rate-type.png';
import HotelProviderIc from 'src/assets/icon/hotel-provider.png';
import classNames from 'classnames';
import Store from 'src/store';
import { PartnerSchema, CurrencySchema } from 'src/store/Country.Store';
import VarHelper from 'src/utils/VarHelper';
import { toJS } from 'mobx';

const TABS = [
    { title: 'COUNTRY', flex: 1, icon: HotelCountryIc },
    { title: 'RESPONSIBLE FOR PAYMENT', flex: 1, icon: ResponsibleForPaymentIc },
    { title: 'RATE TYPE', flex: 1, icon: RateTypeIc },
    { title: 'HOTEL CONTENT PROVIDER', flex: 2, icon: HotelProviderIc },
    { title: 'PAYMENT OPTIONS', flex: 2, icon: HotelPaymentOptionIc },
];

const remToPx = (rem: number) => parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;
const CONTENT_HEIGHT = window.innerHeight - remToPx(16);


const CountryDetail = (props: RouteComponentProps) => {
    const { region, country }: any = props.match.params || {};
    const [showLine, setShowLine] = useState(false);
    const [rerender, setRerender] = useState(Math.random());
    // const [selectedPartner, setSelectedPartner] = useState<{ name: string }>();
    const [selectedResponsiblePayment, setSelectedResponsiblePayment] = useState<{ name: string }>();
    const [rateType, setRateType] = useState<any>();
    const [selectedProvider, setSelectedProvider] = useState<any>();
    const [providerList, setProviderList] = useState<Array<{ name: string, paymentOptions: any }>>([]);
    const [paymentOptions, setPaymentOptions] = useState<Array<{ name: string, id: string, comment: string }>>([]);

    useEffect(() => {
        // @ts-ignore
        !!window.plausible && window.plausible('Page View', { props: { page: 'Country Detail', value: Store.Country.selectedCountry } });
    }, []);

    // useEffect(() => {
    //     if (selectedPartner) {
    //         setShowLine(false);
    //         setTimeout(() => {
    //             setShowLine(true);
    //         }, 100);
    //         if (checkPartnerHideCard(selectedPartner)) {
    //             setSelectedCard(selectedPartner.currencies[0]);
    //         } else {
    //             setSelectedCard(undefined);
    //         }
    //     } else {
    //         setSelectedCard(undefined);
    //     }
    // }, [selectedPartner]);

    useEffect(() => {
        if (!Store.Hotel.listCountries || Store.Hotel.listCountries.length === 0 || Store.Hotel.selectedRegion !== region.toUpperCase()) {
            if (!Store.Hotel.selectedRegion || Store.Hotel.selectedRegion !== region.toUpperCase()) {
                Store.Hotel.setSelectedRegion(region.toUpperCase());
            }
            Store.Hotel.getListData().then(() => {
                setRerender(Math.random());
            });
        } else {
            if (!Store.Hotel.selectedCountry || Store.Hotel.selectedCountry.country !== country) {
                const findCountry = Store.Hotel.listCountries.find(val => val.country === country);
                if (!!findCountry) {
                    Store.Hotel.setSelectedCountry(findCountry);
                    setRerender(Math.random());
                } else {
                    props.history.push('/');
                }
            }
        }
    }, [Store.Hotel.listCountries?.length, Store.Hotel.selectedRegion, country, region]);

    const goBack = () => {
        props.history.goBack();
    }

    const toSelectPage = () => {
        props.history.push('/select-heat-map');
    }

    const { selectedCountry } = Store.Hotel;

    useEffect(() => {
        if (selectedCountry && !Store.Site.getImageByName(selectedCountry.country)) {
            Store.Site.waitUntilLoaded().then(() => setRerender(Math.random()));
        }
    }, [selectedCountry]);

    return (
        <BGContainer title={selectedCountry?.region} onClickHome={toSelectPage}>
            <div className="detail-header-container-wallet">
                {TABS.map((tab, i) => {
                    return (
                        <div key={`tab${i}`} className={classNames("header-item", {
                            'header-item-border': i < TABS.length - 1
                        })} style={{ flex: tab.flex }}>
                            <img src={tab.icon} />
                            <span className="text-regular column-title">{tab.title}</span>
                        </div>
                    )
                })}
            </div>
            {selectedCountry &&
                <div className="detail-content-container">
                    <div style={{ flex: TABS[0].flex, overflow: 'hidden', transform: 'translateX(-20px)' }}>
                        <div style={{ marginTop: '15px' }}>
                            <img
                                src={Store.Site.getImageByName(selectedCountry.country) || VarHelper.getFlagByCountry(selectedCountry.country)}
                                style={{ width: '100%' }}
                            />
                            <span className="text-light" style={{ fontSize: '2.1rem', marginTop: '1rem', marginBottom: '1rem' }}>{selectedCountry.country.toUpperCase()}</span>
                        </div>
                    </div>
                    <div className="scrollable-col" style={{
                        flex: TABS[1].flex, height: CONTENT_HEIGHT,
                        overflow: 'scroll',
                    }}>
                        <ResponsibeForPayment selected={selectedResponsiblePayment?.name} setSelected={(item: PartnerSchema) => {
                            if (item.name !== selectedResponsiblePayment?.name) setSelectedResponsiblePayment(item);
                            else setSelectedResponsiblePayment(undefined);

                            setRateType(undefined);
                            setPaymentOptions([]);
                            setSelectedProvider(undefined);
                            setProviderList([]);
                        }} />
                    </div>
                    <div className="scrollable-col" style={{
                        flex: TABS[2].flex, height: CONTENT_HEIGHT,
                        overflow: 'scroll',
                    }}>
                        {!!selectedResponsiblePayment?.name && (
                            <RateTypes
                                selected={rateType}
                                setSelected={(item) => {
                                    console.log('item', item.name);
                                    if (item.name !== rateType) {
                                        setRateType(item.name);
                                        const { hotelNet, hotelCommissionable, agencyNet, agencyCommissionable } = toJS(Store.Hotel.selectedCountry);
                                        // console.log('length', hotelNet.length, hotelCommissionable.length, agencyNet.length, agencyCommissionable.length);
                                        // console.log('selectedResponsiblePayment?.name', selectedResponsiblePayment?.name);
                                        // console.log('agencyCommissionable', agencyCommissionable);
                                        const newProviderList =
                                            selectedResponsiblePayment?.name === 'HOTEL COLLECT' && item.name === 'NET'
                                                ? hotelNet :
                                                    selectedResponsiblePayment?.name === 'HOTEL COLLECT' && item.name === 'COMMISIONABLE'
                                                        ? hotelCommissionable :
                                                        selectedResponsiblePayment?.name === 'AGENCY COLLECT' && item.name === 'COMMISIONABLE'
                                                            ? agencyCommissionable : agencyNet;
                                        // console.log(providerList === newProviderList);
                                        console.log('provider list length', newProviderList);
                                        setProviderList(newProviderList);
                                    } else {
                                        setRateType(undefined);
                                        setProviderList([]);
                                    }

                                    setPaymentOptions([]);
                                    setSelectedProvider(undefined);
                                }}
                            />
                        )}

                    </div>
                    <div className="scrollable-col" style={{
                        flex: TABS[3].flex, height: CONTENT_HEIGHT,
                        // overflow: 'scroll',
                    }}>
                        {!!selectedResponsiblePayment?.name && (
                            <HotelContentProviderGrid
                                selected={selectedProvider?.name}
                                setSelected={(item) => {
                                    if (item.name !== selectedProvider?.name) {
                                        setSelectedProvider(item);
                                        setPaymentOptions(item.paymentOptions.map(val => (
                                            {
                                                ...val,
                                                name: val.displayName || val.name,
                                                comment: val.displayMessage || val.comment,
                                            }
                                        )));
                                    }
                                    else setSelectedProvider(undefined);
                                }}
                                data={providerList}
                            />
                        )}
                    </div>
                    <div className="scrollable-col" style={{
                        flex: TABS[4].flex, height: CONTENT_HEIGHT,
                        overflow: 'scroll',
                    }}>
                        {!!selectedProvider?.name && (
                            <PaymentOptions
                                data={paymentOptions}
                            />
                        )}
                    </div>
                </div>
            }
        </BGContainer>
    );
}

export default withRouter(CountryDetail);
