import { observable, autorun } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import Protos from './protos/Protos.Utils';

type GeneralResponse = (any|Error)[] | any;

class User extends StoreClass implements StoreClassType {
  // constructor() {
  //   super();
  //   this.loadInitialData();
  // }

  @observable userData : any = {
    token: '',
  }

  async loadInitialData() {
    await Protos.onReady();
    let userData = await this.persist.sync("USER_DATA", "object");
    if (userData != undefined) {
      this.userData = userData;
      Request.setToken(this.getToken());
    }
    autorun(() => {
      this.persist.save(this.userData, "USER_DATA");
    });
    this.makeReady();
    const [res, err] = await this.getMyInfo();
    if (err) {
      console.log(err);
    } else {
      console.log(res);
    }
  }

  getToken() {
    if (!this.userData) return undefined;
    return this.userData.token;
  }

  isAuth = () => Boolean(this.getToken());


  @erria
  async updateCurrentUser(data) {
    console.log('update current')
    console.log(data)
    this.userData = {...this.userData, ...data}
    console.log(this.userData)
    this.persist.save({...this.userData, ...data}, "USER_DATA");
  }

  
  async helloworld() {
    try {
      const res = await Request.get(`${HOST}`, {}, Protos.HelloWorld.SayHelloResponse);
      console.log(res.data);
    } catch(err) {
      console.log(err);
    }
  }

  async testPost() {
    try {
      const res = await Request.post(`${HOST}`, { text: 'ABCDEF' }, { Req: Protos.HelloWorld.SayHelloRequest, Res: Protos.HelloWorld.SayHelloResponse });
      console.log(res.data);
    } catch(err) {
      console.log(err);
    }
  }

  @erria
  async login({ email, password }) : Promise<GeneralResponse> {
    const res = await Request.post(`${HOST}/users/login`, { email, password }, { Req: Protos.User.LoginRequest, Res: Protos.User.LoginResponse });
    console.log(res.data);
    if (res.data.error) throw new Error(res.data.message);
    const { token, data } = res.data;
    this.userData = {
      token,
      ...data
    };
    Request.setToken(this.getToken());
  }

  @erria
  async logout() : Promise<GeneralResponse>  {
    const res = await Request.post(`${HOST}/users/logout`, {}, { Req: undefined, Res: Protos.User.LogoutResponse });
    if (res.data.error) {
      console.log(res.data)
    }
    this.userData = {};
    Request.setToken('');
  }

  @erria
  async getMyInfo() : Promise<GeneralResponse>  {
    const res = await Request.get(`${HOST}/users/me`, {}, Protos.User.UserData);
    console.log(res.data);
    if (res.data.error) throw new Error(res.data.message);
    this.userData = {
      ...this.userData,
      ...res.data,
    };
  }
}

export default new User();