import { observable } from "mobx";
import erria from "erria/decorator";
import StoreClass, { StoreClassType } from "./StoreClass.Utils";
import Request from "./Request.Utils";
import { HOST } from "./host";

import Protos from "./protos/Protos.Utils";

type GeneralResponse = (any | Error)[] | any;

interface IHotelContentProvider {
  name: string;
  paymentOptions: Array<{
    name: string;
    id: string;
    description: string;
    comment: string;
  }>;
}

export type Region =
  | "AFRICA"
  | "APAC"
  | "EUR"
  | "LATAM"
  | "NORAM"
  | "MIDDLE_EAST"
  | "EUROPE";

export interface HotelSchema {
  id: string;
  country: string;
  region: string;
  hotelNet: Array<IHotelContentProvider>;
  hotelCommissionable: Array<IHotelContentProvider>;
  agencyNet: Array<IHotelContentProvider>;
  agencyCommissionable: Array<IHotelContentProvider>;
}

class Hotel extends StoreClass implements StoreClassType {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listCountries: Array<HotelSchema> = [];
  @observable listCountriesByRegion: Array<HotelSchema> = [];
  @observable selectedRegion: Region;
  @observable selectedCountry: HotelSchema;

  async loadInitialData() {
    await Protos.onReady();
    let listCountries = await this.persist.sync(
      "LIST_COUNTRIES_HOTELS",
      "array"
    );
    if (Array.isArray(listCountries)) this.listCountries = listCountries;
    this.makeReady();
  }

  @erria
  async getListData() {
    const res = await Request.restGet(
      `${HOST}/hotel`,
      {},
      Protos.Country.ListCountry
    );
    if (res.data.error) throw new Error(res.data.message);
    if (Array.isArray(res.data.list))
      this.listCountries = res.data.list.map((x) => ({
        ...x,
        country: x.country === "US" ? "United States of America" : x.country,
      }));
    this.persist.save(this.listCountries, "LIST_COUNTRIES_HOTELS");
    return res.data;
  }

  async setSelectedRegion(region: Region) {
    this.selectedRegion = region;
    let arr = [];
    switch (region) {
      case "AFRICA":
        arr = ["AFRICA", "AFRICA SS"];
        break;
      case "EUROPE":
        arr = ["EUR", "EURO", "EUROPE"];
        break;
      case "MIDDLE_EAST":
        arr = ["NECSE", "NECSE EAST", "MIDDLE_EAST", "MIDDLE EAST"];
        break;
      default:
        arr = [region];
        break;
    }
    if (!this.listCountries || !this.listCountries.length) {
      await this.getListData();
    }
    this.listCountriesByRegion = this.listCountries.filter((i) =>
      arr.includes(String(i.region).trim())
    );
    return true;
  }

  setSelectedCountry(country: HotelSchema) {
    this.selectedCountry = country;
  }
}

export default new Hotel();
