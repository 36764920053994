import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import HomeIc from 'src/assets/icon/home.png';
import LogoIc from 'src/assets/logo.png';
import './style.css';

interface Props {
    children?: ReactNode;
    title: string;
    onClickHome?(): void;
    leftTitleWithHomeIcon?: boolean;
}

const BGContainer = (props: Props) => {
    return (
        <Container className="bg-blue">
            <div className="header-title-container">
                <span className="text-light" id="header-title">{props.leftTitleWithHomeIcon && <img alt="home_ic" onClick={props.onClickHome} src={HomeIc} style={{ width: '2.3rem', height: '2.3rem', cursor: 'pointer', marginRight: '1.1rem', transform: 'translateY(0.2rem)' }} />}{props.title}</span>
                {props.onClickHome ?
                    <img alt="home_ic" onClick={props.onClickHome} src={HomeIc} style={{ width: '2.3rem', height: '2.3rem', cursor: 'pointer' }} />
                    :
                    <img alt="logo" src={LogoIc} style={{ width: '10rem', height: '1.33rem' }} />
                }
            </div>
            {props.children}
        </Container>
    )
}

export default BGContainer;
