import { observable, autorun } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import Protos from './protos/Protos.Utils';

const CURRENCIES = require('src/assets/currencies.json');

type GeneralResponse = (any|Error)[] | any;

interface ImageSchema {
  id: string,
  name: string, // name of country / partners / card schemes
  value: string, // image url
}

interface CurrencySchema {
  id: string,
  entity: string,
  currency: string,
  code: string,
}

class Site extends StoreClass implements StoreClassType {

  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listImages: Array<ImageSchema> = [];
  @observable listCurrencies: Array<CurrencySchema> = [];
  authorized = false;

  loadPromise: Promise<any>;

  async loadInitialData() {
    this.loadPromise = Promise.all([
      await Protos.onReady(),
      await this.getListImage(),
      await this.getListCurrentcies()
    ])
    await this.loadPromise;
    let authorized = await this.persist.sync("AUTHEN", "boolean");
    this.authorized = authorized;
    this.makeReady();
  }

  async waitUntilLoaded() {
    if (this.loadPromise) {
      await this.loadPromise;
    } else {
      await new Promise(resolve => setTimeout(resolve, 10));
      await this.waitUntilLoaded();
    }
  }

  async access(password) {
    try {
      const res = await Request.restPost(`${HOST}/site/access`, { password }, { Req: Protos.Site.AccessRequest, Res: Protos.Site.AccessResponse});
      this.authorized = res.data && res.data.success;
      this.persist.save(this.authorized, "AUTHEN");
      return this.authorized;
    } catch(err) {
      console.log(err);
    }
  }

  async getListImage() {
    try {
      const res = await Request.restGet(`${HOST}/site/images`, { }, Protos.Site.ImageResponse);
      // console.log(res.data);
      if (res.data.list) {
        this.listImages = res.data.list;
        console.log("images loaded")
      }
      return res.data.list;
    } catch(err) {
      console.log(err);
    }
  }

  getImageByName(name: string) {
    return this.listImages.find(i => i.name === name)?.value;
  }

  async getListCurrentcies() {
    try {
      const res = await Request.restGet(`${HOST}/site/currencies`, { });
      // console.log(res.data);
      if (res.data.list) {
        // console.log(res.data.list);
        this.listCurrencies = res.data.list;
      }
      return res.data.list;
    } catch(err) {
      console.log(err);
    }
  }

  currencyName(code) {
    const find = this.listCurrencies.find(val => val.code === code);
    // return !!find ? find.currency : '';
    return !!find ? find.currency : CURRENCIES[code]?.name;
  }
}

export default new Site();