import React from 'react';
import classNames from 'classnames';
import ArrowWhiteIc from 'src/assets/icon/arrow-white.png';

interface Props {
    selected?: string,
    setSelected(item: any): void,
}

const RateTypes2 = (props: Props) => {
    const list = [
        { name: 'NET', className: 'net' },
        { name: 'COMMISSIONABLE', className: 'commisionable' },
    ];
    return (
        <div className="cards-container">
            {list.map((item, i) => {
                let selected = item.name === props.selected;
                console.log('selected', selected);
                return (
                    <div className={"rate-type-item target-card"} key={`card${i}`}>
                        <div className="vertical-line">
                            {/* <div className={i === 0 ? 'half' : 'half-active'} />
                            <div className={i + 1 === props.data.length ? 'half' : 'half-active'} /> */}
                        </div>
                        <div className="partner-item-spacer active-line" />
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            {/* FIX SAFARI ISSUEs */}
                            <div
                                style={{ width: '100%', backgroundColor: 'rgba(255,255,255,0.1)' }}
                                className={classNames('image-partner', {
                                    'image-partner-active': selected,
                                    'disable-img-2': !selected,
                                })}
                                onClick={() => props.setSelected(item)}
                            >
                                <div
                                    className={classNames(item.className, "responsive-text")}
                                    style={{
                                        height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {item.name}
                                </div>
                            </div>
                            <div style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }}>
                                <img src={ArrowWhiteIc} className="arrow-icon-bottom" />
                            </div>
                        </div>
                        <div className={classNames('partner-item-spacer', {
                            'active-line': selected,
                        })} />
                    </div>
                )
            })}
        </div>
    )
}

export default RateTypes2;
