import React from 'react';

interface Props {
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    className?: string,
}

const Line = (props: Props) => {
    const { x0, y0, x1, y1 } = props;

    const dy = y1 - y0;
    const dx = x1 - x0;

    const angle = Math.atan2(dy, dx) * 180 / Math.PI;
    const length = Math.sqrt(dx * dx + dy * dy);

    return (
        <div className="react-lineto-placeholder">
            <div style={{
                // borderTopColor: 'white',
                // borderTopStyle: "solid",
                // borderTopWidth: 1,
                backgroundColor: 'white',
                position: 'absolute',
                top: `${y0}px`,
                left: `${x0}px`,
                width: `${length}px`,
                height: '1px',
                zIndex: 1,
                transform: `rotate(${angle}deg)`,
                transformOrigin: '0 0',
            }} className={'animated-line'} />
        </div>
    )
}

export default Line;