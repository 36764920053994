import { observable } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import Protos from './protos/Protos.Utils';

type GeneralResponse = (any | Error)[] | any;

type PartnerName = 'Ixaris' | 'Airplus' | 'Barclaycard' | 'Apiso';
export type CardSchemeName = 'MC MBS (GWTTP) : GPS' | 'MC MRW (FALLBACK) : GPS' | 'VISA Business Debit : FIS' | 'UATP (Airplus)' | 'AIDA (Airplus)' | 'none' | any;

export interface CurrencySchema {
  cardScheme?: CardSchemeName,
  list: Array<string>
}
export interface PartnerSchema {
  name: PartnerName,
  description: string,
  funding: string,
  currencies: Array<CurrencySchema>,
}

export type Region = 'AFRICA' | 'APAC' | 'EUR' | 'LATAM' | 'NORAM' | 'MIDDLE_EAST' | 'EUROPE';
export interface CountrySchema {
  id: string,
  name: string,
  region: Region,
  issurance: string,
  hasLocalIssuer: boolean,
  additionComments: string,
  partners: Array<PartnerSchema>,
};

class Country extends StoreClass implements StoreClassType {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listCountries: Array<CountrySchema> = [];
  @observable listCountriesByRegion: Array<CountrySchema> = [];
  @observable selectedRegion: Region;
  @observable selectedCountry: CountrySchema;
  @observable selectedPartner: PartnerSchema;
  @observable selectedCurrency: CurrencySchema;

  async loadInitialData() {
    await Protos.onReady();
    let listCountries = await this.persist.sync("LIST_COUNTRIES", "array");
    this.listCountries = listCountries;
    this.makeReady();
  }

  @erria
  async getListData() {
    const res = await Request.restGet(`${HOST}/country`, {}, Protos.Country.ListCountry);
    if (res.data.error) throw new Error(res.data.message);
    this.listCountries = res.data.list;
    this.persist.save(this.listCountries, "LIST_COUNTRIES");
    let obj = {};
    this.listCountries.map(i => {
      if (i.partners.length > 0) i.partners.map(j => {
        obj[j.name] = true;
      })
    })
    return res.data;
  }

  async setSelectedRegion(region: Region) {
    this.selectedRegion = region;
    let arr = [];
    switch (region) {
      case 'AFRICA':
        arr = ['AFRICA', 'AFRICA SS'];
        break;
      case 'EUROPE':
        arr = ['EUR', 'EURO', 'EUROPE'];
        break;
      case 'MIDDLE_EAST':
        arr = ['NECSE', 'NECSE EAST', 'MIDDLE_EAST', 'MIDDLE EAST'];
        break;
      default:
        arr = [region];
        break;
    }
    if (!this.listCountries || !this.listCountries.length) {
      await this.getListData();
    }
    this.listCountriesByRegion = this.listCountries.filter(i => arr.includes(String(i.region).trim())).sort((a, b) => {
      if (a.hasLocalIssuer && !b.hasLocalIssuer) return -1;
      if (b.hasLocalIssuer && !a.hasLocalIssuer) return 1;
      return a.name > b.name ? 1 : -1;
    });
    return true;
  }

  setSelectedCountry(country: CountrySchema) {
    this.selectedCountry = country;
  }

  setSelectedPartner(country: PartnerSchema) {
    this.selectedPartner = country;
  }

  setSelectedCurrency(currency: CurrencySchema) {
    this.selectedCurrency = currency;
  }
}

export default new Country();