import airplus from 'src/assets/airplus.png';
import apiso from 'src/assets/apiso.png';
import barclaycard from 'src/assets/barclaycard.jpg';
import ixaris from 'src/assets/ixaris.png';

const IMGS = {
    airplus,
    apiso,
    barclaycard,
    ixaris,
}

export default IMGS;