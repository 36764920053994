import 'cross-fetch/polyfill';
import axios from 'axios';
import Protos from './protos';
import { HOST } from './host';
const qs = require('qs');

class RequestHelper {

  _token

  setToken = (token) => {
    this._token = token;
  }

  getToken = () => {
    return this._token;
  }

  makeRestHeader = (method) => {
    let token = this.getToken();
    let headers = {
      'Accept': 'application/json'
    };
    if(token) headers["Authorization"] = token;
    if(method === "POST" || method === "PUT" ) headers["Content-Type"] = "application/json";
    return headers;
  }

  makeHeader = (method, isUpload = false) => {
    let token = this.getToken();
    let headers = {
      'Accept': 'application/octet-stream'
    };
    if(token) headers["Authorization"] = token;
    if(method === "POST" || method === "PUT" ) headers["Content-Type"] = "application/octet-stream";
    if(isUpload) {
      headers["Content-Type"] = 'multipart/form-data';
    }
    return headers;
  }

  querify = (url, queryObject) => {
    let newUrl = url;
    if(!queryObject) return newUrl;
    newUrl += "?" + qs.stringify(queryObject);
    return newUrl;
  }

  decodeResponse = (bufferData, decoder) => {
    let objData;
    try {
      objData = decoder.decode(new Uint8Array(bufferData));
    } catch(err) {
      throw err;
    }
    return objData;
  }

  restGet = async (URL, queryObject, unneededParam = undefined) => {
    const urlWithQuery = this.querify(URL.replace(HOST, HOST + '/rest'), queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
      headers: this.makeRestHeader("GET"),
    });
    return {
      headers: res.headers,
      data: res.data,
    };
  }

  restPost = async (URL, bodyObject, unneededParam = undefined) => {
    const res = await axios.request({
      url: URL.replace(HOST, HOST + '/rest'),
      method: 'post',
      headers: this.makeRestHeader("POST"),
      data: JSON.stringify(bodyObject),
    });
    return {
      headers: res.headers,
      data: res.data,
    };
  }

  get = async (URL, queryObject, decoder) => {
    try {
      const urlWithQuery = this.querify(URL, queryObject);
      const res = await axios.request({
        url: urlWithQuery,
        method: 'get',
        headers: this.makeHeader("GET"),
        responseType: 'arraybuffer',
      });

      const bufferData = res.data;
      const objData = this.decodeResponse(bufferData, decoder);
      return {
        headers: res.headers,
        data: objData,
      };
    } catch(err) {
      console.log('err', err)
      const res = err.response;
      if (!res) throw err;
      if (res.status === 500 && !!res.data) {
        const bufferData = res.data;
        const ErrorMessage = Protos.ErrorMessage.ErrorMessage;
        const objData = ErrorMessage.decode(new Uint8Array(bufferData));
        return {
          headers: res.headers,
          data: objData,
        };
      }
    }
    
  }

  post = async (URL, bodyObject, { Req, Res }) => {
    const requestOptions = {
      data: undefined,
      responseType: undefined,
    }
    if (!!Req && !!bodyObject) {
      const payload = Req.create(bodyObject);
      const buffer = Req.encode(payload).finish();
      requestOptions.data = Uint8Array.from(buffer);
      requestOptions.responseType = 'arraybuffer';
    }
    try {
      const res = await axios.request({
        url: URL,
        method: 'post',
        headers: this.makeHeader("POST"),
        ...requestOptions,
      });
      const bufferData = res.data;
      const objData = this.decodeResponse(bufferData, Res);
      return {
        headers: res.headers,
        data: objData,
      };
    } catch(err) {
      const res = err.response;
      if (!res) throw err;
      if (res.status === 500 && !!res.data) {
        const bufferData = res.data;
        const ErrorMessage = Protos.ErrorMessage.ErrorMessage;
        const objData = ErrorMessage.decode(new Uint8Array(bufferData));
        return {
          headers: res.headers,
          data: objData,
        };
      }
    }
  }

  postUpload = async (URL, bodyObject, callback = (e) => {}) => {
    const res =  await axios.request({
      url: URL,
      method: 'post',
      headers: this.makeHeader("POST", true),
      data: bodyObject,
      onUploadProgress: (progressEvent) => {
        callback(progressEvent.loaded/progressEvent.total)
        // console.log(progressEvent.loaded/progressEvent.total)
      }
    })
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

}

export default new RequestHelper();