import React, { Component } from 'react';
import Line from './Line';

const defaultAnchor = { x: 0.5, y: 0.5 };

interface Props {
    from: string,
    to: string,
    within?: string,
    fromAnchor?: string,
    toAnchor?: string,
    delay?: number,
    [key: string]: any,
}

class LineTo extends Component<Props> {
    fromAnchor: any;
    toAnchor: any;
    delay: any;
    t: any;

    componentWillMount() {
        this.fromAnchor = this.parseAnchor(this.props.fromAnchor);
        this.toAnchor = this.parseAnchor(this.props.toAnchor);
        this.delay = this.parseDelay(this.props.delay);
    }

    componentDidMount() {
        this.delay = this.parseDelay(this.props.delay);
        if (typeof this.delay !== 'undefined') {
            this.deferUpdate(this.delay);
        }
    }

    componentDidUpdate(nextProps: any) {
        if (nextProps.fromAnchor !== this.props.fromAnchor) {
            this.fromAnchor = this.parseAnchor(this.props.fromAnchor);
        }
        if (nextProps.toAnchor !== this.props.toAnchor) {
            this.toAnchor = this.parseAnchor(this.props.toAnchor);
        }
        this.delay = this.parseDelay(nextProps.delay);
        if (typeof this.delay !== 'undefined') {
            this.deferUpdate(this.delay);
        }
    }

    componentWillUnmount() {
        if (this.t) {
            clearTimeout(this.t);
            this.t = null;
        }
    }

    shouldComponentUpdate() {
        // Always update component if the parent component has been updated.
        // The reason for this is that we would not only like to update
        // this component when the props have changed, but also when
        // the position of our target elements has changed.
        // We could return true only if the positions of `from` and `to` have
        // changed, but that may be expensive and unnecessary.
        return true;
    }

    // Forced update after delay (MS)
    deferUpdate(delay: number) {
        if (this.t) {
            clearTimeout(this.t);
        }
        this.t = setTimeout(() => this.forceUpdate(), delay);
    }

    parseDelay(value: any) {
        if (typeof value === 'undefined') {
            return value;
        } else if (typeof value === 'boolean' && value) {
            return 0;
        }
        const delay = parseInt(value, 10);
        if (isNaN(delay) || !isFinite(delay)) {
            throw new Error(`LinkTo could not parse delay attribute "${value}"`);
        }
        return delay;
    }

    parseAnchorPercent(value: any) {
        const percent = parseFloat(value) / 100;
        if (isNaN(percent) || !isFinite(percent)) {
            throw new Error(`LinkTo could not parse percent value "${value}"`);
        }
        return percent;
    }

    parseAnchorText(value: any) {
        // Try to infer the relevant axis.
        switch (value) {
            case 'top':
                return { y: 0 };
            case 'left':
                return { x: 0 };
            case 'middle':
                return { y: 0.5 };
            case 'center':
                return { x: 0.5 };
            case 'bottom':
                return { y: 1 };
            case 'right':
                return { x: 1 };
        }
        return null;
    }

    parseAnchor(value: any) {
        if (!value) {
            return defaultAnchor;
        }
        const parts = value.split(' ');
        if (parts.length > 2) {
            throw new Error('LinkTo anchor format is "<x> <y>"');
        }
        const [x, y] = parts;
        return Object.assign({}, defaultAnchor,
            x ? this.parseAnchorText(x) || { x: this.parseAnchorPercent(x) } : {},
            y ? this.parseAnchorText(y) || { y: this.parseAnchorPercent(y) } : {}
        );
    }

    findElement(className: string) {
        return document.getElementsByClassName(className);
    }

    detect() {
        const { from, to, within = '' } = this.props;

        const arr_a = this.findElement(from);
        const arr_b = this.findElement(to);
        if (!arr_a || !arr_b) {
            return false;
        }
        let result = [];
        for (let i = 0; i < arr_a.length; i++) {
            let a = arr_a[i];
            for (let j = 0; j < arr_b.length; j++) {
                let b = arr_b[j];
                let anchor0 = this.fromAnchor;
                let anchor1 = this.toAnchor;

                let box0 = a.getBoundingClientRect();
                let box1 = b.getBoundingClientRect();

                let offsetX = window.pageXOffset;
                let offsetY = window.pageYOffset;

                if (within) {
                    const arr_p = this.findElement(within);
                    if (arr_p) {
                        let p = arr_p[0];
                        const boxp = p.getBoundingClientRect();

                        offsetX -= boxp.left + (window.pageXOffset || document.documentElement.scrollLeft) - p.scrollLeft;
                        offsetY -= boxp.top + (window.pageYOffset || document.documentElement.scrollTop) - p.scrollTop;
                    }
                }
                let x0 = box0.left + box0.width * anchor0.x + offsetX;
                let x1 = box1.left + box1.width * anchor1.x + offsetX;
                let y0 = box0.top + box0.height * anchor0.y + offsetY;
                let y1 = box1.top + box1.height * anchor1.y + offsetY;
                result.push({ x0, y0, x1, y1 });
            }
        }
        return result;
    }

    render() {
        const points = this.detect();
        return points ? (
            <>
                {points.map((point, i) => {
                    return (
                        <Line key={`line${i}`} {...point} {...this.props} />
                    )
                })}
            </>
        ) : null;
    }
}
export default LineTo;
