import React from 'react';
import Store from 'src/store';
const CURRENCIES = require('src/assets/currencies.json');

const Currencies = ({ data, funding }: { data: Array<string>, funding: string }) => {
    return (
        <div className="currencies-container">
            {data.map((item, i) => {
                return (
                    <div key={`curr${i}`} className="currency-item">
                        <span className="text-regular" style={{ minWidth: '2rem' }}>{item}</span>
                        <span className="text-regular">{Store.Site.currencyName(item)}</span>
                    </div>
                )
            })}
            {funding && (
                <div style={{ display: 'flex', width: '100%' }}>
                    <p className="funding-text">Funding: {funding}</p>
                </div>
            )}
        </div>
    )
}

export default Currencies;
