import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ArrowIc from 'src/assets/icon/arrow.png';
import LogoIc from 'src/assets/logo.png';
import Store from 'src/store';
import './style.css';

const InputPass = (props: RouteComponentProps) => {
    const [pass, setPass] = useState('');

    const onSubmit = async () => {
        if (!pass) return;
        let res = await Store.Site.access(pass);
        if (res) {
            // @ts-ignore
            !!window.plausible && window.plausible('Pass Login');
            const redirect = localStorage.getItem('redirect');
            console.log('redirect', redirect);
            if (!!redirect) {
                props.history.push(redirect.replace('#', ''));
                localStorage.setItem('redirect', '');
            } else {
                props.history.push('/select-heat-map');
            }
        }
        else alert('ACCESS DENIED');
    }

    return (
        <div className="input-pass-background">
            <img src={LogoIc} style={{ width: '10rem', height: 'auto', position: 'absolute', top: '2rem', right: '2rem' }} />

            <div className="input-pass-box">
                <span style={{ fontSize: '2.1rem' }} id="title1" className="text-light">Amadeus</span>
                <span style={{ fontSize: '3.5rem' }} id="title2" className="text-light">GLOBAL REACH HEATMAP</span>
                <div className="input-container">
                    <input
                        placeholder="Enter Password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        id="pass-input"
                    />
                    <div onClick={onSubmit} className="btn-arrow">
                        <img src={ArrowIc} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(InputPass);
