import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import ArrowWhiteIc from 'src/assets/icon/arrow-white.png';
import Store from 'src/store';
import VarHelper from 'src/utils/VarHelper';
interface Props {
    selected?: string,
    setSelected(item: any): void,
}

const Partners = (props: Props) => {
    const list = [
        { name: 'HOTEL COLLECT', des: 'Paid by the traveler', className: 'hotel'  },
        { name: 'AGENCY COLLECT', des: 'Paid by the travel agency', className: 'agency'  },
    ];
    return (
        <Fragment>
            {list.map((item, i) => {
                let selected = item.name === props.selected;
                return (
                    <Fragment key={`partner${i}`}>
                        <div className={classNames('responsible-payment-item', {
                            'partner-item-active': selected,
                        })} >
                            <div className="partner-item-spacer" />
                            <div style={{ flex: 1 }}>
                                {!!Store.Site.getImageByName(item.name) ? (
                                    <img
                                        alt={item.name}
                                        className={classNames('image-partner', {
                                            'selected-partner': selected,
                                            'image-partner-active': selected,
                                            'disable-img-2': !selected,
                                        })}
                                        onClick={() => {
                                            props.setSelected(item);
                                        }}
                                        src={Store.Site.getImageByName(item.name)}
                                        style={{ width: '100%', backgroundColor: 'rgba(255,255,255,0.1)', minHeight: 100 }}
                                    />
                                ) : (
                                    <div
                                        style={{ height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}
                                        className={classNames('image-partner', {
                                            'selected-partner': selected,
                                            'image-partner-active': selected,
                                            'disable-img-2': !selected,
                                            [item.className]: true,
                                        })}
                                        onClick={() => {
                                            props.setSelected(item);
                                        }}
                                    >
                                        {item.name}
                                        <p>({item.des})</p>
                                    </div>
                                )}
                                
                                <img src={ArrowWhiteIc} className="arrow-icon-bottom" alt="" />
                            </div>
                            <div className={classNames('partner-item-spacer', {
                                'active-line': selected,
                            })} style={{ marginLeft: '1px' }} />
                        </div>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default Partners;
