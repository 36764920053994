import React from 'react';
import Store from 'src/store';

const PaymentOptions = ({ data }: { data: Array<{ name: string, id: string, comment: string }> }) => {

    const parseName = (name) => !name ? '' : String(name).replace(/#/g, '');

    const parseComment = (comment, name) => {
        const indexOfClickHere = comment.toLowerCase().indexOf('click here');
        if (indexOfClickHere === -1) return comment;
        const parts = [
            comment.slice(0, indexOfClickHere),
            comment.slice(indexOfClickHere, indexOfClickHere + 'click here'.length),
            comment.slice(indexOfClickHere + 'click here'.length, comment.length),
        ];
        console.log('parts', parts);
        return parts.map((p, i) => {
            const url = !!name && name.includes('HOTEL BILLBACK') ? 'https://amadeus.com/en/portfolio/retail-travel-agencies/hotel-billback' : window.location.href.replace('hotel', 'wallet');
            if (p === 'click here') return <a target={url.includes('https://amadeus.com') ? '_blank' : undefined} href={url} key={'here'+i} style={{ color: 'white' }}>click here</a>;
            return p;
        })
    }

    return (
        <div className="payment-options-container">
            {data.map((item, i) => {
                return (
                    <div key={`curr${i}`} className="payment-options-item">
                        <p><span className="text-regular" style={{ minWidth: '2rem' }}>{item.id} - {parseName(item.name)}</span></p>
                        {item.comment && <p><em>{parseComment(item.comment, item.name)}</em></p>}
                    </div>
                )
            })}
        </div>
    )
}

export default PaymentOptions;
