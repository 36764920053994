import React from 'react';
import classnames from 'classnames';
import './ImageRatio.css';

interface Props {
  src: string;
  ratio?: number;
  className?: string;
  onClick?(): void,
}

const ImageRatio = (props: Props) => {
  return (
    <div onClick={props.onClick} className={classnames("blf-img-container", props.className)} style={{ paddingTop: `${100 / props.ratio}%` }}>
      <img src={props.src} alt="" />
    </div>
  );
};

export default ImageRatio;
