import React, { useEffect } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import './App.css';
import InputPass from './pages/InputPass';
import SelectHeatMap from './pages/SelectHeatMap';
import CountryList from './pages/CountryList';
import WalletCountryDetail from './pages/WalletCountryDetail';
import HotelCountryDetail from './pages/HotelCountryDetail';
import Map from './pages/Map';
import { BGContainer } from './components';
import Store from './store';

const remToPx = (rem: number) => parseFloat(getComputedStyle(document.documentElement).fontSize) * rem;
const CONTENT_HEIGHT = window.innerHeight - remToPx(10);

// // test

// (async () => {
//   await Store.Country.onReady();
//   console.log('onready');
//   await Store.Country.getListData();
// })();

function App(props: RouteComponentProps) {
  useEffect(() => {
    if (!Store.Site.ready) {
      Store.Site.onReady(() => checkAuthen());
    } else {
      checkAuthen();
    }
  }, [props.location.pathname]);

  const checkAuthen = async () => {
    console.log('Store.Site.authorized', Store.Site.authorized);
    if (!Store.Site.authorized && !window.location.href.endsWith('/#/')) {
      localStorage.setItem('redirect', window.location.hash);
      window.location.replace('/');
    }
  }

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <InputPass />
        </Route>
        <Route exact path="/select-heat-map">
          <SelectHeatMap />
        </Route>
        <Route path="/wallet/map">
          <Map type="wallet" />
        </Route>
        <Route path="/hotel/map">
          <Map type="hotel" />
        </Route>
        <Route path="/wallet/region/:region">
          <CountryList type="wallet" />
        </Route>
        <Route path="/hotel/region/:region">
          <CountryList type="hotel" />
        </Route>
        <Route path="/wallet/country/:region/:country">
          <WalletCountryDetail />
        </Route>
        <Route path="/hotel/country/:region/:country">
          <HotelCountryDetail />
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);
